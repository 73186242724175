import 'patient/styles/account-favorite-medics.css';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { appointmentLocalStepsRequest } from 'shared/redux/actions/PatientAppointmentActions';
import { fetchDoctorProfileRequest } from 'shared/redux/actions/DoctorProfileActions';
import { getAuthState, getFavouriteDoctorsState } from 'shared/redux/src/StatesGetter';
import PatientMedicCard from 'patient/screens/medicList/PatientMedicCard';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import { getFavouriteDoctorsRequest } from 'shared/redux/actions/FavouriteDoctorsActions';
import { useTranslation } from 'react-i18next';

const PatientAccountFavoriteMedicsBlock = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: favouriteMedics,
    currentPage,
    noOfPages,
  } = useSelector(getFavouriteDoctorsState, shallowEqual);

  const onScheduleClicked = async (doctor) => {
    dispatch(
      appointmentLocalStepsRequest({
        doctor,
        type: 'programmed',
        noOfSteps: 5,
      }),
    );
    dispatch(fetchDoctorProfileRequest({ id: doctor?.id }));
    navigate('/patient/appointment/specialization');
  };
  const onCallNowClicked = (doctor) => {
    if (doctor?.isAvailableForCallNow) {
      dispatch(
        appointmentLocalStepsRequest({
          doctor,
          type: 'callNow',
          noOfSteps: 4,
          newAppointment: true,
        }),
      );
      navigate('/patient/appointment/specialization');
    }
  };
  const loadNextPageMedics = () => {
    if (currentPage <= noOfPages) {
      dispatch(
        getFavouriteDoctorsRequest({
          page: currentPage,
          nextPage: true,
        }),
      );
    }
  };

  return (
    <div className="account-favorite-medics">
      <div className="title-section">
        <h2>{t('settings.favorites')}</h2>
      </div>
      <div className="medic">
        <InfiniteScroll
          dataLength={favouriteMedics.length}
          next={loadNextPageMedics}
          hasMore={currentPage <= noOfPages}
          loader={<LoadingSpinner isLoading />}
        >
          {!favouriteMedics.length && (
            <div className="empty-section">{t('settings.noMedicFavorites')}</div>
          )}

          {favouriteMedics.map((medic, index) => (
            <PatientMedicCard
              key={medic.id}
              listItem={medic}
              onScheduleClicked={onScheduleClicked}
              onCallNowClicked={onCallNowClicked}
              showAllSpecializations
              classes={`col-12 ${favouriteMedics.length !== index + 1 ? 'pb-4' : ''}`}
            />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default PatientAccountFavoriteMedicsBlock;
