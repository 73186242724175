import 'patient/styles/call-specialist.css';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ListGroup from 'react-bootstrap/ListGroup';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getPatientAppointmentState } from 'shared/redux/src/StatesGetter';
import TimerComponent from 'shared/components/TimerComponent';
import {
  appointmentCancelRequest,
  appointmentLocalStepsRequest,
} from 'shared/redux/actions/PatientAppointmentActions';
import Alerts from 'shared/components/Alerts';
import dayjs from 'dayjs';

const MedicFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    id: appointmentId,
    doctor: foundDoctor,
    price: priceString,
    priceGrossAsString,
    startTime,
    endTime,
    noOfSteps,
    specializationId,
    duration,
    patient,
    type: appointmentType,
    scheduledAt = dayjs().format('YYYY-MM-DD HH:mm:ss'),
    discountAsString,
    voucher: serverVoucher,
  } = useSelector(getPatientAppointmentState, shallowEqual);
  const cancelAppointment = () => {
    Alerts.okCancelAlert(t('warning'), t('validations.cancelAppointment'), () => {
      dispatch(appointmentCancelRequest({ appointmentId }));
      dispatch(
        appointmentLocalStepsRequest({
          newAppointment: true,
        }),
      );
      navigate('/');
    });
  };

  return (
    <section className="call-specialist">
      <div className="countdown-box countdown">
        <div className="will-start">{t('medicSpecialistStartingCallIn')}</div>
        <div className="timer">
          <TimerComponent action={() => {}} endTime={startTime} onTimeAboutToEnd={() => {}} />
          <p>&nbsp;{t('min')}</p>
        </div>
      </div>
      <div className="medic-call-box">
        <div
          className="medic-avatar"
          style={
            foundDoctor?.imgUrl
              ? { backgroundImage: `url(${foundDoctor?.imgUrl})` }
              : { backgroundColor: '#E5E7E8' }
          }
        >
          {!foundDoctor?.imgUrl && (
            <div className="image-placeholder">{`${foundDoctor?.firstName?.charAt(
              0,
            )}${foundDoctor?.lastName?.charAt(0)}`}</div>
          )}
        </div>
        <div className="rating">
          <CustomIcon className="custom-icon" color={Colors.mediumGrey} size="16" icon="Rating_2" />
          <i className="rating-icon" />
          {t('note')} {foundDoctor.averageRating} ({foundDoctor.countRating ?? 0} {t('noOfReviews')}
          )
        </div>
        <div className="name">{foundDoctor.fullNameWithTitle}</div>
        <div className="specialization">{foundDoctor.specialization}</div>
        <div className="clinic">{foundDoctor.organization?.clinicName}</div>
      </div>
      <div className="details-box">
        <ListGroup>
          <ListGroup.Item>
            <div className="title">{t('time')}</div>
            <div className="details">
              {dayjs(startTime ?? dayjs().format('YYYY-MM-DD HH:mm:ss')).format('HH:mm')}
            </div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="title">{t('examinationDuration')}</div>
            <div className="details">{duration} min</div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="title">{t('totalToPay')}</div>
            <div className="details">{priceString}</div>
          </ListGroup.Item>
        </ListGroup>
      </div>
      <button
        type="button"
        onClick={() => navigate('/patient/call-specialist/summary-payment')}
        className="orange-button"
      >
        {t('continueToPay')}
      </button>
      <div className="cancel-button" onClick={cancelAppointment}>
        <button type="button">{t('abort')}</button>
      </div>
    </section>
  );
};

export default MedicFound;
