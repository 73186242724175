import { shallowEqual, useSelector } from 'react-redux';
import 'shared/styles/Nav.scss';
import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Colors from 'shared/themes/Colors';
import Avatar from 'shared/components/Avatar';
import CustomIcon from 'shared/components/CustomIcon';
import { activateAccount, getEnv, isLandingPage, isTwilioScreen } from 'shared/services/EnvService';
import { slide as Menu } from 'react-burger-menu';
import { getAuthState, getPatientProfileState } from 'shared/redux/src/StatesGetter';
import PatientTopMenu from 'patient/components/PatientTopMenu';
import {
  PatientMenuRoutesWithFullAccess,
  PatientMenuRoutesWithoutFullAccess,
} from 'patient/PatientRoutes';
import { useTranslation } from 'react-i18next';
import LanguageNavSelector from 'patient/components/LanguageNavSelector';
import NavTopMenu from './NavTopMenu';

const HeaderSection = ({ unseen }) => {
  const { t, i18n } = useTranslation();
  const { isLoggedIn, isConfirmed, isProfileCompleted, hasFullAccess } = useSelector(
    getAuthState,
    shallowEqual,
  );

  const contactClass = window.location.pathname.match(/\/contact/) ? 'active' : '';
  const medicListClass = window.location.pathname.match(/\/medicList/) ? 'active' : '';

  if (!isLoggedIn) {
    return (
      <>
        <NavTopMenu />
        <header className="menu landing patient-menu-header">
          <div className="container-xxl">
            <div className="row">
              <div className="view login-register-menu landing-menu">
                <div className="logo-wrapper">
                  <Link className="logo cursor-pointer" to="/" key="LOGO" reloadDocument>
                    <i className="ringdoc-color-logo" />
                  </Link>
                  <div className="register-mob-btn">
                    <Link className="cursor-pointer" to="/patient/register">
                      {t('patientApp.createAccount')}
                    </Link>
                  </div>
                  <Menu right burgerButtonClassName="mobile-menu">
                    <ul>
                      <li>
                        <Link className="cursor-pointer" to="/patient/login">
                          {t('patientApp.login')}
                          <CustomIcon
                            className="custom-icon"
                            color={Colors.darkGrey}
                            size="20"
                            icon="Right_pointer"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link className="cursor-pointer" to="/doctor/landing">
                          {t('imADoctor')}
                          <CustomIcon
                            className="custom-icon"
                            color={Colors.darkGrey}
                            size="20"
                            icon="Right_pointer"
                          />
                        </Link>
                      </li>
                    </ul>
                  </Menu>
                </div>
                <div className="menu">
                  <LanguageNavSelector mobileOnly />
                  <ul>
                    <li className={medicListClass}>
                      <Link className="cursor-pointer" to="/patient/medicList" reloadDocument>
                        {t('medicList')}
                      </Link>
                    </li>
                    <li>
                      <a className="cursor-pointer" href="/#make-online-consultations">
                        {t('ringdocServices')}
                      </a>
                    </li>
                    <li>
                      <Link className="cursor-pointer" to="/patient/medicList" reloadDocument>
                        {t('medicalSpecialities')}
                      </Link>
                    </li>
                    <li className={contactClass}>
                      <Link className="cursor-pointer" to="/patient/contact">
                        {t('contact')}
                      </Link>
                    </li>
                  </ul>
                  <LanguageNavSelector />
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }

  return (
    <PatientTopMenu
      routes={!hasFullAccess ? PatientMenuRoutesWithoutFullAccess : PatientMenuRoutesWithFullAccess}
      unseen={unseen}
      isMobile={false}
    />
  );
};

export default HeaderSection;
