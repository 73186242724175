import { AnyAction } from 'redux';
import {
  APPOINTMENT_PAYMENT_ERROR,
  APPOINTMENT_PAYMENT_REQUEST,
  APPOINTMENT_PAYMENT_SUCCESS,
  CONFIRM_PAYMENT_ERROR,
  CONFIRM_PAYMENT_REQUEST,
  CONFIRM_PAYMENT_SUCCESS,
  CREATE_PAYMENT_ERROR,
  CREATE_PAYMENT_METHOD_REQUEST,
  CREATE_PAYMENT_METHOD_SUCCESS,
  CREATE_PAYMENT_METHOD_ERROR,
  SAVE_PAYMENT_METHOD_REQUEST,
  SAVE_PAYMENT_METHOD_SUCCESS,
  SAVE_PAYMENT_METHOD_ERROR,
  CREATE_PAYMENT_REQUEST,
  CREATE_PAYMENT_SUCCESS,
  GET_PATIENT_TRANSACTIONS_REQUEST,
  GET_PATIENT_TRANSACTIONS_SUCCESS,
  GET_PATIENT_TRANSACTIONS_ERROR,
  GET_PAYMENT_METHODS_ERROR,
  GET_PAYMENT_METHODS_REQUEST,
  GET_PAYMENT_METHODS_SUCCESS,
  REMOVE_PAYMENT_METHOD_ERROR,
  REMOVE_PAYMENT_METHOD_REQUEST,
  REMOVE_PAYMENT_METHOD_SUCCESS,
  LOCALLY_CHOOSE_CARD,
} from 'shared/redux/types/PatientPaymentTypes';

export const getPaymentMethodsRequest = (payload): AnyAction => ({
  type: GET_PAYMENT_METHODS_REQUEST,
  payload,
});

export const getPaymentMethodsSuccess = (payload): AnyAction => ({
  type: GET_PAYMENT_METHODS_SUCCESS,
  payload,
});

export const getPaymentMethodsError = (payload): AnyAction => ({
  type: GET_PAYMENT_METHODS_ERROR,
  payload,
});

export const createPaymentRequest = (payload): AnyAction => ({
  type: CREATE_PAYMENT_REQUEST,
  payload,
});

export const createPaymentSuccess = (payload): AnyAction => ({
  type: CREATE_PAYMENT_SUCCESS,
  payload,
});

export const createPaymentError = (payload): AnyAction => ({
  type: CREATE_PAYMENT_ERROR,
  payload,
});

export const confirmPaymentRequest = (payload): AnyAction => ({
  type: CONFIRM_PAYMENT_REQUEST,
  payload,
});

export const confirmPaymentSuccess = (payload): AnyAction => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload,
});

export const confirmPaymentError = (payload): AnyAction => ({
  type: CONFIRM_PAYMENT_ERROR,
  payload,
});

export const appointmentPaymentRequest = (payload): AnyAction => ({
  type: APPOINTMENT_PAYMENT_REQUEST,
  payload,
});

export const appointmentPaymentSuccess = (payload): AnyAction => ({
  type: APPOINTMENT_PAYMENT_SUCCESS,
  payload,
});

export const appointmentPaymentError = (payload): AnyAction => ({
  type: APPOINTMENT_PAYMENT_ERROR,
  payload,
});

export const createPaymentMethodRequest = (payload): AnyAction => ({
  type: CREATE_PAYMENT_METHOD_REQUEST,
  payload,
});

export const createPaymentMethodSuccess = (payload): AnyAction => ({
  type: CREATE_PAYMENT_METHOD_SUCCESS,
  payload,
});

export const createPaymentMethodError = (payload): AnyAction => ({
  type: CREATE_PAYMENT_METHOD_ERROR,
  payload,
});

export const savePaymentMethodRequest = (payload): AnyAction => ({
  type: SAVE_PAYMENT_METHOD_REQUEST,
  payload,
});

export const savePaymentMethodSuccess = (payload): AnyAction => ({
  type: SAVE_PAYMENT_METHOD_SUCCESS,
  payload,
});

export const savePaymentMethodError = (payload): AnyAction => ({
  type: SAVE_PAYMENT_METHOD_ERROR,
  payload,
});

export const removePaymentMethodRequest = (payload): AnyAction => ({
  type: REMOVE_PAYMENT_METHOD_REQUEST,
  payload,
});

export const removePaymentMethodSuccess = (payload): AnyAction => ({
  type: REMOVE_PAYMENT_METHOD_SUCCESS,
  payload,
});

export const removePaymentMethodError = (payload): AnyAction => ({
  type: REMOVE_PAYMENT_METHOD_ERROR,
  payload,
});

export const getPatientTransactionsRequest = (payload): AnyAction => ({
  type: GET_PATIENT_TRANSACTIONS_REQUEST,
  payload,
});

export const getPatientTransactionsSuccess = (payload): AnyAction => ({
  type: GET_PATIENT_TRANSACTIONS_SUCCESS,
  payload,
});

export const getPatientTransactionsError = (payload): AnyAction => ({
  type: GET_PATIENT_TRANSACTIONS_ERROR,
  payload,
});

export const locallyChooseCard = (payload): AnyAction => ({
  type: LOCALLY_CHOOSE_CARD,
  payload,
});
