import ApiUtils from '../../modules/ApiUtils';
import { default as ApiConstants } from '../../constants/ApiConstants.json';

const setReferralApi = (payload) => {
  ApiUtils.postRequest(`${ApiConstants.SET_REFERRALS}`, payload);
};

const getReferralApi = ({ type, limit, page, connected }) => {
  return ApiUtils.getRequest(
    `${ApiConstants.GET_REFERRALS}?type=${type}&connected=${connected}&page=${page}&limit=${limit}`,
  );
};

const sendReferralInvitationApi = (payload) => {
  return ApiUtils.postRequest(`${ApiConstants.SEND_REFERRAL_INVITATION}`, payload);
};

const fetchReferralBonusApi = () => {
  return ApiUtils.getRequest(`${ApiConstants.FETCH_REFERRAL_BONUS}`);
};

const fetchDoctorsAllIndexApi = (payload) => {
  return ApiUtils.getRequest(
    `${ApiConstants.FETCH_DOCTORS_ALL_INDEX}?criteria[name][type]=contains&criteria[name][value]=${payload.doctorNameSearch}`,
  );
};

export {
  setReferralApi,
  getReferralApi,
  sendReferralInvitationApi,
  fetchReferralBonusApi,
  fetchDoctorsAllIndexApi,
};
