import { AnyAction } from 'redux';
import {
  FETCH_NOTIFICATIONS_SETTINGS_ERROR,
  FETCH_NOTIFICATIONS_SETTINGS_REQUEST,
  FETCH_NOTIFICATIONS_SETTINGS_SUCCESS,
  FETCH_PUSH_DETAILS_ERROR,
  FETCH_PUSH_DETAILS_REQUEST,
  FETCH_PUSH_DETAILS_SUCCESS,
  FETCH_PUSHES_ERROR,
  FETCH_PUSHES_REQUEST,
  FETCH_PUSHES_SUCCESS,
  SILENTLY_FETCH_PUSHES_REQUEST,
  UPDATE_NOTIFICATIONS_SETTINGS_ERROR,
  UPDATE_NOTIFICATIONS_SETTINGS_REQUEST,
  UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS,
  UPDATE_PUSHES_ERROR,
  UPDATE_PUSHES_REQUEST,
  UPDATE_PUSHES_SUCCESS,
} from 'shared/redux/types/NotificationTypes';

export const fetchPushesRequest = (payload: any): AnyAction => ({
  type: FETCH_PUSHES_REQUEST,
  payload,
});

export const silentlyFetchPushesRequest = (payload): AnyAction => ({
  type: SILENTLY_FETCH_PUSHES_REQUEST,
  payload,
});

export const fetchPushesSuccess = (payload: any): AnyAction => ({
  type: FETCH_PUSHES_SUCCESS,
  payload,
});

export const fetchPushesError = (payload: any): AnyAction => ({
  type: FETCH_PUSHES_ERROR,
  payload,
});

export const fetchPushDetailsRequest = (payload): AnyAction => ({
  type: FETCH_PUSH_DETAILS_REQUEST,
  payload,
});

export const fetchPushDetailsSuccess = (payload): AnyAction => ({
  type: FETCH_PUSH_DETAILS_SUCCESS,
  payload,
});

export const fetchPushDetailsError = (payload): AnyAction => ({
  type: FETCH_PUSH_DETAILS_ERROR,
  payload,
});

export const updatePushesRequest = (payload: any): AnyAction => ({
  type: UPDATE_PUSHES_REQUEST,
  payload,
});

export const updatePushesSuccess = (payload: any): AnyAction => ({
  type: UPDATE_PUSHES_SUCCESS,
  payload,
});

export const updatePushesError = (payload: any): AnyAction => ({
  type: UPDATE_PUSHES_ERROR,
  payload,
});

export const fetchNotificationsSettingsRequest = (payload: any): AnyAction => ({
  type: FETCH_NOTIFICATIONS_SETTINGS_REQUEST,
  payload,
});

export const fetchNotificationsSettingsSuccess = (payload: any): AnyAction => ({
  type: FETCH_NOTIFICATIONS_SETTINGS_SUCCESS,
  payload,
});

export const fetchNotificationsSettingsError = (payload: any): AnyAction => ({
  type: FETCH_NOTIFICATIONS_SETTINGS_ERROR,
  payload,
});

export const updateNotificationsSettingsRequest = (payload: any): AnyAction => ({
  type: UPDATE_NOTIFICATIONS_SETTINGS_REQUEST,
  payload,
});

export const updateNotificationsSettingsSuccess = (payload: any): AnyAction => ({
  type: UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS,
  payload,
});

export const updateNotificationsSettingsError = (payload: any): AnyAction => ({
  type: UPDATE_NOTIFICATIONS_SETTINGS_ERROR,
  payload,
});
