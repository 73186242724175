export const resolveStickyBarLabel = (stickyStatues) => {
  switch (stickyStatues) {
    case 'WaitForDoctor':
    case 'WaitForCallSpecialistDoctor':
      return 'waitingForDoctorToAnswer';
    case 'SearchDoctor':
      return 'searchDoctorWaitingToAnswer';
    case 'SearchCallSpecialistDoctor':
      return 'searchingForADoctorForYou';
    case 'WaitForPayment':
    case 'WaitForCallSpecialistPayment':
      return 'WaitForPayment';
    default:
      return '';
  }
};
