import { actionChannel, call, fork, put, take } from 'redux-saga/effects';
import { fetchTranslationsError, fetchTranslationsSuccess } from '../actions/TranslationsActions';
import { getTranslationsAPI } from '../api/TranslationsApi';
import { FETCH_TRANSLATIONS_REQUEST } from '../types/TranslationsTypes';
import { translationsSelector } from '../selector/TrnslationsSelector';

function* getTranslations(actionType) {
  const translationsChanel = yield actionChannel(actionType);
  while (true) {
    yield take(translationsChanel);
    try {
      const response = yield call(getTranslationsAPI);
      yield put(fetchTranslationsSuccess(translationsSelector(response.data)));
    } catch ({ message }) {
      yield put(fetchTranslationsError({ message }));
    }
  }
}

function* translationsSaga() {
  yield fork(getTranslations, FETCH_TRANSLATIONS_REQUEST);
}

export default translationsSaga;
