import { AnyAction } from 'redux';
import { antiDuplication } from 'shared/modules/ArrayUtils';
import {
  ADD_FAVOURITE_DOCTOR_SUCCESS,
  GET_FAVOURITE_DOCTORS_SUCCESS,
  REMOVE_FAVOURITE_DOCTOR_SUCCESS,
} from 'shared/redux/types/FavouriteDoctorsTypes';

const FavouriteDoctorsReducer = (
  state = { ids: [], data: [], currentPage: 1, noOfPages: undefined },
  action: AnyAction,
) => {
  switch (action.type) {
    case GET_FAVOURITE_DOCTORS_SUCCESS:
      return {
        data: action.payload.nextPage
          ? antiDuplication([...state.data, ...action.payload.data], 'id')
          : action.payload.data,
        noOfPages: action.payload.noOfPages,
        currentPage: action.payload.nextPage ? state.currentPage + 1 : 2,
        ids: action.payload?.ids,
      };
    case ADD_FAVOURITE_DOCTOR_SUCCESS:
      return { ...state, ids: state?.ids.concat(action.payload) };
    case REMOVE_FAVOURITE_DOCTOR_SUCCESS:
      return { ...state, ids: state?.ids?.filter((id) => id !== action.payload) ?? [] };
    default:
      return state;
  }
};

export default FavouriteDoctorsReducer;
