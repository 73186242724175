import 'patient/styles/appointment-steps.css';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getLocalCurrentPatientProfileState,
  getMedicalRecordCategoryState,
  getPatientAppointmentDetailsState,
  getPatientAppointmentState,
  getPatientLocalChooseSpecializationState,
  getPatientMedicalRecordsState,
  getUserProfileState,
} from 'shared/redux/src/StatesGetter';
import {
  appointmentCancelRequest,
  appointmentLocalStepsRequest,
  appointmentSelectProfileRequest,
  appointmentShareMedicalRecordRequest,
  getPatientAppointmentDetailsRequest,
} from 'shared/redux/actions/PatientAppointmentActions';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import {
  getPatientMedicalRecordsRequest,
  patientAddMedicalRecordRequest,
} from 'shared/redux/actions/PatientProfileActions';
import { fetchMedicalRecordCategoryRequest } from 'shared/redux/actions/MedicalRecordCategoryActions';
import Utils from 'shared/modules/Utils';
import FileUploadComponent from 'patient/components/FileUploadComponent';
import SelectDocumentComponent from 'patient/components/SelectDocumentComponent';
import { useTranslation } from 'react-i18next';
import { requestAppointment } from 'shared/modules/PatientAppointmentUtils';
import dayjs from 'dayjs';
import GAService from '../../../shared/services/GAService';
import { createLoadingSelector } from '../../../shared/redux/src/GenericSeletors';
import Alerts from '../../../shared/components/Alerts';

const PatientAppointmentMedicalDocuments = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    id: appointmentId,
    type: appointmentType,
    sharedFiles,
    noOfSteps,
    scheduledAt,
  } = useSelector(getPatientAppointmentState, shallowEqual);
  const { serverStatus: appointmentStatus } = useSelector(
    getPatientAppointmentDetailsState,
    shallowEqual,
  );
  const user = useSelector(getLocalCurrentPatientProfileState, shallowEqual);
  const medicalRecords = useSelector(getPatientMedicalRecordsState, shallowEqual);
  const fileTypes = useSelector(getMedicalRecordCategoryState, shallowEqual);
  const appointmentState = useSelector(getPatientAppointmentState, shallowEqual);
  const stepString = `${t('step')} ${noOfSteps - 1}/${noOfSteps}`;
  const [selectedFiles, setSelectedFiles] = useState(sharedFiles ?? []);
  const {
    id: specializationId,
    name: specializationName,
    title,
  } = useSelector(getPatientLocalChooseSpecializationState, shallowEqual);
  const loadingSelector = createLoadingSelector();
  const isLoading = useSelector(loadingSelector);

  useEffect(() => {
    dispatch(getPatientMedicalRecordsRequest(user?.id));
    dispatch(fetchMedicalRecordCategoryRequest({}));
    if (appointmentId) {
      dispatch(getPatientAppointmentDetailsRequest({ id: appointmentId }));
    }
  }, []);

  const getNextButtonText = () => {
    switch (appointmentType) {
      case 'emergency':
      case 'callSpecialist':
        if (title) {
          return `${t('chooseMedic')} ${title}`;
        }
        return t('findAvailableDoctor');
      default:
        return t('continueToPayment');
    }
  };

  const submitNewFiles = (name, type, investigationDate, pickedFiles) => {
    dispatch(
      patientAddMedicalRecordRequest({
        data: {
          title: name,
          appointmentId,
          medicalRecordCategory: type,
          investigationDate: dayjs(investigationDate).format('YYYY-MM-DD 00:00:00'),
          filesToCreate: pickedFiles.map((file) => {
            return {
              originalName: file.originalName,
              mimeType: file.mimeType,
              size: file.size,
            };
          }),
        },
        userId: user.id,
        files: pickedFiles.map((file) => {
          return file.fileData;
        }),
      }),
    );
  };

  const doSendDocument = () => {
    if (new Date() > new Date(scheduledAt) && appointmentType === 'programmed') {
      dispatch(appointmentCancelRequest({ appointmentId }));
      navigate('/patient/medicList');
      Alerts.simpleAlert(t('error'), t('alerts.appointment_passed'));
    } else {
      const requestFiles: Record<string, string>[] = [];
      const filesBucket: Record<string, string>[] = [];
      [...selectedFiles, ...sharedFiles]?.map((fileId) => {
        if (filesBucket.indexOf(fileId) === -1) {
          requestFiles.push({ patientMedicalRecord: fileId });
        }
        filesBucket.push(fileId);
        return true;
      });

      const profilePayload = {
        forRequest: { appointmentId, data: { patient: user?.id } },
        forUpdate: user,
        shareFiles: requestFiles.length,
        requestFiles,
        appointmentType,
        appointmentStatus,
      };
      if (appointmentType === 'callSpecialist') {
        GAService.event('web_callSpecialist');
        requestAppointment('callSpecialist', {
          callSpecialistAppointmentPayload: {
            forRequest: {
              specialization: `${specializationId}`,
            },
          },
          profilePayload,
        });
      } else {
        dispatch(appointmentSelectProfileRequest(profilePayload));
      }
      dispatch(appointmentLocalStepsRequest({ sharedFiles: selectedFiles }));

      setSelectedFiles([]);

      if (appointmentType !== 'callSpecialist') {
        navigate('/patient/appointment/summary-payment');
      }
    }
  };

  const modifyAccount = () => navigate('/patient/medicalFolder', { state: 'folder' });

  const [y, setY] = useState(window.scrollY);

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      setY(window.scrollY);
    },
    [y],
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleNavigation);

    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation]);

  const abortAction = () => {
    dispatch(appointmentCancelRequest({ appointmentId: appointmentState?.id }));
    navigate('/patient/medicList');
  };

  return (
    <section className="appointment-steps">
      <div className="title-section">
        <div className="steps">
          <div className="icon-wrapper" onClick={() => navigate(-1)}>
            <CustomIcon
              className="custom-icon"
              color={Colors.darkGrey}
              size="24"
              icon="Left_arrow"
            />
          </div>
          {stepString}
          <div className="cancel-button">
            <button type="button" onClick={abortAction}>
              {t('abort')}
            </button>
          </div>
        </div>
        <h1>
          {t('documents')}
          <br />
          {user.fullNameWithTitle}
        </h1>
      </div>
      <form>
        <FileUploadComponent
          onFileSubmitted={submitNewFiles}
          fileTypes={fileTypes}
          onDelete={() => {}}
        />
        <SelectDocumentComponent
          medicalRecords={Utils.makeSectionListFormat(medicalRecords, 'categoryName')}
          sharedFiles={sharedFiles}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
        />
      </form>
      <div className="sticky-btn-bottom">
        <button
          type="button"
          className="orange-button"
          onClick={doSendDocument}
          disabled={!!isLoading}
        >
          {getNextButtonText()}
        </button>
      </div>
    </section>
  );
};

export default PatientAppointmentMedicalDocuments;
