import i18next from 'i18next';

export const possibleKinshipSelector = (response: any[]) => {
  return response.map((kinship) => {
    return {
      id: kinship?.id,
      name: kinship.translations.find((r) => r.locale === i18next.language)?.name ?? '',
      gender: kinship?.gender,
    };
  });
};
