import 'doctor/styles/account-assistance.css';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { useDispatch } from 'react-redux';
import { supportFormRequest } from 'shared/redux/actions/SupportActions';
import Alerts from 'shared/components/Alerts';
import { useTranslation } from 'react-i18next';

const DoctorAccountAssistanceBlock = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [showModalContact, setModalContactShow] = useState(false);
  const modalContactClose = () => {
    setModalContactShow(false);
    setSubject('');
    setContent('');
  };
  const modalContactShow = () => setModalContactShow(true);
  const onPress = () => {
    if (!subject || !content) {
      Alerts.simpleAlert(`${t('error')}`, `${t('addressFieldMandatory')}`);
      return;
    }
    dispatch(supportFormRequest({ subject, content }));
    modalContactClose();
  };
  return (
    <div className="account-assistance">
      <div className="title-section">
        <h2>{t('settings.support')}</h2>
      </div>
      <div className="contact">
        <div className="subtitle">{t('contact')}</div>
        <button type="button" className="white-button" onClick={modalContactShow}>
          {t('support.supportForm')}
          <CustomIcon
            className="custom-icon"
            color={Colors.darkGrey}
            size="24"
            icon="Right_pointer"
          />
        </button>
      </div>
      <div className="information">
        <div className="subtitle">{t('usefulInfo')}</div>
        <Link to="/doctor/about-us" className="white-button">
          {t('aboutUs')}
          <CustomIcon
            className="custom-icon"
            color={Colors.darkGrey}
            size="24"
            icon="Right_pointer"
          />
        </Link>
        <ListGroup>
          <ListGroup.Item>
            <Link to="/support/terms-and-conditions">
              {t('support.terms')}
              <CustomIcon
                className="custom-icon"
                color={Colors.darkGrey}
                size="24"
                icon="Right_pointer"
              />
            </Link>
          </ListGroup.Item>
          <ListGroup.Item>
            <Link to="/doctor/support/stripe-terms-and-conditions">
              {t('support.stripeTerms')}
              <CustomIcon
                className="custom-icon"
                color={Colors.darkGrey}
                size="24"
                icon="Right_pointer"
              />
            </Link>
          </ListGroup.Item>
          <ListGroup.Item>
            <Link to="/support/privacy-policy">
              {t('support.policy')}
              <CustomIcon
                className="custom-icon"
                color={Colors.darkGrey}
                size="24"
                icon="Right_pointer"
              />
            </Link>
          </ListGroup.Item>
        </ListGroup>
      </div>

      <Modal
        show={showModalContact}
        onHide={modalContactClose}
        animation
        centered
        className="modal-contact medic-modal"
      >
        <Modal.Header closeButton closeLabel={t('close2')}>
          <Modal.Title>{t('contact')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="info-message">
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="24"
              icon="Warning_2"
            />
            {t('contactText')}
          </div>
          <form>
            <div className="form-input">
              <input
                autoComplete="off"
                id="subject"
                name="subject"
                type="text"
                className="form-control"
                placeholder=" "
                onChange={(event) => setSubject(event.target.value)}
              />
              <label htmlFor="subject">{t('support.subject')}</label>
            </div>
            <div className="form-textarea">
              <textarea
                id="message"
                name="message"
                className="form-control"
                placeholder=" "
                rows={5}
                onChange={(event) => setContent(event.target.value)}
              />
              <label htmlFor="message">{t('support.message')}</label>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="green-button" onClick={onPress}>
            {t('send')}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DoctorAccountAssistanceBlock;
