import { AnyAction } from 'redux';
import {
  CONTACT_US_ERROR,
  CONTACT_US_REQUEST,
  CONTACT_US_SUCCESS,
  SUPPORT_FORM_ERROR,
  SUPPORT_FORM_REQUEST,
  SUPPORT_FORM_SUCCESS,
} from 'shared/redux/types/SupportTypes';

export const supportFormRequest = (payload: any): AnyAction => ({
  type: SUPPORT_FORM_REQUEST,
  payload,
});

export const supportFormSuccess = (payload: any): AnyAction => ({
  type: SUPPORT_FORM_SUCCESS,
  payload,
});

export const supportFormError = (payload: any): AnyAction => ({
  type: SUPPORT_FORM_ERROR,
  payload,
});

export const contactUsRequest = (payload: any): AnyAction => ({
  type: CONTACT_US_REQUEST,
  payload,
});

export const contactUsSuccess = (payload: any): AnyAction => ({
  type: CONTACT_US_SUCCESS,
  payload,
});

export const contactUsError = (payload: any): AnyAction => ({
  type: CONTACT_US_ERROR,
  payload,
});
