import 'patient/styles/auth.css';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { requestLogout, resendTokenRequest } from 'shared/redux/actions/AuthActions';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { useNavigate } from 'react-router-dom';
import { isDoctor } from '../../services/EnvService';

const ResendConfirmationToken = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const resendToken = (e) => {
    e.preventDefault();
    dispatch(resendTokenRequest({ email }));
  };

  const onLogOut = (e) => {
    e.preventDefault();
    dispatch(requestLogout({}));
    setTimeout(() => {
      navigate('/');
    }, 500);
  };

  return (
    <section className="account-activation">
      <form onSubmit={resendToken}>
        <div className="title-section">
          <h1>{t('emailConfirmation')}</h1>
        </div>

        <div className="form-input mb-2">
          <input
            type="text"
            id="email"
            name="email"
            className="form-control"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            placeholder=""
          />
          <label htmlFor="token">{t('email')}</label>
        </div>

        <button type="submit" className={isDoctor() ? 'green-button mb-3' : 'orange-button mb-3'}>
          {t('didNotGetToken')}
        </button>

        <div className="cancel-button" onClick={onLogOut}>
          <button type="button">{t('abort')}</button>
        </div>
      </form>
    </section>
  );
};

export default ResendConfirmationToken;
