import { AnyAction } from 'redux';
import {
  GET_TERMS_REQUEST,
  GET_TERMS_ERROR,
  GET_TERMS_SUCCESS,
  GET_STRIPE_TERMS_SUCCESS,
  GET_TWILIO_TERMS_SUCCESS,
  GET_STRIPE_TERMS_ERROR,
  GET_TWILIO_TERMS_ERROR,
  GET_STRIPE_TERMS_REQUEST,
  GET_TWILIO_TERMS_REQUEST,
  GET_PRIVACY_POLICY_ERROR,
  GET_PRIVACY_POLICY_REQUEST,
  GET_PRIVACY_POLICY_SUCCESS,
  GET_ABOUT_US_REQUEST,
  GET_ABOUT_US_ERROR,
  GET_ABOUT_US_SUCCESS,
  GET_GDPR_REQUEST,
  GET_GDPR_ERROR,
  GET_GDPR_SUCCESS,
} from 'shared/redux/types/StaticPagesTypes';

export const getAboutUsRequest = (payload: any): AnyAction => ({
  type: GET_ABOUT_US_REQUEST,
  payload,
});

export const getAboutUsSuccess = (payload: any): AnyAction => ({
  type: GET_ABOUT_US_SUCCESS,
  payload,
});

export const getAboutUsError = (payload: any): AnyAction => ({
  type: GET_ABOUT_US_ERROR,
  payload,
});

export const getPrivacyPolicyRequest = (payload: any): AnyAction => ({
  type: GET_PRIVACY_POLICY_REQUEST,
  payload,
});

export const getPrivacyPolicySuccess = (payload: any): AnyAction => ({
  type: GET_PRIVACY_POLICY_SUCCESS,
  payload,
});

export const getPrivacyPolicyError = (payload: any): AnyAction => ({
  type: GET_PRIVACY_POLICY_ERROR,
  payload,
});

export const getTermsRequest = (payload: any): AnyAction => ({
  type: GET_TERMS_REQUEST,
  payload,
});

export const getTermsSuccess = (payload: any): AnyAction => ({
  type: GET_TERMS_SUCCESS,
  payload,
});

export const getTermsError = (payload: any): AnyAction => ({
  type: GET_TERMS_ERROR,
  payload,
});

export const getStripeTermsRequest = (payload: any): AnyAction => ({
  type: GET_STRIPE_TERMS_REQUEST,
  payload,
});

export const getStripeTermsSuccess = (payload: any): AnyAction => ({
  type: GET_STRIPE_TERMS_SUCCESS,
  payload,
});

export const getStripeTermsError = (payload: any): AnyAction => ({
  type: GET_STRIPE_TERMS_ERROR,
  payload,
});

export const getTwilioTermsRequest = (payload: any): AnyAction => ({
  type: GET_TWILIO_TERMS_REQUEST,
  payload,
});

export const getTwilioTermsSuccess = (payload: any): AnyAction => ({
  type: GET_TWILIO_TERMS_SUCCESS,
  payload,
});

export const getTwilioTermsError = (payload: any): AnyAction => ({
  type: GET_TWILIO_TERMS_ERROR,
  payload,
});

export const getGDPRRequest = (payload: any): AnyAction => ({
  type: GET_GDPR_REQUEST,
  payload,
});

export const getGDPRSuccess = (payload: any): AnyAction => ({
  type: GET_GDPR_SUCCESS,
  payload,
});

export const getGDPRError = (payload: any): AnyAction => ({
  type: GET_GDPR_ERROR,
  payload,
});
