import { AnyAction } from 'redux';
import {
  ADD_FAVOURITE_DOCTOR_ERROR,
  ADD_FAVOURITE_DOCTOR_REQUEST,
  ADD_FAVOURITE_DOCTOR_SUCCESS,
  GET_FAVOURITE_DOCTORS_ERROR,
  GET_FAVOURITE_DOCTORS_REQUEST,
  GET_FAVOURITE_DOCTORS_SUCCESS,
  REMOVE_FAVOURITE_DOCTOR_ERROR,
  REMOVE_FAVOURITE_DOCTOR_REQUEST,
  REMOVE_FAVOURITE_DOCTOR_SUCCESS,
} from 'shared/redux/types/FavouriteDoctorsTypes';

export const getFavouriteDoctorsRequest = (payload): AnyAction => ({
  type: GET_FAVOURITE_DOCTORS_REQUEST,
  payload,
});

export const getFavouriteDoctorsSuccess = (payload): AnyAction => ({
  type: GET_FAVOURITE_DOCTORS_SUCCESS,
  payload,
});

export const getFavouriteDoctorsError = (payload): AnyAction => ({
  type: GET_FAVOURITE_DOCTORS_ERROR,
  payload,
});

export const addFavouriteDoctorRequest = (payload): AnyAction => ({
  type: ADD_FAVOURITE_DOCTOR_REQUEST,
  payload,
});

export const addFavouriteDoctorSuccess = (payload): AnyAction => ({
  type: ADD_FAVOURITE_DOCTOR_SUCCESS,
  payload,
});

export const addFavouriteDoctorError = (payload): AnyAction => ({
  type: ADD_FAVOURITE_DOCTOR_ERROR,
  payload,
});

export const removeFavouriteDoctorRequest = (payload): AnyAction => ({
  type: REMOVE_FAVOURITE_DOCTOR_REQUEST,
  payload,
});

export const removeFavouriteDoctorSuccess = (payload): AnyAction => ({
  type: REMOVE_FAVOURITE_DOCTOR_SUCCESS,
  payload,
});

export const removeFavouriteDoctorError = (payload): AnyAction => ({
  type: REMOVE_FAVOURITE_DOCTOR_ERROR,
  payload,
});
