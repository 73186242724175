import 'patient/styles/appointment-steps.css';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import Utils from 'shared/modules/Utils';
import { IPickedFiles } from 'shared/Interfaces/DocumentsInterfaces';
import { Modal } from 'react-bootstrap';
import Alerts from 'shared/components/Alerts';
import { patientAddMedicalRecordRequest } from 'shared/redux/actions/PatientProfileActions';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const AddMedicalRecordModalComponent = ({ userId, fileTypes, showUploadingModal }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [modalDisplayed, setModalDisplayed] = useState(false);
  const [files, setFiles] = useState<IPickedFiles>([]);
  const [title, setTitle] = useState<string>('');
  const [category, setCategory] = useState<number | null | undefined>(undefined);
  const [investigationDate, setInvestigationDate] = useState<string>('');
  const showModal = () => setModalDisplayed(true);
  const hideModal = () => setModalDisplayed(false);
  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleCategoryChange = (e) => setCategory(e.target.value);
  const handleInvestigationDateChange = (e) => setInvestigationDate(e.target.value);
  const handleFilesChange = (e) => {
    const { files: selectedFiles } = e.target;

    for (let i = 0; i < selectedFiles.length; i += 1) {
      const file = selectedFiles.item(i);

      if (files.every((existingFile) => existingFile.originalName !== file.name)) {
        setFiles((prevState) =>
          prevState.concat({
            originalName: file.name as string,
            fileData: file,
            mimeType: file.type as string,
            size: file.size as number,
          } as unknown as ConcatArray<never>),
        );
      }
    }
  };
  const handleFileRemove = (index) =>
    setFiles((prevState) => prevState.filter((r, i) => i !== index));
  const addMedicalRecord = () => {
    if (!files.length || !files[0]?.originalName?.length || investigationDate === '' || !category) {
      Alerts.simpleAlert(t('error'), t('allFieldsMandatory'));
      return;
    }

    const dateTimeStamp = investigationDate
      ? dayjs(investigationDate).valueOf()
      : dayjs().valueOf();

    if (dateTimeStamp > dayjs().valueOf()) {
      Alerts.simpleAlert(t('error'), t('alerts.futureDate'));
      return;
    }

    dispatch(
      patientAddMedicalRecordRequest({
        userId,
        data: {
          title,
          medicalRecordCategory: category,
          investigationDate: dayjs(investigationDate).format('YYYY-MM-DD 00:00:00'),
          filesToCreate: files.map((file) => {
            return {
              originalName: file.originalName,
              mimeType: file.mimeType,
              size: file.size,
            };
          }),
        },
        files: files.map((file) => {
          return file.fileData;
        }),
      }),
    );
    showUploadingModal(true);
    hideModal();
  };

  useEffect(() => {
    setFiles([]);
    setTitle('');
    setCategory(undefined);
    setInvestigationDate('');
  }, [modalDisplayed]);

  const fileMimeTypes = [
    'application/x-7z-compressed',
    'image/bmp',
    'image/dcm',
    'image/vnd-ms.dds',
    'image/dicom',
    'application/octet-stream',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/x-emf',
    'image/gif',
    'image/heic',
    'image/heic-sequence',
    'application/x-iso9660-image',
    'image/jpeg',
    'image/jp2',
    'application/pdf',
    'image/png',
    'application/postscript',
    'image/vnd.adobe.photoshop',
    'image/x-psd',
    'application/vnd.rar',
    'application/x-rar-compressed',
    'application/x-tar',
    'application/x-gtar',
    'image/tga',
    'image/x-tga',
    'image/x-targa',
    'image/tiff',
    'image/webp',
    'application/zip',
    'application/x-zip',
    'application/x-zip-compressed',
  ];
  const acceptString = fileMimeTypes.join(', ');

  return (
    <>
      <button type="button" className="white-button add-btn" onClick={showModal}>
        <CustomIcon className="custom-icon" color={Colors.patientColor} size="24" icon="Plus" />
        {t('addMedicalFile')}
      </button>

      <Modal
        show={modalDisplayed}
        onHide={hideModal}
        animation
        centered
        className="modal-add-document"
      >
        <Modal.Header closeButton closeLabel={t('close')}>
          <Modal.Title>{t('addMedicalFile')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="info-message gray">
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="24"
              icon="Warning_2"
            />
            {t('mustShare')}
          </div>

          <div className="files">
            <div className="subtitle">{t('files')}</div>
            <Form.Group controlId="medicalRecordFile" className="files-box">
              <Form.Label className="test">
                <CustomIcon
                  className="custom-icon"
                  color={Colors.darkGrey}
                  size="24"
                  icon="Upload"
                />
                {t('account.uploadDocument')}
              </Form.Label>
              <Form.Control
                type="file"
                multiple
                accept={acceptString}
                onChange={handleFilesChange}
              />
            </Form.Group>
            <div className="info-message mt-3">
              <CustomIcon
                className="custom-icon"
                color={Colors.mediumGrey}
                size="24"
                icon="Warning_2"
              />
              <div>
                {t('settings.acceptFileInfo')}
                <br />
                <br />
              </div>
            </div>

            {!!files.length && (
              <div className="mt-2">
                <div className="uploaded-box">
                  {files.map((file, index) => {
                    return (
                      <div className="uploaded-file" key={`file_${file.originalName}`}>
                        <div className="file-name">
                          <CustomIcon
                            className="custom-icon"
                            color={Colors.darkGrey}
                            size="24"
                            icon="Document"
                          />
                          <span>{file.originalName}</span>
                        </div>
                        <button type="button" onClick={() => handleFileRemove(index)}>
                          <CustomIcon
                            className="custom-icon"
                            color={Colors.mediumGrey}
                            size="24"
                            icon="Delete"
                          />
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>

          {!!files.length && (
            <div className="details">
              <div className="subtitle">{t('details')}</div>

              <div className="form-select-label mb-2">
                <select
                  required
                  id="type-document"
                  name="type-document"
                  className="custom-select"
                  value={category || ''}
                  onChange={handleCategoryChange}
                >
                  <option value="" disabled hidden>
                    {t('medicalProfile.documentType')}
                  </option>
                  {fileTypes.length &&
                    Utils.makeSelectOptions(fileTypes, 'name', 'id').map((rel) => {
                      return (
                        <option key={rel.value} value={rel.value}>
                          {Utils.capitalizeFirstLetter(rel.label)}
                        </option>
                      );
                    })}
                </select>
                <label htmlFor="type-document">{t('medicalProfile.documentType')}</label>
              </div>

              <div className="form-input">
                <input
                  type="date"
                  id="date-document"
                  name="date-document"
                  className="form-control"
                  placeholder=" "
                  max={dayjs().format('YYYY-MM-DD')}
                  value={investigationDate}
                  onChange={handleInvestigationDateChange}
                />
                <label htmlFor="date-document">{t('medicalProfile.investigationDate')}</label>
              </div>

              <div className="form-input">
                <input
                  type="text"
                  id="name-document"
                  name="name-document"
                  className="form-control"
                  placeholder=" "
                  value={title}
                  onChange={handleTitleChange}
                />
                <label htmlFor="name-document">{t('medicalProfile.documentName')}</label>
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <button type="button" className="orange-button" onClick={addMedicalRecord}>
            {t('add')}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddMedicalRecordModalComponent;
