import 'shared/styles/how-it-works.css';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import FooterSection from 'shared/components/FooterSection';
import HeaderMenu from 'shared/components/HeaderMenu';
import HeaderMenuMobile from 'shared/components/HeaderMenuMobile';
import CustomIcon from 'shared/components/CustomIcon';
import appPatientImage from 'shared/assets/images/about-us/aplicatie-pacient-image.png';
import openGraphImage from 'shared/assets/images/ringdoc_image_open_graph.png';
import Colors from 'shared/themes/Colors';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { Form, Modal } from 'react-bootstrap';
import Utils from '../modules/Utils';
import { scrollToTop } from '../modules/WindowUtils';

const HowItWorksPatient = () => {
  const { t } = useTranslation();
  const [showModalVideo, setModalVideoShow] = useState(false);
  const modalVideoClose = () => setModalVideoShow(false);
  const modalVideoShow = () => setModalVideoShow(true);
  const currentUrl = window.location.href;
  const baseUrl = window.location.origin;

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="how-it-works gradient-how-it-works-patient">
      <Helmet>
        <title>{t('howItWorks.patient.titlePage')}</title>
        <meta
          name="description"
          content="Afla cum functioneaza o aplicatia de telemedicina Ringdoc care iti permite sa faci consultatii medicale online rapid si sigur"
        />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:locale" content="ro_RO" />
        <meta property="og:locale:alternate" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={t('howItWorks.patient.titlePage')} />
        <meta
          property="og:description"
          content="Afla cum functioneaza o aplicatia de telemedicina Ringdoc care iti permite sa faci consultatii medicale online rapid si sigur"
        />
        <meta property="og:image" content={`${baseUrl}${openGraphImage}`} />
      </Helmet>

      {/* <HeaderMenuMobile /> */}

      <div className="container-fluid">
        <div className="row">
          <HeaderMenu />

          <main className="container">
            <section className="title">
              <h1>{t('howItWorks.patient.title')}</h1>
            </section>

            <section className="video">
              <div className="frame-video" onClick={modalVideoShow}>
                <div className="circle-play">
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.darkGrey}
                    size="70"
                    icon="Play"
                  />
                </div>
              </div>
              <p>{t('howItWorks.patient.description')}</p>

              <div className="box-number patient">
                <div className="circle one">
                  <span>1</span>
                </div>
                <div className="description">{t('howItWorks.patient.steps.step1')}</div>
              </div>

              <div className="box-number patient">
                <div className="circle two">
                  <span>2</span>
                </div>
                <div className="description">{t('howItWorks.patient.steps.step2')}</div>
              </div>

              <div className="box-number patient">
                <div className="circle three">
                  <span>3</span>
                </div>
                <div className="description">{t('howItWorks.patient.steps.step3')}</div>
              </div>
            </section>

            <section className="faq" id="faq">
              <h3>{t('howItWorks.patient.faq')}</h3>
              <Accordion defaultActiveKey="">
                <Accordion.Item eventKey="0">
                  <Accordion.Header as={Card.Header}>
                    {t('howItWorks.patient.faqs.title1')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>{t('howItWorks.patient.faqs.description1')}</p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header as={Card.Header}>
                    {t('howItWorks.patient.faqs.title2')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>{t('howItWorks.patient.faqs.description2')}</p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header as={Card.Header}>
                    {t('howItWorks.patient.faqs.title3')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>{t('howItWorks.patient.faqs.description3')}</p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header as={Card.Header}>
                    {t('howItWorks.patient.faqs.title4')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>{t('howItWorks.patient.faqs.description4')}</p>
                    <br />
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                  <Accordion.Header as={Card.Header}>
                    {t('howItWorks.patient.faqs.title5')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>{t('howItWorks.patient.faqs.description5')}</p>
                    <br />
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                  <Accordion.Header as={Card.Header}>
                    {t('howItWorks.patient.faqs.title6')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>{t('howItWorks.patient.faqs.description6')}</p>
                    <br />
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="6">
                  <Accordion.Header as={Card.Header}>
                    {t('howItWorks.patient.faqs.title7')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>{t('howItWorks.patient.faqs.description7')}</p>
                    <br />
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="7">
                  <Accordion.Header as={Card.Header}>
                    {t('howItWorks.patient.faqs.title8')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>{t('howItWorks.patient.faqs.description8')}</p>
                    <br />
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="8">
                  <Accordion.Header as={Card.Header}>
                    {t('howItWorks.patient.faqs.title9')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>{t('howItWorks.patient.faqs.description9')}</p>
                    <br />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </section>

            <section className="download-app patient">
              <div className="column">
                <h3>{t('howItWorks.patient.downloadAppPatient')}</h3>
                <div className="apps-button">
                  <a
                    target="_blank"
                    href={
                      Utils.isiPhone()
                        ? 'itms-apps://apps.apple.com/us/app/ringdoc/id1574488837'
                        : 'https://apps.apple.com/us/app/ringdoc/id1574488837'
                    }
                    rel="noreferrer"
                  >
                    <i className="app-store-icon cursor-pointer" />
                  </a>
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=ro.ascendnet.alodoc.patient.ro"
                    rel="noreferrer"
                  >
                    <i className="google-play-icon cursor-pointer" />
                  </a>
                </div>
              </div>
              <div className="column">
                <img src={appPatientImage} alt="Descarca Aplicatia" />
              </div>
            </section>

            <Modal
              show={showModalVideo}
              onHide={modalVideoClose}
              animation
              centered
              className="modal-video"
            >
              <Modal.Header closeButton closeLabel={t('close')} />

              <Modal.Body>
                <div className="iframe-responsive">
                  <iframe
                    src="https://www.youtube.com/embed/ruFQEahE8sE"
                    title="Cum functioneaza - Pacienti"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </Modal.Body>
            </Modal>
          </main>

          <FooterSection />
        </div>
      </div>
    </div>
  );
};

export default HowItWorksPatient;
