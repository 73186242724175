import { actionChannel, ActionPattern, call, fork, put, take } from 'redux-saga/effects';
import { Action } from 'redux';
import { FETCH_MEDICAL_RECORD_CATEGORY_REQUEST } from 'shared/redux/types/MedicalRecordCategoryTypes';
import { getMedicalRecordCategoryAPI } from 'shared/redux/api/MedicalRecordCategoryApi';
import {
  fetchMedicalRecordCategoryError,
  fetchMedicalRecordCategorySuccess,
} from 'shared/redux/actions/MedicalRecordCategoryActions';
import medicalRecordCategorySelector from 'shared/redux/selector/MedicalRecordCategorySelector';

function* getMedicalRecordCategories(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const medicalRecordCategoriesChannel = yield actionChannel(actionType);
  while (true) {
    yield take(medicalRecordCategoriesChannel);
    try {
      // @ts-ignore
      const response = yield call(getMedicalRecordCategoryAPI);
      yield put(fetchMedicalRecordCategorySuccess(medicalRecordCategorySelector(response.data)));
    } catch ({ message }) {
      yield put(fetchMedicalRecordCategoryError({ message }));
    }
  }
}

function* medicalRecordCategorySaga() {
  yield fork(getMedicalRecordCategories, FETCH_MEDICAL_RECORD_CATEGORY_REQUEST);
}

export default medicalRecordCategorySaga;
