import { AnyAction } from 'redux';
import {
  FETCH_TRANSLATIONS_REQUEST,
  FETCH_TRANSLATIONS_ERROR,
  FETCH_TRANSLATIONS_SUCCESS,
} from '../types/TranslationsTypes';

export const fetchTranslationsRequest = (payload): AnyAction => ({
  type: FETCH_TRANSLATIONS_REQUEST,
  payload,
});

export const fetchTranslationsSuccess = (payload): AnyAction => ({
  type: FETCH_TRANSLATIONS_SUCCESS,
  payload,
});

export const fetchTranslationsError = (payload): AnyAction => ({
  type: FETCH_TRANSLATIONS_ERROR,
  payload,
});
