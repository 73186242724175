import { shallowEqual, useSelector } from 'react-redux';
import {
  getAuthState,
  getPatientProfileState,
  getUserProfileState,
} from 'shared/redux/src/StatesGetter';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Avatar from 'shared/components/Avatar';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import StorageService from '../services/StorageService';

const HeaderMenuDoctor = () => {
  const { t, i18n } = useTranslation();
  const { isLoggedIn, isDoctor: isDoctorUser } = useSelector(getAuthState, shallowEqual);
  const homeClass = (() => {
    if (window.location.pathname === '/') return 'active';
    if (window.location.pathname.match(/\/landing/)) return 'active';
    return '';
  })();
  const goToBlog = () => window.open(`https://blog.ringdoc.ro`, '_self');
  const aboutClass = window.location.pathname.match(/\/about-us/) ? 'active' : '';
  const howItWorksClass = window.location.pathname.match(/\/how-it-works/) ? 'active' : '';
  const contactClass = window.location.pathname.match(/\/contact/) ? 'active' : '';

  const profileData = useSelector(
    isDoctorUser ? getUserProfileState : getPatientProfileState,
    shallowEqual,
  );

  const [isChangePlatform, setIsChangePlatform] = useState(false);

  StorageService.getData('IS_CHANGE_PLATFORM', '').then((result) => {
    setIsChangePlatform(result);
  });

  return (
    <div className="container-xxl">
      <div className="row">
        <div className="view landing-menu">
          <Link className="logo cursor-pointer" to="/doctor/home">
            <i className="ringdoc-medic-color-logo" />
          </Link>
          <div className="menu">
            <ul>
              <li className={homeClass}>
                <Link className="cursor-pointer" to="/doctor/landing">
                  {t('mainScreen')}
                </Link>
              </li>
              <li className={aboutClass}>
                <Link className="cursor-pointer" to="/doctor/about-us">
                  {t('aboutUs')}
                </Link>
              </li>
              <li className={howItWorksClass}>
                <Link className="cursor-pointer" to="/doctor/how-it-works">
                  {t('howItWork')}
                </Link>
              </li>
              <li>
                <div className="cursor-pointer" onClick={goToBlog}>
                  {t('blog')}
                </div>
              </li>
              <li className={contactClass}>
                <Link className="cursor-pointer" to="/doctor/contact">
                  {t('contact')}
                </Link>
              </li>
              {(!isLoggedIn || isChangePlatform) && (
                <li className="auth">
                  <Link to="/doctor/login">{t('patientApp.login')}</Link>
                </li>
              )}
              {isLoggedIn && isDoctorUser && (
                <li className="my-account">
                  <Link to="/doctor/home">
                    <div className="avatar-section">
                      <Avatar
                        size={18}
                        imageURL={isDoctorUser ? profileData?.pictureMedia : profileData?.imageUrl}
                        firstName={profileData?.firstName}
                        lastName={profileData?.lastName}
                      />
                    </div>
                    {isDoctorUser ? 'Dr.' : ''} {profileData?.lastName}
                    <div className={`${isDoctorUser ? 'bg-arrow green' : 'bg-arrow orange'}`}>
                      <CustomIcon
                        className="custom-icon"
                        color={Colors.white}
                        size="20"
                        icon="Right_pointer"
                      />
                    </div>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderMenuDoctor;
