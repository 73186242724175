import { AnyAction } from 'redux';
import {
  APPOINTMENT_END_CONSULTATION_ERROR,
  APPOINTMENT_END_CONSULTATION_REQUEST,
  APPOINTMENT_END_CONSULTATION_SUCCESS,
  APPOINTMENT_REPORT_ISSUE_ERROR,
  APPOINTMENT_REPORT_ISSUE_REQUEST,
  APPOINTMENT_REPORT_ISSUE_SUCCESS,
  GET_APPOINTMENT_SETTINGS_REQUEST,
  GET_APPOINTMENT_SETTINGS_SUCCESS,
  GET_APPOINTMENT_SETTINGS_ERROR,
  GET_CURRENT_APPOINTMENT_REQUEST,
  GET_CURRENT_APPOINTMENT_SUCCESS,
  GET_CURRENT_APPOINTMENT_ERROR,
} from 'shared/redux/types/AppointmentTypes';

export const appointmentEndConsultationRequest = (payload: any): AnyAction => ({
  type: APPOINTMENT_END_CONSULTATION_REQUEST,
  payload,
});

export const appointmentEndConsultationSuccess = (payload: any): AnyAction => ({
  type: APPOINTMENT_END_CONSULTATION_SUCCESS,
  payload,
});

export const appointmentEndConsultationError = (payload: any): AnyAction => ({
  type: APPOINTMENT_END_CONSULTATION_ERROR,
  payload,
});

export const appointmentReportIssueRequest = (payload: any): AnyAction => ({
  type: APPOINTMENT_REPORT_ISSUE_REQUEST,
  payload,
});

export const appointmentReportIssueSuccess = (payload: any): AnyAction => ({
  type: APPOINTMENT_REPORT_ISSUE_SUCCESS,
  payload,
});

export const appointmentReportIssueError = (payload: any): AnyAction => ({
  type: APPOINTMENT_REPORT_ISSUE_ERROR,
  payload,
});

export const getAppointmentSettingsRequest = (payload: any): AnyAction => ({
  type: GET_APPOINTMENT_SETTINGS_REQUEST,
  payload,
});

export const getAppointmentSettingsSuccess = (payload: any): AnyAction => ({
  type: GET_APPOINTMENT_SETTINGS_SUCCESS,
  payload,
});

export const getAppointmentSettingsError = (payload: any): AnyAction => ({
  type: GET_APPOINTMENT_SETTINGS_ERROR,
  payload,
});

export const getCurrentAppointmentRequest = (payload): AnyAction => ({
  type: GET_CURRENT_APPOINTMENT_REQUEST,
  payload,
});

export const getCurrentAppointmentSuccess = (payload): AnyAction => ({
  type: GET_CURRENT_APPOINTMENT_SUCCESS,
  payload,
});

export const getCurrentAppointmentError = (payload): AnyAction => ({
  type: GET_CURRENT_APPOINTMENT_ERROR,
  payload,
});
