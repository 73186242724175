import '../styles/wip.css';
import googleStore from 'shared/assets/images/google.png';
import appleStore from 'shared/assets/images/apple.png';
import NavigationService from '../services/NavigationService';

const WorkInProgress = () => {
  return (
    <div className="wip-bg">
      <section className="h-100 mentenante-page">
        <header className="container h-100">
          <div className="d-flex align-items-center justify-content-center h-100">
            <div className="d-flex flex-column">
              <div className="container wip-page">
                <div className="wrapper-container p-5">
                  <div
                    onClick={() => NavigationService.navigate('/')}
                    className="header-title cursor-pointer"
                  >
                    <i className="ringdoc-logo" />
                  </div>
                  <div className="main-content consultation-details-page">
                    <div className="recommendation add-recommendation text-center">
                      <h1 className="mb-3">Această secțiune va fi disponibilă în curând.</h1>
                      <h5 className="mb-5">
                        Între timp, poți descărca aplicația Ringdoc pe mobil!
                      </h5>
                      <div className="row w-75 align-items-center justify-content-center m-auto">
                        <a
                          className="w-auto"
                          target="_blank"
                          href="https://play.google.com/store/apps/details?id=ro.ascendnet.alodoc.patient.ro"
                          rel="noreferrer"
                        >
                          <img className="store-image" src={googleStore} alt="google store" />
                        </a>
                        <a
                          className="w-auto"
                          target="_blank"
                          href="https://apps.apple.com/us/app/ringdoc/id1574488837"
                          rel="noreferrer"
                        >
                          <img className="store-image" src={appleStore} alt="apple store" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </section>
    </div>
  );
};

export default WorkInProgress;
