import { useEffect, useState } from 'react';

const useProfilePictureUpload = (pictureUri = '') => {
  const [dataToUpload, setDataToUpload] = useState(undefined);
  const [uri, setUri] = useState(pictureUri);
  const [name, setName] = useState('');
  const [size, setSize] = useState(0);
  const [type, setType] = useState(null);

  const handleProfilePictureChange = (event) => {
    const picture = event.target.files[0] ?? {};

    if (!picture.name) {
      return;
    }

    setUri(URL.createObjectURL(picture));
    setName(picture.name);
    setSize(picture.size);
    setType(picture.type === 'image/jpg' ? 'image/jpeg' : picture.type);
    setDataToUpload(picture);
  };

  useEffect(() => {
    return () => {
      setDataToUpload(undefined);
      setUri('');
      setName('');
      setSize(0);
      setType(null);
    };
  }, []);

  return {
    dataToUpload,
    uri,
    name,
    size,
    type,
    setDataToUpload,
    setUri,
    setName,
    setSize,
    setType,
    handleProfilePictureChange,
  };
};

export default useProfilePictureUpload;
