import { default as ApiConstants } from 'shared/constants/ApiConstants.json';
import ApiUtils from 'shared/modules/ApiUtils';

const getDoctorPastAppointmentsAPI = ({ page, limit }) =>
  ApiUtils.getRequest(
    `${ApiConstants.DOCTOR_PAST_APPOINTMENTS_ENDPOINT}?page=${page}&limit=${limit}`,
  );

const getDoctorUpcomingAppointmentsAPI = ({ page, limit, date }) => {
  let url = `${ApiConstants.DOCTOR_UPCOMING_APPOINTMENTS_ENDPOINT}?page=${page}&limit=${limit}`;

  if (date) {
    url += `&date=${date}`;
  }

  return ApiUtils.getRequest(url);
};

const getAppointmentDetailsAPI = (payload) =>
  ApiUtils.getRequest(`${ApiConstants.DOCTOR_APPOINTMENT_DETAILS_ENDPOINT}/${payload?.id}`);

const cancelAppointmentByDoctorAPI = (payload) =>
  ApiUtils.putRequest(`${ApiConstants.USER_APPOINTMENT_ENDPOINT}/${payload?.id}/cancel`);

const cancelAllAppointmentsByDoctorAPI = (payload) =>
  ApiUtils.putRequest(`${ApiConstants.CANCEL_DOCTOR_APPOINTMENTS_ENDPOINT}`, payload);

const doctorAcceptsCallAPI = (payload) =>
  ApiUtils.putRequest(
    `${ApiConstants.DOCTOR_APPOINTMENT_ENDPOINT}/${payload.appointmentId}/doctor-accepts-call`,
    {
      timeStart: payload.timeStart,
      bidTime: payload.bidTime, // callSpecialist flow
    },
  );

const doctorRejectsCallAPI = (payload) =>
  ApiUtils.putRequest(
    `${ApiConstants.DOCTOR_APPOINTMENT_ENDPOINT}/${payload?.id}/doctor-rejects-call`,
  );

const addAppointmentConclusionAPI = (payload) =>
  ApiUtils.postRequest(
    `${ApiConstants.DOCTOR_APPOINTMENT_ENDPOINT}/${payload?.id}/conclusion`,
    payload.add,
  );

const addAppointmentPrescriptionAPI = (payload) =>
  ApiUtils.postRequest(
    `${ApiConstants.DOCTOR_APPOINTMENT_ENDPOINT}/${payload?.id}/prescription`,
    payload.add,
  );

const removeAppointmentPrescriptionAPI = (payload) =>
  ApiUtils.deleteRequest(
    `${ApiConstants.DOCTOR_APPOINTMENT_ENDPOINT}/${payload?.id}/remove-prescription`,
  );

const addAppointmentPrescriptionMedicationAPI = (payload) =>
  ApiUtils.postRequest(
    `${ApiConstants.DOCTOR_APPOINTMENT_PRESCRIPTION_ENDPOINT}/${payload?.id}/medication`,
    payload.add,
  );

const updateAppointmentPrescriptionMedicationAPI = (payload) =>
  ApiUtils.patchRequest(
    `${ApiConstants.DOCTOR_APPOINTMENT_PRESCRIPTION_MEDICATION_ENDPOINT}/${payload?.id}/update`,
    payload.add,
  );

const removeAppointmentPrescriptionMedicationAPI = (payload) =>
  ApiUtils.deleteRequest(
    `${ApiConstants.DOCTOR_APPOINTMENT_PRESCRIPTION_MEDICATION_ENDPOINT}/${payload?.id}`,
    payload.add,
  );

const getCallSpecialistInfoAPI = () =>
  ApiUtils.getRequest(ApiConstants.GET_CALL_SPECIALIST_INFO_ENDPOINT);

export {
  getDoctorPastAppointmentsAPI,
  getDoctorUpcomingAppointmentsAPI,
  getAppointmentDetailsAPI,
  cancelAppointmentByDoctorAPI,
  cancelAllAppointmentsByDoctorAPI,
  doctorAcceptsCallAPI,
  doctorRejectsCallAPI,
  addAppointmentConclusionAPI,
  addAppointmentPrescriptionAPI,
  getCallSpecialistInfoAPI,
  addAppointmentPrescriptionMedicationAPI,
  updateAppointmentPrescriptionMedicationAPI,
  removeAppointmentPrescriptionMedicationAPI,
  removeAppointmentPrescriptionAPI,
};
