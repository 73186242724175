import { storeDispatcher, storeStateGetter } from 'shared/redux/ReduxStore';
import { updateDeviceRegIdRequest } from 'shared/redux/actions/UserActions';
import { parseToJson } from 'shared/modules/JsonUtils';
import { stringHashCode } from 'shared/modules/StringUtils';
import { fetchPushesRequest, updatePushesRequest } from 'shared/redux/actions/NotificationActions';
import { updateSharedStateAction } from 'shared/redux/actions/UngroupedActions';
import Alerts from 'shared/components/Alerts';
import NavigationService from 'shared/services/NavigationService';
import {
  appointmentLocalStepsRequest,
  getPatientAppointmentDetailsRequest,
} from 'shared/redux/actions/PatientAppointmentActions';
import { locallyChooseVoucher } from 'shared/redux/actions/VouchersActions';
import firebase from 'firebase';
import {
  videoCallGetTokenRequest,
  videoCallGetTokenSuccess,
} from 'shared/redux/actions/VideoCallActions';
import LocalEventsService from 'shared/services/LocalEventsService';
import { getPatientMedicalRecordsRequest } from 'shared/redux/actions/PatientProfileActions';
import { fetchMedicalRecordCategoryRequest } from 'shared/redux/actions/MedicalRecordCategoryActions';
import dayjs from 'dayjs';
import { translatedFullDate } from './DateTimeUtils';
import { requestAppointment } from './PatientAppointmentUtils';

const handledNotifications: number[] = [];

const onTokenChanged = (newToken) => {
  storeDispatcher(updateDeviceRegIdRequest(newToken));
};

const doctorAcceptsCallPushNotification = (appointmentType, requestPayload) => {
  storeDispatcher(updateSharedStateAction({ isNotificationInProgress: false }));
  switch (appointmentType) {
    case 'freeTalk':
    case 'callNow':
      storeDispatcher(
        appointmentLocalStepsRequest({
          ...requestPayload,
          status: 'accepted',
          stickyStatus: 'WaitForDoctor',
        }),
      );
      NavigationService.navigate('/patient/appointment/waiting-screen');
      break;
    case 'emergency':
      storeDispatcher(
        appointmentLocalStepsRequest({
          ...requestPayload,
          status: 'accepted',
          stickyStatus: 'WaitForDoctor',
        }),
      );
      NavigationService.navigate('/patient/appointment/summary-payment');
      break;
    case 'callSpecialist':
      storeDispatcher(
        appointmentLocalStepsRequest({
          ...requestPayload,
          status: 'accepted',
          stickyStatus: 'WaitForCallSpecialistPayment',
        }),
      );
      NavigationService.navigate('/patient/call-specialist/medic-found');
      break;
    default:
      storeDispatcher(
        appointmentLocalStepsRequest({
          ...requestPayload,
          status: 'accepted',
          stickyStatus: '',
        }),
      );
      NavigationService.navigate('/patient/appointment/summary-payment');
  }
};

const onForegroundPushReceived = (pushPayload) => {
  const { isLoggedIn } = storeStateGetter('authState');

  if (!isLoggedIn) {
    return;
  }

  const { callStatus: appointmentCallStatus, type: appointmentType } =
    storeStateGetter('patientAppointmentState');
  const { data: customData, messageId } = pushPayload;
  const notificationId = stringHashCode(messageId);
  const {
    type: notificationType,
    metadata: rawMetadata = {},
    body,
    title,
    uuid: notificationUuid,
  } = customData;
  const metadata = parseToJson(rawMetadata);
  const { doctor = {}, patient = {}, appointment = {}, recommendation = {} } = metadata;
  const {
    id: doctorId,
    firstName: doctorFirstName,
    lastName: doctorLastName,
    fullNameWithTitle: doctorName,
    imgUrl: doctorImage,
    averageRating: doctorAverageRatingRating,
    countRating: doctorCountRating,
    isResident: doctorIsResident,
  } = doctor;
  const {
    id: patientId,
    firstName: patientFirstName,
    lastName: patientLastName,
    fullNameWithTitle: patientName = `${patientFirstName} ${patientLastName}`,
    pictureMedia: patientImage,
    birthDate: patientBirthDate,
    age: patientAge,
  } = patient;
  const {
    id: appointmentId = 0,
    timeStart: startTime = dayjs().format('YYYY-MM-DD HH:mm:ss'),
    timeEnd: endTime = dayjs().format('YYYY-MM-DD HH:mm:ss'),
    timeEndWithPause = dayjs().format('YYYY-MM-DD HH:mm:ss'),
    price,
    type,
    typeName,
    specialization = '',
  } = appointment;

  // Notification already handled
  if (handledNotifications.indexOf(notificationId) > -1) {
    return;
  }

  const { isNotificationInProgress } = storeStateGetter('sharedState');
  const priorityPushes = [
    'consultation_start_now_for_patient',
    'doctor_accepts_call',
    'a_doctor_won_call_specialist',
    'consultation_ended_by_doctor',
    'consultation_ended_by_doctor_free_talk',
    'expired_consultation_for_patient',
    'expired_call_specialist_search_doctor',
    'force_end_consultation_for_patient',
    'emergency_doctor_not_found',
    'free_talk_doctor_not_found',
    'call_specialist_doctor_not_found',
    'call_specialist_doctor_not_found_with_rec',
    'payment_failed_appointment_cancelled',
  ];
  const notIgnorablePushes = [
    'consultation_start_now_for_patient',
    'doctor_accepts_call',
    'a_doctor_won_call_specialist',
    'consultation_ended_by_doctor',
    'consultation_ended_by_doctor_free_talk',
    'expired_consultation_for_patient',
    'expired_call_specialist_search_doctor',
    'force_end_consultation_for_patient',
    'emergency_doctor_not_found',
    'free_talk_doctor_not_found',
    'call_specialist_doctor_not_found',
    'call_specialist_doctor_not_found_with_rec',
    'payment_failed_appointment_cancelled',
  ];

  // Don't proceed with this notification unless it has priority
  if (isNotificationInProgress && priorityPushes.indexOf(notificationType) === -1) {
    return;
  }

  // Don't proceed with this notification if you have an active appointment call and the notification can be ignored
  if (appointmentCallStatus === 'active' && notIgnorablePushes.indexOf(notificationType) === -1) {
    return;
  }

  // Notification is in progress
  storeDispatcher(updateSharedStateAction({ isNotificationInProgress: true }));
  storeDispatcher(fetchPushesRequest({}));

  if (appointmentId) {
    storeDispatcher(getPatientAppointmentDetailsRequest({ id: appointmentId }));
  }

  let requestPayload;

  try {
    requestPayload = {
      doctor: {
        ...doctor,
        imgUrl: doctorImage,
        firstName: doctorFirstName,
        lastName: doctorLastName,
        name: doctorName,
        specialization,
      },
      startTime,
      endTime,
      id: appointmentId,
      patient,
    };
  } catch {
    requestPayload = {
      id: appointmentId,
    };
  }

  handledNotifications.push(notificationId);
  switch (notificationType) {
    case 'emergency_doctor_not_found':
    case 'free_talk_doctor_not_found':
    case 'call_specialist_doctor_not_found':
      storeDispatcher(
        appointmentLocalStepsRequest({
          newAppointment: true,
        }),
      );
      storeDispatcher(locallyChooseVoucher({}));
      Alerts.actionAlert(title, body, 'Ok', () => {
        storeDispatcher(updateSharedStateAction({ isNotificationInProgress: false }));
      });
      NavigationService.navigate('/');
      break;
    case 'call_specialist_doctor_not_found_with_rec':
      storeDispatcher(
        appointmentLocalStepsRequest({
          newAppointment: true,
        }),
      );
      storeDispatcher(locallyChooseVoucher({}));
      Alerts.callSpecialistDoctorNotFoundAlert(
        patientFirstName,
        recommendation?.doctor_id,
        recommendation?.avatar,
        recommendation?.doctor_full_name,
        recommendation?.doctor_first_name,
        recommendation?.doctor_last_name,
        recommendation?.specialization_name,
        recommendation?.first_available_at
          ? translatedFullDate(recommendation?.first_available_at?.date, true, true)
          : '-',
        `${recommendation?.price} / ${recommendation?.duration}`,
        () => {
          requestAppointment('programmed', {
            forRequest: {
              timeStart: dayjs(recommendation?.first_available_at?.date).format('YYYY-MM-DD HH:mm'),
              doctor: `${recommendation?.doctor_id}`,
              specialization: `${recommendation?.specialization_id}`,
            },
            forUpdate: {
              date: dayjs(recommendation?.first_available_at?.date).format('YYYY-MM-DD'),
              doctor: {
                id: recommendation?.doctor_id,
                pictureMedia: recommendation?.avatar,
                firstName: recommendation?.doctor_first_name,
                lastName: recommendation?.doctor_last_name,
                fullNameWithTitle: recommendation?.doctor_full_name,
                specializationName: recommendation?.specialization_name,
              },
              specialization: `${recommendation?.specialization_id}`,
              startTime: dayjs(recommendation?.first_available_at?.date).format('YYYY-MM-DD HH:mm'),
              time: dayjs(recommendation?.first_available_at?.date).format('HH:mm'),
              scheduledAt: dayjs(recommendation?.first_available_at?.date).format(
                'YYYY-MM-DD HH:mm',
              ),
            },
          });
        },
      );
      storeDispatcher(updateSharedStateAction({ isNotificationInProgress: false }));
      NavigationService.navigate('/');
      break;
    case 'doctor_cancel_appointment':
      storeDispatcher(
        appointmentLocalStepsRequest({
          status: 'canceled',
          callStatus: 'terminated',
          stickyStatus: 'terminated',
        }),
      );
      storeDispatcher(locallyChooseVoucher({}));
      Alerts.actionAlert(title, body, 'Ok', () => {
        storeDispatcher(updateSharedStateAction({ isNotificationInProgress: false }));
      });
      break;
    case 'doctor_accepts_call':
    case 'a_doctor_won_call_specialist':
      doctorAcceptsCallPushNotification(appointmentType, requestPayload);
      break;
    case 'doctor_rejects_call':
      storeDispatcher(
        appointmentLocalStepsRequest({
          status: 'rejected',
          callStatus: 'terminated',
          stickyStatus: 'rejected',
        }),
      );
      storeDispatcher(locallyChooseVoucher({}));
      NavigationService.navigate('/');
      Alerts.actionAlert(title, body, 'Ok', () => {
        storeDispatcher(updateSharedStateAction({ isNotificationInProgress: false }));
      });
      break;
    case 'doctor_add_conclusion':
      storeDispatcher(updateSharedStateAction({ isNotificationInProgress: false }));
      Alerts.actionAlert(title, body, 'Ok', () => {
        storeDispatcher(
          appointmentLocalStepsRequest({
            ...requestPayload,
            status: 'ended',
            callStatus: 'terminated',
            stickyStatus: 'terminated',
          }),
        );
      });
      break;
    case 'doctor_added_conclusion':
      storeDispatcher(updateSharedStateAction({ isNotificationInProgress: false }));
      Alerts.simpleAlert(title, body);
      break;
    case 'force_end_consultation_for_patient':
    case 'consultation_ended_by_doctor_free_talk':
    case 'consultation_ended_by_doctor':
      storeDispatcher(
        appointmentLocalStepsRequest({
          ...requestPayload,
          status: 'needReview',
          callStatus: 'terminated',
          stickyStatus: 'terminated',
        }),
      );
      storeDispatcher(videoCallGetTokenSuccess({}));
      storeDispatcher(locallyChooseVoucher({}));
      LocalEventsService.emit('callEnded', { appointmentId, withNavigate: true });
      Alerts.actionAlert(title, body, 'Ok', () => {
        storeDispatcher(updateSharedStateAction({ isNotificationInProgress: false }));
      });
      break;
    case 'consultation_with_doctor_starts_soon':
      storeDispatcher(updateSharedStateAction({ isNotificationInProgress: false }));
      Alerts.actionAlert(title, body, 'Ok', () => {
        NavigationService.navigate('/patient/consultations', { state: 'upcoming' });
      });
      break;
    case 'consultation_start_now_for_patient':
      storeDispatcher(
        appointmentLocalStepsRequest({
          ...requestPayload,
          id: appointmentId,
          stickyStatus: 'started',
        }),
      );
      localStorage.setItem('Answer_Call', `false`);
      Alerts.answerCallAlert(
        title,
        doctorImage,
        doctorName,
        doctorFirstName,
        doctorLastName,
        specialization,
        () => {
          // Enter twilio video call
          storeDispatcher(videoCallGetTokenRequest({ appointmentId }));
          if (isLoggedIn && patient?.id) {
            storeDispatcher(getPatientMedicalRecordsRequest(patient.id));
          }
          storeDispatcher(fetchMedicalRecordCategoryRequest({}));
          storeDispatcher(updateSharedStateAction({ isNotificationInProgress: false }));
          localStorage.setItem('Answer_Call', `true`);
        },
      );
      break;
    case 'payment_failed_appointment_cancelled':
      NavigationService.navigate('/');
      storeDispatcher(appointmentLocalStepsRequest({ newAppointment: true }));
      storeDispatcher(locallyChooseVoucher({}));
      Alerts.actionAlert(title, body, 'Ok', () => {
        storeDispatcher(updateSharedStateAction({ isNotificationInProgress: false }));
      });
      break;
    case 'expired_consultation_for_patient':
    case 'expired_call_specialist_search_doctor':
      storeDispatcher(
        appointmentLocalStepsRequest({
          ...requestPayload,
          status: 'terminated',
          callStatus: 'terminated',
          stickyStatus: 'terminated',
          enableVideo: true,
          enableAudio: true,
          isInitialTime: true,
        }),
      );
      storeDispatcher(locallyChooseVoucher({}));
      storeDispatcher(videoCallGetTokenSuccess({}));
      NavigationService.navigate('/');
      Alerts.actionAlert(title, body, 'Ok', () => {
        storeDispatcher(updateSharedStateAction({ isNotificationInProgress: false }));
      });
      break;
    case 'reminder_review_appointment':
      Alerts.okCancelAlert(
        title,
        body,
        () => {
          NavigationService.navigate('/patient/rating');
        },
        () => {},
      );
      break;
    default:
      Alerts.actionAlert(title, body, 'Ok', () => {
        storeDispatcher(updateSharedStateAction({ isNotificationInProgress: false }));
      });
      break;
  }

  if (!notificationUuid) {
    return;
  }

  storeDispatcher(
    updatePushesRequest({
      forRequest: {
        notificationId: notificationUuid,
        status: 'answered',
      },
      forUpdate: null,
    }),
  );
};

const updateDeviceRegId = async () => {
  const deviceRegId = await firebase.getWebToken();
  setTimeout(() => {
    if (deviceRegId.deviceRegId) {
      console.log(
        `%cDeviceRegistrationId:\n${deviceRegId.deviceRegId}`,
        'color:#FF6A39; font-family:monospace; font-size: 15px;',
      );
      onTokenChanged(deviceRegId.deviceRegId);
    } else {
      window.location.href = '/support/permissions?lk=li';
    }
  }, 1000);
};

export default {
  onTokenChanged,
  onForegroundPushReceived,
  updateDeviceRegId,
};
