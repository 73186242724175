import 'doctor/styles/wallet.css';
import { useNavigate } from 'react-router-dom';
import { getEnv } from 'shared/services/EnvService';
import Utils from 'shared/modules/Utils';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import dayjs from 'dayjs';

const DoctorWalletTabIncome = ({
  incomeData,
  incomeCurrentPage,
  incomeNoOfPages,
  loadNextPageIncomeData,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const renderItemForIncome = (item) => {
    const {
      startDate = dayjs().format('YYYY-MM-DD HH:mm:ss'),
      endDate = dayjs().format('YYYY-MM-DD HH:mm:ss'),
      amount = 0,
      noOfAppointments = 0,
    } = item;
    const dateString = `${dayjs(startDate).format('DD')} - ${dayjs(endDate).format('DD MMM YYYY')}`;
    const onPressIncome = (e) => {
      e.preventDefault();
      navigate('/doctor/wallet/unfolded', {
        state: {
          type: 'income',
          data: {
            dateStart: startDate,
            dateEnd: endDate,
            amount: Utils.convertAmount(amount),
            dateString,
          },
        },
      });
    };
    return (
      <div key={item.id.toString()}>
        <div className="income-box">
          <div className="monthly-income cursor-pointer" onClick={onPressIncome}>
            <div className="subtitle">{dateString}</div>
            <div className="amount">{`${Utils.convertAmount(amount)} ${getEnv('CURRENCY')}`}</div>
            <div className="estimate">
              {t('noOfAppointments')}: {noOfAppointments}
            </div>
            <CustomIcon
              className="custom-icon arrow"
              color={Colors.darkGrey}
              size="24"
              icon="Right_pointer"
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="income-wrapper">
      <InfiniteScroll
        dataLength={incomeData.length}
        next={loadNextPageIncomeData}
        hasMore={incomeCurrentPage <= incomeNoOfPages}
        loader={<LoadingSpinner isLoading />}
        className="income-wrapper"
      >
        {!incomeData.length && <div className="empty-container">{t('wallet.noTransactions')}</div>}

        {incomeData.map((income) => renderItemForIncome(income))}
      </InfiniteScroll>
    </div>
  );
};

export default DoctorWalletTabIncome;
