import React, { useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CustomIcon from '../../../shared/components/CustomIcon';
import Colors from '../../../shared/themes/Colors';
import {
  getDoctorAppointmentDetailsState,
  getPrescriptionState,
} from '../../../shared/redux/src/StatesGetter';
import Alerts from '../../../shared/components/Alerts';
import {
  addAppointmentPrescriptionRequest,
  getAppointmentDetailsRequest,
  removeAppointmentPrescriptionMedicationRequest,
  removeAppointmentPrescriptionRequest,
  updateAppointmentPrescriptionMedicationRequest,
} from '../../../shared/redux/actions/DoctorAppointmentsActions';
import { getEnv } from '../../../shared/services/EnvService';

const Prescription = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const { prescription } = useSelector(getDoctorAppointmentDetailsState, shallowEqual);
  const { patient } = useSelector(getDoctorAppointmentDetailsState, shallowEqual);
  const { prescription: localPrescription } = useSelector(getPrescriptionState, shallowEqual);

  // eslint-disable-next-line no-nested-ternary
  const patientFirstNameValue = prescription
    ? // @ts-ignore
      prescription?.patient_first_name
      ? // @ts-ignore
        prescription?.patient_first_name
      : patient.firstName
    : patient.firstName;
  const [patientFirstName, setPatientFirstName] = useState(patientFirstNameValue);
  // eslint-disable-next-line no-nested-ternary
  const patientLastNameValue = prescription
    ? // @ts-ignore
      prescription?.patient_last_name
      ? // @ts-ignore
        prescription?.patient_last_name
      : patient.lastName
    : patient.lastName;
  const [patientLastName, setPatientLastName] = useState(patientLastNameValue);
  // eslint-disable-next-line no-nested-ternary
  const patientCNPValue = prescription
    ? // @ts-ignore
      prescription?.numeric_personal_code
      ? // @ts-ignore
        prescription?.numeric_personal_code
      : patient.numericPersonalCode
    : patient.numericPersonalCode;

  const [patientCNP, setPatientCNP] = useState(patientCNPValue ?? '');

  const {
    doctor,
    serverStatus,
    appointmentId,
    setAddPrescription,
    addNewMedication,
    setAddNewMedication,
    makeAddPrescriptionFalse,
    removePrescription,
    savePrescriptionAddMedication,
    setCurrentMedication,
    currentMedication,
  } = props;
  // eslint-disable-next-line no-nested-ternary
  const medicalUnitValue = prescription
    ? // @ts-ignore
      prescription?.organization_name
      ? // @ts-ignore
        prescription?.organization_name
      : doctor.organizationName
    : doctor.organizationName;
  // const medicalUnitAddressValue = doctor.organizationAddress.address_as_string;
  const [medicalUnit, setMedicalUnit] = useState(medicalUnitValue);

  // eslint-disable-next-line no-nested-ternary
  const medicalUnitAddressValue = prescription
    ? // @ts-ignore
      prescription?.organization_address
      ? // @ts-ignore
        prescription?.organization_address
      : doctor.organizationAddress.address_as_string
    : doctor.organizationAddress.address_as_string;
  const [medicalUnitAddress, setMedicalUnitAddress] = useState(medicalUnitAddressValue);

  // eslint-disable-next-line no-nested-ternary
  const patientDiagnosisValue = prescription
    ? // @ts-ignore
      prescription?.diagnosis
      ? // @ts-ignore
        prescription?.diagnosis
      : ''
    : '';
  const [patientDiagnosis, setPatientDiagnosis] = useState(patientDiagnosisValue);

  // eslint-disable-next-line no-nested-ternary
  const patientDiagnosisCodeValue = prescription
    ? // @ts-ignore
      prescription?.diagnosis_code
      ? // @ts-ignore
        prescription?.diagnosis_code
      : ''
    : '';
  const [patientDiagnosisCode, setPatientDiagnosisCode] = useState(patientDiagnosisCodeValue);
  const [doRedirect, setDoRedirect] = useState(false);

  const doSavePrescription = () => {
    if (
      medicalUnit === '' ||
      medicalUnitAddress === '' ||
      patientFirstName === '' ||
      patientLastName === '' ||
      patientDiagnosis === '' ||
      patientDiagnosisCode === ''
    ) {
      Alerts.simpleAlert(t('error'), t('prescription.mandatoryFields'));
      return false;
    }
    const payloadToAdd = {
      organizationName: medicalUnit,
      organizationAddress: medicalUnitAddress,
      patientFirstName,
      patientLastName,
      numericPersonalCode: patientCNP,
      diagnosis: patientDiagnosis,
      diagnosisCode: patientDiagnosisCode,
      isCompleted: false,
    };

    dispatch(
      addAppointmentPrescriptionRequest({
        toAdd: payloadToAdd,
        id: appointmentId,
      }),
    );
    setTimeout(() => {
      dispatch(getAppointmentDetailsRequest({ id: appointmentId }));
    }, 2000);

    return true;
  };

  const addMedication = () => {
    localStorage.setItem('REDIRECT_MEDICATION', 'false');

    doSavePrescription();
    if (location.pathname.indexOf('/doctor/video-call') === 0) {
      savePrescriptionAddMedication();
    } else if (localPrescription?.id) {
      savePrescriptionAddMedication();
    }
  };

  const savePrescription = () => {
    localStorage.setItem('REDIRECT_MEDICATION', 'false');
    localStorage.setItem('SAVED_PRESCRIPTION', 'false');
    doSavePrescription();
    makeAddPrescriptionFalse();
  };

  const cancelPrescription = () => {
    Alerts.okCancelAlert(t('warning'), t('prescription.removePrescriptionConfirmation'), () => {
      setAddPrescription(false);
      setMedicalUnit('');
      setMedicalUnitAddress('');
      setPatientFirstName('');
      setPatientLastName('');
      setPatientCNP('');
      setPatientDiagnosis('');
      setPatientDiagnosisCode('');

      if (prescription) {
        dispatch(
          removeAppointmentPrescriptionRequest({
            id: appointmentId,
          }),
        );
      }

      removePrescription();
    });
  };

  const editCurrentMedication = (medication) => {
    if (location.pathname.indexOf('/doctor/final-consultation') === 0) {
      window.location.href = `/doctor/final-consultation/${prescription.id}/medication/${medication.id}`;
    } else if (location.pathname.indexOf('/doctor/consultation-details') === 0) {
      window.location.href = `/doctor/consultation-details/${prescription.id}/medication/${medication.id}`;
    } else {
      setCurrentMedication({
        id: medication.id,
        name: medication.name,
        concentration: medication.concentration,
        administration: medication.administration,
        quantity: medication.quantity,
        duration: medication.duration,
        type: medication.type,
      });
    }
  };

  const removeMedicationItem = (id) => {
    Alerts.okCancelAlert(t('warning'), t('prescription.removeMedicationConfirmation'), () => {
      dispatch(
        removeAppointmentPrescriptionMedicationRequest({
          id,
        }),
      );

      setTimeout(() => {
        dispatch(getAppointmentDetailsRequest({ id: appointmentId }));
      }, 1000);
    });
  };

  return (
    <>
      <div className="subtitle">{t('prescription.title')}</div>
      <div className="form-input">
        <input
          name="medicalUnit"
          id="medicalUnit"
          value={medicalUnit}
          className="form-control"
          placeholder=" "
          onChange={(event) => {
            setMedicalUnit(event.target.value);
          }}
        />
        <label htmlFor="medicalUnit">{t('prescription.medicalUnit')}*</label>
      </div>
      <div className="form-input">
        <input
          name="medicalUnitAddress"
          id="medicalUnitAddress"
          value={medicalUnitAddress}
          className="form-control"
          placeholder=" "
          onChange={(event) => {
            setMedicalUnitAddress(event.target.value);
          }}
        />
        <label htmlFor="medicalUnitAddress">{t('prescription.medicalUnitAddress')}*</label>
      </div>

      <div className="subtitle">{t('prescription.patientData')}</div>
      <div className="form-input">
        <input
          name="patientFirstName"
          id="patientFirstName"
          value={patientFirstName}
          className="form-control"
          placeholder=" "
          onChange={(event) => {
            setPatientFirstName(event.target.value);
          }}
        />
        <label htmlFor="patientFirstName">{t('prescription.firstName')}*</label>
      </div>
      <div className="form-input">
        <input
          name="patientLastName"
          id="patientLastName"
          value={patientLastName}
          className="form-control"
          placeholder=" "
          onChange={(event) => {
            setPatientLastName(event.target.value);
          }}
        />
        <label htmlFor="patientLastName">{t('prescription.lastName')}*</label>
      </div>
      <div className="form-input">
        <input
          name="patientCNP"
          id="patientCNP"
          value={patientCNP}
          className="form-control"
          placeholder=" "
          onChange={(event) => {
            setPatientCNP(event.target.value);
          }}
        />
        <label htmlFor="patientCNP">{t('prescription.personalCode')}</label>
      </div>
      <div className="form-input">
        <TextareaAutosize
          name="patientDiagnosis"
          id="patientDiagnosis"
          value={patientDiagnosis}
          className="form-control textarea-autoheight"
          placeholder=" "
          onChange={(event) => {
            setPatientDiagnosis(event.target.value);
          }}
          minRows={1}
        />
        <label htmlFor="patientDiagnosis">{t('prescription.diagnosis')}*</label>
      </div>
      <div className="form-input">
        <TextareaAutosize
          name="patientDiagnosisCode"
          id="patientDiagnosisCode"
          value={patientDiagnosisCode}
          className="form-control textarea-autoheight"
          placeholder=" "
          onChange={(event) => {
            setPatientDiagnosisCode(event.target.value);
          }}
          minRows={1}
        />
        <label htmlFor="patientDiagnosisCode">{t('prescription.diagnosisCode')}*</label>
      </div>

      {prescription?.medications.length > 0 ? (
        <div className="subtitle">{t('prescription.medication')}</div>
      ) : (
        <div className="subtitle">{t('prescription.medication')}</div>
      )}

      {prescription?.medications.length > 0 && (
        <div>
          {prescription?.medications.map((medication, index) => {
            return (
              <div key={medication.id} className="medication-section">
                <div className="specializations specializations-rates">
                  <div className="specialization-item">
                    <div className="header">
                      <div className="specialization-name">
                        {
                          // @ts-ignore
                          medication.name
                        }
                      </div>
                      <div className="medication-actions">
                        <div
                          className="custom-icon-edit"
                          onClick={() => {
                            editCurrentMedication(medication);
                            setAddNewMedication(true);
                          }}
                        >
                          <CustomIcon
                            className="custom-icon"
                            color={Colors.medicColor}
                            size="24"
                            icon="Edit"
                          />
                        </div>
                        <div
                          className="custom-icon-delete"
                          onClick={() => {
                            // @ts-ignore
                            return removeMedicationItem(medication.id);
                          }}
                        >
                          <CustomIcon
                            className="custom-icon"
                            color={Colors.darkGrey}
                            size="24"
                            icon="Delete"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      <button
        type="button"
        className="white-button add-medication-btn btn-add-medication"
        onClick={() => {
          addMedication();
        }}
      >
        <CustomIcon className="custom-icon" color={Colors.medicColor} size="24" icon="Plus" />
        {t('prescription.addMedication')}
      </button>

      <div className="row">
        <div className="col-6">
          <button
            type="button"
            className="green-button btn-send-conclusions"
            onClick={() => savePrescription()}
          >
            {t('prescription.savePrescription')}
          </button>
        </div>
        <div className="col-6">
          <button
            type="button"
            className="white-button btn-send-conclusions btn-cancel-prescription"
            onClick={() => cancelPrescription()}
          >
            {t('prescription.removePrescription')}
          </button>
        </div>
      </div>
    </>
  );
};

export default Prescription;
