import { AnyAction } from 'redux';
import { APPOINTMENT_END_CONSULTATION_SUCCESS } from 'shared/redux/types/AppointmentTypes';
import { TWILIO_TOKEN_SUCCESS } from 'shared/redux/types/TwilioTypes';
import {
  ACCEPTED_DOCTOR_CALLS_STACK_REQUEST,
  ADD_APPOINTMENT_CONCLUSION_SUCCESS,
  CANCEL_ALL_APPOINTMENTS_BY_DOCTOR_SUCCESS,
  CANCEL_APPOINTMENT_BY_DOCTOR_SUCCESS,
  DOCTOR_ACCEPTS_CALL_SUCCESS,
  DOCTOR_APPOINTMENT_UPDATES_REQUEST,
  DOCTOR_REJECTS_CALL_SUCCESS,
  FETCH_DOCTOR_PAST_APPOINTMENTS_SUCCESS,
  FETCH_DOCTOR_TODAY_APPOINTMENTS_SUCCESS,
  FETCH_DOCTOR_UPCOMING_APPOINTMENTS_SUCCESS,
  GET_APPOINTMENT_DETAILS_SUCCESS,
  GET_CALL_SPECIALIST_INFO_SUCCESS,
  REMOVE_DOCTOR_CALLS_STACK_REQUEST,
  UPDATE_DOCTOR_CALLS_STACK_REQUEST,
} from 'shared/redux/types/DoctorAppointmentsTypes';
import { antiDuplication } from 'shared/modules/ArrayUtils';

const DoctorPastAppointmentsReducer = (
  state = { data: [], currentPage: 1, noOfPages: undefined },
  action: AnyAction,
) => {
  switch (action.type) {
    case FETCH_DOCTOR_PAST_APPOINTMENTS_SUCCESS:
      return {
        data: action.payload.nextPage
          ? antiDuplication([...state.data, ...action.payload.data], 'id')
          : action.payload.data,
        noOfPages: action.payload.noOfPages,
        currentPage: action.payload.nextPage ? state.currentPage + 1 : 2,
      };
    default:
      return state;
  }
};

const DoctorUpcomingAppointmentsReducer = (
  state = { data: [], currentPage: 1, noOfPages: undefined },
  action: AnyAction,
) => {
  switch (action.type) {
    case FETCH_DOCTOR_UPCOMING_APPOINTMENTS_SUCCESS:
      return {
        data: action.payload.nextPage
          ? antiDuplication([...state.data, ...action.payload.data], 'id')
          : action.payload.data,
        noOfPages: action.payload.noOfPages,
        currentPage: action.payload.nextPage ? state.currentPage + 1 : 2,
      };
    default:
      return state;
  }
};

const DoctorTodayAppointmentsReducer = (
  state = {
    data: [],
    callNowAppointment: null,
    nextScheduledAppointment: null,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case FETCH_DOCTOR_TODAY_APPOINTMENTS_SUCCESS:
      return {
        data: action.payload.data,
        callNowAppointment: action.payload.callNowAppointment,
        nextScheduledAppointment: action.payload.nextScheduledAppointment,
      };
    default:
      return state;
  }
};

const DoctorAppointmentDetailsReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case GET_APPOINTMENT_DETAILS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const CancelAppointmentByDoctorReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case CANCEL_APPOINTMENT_BY_DOCTOR_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const CancelAllAppointmentsByDoctorReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case CANCEL_ALL_APPOINTMENTS_BY_DOCTOR_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const defaultState = {
  id: 0,
  status: null,
  type: null,
  step: 0,
  scheduledAt: null,
  specializationId: 0,
  payment: {
    isPayed: false,
  },
  doctor: {},
  patient: {},
  conclusion: '',
  callStatus: '',
  startTime: '',
  endTime: '',
  timeEndWithPause: '',
  enableAudio: true,
  enableVideo: true,
  isExtendable: true,
  showTimeBubble: false,
  isInitialTime: true,
  isOtherUserConnected: false,
  otherVideoEnabled: false,
  otherAudioEnabled: false,
};

const DoctorAppointmentReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case DOCTOR_ACCEPTS_CALL_SUCCESS:
      return { ...state, status: 'accepted' };
    case DOCTOR_REJECTS_CALL_SUCCESS:
      return { ...state, status: 'rejected', callStatus: 'terminated' };
    case ADD_APPOINTMENT_CONCLUSION_SUCCESS:
    case DOCTOR_APPOINTMENT_UPDATES_REQUEST:
      return { ...state, ...action.payload };
    case TWILIO_TOKEN_SUCCESS:
      if (action.payload.token) {
        return { ...state, callStatus: 'active' };
      }
      return state;
    case APPOINTMENT_END_CONSULTATION_SUCCESS:
      return { ...state, status: 'done', callStatus: 'semiEnded' };
    default:
      return state;
  }
};

const DoctorCallsStackReducer = (
  state = { data: [], acceptedCallSpecialistAppointments: [] as number[], missedCalls: 0 },
  action: AnyAction = (() => {}) as unknown as AnyAction,
) => {
  switch (action.type) {
    case ACCEPTED_DOCTOR_CALLS_STACK_REQUEST:
      return {
        ...state,
        acceptedCallSpecialistAppointments: [
          ...state.acceptedCallSpecialistAppointments,
          action.payload,
        ],
      };
    case UPDATE_DOCTOR_CALLS_STACK_REQUEST:
      if (!action.payload.length) {
        return { ...state, data: [], acceptedCallSpecialistAppointments: [] };
      }
      return { ...state, data: antiDuplication([...action.payload, ...state.data], 'id') };
    case REMOVE_DOCTOR_CALLS_STACK_REQUEST:
      return {
        ...state,
        data: state.data.filter((item: any) => item.id !== action.payload.id),
        acceptedCallSpecialistAppointments: state.acceptedCallSpecialistAppointments?.filter(
          (id: number) => id !== action.payload.id,
        ),
      };
    case GET_CALL_SPECIALIST_INFO_SUCCESS:
      return {
        ...state,
        missedCalls:
          Number(action.payload.lost_bid) +
          Number(action.payload.has_push_and_rejected) +
          Number(action.payload.has_push_didnt_bid),
      };
    default:
      return state;
  }
};

export {
  DoctorPastAppointmentsReducer,
  DoctorUpcomingAppointmentsReducer,
  DoctorTodayAppointmentsReducer,
  DoctorAppointmentDetailsReducer,
  CancelAppointmentByDoctorReducer,
  CancelAllAppointmentsByDoctorReducer,
  DoctorAppointmentReducer,
  DoctorCallsStackReducer,
};
