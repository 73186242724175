import { AnyAction } from 'redux';
import {
  REGISTER_ERROR,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  UPDATE_DEVICE_REG_ID_ERROR,
  UPDATE_DEVICE_REG_ID_REQUEST,
  UPDATE_DEVICE_REG_ID_SUCCESS,
} from 'shared/redux/types/UserTypes';

export const registerRequest = (payload: any): AnyAction => ({
  type: REGISTER_REQUEST,
  payload,
});

export const registerSuccess = (payload: any): AnyAction => ({
  type: REGISTER_SUCCESS,
  payload,
});

export const registerError = (payload: any): AnyAction => ({
  type: REGISTER_ERROR,
  payload,
});

export const updateDeviceRegIdRequest = (payload: any): AnyAction => ({
  type: UPDATE_DEVICE_REG_ID_REQUEST,
  payload,
});

export const updateDeviceRegIdSuccess = (payload: any): AnyAction => ({
  type: UPDATE_DEVICE_REG_ID_SUCCESS,
  payload,
});

export const updateDeviceRegIdError = (payload: any): AnyAction => ({
  type: UPDATE_DEVICE_REG_ID_ERROR,
  payload,
});
