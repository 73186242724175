import 'patient/styles/account-cards.css';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import CVVExampleImage from 'patient/assets/images/cvv-example-image.png';
import {
  createPaymentRequest,
  getPaymentMethodsRequest,
  savePaymentMethodRequest,
} from 'shared/redux/actions/PatientPaymentActions';
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import {
  getDoctorProfileState,
  getPaymentMethodsState,
  getUserProfileState,
} from 'shared/redux/src/StatesGetter';
import stripeElementOptions from 'shared/settings/StripeElementOptions';
import { appointmentCancelRequest } from 'shared/redux/actions/PatientAppointmentActions';
import Alerts from 'shared/components/Alerts';
import Utils from 'shared/modules/Utils';
import { useTranslation } from 'react-i18next';
import SecurePaymentIcon from 'shared/assets/icons/secure-payment-icon.svg';
import GAService from '../../shared/services/GAService';
import { createLoadingSelector } from '../../shared/redux/src/GenericSeletors';

const PaymentCardComponent = (props) => {
  const { t } = useTranslation();
  const { appointmentId, skippablePayment, appointmentType, priceString } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const elements = useElements();
  const stripe = useStripe();
  const [saveCard, setSaveCard] = useState(true);
  const { cards } = useSelector(getPaymentMethodsState, shallowEqual);
  const userProfile = useSelector(getUserProfileState, shallowEqual);
  const doctor = useSelector(getDoctorProfileState, shallowEqual);
  const loadingSelector = createLoadingSelector();
  const isLoading = useSelector(loadingSelector);

  const [selectedCard, setSelectedCard] = useState<Record<string, any>>({});
  const [checkedAsMainCard, setCheckedAsMainCard] = useState(false);
  const [cardHolderName, setCardHolderName] = useState('');
  const handleCardHolderName = (e) => setCardHolderName(e.target.value);
  const [showModalAddCard, setModalAddCardShow] = useState(false);
  const modalAddCardClose = () => setModalAddCardShow(false);
  const modalAddCardShow = () => setModalAddCardShow(true);
  const [showModalCards, setModalCardsShow] = useState(false);
  const selectedCardImage = Utils.solveImageLogoByType(selectedCard?.brand);
  const modalCardsClose = () => setModalCardsShow(false);
  const modalCardsShow = () => setModalCardsShow(true);
  const payLater = () =>
    Alerts.twoActionsAlert(
      '',
      t('alerts.payLater'),
      t('cancel'),
      () => {},
      t('yes'),
      () => {
        navigate('/patient/consultations', { state: 'upcoming' });
      },
    );
  const savePaymentMethod = async () => {
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardNumberElement);

    if (!card) {
      return;
    }

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card,
      billing_details: {
        name: cardHolderName,
      },
    });

    if (error) {
      throw new Error(error.message);
    }

    if (saveCard) {
      dispatch(
        savePaymentMethodRequest({
          forRequest: paymentMethod,
        }),
      );
    } else {
      setSelectedCard({
        id: paymentMethod.id,
        brand: paymentMethod?.card?.brand,
        last4: paymentMethod?.card?.last4,
        expiryMonth: paymentMethod?.card?.exp_month,
        expiryYear: paymentMethod?.card?.exp_year,
      });
      setCardHolderName('');
    }

    setModalAddCardShow(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!stripe || !elements || !selectedCard) {
      return;
    }

    GAService.event('web_payNow', { patient: userProfile.email, doctor: doctor.email });
    dispatch(
      createPaymentRequest({
        paymentType: 'PaymentMethod',
        appointmentId,
        paymentMethodId: selectedCard?.id,
        // paymentMethodId: 'pm_1Lc2MXHc1u8x8mteLc7idwYH',
        confirmPayment: stripe.confirmCardPayment,
      }),
    );
  };
  const handleSavePaymentMethod = async () => {
    try {
      await savePaymentMethod();
    } catch (error: any) {
      Alerts.simpleAlert(t('error'), error?.message);
    }
  };

  // const renderPaymentButtonText = () => {
  //   if (skippablePayment) {
  //     return t('payForConsultationOver24Hours');
  //   }
  //
  //   if (appointmentType === 'callNow') {
  //     return t('payNowAndCallTheDoctor');
  //   }
  //
  //   return t('payForConsultation');
  // };

  useEffect(() => {
    dispatch(getPaymentMethodsRequest({}));
  }, []);

  useEffect(() => {
    setSelectedCard(cards[0]);
    setCardHolderName('');
  }, [cards]);

  return (
    <>
      <div className="card-details">
        <div className="card-details-section-header">
          <div className="subtitle">{t('wallet.dataCard')}</div>
          <div className="green-label">
            <img src={SecurePaymentIcon} alt="Secure payment icon" />
            {t('securePayment')}
          </div>
        </div>
        <form>
          {!selectedCard ? (
            <>
              <div className="no-card">
                <div className="form-input StripeElementContainer">
                  <CardNumberElement id="number-card" options={stripeElementOptions} />
                  <label htmlFor="number-card">{t('wallet.numberCard')}</label>
                </div>

                <div className="row rowp5">
                  <div className="col-6 colp5">
                    <div className="form-input StripeElementContainer">
                      <CardExpiryElement id="expiration" options={stripeElementOptions} />
                      <label htmlFor="expiration">{t('wallet.llaa')}</label>
                    </div>
                  </div>

                  <div className="col-6 colp5">
                    <div className="form-input StripeElementContainer">
                      <CardCvcElement id="cvv" options={stripeElementOptions} />
                      <label htmlFor="cvv">CVC</label>
                    </div>
                  </div>
                </div>
                <div className="form-input">
                  <input
                    autoComplete="off"
                    id="name-card"
                    name="name-card"
                    type="text"
                    className="form-control"
                    placeholder=" "
                    value={cardHolderName}
                    onChange={handleCardHolderName}
                  />
                  <label htmlFor="name-card">{t('wallet.nameCard')}</label>
                </div>
                <div className="custom-control custom-checkbox orange-checkbox">
                  <input
                    className="custom-control-input"
                    onChange={() => setSaveCard(!saveCard)}
                    id="checkbox"
                    type="checkbox"
                    checked={saveCard}
                  />
                  <label htmlFor="checkbox" className="custom-control-label">
                    {t('saveCard')}
                  </label>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="saved-cards">
                <div className="card-box">
                  <div className="details">
                    {selectedCardImage ? (
                      <img src={selectedCardImage} alt={selectedCard?.brand} />
                    ) : null}

                    <div className="info">
                      <div className="number">•••• {selectedCard?.last4}</div>
                      <div className="date">
                        {`${selectedCard?.expiryMonth} / ${selectedCard?.expiryYear}`}
                      </div>
                    </div>
                  </div>
                  <button type="button" onClick={modalCardsShow}>
                    {t('edit')}
                  </button>
                </div>
              </div>

              <Modal
                show={showModalAddCard}
                onHide={modalAddCardClose}
                animation
                centered
                className="modal-add-card"
              >
                <Modal.Header closeButton closeLabel="Închide">
                  <Modal.Title>{t('wallet.addCard')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form>
                    <div className="form-input StripeElementContainer">
                      <CardNumberElement id="number-card" options={stripeElementOptions} />
                      <label htmlFor="number-card">{t('wallet.numberCard')}</label>
                    </div>

                    <div className="row rowp5">
                      <div className="col-6 colp5">
                        <div className="form-input StripeElementContainer">
                          <CardExpiryElement id="expiration" options={stripeElementOptions} />
                          <label htmlFor="expiration">{t('wallet.llaa')}</label>
                        </div>
                      </div>

                      <div className="col-6 colp5">
                        <div className="form-input StripeElementContainer">
                          <CardCvcElement id="cvv" options={stripeElementOptions} />
                          <label htmlFor="cvv">CVC</label>
                        </div>
                      </div>
                    </div>

                    <div className="form-input">
                      <input
                        autoComplete="off"
                        id="name-card"
                        name="name-card"
                        type="text"
                        className="form-control"
                        placeholder=" "
                        value={cardHolderName}
                        onChange={handleCardHolderName}
                      />
                      <label htmlFor="name-card">{t('wallet.nameCard')}</label>
                    </div>

                    <div className="custom-control custom-checkbox orange-checkbox">
                      <input
                        className="custom-control-input"
                        id="set_as_main_card"
                        type="checkbox"
                        checked={checkedAsMainCard}
                        onChange={() => setCheckedAsMainCard(!checkedAsMainCard)}
                      />
                      <label htmlFor="set_as_main_card" className="custom-control-label">
                        {t('wallet.mainCardCheckbox')}
                      </label>
                    </div>

                    <div className="info-message">
                      <CustomIcon
                        className="custom-icon"
                        color={Colors.mediumGrey}
                        size="24"
                        icon="Warning_2"
                      />
                      <div>
                        {t('wallet.cvcText')}
                        <img src={CVVExampleImage} alt="CVC Example" />
                      </div>
                    </div>
                  </form>
                </Modal.Body>
                <Modal.Footer>
                  <button type="button" className="orange-button" onClick={handleSavePaymentMethod}>
                    {t('wallet.addCard')}
                  </button>
                </Modal.Footer>
              </Modal>

              <Modal
                show={showModalCards}
                onHide={modalCardsClose}
                animation
                centered
                className="modal-cards"
              >
                <Modal.Header closeButton closeLabel={t('close')}>
                  <Modal.Title>{t('wallet.cards')}</Modal.Title>
                </Modal.Header>
                <Modal.Header className="subheader">
                  <button
                    type="button"
                    className="white-button"
                    onClick={(event) => {
                      modalAddCardShow();
                      modalCardsClose();
                    }}
                  >
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.darkGrey}
                      size="24"
                      icon="Plus"
                    />
                    {t('wallet.addCard')}
                  </button>
                </Modal.Header>
                <Modal.Body>
                  {cards.map((card) => {
                    const cardImage = Utils.solveImageLogoByType(card.brand);

                    return (
                      <div className="card-box" key={`box${card.id}`}>
                        <div className="custom-control custom-radio orange-radio align-center-radio active-label-orange">
                          <input
                            className="custom-control-input"
                            id={`card${card.id}`}
                            name="cards"
                            type="radio"
                            checked={card.id === selectedCard?.id}
                            onChange={() => setSelectedCard(card)}
                          />
                          <label htmlFor={`card${card.id}`} className="custom-control-label">
                            {cardImage ? <img src={cardImage} alt={card.brand} /> : null}

                            <div className="info">
                              <div className="title">•••• {card.last4}</div>
                              <div className="details">{`${card.expiryMonth} / ${card.expiryYear}`}</div>
                            </div>
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </Modal.Body>
                <Modal.Footer>
                  <button type="button" className="orange-button" onClick={modalCardsClose}>
                    {t('wallet.saveMethodPay')}
                  </button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </form>
      </div>
      <div className="row">
        {!selectedCard ? (
          <div className={skippablePayment ? 'col-md-12 mt-3' : 'col-12 mt-3'}>
            <button
              type="submit"
              className="orange-button mt-0"
              onClick={handleSavePaymentMethod}
              disabled={!!isLoading}
            >
              {t('wallet.addCard')}
            </button>
          </div>
        ) : (
          <div className={skippablePayment ? 'col-md-12 mt-3' : 'col-12 mt-3'}>
            <button
              type="submit"
              className="orange-button mt-0"
              onClick={handleSubmit}
              disabled={!!isLoading}
            >
              {t('payForConsultation')} · {priceString}
            </button>
          </div>
        )}
        {skippablePayment && (
          <div className="col-md-12 mt-3">
            <div className="cancel-button">
              <button type="button" onClick={payLater}>
                {t('skipPayment')}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentCardComponent;
