import { AnyAction } from 'redux';
import { FETCH_TRANSLATIONS_SUCCESS } from '../types/TranslationsTypes';

const TranslationsReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case FETCH_TRANSLATIONS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default TranslationsReducer;
