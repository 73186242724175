import { AnyAction } from 'redux';
import {
  FETCH_MEDICAL_RECORD_CATEGORY_REQUEST,
  FETCH_MEDICAL_RECORD_CATEGORY_ERROR,
  FETCH_MEDICAL_RECORD_CATEGORY_SUCCESS,
} from 'shared/redux/types/MedicalRecordCategoryTypes';

export const fetchMedicalRecordCategoryRequest = (payload: any): AnyAction => ({
  type: FETCH_MEDICAL_RECORD_CATEGORY_REQUEST,
  payload,
});

export const fetchMedicalRecordCategorySuccess = (payload: any): AnyAction => ({
  type: FETCH_MEDICAL_RECORD_CATEGORY_SUCCESS,
  payload,
});

export const fetchMedicalRecordCategoryError = (payload: any): AnyAction => ({
  type: FETCH_MEDICAL_RECORD_CATEGORY_ERROR,
  payload,
});
