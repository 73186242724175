import i18next from 'i18next';
import { getEnv } from './EnvService';

const solveTranslatedError = (errorCode) => {
  switch (errorCode) {
    case 'incorrect_number':
      return 'Numărul cardului introdus este incorect.';
    case 'invalid_number':
      return 'Numărul cardului introdus nu este un număr valid.';
    case 'invalid_expiry_month':
      return 'Luna de expirare a cardului este invalidă.';
    case 'invalid_expiry_year':
      return 'Anul de expirare al cardului este invalid.';
    case 'invalid_cvc':
      return 'Codul de securitate al cardului este invalid';
    case 'expired_card':
      return 'Cardul introdus este expirat.';
    case 'incorrect_cvc':
      return 'Codul de securitate al cardului este incorect.';
    case 'incorrect_zip':
      return 'Validarea codului poștal aferent cardului a eșuat.';
    case 'card_declined':
      return 'Cardul a fost respins.';
    case 'missing':
      return 'Cardul atașat nu aparține niciunui cont existent. Te rugăm să introduci acest card în profilul tău pentru a putea efectua plata.';
    case 'processing_error':
      return 'Am întâmpinat o eroare în timpul procesării cardului.';
    case 'rate_limit':
      return 'Ne pare rău, am întâmpinat o eroare în timpul procesării plății. Te rugăm să încerci mai târziu.';
    case 'default':
      return 'Ne pare rău, am întâmpinat o eroare în timpul procesării plății. Te rugăm să încerci mai târziu.';
    default:
      return 'Ne pare rău, am întâmpinat o eroare în timpul procesării plății. Te rugăm să încerci mai târziu.';
  }
};

const getErrorMessage = (errorCode) => {
  // const translatedError = solveTranslatedError(errorCode);
  const translatedError = i18next.t(`stripe.${errorCode}`);
  return translatedError?.length
    ? translatedError
    : 'Ne pare rău, am întâmpinat o eroare în timpul procesării plății. Te rugăm să încerci mai târziu.';
};

/**
 * @ref https://stripe.com/docs/api/tokens/create_card#create_card_token-card
 * @param cardHolderName
 * @param cardNumber
 * @param expirationYear
 * @param expirationMonth
 * @param cvc
 */
const initializeStripePayment = ({
  cardHolderName,
  cardNumber,
  expirationYear,
  expirationMonth,
  cvc,
}) => {
  let data = `card[number]=${cardNumber}`;
  data += `&card[exp_month]=${expirationMonth}`;
  data += `&card[exp_year]=${expirationYear}`;
  data += `&card[cvc]=${cvc}`;
  data += `&card[name]=${cardHolderName}`;

  return new Promise((resolve, reject) => {
    fetch(`${getEnv('STRIPE_API')}/tokens`, {
      method: 'POST',
      body: data,
      headers: {
        Authorization: `Bearer ${getEnv('STRIPE_PK_KEY')}`,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    }).then((response) => {
      response.json().then((responseData) => {
        if (response.ok) {
          return resolve(responseData);
        }
        const errorMessage = getErrorMessage(responseData.error?.code);
        return reject(new Error(errorMessage));
      });
    });
  });
};

/**
 * @see https://stripe.com/docs/api/payment_intents/confirm
 * @param confirmPayment
 * @param clientSecret
 * @param card
 * @param paymentToken
 */
const confirmPaymentIntentWithToken = ({ confirmPayment, clientSecret, card }) => {
  return new Promise((resolve, reject) => {
    confirmPayment(clientSecret, {
      payment_method: {
        card,
      },
    }).then((responseData) => {
      const { error, paymentIntent } = responseData;
      if (error) {
        const errorMessage = getErrorMessage(error?.code);
        return reject(new Error(errorMessage));
      }
      return resolve(paymentIntent);
    });
  });
};

/**
 * @see https://stripe.com/docs/api/payment_intents/confirm
 * @param confirmPayment
 * @param clientSecret
 * @param intentId
 * @param paymentMethodId
 */
const confirmPaymentIntentWithPaymentId = ({ confirmPayment, clientSecret, paymentMethodId }) => {
  return new Promise((resolve, reject) => {
    confirmPayment(clientSecret, {
      payment_method: paymentMethodId,
    }).then((responseData) => {
      const { error, paymentIntent } = responseData;
      if (error) {
        const errorMessage = getErrorMessage(error?.code);
        return reject(new Error(errorMessage));
      }
      return resolve(paymentIntent);
    });
  });
};

/**
 * @see I https://stripe.com/docs/api/payment_intents/create
 * @see https://stripe.com/docs/api/payment_methods/create
 * @param cardHolderName
 * @param cardNumber
 * @param expirationYear
 * @param expirationMonth
 * @param cvc
 */
const createPaymentMethod = ({
  cardHolderName,
  cardNumber,
  expirationYear,
  expirationMonth,
  cvc,
}) => {
  return new Promise((resolve, reject) => {
    let data = `type=card`;
    data += `&card[number]=${cardNumber}`;
    data += `&card[exp_month]=${expirationMonth}`;
    data += `&card[exp_year]=${expirationYear}`;
    data += `&card[cvc]=${cvc}`;
    data += `&billing_details[name]=${cardHolderName}`;
    fetch(`${getEnv('STRIPE_API')}/payment_methods`, {
      method: 'POST',
      body: data,
      headers: {
        Authorization: `Bearer ${getEnv('STRIPE_PK_KEY')}`,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    }).then((response) => {
      response.json().then((responseData) => {
        if (response.ok) {
          return resolve(responseData);
        }
        const errorMessage = getErrorMessage(responseData.error?.code);
        return reject(new Error(errorMessage));
      });
    });
  });
};

export default {
  initializeStripePayment,
  confirmPaymentIntentWithToken,
  confirmPaymentIntentWithPaymentId,
  createPaymentMethod,
};
