import LocalEventsService from './LocalEventsService';

const uploadToGCWithSignedUrl = async (
  url: string,
  fileMedia: {
    [x: string]: string | Blob;
    key: string | Blob;
    // eslint-disable-next-line camelcase
    success_action_status: string | Blob;
    acl: string | Blob;
    policy: string | Blob;
  },
  fileData: Blob,
) => {
  const myHeaders = new Headers();
  myHeaders.append('x-goog-acl', 'public-read');
  const formData = new FormData();
  formData.append('key', fileMedia.key);
  formData.append('success_action_status', fileMedia.success_action_status);
  formData.append('acl', fileMedia.acl);
  formData.append('x-goog-meta-id', fileMedia['x-goog-meta-id']);
  formData.append('x-goog-algorithm', fileMedia['x-goog-algorithm']);
  formData.append('x-goog-credential', fileMedia['x-goog-credential']);
  formData.append('x-goog-date', fileMedia['x-goog-date']);
  formData.append('x-goog-signature', fileMedia['x-goog-signature']);
  formData.append('policy', fileMedia.policy);
  formData.append('file', fileData);
  // eslint-disable-next-line consistent-return
  return new Promise((resolve, reject) => {
    try {
      const xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      const options = {
        method: 'POST',
        body: formData,
        headers: myHeaders,
      };
      xhr.upload.onprogress = () => {
        console.log('se incarca fisierele');
        LocalEventsService.emit('uploadInProgress', {
          status: true,
        });
      };
      fetch(url, options)
        .then((response) => {
          response
            .text()
            .then((data) => {
              console.warn(`this is upload log ${JSON.stringify(data)}`);
              return resolve(data);
            })
            .catch((responseError) => {
              console.warn(`apiError error ${JSON.stringify(responseError)}`);
            });
        })
        .catch((apiError) => {
          console.warn(`apiError error ${JSON.stringify(apiError)}`);
        });
      console.log('fisierele s-au incarcat');
      LocalEventsService.emit('uploadInProgress', {
        status: false,
      });
    } catch (error) {
      console.warn(`this is upload error ${JSON.stringify(error)}`);
      return reject(error);
    }
  });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { uploadToGCWithSignedUrl };
