import { AnyAction } from 'redux';
import {
  GET_DOCTOR_TIME_SLOTS_ERROR,
  GET_DOCTOR_TIME_SLOTS_REQUEST,
  GET_DOCTOR_TIME_SLOTS_SUCCESS,
  GET_SPECIALIZATION_DOCTORS_ERROR,
  GET_SPECIALIZATION_DOCTORS_REQUEST,
  GET_SPECIALIZATION_DOCTORS_SUCCESS,
  AVAILABLE_DOCTORS_ERROR,
  AVAILABLE_DOCTORS_REQUEST,
  AVAILABLE_DOCTORS_SUCCESS,
  PATIENT_LOCALLY_CHOOSE_SPECIALIZATION,
  GET_SORTING_OPTIONS_REQUEST,
  GET_SORTING_OPTIONS_SUCCESS,
  GET_SORTING_OPTIONS_ERROR,
} from 'shared/redux/types/DoctorTypes';

export const getSortingOptionsRequest = (payload): AnyAction => ({
  type: GET_SORTING_OPTIONS_REQUEST,
  payload,
});

export const getSortingOptionsSuccess = (payload): AnyAction => ({
  type: GET_SORTING_OPTIONS_SUCCESS,
  payload,
});

export const getSortingOptionsError = (payload): AnyAction => ({
  type: GET_SORTING_OPTIONS_ERROR,
  payload,
});

export const getSpecializationDoctorsRequest = (payload): AnyAction => ({
  type: GET_SPECIALIZATION_DOCTORS_REQUEST,
  payload,
});

export const getSpecializationDoctorsSuccess = (payload): AnyAction => ({
  type: GET_SPECIALIZATION_DOCTORS_SUCCESS,
  payload,
});

export const getSpecializationDoctorsError = (payload): AnyAction => ({
  type: GET_SPECIALIZATION_DOCTORS_ERROR,
  payload,
});

export const getDoctorTimeSlotsRequest = (payload): AnyAction => ({
  type: GET_DOCTOR_TIME_SLOTS_REQUEST,
  payload,
});

export const getDoctorTimeSlotsSuccess = (payload): AnyAction => ({
  type: GET_DOCTOR_TIME_SLOTS_SUCCESS,
  payload,
});

export const getDoctorTimeSlotsError = (payload): AnyAction => ({
  type: GET_DOCTOR_TIME_SLOTS_ERROR,
  payload,
});

export const getAvailableDoctorsRequest = (payload): AnyAction => ({
  type: AVAILABLE_DOCTORS_REQUEST,
  payload,
});

export const getAvailableDoctorsSuccess = (payload): AnyAction => ({
  type: AVAILABLE_DOCTORS_SUCCESS,
  payload,
});

export const getAvailableDoctorsError = (payload): AnyAction => ({
  type: AVAILABLE_DOCTORS_ERROR,
  payload,
});

export const patientLocallyChooseSpecialization = (payload): AnyAction => ({
  type: PATIENT_LOCALLY_CHOOSE_SPECIALIZATION,
  payload,
});
