import 'doctor/styles/auth.css';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { requestLogin } from 'shared/redux/actions/AuthActions';
import { useNavigate } from 'react-router-dom';
import Alerts from 'shared/components/Alerts';
import { getAuthState } from 'shared/redux/src/StatesGetter';
import SocialLogin from 'shared/components/SocialLogin';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Validators from '../../../shared/modules/Validators';
import { createLoadingSelector } from '../../../shared/redux/src/GenericSeletors';

const DoctorLogin = () => {
  const { t } = useTranslation();
  const loadingSelector = createLoadingSelector();
  const isLoading = useSelector(loadingSelector);

  const [showModalGDPR, setModalGDPRShow] = useState(false);
  const modalGDPRClose = () => setModalGDPRShow(false);
  const [iAgree, setIAgree] = useState(false);
  const [iAgree2, setIAgree2] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [requiredFieldsError, setRequiredFieldsError] = useState(false);
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const dispatchLogin = (type, data, unconfirmedModalAlert) => {
    dispatch(requestLogin({ type, data, unconfirmedModalAlert }));
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/doctor/home');
    }
  }, [isLoggedIn]);

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const onLogin = (e) => {
    e.preventDefault();
    if (!email || !password) {
      Alerts.simpleAlert(t('error'), t('allFieldsMandatory'));
      setRequiredFieldsError(true);
      return;
    }

    const isValidEmail = Validators.validateEmail(email);
    if (!isValidEmail) {
      Alerts.simpleAlert(t('error'), t('alerts.invalidEmail'));
      setRequiredFieldsError(true);
      return;
    }

    dispatchLogin('classic', { email, password }, true);
  };

  return (
    <section className="login">
      <form onSubmit={onLogin}>
        <div className="title-section">
          <div className="small-title-text">{t('doctorApp.welcome')}</div>
          <h1>{t('doctorApp.login')}</h1>
        </div>
        <div className="social-login">
          <SocialLogin />
        </div>
        <div className="or">{t('or')}</div>
        <div className="form-input">
          <input
            type="email"
            name="email"
            id="email"
            className={`form-control ${
              requiredFieldsError && (!email || !Validators.validateEmail(email)) && 'is-invalid'
            }`}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            placeholder=" "
          />
          <label htmlFor="email">{t('email')}</label>
        </div>
        <div className="password-eye">
          <input
            value={password}
            name="password"
            id="password"
            onChange={(event) => setPassword(event.target.value)}
            type={passwordShown ? 'text' : 'password'}
            className={`form-control ${requiredFieldsError && !password && 'is-invalid'}`}
            placeholder=" "
          />
          <label htmlFor="password">{t('password')}</label>
          <div className="eye" onClick={togglePasswordVisibility}>
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="24"
              icon={`${passwordShown ? 'Eye_off' : 'Eye'}`}
            />
          </div>
        </div>
        <button type="submit" className="green-button" disabled={!!isLoading}>
          {t('doctorApp.login')}
        </button>
        <div
          onClick={() => navigate('/doctor/forgot-password')}
          className="forgot-pass-btn cursor-pointer"
        >
          <CustomIcon
            className="custom-icon"
            color={Colors.darkGrey}
            size="18"
            icon="Password_unknown"
          />
          {t('forgotPassword')}
        </div>
        <hr />
        <button
          type="button"
          onClick={() => navigate('/doctor/register')}
          className="white-button position-relative doctor-create-account"
        >
          {`${t('doctorApp.noAccount')} ${t('doctorApp.createAccount')}`}
          <CustomIcon
            className="custom-icon"
            color={Colors.darkGrey}
            size="24"
            icon="Right_pointer"
          />
        </button>
      </form>

      <Modal
        show={showModalGDPR}
        onHide={modalGDPRClose}
        animation
        centered
        className="modal-gdpr medic-modal"
      >
        <Modal.Header closeButton closeLabel="Închideți">
          <Modal.Title>{t('dataProtection')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="custom-control custom-checkbox green-checkbox align-center-checkbox left-justify-content">
            <input
              className="custom-control-input"
              id="future_appointment"
              type="checkbox"
              checked={iAgree}
              onChange={() => setIAgree(!iAgree)}
            />
            <label htmlFor="future_appointment" className="custom-control-label">
              {t('iAgreeWith')}
              <a
                target="_blank"
                href="https://api.ringdoc.ro/ro/pages/termenii-si-conditiile"
                rel="noreferrer"
              >
                {t('termsAndConditions')}
              </a>
            </label>
          </div>
          <div className="custom-control custom-checkbox green-checkbox align-center-checkbox left-justify-content">
            <input
              className="custom-control-input"
              id="future_appointment2"
              type="checkbox"
              checked={iAgree2}
              onChange={() => setIAgree2(!iAgree2)}
            />
            <label htmlFor="future_appointment2" className="custom-control-label">
              {t('iAgreeWith')}
              <a
                target="_blank"
                href="https://api.ringdoc.ro/ro/pages/termenii-si-conditiile"
                rel="noreferrer"
              >
                {t('privacyTitle')}
              </a>
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="green-button">
            {t('confirm')}
          </button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default DoctorLogin;
