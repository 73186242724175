import { AnyAction } from 'redux';
import {
  APPOINTMENT_END_CONSULTATION_SUCCESS,
  GET_APPOINTMENT_SETTINGS_SUCCESS,
} from 'shared/redux/types/AppointmentTypes';

const defaultState = {
  id: 0,
  status: null,
  type: null,
  step: 0,
  scheduledAt: null,
  specializationId: 0,
  payment: {
    isPayed: false,
  },
  doctor: {},
  patient: {},
};

const SharedAppointmentReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case APPOINTMENT_END_CONSULTATION_SUCCESS:
      return { ...state, status: 'done' };
    default:
      return state;
  }
};

const AppointmentSettingsReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case GET_APPOINTMENT_SETTINGS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export { SharedAppointmentReducer, AppointmentSettingsReducer };
