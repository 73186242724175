import { default as ApiConstants } from 'shared/constants/ApiConstants.json';
import ApiUtils from 'shared/modules/ApiUtils';

const supportFormAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.postRequest(ApiConstants.SUPPORT_FORM_ENDPOINT, payload);

const contactUsAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.postRequest(ApiConstants.CONTACT_US_ENDPOINT, payload);

export { supportFormAPI, contactUsAPI };
