import { translatedFullDate, translatedMonthAndYear } from 'shared/modules/DateTimeUtils';
import i18next from 'i18next';
import { profileDetailsSelector } from 'shared/redux/selector/ProfileDetailsSelector';

const patientProfilePlaceholder = {
  id: 0,
  email: '',
  locale: i18next.language,
  accountStatus: '',
  fullNameWithTitle: '',
  firstName: '',
  lastName: '',
  numericPersonalCode: '',
  gender: '',
  rawGender: '',
  birthDate: null,
  phoneNumber: '',
  pictureMedia: null,
  userLanguages: [],
  weight: null,
  height: null,
  bloodSystemA: null,
  isSmoker: false,
  isAlcoholConsumer: false,
  knownAllergies: null,
  pastMedicalConditions: null,
  currentMedicalConditions: null,
  patientKinshipDiseases: [],
};

const patientProfileSelector = (userProfile) => {
  return { ...profileDetailsSelector(userProfile) };
};

const medicalRecordsSelector = (medicalRecords) => {
  if (!Array.isArray(medicalRecords) || !medicalRecords.length) {
    return [];
  }
  return medicalRecords.map((row) => {
    return {
      id: row?.id,
      title: row.title,
      categoryId: row.medical_record_category?.id,
      categoryName:
        row.medical_record_category.translations.find((r) => r.locale === i18next.language)?.name ??
        '',
      type:
        row.files_portfolio?.media_portfolios[0]?.media?.gcloud_upload_policy_v4?.fields.key
          ?.split('.')
          ?.pop() ?? '',
      files: row.files_portfolio?.media_portfolios.map((file) => {
        return {
          ...file,
          externalId: file.media?.id,
          type: file?.media?.gcloud_upload_policy_v4?.fields.key?.split('.')?.pop() ?? '',
        };
      }),
      createdAtFormatted: translatedMonthAndYear(row.created_at),
      createdAtFullFormatted: translatedFullDate(row.created_at),
      createdAt: row.created_at,
      investigationDate: row.investigation_date,
    };
  });
};

export { patientProfileSelector, patientProfilePlaceholder, medicalRecordsSelector };
