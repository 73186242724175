import 'doctor/styles/account-profile.css';
import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import Avatar from 'shared/components/Avatar';
import {
  getCountriesState,
  getTitlesState,
  getUserProfileState,
} from 'shared/redux/src/StatesGetter';
import {
  fetchTitlesRequest,
  fetchUserProfileRequest,
  patchUserProfileRequest,
} from 'shared/redux/actions/UserProfileActions';
import { getPossibleKinshipRequest } from 'shared/redux/actions/KinshipActions';
import Alerts from 'shared/components/Alerts';
import { getEnv } from 'shared/services/EnvService';
import { useTranslation } from 'react-i18next';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import Utils from '../../../shared/modules/Utils';

const DoctorAccountProfileTabPersonal = ({ type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profileData = useSelector(getUserProfileState, shallowEqual);
  const titles = useSelector(getTitlesState, shallowEqual);
  const [address, setAddress] = useState(profileData.address?.address_as_string);
  const [rawAddress, setRawAddress] = useState<any>('');
  const [titleId, setTitleId] = useState(profileData?.titleId);
  const [firstName, setFirstName] = useState(profileData.firstName);
  const [lastName, setLastName] = useState(profileData.lastName);
  const [email, setEmail] = useState(profileData.email);
  const [phoneNumber, setPhoneNumber] = useState(profileData.phoneNumber);
  const [code, setCode] = useState(profileData.code);
  const [cnp, setCnp] = useState(profileData.numericPersonalCode);
  const [canUseSeal, setCanUseSeal] = useState(profileData.canUseSeal);
  const [profilePicture, setProfilePicture] = useState(profileData.pictureMedia);
  const [pictureName, setPictureName] = useState('');
  const [pictureSize, setPictureSize] = useState(0);
  const [pictureType, setPictureType] = useState(null);
  const [pictureDataToUpload, setPictureDataToUpload] = useState(undefined);
  const handleProfilePictureChange = (e) => {
    const picture = e.target.files[0] ?? {};
    if (!picture.name) {
      return;
    }
    setProfilePicture(URL.createObjectURL(picture));
    setPictureName(picture.name);
    setPictureSize(picture.size);
    setPictureType(picture.type === 'image/jpg' ? 'image/jpeg' : picture.type);
    setPictureDataToUpload(picture);
  };
  const countries = useSelector(getCountriesState, shallowEqual);

  const phoneNumberRef = useRef(null);

  useEffect(() => {
    dispatch(fetchUserProfileRequest({}));
    dispatch(getPossibleKinshipRequest({}));
  }, []);

  useEffect(() => {
    dispatch(fetchTitlesRequest({}));
  }, [titles.length]);

  useEffect(() => {
    const parsedPhone =
      profileData.phoneNumber &&
      Utils.withoutMetadata(parsePhoneNumber(profileData.phoneNumber?.replace('00', '+')));
    const getNumberAfterPossiblyEliminatingZero = Utils.getNumberAfterPossiblyEliminatingZero(
      parsedPhone?.nationalNumber,
      parsedPhone?.countryCallingCode,
    );
    setFirstName(profileData.firstName);
    setLastName(profileData.lastName);
    setEmail(profileData.email);
    setTitleId(profileData.titleId);
    setCanUseSeal(profileData.canUseSeal);
    setPhoneNumber(getNumberAfterPossiblyEliminatingZero?.formattedNumber);
    if (profileData?.address?.address_as_string) {
      setAddress(profileData.address?.address_as_string);
    }
    if (profileData.numericPersonalCode) {
      setCnp(profileData.numericPersonalCode);
    }
  }, [profileData]);
  const submitProfile = (_rawAddress) => {
    const parsedPhone = phoneNumber && Utils.withoutMetadata(parsePhoneNumber(phoneNumber));
    const getNumberAfterPossiblyEliminatingZero = Utils.getNumberAfterPossiblyEliminatingZero(
      parsedPhone?.nationalNumber,
      parsedPhone?.countryCallingCode,
    );

    if (
      type === 'unprofiled' &&
      (!cnp ||
        !phoneNumber ||
        !parseInt(getNumberAfterPossiblyEliminatingZero.number, 10) ||
        !_rawAddress)
    ) {
      Alerts.simpleAlert(`${t('error')}`, `${t('markedFieldsMandatory')}`);
      return;
    }
    if (
      type === 'profiled' &&
      (!firstName ||
        !lastName ||
        !phoneNumber ||
        !parseInt(getNumberAfterPossiblyEliminatingZero.number, 10) ||
        !cnp ||
        !_rawAddress)
    ) {
      Alerts.simpleAlert(`${t('error')}`, `${t('markedFieldsMandatory')}`);
      return;
    }
    const pictureDataToPost = {
      originalName: pictureName,
      mimeType: pictureType,
      size: pictureSize,
    };
    const userData = {
      email: profileData.isUnconfirmedFromSocial ? email : undefined,
      firstName,
      lastName,
      phoneNumber: getNumberAfterPossiblyEliminatingZero?.formattedNumber,
      numericPersonalCode: cnp,
      rawAddress:
        typeof _rawAddress !== 'string' ? JSON.stringify(_rawAddress) : _rawAddress || undefined,
      userTitle: titleId,
      canUseSeal: canUseSeal ? 1 : 0,
    };
    let payloadToUpdate;
    if (pictureType) {
      payloadToUpdate = { ...userData, picture: pictureDataToPost };
    } else {
      payloadToUpdate = userData;
    }
    Alerts.okCancelAlert(
      `${t('warning')}`,
      `${t('validations.saveProfile')}`,
      () => {
        dispatch(
          patchUserProfileRequest({
            toUpdate: payloadToUpdate,
            avatarFile: pictureDataToUpload,
            withNavigation: true,
            type,
          }),
        );
      },
      () => {},
    );
  };
  const saveProfile = async (e) => {
    e.preventDefault();
    if (typeof rawAddress !== 'object') {
      try {
        await geocodeByAddress(profileData.address?.address_as_string)
          .then((results) => {
            submitProfile(results[0]);
            setRawAddress(results[0]);
          })
          .catch((error) => {
            Alerts.simpleAlert(`${t('error')}`, `${t('addressFieldCompleted')}`);
            console.error(error);
          });
      } catch (err) {
        Alerts.simpleAlert(`${t('error')}`, `${t('addressFieldCompleted')}`);
        console.error(err);
      }
    } else {
      submitProfile(rawAddress);
    }
  };

  return (
    <>
      <div className="profile-picture">
        <div className="subtitle">{t('profilePhoto')}</div>
        <div className="change-picture">
          <Form.Group controlId="formFile" className="files-box" style={{ marginRight: 10 }}>
            <Form.Label>{t('changeProfilePhoto')}</Form.Label>
            <Form.Control type="file" onChange={handleProfilePictureChange} />
          </Form.Group>
          <div className="avatar-section">
            <Avatar size={46} imageURL={profilePicture} firstName={firstName} lastName={lastName} />
          </div>
        </div>
      </div>
      {type === 'profiled' && (
        <>
          <div className="subtitle">{t('settings.personalData')}</div>
          <div className="form-input">
            <input
              autoComplete="off"
              id="email"
              name="email"
              type="email"
              className="form-control"
              placeholder=" "
              value={email}
              disabled={!profileData.isUnconfirmedFromSocial}
              onChange={(event) => setEmail(event?.target?.value)}
            />
            <label htmlFor="email">{t('email')}</label>
          </div>
          <div className="form-select-label">
            <select
              onChange={(e) => setTitleId(e?.target?.value)}
              required
              id="title"
              name="title"
              className="custom-select"
              value={titleId}
            >
              {titles.length ? (
                titles.map((title) => {
                  return (
                    <option key={title.id} value={title.id}>
                      {title.name}
                    </option>
                  );
                })
              ) : (
                <option>{t('noTitle')}</option>
              )}
            </select>
            <label htmlFor="title">{t('title')}</label>
          </div>
          <div className="form-input">
            <input
              autoComplete="off"
              id="lastName"
              name="lastName"
              type="text"
              className="form-control"
              placeholder=" "
              value={lastName}
              onChange={(e) => setLastName(e?.target?.value)}
            />
            <label htmlFor="fullName">{t('lastName')}</label>
          </div>
          <div className="form-input">
            <input
              autoComplete="off"
              id="firstName"
              name="firstName"
              type="text"
              className="form-control"
              placeholder=" "
              value={firstName}
              onChange={(e) => setFirstName(e?.target?.value)}
            />
            <label htmlFor="fullName">{t('firstName')}</label>
          </div>
          <div className="form-input">
            <input
              autoComplete="off"
              id="cnp"
              name="cnp"
              type="text"
              className="form-control"
              placeholder=" "
              value={cnp}
              onChange={(e) => setCnp(e?.target?.value)}
            />
            <label htmlFor="cnp">{t('cnp')}</label>
          </div>
          <div className="phone-number-with-prefix">
            <PhoneInput
              initialValueFormat="national"
              className={phoneNumber && 'has-value'}
              placeholder=" "
              value={phoneNumber}
              defaultCountry="RO"
              id="phoneNumber"
              name="phoneNumber"
              /* @ts-ignore */
              onChange={(value) => setPhoneNumber(value)}
              ref={phoneNumberRef}
            />
            <label htmlFor="phoneNumber">{t('phoneNumber')}</label>
          </div>
        </>
      )}
      <div className="subtitle">{t('patientApp.contactData')}</div>
      {type !== 'profiled' && (
        <>
          <div className="form-input">
            <input
              autoComplete="off"
              id="cnp"
              name="cnp"
              type="text"
              className="form-control"
              placeholder=" "
              value={cnp}
              onChange={(e) => setCnp(e?.target?.value)}
            />
            <label htmlFor="cnp">{t('cnp')}</label>
          </div>
          <div className="phone-number-with-prefix">
            <PhoneInput
              initialValueFormat="national"
              className={phoneNumber && 'has-value'}
              placeholder=" "
              value={phoneNumber}
              defaultCountry="RO"
              id="phoneNumber"
              name="phoneNumber"
              /* @ts-ignore */
              onChange={(value) => setPhoneNumber(value)}
              ref={phoneNumberRef}
            />
            <label htmlFor="phoneNumber">{t('phoneNumber')}</label>
          </div>
        </>
      )}
      <div className="address-google-places-autocomplete">
        <GooglePlacesAutocomplete
          apiKey={getEnv('GOOGLE_PLACES_API_KEY')}
          apiOptions={{ language: 'ro', region: 'ro' }}
          minLengthAutocomplete={3}
          selectProps={{
            placeholder: t('address'),
            defaultInputValue: address,
            onChange: (o) => {
              geocodeByPlaceId(o.value.place_id)
                .then((results) => {
                  setRawAddress(results[0]);
                })
                .catch((error) => console.error(error));
            },
          }}
        />
        <label htmlFor="react-select-3-input">{t('address')}</label>
      </div>
      <div className="custom-control custom-checkbox green-checkbox mb-4">
        <input
          className="custom-control-input"
          onChange={() => setCanUseSeal(!canUseSeal)}
          id="checkbox"
          type="checkbox"
          checked
          disabled
        />
        <label htmlFor="checkbox" className="custom-control-label">
          {t('useSealMessage')}
        </label>
      </div>
      {type !== 'profiled' && (
        <button type="button" onClick={saveProfile} className="green-button">
          {t('nextStep')}
        </button>
      )}
      {type === 'profiled' && (
        <button type="button" onClick={saveProfile} className="green-button">
          {t('saveChanges')}
        </button>
      )}
    </>
  );
};

DoctorAccountProfileTabPersonal.defaultProps = {
  type: 'profiled',
};

DoctorAccountProfileTabPersonal.propTypes = {
  type: PropTypes.string,
};

export default DoctorAccountProfileTabPersonal;
