const styles = {
  control: (provided) => ({
    ...provided,
    padding: '8px',
    borderWidth: '2px',
    borderColor: '#BFC4C5',
    borderRadius: '12px',
    backgroundColor: 'inherit',
    outline: 'none',
    fontFamily: 'Rota',
    fontWeight: 600,
    fontSize: '18px',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:focus': {
      backgroundColor: '#FFFFFF',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#001315',
  }),
  input: (provided) => ({
    ...provided,
    color: '#001315',
  }),
  option: (provided) => ({
    ...provided,
    color: '#001315',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#001315',
    fontFamily: 'Rota',
    fontWeight: 600,
    fontSize: '18px',
  }),
};

export { styles };
