import './App.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  createErrorMessageSelector,
  createLoadingSelector,
} from 'shared/redux/src/GenericSeletors';
import {
  getAuthState,
  getErrorState,
  getTranslationsState,
  settingsState,
} from 'shared/redux/src/StatesGetter';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import CustomErrorUtils from 'shared/modules/CustomErrorUtils';
import NavigationService from 'shared/services/NavigationService';
import { loginCheckPoint } from 'shared/modules/AuthUtils';
import { updateDeviceRegIdRequest } from 'shared/redux/actions/UserActions';
import { Routes } from 'shared/components/Routes';
import { updateSharedStateAction } from 'shared/redux/actions/UngroupedActions';
import { getEnv, isDoctor } from 'shared/services/EnvService';
import StorageService from 'shared/services/StorageService';
import LocalEventsService from 'shared/services/LocalEventsService';
import { Helmet } from 'react-helmet';
import { getAppointmentSettingsRequest } from 'shared/redux/actions/AppointmentActions';
import i18next from 'i18next';
import ReactGA from 'react-ga4';
import _ from 'lodash';
import dayjs from 'dayjs';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { fetchTranslationsRequest } from './shared/redux/actions/TranslationsActions';
import i18n from './shared/i18n/I18n';
import 'dayjs/locale/ro';
import 'dayjs/locale/en';
import { fetchSettingsRequest } from './shared/redux/actions/SettingsActions';

const App = () => {
  // @ts-ignore
  if (getEnv('ENV') === 'production') {
    ReactGA.initialize([
      {
        trackingId: 'AW-382378191',
      },
      {
        trackingId: 'G-LVBN38CJN9',
      },
      {
        trackingId: 'G-GSS0YQ4WGY',
      },
      {
        trackingId: 'GT-PHCMWHH',
      },
    ]);
  }

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const translations = useSelector(getTranslationsState, shallowEqual);
  const loadingSelector = createLoadingSelector();
  const isLoading = useSelector(loadingSelector);
  const errorState = useSelector(getErrorState, shallowEqual);
  const settings = useSelector(settingsState, shallowEqual);
  const [selectedLocale, setSelectedLocale] = useState(getEnv('LOCALE'));

  // Set Error State
  useEffect(() => {
    const error = createErrorMessageSelector(errorState);
    if (error?.message) {
      CustomErrorUtils.errorHandler(i18next.t('error'), error.message);
    }
  }, [errorState]);

  // Fetch at the beginning
  useEffect(() => {
    dispatch(fetchTranslationsRequest({}));
    dispatch(fetchSettingsRequest({}));
  }, []);

  // Set translations
  useEffect(() => {
    if (_.has(translations, 'en')) {
      i18n.addResourceBundle('en', 'translation', translations.en, true, true);
    }
    if (_.has(translations, 'ro')) {
      i18n.addResourceBundle('ro', 'translation', translations.ro, true, true);
    }

    // @ts-ignore
    // i18n.addResource(resourceTranslations);
  }, [translations]);

  // Set locale
  useEffect(() => {
    if (!localStorage.getItem('APP_VERSION')) {
      localStorage.setItem('APP_VERSION', `${getEnv('VERSION')}`);
    } else if (
      getEnv('VERSION') &&
      `${getEnv('VERSION')}` !== localStorage.getItem('APP_VERSION')
    ) {
      LocalEventsService.emit('forceLogout');
    }

    dispatch(getAppointmentSettingsRequest({}));

    NavigationService.setNavHistoryRef(navigate);

    (async () => {
      const locale = await StorageService.getData('APP_LANGUAGE', i18next.language);
      dispatch(updateSharedStateAction({ locale, isNotificationInProgress: false }));
      await i18next.changeLanguage(locale);
      setSelectedLocale(locale);
      dayjs.locale(locale);
      dispatch(
        updateSharedStateAction({ locale: i18next.language, isNotificationInProgress: false }),
      );
      if (!isLoggedIn) {
        const checkPoint = await loginCheckPoint();
        if (checkPoint.action === 'login') {
          const isLoggedInNow = checkPoint.isProfileCompleted && checkPoint.isConfirmed;
          if (isLoggedInNow) {
            dispatch(updateDeviceRegIdRequest(checkPoint.deviceRegId));
          }
        }
      }
    })();
  }, []);

  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    // @ts-ignore
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.warn('Cannot remove a child from a different parent', child, this);
        }
        return child;
      }
      // eslint-disable-next-line prefer-rest-params
      // @ts-ignore
      // eslint-disable-next-line prefer-rest-params
      return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    // @ts-ignore
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.warn(
            'Cannot insert before a reference node from a different parent',
            referenceNode,
            this,
          );
        }
        return newNode;
      }
      // eslint-disable-next-line prefer-rest-params
      // @ts-ignore
      // eslint-disable-next-line prefer-rest-params
      return originalInsertBefore.apply(this, arguments);
    };
  }

  let favicon = '';
  if (isDoctor(true)) {
    favicon = '/favicon-medic.png';
  } else {
    favicon = '/favicon-patient.png';
  }

  return (
    <>
      <Helmet htmlAttributes={{ lang: selectedLocale }}>
        <title>Ringdoc aplicatie de telemedicina pentru consultatii online</title>
        <meta
          name="description"
          content="Ringdoc este o aplicatie de telemedicina pentru consultatii online cu medici specialisti fara programare prealabila usor de utilizat"
        />
        <link rel="icon" sizes="any" type="image/png" href={favicon} />
      </Helmet>
      {(settings.chatBot || 1) && (
        <TawkMessengerReact propertyId="6513fed9b1aaa13b7a7934ca" widgetId="1hbb1n0u7" />
      )}
      <Routes />
      {['/patient/medicalFolder'].indexOf(location.pathname) === -1 && (
        <LoadingSpinner isLoading={isLoading} />
      )}
    </>
  );
};
export default App;
