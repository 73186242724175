import 'doctor/styles/consultation-details.css';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import ListGroup from 'react-bootstrap/ListGroup';
import { getDoctorAppointmentDetailsState } from 'shared/redux/src/StatesGetter';
import Utils from 'shared/modules/Utils';
import { useTranslation } from 'react-i18next';

const DoctorConsultationDetailsTabProfile = () => {
  const { t } = useTranslation();
  const { patient = { firstName: '', lastName: '' } } = useSelector(
    getDoctorAppointmentDetailsState,
    shallowEqual,
  );
  const {
    birthDate,
    height,
    weight,
    bloodSystem,
    isSmoker,
    isAlcoholConsumer,
    knownAllergies,
    pastMedicalConditions,
    currentMedicalConditions,
    patientKinshipDiseases,
    gender,
  } = patient;

  return (
    <>
      <div className="subtitle">{t('medicalProfile.biologicalData')}</div>
      <div className="details-box">
        <ListGroup>
          <ListGroup.Item>
            <div className="title">{t('medicalProfile.age')}</div>
            <div className="details">
              {Utils.calculateAge(birthDate) > 0
                ? `${Utils.calculateAge(birthDate)} ${t('years')}`
                : '-'}
            </div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="title">{t('medicalProfile.height')}</div>
            <div className="details">{`${height ?? '0'} cm`}</div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="title">{t('medicalProfile.weight')}</div>
            <div className="details">{`${weight ?? '0'} kg`}</div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="title">{t('medicalProfile.gender')}</div>
            <div className="details">{`${i18next.t(gender)}`}</div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="title">{t('medicalProfile.bloodType')}</div>
            <div className="details">{bloodSystem ?? '-'}</div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="title">{t('medicalProfile.smoking')}</div>
            <div className="details">{isSmoker ? t('yes') : t('no')}</div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="title">{t('medicalProfile.alcohol')}</div>
            <div className="details">{isAlcoholConsumer ? t('yes') : t('no')}</div>
          </ListGroup.Item>
        </ListGroup>
      </div>

      <div className="subtitle">{t('medicalProfile.issues')}</div>
      <div className="disease-box">
        <ListGroup>
          <ListGroup.Item>
            <div className="title">{t('medicalProfile.allergies')}</div>
            <div className="details">{knownAllergies ?? '-'}</div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="title">{t('medicalProfile.knownIssues')}</div>
            <div className="details">{currentMedicalConditions ?? '-'}</div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="title">{t('medicalProfile.pastIssues')}</div>
            <div className="details">{pastMedicalConditions ?? '-'}</div>
          </ListGroup.Item>
        </ListGroup>
      </div>

      <div className="subtitle">{t('medicalProfile.familyHistory')}</div>
      {patientKinshipDiseases && patientKinshipDiseases?.length ? (
        patientKinshipDiseases?.map((row) => {
          return (
            <div key={row?.id.toString()} className="familiar-history-box">
              <div className="title">{Utils.capitalizeFirstLetter(row.kinship)}</div>
              <div className="description">{Utils.capitalizeFirstLetter(row.diseases)}</div>
            </div>
          );
        })
      ) : (
        <div className="familiar-history-box">
          <div className="details">{t('medicalProfile.noHistory')}</div>
        </div>
      )}
    </>
  );
};

DoctorConsultationDetailsTabProfile.defaultProps = {
  match: {},
};

DoctorConsultationDetailsTabProfile.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }),
};

export default DoctorConsultationDetailsTabProfile;
