import 'patient/styles/appointment-service-steps.css';
import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import {
  getAuthState,
  getDoctorProfileState,
  getDoctorTimeSlotsState,
  getPatientAppointmentState,
} from 'shared/redux/src/StatesGetter';
import { getDoctorTimeSlotsRequest } from 'shared/redux/actions/DoctorsActions';
import { requestAppointment } from 'shared/modules/PatientAppointmentUtils';
import Alerts from 'shared/components/Alerts';
import dayjs from 'dayjs';
import { Form, Modal } from 'react-bootstrap';
import { useLocation } from 'react-router';
import _ from 'lodash';
import Utils from '../../../shared/modules/Utils';
import { appointmentLocalStepsRequest } from '../../../shared/redux/actions/PatientAppointmentActions';

const PatientAppointmentChooseService = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const { doctor, specializationId, noOfSteps } = useSelector(
    getPatientAppointmentState,
    shallowEqual,
  );
  const doctorProfile = useSelector(getDoctorProfileState, shallowEqual);
  const searchName = useLocation().search;
  const doctorSpecializationParam = new URLSearchParams(searchName).get('ds');
  const { doctorSpecializations } = doctorProfile;
  const activeDoctorServices = doctorSpecializations
    .filter((specialization) => {
      // @ts-ignore
      return specialization.id === parseInt(doctorSpecializationParam, 10);
    })
    .map((specialization) => {
      return specialization.doctorServices.filter((service) => {
        return service.is_active === true;
      });
    });

  const [modalDisplayed, setModalDisplayed] = useState(false);
  const hideModal = () => setModalDisplayed(false);

  const {
    pictureMedia,
    firstName,
    lastName,
    fullNameWithTitle,
    rawFirstAvailableTime,
    doctorServices,
  } = doctor;
  const stepString = `${t('step')} 2/${noOfSteps}`;
  const abortAction = () => {
    navigate(-2);
  };

  const continueConsultation = () => {
    dispatch(
      appointmentLocalStepsRequest({
        newAppointment: true,
        noOfSteps: 6,
        specializationId,
        type: 'programmed',
        doctor,
        service: 'consultation',
        hasControl: true,
      }),
    );
    navigate('/patient/appointment/set-date');
  };

  const controlModal = () => {
    setModalDisplayed(true);
  };

  const continueControl = () => {
    setModalDisplayed(false);
    dispatch(
      appointmentLocalStepsRequest({
        newAppointment: true,
        noOfSteps: 6,
        specializationId,
        type: 'programmed',
        doctor,
        service: 'control',
        hasControl: true,
      }),
    );
    navigate('/patient/appointment/set-date');
  };

  return (
    <>
      <section className="appointment-service-steps">
        <div className="title-section">
          <div className="steps">
            <div className="icon-wrapper" onClick={() => navigate(-1)}>
              <CustomIcon
                className="custom-icon"
                color={Colors.darkGrey}
                size="24"
                icon="Left_arrow"
              />
            </div>
            {stepString}
            <div className="cancel-button">
              <button type="button" onClick={abortAction}>
                {t('abort')}
              </button>
            </div>
          </div>
          <h1>{t('appointment')}</h1>
        </div>
        <div className="medic-box">
          <div
            className="avatar"
            style={
              pictureMedia
                ? { backgroundImage: `url(${pictureMedia})` }
                : { backgroundColor: '#E5E7E8' }
            }
          >
            {!pictureMedia && (
              <div className="image-placeholder">{`${firstName?.charAt(0)}${lastName?.charAt(
                0,
              )}`}</div>
            )}
          </div>
          <div className="info">
            <div className="name">{fullNameWithTitle}</div>
            <div className="specialization">
              {doctorProfile.doctorSpecializations
                .filter(
                  // @ts-ignore
                  (sp) => sp.id === parseInt(doctorSpecializationParam, 10),
                )
                .map((sp) => sp.name)}
            </div>
          </div>
        </div>
        <div className="services">
          {activeDoctorServices.map((serviceRow) => {
            return serviceRow.map((service) => {
              return (
                <div
                  key={_.uniqueId()}
                  className="service-item"
                  onClick={(e) => {
                    if (service.service.slug === 'consultation') {
                      continueConsultation();
                    } else {
                      controlModal();
                    }
                  }}
                >
                  <div>
                    <div className="service-name">
                      {Utils.capitalizeFirstLetter(t(service.service.slug))}
                    </div>
                    <div className="service-description">
                      {service.service.slug === 'consultation'
                        ? t('appointments.service-description-consultation')
                        : t('appointments.service-description-control')}
                    </div>
                    <div className="service-details">
                      {
                        // @ts-ignore
                        service.price_as_string
                      }{' '}
                      /{' '}
                      {
                        // @ts-ignore
                        service.duration
                      }{' '}
                      {t('minutes')}
                    </div>
                  </div>

                  <CustomIcon
                    className="custom-icon"
                    color={Colors.darkGrey}
                    size="24"
                    icon="Right_pointer"
                  />
                </div>
              );
            });
          })}
        </div>

        <Modal
          show={modalDisplayed}
          onHide={hideModal}
          animation
          centered
          className="modal-edit-document"
        >
          <Modal.Body>
            <Form>
              <div className="text-center">
                <CustomIcon
                  className="custom-icon"
                  color={Colors.warningColor}
                  size="150"
                  icon="Big_warning"
                />
              </div>
              <div className="details title text-center text-capitalize text-black font-weight-bold mb-2 mb-md-5">
                {t('warning')}!
              </div>

              <div className="info-text mb-4">{t('appointments.controlApproveText1')}</div>

              <div className="info-text">{t('appointments.controlApproveText2')}</div>

              <button type="button" className="orange-button" onClick={continueControl}>
                {t('appointments.controlContinue')}
              </button>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <button type="button" className="white-button" onClick={hideModal}>
              {t('back')}
            </button>
          </Modal.Footer>
        </Modal>
      </section>
    </>
  );
};

export default PatientAppointmentChooseService;
