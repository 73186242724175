import { AnyAction } from 'redux';
import { FETCH_MEDICAL_RECORD_CATEGORY_SUCCESS } from 'shared/redux/types/MedicalRecordCategoryTypes';

const MedicalRecordCategoryReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case FETCH_MEDICAL_RECORD_CATEGORY_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default MedicalRecordCategoryReducer;
