import i18next from 'i18next';
import { AnyAction } from 'redux';
import {
  UPDATE_LOCALE_SUCCESS,
  UPDATE_SHARED_STATE_REQUEST,
} from 'shared/redux/types/UngroupedTypes';
import { FORGOT_PASSWORD_SUCCESS, RESET_PASSWORD_SUCCESS } from 'shared/redux/types/AuthTypes';
import Utils from 'shared/modules/Utils';

const SharedStateReducer = (
  state = {
    locale: i18next.language,
    forgotPassword: false,
    passwordReset: false,
    showNextAppointment: false,
    showWaitingTime: false,
    nextAppointmentStart: null,
    appState: '',
    isNotificationInProgress: false,
    needForceUpdate: false,
    currentRoute: '',
    timeOptionsAsArray: Utils.oneHourMinutes,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case UPDATE_SHARED_STATE_REQUEST:
      return { ...state, ...action.payload };
    case UPDATE_LOCALE_SUCCESS:
      return { ...state, ...action.payload };
    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, forgotPassword: true };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, forgotPassword: false, passwordReset: true };
    default:
      return state;
  }
};

export default SharedStateReducer;
