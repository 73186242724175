import { AnyAction } from 'redux';
import {
  ASSOCIATE_SPECIALIZATION_ERROR,
  ASSOCIATE_SPECIALIZATION_REQUEST,
  ASSOCIATE_SPECIALIZATION_SUCCESS,
  FETCH_USER_PROFILE_ERROR,
  FETCH_USER_PROFILE_REQUEST,
  FETCH_USER_PROFILE_SUCCESS,
  LOCALLY_UPDATE_USER_PROFILE,
  PATCH_DOCTOR_CV_ERROR,
  PATCH_DOCTOR_CV_REQUEST,
  PATCH_DOCTOR_CV_SUCCESS,
  PATCH_DOCTOR_ORGANIZATION_ERROR,
  PATCH_DOCTOR_ORGANIZATION_REQUEST,
  PATCH_DOCTOR_ORGANIZATION_SUCCESS,
  PATCH_USER_PROFILE_ERROR,
  PATCH_USER_PROFILE_REQUEST,
  PATCH_USER_PROFILE_SUCCESS,
  POST_USER_PROFILE_ERROR,
  POST_USER_PROFILE_REQUEST,
  POST_USER_PROFILE_SUCCESS,
  SET_AVAILABILITY_ERROR,
  SET_AVAILABILITY_REQUEST,
  SET_AVAILABILITY_SUCCESS,
  LOCALLY_ADD_SPECIALIZATION,
  LOCALLY_REMOVE_SPECIALIZATION,
  GET_MY_SPECIALIZATIONS_ERROR,
  GET_MY_SPECIALIZATIONS_REQUEST,
  GET_MY_SPECIALIZATIONS_SUCCESS,
  LOCALLY_ADD_CITY,
  FETCH_TITLES_ERROR,
  FETCH_TITLES_REQUEST,
  FETCH_TITLES_SUCCESS,
} from 'shared/redux/types/UserProfileTypes';

export const fetchUserProfileRequest = (payload): AnyAction => ({
  type: FETCH_USER_PROFILE_REQUEST,
  payload,
});

export const fetchUserProfileSuccess = (payload): AnyAction => ({
  type: FETCH_USER_PROFILE_SUCCESS,
  payload,
});

export const fetchUserProfileError = (payload): AnyAction => ({
  type: FETCH_USER_PROFILE_ERROR,
  payload,
});

export const postUserProfileRequest = (payload): AnyAction => ({
  type: POST_USER_PROFILE_REQUEST,
  payload,
});

export const postUserProfileSuccess = (payload): AnyAction => ({
  type: POST_USER_PROFILE_SUCCESS,
  payload,
});

export const postUserProfileError = (payload): AnyAction => ({
  type: POST_USER_PROFILE_ERROR,
  payload,
});

export const patchUserProfileRequest = (payload): AnyAction => ({
  type: PATCH_USER_PROFILE_REQUEST,
  payload,
});

export const patchUserProfileSuccess = (payload): AnyAction => ({
  type: PATCH_USER_PROFILE_SUCCESS,
  payload,
});

export const patchUserProfileError = (payload): AnyAction => ({
  type: PATCH_USER_PROFILE_ERROR,
  payload,
});

export const locallyUpdateUserProfile = (payload): AnyAction => ({
  type: LOCALLY_UPDATE_USER_PROFILE,
  payload,
});

export const patchDoctorOrganizationRequest = (payload): AnyAction => ({
  type: PATCH_DOCTOR_ORGANIZATION_REQUEST,
  payload,
});

export const patchDoctorOrganizationSuccess = (payload): AnyAction => ({
  type: PATCH_DOCTOR_ORGANIZATION_SUCCESS,
  payload,
});

export const patchDoctorOrganizationError = (payload): AnyAction => ({
  type: PATCH_DOCTOR_ORGANIZATION_ERROR,
  payload,
});

export const patchDoctorCvRequest = (payload): AnyAction => ({
  type: PATCH_DOCTOR_CV_REQUEST,
  payload,
});

export const patchDoctorCvSuccess = (payload): AnyAction => ({
  type: PATCH_DOCTOR_CV_SUCCESS,
  payload,
});

export const patchDoctorCvError = (payload): AnyAction => ({
  type: PATCH_DOCTOR_CV_ERROR,
  payload,
});

export const associateSpecializationRequest = (payload): AnyAction => ({
  type: ASSOCIATE_SPECIALIZATION_REQUEST,
  payload,
});

export const associateSpecializationSuccess = (payload): AnyAction => ({
  type: ASSOCIATE_SPECIALIZATION_SUCCESS,
  payload,
});

export const associateSpecializationError = (payload): AnyAction => ({
  type: ASSOCIATE_SPECIALIZATION_ERROR,
  payload,
});

export const setAvailabilityRequest = (payload): AnyAction => ({
  type: SET_AVAILABILITY_REQUEST,
  payload,
});

export const setAvailabilitySuccess = (payload): AnyAction => ({
  type: SET_AVAILABILITY_SUCCESS,
  payload,
});

export const setAvailabilityError = (payload): AnyAction => ({
  type: SET_AVAILABILITY_ERROR,
  payload,
});

export const locallyAddSpecialization = (payload): AnyAction => ({
  type: LOCALLY_ADD_SPECIALIZATION,
  payload,
});

export const locallyRemoveSpecialization = (payload): AnyAction => ({
  type: LOCALLY_REMOVE_SPECIALIZATION,
  payload,
});

export const fetchMySpecializationsRequest = (payload): AnyAction => ({
  type: GET_MY_SPECIALIZATIONS_REQUEST,
  payload,
});

export const fetchMySpecializationsSuccess = (payload): AnyAction => ({
  type: GET_MY_SPECIALIZATIONS_SUCCESS,
  payload,
});

export const fetchMySpecializationsError = (payload): AnyAction => ({
  type: GET_MY_SPECIALIZATIONS_ERROR,
  payload,
});

export const locallyAddCity = (payload): AnyAction => ({
  type: LOCALLY_ADD_CITY,
  payload,
});

export const fetchTitlesRequest = (payload): AnyAction => ({
  type: FETCH_TITLES_REQUEST,
  payload,
});

export const fetchTitlesSuccess = (payload): AnyAction => ({
  type: FETCH_TITLES_SUCCESS,
  payload,
});

export const fetchTitlesError = (payload): AnyAction => ({
  type: FETCH_TITLES_ERROR,
  payload,
});
