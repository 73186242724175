export const FETCH_PUSHES_REQUEST = 'FETCH_PUSHES_REQUEST';
export const FETCH_PUSHES_SUCCESS = 'FETCH_PUSHES_SUCCESS';
export const FETCH_PUSHES_ERROR = 'FETCH_PUSHES_ERROR';

export const FETCH_PUSH_DETAILS_REQUEST = 'FETCH_PUSH_DETAILS_REQUEST';
export const FETCH_PUSH_DETAILS_SUCCESS = 'FETCH_PUSH_DETAILS_SUCCESS';
export const FETCH_PUSH_DETAILS_ERROR = 'FETCH_PUSH_DETAILS_ERROR';

export const UPDATE_PUSHES_REQUEST = 'UPDATE_PUSHES_REQUEST';
export const UPDATE_PUSHES_SUCCESS = 'UPDATE_PUSHES_SUCCESS';
export const UPDATE_PUSHES_ERROR = 'UPDATE_PUSHES_ERROR';

export const FETCH_NOTIFICATIONS_SETTINGS_REQUEST = 'FETCH_NOTIFICATIONS_SETTINGS_REQUEST';
export const FETCH_NOTIFICATIONS_SETTINGS_SUCCESS = 'FETCH_NOTIFICATIONS_SETTINGS_SUCCESS';
export const FETCH_NOTIFICATIONS_SETTINGS_ERROR = 'FETCH_NOTIFICATIONS_SETTINGS_ERROR';

export const UPDATE_NOTIFICATIONS_SETTINGS_REQUEST = 'UPDATE_NOTIFICATIONS_SETTINGS_REQUEST';
export const UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS = 'UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS';
export const UPDATE_NOTIFICATIONS_SETTINGS_ERROR = 'UPDATE_NOTIFICATIONS_SETTINGS_ERROR';

export const SILENTLY_FETCH_PUSHES_REQUEST = 'SILENTLY_FETCH_PUSHES_REQUEST';
