import i18next from 'i18next';

const medicalRecordCategorySelector = (categories: any[]) => {
  return categories.map((row) => {
    return {
      id: row?.id,
      name: row.translations?.find((r) => r.locale === i18next.language)?.name ?? '',
    };
  });
};

export default medicalRecordCategorySelector;
