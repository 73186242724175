import 'patient/styles/free-call.css';
import NavigationService from 'shared/services/NavigationService';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';

const PatientFreeCallSearchingMedic = () => {
  return (
    <section className="free-call-medic">
      <div className="info-message">
        <CustomIcon className="custom-icon" color={Colors.mediumGrey} size="24" icon="Warning_2" />
        Pentru probleme medicale grave sună la 112!
      </div>
      <div className="box">
        <CustomIcon className="custom-icon" color={Colors.darkGrey} size="159" icon="Big_doctor" />
        <i className="searching-circles-orange-icon" />
        <h1>Căutăm un medic pentru tine chiar acum ...</h1>
      </div>
      <div className="box">
        <ul>
          <li>
            <div className="field-title">Durată apel</div>
            <div className="field-value">10 minute</div>
          </li>
          <li>
            <div className="field-title">Total de plată</div>
            <div className="field-value">Gratuit</div>
          </li>
        </ul>
      </div>
      <button
        type="button"
        onClick={() => NavigationService.navigate('/patient')}
        className="white-button position-relative"
      >
        Anulează apelul
      </button>
    </section>
  );
};

export default PatientFreeCallSearchingMedic;
