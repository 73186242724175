const colors = {
  patientColor: '#FF6A39',
  medicColor: '#00CFB4',
  darkGrey: '#001315',
  mediumGrey: '#80898A',
  lightGrey: '#F2F3F3',
  statusOnline: '#00CFB4',
  statusOffline: '#BFC4C5',
  statusBusy: '#F6BE00',
  white: '#FFFFFF',
  lightYellow: '#FEF5D9',
  yellow: '#F4B700',
  lightOrange: '#F4AF23',
  menuColor: '#BFC4C5',
  warningColor: '#EB3300',
  confirmedColor: '#00CBB2',
  border: '#E5E7E8',
};

export default colors;
