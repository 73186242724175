import { AnyAction } from 'redux';
import { GET_SERVICES_SUCCESS } from '../types/ServiceTypes';

const ServiceReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case GET_SERVICES_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export { ServiceReducer };
