import { setPendingAppointment } from '../actions/PatientAppointmentActions';

export const APPOINTMENT_SCHEDULE_REQUEST = 'APPOINTMENT_SCHEDULE_REQUEST';
export const APPOINTMENT_SCHEDULE_SUCCESS = 'APPOINTMENT_SCHEDULE_SUCCESS';
export const APPOINTMENT_SCHEDULE_ERROR = 'APPOINTMENT_SCHEDULE_ERROR';

export const APPOINTMENT_CALL_NOW_REQUEST = 'APPOINTMENT_CALL_NOW_REQUEST';
export const APPOINTMENT_CALL_NOW_SUCCESS = 'APPOINTMENT_CALL_NOW_SUCCESS';
export const APPOINTMENT_CALL_NOW_ERROR = 'APPOINTMENT_CALL_NOW_ERROR';

export const APPOINTMENT_EMERGENCY_REQUEST = 'APPOINTMENT_EMERGENCY_REQUEST';
export const APPOINTMENT_EMERGENCY_SUCCESS = 'APPOINTMENT_EMERGENCY_SUCCESS';
export const APPOINTMENT_EMERGENCY_ERROR = 'APPOINTMENT_EMERGENCY_ERROR';

export const APPOINTMENT_CALL_SPECIALIST_REQUEST = 'APPOINTMENT_CALL_SPECIALIST_REQUEST';
export const APPOINTMENT_CALL_SPECIALIST_SUCCESS = 'APPOINTMENT_CALL_SPECIALIST_SUCCESS';
export const APPOINTMENT_CALL_SPECIALIST_ERROR = 'APPOINTMENT_CALL_SPECIALIST_ERROR';

export const APPOINTMENT_SELECT_PROFILE_REQUEST = 'APPOINTMENT_SELECT_PROFILE_REQUEST';
export const APPOINTMENT_SELECT_PROFILE_SUCCESS = 'APPOINTMENT_SELECT_PROFILE_SUCCESS';
export const APPOINTMENT_SELECT_PROFILE_ERROR = 'APPOINTMENT_SELECT_PROFILE_ERROR';

export const APPOINTMENT_DELETE_REQUEST = 'APPOINTMENT_DELETE_REQUEST';
export const APPOINTMENT_DELETE_SUCCESS = 'APPOINTMENT_DELETE_SUCCESS';
export const APPOINTMENT_DELETE_ERROR = 'APPOINTMENT_DELETE_ERROR';

export const APPOINTMENT_BOOK_REQUEST = 'APPOINTMENT_BOOK_REQUEST';
export const APPOINTMENT_BOOK_SUCCESS = 'APPOINTMENT_BOOK_SUCCESS';
export const APPOINTMENT_BOOK_ERROR = 'APPOINTMENT_BOOK_ERROR';

export const APPOINTMENT_CANCEL_REQUEST = 'APPOINTMENT_CANCEL_REQUEST';
export const APPOINTMENT_CANCEL_SUCCESS = 'APPOINTMENT_CANCEL_SUCCESS';
export const APPOINTMENT_CANCEL_ERROR = 'APPOINTMENT_CANCEL_ERROR';

export const FETCH_PATIENT_PAST_APPOINTMENTS_REQUEST = 'FETCH_PATIENT_PAST_APPOINTMENTS_REQUEST';
export const FETCH_PATIENT_PAST_APPOINTMENTS_SUCCESS = 'FETCH_PATIENT_PAST_APPOINTMENTS_SUCCESS';
export const FETCH_PATIENT_PAST_APPOINTMENTS_ERROR = 'FETCH_PATIENT_PAST_APPOINTMENTS_ERROR';

export const FETCH_PATIENT_UPCOMING_APPOINTMENTS_REQUEST =
  'FETCH_PATIENT_UPCOMING_APPOINTMENTS_REQUEST';
export const FETCH_PATIENT_UPCOMING_APPOINTMENTS_SUCCESS =
  'FETCH_PATIENT_UPCOMING_APPOINTMENTS_SUCCESS';
export const FETCH_PATIENT_UPCOMING_APPOINTMENTS_ERROR =
  'FETCH_PATIENT_UPCOMING_APPOINTMENTS_ERROR';

export const GET_PATIENT_APPOINTMENT_DETAILS_REQUEST = 'GET_PATIENT_APPOINTMENT_DETAILS_REQUEST';
export const GET_PATIENT_APPOINTMENT_DETAILS_SUCCESS = 'GET_PATIENT_APPOINTMENT_DETAILS_SUCCESS';
export const GET_PATIENT_APPOINTMENT_DETAILS_ERROR = 'GET_PATIENT_APPOINTMENT_DETAILS_ERROR';

export const APPOINTMENT_LOCAL_STEPS_REQUEST = 'APPOINTMENT_LOCAL_STEPS_REQUEST';

export const APPOINTMENT_ADD_REVIEW_REQUEST = 'APPOINTMENT_ADD_REVIEW_REQUEST';
export const APPOINTMENT_ADD_REVIEW_SUCCESS = 'APPOINTMENT_ADD_REVIEW_SUCCESS';
export const APPOINTMENT_ADD_REVIEW_ERROR = 'APPOINTMENT_ADD_REVIEW_ERROR';

export const APPOINTMENT_SHARE_MEDICAL_RECORD_REQUEST = 'APPOINTMENT_SHARE_MEDICAL_RECORD_REQUEST';
export const APPOINTMENT_SHARE_MEDICAL_RECORD_SUCCESS = 'APPOINTMENT_SHARE_MEDICAL_RECORD_SUCCESS';
export const APPOINTMENT_SHARE_MEDICAL_RECORD_ERROR = 'APPOINTMENT_SHARE_MEDICAL_RECORD_ERROR';

export const SET_PENDING_APPOINTMENT = 'SET_PENDING_APPOINTMENT';
