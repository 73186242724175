import { actionChannel, ActionPattern, call, fork, put, take } from 'redux-saga/effects';
import { Action } from 'redux';
import { UPDATE_LOCALE_REQUEST } from 'shared/redux/types/UngroupedTypes';
import { changeLocaleAPI } from 'shared/redux/api/UngroupedApi';
import { updateLocaleError, updateLocaleSuccess } from 'shared/redux/actions/UngroupedActions';

function* updateLocale(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const localeChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(localeChannel);
    try {
      if (payload.isLoggedIn) {
        yield call(changeLocaleAPI, { locale: payload.locale });
      }
      yield put(updateLocaleSuccess({ locale: payload.locale }));
    } catch ({ message }) {
      yield put(updateLocaleError({ message }));
    }
  }
}

function* updateLocaleSaga() {
  yield fork(updateLocale, UPDATE_LOCALE_REQUEST);
}

export default updateLocaleSaga;
