import { storeDispatcher, storeStateGetter } from 'shared/redux/ReduxStore';
import LocalEventsService from 'shared/services/LocalEventsService';
import NavigationService from 'shared/services/NavigationService';
import { forceLogoutRequest } from 'shared/redux/actions/AuthActions';
import {
  doctorAppointmentUpdatesRequest,
  fetchDoctorTodayAppointmentsRequest,
  getAppointmentDetailsRequest,
  getAppointmentDetailsSuccess,
  updateDoctorCallsStackRequest,
} from 'shared/redux/actions/DoctorAppointmentsActions';
import { doctorLocalAppointmentSelector } from 'shared/redux/selector/DoctorAppointmentsSelector';
import { fetchUserProfileRequest } from 'shared/redux/actions/UserProfileActions';
import {
  videoCallGetTokenRequest,
  videoCallGetTokenSuccess,
} from 'shared/redux/actions/VideoCallActions';
import { updateSharedStateAction } from 'shared/redux/actions/UngroupedActions';
import StorageService from 'shared/services/StorageService';
import { default as StringConstants } from 'shared/constants/StringConstants.json';
import { isTwilioScreen } from 'shared/services/EnvService';
import dayjs from 'dayjs';
import { snakeToCamel } from './StringUtils';
import DoctorPushNotifier from './DoctorPushNotifier';
import { getAppointmentSettingsRequest } from '../redux/actions/AppointmentActions';

export const registerDoctorLocalEvents = () => {
  LocalEventsService.offAll();
  LocalEventsService.on('checkEvents', () => {
    // console.warn(`still comes checkEvents ${JSON.stringify(data)}`);
  });
  LocalEventsService.on('loginDone', ({ isProfileCompleted, activeRole }) => {
    storeDispatcher(fetchUserProfileRequest({}));
    if (activeRole === 'ROLE_PHARMACIST') {
      NavigationService.navigate('/doctor/recommend');
    } else if (isProfileCompleted === false) {
      NavigationService.navigate('/doctor/register-step-personal-data');
    } else {
      NavigationService.navigate('/doctor/home');
    }
  });
  LocalEventsService.on('twilioError', () => {
    storeDispatcher(
      doctorAppointmentUpdatesRequest({
        id: 0,
        status: null,
        type: null,
        step: 0,
        scheduledAt: null,
        specializationId: 0,
        payment: {
          isPayed: false,
        },
        doctor: {},
        patient: {},
        conclusion: '',
        tmpConclusion: null,
        callStatus: '',
        startTime: '',
        endTime: '',
        timeEndWithPause: '',
        enableAudio: true,
        enableVideo: true,
        isExtendable: true,
        showTimeBubble: false,
        isInitialTime: true,
        isOtherUserConnected: false,
        otherVideoEnabled: false,
        otherAudioEnabled: false,
      }),
    );
  });
  LocalEventsService.on('logOut', () => {
    storeDispatcher(getAppointmentSettingsRequest({}));
    // window.location.assign('/doctor/login');
  });
  LocalEventsService.on('forceLogout', () => {
    StorageService.getData(StringConstants.REFRESH_TOKEN, null).then((token) => {
      if (!token) return true;
      storeDispatcher(forceLogoutRequest(token));
      return true;
    });
  });
  LocalEventsService.on('callEnded', ({ appointmentId, appointmentType }) => {
    storeDispatcher(getAppointmentDetailsRequest({ id: appointmentId }));
    storeDispatcher(
      doctorAppointmentUpdatesRequest({
        enableAudio: true,
        enableVideo: true,
        isExtendable: true,
        isInitialTime: true,
        showTimeBubble: false,
        status: 'needConclusion',
        callStatus: 'terminated',
        showNextAppointment: false,
        isOtherUserConnected: false,
        otherVideoEnabled: false,
        otherAudioEnabled: false,
      }),
    );
    storeDispatcher(
      updateSharedStateAction({
        showNextAppointment: false,
        showWaitingTime: false,
        isNotificationInProgress: false,
      }),
    );
    if (appointmentType !== 'free_talk') {
      NavigationService.navigate(`/doctor/final-consultation/${appointmentId}/review-conclusions`);
    }
  });
  LocalEventsService.on('joinVideoCall', () => {
    if (localStorage.getItem('Answer_Call') === 'true') {
      storeDispatcher(
        doctorAppointmentUpdatesRequest({
          callStatus: 'active',
          status: 'inProgress',
        }),
      );
      NavigationService.navigate('/doctor/video-call');
    }
  });
  LocalEventsService.on('needForceUpdate', () => {
    storeDispatcher(
      updateSharedStateAction({
        needForceUpdate: true,
      }),
    );
  });
  LocalEventsService.on('dontNeedForceUpdate', () => {
    storeDispatcher(
      updateSharedStateAction({
        needForceUpdate: false,
      }),
    );
  });
  LocalEventsService.on('currentAppointmentData', (appointments) => {
    const callsStack: any[] = [];
    const callsInProgress: any[] = [];
    const { acceptedCallSpecialistAppointments } = storeStateGetter('doctorCallsStackState');
    appointments.forEach((data) => {
      if (
        data.type === 'call_specialist' &&
        // Doctor dashboard is different from mobile
        // ['searching_for_doctor', 'waiting_for_payment', 'ready_to_start'].indexOf(data.status) > -1
        ['searching_for_doctor'].indexOf(data.status) > -1
      ) {
        let state = 'readyToStart';

        if (
          data.status === 'searching_for_doctor' &&
          acceptedCallSpecialistAppointments.indexOf(data.id) > -1
        ) {
          state = 'pendingResult';
        } else if (
          data.status === 'searching_for_doctor' &&
          acceptedCallSpecialistAppointments.indexOf(data.id) === -1
        ) {
          state = 'waitingForAction';
        }

        callsStack.push({
          id: data.id,
          state,
          appointment: {
            id: data.id,
            timeStart: data?.time_start,
            timeEnd: data?.time_end,
            timeEndWithPause: data?.time_end_with_pause,
            priceAsString: data?.price_as_string,
          },
          patient: {
            id: data.patient.id,
            firstName: data.patient.first_name,
            lastName: data.patient.last_name,
            pictureMedia: data.patient.picture_media?.public_url,
            birthDate: data.patient.birthDate,
          },
          doctor: {},
        });
      }

      const nowTimeStamp = dayjs().valueOf();
      const startTimeStamp = dayjs(data.time_start).valueOf();
      const endTimeWithPauseStamp = dayjs(data.time_end_with_pause).valueOf();
      const inProgress =
        startTimeStamp < nowTimeStamp &&
        nowTimeStamp < endTimeWithPauseStamp &&
        (data.status === 'ready_to_start' || data.status === 'in_progress');

      if (inProgress) {
        callsInProgress.push(data);
      }
    });

    storeDispatcher(updateDoctorCallsStackRequest(callsStack));
    storeDispatcher(fetchDoctorTodayAppointmentsRequest({}));

    if (callsInProgress.length > 0) {
      const [data] = callsInProgress;
      const { isResident } = storeStateGetter('userProfileState');
      storeDispatcher(getAppointmentDetailsSuccess(data));
      storeDispatcher(doctorAppointmentUpdatesRequest(doctorLocalAppointmentSelector(data)));

      if (isResident) {
        storeDispatcher(
          doctorAppointmentUpdatesRequest({
            enableVideo: !isResident,
          }),
        );
      }

      storeDispatcher(
        updateSharedStateAction({
          showNextAppointment: false,
          showWaitingTime: false,
          isNotificationInProgress: false,
        }),
      );
      storeDispatcher(getAppointmentDetailsRequest({ id: data.id }));
      storeDispatcher(videoCallGetTokenRequest({ appointmentId: data.id }));
      storeDispatcher(
        doctorAppointmentUpdatesRequest({
          step: Math.random() * 100,
          status: snakeToCamel(data?.status),
          callStatus: 'active',
        }),
      );
    }
  });
  LocalEventsService.on('noCurrentAppointment', () => {
    storeDispatcher(updateDoctorCallsStackRequest([]));
    storeDispatcher(fetchDoctorTodayAppointmentsRequest({}));
    const { id } = storeStateGetter('doctorAppointmentState');
    storeDispatcher(videoCallGetTokenSuccess({}));
    storeDispatcher(
      updateSharedStateAction({
        showNextAppointment: false,
        showWaitingTime: false,
        isNotificationInProgress: false,
      }),
    );
    storeDispatcher(
      doctorAppointmentUpdatesRequest({
        id: 0,
        status: null,
        type: null,
        step: 0,
        scheduledAt: null,
        specializationId: 0,
        payment: {
          isPayed: false,
        },
        doctor: {},
        patient: {},
        conclusion: '',
        callStatus: '',
        startTime: '',
        endTime: '',
        timeEndWithPause: '',
        enableAudio: true,
        enableVideo: true,
        isExtendable: true,
        showTimeBubble: false,
        isInitialTime: true,
      }),
    );

    if (isTwilioScreen()) {
      NavigationService.navigate(`/doctor/final-consultation/${id}/review-conclusions`);
    }
  });
  LocalEventsService.on('pushNotificationClicked', (pushPayload) => {
    DoctorPushNotifier.onForegroundPushReceived({
      data: { ...pushPayload, type: pushPayload.notification_type.type_name },
    });
  });
};

export const removeDoctorLocalEvents = () => {
  LocalEventsService.offAll();
};
