import 'patient/styles/main-screen.css';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import FooterSection from 'shared/components/FooterSection';
import HeaderMenu from 'shared/components/HeaderMenu';
import patientMainScreenRow1 from 'shared/assets/images/patient-main-screen-row1.png';
import specializationHover from 'shared/assets/images/homepage-specialization-accordion-hover.png';
import howItWorksImg1 from 'shared/assets/images/how-it-works-img-1.png';
import howItWorksImg2 from 'shared/assets/images/how-it-works-img-2.png';
import howItWorksImg3 from 'shared/assets/images/how-it-works-img-3.png';
import imageBenefit1 from 'shared/assets/images/homepage-patient-benefit1.svg';
import imageBenefit2 from 'shared/assets/images/homepage-patient-benefit2.svg';
import imageBenefit3 from 'shared/assets/images/homepage-patient-benefit3.svg';
import imageBenefit4 from 'shared/assets/images/homepage-patient-benefit4.svg';
import imageBenefit5 from 'shared/assets/images/homepage-patient-benefit5.svg';
import imageBenefit6 from 'shared/assets/images/homepage-patient-benefit6.svg';
import newsletterIcon from 'shared/assets/images/newsletter-icon.svg';
import ringdocDoctorLogo from 'shared/assets/images/ringdoc-medic-logo.svg';
import doctorSectionImg from 'shared/assets/images/homapge-patient-doctor-section.png';
import doctorSectionImgMobile from 'shared/assets/images/homapge-patient-doctor-section-mobile.png';
import demoAccordionCollapsible0 from 'shared/assets/images/demo-accordion-collapsible-1.png';
import demoAccordionCollapsible1 from 'shared/assets/images/demo-accordion-collapsible-2.png';
import demoAccordionCollapsible2 from 'shared/assets/images/demo-accordion-collapsible-3.png';
import demoAccordionMobile1 from 'shared/assets/images/demo-accordion-mobile1.png';
import demoAccordionMobile2 from 'shared/assets/images/demo-accordion-mobile2.png';
import demoAccordionMobile3 from 'shared/assets/images/demo-accordion-mobile3.png';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Accordion, Modal } from 'react-bootstrap';
import Carousel from 'react-grid-carousel';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import _ from 'lodash';
import Utils from 'shared/modules/Utils';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { isDoctor } from 'shared/services/EnvService';
import {
  getAllReviewsState,
  getAuthState,
  getAvailableDoctorsState,
  getSpecializationsState,
} from 'shared/redux/src/StatesGetter';
import { getSpecializationsRequest } from 'shared/redux/actions/SpecializationActions';
import { fetchAllReviewsRequest } from 'shared/redux/actions/AllReviewsActions';
import { Rating } from 'react-simple-star-rating';
import { getAvailableDoctorsRequest } from 'shared/redux/actions/DoctorsActions';
import openGraphImage from 'shared/assets/images/ringdoc_image_open_graph.png';
import { Helmet } from 'react-helmet';
import LazyImage from 'shared/components/LazyImage';
import * as DOMPurify from 'dompurify';

const PatientMainScreenComponent = ({ page = 'homepage' }) => {
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sanitizer = DOMPurify.sanitize;
  const [activeDemoAccordionSlideIndex, setActiveDemoAccordionSlideIndex] = useState<any>('0');
  const reviews = useSelector(getAllReviewsState, shallowEqual);
  const demoImages = {
    demoAccordionCollapsible0,
    demoAccordionCollapsible1,
    demoAccordionCollapsible2,
  };
  const specializations = useSelector(getSpecializationsState, shallowEqual);
  const { total: doctorsCounter } = useSelector(getAvailableDoctorsState, shallowEqual);
  const baseUrl = window.location.origin;
  useEffect(() => {
    dispatch(
      getSpecializationsRequest({
        searchName: '',
      }),
    );
    dispatch(fetchAllReviewsRequest({}));

    const anchor = window.location.hash.slice(1);
    setTimeout(() => {
      if (anchor) {
        const anchorEl = document.getElementById(anchor);
        if (anchorEl) {
          anchorEl.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, 1000);
  }, []);

  const mailChimpSubmitButton = document.querySelector('.mailchimp-form button');
  if (mailChimpSubmitButton) {
    mailChimpSubmitButton.innerHTML = t('homepage.patient.newsletter.submit');
  }

  const mailChimpInputField = document.querySelector('.mailchimp-form input');
  if (mailChimpInputField) {
    // @ts-ignore
    mailChimpInputField.placeholder = t('email');
  }

  const handleDoctorSpecializationSubmit = (name) => {
    navigate(
      generatePath('/patient/medicList/:specialization', {
        specialization: name,
      }),
    );
  };

  let patientAppStoreLink;
  if (!isDoctor(true)) {
    if (Utils.isiPhone()) {
      patientAppStoreLink = 'itms-apps://apps.apple.com/us/app/ringdoc/id1574488837';
    } else {
      patientAppStoreLink = 'https://apps.apple.com/us/app/ringdoc/id1574488837';
    }
  }
  const renderArrowLeft = () => {
    return (
      <div className="prev-slider">
        <CustomIcon
          className="custom-icon rotate-180"
          color={Colors.darkGrey}
          size="24"
          icon="Right_pointer"
        />
      </div>
    );
  };
  const renderArrowRight = () => {
    return (
      <div className="next-slider">
        <CustomIcon
          className="custom-icon"
          color={Colors.darkGrey}
          size="24"
          icon="Right_pointer"
        />
      </div>
    );
  };

  const benefitsArray = [
    {
      id: 1,
      iconName: imageBenefit1,
      title: t('homepage.patient.benefits.benefit1'),
      iconColor: '#FF6A39',
      bgColor: 'rgba(255, 106, 57, 0.1)',
    },
    {
      id: 2,
      iconName: imageBenefit2,
      title: t('homepage.patient.benefits.benefit2'),
      iconColor: '#6AC684',
      bgColor: 'rgba(106, 198, 132, 0.1)',
    },
    {
      id: 3,
      iconName: imageBenefit3,
      title: t('homepage.patient.benefits.benefit3'),
      iconColor: '#00CFB4',
      bgColor: 'rgba(0, 207, 180, 0.1)',
    },
    {
      id: 4,
      iconName: imageBenefit4,
      title: t('homepage.patient.benefits.benefit4'),
      iconColor: '#FFA3BF',
      bgColor: 'rgba(255, 163, 191, 0.1)',
    },
    {
      id: 5,
      iconName: imageBenefit5,
      title: t('homepage.patient.benefits.benefit5'),
      iconColor: '#F4B700',
      bgColor: 'rgba(244, 183, 0, 0.1)',
    },
    {
      id: 6,
      iconName: imageBenefit6,
      title: t('homepage.patient.benefits.benefit6'),
      iconColor: '#9F8FFF',
      bgColor: 'rgba(159, 143, 255, 0.1)',
    },
  ];

  const howItWorks1 = (
    <div className="box box1">
      <div className="row">
        <div className="col-12 d-flex justify-content-center flex-column">
          <div className="circle one">
            <span>1</span>
          </div>
          <div className="description">{t('homepage.howItWorks.steps.step1Title')}</div>
          <div className="description2">{t('homepage.howItWorks.steps.step1SubTitle')}</div>
          <div className="apps-button">
            <a target="_blank" href={patientAppStoreLink} rel="noreferrer">
              <i className="app-store-icon cursor-pointer" />
            </a>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=ro.ascendnet.alodoc.patient.ro"
              rel="noreferrer"
            >
              <i className="google-play-icon cursor-pointer" />
            </a>
          </div>
        </div>
        <div className={`col-12 ${!isMobile ? 'box-img' : ''}`}>
          <LazyImage
            width={600}
            height={450}
            src={howItWorksImg1}
            className="how-it-works-img"
            alt="Ringdoc"
          />
        </div>
      </div>
    </div>
  );

  const howItWorks2 = (
    <div className="box box2">
      <div className="row">
        <div className="col-12 d-flex justify-content-center flex-column box2FlexG">
          <div className="circle two">
            <span>2</span>
          </div>
          <div className="description">{t('homepage.howItWorks.steps.step2Title')}</div>
          <div className="description2">{t('homepage.howItWorks.steps.step2SubTitle')}</div>
        </div>
        <div className={`col-12 ${!isMobile ? 'box-img' : ''}`}>
          <LazyImage
            width={600}
            height={450}
            src={howItWorksImg2}
            className="how-it-works-img"
            alt="Ringdoc"
          />
        </div>
      </div>
    </div>
  );

  const howItWorks3 = (
    <div className="box box3">
      <div className="row">
        <div className="col-12 d-flex justify-content-center flex-column">
          <div className="circle three">
            <span>3</span>
          </div>
          <div className="description">{t('homepage.howItWorks.steps.step3Title')}</div>
          <div className="description2">{t('homepage.howItWorks.steps.step3SubTitle')}</div>
        </div>
        <div className={`col-12 ${!isMobile ? 'box-img' : ''}`}>
          <LazyImage
            width={600}
            height={450}
            src={howItWorksImg3}
            className="how-it-works-img"
            alt="Ringdoc"
          />
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    dispatch(getAvailableDoctorsRequest({}));
  }, [doctorsCounter]);

  const CustomNewsletterForm = ({ status, message, onValidated }) => {
    let email;
    const submit = () => {
      return (
        email &&
        onValidated({
          EMAIL: email.value,
        })
      );
    };

    return (
      <div className="mailchimp-form">
        {status === 'error' && (
          <div
            className="error-response"
            dangerouslySetInnerHTML={{ __html: sanitizer(t('mailchimpErrorMessage')) }}
          />
        )}
        {status === 'success' && (
          <div
            className="success-response"
            // eslint-disable-next-line max-len
            dangerouslySetInnerHTML={{ __html: sanitizer(t('mailchimpConfirmationMessage')) }}
          />
        )}
        <input
          className="mailchimp-form-email-input form-control"
          ref={(node) => {
            email = node;
            return email;
          }}
          type="email"
          placeholder={t('email')}
        />
        {/* eslint-disable-next-line react/button-has-type */}
        <button className="mailchimp-form-button" onClick={submit}>
          {t('homepage.patient.newsletter.submit')}
        </button>
      </div>
    );
  };

  const redirectToMedicList = () => {
    navigate('/patient/medicList');
  };

  // const defiroProducts = [
  //   {
  //     id: 1,
  //     name: 'Tensiometru cu EKG Defiro, avizat medical, dotat cu acumulator mare',
  //     img: 'https://cdn.shopify.com/s/files/1/0433/7405/1483/files/Group_4.jpg?v=1657614808',
  //     link: 'https://defiro.com/collections/monitorizare-corp/products/tensiometru-defiro-viatom-wireless-avizat-medical-fara-cabluri-%C8%99i-fara-baterii',
  //   },
  //   {
  //     id: 2,
  //     name: 'Ecograf wireless dual, Liniar si Convex, avizat medical',
  //     img: 'https://cdn.shopify.com/s/files/1/0433/7405/1483/files/EcografwirelesssitabletaSamsung.jpg?v=1698997921',
  //     link: 'https://defiro.com/collections/monitorizare-cardio/products/ecograf-wireless-dual-eagle-view-avizat-medical-in-ue',
  //   },
  //   {
  //     id: 3,
  //     name: 'Holter EKG 24h, profesional cu 12 derivatii, cu interpretare avizata medical',
  //     img: 'https://cdn.shopify.com/s/files/1/0433/7405/1483/products/electrozi-cu-gel-pentru-holterul-profesional-cu-12-derivatii-165110.jpg?v=1697191012',
  //     link: 'https://defiro.com/collections/monitorizare-cardio/products/holter-ekg-profesional-th12',
  //   },
  //   {
  //     id: 4,
  //     name: 'Holter EKG (ECG) -24h, personal cu interpretare AI, in mod continuu',
  //     img: 'https://cdn.shopify.com/s/files/1/0433/7405/1483/products/holter-ekg-ecg-24h-personal-cu-interpretare-ai-in-mod-continuu-358340.jpg?v=1697191028',
  //     link: 'https://defiro.com/collections/monitorizare-cardio/products/holter-ekg-profesional-cu-interpretare-ai-in-mod-continuu',
  //   },
  //   {
  //     id: 5,
  //     name: 'Cantar inteligent Defiro, 16 functii, cantar smart, display mare, pentru monitorizarea greutatii',
  //     img: 'https://cdn.shopify.com/s/files/1/0433/7405/1483/files/Group_15_copy.jpg?v=1669134776',
  //     link: 'https://defiro.com/collections/monitorizare-corp/products/cantar-smart-pentru-monitorizarea-greutatii',
  //   },
  //   {
  //     id: 6,
  //     name: 'Spirometru Defiro - Contec SP70B, cu bluetooth si aplicatie mobila',
  //     img: 'https://cdn.shopify.com/s/files/1/0433/7405/1483/products/spirometru-defiro-contec-sp70b-cu-bluetooth-si-aplicatie-mobila-794235.jpg?v=1697191033',
  //     link: 'https://defiro.com/collections/monitorizare-cardio/products/spirometru-defiro-contec-sp70b',
  //   },
  //   {
  //     id: 7,
  //     name: 'Pulsoximetru copii 0-3 ani, somn, puls, procentul de oxigen si miscarile',
  //     img: 'https://defiro.com/cdn/shop/products/pulsoximetru-copii-0-3-ani-somn-puls-procentul-de-oxigen-si-miscarile-884442_1800x1800.jpg?v=1697191016',
  //     link: 'https://defiro.com/collections/dispozitive-pentru-monitorizare-prenatal-si-copii-doppler-personal-pulsoximetru-copii/products/monitor-copii-0-3-ani-somn-puls-si-procentul-de-oxigen',
  //   },
  // ];

  const [showModalVideo, setModalVideoShow] = useState(false);
  const modalVideoShow = () => setModalVideoShow(true);
  const modalVideoClose = () => setModalVideoShow(false);

  return (
    <div className={page === 'isLanding' ? 'main-screen-page-is-landing' : 'main-screen-page'}>
      <Helmet>
        <meta property="og:url" content="URL-ul paginii" />
        <meta property="og:locale" content="ro_RO" />
        <meta property="og:locale:alternate" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Titlul meta al paginii sau h1" />
        <meta property="og:description" content="Continutul meta description" />
        <meta property="og:image" content={`${baseUrl}${openGraphImage}`} />
      </Helmet>
      {page === 'homepage' && <HeaderMenu />}

      <div className="container-fluid p-0">
        <section className="main-screen-gradient1">
          <div className="row main-screen-gradient1-row1">
            <div className="col-12 col-md-6 pt-5 mt-5">
              <h1>{t('medicalApp.homeScreenPatient.header')}</h1>
              <h4>{t('medicalApp.homeScreenPatient.subheader')}</h4>
              <Link to="/patient/medicList" className="green-button">
                <div>
                  <CustomIcon className="custom-icon" color={Colors.white} size="32" icon="Video" />
                </div>
                <div className="btn-text">
                  <div className="see-all-doctors">{t('seeAllDoctors')}</div>
                  <div className="nr-of-doctors">
                    {doctorsCounter > 0 && <>{`${doctorsCounter} ${t('availableSpecialists')}`}</>}
                  </div>
                </div>
              </Link>
              <p>{t('homepage.downloadApp')}</p>
              <div className="apps-button">
                <a target="_blank" href={patientAppStoreLink} rel="noreferrer">
                  <i className="app-store-icon cursor-pointer" />
                </a>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=ro.ascendnet.alodoc.patient.ro"
                  rel="noreferrer"
                >
                  <i className="google-play-icon cursor-pointer" />
                </a>
              </div>
            </div>
            <div
              className="col-12 col-md-6 pe-0 patient-main-screen-row1-image"
              onClick={redirectToMedicList}
            >
              <img className="patient-main-screen-row1" src={patientMainScreenRow1} alt="Ringdoc" />
            </div>
          </div>
          <div className="row how-it-works-wrapper">
            <div className={`container ${!isMobile ? 'container-noMobile' : ''}`}>
              {/* {!isMobile && ( */}
              {/*  <div className="how-it-works-wrapper-play-video-relative"> */}
              {/*    <div className="how-it-works-wrapper-play-video" onClick={modalVideoShow}> */}
              {/*      <CustomIcon */}
              {/*        className="custom-icon" */}
              {/*        color={Colors.darkGrey} */}
              {/*        size="32" */}
              {/*        icon="Play" */}
              {/*      /> */}
              {/*      <span className="vezi-video-ml">{t('seeVideo')}</span> */}
              {/*    </div> */}
              {/*  </div> */}
              {/* )} */}
              <h1>{t('howItWorks.patient.title')}</h1>
              {!isMobile && (
                <div
                  className="how-it-works-wrapper-play-video-center-notmobile"
                  onClick={modalVideoShow}
                >
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.darkGrey}
                    size="32"
                    icon="Play"
                  />
                  <span className="vezi-video-ml">{t('seeVideo')}</span>
                </div>
              )}
              {isMobile && (
                <div className="how-it-works-wrapper-play-video-center" onClick={modalVideoShow}>
                  {t('seeVideo')}
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.darkGrey}
                    size="32"
                    icon="Play"
                  />
                </div>
              )}
              {isMobile ? (
                <div className="specializations-carousel">
                  <Carousel
                    cols={1}
                    rows={3}
                    gap={10}
                    loop
                    autoplay={5000}
                    arrowLeft={renderArrowLeft}
                    arrowRight={renderArrowRight}
                    responsiveLayout={[
                      {
                        breakpoint: 1200,
                        cols: 1,
                        rows: 1,
                      },
                      {
                        breakpoint: 990,
                        cols: 1,
                        rows: 1,
                      },
                    ]}
                    mobileBreakpoint={100}
                  >
                    <Carousel.Item>{howItWorks1}</Carousel.Item>
                    <Carousel.Item>{howItWorks2}</Carousel.Item>
                    <Carousel.Item>{howItWorks3}</Carousel.Item>
                  </Carousel>
                </div>
              ) : (
                <>
                  {howItWorks1}
                  {howItWorks2}
                  {howItWorks3}
                </>
              )}
            </div>
          </div>
        </section>

        <section className="main-screen-gradient2" id="make-online-consultations">
          <div className="container">
            <h1>{t('homepage.patient.demo.title')}</h1>
            <div className="row main-screen-gradient2-content">
              {!isMobile && (
                <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
                  <div className="box-transparent demo-img">
                    <LazyImage
                      width={1234}
                      height={1584}
                      src={demoImages.demoAccordionCollapsible0}
                      alt="Ringdoc"
                      hidden={activeDemoAccordionSlideIndex !== '0'}
                    />
                    <LazyImage
                      width={1234}
                      height={1388}
                      src={demoImages.demoAccordionCollapsible1}
                      alt="Ringdoc"
                      hidden={activeDemoAccordionSlideIndex !== '1'}
                    />
                    <LazyImage
                      width={1234}
                      height={1420}
                      src={demoImages.demoAccordionCollapsible2}
                      alt="Ringdoc"
                      hidden={activeDemoAccordionSlideIndex !== '2'}
                    />
                  </div>
                </div>
              )}
              <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                <div className="box-white accordion-wrapper-div">
                  <Accordion
                    defaultActiveKey="0"
                    onSelect={(e) => {
                      if (e !== null) {
                        setActiveDemoAccordionSlideIndex(e);
                      }
                    }}
                  >
                    <Accordion.Item eventKey="0" id="acc0">
                      <Accordion.Header>
                        <div className="circle one">
                          <span>1</span>
                        </div>
                        <div className="description">{t('homepage.patient.demo.step1.title')}</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {t('homepage.patient.demo.step1.description')}
                        {isMobile && (
                          <div className="demo-image-wrapper">
                            <LazyImage
                              width={457}
                              height={632}
                              src={demoAccordionMobile1}
                              className="demo-img"
                              alt="Ringdoc"
                            />
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" id="acc1">
                      <Accordion.Header>
                        <div className="circle two">
                          <span>2</span>
                        </div>
                        <div className="description">{t('homepage.patient.demo.step2.title')}</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {t('homepage.patient.demo.step2.description')}
                        {isMobile && (
                          <div className="demo-image-wrapper">
                            <LazyImage
                              width={457}
                              height={632}
                              src={demoAccordionMobile2}
                              className="demo-img"
                              alt="Ringdoc"
                            />
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" id="acc2">
                      <Accordion.Header>
                        <div className="circle three">
                          <span>3</span>
                        </div>
                        <div className="description">{t('homepage.patient.demo.step3.title')}</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {t('homepage.patient.demo.step3.description')}
                        {isMobile && (
                          <div className="demo-image-wrapper">
                            <LazyImage
                              width={457}
                              height={550}
                              src={demoAccordionMobile3}
                              className="demo-img"
                              alt="Ringdoc"
                            />
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>

            <div className="row homepage-specializations" id="homepage-specializations">
              <h1>{t('homepage.patient.specializations.title')}</h1>
              <div className="specializations-carousel">
                <Carousel
                  cols={4}
                  rows={2}
                  gap={10}
                  loop
                  autoplay={5000}
                  arrowLeft={renderArrowLeft}
                  arrowRight={renderArrowRight}
                  responsiveLayout={[
                    {
                      breakpoint: 1200,
                      cols: 3,
                    },
                    {
                      breakpoint: 990,
                      cols: 2,
                    },
                    {
                      breakpoint: 670,
                      cols: 2,
                    },
                  ]}
                  mobileBreakpoint={100}
                >
                  {specializations?.data?.map((specialization) => {
                    return (
                      <Carousel.Item
                        key={specialization.id}
                        onClick={() => handleDoctorSpecializationSubmit(specialization.slug)}
                      >
                        <div className="specializations-carousel-item hoverable-item">
                          <div className="specializations-carousel-item-content hoverable-hide">
                            <LazyImage
                              width={65}
                              height={80}
                              src={specialization?.media?.url}
                              className="carousel-image-specializations"
                              alt={specialization.name}
                            />
                            <div className="name" title={specialization.name}>
                              {specialization.name}
                            </div>
                          </div>
                          <div className="specializations-carousel-item-hover hoverable-show">
                            <LazyImage
                              width={195}
                              height={100}
                              src={specializationHover}
                              alt={specialization.name}
                            />
                            <div className="name" title={specialization.name}>
                              {specialization.name}
                            </div>
                            <div
                              id={`specialization-${specialization.id}`}
                              className="orange-button"
                              onClick={() => handleDoctorSpecializationSubmit(specialization.slug)}
                            >
                              {t('homepage.patient.specializations.button')}
                            </div>
                          </div>
                        </div>
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              </div>
            </div>

            <div className="row homepage-benefits" id="homepage-benefits">
              <h1>{t('homepage.patient.benefits.title')}</h1>
              <div className="row benefit-collection">
                {benefitsArray.map((benefit, index) => {
                  return (
                    <div className="col-6 col-md-4 benefit-item-container" key={_.uniqueId()}>
                      <div className="benefit-item">
                        <div
                          className="benefit-img-wrapper"
                          style={{ backgroundColor: benefit.bgColor }}
                        >
                          <LazyImage
                            width={62}
                            height={62}
                            src={benefit.iconName}
                            alt={benefit.title}
                          />
                        </div>
                        <div className="title">{benefit.title}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>

        <section className="main-screen-gradient3">
          <div className="container">
            {!isLoggedIn && (
              <div className="row homepage-permanent-access">
                <p>{t('homepage.patient.permanentAccess.title')}</p>
                <Link to="/patient/register" className="orange-button">
                  {t('patientApp.createAccount')}
                </Link>
              </div>
            )}
            {reviews.length > 0 && (
              <>
                <div className="row homepage-feedback">
                  <h1>{t('homepage.patient.feedback.title')}</h1>
                </div>
                <div className="feedback-carousel">
                  <Carousel
                    cols={3}
                    rows={1}
                    gap={10}
                    loop
                    autoplay={5000}
                    arrowLeft={renderArrowLeft}
                    arrowRight={renderArrowRight}
                    responsiveLayout={[
                      {
                        breakpoint: 1200,
                        cols: 3,
                      },
                      {
                        breakpoint: 990,
                        cols: 2,
                      },
                      {
                        breakpoint: 670,
                        cols: 1,
                      },
                    ]}
                    mobileBreakpoint={100}
                  >
                    {reviews.map((review) => {
                      return (
                        <Carousel.Item key={review.id}>
                          <div className="review-carousel-item">
                            <div className="row d-flex justify-content-between align-items-center mb-2">
                              <div className="col-6 review-details">
                                <p className="mb-0">
                                  {`${review.originator ?? '-'} `}
                                  <span>{`· ${review.city ?? '-'}`}</span>
                                </p>
                              </div>
                              <div className="stars col-6">
                                <Rating
                                  fillColor="#FF6A39"
                                  size={20}
                                  readonly
                                  initialValue={review.rating ?? 0}
                                  allowFraction
                                />
                              </div>
                            </div>
                            <p className="review-message">{review.remarks ?? '-'}</p>
                            <hr />
                            <div className="row d-flex justify-content-between align-items-center">
                              <div className="d-flex flex-column col-7 review-doctor-details">
                                <p>{review.doctorFullName ?? '-'}</p>
                                <p className="smaller-text">{review.specialization ?? '-'}</p>
                              </div>
                              <div className="col-5 profile-picture d-flex flex-row align-items-center justify-content-end">
                                {!review?.doctorImage && (
                                  <div className="avatar" style={{ backgroundColor: '#E5E7E8' }}>
                                    <div className="image-placeholder">{`${review?.doctorFirstName?.charAt(
                                      0,
                                    )}${review?.doctorLastName?.charAt(0)}`}</div>
                                  </div>
                                )}
                                {review?.doctorImage && (
                                  <div className="avatar">
                                    <LazyImage
                                      width={35}
                                      height={35}
                                      src={review?.doctorImage}
                                      alt="doc_image"
                                    />
                                  </div>
                                )}
                                <CustomIcon
                                  className="custom-icon"
                                  color={Colors.darkGrey}
                                  size="18"
                                  icon="Right_pointer"
                                />
                              </div>
                            </div>
                          </div>
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                </div>
              </>
            )}

            {/* {defiroProducts.length > 0 && ( */}
            {/*  <> */}
            {/*    <div className="row homepage-feedback"> */}
            {/*      <h1>{t('homepage.patient.defiro.title')}</h1> */}
            {/*    </div> */}
            {/*    <div className="defiro-carousel"> */}
            {/*      <Carousel */}
            {/*        cols={3} */}
            {/*        rows={1} */}
            {/*        gap={10} */}
            {/*        loop */}
            {/*        autoplay={5000} */}
            {/*        arrowLeft={renderArrowLeft} */}
            {/*        arrowRight={renderArrowRight} */}
            {/*        responsiveLayout={[ */}
            {/*          { */}
            {/*            breakpoint: 1200, */}
            {/*            cols: 3, */}
            {/*          }, */}
            {/*          { */}
            {/*            breakpoint: 990, */}
            {/*            cols: 2, */}
            {/*          }, */}
            {/*          { */}
            {/*            breakpoint: 670, */}
            {/*            cols: 1, */}
            {/*          }, */}
            {/*        ]} */}
            {/*        mobileBreakpoint={100} */}
            {/*      > */}
            {/*        {defiroProducts.map((product) => { */}
            {/*          return ( */}
            {/*            <Carousel.Item key={product.id}> */}
            {/*              <div className="defiro-carousel-product"> */}
            {/*                <a href={product.link} target="_blank" rel="noreferrer"> */}
            {/*                  <img src={product.img} alt={product.name} /> */}
            {/*                  <span>{product.name}</span> */}
            {/*                </a> */}
            {/*              </div> */}
            {/*            </Carousel.Item> */}
            {/*          ); */}
            {/*        })} */}
            {/*      </Carousel> */}
            {/*    </div> */}
            {/*  </> */}
            {/* )} */}

            <div className="row homepage-newsletter">
              <img src={newsletterIcon} alt="Ringdoc" />
              <h1>{t('homepage.patient.newsletter.title')}</h1>
              <h4>{t('homepage.patient.newsletter.content')}</h4>
              <MailchimpSubscribe
                url="https://alodoc.us1.list-manage.com/subscribe/post?u=6ff6095761fe5ec7125b6ce6c&amp;id=2c83ad6e6f&amp;f_id=0036fee5f0"
                render={({ subscribe, status, message }) => (
                  <CustomNewsletterForm
                    status={status}
                    message={message}
                    onValidated={(formData) => subscribe(formData)}
                  />
                )}
              />
              <p>{t('homepage.patient.newsletter.footnote')}</p>
            </div>

            <div className="row homepage-doctor-section">
              <img className="homepage-doctor-section-logo" src={ringdocDoctorLogo} alt="Ringdoc" />
              <h1>{t('homepage.patient.doctorSection.title')}</h1>
              <Link to="/doctor/landing" className="green-button" id="">
                {t('homepage.patient.doctorSection.button')}
              </Link>
              {isMobile ? (
                <LazyImage
                  width={370}
                  height={320}
                  src={doctorSectionImgMobile}
                  className="homepage-doctor-section-img"
                  alt="Ringdoc"
                />
              ) : (
                <LazyImage
                  width={2149}
                  height={1096}
                  src={doctorSectionImg}
                  className="homepage-doctor-section-img"
                  alt="Ringdoc"
                />
              )}
            </div>
          </div>
        </section>
      </div>
      <Modal
        show={showModalVideo}
        onHide={modalVideoClose}
        animation
        centered
        className="modal-video"
      >
        <Modal.Header closeButton closeLabel={t('close')} />

        <Modal.Body>
          <div className="iframe-responsive">
            <iframe
              src="https://www.youtube.com/embed/EfG0jJVO4DQ?si=6arPcltAPFfqHg6I"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>
        </Modal.Body>
      </Modal>
      {page !== 'isLanding' && <FooterSection />}
    </div>
  );
};

export default PatientMainScreenComponent;
