import { AnyAction } from 'redux';
import { TWILIO_TOKEN_SUCCESS } from 'shared/redux/types/TwilioTypes';
import { VIDEO_CALL_GET_TOKEN_SUCCESS } from '../types/VideoCallTypes';

const VideoCallTokenReducer = (
  state = {
    token: '',
    room: '',
    consultationId: 0,
    userId: 0,
    user: '',
  },
  action: AnyAction = (() => {}) as unknown as AnyAction,
) => {
  switch (action.type) {
    case VIDEO_CALL_GET_TOKEN_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export { VideoCallTokenReducer };
