import { AnyAction } from 'redux';
import { patientProfilePlaceholder } from 'shared/redux/selector/PatientProfileSelector';
import {
  FETCH_PATIENT_PROFILE_SUCCESS,
  GET_PATIENT_MEDICAL_RECORDS_SUCCESS,
  PATCH_PATIENT_PROFILE_SUCCESS,
  PATIENT_DELETE_MEDICAL_RECORD_SUCCESS,
  GET_PATIENT_VOUCHERS_SUCCESS,
  LOCALLY_CURRENT_PATIENT_PROFILE,
} from 'shared/redux/types/PatientProfileTypes';
import { LOGIN_SUCCESS } from 'shared/redux/types/AuthTypes';

const PatientProfileReducer = (state = patientProfilePlaceholder, action: AnyAction) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, ...action.payload.userData };
    case FETCH_PATIENT_PROFILE_SUCCESS:
    case PATCH_PATIENT_PROFILE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const PatientMedicalRecordsReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case GET_PATIENT_MEDICAL_RECORDS_SUCCESS:
      return action.payload;
    case PATIENT_DELETE_MEDICAL_RECORD_SUCCESS:
      return state.filter((row: Record<string, string>) => row?.id !== action.payload.mediaId);
    default:
      return state;
  }
};

const LocalCurrentPatientProfileReducer = (
  state = {},
  action: AnyAction = (() => {}) as unknown as AnyAction,
) => {
  switch (action.type) {
    case LOCALLY_CURRENT_PATIENT_PROFILE:
      return action.payload;
    default:
      return state;
  }
};

export { PatientProfileReducer, PatientMedicalRecordsReducer, LocalCurrentPatientProfileReducer };
