import Utils from 'shared/modules/Utils';
import { getEnv } from 'shared/services/EnvService';
import { resolveDoctorSpecializationName } from 'shared/modules/DoctorDataFormatter';
import dayjs from 'dayjs';

const getStickyBar = (status, inProgress, paymentStatus, appointmentType) => {
  if (inProgress) return 'started';
  let stickyState = '';
  switch (status) {
    case 'searching_for_doctor':
      if (appointmentType === 'callSpecialist') {
        stickyState = 'SearchCallSpecialistDoctor';
      } else {
        stickyState = 'SearchDoctor';
      }
      break;
    case 'waiting_for_payment':
      if (appointmentType === 'callSpecialist') {
        stickyState = 'WaitForCallSpecialistPayment';
      } else {
        stickyState = '';
      }
      break;
    case 'ready_to_start':
      if (appointmentType === 'callSpecialist') {
        stickyState = 'WaitForCallSpecialistDoctor';
      } else {
        stickyState = 'WaitForDoctor';
      }
      break;
    default:
      stickyState = '';
      break;
  }
  const paymentSticky = paymentStatus.isPaid ? '' : 'WaitForPayment';
  return stickyState.length ? stickyState : paymentSticky;
};

const resolveDoctorDetails = (doctorSpecialization) => {
  const { doctor = {}, specialization } = doctorSpecialization ?? {};
  return {
    id: doctor?.id,
    firstName: doctor.is_resident ? 'Medic' : doctor.first_name ?? '',
    lastName: doctor.is_resident ? 'Ringdoc' : doctor.last_name ?? '',
    name: doctor.is_resident ? `Medic Ringdoc` : doctor.full_name_with_title ?? '',
    fullNameWithTitle: doctor.is_resident ? `Medic Ringdoc` : doctor.full_name_with_title ?? '',
    imgUrl: doctor.is_resident
      ? getEnv('RESIDENT_PROFILE_PICTURE')
      : doctor.picture_media?.public_url ?? null,
    averageRating: doctor.average_rating,
    countRating: doctor.count_rating,
    specialization: resolveDoctorSpecializationName(specialization),
  };
};

export const localAppointmentSelector = (data) => {
  const paymentStatus = {
    isPaid: data.is_paid,
    status: data.is_paid ? 'Confirmed' : 'Pending',
  };
  const appointmentType = Utils.appointmentTypeMapper(data.appointment_type.type);
  const nowTimeStamp = dayjs().valueOf();
  const startTimeStamp = dayjs(data.time_start).valueOf();
  const endTimeStamp = dayjs(data.time_end).valueOf();
  const endTimeWithPauseStamp = dayjs(data.time_end_with_pause).valueOf();
  const inProgress =
    startTimeStamp < nowTimeStamp &&
    nowTimeStamp < endTimeWithPauseStamp &&
    (data.status === 'ready_to_start' ||
      data.status === 'in_progress' ||
      data.status === 'in curs');
  return {
    id: data.id,
    stickyStatus: getStickyBar(data.status, inProgress, paymentStatus, appointmentType),
    inProgress,
    duration: data.duration_in_minutes,
    startTime: data.time_start,
    endTime: data.time_end,
    type: appointmentType,
    price: data.price_as_string,
    rawPrice: data.price,
    scheduledAt: null,
    specializationId: data.doctor_specialization?.id,
    isActive: true,
    doctor: resolveDoctorDetails(data.doctor_specialization),
    patient: {
      id: data.patient?.id,
      name: data.patient?.full_name_with_title,
      fullNameWithTitle: data.patient?.full_name_with_title,
      firstName: data.patient?.first_name,
      lastName: data.patient?.last_name,
      pictureMedia: data.patient?.picture_media?.public_url,
      imgUrl: data.patient?.picture_media?.public_url,
    },
    sharedFiles: data.appointment_patient_medical_records?.map(
      (record) => record?.patient_medical_record?.id,
    ),
    isInitialTime: nowTimeStamp < endTimeStamp,
    payment: paymentStatus,
  };
};
