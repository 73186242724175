import i18next from 'i18next';
import {
  translatedDate,
  translatedFullDate,
  translatedMonthAndYear,
} from 'shared/modules/DateTimeUtils';
import Utils from 'shared/modules/Utils';
import dayjs from 'dayjs';
import { patientProfilePlaceholder } from './PatientProfileSelector';

const resolveLanguages = (languages = []) => {
  return languages.map((lang: any) => {
    return `${lang.translations?.find((row) => row.locale === i18next.language)?.name ?? ''} `;
  });
};

const solveStatus = (status) => {
  switch (status) {
    case 'created':
      return `${i18next.t('states.created')}`;
    case 'profile_selected':
      return `${i18next.t('states.profile_selected')}`;
    case 'waiting_for_payment':
      return `${i18next.t('states.waiting_for_payment')}`;
    case 'searching_for_doctor':
      return `${i18next.t('states.searching_for_doctor')}`;
    case 'ready_to_start':
      return `${i18next.t('states.ready_to_start')}`;
    case 'rejected_by_doctor':
      return `${i18next.t('states.rejected_by_doctor')}`;
    case 'cancelled_search':
      return `${i18next.t('states.cancelled_search')}`;
    case 'deleted':
      return `${i18next.t('states.deleted')}`;
    case 'cancelled_by_patient':
      return `${i18next.t('states.cancelled_by_patient')}`;
    case 'in_progress':
      return `${i18next.t('states.in_progress')}`;
    case 'cancelled_by_doctor':
      return `${i18next.t('states.cancelled_by_doctor')}`;
    case 'call_ended':
      return `${i18next.t('states.call_ended')}`;
    case 'completed':
      return `${i18next.t('states.completed')}`;
    case 'cancelled':
      return `${i18next.t('states.cancelled')}`;
    case 'canceled':
      return `${i18next.t('states.canceled')}`;
    default:
      return status;
  }
};

const resolveDoctorDetails = (doctorSpecialization) => {
  if (!doctorSpecialization) {
    return null;
  }
  const { specialization } = doctorSpecialization;
  return {
    id: doctorSpecialization?.id,
    specialization: {
      id: specialization?.id,
      isActive: specialization.is_active,
      isVisible: specialization.is_visible,
      name: specialization.translations?.find((row) => row.locale === i18next.language)?.name ?? '',
      description:
        specialization.translations?.find((row) => row.locale === i18next.language)?.description ??
        '',
      isValidated: specialization.is_validated,
      isAvailable: specialization.is_available,
      price: specialization.price_as_string,
    },
  };
};

const resolvePatientKinshipDiseases = (diseases) => {
  return diseases?.map((row) => {
    return {
      id: row?.id,
      kinship: row.kinship.translations?.find((r) => r.locale === i18next.language)?.name ?? '',
      diseases: row.diseases,
    };
  });
};

const resolvePatientDetails = (patient) => {
  if (!patient) {
    return patientProfilePlaceholder;
  }
  return {
    id: patient?.id,
    email: patient?.email,
    firstName: patient?.first_name,
    lastName: patient?.last_name,
    accountStatus: patient?.account_status,
    numericPersonalCode: patient?.numeric_personal_code,
    birthDate: patient?.birth_date,
    gender: Utils.solveGender(patient?.gender),
    phonePrefix: patient?.phone_number_country?.calling_code,
    phonePrefixId: patient?.phone_number_country?.id,
    phone: patient?.phone_number,
    picture: patient?.picture_media?.public_url ?? null,
    userLanguages: resolveLanguages(patient?.user_languages),
    weight: patient?.weight ? parseInt(patient?.weight, 10) : '',
    height: patient?.height ? parseInt(patient?.height, 10) : '',
    bloodSystem: patient?.blood_system,
    isSmoker: patient?.is_smoker,
    isAlcoholConsumer: patient?.is_alcohol_consumer,
    knownAllergies: patient?.known_allergies,
    pastMedicalConditions: patient?.past_medical_conditions,
    currentMedicalConditions: patient?.current_medical_conditions,
    patientKinshipDiseases: resolvePatientKinshipDiseases(patient?.patient_kinship_diseases),
  };
};

const resolveUserDetails = (user) => {
  return {
    id: user?.id,
    email: user?.email,
    firstName: user?.first_name,
    lastName: user?.last_name,
    accountStatus: user?.account_status,
    gender: Utils.solveGender(user?.gender),
    numericPersonalCode: user?.numeric_personal_code,
    birthDate: user?.birth_date,
    phonePrefix: user?.phone_number_country?.calling_code,
    phonePrefixId: user?.phone_number_country?.id,
    phone: user?.phone_number,
    picture: user?.picture_media?.public_url ?? null,
    userLanguages: resolveLanguages(user?.user_languages),
  };
};

const resolveAppointmentType = (appointmentType) => {
  return {
    id: appointmentType?.id,
    type: appointmentType.type,
    duration: appointmentType.duration,
    createdAt: appointmentType.created_at,
    updatedAt: appointmentType.updated_at,
    pause: appointmentType.pause,
    isAvailable: appointmentType.is_available,
    minPrice: appointmentType.min_price,
    maxPrice: appointmentType.max_price,
  };
};

const resolveReviews = (reviews) => {
  return reviews?.map((review) => {
    return {
      id: review?.id,
      rating: review.rating,
      remarks: review.remarks,
      createdAt: dayjs(review.created_at).format('YYYY-MM-DD'),
      created_at: dayjs(review.created_at).format('YYYY-MM-DD'),
      originator: review.anonymized_name,
    };
  });
};

const resolveRecords = (records) => {
  const resolveMedias = (record, portfolios) => {
    return portfolios?.map((media) => {
      return {
        id: media?.id,
        mediaId: media.media?.id,
        isAvailable: media.media.status === 'available',
        mediaFileName: record?.patient_medical_record?.title,
        createdAt: dayjs(media.created_at).format('YYYY-MM-DD'),
        investigationDate: record?.patient_medical_record?.investigation_date
          ? dayjs(record?.patient_medical_record?.investigation_date).format('YYYY-MM-DD')
          : dayjs(media.created_at).format('YYYY-MM-DD'),
        createdAtFormatted: translatedDate(media.created_at),
      };
    });
  };
  return records.map((record) => {
    return {
      id: record?.id,
      category:
        record?.patient_medical_record?.medical_record_category?.translations?.find(
          (r) => r.locale === i18next.language,
        )?.name ?? '',
      medias: resolveMedias(
        record,
        record?.patient_medical_record?.files_portfolio?.media_portfolios,
      ),
      investigationDate: record?.patient_medical_record?.investigation_date
        ? dayjs(record?.patient_medical_record?.investigation_date).format('YYYY-MM-DD')
        : dayjs(record.created_at).format('YYYY-MM-DD'),
      createdAt: dayjs(record.created_at).format('YYYY-MM-DD'),
      createdAtFormatted: translatedDate(record.created_at),
    };
  });
};
const doctorAppointmentsSelector = (appointments, withSort = false) => {
  // eslint-disable-next-line no-underscore-dangle
  const appointmentsArray = appointments._embedded?.items ?? [];
  const data: Array<Record<string, unknown>> = [];
  // eslint-disable-next-line array-callback-return
  appointmentsArray.map((row) => {
    data.push({
      id: row?.id,
      user: resolveUserDetails(row.user),
      specializationName:
        row?.doctor_specialization?.specialization?.translations?.find(
          (r) => r.locale === i18next.language,
        )?.name ?? '',
      patient: resolvePatientDetails(row.patient),
      appointmentType: resolveAppointmentType(row.appointment_type),
      doctor: resolveDoctorDetails(row.doctor_specialization),
      date: row.time_start,
      scheduledAt: dayjs(row.time_start).format('YYYY-MM-DD'),
      dateToSort: dayjs(row.time_start).valueOf(),
      type: row.appointment_type.type,
      dateFormatted: translatedMonthAndYear(row.time_start),
      dateFormattedFull: translatedDate(row.time_start),
      time: dayjs(row.time_start).format('HH:mm'),
      serverStatus: row.status,
      status: solveStatus(row.status),
      conclusion: row.appointment_conclusion,
      price: row.price_as_string,
      reviews: resolveReviews(row.reviews),
      medicalRecords: resolveRecords(row.appointment_patient_medical_records),
      duration: row.duration_in_minutes,
      startTime: row.time_start,
      endTime: row.time_end,
      timeEndWithPause: row.time_end_with_pause,
      service: row.service,
    });
  });
  const compare = (a, b) => {
    if (a.dateToSort > b.dateToSort) return 1;
    if (a.dateToSort < b.dateToSort) return -1;
    return 0;
  };
  const { pages } = appointments;
  return { noOfPages: pages, data: withSort ? data.sort(compare) : data };
};

const doctorTodayAppointmentsSelector = (appointments: any[] = []) => {
  const data: Array<Record<string, unknown>> = [];
  let callNowAppointment;

  // eslint-disable-next-line array-callback-return
  appointments.map((appointment) => {
    if (appointment.type === 'call_now') {
      callNowAppointment = appointment;
    } else {
      data.push(appointment);
    }
  });

  const nextScheduledAppointment = data.shift();

  return {
    data,
    callNowAppointment,
    nextScheduledAppointment,
  };
};

const doctorAppointmentDetailsSelector = (appointment) => {
  const resolveFiles = (rawFiles) => {
    if (!rawFiles) return [];
    return rawFiles.map((file) => ({
      name: file?.media?.original_file_name,
      id: file.media?.id,
    }));
  };

  return {
    id: appointment?.id,
    user: resolveUserDetails(appointment.user),
    specializationName:
      appointment?.doctor_specialization?.specialization?.translations?.find(
        (row) => row.locale === i18next.language,
      )?.name ?? '',
    patient: resolvePatientDetails(appointment.patient),
    appointmentType: resolveAppointmentType(appointment.appointment_type),
    date: appointment.time_start,
    dateFormatted: translatedMonthAndYear(appointment.time_start),
    time: dayjs(appointment.time_start).format('HH:mm'),
    status: solveStatus(appointment.status),
    serverStatus: appointment.status,
    type: appointment.appointment_type.type,
    conclusion: {
      files: resolveFiles(
        appointment.appointment_conclusion?.prescriptions_portfolio?.media_portfolios,
      ),
      symptoms: appointment.appointment_conclusion?.symptoms,
      diagnosis: appointment.appointment_conclusion?.diagnosis,
      treatment: appointment.appointment_conclusion?.treatment,
      ongoingTreatments: appointment.appointment_conclusion?.ongoing_treatments,
      knownAllergies: appointment.appointment_conclusion?.known_allergies,
      recommendFutureAppointment: appointment.appointment_conclusion?.recommend_future_appointment,
      recommendations: appointment.appointment_conclusion?.recommendations,
      sentToPatient: appointment.appointment_conclusion?.sent_to_patient,
      updatedAt: appointment.appointment_conclusion?.updated_at,
      updatedAtFormatted: appointment.appointment_conclusion?.updated_at
        ? dayjs(appointment.appointment_conclusion.updated_at).format('YYYY-MM-DD HH:mm')
        : '',
    },
    price: appointment.price_as_string,
    duration: appointment.duration_in_minutes,
    realDurationAsString: appointment.duration_real_in_minutes,
    reviews: resolveReviews(appointment.reviews),
    medicalRecords: resolveRecords(appointment.appointment_patient_medical_records),
    timeStart: appointment.time_start,
    timeEnd: appointment.time_end,
    timeEndWithPause: appointment.time_end_with_pause,
    prescription: appointment.prescription,
    priceGrossAsString: appointment.price_gross_as_string,
  };
};

const doctorLocalAppointmentSelector = (appointment) => {
  const nowTimeStamp = dayjs().valueOf();
  const endTimeStamp = dayjs(appointment.time_end).valueOf();
  return {
    id: appointment?.id,
    appointmentId: appointment?.id,
    specializationId: appointment?.doctor_specialization?.id,
    payment: {
      isPayed: appointment?.is_paid,
    },
    doctor: resolveDoctorDetails(appointment?.doctor_specialization),
    patient: resolvePatientDetails(appointment?.patient),
    conclusion: {
      file: {
        name: appointment?.appointment_conclusion?.prescriptions_portfolio?.media_portfolios[0]
          ?.media?.original_file_name,
        id: appointment?.appointment_conclusion?.prescriptions_portfolio?.media_portfolios[0]?.media
          ?.id,
      },
      symptoms: appointment?.appointment_conclusion?.symptoms,
      diagnosis: appointment?.appointment_conclusion?.diagnosis,
      treatment: appointment?.appointment_conclusion?.treatment,
      recommendFutureAppointment: appointment?.appointment_conclusion?.recommend_future_appointment,
      recommendations: appointment?.appointment_conclusion?.recommendations,
      sentToPatient: appointment?.appointment_conclusion?.sent_to_patient,
      updatedAt: appointment?.appointment_conclusion?.updated_at,
      updatedAtFormatted: appointment?.appointment_conclusion?.updated_at
        ? translatedFullDate(appointment?.appointment_conclusion.updated_at, false)
        : '',
    },
    startTime: appointment?.time_start,
    endTime: appointment?.time_end,
    timeEndWithPause: appointment?.time_end_with_pause,
    isExtendable: nowTimeStamp < endTimeStamp,
    isInitialTime: nowTimeStamp < endTimeStamp,
  };
};

export {
  doctorAppointmentsSelector,
  doctorAppointmentDetailsSelector,
  doctorTodayAppointmentsSelector,
  resolveDoctorDetails,
  resolvePatientDetails,
  doctorLocalAppointmentSelector,
};
