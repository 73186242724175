import { AnyAction } from 'redux';
import {
  FETCH_DOCTOR_REVIEWS_REQUEST,
  FETCH_DOCTOR_REVIEWS_ERROR,
  FETCH_DOCTOR_REVIEWS_SUCCESS,
} from 'shared/redux/types/DoctorReviewsTypes';

export const fetchDoctorReviewsRequest = (payload): AnyAction => ({
  type: FETCH_DOCTOR_REVIEWS_REQUEST,
  payload,
});

export const fetchDoctorReviewsSuccess = (payload): AnyAction => ({
  type: FETCH_DOCTOR_REVIEWS_SUCCESS,
  payload,
});

export const fetchDoctorReviewsError = (payload): AnyAction => ({
  type: FETCH_DOCTOR_REVIEWS_ERROR,
  payload,
});
