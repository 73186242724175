import 'doctor/styles/auth.css';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Alerts from 'shared/components/Alerts';
import { forgotPasswordRequest } from 'shared/redux/actions/AuthActions';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';
import { useTranslation } from 'react-i18next';
import Validators from 'shared/modules/Validators';

const DoctorForgotPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [requiredFieldsError, setRequiredFieldsError] = useState(false);
  const onPress = (e) => {
    e.preventDefault();
    if (!email) {
      Alerts.simpleAlert(t('error'), t('allFieldsMandatory'));
      setRequiredFieldsError(true);
      return;
    }

    const isValidEmail = Validators.validateEmail(email);
    if (!isValidEmail) {
      Alerts.simpleAlert(t('error'), t('alerts.invalidEmail'));
      setRequiredFieldsError(true);
      return;
    }
    dispatch(forgotPasswordRequest({ email }));
  };

  return (
    <section className="forgot-password">
      <form onSubmit={onPress}>
        <div className="title-section">
          <h1>{t('patientApp.resetPassword')}</h1>
        </div>
        <div className="info-message">
          <CustomIcon
            className="custom-icon"
            color={Colors.mediumGrey}
            size="24"
            icon="Warning_2"
          />
          {t('resetPasswordText')}
        </div>
        <div className="form-input">
          <input
            type="email"
            id="email"
            name="email"
            className={`form-control ${
              requiredFieldsError &&
              (email === '' || !Validators.validateEmail(email)) &&
              'is-invalid'
            }`}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            placeholder=" "
          />
          <label htmlFor="email">{t('email')}</label>
        </div>
        <button type="submit" className="green-button">
          {t('patientApp.resetPassword')}
        </button>
      </form>
    </section>
  );
};

export default DoctorForgotPassword;
