import 'doctor/styles/auth.css';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchUserProfileRequest } from 'shared/redux/actions/UserProfileActions';
import DoctorAccountProfileTabPersonal from 'doctor/components/account/DoctorAccountProfileTabPersonal';

const DoctorRegisterPersonalData = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUserProfileRequest({}));
  }, []);
  return (
    <section className="register-steps">
      <div className="title-section">
        <div className="steps">{t('step')} 1/3</div>
        <h1>{t('settings.personalData')}</h1>
      </div>
      <DoctorAccountProfileTabPersonal type="unprofiled" />
    </section>
  );
};

export default DoctorRegisterPersonalData;
