import 'patient/styles/medic-list.css';
import _ from 'lodash';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Modal, Form, Nav } from 'react-bootstrap';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';
import PatientMedicCard from 'patient/screens/medicList/PatientMedicCard';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { generatePath, useLocation, useParams } from 'react-router';
import {
  getAuthState,
  getFavouriteDoctorsState,
  getPatientAppointmentState,
  getPatientLocalChooseSpecializationState,
  getSortingOptionsState,
  getSpecializationDoctorsState,
  getSpecializationsState,
  getUserProfileState,
  getVouchersState,
} from 'shared/redux/src/StatesGetter';
import { useNavigate } from 'react-router-dom';
import {
  getSortingOptionsRequest,
  getSpecializationDoctorsRequest,
  patientLocallyChooseSpecialization,
} from 'shared/redux/actions/DoctorsActions';
import { getSpecializationsRequest } from 'shared/redux/actions/SpecializationActions';
import { appointmentLocalStepsRequest } from 'shared/redux/actions/PatientAppointmentActions';
import { fetchDoctorProfileRequest } from 'shared/redux/actions/DoctorProfileActions';
import { requestAppointment, allowNewAppointment } from 'shared/modules/PatientAppointmentUtils';
import openGraphImage from 'shared/assets/images/ringdoc_image_open_graph.png';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import i18next from 'i18next';
import { isMobile } from 'react-device-detect';
import Carousel from 'react-grid-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import Select, { components, OptionProps } from 'react-select';
import FindADoctorCard from './FindADoctorCard';
import {
  addFavouriteDoctorRequest,
  getFavouriteDoctorsRequest,
  removeFavouriteDoctorRequest,
} from '../../../shared/redux/actions/FavouriteDoctorsActions';
import GAService from '../../../shared/services/GAService';
import { fetchVouchersRequest } from '../../../shared/redux/actions/VouchersActions';
import LazyImage from '../../../shared/components/LazyImage';
import howItWorksImg1 from '../../../shared/assets/images/how-it-works-img-1.png';
import howItWorksImg2 from '../../../shared/assets/images/how-it-works-img-2.png';
import howItWorksImg3 from '../../../shared/assets/images/how-it-works-img-3.png';
import { isDoctor } from '../../../shared/services/EnvService';
import Utils from '../../../shared/modules/Utils';

type medicFiltersAvailable = {
  specializationId: number | null;
  specializationName: string;
  specializationSearch?: string;
  doctorNameSearch?: string;
  seoTitle?: string;
};

type LocationState = {
  medicFilterId: number | null;
  medicFilterName: string;
};

const Option = ({ innerProps, label, data, isSelected }) => {
  return (
    <div className="sort-radio" {...innerProps}>
      <input type="radio" checked={isSelected} readOnly />
      <span>{label}</span>
    </div>
  );
};

const PatientMedicList = () => {
  const { specialization: specializationParamSelected } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const specializationDescriptionRef = useRef<HTMLDivElement>(null);
  const { specialization: urlSpecialization } = useParams();
  const [urlSpecializationRecord, setUrlSpecializationRecord] = useState({
    id: 0,
    name: '',
    description: '',
    metaTitle: '',
    metaDescription: '',
    seoTitle: '',
  });
  const vouchers = useSelector(getVouchersState, shallowEqual);
  const userProfile = useSelector(getUserProfileState, shallowEqual);
  const [isActive, setActive] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showModalControlAlert, setModalControlAlertShow] = useState(false);
  const modalControlAlertClose = () => setModalControlAlertShow(false);
  const modalControlAlertShow = () => setModalControlAlertShow(true);
  const [medicFilters, setMedicFilters] = useState<medicFiltersAvailable>({
    doctorNameSearch: '',
    specializationId: null,
    specializationName: '',
    seoTitle: '',
  });
  const [specializationModal, setSpecializationModal] = useState<medicFiltersAvailable>({
    specializationId: null,
    specializationName: '',
    specializationSearch: '',
  });
  const [doctorNameSearch, setDoctorNameSearch] = useState('');
  const [specializationSearch, setSpecializationSearch] = useState('');
  const specializations = useSelector(getSpecializationsState, shallowEqual);
  const [selectedSpecializationDescription, setSelectedSpecializationDescription] = useState('');
  const [selectedSpecializationMetaTitle, setSelectedSpecializationMetaTitle] = useState('');
  const [selectedSpecializationMetaDescription, setSelectedSpecializationMetaDescription] =
    useState('');
  const [selectedSeoTitle, setSelectedSeoTitle] = useState('');
  const {
    data: doctors,
    currentPage,
    noOfPages,
  } = useSelector(getSpecializationDoctorsState, shallowEqual);
  const preSelectedSpecialization = useSelector(
    getPatientLocalChooseSpecializationState,
    shallowEqual,
  );
  const [filterSearch, setFilterSearch] = useState('');
  let haveActiveDoctors = true;
  const [sortSelectedOption, setSortSelectedOption] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  useEffect(() => {
    if (preSelectedSpecialization && !urlSpecialization) {
      setMedicFilters({
        doctorNameSearch: '',
        specializationId: preSelectedSpecialization.id,
        specializationName: preSelectedSpecialization.name,
        seoTitle: preSelectedSpecialization.seoTitle,
      });
      setSpecializationModal({
        specializationId: preSelectedSpecialization.id,
        specializationName: preSelectedSpecialization.name,
        specializationSearch: '',
      });
      setSelectedSpecializationDescription(preSelectedSpecialization.description);
      setSelectedSpecializationMetaTitle(preSelectedSpecialization.metaTitle);
      setSelectedSeoTitle(preSelectedSpecialization.seoTitle);
      setSelectedSpecializationMetaDescription(preSelectedSpecialization.metaDescription);
    } else {
      setUrlSpecializationRecord(
        specializations.data.find((record) => {
          return record.slug === urlSpecialization;
        }),
      );
    }
  }, [i18next.language]);

  useEffect(() => {
    if (urlSpecializationRecord?.id > 0) {
      setMedicFilters({
        doctorNameSearch: '',
        specializationId: urlSpecializationRecord.id,
        specializationName: urlSpecializationRecord.name,
        seoTitle: urlSpecializationRecord.seoTitle,
      });
      setSpecializationModal({
        specializationId: urlSpecializationRecord.id,
        specializationName: urlSpecializationRecord.name,
        specializationSearch: '',
      });
      setSelectedSpecializationDescription(urlSpecializationRecord.description);
      setSelectedSpecializationMetaTitle(urlSpecializationRecord.metaTitle);
      setSelectedSeoTitle(urlSpecializationRecord.seoTitle);
      setSelectedSpecializationMetaDescription(urlSpecializationRecord.metaDescription);
      dispatch(patientLocallyChooseSpecialization(urlSpecializationRecord));
    }
  }, [urlSpecializationRecord, i18next.language]);

  const [listItems, setListItems] = useState([]);
  const { status: appointmentStatus, stickyStatus } = useSelector(
    getPatientAppointmentState,
    shallowEqual,
  );
  const toggleSearchBar = () => setActive(!isActive);
  const handleSearchNameChange = (e) => {
    setFilterSearch(e.target.value);
    setDoctorNameSearch(e.target.value);
    setMedicFilters({
      ...medicFilters,
      doctorNameSearch: e.target.value,
    });
  };
  const handleSearchNameSubmit = (e) => {
    e.preventDefault();
    if (!doctorNameSearch) {
      setActive(false);
    }
    setMedicFilters({
      ...medicFilters,
      doctorNameSearch,
    });
  };
  const handleSpecializationSearchChange = (e) => setSpecializationSearch(e.target.value);
  const handleDoctorSpecializationChange = (e) => {
    const data = JSON.parse(decodeURIComponent(e.target.getAttribute('data-specialization')));
    setSpecializationModal({
      ...specializationModal,
      specializationId: +e.target.value,
      specializationName: e.target.getAttribute('data-name'),
    });
    setSelectedSpecializationDescription(data.description);
    setSelectedSpecializationMetaTitle(data.metaTitle);
    setSelectedSeoTitle(data.seoTitle);
    setSelectedSpecializationMetaDescription(data.metaDescription);
    dispatch(patientLocallyChooseSpecialization(data));
  };
  const handleDoctorSpecializationClear = () => {
    setSpecializationModal({
      ...specializationModal,
      specializationId: null,
      specializationName: '',
    });
    setSelectedSpecializationDescription('');
    setSelectedSpecializationMetaTitle('');
    setSelectedSeoTitle('');
    setSelectedSpecializationMetaDescription('');
    setMedicFilters({
      ...medicFilters,
      specializationId: null,
      specializationName: '',
    });

    navigate(generatePath('/patient/medicList'));
    dispatch(patientLocallyChooseSpecialization({}));
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }, 500);
  };
  const handleDoctorSpecializationSubmit = (specialization) => {
    setMedicFilters({
      ...medicFilters,
      specializationId: specialization.id,
      specializationName: specialization.name,
      seoTitle: specialization.seoTitle,
    });
    setSelectedSpecializationDescription(specialization.description);
    setSelectedSpecializationMetaTitle(specialization.metaTitle);
    setSelectedSeoTitle(specialization.seoTitle);
    setSelectedSpecializationMetaDescription(specialization.metaDescription);
    dispatch(patientLocallyChooseSpecialization(specialization));
    navigate(
      generatePath('/patient/medicList/:specialization', {
        specialization: specialization.slug,
      }),
    );
    handleClose();
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }, 500);
  };

  useEffect(() => {
    dispatch(getSortingOptionsRequest({}));
    setSelectedSpecializationMetaTitle('');
    if (isLoggedIn) {
      dispatch(fetchVouchersRequest({}));
    }
    if (!specializationParamSelected) {
      setMedicFilters({
        doctorNameSearch: '',
        specializationId: null,
        specializationName: '',
        seoTitle: '',
      });
    }
    if (location.state) {
      const filter = location.state as LocationState;
      setMedicFilters({
        ...medicFilters,
        specializationId: filter.medicFilterId,
        specializationName: filter.medicFilterName,
      });
      navigate(location.pathname, { replace: true });
    }

    const newArray = _.unionBy(listItems, doctors.data, 'rowId');
    setListItems(newArray);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    dispatch(
      getSpecializationsRequest({
        searchName: specializationSearch,
      }),
    );
  }, [specializationSearch, i18next.language]);

  useEffect(() => {
    const { specializationId: id, doctorNameSearch: searchName } = medicFilters;
    // @ts-ignore
    if ((filterSearch !== '' && searchName?.length > 2) || filterSearch === '') {
      dispatch(
        getSpecializationDoctorsRequest({
          id,
          searchName,
          // @ts-ignore
          sortingOptions: sortOption,
        }),
      );
    }
  }, [medicFilters, i18next.language]);

  useEffect(() => {
    const newArray = _.unionBy(listItems, doctors.data, 'rowId');
    setListItems(newArray);
  }, [doctors]);

  const onScheduleClicked = (doctor, specializationId, hasControl) => {
    if (!allowNewAppointment(appointmentStatus, stickyStatus)) {
      return;
    }
    dispatch(fetchDoctorProfileRequest({ id: doctor?.id }));

    if (hasControl) {
      dispatch(
        appointmentLocalStepsRequest({
          newAppointment: true,
          noOfSteps: 6,
          specializationId,
          type: 'programmed',
          doctor,
          hasControl: true,
        }),
      );
      navigate(`/patient/appointment/choose-service?ds=${doctor.rowId}`);
    } else {
      dispatch(
        appointmentLocalStepsRequest({
          newAppointment: true,
          noOfSteps: 5,
          specializationId,
          type: 'programmed',
          doctor,
          service: 'consultation',
          hasControl: false,
        }),
      );
      navigate('/patient/appointment/set-date');
    }
  };

  const onCallNowClicked = (doctor, specializationId) => {
    if (!allowNewAppointment(appointmentStatus, stickyStatus)) {
      return;
    }
    const specializationName = doctor.doctorSpecializations
      ?.map((specialization) => specialization.name)
      .join(', ');

    GAService.event('web_callNow', { specializationName, specializationId });
    requestAppointment('callNow', {
      forRequest: {
        doctor: `${doctor?.id}`,
        specialization: `${specializationId}`,
      },
      forUpdate: {
        specializationId,
        doctor,
      },
    });
  };

  const loadMore = () => {
    const { specializationId: id, doctorNameSearch: searchName } = medicFilters;

    dispatch(
      getSpecializationDoctorsRequest({
        id,
        searchName,
        // @ts-ignore
        sortingOptions: sortOption,
        page: currentPage,
        nextPage: true,
      }),
    );
  };

  useEffect(() => {
    if (specializationDescriptionRef.current) {
      if (selectedSpecializationDescription) {
        specializationDescriptionRef.current.innerHTML = selectedSpecializationDescription;
      } else {
        specializationDescriptionRef.current.innerHTML = '';
      }
    }
  }, [selectedSpecializationDescription]);
  const currentUrl = window.location.href;
  const baseUrl = window.location.origin;

  const redirectToVouchers = () => {
    navigate({
      pathname: '/patient/account',
      search: '?section=vouchers',
    });
  };

  const { ids: favouriteDoctorsIds } = useSelector(getFavouriteDoctorsState, shallowEqual);
  const [mobileSearchSpecialization, setMobileSearchSpecialization] = useState(false);
  const toggleFavorite = (id) => {
    if (favouriteDoctorsIds.includes(id)) {
      dispatch(removeFavouriteDoctorRequest(parseInt(id, 10)));
    } else {
      dispatch(addFavouriteDoctorRequest(parseInt(id, 10)));
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getFavouriteDoctorsRequest({ limit: 500 }));
    }
  }, [i18next.language]);

  const searchSpecializationMobile = useRef(null);

  const canonicalPath = `https://ringdoc.ro${location.pathname}`;

  const [popEnabled, setPopEnabled] = useState(false);

  const showPopUp = () => {
    setPopEnabled(true);
  };

  const closePopUp = () => {
    setPopEnabled(false);
  };

  let patientAppStoreLink;
  if (!isDoctor(true)) {
    if (Utils.isiPhone()) {
      patientAppStoreLink = 'itms-apps://apps.apple.com/us/app/ringdoc/id1574488837';
    } else {
      patientAppStoreLink = 'https://apps.apple.com/us/app/ringdoc/id1574488837';
    }
  }

  const howItWorks1 = (
    <div className="box box1">
      <div className="row">
        <div className="col-12 justify-content-center flex-column">
          <div className="circle one">
            <span>1</span>
          </div>
          <div className="description">{t('homepage.howItWorks.steps.step1Title')}</div>
          <div className="description2">{t('homepage.howItWorks.steps.step1SubTitle')}</div>
          <div className="apps-button">
            <a target="_blank" href={patientAppStoreLink} rel="noreferrer">
              <i className="app-store-icon cursor-pointer" />
            </a>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=ro.ascendnet.alodoc.patient.ro"
              rel="noreferrer"
            >
              <i className="google-play-icon cursor-pointer" />
            </a>
          </div>
        </div>
        <div className={`col-12 ${!isMobile ? 'box-img' : ''}`}>
          <LazyImage
            width={600}
            height={450}
            src={howItWorksImg1}
            className="how-it-works-img"
            alt="Ringdoc"
          />
        </div>
      </div>
    </div>
  );

  const howItWorks1Mobile = (
    <div className="box box1">
      <div className="row">
        <div className="col-12 justify-content-center flex-column">
          <div className="circle one">
            <span>1</span>
          </div>
          <div className="description">{t('homepage.howItWorks.steps.step1Title')}</div>
          <div className="description2">{t('homepage.howItWorks.steps.step1SubTitle')}</div>
          {/* <div className="apps-button"> */}
          {/*  <a target="_blank" href={patientAppStoreLink} rel="noreferrer"> */}
          {/*    <i className="app-store-icon cursor-pointer" /> */}
          {/*  </a> */}
          {/*  <a */}
          {/*    target="_blank" */}
          {/*    href="https://play.google.com/store/apps/details?id=ro.ascendnet.alodoc.patient.ro" */}
          {/*    rel="noreferrer" */}
          {/*  > */}
          {/*    <i className="google-play-icon cursor-pointer" /> */}
          {/*  </a> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );

  const howItWorks2 = (
    <div className="box box2">
      <div className="row">
        <div className="col-12 justify-content-center flex-column box2FlexG">
          <div className="circle two">
            <span>2</span>
          </div>
          <div className="description">{t('homepage.howItWorks.steps.step2Title')}</div>
          <div className="description2">{t('homepage.howItWorks.steps.step2SubTitle')}</div>
        </div>
        <div className={`col-12 ${!isMobile ? 'box-img' : ''}`}>
          <LazyImage
            width={600}
            height={450}
            src={howItWorksImg2}
            className="how-it-works-img"
            alt="Ringdoc"
          />
        </div>
      </div>
    </div>
  );

  const howItWorks2Mobile = (
    <div className="box box2">
      <div className="row">
        <div className="col-12 justify-content-center flex-column box2FlexG">
          <div className="circle two">
            <span>2</span>
          </div>
          <div className="description">{t('homepage.howItWorks.steps.step2Title')}</div>
          <div className="description2">{t('homepage.howItWorks.steps.step2SubTitle')}</div>
        </div>
      </div>
    </div>
  );

  const howItWorks3 = (
    <div className="box box3">
      <div className="row">
        <div className="col-12 justify-content-center flex-column">
          <div className="circle three">
            <span>3</span>
          </div>
          <div className="description">{t('homepage.howItWorks.steps.step3Title')}</div>
          <div className="description2">{t('homepage.howItWorks.steps.step3SubTitle')}</div>
        </div>
        <div className={`col-12 ${!isMobile ? 'box-img' : ''}`}>
          <LazyImage
            width={600}
            height={450}
            src={howItWorksImg3}
            className="how-it-works-img"
            alt="Ringdoc"
          />
        </div>
      </div>
    </div>
  );

  const howItWorks3Mobile = (
    <div className="box box3">
      <div className="row">
        <div className="col-12 justify-content-center flex-column">
          <div className="circle three">
            <span>3</span>
          </div>
          <div className="description">{t('homepage.howItWorks.steps.step3Title')}</div>
          <div className="description2">{t('homepage.howItWorks.steps.step3SubTitle')}</div>
        </div>
      </div>
    </div>
  );

  const [showModalVideo, setModalVideoShow] = useState(false);
  const modalVideoShow = () => setModalVideoShow(true);
  const modalVideoClose = () => setModalVideoShow(false);

  const renderArrowLeft = () => {
    return (
      <div className="prev-slider">
        <CustomIcon
          className="custom-icon rotate-180"
          color={Colors.darkGrey}
          size="24"
          icon="Right_pointer"
        />
      </div>
    );
  };

  const renderArrowRight = () => {
    return (
      <div className="next-slider">
        <CustomIcon
          className="custom-icon"
          color={Colors.darkGrey}
          size="24"
          icon="Right_pointer"
        />
      </div>
    );
  };

  //  ========================== SORTING ==========================
  const sortingOptions = useSelector(getSortingOptionsState, shallowEqual);
  const [options, setOptions] = useState([
    { value: 'byFirstSlot', label: 'Primul slot disponibil' },
    { value: 'byPriceAsc', label: 'Pret ascendent' },
    { value: 'byPriceDesc', label: 'Pret descendent' },
    { value: 'byRating', label: 'Recenzii' },
  ]);

  useEffect(() => {
    const updatedLanguage = [];
    sortingOptions.map((option) => {
      const { translations } = option;
      updatedLanguage.push({
        // @ts-ignore
        value: option.slug,
        // @ts-ignore
        label: translations.filter((translation) => translation.locale === i18next.language)[0]
          .name,
      });
      setOptions(updatedLanguage);
      return true;
    });
  }, [sortingOptions, i18next.language]);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      border: '0 !important',
      boxShadow: '0 !important',
      '&:hover': {
        border: '0 !important',
      },
    }),
  };

  const orderDoctors = (selected) => {
    setSortSelectedOption(selected);
    setSortOption(selected?.value);
    const { specializationId: id, doctorNameSearch: searchName } = medicFilters;

    dispatch(
      getSpecializationDoctorsRequest({
        id,
        searchName,
        sortingOptions: selected?.value,
      }),
    );
  };

  //  ========================== SORTING ==========================

  return (
    <section className="row medic-list">
      <Helmet>
        {selectedSpecializationMetaTitle && <title>{selectedSpecializationMetaTitle}</title>}
        {selectedSpecializationMetaDescription && (
          <meta
            name="description"
            content={
              selectedSpecializationMetaDescription ||
              'Pe aceasta pagina veti gasi lista tuturor medicilor disponibili in Ringdoc, pe care ii puteti filtra dupa specialitatea medicala'
            }
          />
        )}
        <meta property="og:url" content={currentUrl} />
        <meta property="og:locale" content="ro_RO" />
        <meta property="og:locale:alternate" content="en_GB" />
        <meta
          property="og:type:section"
          content={
            medicFilters.specializationName
              ? `${t('appointments.appointments')} ${medicFilters.specializationName} ${t(
                  'appointments.online',
                )}`
              : t('appointments.onlineAppointments')
          }
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={
            medicFilters.specializationName
              ? medicFilters.specializationName
              : t('medicalApp.homeScreenPatient.listOfDoctors')
          }
        />
        <meta
          property="og:description"
          content={
            selectedSpecializationMetaDescription ||
            'Pe aceasta pagina veti gasi lista tuturor medicilor disponibili in Ringdoc, pe care ii puteti filtra dupa specialitatea medicala'
          }
        />
        <meta property="og:image" content={`${baseUrl}${openGraphImage}`} />
        <link rel="canonical" href={canonicalPath} />
      </Helmet>
      <div className="medic-list-info">
        <h1 className="title">
          {!isMobile && <span className="w216" />}
          {!isMobile && (
            <span className="title-text">
              {/* eslint-disable-next-line no-nested-ternary */}
              {medicFilters.specializationName
                ? medicFilters.seoTitle
                  ? medicFilters.seoTitle
                  : medicFilters.specializationName
                : t('medicalApp.homeScreenPatient.listOfDoctors')}
            </span>
          )}
          {!isMobile && (
            // <span className="how-does-it-works" onClick={showPopUp}>
            <span className="how-does-it-works wbtn" onClick={showPopUp}>
              {/* <CustomIcon className="custom-icon" color={Colors.white} size="24" icon="Info" /> */}
              <CustomIcon
                className="custom-icon"
                color={Colors.patientColor}
                size="24"
                icon="Info"
              />
              <div className="call-now-btn-text">{t('howItWorks.patient.title')}</div>
            </span>
          )}
        </h1>
        {isMobile && (
          <>
            <h3 className="how-it-works-title-mobile">{t('howItWorks.patient.title')}</h3>
            <div className="how-it-works-wrapper-play-video-center" onClick={modalVideoShow}>
              {t('seeVideo')}
              <CustomIcon className="custom-icon" color={Colors.darkGrey} size="32" icon="Play" />
            </div>
          </>
        )}
        {isMobile && (
          <div className="specializations-carousel">
            <Carousel
              cols={1}
              rows={3}
              gap={10}
              loop
              autoplay={5000}
              arrowLeft={renderArrowLeft}
              arrowRight={renderArrowRight}
              responsiveLayout={[
                {
                  breakpoint: 1200,
                  cols: 1,
                  rows: 1,
                },
                {
                  breakpoint: 990,
                  cols: 1,
                  rows: 1,
                },
              ]}
              mobileBreakpoint={100}
            >
              <Carousel.Item>{howItWorks1Mobile}</Carousel.Item>
              <Carousel.Item>{howItWorks2Mobile}</Carousel.Item>
              <Carousel.Item>{howItWorks3Mobile}</Carousel.Item>
            </Carousel>
          </div>
        )}
      </div>
      {isMobile && (
        <div className="medic-list-info ">
          <h1 className="title title-mt-0">
            <span className="title-text title-text-mt-0">
              {/* eslint-disable-next-line no-nested-ternary */}
              {medicFilters.specializationName
                ? medicFilters.seoTitle
                  ? medicFilters.seoTitle
                  : medicFilters.specializationName
                : t('medicalApp.homeScreenPatient.listOfDoctors')}
            </span>
          </h1>
        </div>
      )}

      {/* Filters Listing */}
      <div
        className={`medic-list-filters col-12 col-md-4 ${
          // eslint-disable-next-line no-nested-ternary
          isMobile ? (vouchers.length > 0 ? 'sticky-filter-with-voucher' : 'sticky-filter') : ''
        }`}
      >
        <div className="filters-section">
          <div
            className={`searchbar-speciality ${
              mobileSearchSpecialization ? 'searchbar-speciality-mobile' : ''
            }`}
          >
            {/* <form className="searchbar active w-auto" onSubmit={handleSearchNameSubmit}> */}
            {/*  <CustomIcon className="custom-icon" color={Colors.darkGrey} size="20" icon="Search" /> */}
            {/*  <input */}
            {/*    type="text" */}
            {/*    placeholder={t('searchDoctor')} */}
            {/*    value={doctorNameSearch} */}
            {/*    onChange={handleSearchNameChange} */}
            {/*  /> */}
            {/*  <span onClick={toggleSearchBar}>{t('search')}</span> */}
            {/* </form> */}
            <div className="search-speciality d-block d-md-none">
              <div
                ref={searchSpecializationMobile}
                className="custom-control custom-radio orange-radio right-radio specialization-item"
                onClick={() => {
                  if (!mobileSearchSpecialization) {
                    setTimeout(() => {
                      window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth',
                      });
                    }, 500);
                  }
                  setMobileSearchSpecialization(!mobileSearchSpecialization);
                }}
              >
                <CustomIcon
                  className="custom-icon"
                  color={Colors.darkGrey}
                  size="20"
                  icon="Filter"
                />
                <div className="name">{t('chooseSpecialty')}</div>
                <CustomIcon
                  className="custom-icon down-pointer"
                  color={Colors.darkGrey}
                  size="24"
                  icon="Down_pointer"
                />
              </div>
            </div>
            {mobileSearchSpecialization && (
              <div className="scroll-list d-block d-md-none">
                <Form>
                  {medicFilters.specializationName && (
                    <div
                      className="specialization-selected"
                      onClick={handleDoctorSpecializationClear}
                    >
                      <span className="specialization-selected-name">
                        {medicFilters.specializationName}
                      </span>
                      <CustomIcon className="custom-icon" color="#FF6A39" size="20" icon="Cancel" />
                    </div>
                  )}
                </Form>
              </div>
            )}
          </div>

          <div className="scroll-list d-none d-md-block">
            <Form>
              {medicFilters.specializationName && (
                <div className="specialization-selected" onClick={handleDoctorSpecializationClear}>
                  <span className="specialization-selected-name">
                    {medicFilters.specializationName}
                  </span>
                  <CustomIcon className="custom-icon" color="#FF6A39" size="20" icon="Cancel" />
                </div>
              )}
              {specializations.data.map((specialization) => {
                return (
                  <div
                    key={_.uniqueId()}
                    className="custom-control custom-radio orange-radio right-radio specialization-item"
                    onClick={() => handleDoctorSpecializationSubmit(specialization)}
                    data-specialization={encodeURIComponent(JSON.stringify(specialization))}
                  >
                    <div className="name">{specialization.name}</div>
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.darkGrey}
                      size="24"
                      icon="Right_pointer"
                    />
                  </div>
                );
              })}
            </Form>
          </div>
        </div>
      </div>
      {mobileSearchSpecialization && (
        <div className="medic-list-filters-mobile col-12">
          <div className="filters-section-mobile">
            <div className="scroll-list d-block d-md-none">
              <Form>
                {!medicFilters.specializationName &&
                  specializations.data.map((specialization) => {
                    return (
                      <div
                        key={_.uniqueId()}
                        className="custom-control custom-radio orange-radio right-radio specialization-item"
                        onClick={() => handleDoctorSpecializationSubmit(specialization)}
                        data-specialization={encodeURIComponent(JSON.stringify(specialization))}
                      >
                        <div className="name">{specialization.name}</div>
                        <CustomIcon
                          className="custom-icon"
                          color={Colors.darkGrey}
                          size="24"
                          icon="Right_pointer"
                        />
                      </div>
                    );
                  })}
              </Form>
            </div>
          </div>
        </div>
      )}
      {/* Doctor Listing */}
      <div className="medic-list-doctors col-12 col-md-8">
        <div className="row">
          <div className={`col-12 col-xl-6 ${isMobile ? '' : 'colpr10'}`}>
            <div
              className={`searchbar-speciality ${
                mobileSearchSpecialization ? 'searchbar-speciality-mobile' : ''
              }`}
            >
              <form className="searchbar active w-auto" onSubmit={handleSearchNameSubmit}>
                <CustomIcon
                  className="custom-icon"
                  color={Colors.darkGrey}
                  size="20"
                  icon="Search"
                />
                <input
                  type="text"
                  placeholder={t('searchDoctor')}
                  value={doctorNameSearch}
                  onChange={handleSearchNameChange}
                />
                <span onClick={toggleSearchBar}>{t('search')}</span>
              </form>
            </div>
          </div>
          <div className={`col-12 col-xl-6 ${isMobile ? '' : 'colpr10'}`}>
            <div className="sort-container">
              <div className="svg-container">
                <FontAwesomeIcon icon={faSort} />
              </div>
              <Select
                value={sortSelectedOption}
                inputValue=""
                onChange={(selected) => orderDoctors(selected)}
                onMenuClose={() => {}}
                onMenuOpen={() => {}}
                options={options}
                onInputChange={() => {}}
                isClearable
                components={{ Option }}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder={t('sort')}
                styles={customStyles}
                isSearchable={false}
              />
            </div>
          </div>
        </div>
        <div className="row rowp10">
          <InfiniteScroll
            dataLength={doctors.length}
            next={loadMore}
            hasMore={currentPage <= noOfPages}
            loader={<LoadingSpinner isLoading />}
            className="row"
          >
            {doctors.map((doctor) => {
              const doctorIsFavorite = favouriteDoctorsIds.includes(doctor.id);
              if (doctor.availability !== 'online' && haveActiveDoctors) {
                haveActiveDoctors = false;
                return (
                  <Fragment key={_.uniqueId()}>
                    <FindADoctorCard
                      specialization={medicFilters.specializationName}
                      specializations={specializations}
                    />
                    <PatientMedicCard
                      key={_.uniqueId()}
                      listItem={doctor}
                      onScheduleClicked={onScheduleClicked}
                      onCallNowClicked={onCallNowClicked}
                      toggleFavorite={toggleFavorite}
                      doctorIsFavorite={doctorIsFavorite}
                      classes="col-12 col-xl-6 colp10"
                    />
                  </Fragment>
                );
              }
              return (
                <Fragment key={_.uniqueId()}>
                  <PatientMedicCard
                    key={_.uniqueId()}
                    listItem={doctor}
                    onScheduleClicked={onScheduleClicked}
                    onCallNowClicked={onCallNowClicked}
                    toggleFavorite={toggleFavorite}
                    doctorIsFavorite={doctorIsFavorite}
                    classes="col-12 col-xl-6 colp10"
                  />
                </Fragment>
              );
            })}
          </InfiniteScroll>
        </div>
        <div className="details details-justify" ref={specializationDescriptionRef} />
      </div>
      <Modal show={show} onHide={handleClose} animation centered className="modal-specialization">
        <Modal.Header closeButton closeLabel={t('close')}>
          <Modal.Title>{t('chooseSpecialization')}</Modal.Title>
        </Modal.Header>

        <Modal.Header className="subheader">
          <div className="searchbar">
            <CustomIcon className="custom-icon" color={Colors.mediumGrey} size="20" icon="Search" />
            <input
              type="text"
              placeholder={t('searchSpecialization')}
              value={specializationSearch}
              onChange={handleSpecializationSearchChange}
            />
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="scroll-list">
            <Form>
              {specializations.data.map((specialization) => {
                return (
                  <div
                    key={_.uniqueId()}
                    className="custom-control custom-radio orange-radio right-radio specialization-item"
                    onClick={() => handleDoctorSpecializationSubmit(specialization)}
                    data-specialization={encodeURIComponent(JSON.stringify(specialization))}
                  >
                    <div className="name">{specialization.name}</div>
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.darkGrey}
                      size="24"
                      icon="Right_pointer"
                    />
                  </div>
                );
              })}
            </Form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={popEnabled}
        onHide={closePopUp}
        animation
        centered
        className="how-does-it-works-modal"
      >
        <Modal.Header>
          <Modal.Title>{t('howItWorks.patient.title')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="colv">
            <div
              className="how-it-works-wrapper-play-video-center-notmobile"
              onClick={modalVideoShow}
            >
              <CustomIcon className="custom-icon" color={Colors.darkGrey} size="32" icon="Play" />
              <span className="vezi-video-ml">{t('seeVideo')}</span>
            </div>
            <div className="colh">
              {howItWorks1}
              {howItWorks2}
              {howItWorks3}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModalVideo}
        onHide={modalVideoClose}
        animation
        centered
        className="modal-video"
      >
        <Modal.Header closeButton closeLabel={t('close')} />

        <Modal.Body>
          <div className="iframe-responsive">
            <iframe
              src="https://www.youtube.com/embed/EfG0jJVO4DQ?si=6arPcltAPFfqHg6I"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>
        </Modal.Body>
      </Modal>
      {/* <Modal
        show={showModalControlAlert}
        onHide={modalControlAlertClose}
        animation
        centered
        className="modal-control-alert"
      >
        <Modal.Body>
          <div className="icon">
            <CustomIcon
              className="custom-icon"
              color={Colors.warningColor}
              size="159"
              icon="Warning"
            />
          </div>
          <div className="title">Atenție!</div>
          <p>
            Controlul medical poate fi efectuat dacă ai primit o recomandare pentru control din
            partea medicului care te-a consultat anterior.
          </p>
          <p>
            În cazul în care nu ai o recomandare de control te rugăm să efectuezi o consultație.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <button type="button" className="orange-button">
            {t('continue')}
          </button>
        </Modal.Footer>
      </Modal> */}
    </section>
  );
};

export default PatientMedicList;
