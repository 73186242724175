import { AnyAction } from 'redux';
import {
  FETCH_VOUCHERS_ERROR,
  FETCH_VOUCHERS_REQUEST,
  FETCH_VOUCHERS_SUCCESS,
  FETCH_VALID_VOUCHERS_ERROR,
  FETCH_VALID_VOUCHERS_REQUEST,
  FETCH_VALID_VOUCHERS_SUCCESS,
  APPLY_VOUCHER_ERROR,
  APPLY_VOUCHER_REQUEST,
  APPLY_VOUCHER_SUCCESS,
  REMOVE_VOUCHER_ERROR,
  REMOVE_VOUCHER_REQUEST,
  REMOVE_VOUCHER_SUCCESS,
  LOCALLY_CHOOSE_VOUCHER,
} from 'shared/redux/types/VouchersTypes';

export const fetchVouchersRequest = (payload): AnyAction => ({
  type: FETCH_VOUCHERS_REQUEST,
  payload,
});

export const fetchVouchersSuccess = (payload): AnyAction => ({
  type: FETCH_VOUCHERS_SUCCESS,
  payload,
});

export const fetchVouchersError = (payload): AnyAction => ({
  type: FETCH_VOUCHERS_ERROR,
  payload,
});

export const fetchValidVouchersRequest = (payload): AnyAction => ({
  type: FETCH_VALID_VOUCHERS_REQUEST,
  payload,
});

export const fetchValidVouchersSuccess = (payload): AnyAction => ({
  type: FETCH_VALID_VOUCHERS_SUCCESS,
  payload,
});

export const fetchValidVouchersError = (payload): AnyAction => ({
  type: FETCH_VALID_VOUCHERS_ERROR,
  payload,
});

export const applyVoucherRequest = (payload): AnyAction => ({
  type: APPLY_VOUCHER_REQUEST,
  payload,
});

export const applyVoucherSuccess = (payload): AnyAction => ({
  type: APPLY_VOUCHER_SUCCESS,
  payload,
});

export const applyVoucherError = (payload): AnyAction => ({
  type: APPLY_VOUCHER_ERROR,
  payload,
});

export const removeVoucherRequest = (payload): AnyAction => ({
  type: REMOVE_VOUCHER_REQUEST,
  payload,
});

export const removeVoucherSuccess = (payload): AnyAction => ({
  type: REMOVE_VOUCHER_SUCCESS,
  payload,
});

export const removeVoucherError = (payload): AnyAction => ({
  type: REMOVE_VOUCHER_ERROR,
  payload,
});

export const locallyChooseVoucher = (payload): AnyAction => ({
  type: LOCALLY_CHOOSE_VOUCHER,
  payload,
});
