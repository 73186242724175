import HttpGunService from 'shared/services/HttpGunService';
import StorageService from 'shared/services/StorageService';
import { default as StringConstants } from 'shared/constants/StringConstants.json';
import { default as ApiConstants } from 'shared/constants/ApiConstants.json';
import { parseToJson } from 'shared/modules/JsonUtils';
import { getEnv } from 'shared/services/EnvService';
import LocalEventsService from 'shared/services/LocalEventsService';
import i18next from 'i18next';
import { allowRequestCheckpoint, getDestination, loginCheckPoint } from './AuthUtils';

const getDefaultHeaders = () => {
  return {
    'X-Accept-Version': '1.0',
    'X-Client-Destination': getDestination(),
    'X-Client-Platform': 'WEB',
    'X-Client-Ver': getEnv('VERSION'),
  };
};

const noLocaleUrls = [
  ApiConstants.REPORT_ERROR_ENDPOINT,
  ApiConstants.SERVER_TIME_ENDPOINT,
  ApiConstants.GET_TRANSLATIONS,
  ApiConstants.GET_SETTINGS,
];

const makeUrl = (url: string) => {
  const [, cleanUrl] = url.split('/');
  if (noLocaleUrls.indexOf(`/${cleanUrl}`) > -1) return `${getEnv('BASE_URL')}/api${url}`;
  return `${getEnv('BASE_URL')}/api/${i18next.language}${url}`;
};

const refreshTokenExceptions = [
  ApiConstants.REFRESH_TOKEN_ENDPOINT,
  ApiConstants.LOGIN_ENDPOINT,
  ApiConstants.FORCE_LOGOUT_ENDPOINT,
  ApiConstants.GET_TRANSLATIONS,
];

const noTokenUrls = [
  ApiConstants.REFRESH_TOKEN_ENDPOINT,
  ApiConstants.LOGIN_ENDPOINT,
  ApiConstants.GOOGLE_LOGIN_ENDPOINT,
  ApiConstants.FACEBOOK_LOGIN_ENDPOINT,
  ApiConstants.APPLE_LOGIN_ENDPOINT,
  ApiConstants.FORGOT_PASSWORD_ENDPOINT,
  ApiConstants.REGISTER_ENDPOINT,
  ApiConstants.ABOUT_US_ENDPOINT,
  ApiConstants.PRIVACY_POLICY_ENDPOINT,
  ApiConstants.TERMS_ENDPOINT,
  ApiConstants.GDPR_ENDPOINT,
  ApiConstants.GDPR_ENDPOINT_EN,
  ApiConstants.STRIPE_TERMS_ENDPOINT,
  ApiConstants.TWILIO_TERMS_ENDPOINT,
  ApiConstants.CONTACT_US_ENDPOINT,
  ApiConstants.RESET_PASSWORD_ENDPOINT,
  ApiConstants.FORCE_LOGOUT_ENDPOINT,
  ApiConstants.GET_TRANSLATIONS,
];

const getErrorMessage = (statusCode, message) => {
  return getEnv('IS_PRODUCTION') === 'false' ? `(${statusCode}) ${message}` : message;
};

const handleErrorResponse = (response: { status: any; text?: () => Promise<any> }, url: any) => {
  // eslint-disable-next-line consistent-return
  return new Promise((resolve) => {
    if (response?.status) {
      (response as any)
        ?.text()
        .then((responseData) => {
          if (typeof responseData === 'string') {
            const errorData = parseToJson(responseData);
            return resolve(getErrorMessage(response?.status, errorData.message));
          }
          const message = responseData?.message ?? `internal server error @ ${url}`;
          return resolve(getErrorMessage(response?.status, message));
        })
        .catch((err) => {
          return resolve(getErrorMessage(response?.status, err.message));
        });
    } else {
      return resolve((response as any)?.message);
    }
  });
};

const doRefreshToken = async () => {
  const fullUrl = makeUrl(ApiConstants.REFRESH_TOKEN_ENDPOINT);
  const refreshToken = await StorageService.getData(StringConstants.REFRESH_TOKEN, null);
  const defaultHeaders: Record<string, string | undefined> = getDefaultHeaders();
  return new Promise((resolve, reject) => {
    HttpGunService.postR(
      fullUrl,
      { refresh_token: refreshToken as any },
      { headers: defaultHeaders },
    )
      .then(async ({ data }: any) => {
        await StorageService.setData(StringConstants.USER_TOKEN, data.token);
        await StorageService.setData(StringConstants.REFRESH_TOKEN, data.refresh_token);
        return resolve(data.token);
      })
      .catch(() => {
        loginCheckPoint().then((checkPoint) => {
          if (checkPoint.action === 'login') {
            LocalEventsService.emit('forceLogout');
          }
        });
        return resolve(null);
      });
  });
};

const requester = async (
  method: string,
  url: string,
  data: Record<string, unknown> | null,
  freshToken: string | null = null,
  customHeaders: Record<string, unknown> | null = null,
) => {
  const fullUrl = makeUrl(url);
  let token = freshToken ?? (await StorageService.getData(StringConstants.USER_TOKEN, null));
  if (noTokenUrls.indexOf(url) > -1 || noTokenUrls.indexOf(url.slice(0, -1)) > -1) {
    token = null;
  }
  if (window.location.pathname !== '/') {
    const checkpoint = await allowRequestCheckpoint();
    if (!checkpoint) return;
  }
  const defaultHeaders: Record<string, string | undefined> = getDefaultHeaders();
  let headers: any = defaultHeaders;
  if (customHeaders) {
    headers = { ...defaultHeaders, ...customHeaders } as any;
  }
  // eslint-disable-next-line consistent-return
  return new Promise((resolve, reject) => {
    // @ts-ignore
    HttpGunService[method](fullUrl, data, { headers }, token)
      // eslint-disable-next-line consistent-return
      .then((response: any) => {
        return resolve(response);
      })
      // eslint-disable-next-line consistent-return
      .catch((error: { status: any; text?: () => Promise<any> }) => {
        if (
          error?.status === 401 &&
          refreshTokenExceptions.indexOf(url) === -1 &&
          url !== '/user/logout'
        ) {
          doRefreshToken()
            // eslint-disable-next-line consistent-return
            .then((newToken) => {
              if (newToken) {
                HttpGunService[method](fullUrl, data, { headers }, newToken)
                  .then((resp) => {
                    return resolve(resp);
                  })
                  .catch((err) => {
                    return reject(err);
                  });
              } else {
                const activeRole = localStorage.getItem('ACTIVE_ROLE');
                // Clearing local storage
                localStorage.clear();
                // Clearing session storage
                sessionStorage.clear();

                caches.keys().then((names) => {
                  names.forEach((name) => {
                    caches.delete(name);
                  });
                });

                const dbs = window.indexedDB.databases();
                dbs.then((result) => {
                  result.forEach((db) => {
                    // @ts-ignore
                    window.indexedDB.deleteDatabase(db.name);
                    return true;
                  });
                });
                window.location.href = `${activeRole === 'doctor' ? '/doctor' : '/patient'}/login`;
                // return resolve({ data: {} });
              }
            })
            .catch((refreshError) => {
              return reject(refreshError);
            });
        } else if (error?.status === 401 && url === '/user/logout') {
          const changePlatform = localStorage.getItem('CHANGE_PLATFORM');
          // Clearing local storage
          localStorage.clear();
          // Clearing session storage
          sessionStorage.clear();

          caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
          });

          const dbs = window.indexedDB.databases();
          dbs.then((result) => {
            result.forEach((db) => {
              // @ts-ignore
              window.indexedDB.deleteDatabase(db.name);
              return true;
            });
          });

          if (changePlatform === 'doctor') {
            window.location.href = `${window.location.origin}/doctor/landing`;
          } else {
            window.location.href = `${window.location.origin}/`;
          }
        } else if (error?.status === 404 && url.indexOf('/patient/doctor-profile') > -1) {
          const pathElements = window.location.pathname.split('/');
          window.location.href = `/?dnf=${pathElements[pathElements.length - 1]}`;
        } else {
          handleErrorResponse(error, url)
            .then((message) => {
              // return reject(new Error(message as string));
              // eslint-disable-next-line prefer-promise-reject-errors
              return reject({ message, status: error?.status });
            })
            .catch((err) => {
              return reject(err);
            });
        }
      });
  });
};

const postRequest = async (
  url: string,
  data: Record<string, unknown> | null = null,
  customHeaders: Record<string, unknown> | null = null,
) => {
  return requester('postR', url, data, null, customHeaders);
};

const patchRequest = async (url: string, data: Record<string, unknown> | null = null) => {
  return requester('patchR', url, data);
};

const getRequest = async (url: string, customHeaders: Record<string, unknown> | null = null) => {
  return requester('getR', url, null, null, customHeaders);
};

const putRequest = async (url: string, data: Record<string, unknown> | null = null) => {
  return requester('putR', url, data);
};

const deleteRequest = async (url: string, data: Record<string, unknown> | null = null) => {
  return requester('deleteR', url, data);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { getRequest, postRequest, putRequest, patchRequest, deleteRequest, doRefreshToken };
