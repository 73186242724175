import { AnyAction } from 'redux';
import {
  FETCH_COUNTRIES_REQUEST,
  FETCH_COUNTRIES_ERROR,
  FETCH_COUNTRIES_SUCCESS,
} from 'shared/redux/types/CountryTypes';

export const fetchCountriesRequest = (payload: any): AnyAction => ({
  type: FETCH_COUNTRIES_REQUEST,
  payload,
});

export const fetchCountriesSuccess = (payload: any): AnyAction => ({
  type: FETCH_COUNTRIES_SUCCESS,
  payload,
});

export const fetchCountriesError = (payload: any): AnyAction => ({
  type: FETCH_COUNTRIES_ERROR,
  payload,
});
