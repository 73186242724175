import { AnyAction } from 'redux';
import {
  FETCH_ALL_REVIEWS_REQUEST,
  FETCH_ALL_REVIEWS_ERROR,
  FETCH_ALL_REVIEWS_SUCCESS,
} from 'shared/redux/types/AllReviewsTypes';

export const fetchAllReviewsRequest = (payload): AnyAction => ({
  type: FETCH_ALL_REVIEWS_REQUEST,
  payload,
});

export const fetchAllReviewsSuccess = (payload): AnyAction => ({
  type: FETCH_ALL_REVIEWS_SUCCESS,
  payload,
});

export const fetchAllReviewsError = (payload): AnyAction => ({
  type: FETCH_ALL_REVIEWS_ERROR,
  payload,
});
