import { AnyAction } from 'redux';
import {
  FETCH_DOCTOR_PAST_APPOINTMENTS_REQUEST,
  FETCH_DOCTOR_PAST_APPOINTMENTS_ERROR,
  FETCH_DOCTOR_PAST_APPOINTMENTS_SUCCESS,
  FETCH_DOCTOR_UPCOMING_APPOINTMENTS_ERROR,
  FETCH_DOCTOR_UPCOMING_APPOINTMENTS_REQUEST,
  FETCH_DOCTOR_UPCOMING_APPOINTMENTS_SUCCESS,
  GET_APPOINTMENT_DETAILS_REQUEST,
  GET_APPOINTMENT_DETAILS_ERROR,
  GET_APPOINTMENT_DETAILS_SUCCESS,
  CANCEL_APPOINTMENT_BY_DOCTOR_REQUEST,
  CANCEL_APPOINTMENT_BY_DOCTOR_SUCCESS,
  CANCEL_APPOINTMENT_BY_DOCTOR_ERROR,
  CANCEL_ALL_APPOINTMENTS_BY_DOCTOR_ERROR,
  CANCEL_ALL_APPOINTMENTS_BY_DOCTOR_REQUEST,
  CANCEL_ALL_APPOINTMENTS_BY_DOCTOR_SUCCESS,
  DOCTOR_ACCEPTS_CALL_REQUEST,
  DOCTOR_ACCEPTS_CALL_SUCCESS,
  DOCTOR_ACCEPTS_CALL_ERROR,
  ADD_APPOINTMENT_CONCLUSION_REQUEST,
  ADD_APPOINTMENT_CONCLUSION_SUCCESS,
  ADD_APPOINTMENT_CONCLUSION_ERROR,
  DOCTOR_APPOINTMENT_UPDATES_REQUEST,
  DOCTOR_REJECTS_CALL_REQUEST,
  DOCTOR_REJECTS_CALL_SUCCESS,
  DOCTOR_REJECTS_CALL_ERROR,
  GET_CALL_SPECIALIST_INFO_REQUEST,
  GET_CALL_SPECIALIST_INFO_SUCCESS,
  GET_CALL_SPECIALIST_INFO_ERROR,
  UPDATE_DOCTOR_CALLS_STACK_REQUEST,
  REMOVE_DOCTOR_CALLS_STACK_REQUEST,
  ACCEPTED_DOCTOR_CALLS_STACK_REQUEST,
  FETCH_DOCTOR_TODAY_APPOINTMENTS_REQUEST,
  FETCH_DOCTOR_TODAY_APPOINTMENTS_SUCCESS,
  FETCH_DOCTOR_TODAY_APPOINTMENTS_ERROR,
  ADD_APPOINTMENT_PRESCRIPTION_REQUEST,
  ADD_APPOINTMENT_PRESCRIPTION_SUCCESS,
  ADD_APPOINTMENT_PRESCRIPTION_ERROR,
  ADD_APPOINTMENT_PRESCRIPTION_MEDICATION_ERROR,
  ADD_APPOINTMENT_PRESCRIPTION_MEDICATION_SUCCESS,
  ADD_APPOINTMENT_PRESCRIPTION_MEDICATION_REQUEST,
  UPDATE_APPOINTMENT_PRESCRIPTION_MEDICATION_REQUEST,
  UPDATE_APPOINTMENT_PRESCRIPTION_MEDICATION_SUCCESS,
  UPDATE_APPOINTMENT_PRESCRIPTION_MEDICATION_ERROR,
  REMOVE_APPOINTMENT_PRESCRIPTION_REQUEST,
  REMOVE_APPOINTMENT_PRESCRIPTION_SUCCESS,
  REMOVE_APPOINTMENT_PRESCRIPTION_ERROR,
  REMOVE_APPOINTMENT_PRESCRIPTION_MEDICATION_REQUEST,
  REMOVE_APPOINTMENT_PRESCRIPTION_MEDICATION_SUCCESS,
  REMOVE_APPOINTMENT_PRESCRIPTION_MEDICATION_ERROR,
} from 'shared/redux/types/DoctorAppointmentsTypes';

export const fetchDoctorPastAppointmentsRequest = (payload): AnyAction => ({
  type: FETCH_DOCTOR_PAST_APPOINTMENTS_REQUEST,
  payload,
});

export const fetchDoctorPastAppointmentsSuccess = (payload): AnyAction => ({
  type: FETCH_DOCTOR_PAST_APPOINTMENTS_SUCCESS,
  payload,
});

export const fetchDoctorPastAppointmentsError = (payload): AnyAction => ({
  type: FETCH_DOCTOR_PAST_APPOINTMENTS_ERROR,
  payload,
});

export const fetchDoctorUpcomingAppointmentsRequest = (payload): AnyAction => ({
  type: FETCH_DOCTOR_UPCOMING_APPOINTMENTS_REQUEST,
  payload,
});

export const fetchDoctorUpcomingAppointmentsSuccess = (payload): AnyAction => ({
  type: FETCH_DOCTOR_UPCOMING_APPOINTMENTS_SUCCESS,
  payload,
});

export const fetchDoctorUpcomingAppointmentsError = (payload): AnyAction => ({
  type: FETCH_DOCTOR_UPCOMING_APPOINTMENTS_ERROR,
  payload,
});

export const fetchDoctorTodayAppointmentsRequest = (payload): AnyAction => ({
  type: FETCH_DOCTOR_TODAY_APPOINTMENTS_REQUEST,
  payload,
});

export const fetchDoctorTodayAppointmentsSuccess = (payload): AnyAction => ({
  type: FETCH_DOCTOR_TODAY_APPOINTMENTS_SUCCESS,
  payload,
});

export const fetchDoctorTodayAppointmentsError = (payload): AnyAction => ({
  type: FETCH_DOCTOR_TODAY_APPOINTMENTS_ERROR,
  payload,
});

export const getAppointmentDetailsRequest = (payload): AnyAction => ({
  type: GET_APPOINTMENT_DETAILS_REQUEST,
  payload,
});

export const getAppointmentDetailsSuccess = (payload): AnyAction => ({
  type: GET_APPOINTMENT_DETAILS_SUCCESS,
  payload,
});

export const getAppointmentDetailsError = (payload): AnyAction => ({
  type: GET_APPOINTMENT_DETAILS_ERROR,
  payload,
});

export const cancelAppointmentByDoctorRequest = (payload): AnyAction => ({
  type: CANCEL_APPOINTMENT_BY_DOCTOR_REQUEST,
  payload,
});

export const cancelAppointmentByDoctorSuccess = (payload): AnyAction => ({
  type: CANCEL_APPOINTMENT_BY_DOCTOR_SUCCESS,
  payload,
});

export const cancelAppointmentByDoctorError = (payload): AnyAction => ({
  type: CANCEL_APPOINTMENT_BY_DOCTOR_ERROR,
  payload,
});

export const cancelAllAppointmentsByDoctorRequest = (payload): AnyAction => ({
  type: CANCEL_ALL_APPOINTMENTS_BY_DOCTOR_REQUEST,
  payload,
});

export const cancelAllAppointmentsByDoctorSuccess = (payload): AnyAction => ({
  type: CANCEL_ALL_APPOINTMENTS_BY_DOCTOR_SUCCESS,
  payload,
});

export const cancelAllAppointmentsByDoctorError = (payload): AnyAction => ({
  type: CANCEL_ALL_APPOINTMENTS_BY_DOCTOR_ERROR,
  payload,
});

export const doctorAcceptsCallRequest = (payload): AnyAction => ({
  type: DOCTOR_ACCEPTS_CALL_REQUEST,
  payload,
});

export const doctorAcceptsCallSuccess = (payload): AnyAction => ({
  type: DOCTOR_ACCEPTS_CALL_SUCCESS,
  payload,
});

export const doctorAcceptsCallError = (payload): AnyAction => ({
  type: DOCTOR_ACCEPTS_CALL_ERROR,
  payload,
});

export const doctorRejectsCallRequest = (payload): AnyAction => ({
  type: DOCTOR_REJECTS_CALL_REQUEST,
  payload,
});

export const doctorRejectsCallSuccess = (payload): AnyAction => ({
  type: DOCTOR_REJECTS_CALL_SUCCESS,
  payload,
});

export const doctorRejectsCallError = (payload): AnyAction => ({
  type: DOCTOR_REJECTS_CALL_ERROR,
  payload,
});

export const addAppointmentConclusionRequest = (payload): AnyAction => ({
  type: ADD_APPOINTMENT_CONCLUSION_REQUEST,
  payload,
});

export const addAppointmentConclusionSuccess = (payload): AnyAction => ({
  type: ADD_APPOINTMENT_CONCLUSION_SUCCESS,
  payload,
});

export const addAppointmentConclusionError = (payload): AnyAction => ({
  type: ADD_APPOINTMENT_CONCLUSION_ERROR,
  payload,
});

export const addAppointmentPrescriptionRequest = (payload): AnyAction => ({
  type: ADD_APPOINTMENT_PRESCRIPTION_REQUEST,
  payload,
});

export const addAppointmentPrescriptionSuccess = (payload): AnyAction => ({
  type: ADD_APPOINTMENT_PRESCRIPTION_SUCCESS,
  payload,
});

export const addAppointmentPrescriptionError = (payload): AnyAction => ({
  type: ADD_APPOINTMENT_PRESCRIPTION_ERROR,
  payload,
});

export const addAppointmentPrescriptionMedicationRequest = (payload): AnyAction => ({
  type: ADD_APPOINTMENT_PRESCRIPTION_MEDICATION_REQUEST,
  payload,
});

export const addAppointmentPrescriptionMedicationSuccess = (payload): AnyAction => ({
  type: ADD_APPOINTMENT_PRESCRIPTION_MEDICATION_SUCCESS,
  payload,
});

export const addAppointmentPrescriptionMedicationError = (payload): AnyAction => ({
  type: ADD_APPOINTMENT_PRESCRIPTION_MEDICATION_ERROR,
  payload,
});

export const removeAppointmentPrescriptionRequest = (payload): AnyAction => ({
  type: REMOVE_APPOINTMENT_PRESCRIPTION_REQUEST,
  payload,
});

export const removeAppointmentPrescriptionSuccess = (payload): AnyAction => ({
  type: REMOVE_APPOINTMENT_PRESCRIPTION_SUCCESS,
  payload,
});

export const removeAppointmentPrescriptionError = (payload): AnyAction => ({
  type: REMOVE_APPOINTMENT_PRESCRIPTION_ERROR,
  payload,
});

export const updateAppointmentPrescriptionMedicationRequest = (payload): AnyAction => ({
  type: UPDATE_APPOINTMENT_PRESCRIPTION_MEDICATION_REQUEST,
  payload,
});

export const updateAppointmentPrescriptionMedicationSuccess = (payload): AnyAction => ({
  type: UPDATE_APPOINTMENT_PRESCRIPTION_MEDICATION_SUCCESS,
  payload,
});

export const updateAppointmentPrescriptionMedicationError = (payload): AnyAction => ({
  type: UPDATE_APPOINTMENT_PRESCRIPTION_MEDICATION_ERROR,
  payload,
});

export const removeAppointmentPrescriptionMedicationRequest = (payload): AnyAction => ({
  type: REMOVE_APPOINTMENT_PRESCRIPTION_MEDICATION_REQUEST,
  payload,
});

export const removeAppointmentPrescriptionMedicationSuccess = (payload): AnyAction => ({
  type: REMOVE_APPOINTMENT_PRESCRIPTION_MEDICATION_SUCCESS,
  payload,
});

export const removeAppointmentPrescriptionMedicationError = (payload): AnyAction => ({
  type: REMOVE_APPOINTMENT_PRESCRIPTION_MEDICATION_ERROR,
  payload,
});

export const doctorAppointmentUpdatesRequest = (payload): AnyAction => ({
  type: DOCTOR_APPOINTMENT_UPDATES_REQUEST,
  payload,
});

export const acceptedDoctorCallsStackRequest = (payload): AnyAction => ({
  type: ACCEPTED_DOCTOR_CALLS_STACK_REQUEST,
  payload,
});

export const updateDoctorCallsStackRequest = (payload): AnyAction => ({
  type: UPDATE_DOCTOR_CALLS_STACK_REQUEST,
  payload,
});

export const removeDoctorCallsStackRequest = (payload): AnyAction => ({
  type: REMOVE_DOCTOR_CALLS_STACK_REQUEST,
  payload,
});

export const getCallSpecialistInfoRequest = (payload): AnyAction => ({
  type: GET_CALL_SPECIALIST_INFO_REQUEST,
  payload,
});

export const getCallSpecialistInfoSuccess = (payload): AnyAction => ({
  type: GET_CALL_SPECIALIST_INFO_SUCCESS,
  payload,
});

export const getCallSpecialistInfoError = (payload): AnyAction => ({
  type: GET_CALL_SPECIALIST_INFO_ERROR,
  payload,
});
