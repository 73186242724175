import 'patient/styles/medical-folder.css';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Form, Modal } from 'react-bootstrap';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import Utils from 'shared/modules/Utils';
import DisplayDocumentComponent from 'patient/components/DisplayDocumentComponent';
import AddMedicalRecordModalComponent from 'patient/components/AddMedicalRecordModalComponent';
import { MedicalRecord } from 'shared/Interfaces/DocumentsInterfaces';
import {
  getPrivateMediaFileRequest,
  updateLocaleRequest,
} from 'shared/redux/actions/UngroupedActions';
import {
  patientDeleteMedicalRecordRequest,
  patientUpdateMedicalRecordRequest,
} from 'shared/redux/actions/PatientProfileActions';
import Alerts from 'shared/components/Alerts';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { createLoadingSelector } from '../../../shared/redux/src/GenericSeletors';
import * as animationData from '../../../shared/assets/loader/loading_ringdoc.json';

const PatientMedicalFolderTabFolder = ({ medicalRecords, fileTypes, userId }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const loadingSelector = createLoadingSelector();
  const isLoading = useSelector(loadingSelector);
  const [medicalRecordData, setMedicalRecordData] = useState<MedicalRecord>({
    id: undefined,
    categoryId: undefined,
    title: undefined,
    investigationDate: '',
    files: [],
  });
  const [modalDisplayed, setModalDisplayed] = useState(false);
  const [uploadingModalDisplayed, setUploadingModalDisplayed] = useState(false);
  const [medicalRecordId, setMedicalRecordId] = useState<number | null | undefined>(undefined);
  const [medicalRecordTitle, setMedicalRecordTitle] = useState<string | null | undefined>('');
  const [medicalRecordCategory, setMedicalRecordCategory] = useState<number | null | undefined>(
    undefined,
  );
  const [showLoader, setShowLoader] = useState(false);
  const [viewUploadingModal, setViewUploadingModal] = useState(false);

  const hideModal = () => setModalDisplayed(false);
  const showModal = () => setModalDisplayed(true);
  const hideUploadingModal = () => setUploadingModalDisplayed(false);
  const showUploadingModal = () => setUploadingModalDisplayed(true);
  const openMedicalRecordFile = (externalId) => dispatch(getPrivateMediaFileRequest(externalId));
  const handleMedicalRecordTitleChange = (e) => setMedicalRecordTitle(e.target.value);
  const handleMedicalRecordCategoryChange = (e) => setMedicalRecordCategory(e.target.value);
  const updateMedicalRecord = () => {
    dispatch(
      patientUpdateMedicalRecordRequest({
        userId,
        mediaId: medicalRecordId,
        data: {
          title: medicalRecordTitle,
          medicalRecordCategory,
        },
      }),
    );

    hideModal();
  };
  const deleteMedicalRecord = () =>
    Alerts.okCancelAlert(t('warning'), t('medicalProfile.deleteMedicalFile'), () => {
      dispatch(
        patientDeleteMedicalRecordRequest({
          userId,
          mediaId: medicalRecordId,
        }),
      );

      hideModal();
    });

  useEffect(() => {
    setMedicalRecordId(medicalRecordData.id);
    setMedicalRecordTitle(medicalRecordData.title);
    setMedicalRecordCategory(medicalRecordData.categoryId);
  }, [medicalRecordData]);

  useEffect(() => {
    setShowLoader(isLoading);
  }, [isLoading]);

  const activateUploadingModal = (value) => {
    setViewUploadingModal(value);
  };

  return (
    <div className="small-container folder-tab">
      <Modal
        show={uploadingModalDisplayed}
        onHide={hideUploadingModal}
        backdrop="static"
        animation
        centered
      >
        <Modal.Header closeLabel={t('close')}>
          <Modal.Title>
            {Utils.capitalizeFirstLetter(Utils.shortenText(t('uploadingFilesTitle'), 32))}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{isLoading ? t('uploadInProgress') : t('uploadProgressDone')}</p>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className={`orange-button ${isLoading ? 'disabled' : ''}`}
            onClick={hideUploadingModal}
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="uploading-files-animation">
                <Lottie options={defaultOptions} />
              </div>
            ) : (
              t('ok')
            )}
          </button>
        </Modal.Footer>
      </Modal>
      <div className="info-message gray">
        <CustomIcon className="custom-icon" color={Colors.mediumGrey} size="24" icon="Warning_2" />
        {t('mustShare')}
      </div>

      <AddMedicalRecordModalComponent
        userId={userId}
        fileTypes={fileTypes}
        showUploadingModal={showUploadingModal}
      />

      {!medicalRecords.length && <div className="empty-container">{t('noMedicalRecords')}</div>}

      <DisplayDocumentComponent
        medicalRecords={Utils.makeSectionListFormat(medicalRecords, 'categoryName')}
        setMedicalRecordData={setMedicalRecordData}
        showEditModal={showModal}
      />

      <Modal
        show={modalDisplayed}
        onHide={hideModal}
        animation
        centered
        className="modal-edit-document"
      >
        <Modal.Header closeButton closeLabel={t('close')}>
          <Modal.Title>
            {Utils.capitalizeFirstLetter(Utils.shortenText(medicalRecordData.title, 32))}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <div className="details">
              <div className="subtitle">{t('details')}</div>

              <div className="form-select-label">
                <select
                  required
                  id="type-document"
                  name="type-document"
                  className="custom-select"
                  value={medicalRecordCategory || ''}
                  onChange={handleMedicalRecordCategoryChange}
                >
                  <option value="" disabled hidden>
                    {t('fileDefault')}
                  </option>
                  {fileTypes.length &&
                    Utils.makeSelectOptions(fileTypes, 'name', 'id').map((rel) => {
                      return (
                        <option key={rel.value} value={rel.value}>
                          {Utils.capitalizeFirstLetter(rel.label)}
                        </option>
                      );
                    })}
                </select>
                <label htmlFor="type-document">{t('fileDefault')}</label>
              </div>

              <div className="form-input">
                <input
                  disabled
                  type="text"
                  id="data-upload"
                  name="data-upload"
                  className="form-control"
                  defaultValue={medicalRecordData.createdAtFullFormatted}
                  placeholder=" "
                />
                <label htmlFor="data-upload">{t('medicalProfile.investigationDate')}</label>
              </div>

              <div className="form-input">
                <input
                  type="text"
                  id="name-document"
                  name="name-document"
                  className="form-control"
                  placeholder=" "
                  value={medicalRecordTitle || ''}
                  onChange={handleMedicalRecordTitleChange}
                />
                <label htmlFor="name-document">{t('medicalProfile.documentName')}</label>
              </div>
            </div>

            <div className="files">
              <div className="subtitle">{t('files')}</div>

              {medicalRecordData?.files.map((file) => {
                return (
                  <div className="uploaded-box" key={file.id}>
                    <div className="uploaded-file">
                      {/* <div className="circle new-file" /> */}
                      <div className="file-name">
                        <CustomIcon
                          className="custom-icon"
                          color={Colors.darkGrey}
                          size="24"
                          icon="Document"
                        />
                        <span
                          className="cursor-pointer"
                          onClick={() => openMedicalRecordFile(file.externalId)}
                        >
                          {Utils.shortenText(file.media.original_file_name, 25)}
                        </span>
                      </div>
                      {/* <button type="button"> */}
                      {/*   <CustomIcon */}
                      {/*     className="custom-icon" */}
                      {/*     color={Colors.mediumGrey} */}
                      {/*     size="24" */}
                      {/*     icon="Delete" */}
                      {/*   /> */}
                      {/* </button> */}
                    </div>
                  </div>
                );
              })}
            </div>

            {/* <div className="add-files"> */}
            {/*   <div className="subtitle">Adaugă Fișiere</div> */}
            {/*   <Form.Group controlId="formFile" className="files-box"> */}
            {/*     <Form.Label> */}
            {/*       <CustomIcon */}
            {/*         className="custom-icon" */}
            {/*         color={Colors.darkGrey} */}
            {/*         size="24" */}
            {/*         icon="Upload" */}
            {/*       /> */}
            {/*       Încarcă un fișier */}
            {/*     </Form.Label> */}
            {/*     <Form.Control type="file" /> */}
            {/*   </Form.Group> */}
            {/* </div> */}

            <button type="button" className="orange-button" onClick={updateMedicalRecord}>
              {t('saveChanges')}
            </button>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <button type="button" className="white-button" onClick={deleteMedicalRecord}>
            {t('medicalProfile.deleteDocument')}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PatientMedicalFolderTabFolder;
