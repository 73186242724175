import 'patient/styles/persistent-notification-bar.css';
import { useNavigate } from 'react-router-dom';
import CustomIcon from 'shared/components/CustomIcon';
import { resolveStickyBarLabel } from 'shared/modules/PatientUtils';
import TimerComponent from 'shared/components/TimerComponent';
import i18next from 'i18next';

const PatientStickyBar = ({
  theme,
  stickyStatus,
  doctor,
  callStatus,
  startTime,
  endTime,
  isInitialTime,
  timeUp,
  returnToCall,
}) => {
  const navigate = useNavigate();
  const clickOnTheBarHandler = () => {
    if (stickyStatus === 'SearchDoctor' || stickyStatus === 'WaitForDoctor') {
      navigate('/patient/appointment/waiting-screen');
    } else if (stickyStatus === 'SearchCallSpecialistDoctor') {
      navigate('/patient/call-specialist/searching-medic');
    } else if (stickyStatus === 'WaitForPayment') {
      navigate('/patient/appointment/summary-payment');
    } else if (stickyStatus === 'WaitForCallSpecialistPayment') {
      navigate('/patient/call-specialist/summary-payment');
    } else if (stickyStatus === 'WaitForCallSpecialistDoctor') {
      navigate('/patient/call-specialist/waiting-screen');
    } else {
      returnToCall();
    }
  };
  const solveStickyType = () => {
    if (
      stickyStatus === 'SearchDoctor' ||
      stickyStatus === 'WaitForDoctor' ||
      stickyStatus === 'SearchCallSpecialistDoctor' ||
      stickyStatus === 'WaitForCallSpecialistDoctor'
    ) {
      return {
        bg: theme.colors.white,
        titleColor: theme.colors.text,
        subtitleColor: theme.colors.darkGrey,
        title: i18next.t(resolveStickyBarLabel(stickyStatus)),
        subtitle: i18next.t('goBackToWaiting'),
      };
    }
    if (stickyStatus === 'WaitForPayment' || stickyStatus === 'WaitForCallSpecialistPayment') {
      return {
        bg: theme.colors.white,
        titleColor: theme.colors.text,
        subtitleColor: theme.colors.darkGrey,
        title: i18next.t(resolveStickyBarLabel(stickyStatus)),
        subtitle: i18next.t('goBackToWaiting'),
      };
    }
    if (callStatus === 'active') {
      return {
        bg: theme.colors.accent,
        titleColor: theme.colors.white,
        subtitleColor: theme.colors.white,
        title: i18next.t('goBackToCall'),
        subtitle: doctor.name,
      };
    }
    return {
      bg: theme.colors.white,
      titleColor: theme.colors.text,
      subtitleColor: theme.colors.darkGrey,
      title: '',
      subtitle: '',
    };
  };
  const shouldBeShown = () => {
    if (
      (new Date() > new Date(startTime) && stickyStatus === 'WaitForDoctor') ||
      (new Date() > new Date(startTime) && stickyStatus === 'WaitForCallSpecialistDoctor')
    ) {
      return false;
    }

    return (
      [
        'SearchDoctor',
        'SearchCallSpecialistDoctor',
        'WaitForDoctor',
        'WaitForCallSpecialistDoctor',
        'WaitForPayment',
        'WaitForCallSpecialistPayment',
      ].indexOf(stickyStatus) > -1 || callStatus === 'active'
    );
  };

  return shouldBeShown() ? (
    <div
      className="persistent-notification-bar cursor-pointer"
      style={{ backgroundColor: solveStickyType().bg }}
      onClick={clickOnTheBarHandler}
    >
      <div className="details">
        <div className="status-title" style={{ color: solveStickyType().titleColor }}>
          {solveStickyType().title}
        </div>
        <div className="medic-fullname" style={{ color: solveStickyType().subtitleColor }}>
          {solveStickyType().subtitle}
        </div>
      </div>
      <div className="time-left-counter">
        {isInitialTime && callStatus === 'active' && (
          <TimerComponent action={timeUp} endTime={endTime} onTimeAboutToEnd={() => {}} />
        )}
        <CustomIcon
          className="custom-icon"
          color={solveStickyType().titleColor}
          size="24"
          icon="Right_pointer"
        />
      </div>
    </div>
  ) : null;
};

export default PatientStickyBar;
