import { actionChannel, call, fork, put, take } from 'redux-saga/effects';
import { goBack } from 'react-router-redux';
import {
  ADD_BULK_DOCTOR_TIMETABLE_REQUEST,
  ADD_DOCTOR_EXCEPTION_TIMETABLE_REQUEST,
  ADD_DOCTOR_RECURRENT_TIMETABLE_REQUEST,
  ADD_DOCTOR_TIMETABLE_REQUEST,
  FETCH_DOCTOR_TIMETABLE_REQUEST,
  FETCH_MERGED_DOCTOR_TIMETABLE_REQUEST,
  GET_DOCTOR_PAST_TIMETABLE_REQUEST,
  PATCH_DOCTOR_EXCEPTION_TIMETABLE_REQUEST,
  PATCH_DOCTOR_RECURRENT_TIMETABLE_REQUEST,
  PATCH_DOCTOR_TIMETABLE_REQUEST,
  REMOVE_DOCTOR_EXCEPTION_TIMETABLE_REQUEST,
  REMOVE_DOCTOR_RECURRENT_TIMETABLE_REQUEST,
  REMOVE_DOCTOR_TIMETABLE_REQUEST,
} from 'shared/redux/types/DoctorTimetableTypes';
import {
  addBulkDoctorTimetableError,
  addBulkDoctorTimetableSuccess,
  addDoctorExceptionTimetableError,
  addDoctorExceptionTimetableSuccess,
  addDoctorRecurrentTimetableError,
  addDoctorRecurrentTimetableSuccess,
  addDoctorTimetableError,
  addDoctorTimetableSuccess,
  fetchDoctorTimetableError,
  fetchDoctorTimetableRequest,
  fetchDoctorTimetableSuccess,
  fetchMergedDoctorTimetableError,
  fetchMergedDoctorTimetableRequest,
  fetchMergedDoctorTimetableSuccess,
  getDoctorPastTimetableError,
  getDoctorPastTimetableSuccess,
  locallyAddDate,
  patchDoctorExceptionTimetableError,
  patchDoctorExceptionTimetableSuccess,
  patchDoctorRecurrentTimetableError,
  patchDoctorRecurrentTimetableSuccess,
  patchDoctorTimetableError,
  patchDoctorTimetableSuccess,
  removeDoctorExceptionTimetableError,
  removeDoctorExceptionTimetableSuccess,
  removeDoctorRecurrentTimetableError,
  removeDoctorRecurrentTimetableSuccess,
  removeDoctorTimetableError,
  removeDoctorTimetableSuccess,
} from 'shared/redux/actions/DoctorTimetableActions';
import {
  addBulkDoctorTimetableAPI,
  addDoctorExceptionTimetableAPI,
  addDoctorRecurrentTimetableAPI,
  addDoctorTimetableAPI,
  getDoctorPastTimetableAPI,
  getDoctorTimetableAPI,
  getMergedDoctorTimetableAPI,
  patchDoctorExceptionTimetableAPI,
  patchDoctorRecurrentTimetableAPI,
  patchDoctorTimetableAPI,
  removeDoctorExceptionTimetableAPI,
  removeDoctorRecurrentTimetableAPI,
  removeDoctorTimetableAPI,
} from 'shared/redux/api/DoctorTimetableApi';
import {
  doctorMergedTimetableSelector,
  doctorTimetableSelector,
} from '../selector/DoctorTimetableSelector';
import StorageService from '../../services/StorageService';
import { getMyReviewsAPI } from '../api/MyReviewsApi';

function* getDoctorTimetable(actionType) {
  const doctorTimetableChannel = yield actionChannel(actionType);
  while (true) {
    yield take(doctorTimetableChannel);
    try {
      const response = yield call(getDoctorTimetableAPI);
      yield put(fetchDoctorTimetableSuccess(doctorTimetableSelector(response.data)));
    } catch ({ message }) {
      yield put(fetchDoctorTimetableError({ message }));
    }
  }
}

function* patchDoctorTimetable(actionType) {
  const patchDoctorTimetableChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(patchDoctorTimetableChannel);
    try {
      yield call(patchDoctorTimetableAPI, payload.toRequest);
      yield put(patchDoctorTimetableSuccess(payload.toRequest));
      yield put(fetchDoctorTimetableRequest({}));
      yield put(goBack());
    } catch ({ message }) {
      yield put(patchDoctorTimetableError({ message }));
    }
  }
}

function* addDoctorTimetable(actionType) {
  const addDoctorTimetableChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(addDoctorTimetableChannel);
    try {
      const response = yield call(addDoctorTimetableAPI, payload.toRequest);
      const goodPayload = { ...payload.toRequest, id: response.data?.id };
      yield put(addDoctorTimetableSuccess({ data: goodPayload, action: payload.action }));
      yield put(goBack());
    } catch ({ message }) {
      yield put(addDoctorTimetableError({ message }));
    }
  }
}

function* removeDoctorTimetable(actionType) {
  const removeDoctorTimetableChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(removeDoctorTimetableChannel);
    try {
      yield call(removeDoctorTimetableAPI, payload?.id);
      yield put(removeDoctorTimetableSuccess(payload));
      yield put(fetchDoctorTimetableRequest({}));
    } catch ({ message }) {
      yield put(removeDoctorTimetableError({ message }));
    }
  }
}

function* getDoctorPastTimetable(actionType) {
  const doctorPastTimetableChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(doctorPastTimetableChannel);
    try {
      const response = yield call(getDoctorPastTimetableAPI, payload.data);
      yield put(getDoctorPastTimetableSuccess(doctorTimetableSelector(response.data, true)));
      if (payload.withNavigation) {
        yield call(goBack());
      }
    } catch ({ message }) {
      yield put(getDoctorPastTimetableError({ message }));
    }
  }
}

function* addBulkDoctorTimetable(actionType) {
  const addBulkDoctorTimetableChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(addBulkDoctorTimetableChannel);
    try {
      yield call(addBulkDoctorTimetableAPI, payload.data);
      yield put(addBulkDoctorTimetableSuccess({}));
      yield call(goBack());
    } catch ({ message }) {
      yield put(addBulkDoctorTimetableError({ message }));
    }
  }
}

function* getMergedDoctorTimetable(actionType) {
  const mergedDoctorTimetableChannel = yield actionChannel(actionType);
  let isPharmacist = 'false';
  StorageService.getData('IS_PHARMACIST', 'false').then((response) => {
    isPharmacist = response;
  });
  while (true) {
    yield take(mergedDoctorTimetableChannel);
    try {
      let response = { data: [] };
      if (isPharmacist === 'false') {
        response = yield call(getMergedDoctorTimetableAPI);
      }

      yield put(fetchMergedDoctorTimetableSuccess(doctorMergedTimetableSelector(response.data)));
      // yield put(fetchMergedDoctorTimetableSuccess(response.data));
    } catch ({ message }) {
      yield put(fetchMergedDoctorTimetableError({ message }));
    }
  }
}

function* patchDoctorRecurrenceTimetable(actionType) {
  const patchDoctorRecurrenceTimetableChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(patchDoctorRecurrenceTimetableChannel);
    try {
      yield call(patchDoctorRecurrentTimetableAPI, payload.toRequest);
      yield put(patchDoctorRecurrentTimetableSuccess(payload.toRequest));
      yield put(fetchMergedDoctorTimetableRequest({}));
      yield call(goBack());
    } catch ({ message }) {
      yield put(patchDoctorRecurrentTimetableError({ message }));
    }
  }
}

function* addRecurrentDoctorTimetable(actionType) {
  const addRecurrentDoctorTimetableChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(addRecurrentDoctorTimetableChannel);
    try {
      const response = yield call(addDoctorRecurrentTimetableAPI, payload.toRequest);
      const goodPayload = { ...payload.toRequest, id: response.data?.id };
      yield put(addDoctorRecurrentTimetableSuccess({ data: goodPayload, action: payload.action }));
      // yield put(fetchDoctorTimetableRequest({}));
      yield call(goBack());
    } catch ({ message }) {
      yield put(addDoctorRecurrentTimetableError({ message }));
    }
  }
}

function* removeRecurrentDoctorTimetable(actionType) {
  const removeRecurrentDoctorTimetableChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(removeRecurrentDoctorTimetableChannel);
    try {
      yield call(removeDoctorRecurrentTimetableAPI, payload?.id);
      yield put(removeDoctorRecurrentTimetableSuccess(payload));
      yield put(fetchMergedDoctorTimetableRequest({}));
    } catch ({ message }) {
      yield put(removeDoctorRecurrentTimetableError({ message }));
    }
  }
}

function* patchDoctorExceptionTimetable(actionType) {
  const patchDoctorExceptionTimetableChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(patchDoctorExceptionTimetableChannel);
    try {
      yield call(patchDoctorExceptionTimetableAPI, payload.toRequest);
      yield put(patchDoctorExceptionTimetableSuccess(payload.toRequest));
      yield put(fetchMergedDoctorTimetableRequest({}));
      yield put(locallyAddDate({}));
      yield call(goBack());
    } catch ({ message }) {
      yield put(patchDoctorExceptionTimetableError({ message }));
    }
  }
}

function* addDoctorExceptionTimetable(actionType) {
  const addDoctorExceptionTimetableChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(addDoctorExceptionTimetableChannel);
    try {
      const response = yield call(addDoctorExceptionTimetableAPI, payload.toRequest);
      const goodPayload = { ...payload.toRequest, id: response.data?.id };
      yield put(addDoctorExceptionTimetableSuccess({ data: goodPayload, action: payload.action }));
      yield put(fetchMergedDoctorTimetableRequest({}));
      yield call(goBack());
      yield put(locallyAddDate({}));
    } catch ({ message }) {
      yield put(addDoctorExceptionTimetableError({ message }));
    }
  }
}

function* removeDoctorExceptionTimetable(actionType) {
  const removeDoctorExceptionTimetableChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(removeDoctorExceptionTimetableChannel);
    try {
      yield call(removeDoctorExceptionTimetableAPI, payload?.id);
      yield put(removeDoctorExceptionTimetableSuccess(payload));
      yield put(fetchMergedDoctorTimetableRequest({}));
    } catch ({ message }) {
      yield put(removeDoctorExceptionTimetableError({ message }));
    }
  }
}

function* doctorTimetableSaga() {
  yield fork(getDoctorTimetable, FETCH_DOCTOR_TIMETABLE_REQUEST);
  yield fork(patchDoctorTimetable, PATCH_DOCTOR_TIMETABLE_REQUEST);
  yield fork(addDoctorTimetable, ADD_DOCTOR_TIMETABLE_REQUEST);
  yield fork(removeDoctorTimetable, REMOVE_DOCTOR_TIMETABLE_REQUEST);
  yield fork(getDoctorPastTimetable, GET_DOCTOR_PAST_TIMETABLE_REQUEST);
  yield fork(addBulkDoctorTimetable, ADD_BULK_DOCTOR_TIMETABLE_REQUEST);
  yield fork(getMergedDoctorTimetable, FETCH_MERGED_DOCTOR_TIMETABLE_REQUEST);
  yield fork(patchDoctorRecurrenceTimetable, PATCH_DOCTOR_RECURRENT_TIMETABLE_REQUEST);
  yield fork(addRecurrentDoctorTimetable, ADD_DOCTOR_RECURRENT_TIMETABLE_REQUEST);
  yield fork(removeRecurrentDoctorTimetable, REMOVE_DOCTOR_RECURRENT_TIMETABLE_REQUEST);
  yield fork(patchDoctorExceptionTimetable, PATCH_DOCTOR_EXCEPTION_TIMETABLE_REQUEST);
  yield fork(addDoctorExceptionTimetable, ADD_DOCTOR_EXCEPTION_TIMETABLE_REQUEST);
  yield fork(removeDoctorExceptionTimetable, REMOVE_DOCTOR_EXCEPTION_TIMETABLE_REQUEST);
}

export default doctorTimetableSaga;
