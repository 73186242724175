import 'doctor/styles/appointments.css';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getDoctorUpcomingAppointmentsState } from 'shared/redux/src/StatesGetter';
import Utils from 'shared/modules/Utils';
import {
  cancelAllAppointmentsByDoctorRequest,
  fetchDoctorUpcomingAppointmentsRequest,
  getAppointmentDetailsRequest,
} from 'shared/redux/actions/DoctorAppointmentsActions';
import Alerts from 'shared/components/Alerts';
import { Link, useNavigate } from 'react-router-dom';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { fetchMedicalRecordCategoryRequest } from 'shared/redux/actions/MedicalRecordCategoryActions';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import UpcomingConsultationListItem from 'shared/components/consultations/UpcomingConsultationListItem';
import dayjs from 'dayjs';

const DoctorAppointmentsTabFuture = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModalOptionsAppointments, setModalOptionsAppointmentsShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const modalOptionsAppointmentsClose = () => {
    setModalOptionsAppointmentsShow(false);
    setSelectedDate('');
  };
  const modalOptionsAppointmentsShow = (date) => {
    setModalOptionsAppointmentsShow(true);
    setSelectedDate(date);
  };
  const {
    data: upcomingAppointments,
    currentPage: upcomingAppointmentsCurrentPage,
    noOfPages: upcomingAppointmentsNoOfPages,
  } = useSelector(getDoctorUpcomingAppointmentsState, shallowEqual);
  const [nextScheduledAppointment, setNextScheduledAppointment] = useState<Record<
    string,
    any
  > | null>(null);
  const [formattedUpcomingAppointments, setFormattedFutureAppointments] = useState(
    Utils.makeSectionListFormat(upcomingAppointments, 'dateFormattedFull'),
  );
  const loadNextPageUpcomingAppointments = () => {
    if (upcomingAppointmentsCurrentPage <= upcomingAppointmentsNoOfPages) {
      dispatch(
        fetchDoctorUpcomingAppointmentsRequest({
          page: upcomingAppointmentsCurrentPage,
          nextPage: true,
        }),
      );
    }
  };
  const fetchUpcomingAppointments = () => dispatch(fetchDoctorUpcomingAppointmentsRequest({}));

  useEffect(() => {
    fetchUpcomingAppointments();
  }, []);

  useEffect(() => {
    setNextScheduledAppointment(upcomingAppointments[0] ?? null);
    setFormattedFutureAppointments(
      Utils.makeSectionListFormat(upcomingAppointments, 'dateFormattedFull'),
    );
  }, [upcomingAppointments]);

  const goToDetails = (e, id) => {
    e.preventDefault();
    dispatch(
      getAppointmentDetailsRequest({ id, withNavigate: true, navigationTemplate: 'future' }),
    );
    dispatch(fetchMedicalRecordCategoryRequest({}));
  };
  const renderSubTitle = (appointment) => {
    if (appointment?.medicalRecords?.length) {
      return (
        <div className="year-files">
          {Utils.calculateAge(appointment?.patient?.birthDate) > 0 &&
            `${Utils.calculateAge(appointment?.patient?.birthDate)} ${t('years')} • `}
          <CustomIcon className="custom-icon" color={Colors.mediumGrey} size="15" icon="Document" />
          {t('attachedFiles')}
        </div>
      );
    }
    if (Utils.calculateAge(appointment?.patient?.birthDate) > 0) {
      return (
        <div className="year-files">{`${Utils.calculateAge(appointment?.patient?.birthDate)} ${t(
          'years',
        )}`}</div>
      );
    }
    return null;
  };

  const cancelAppointments = () => {
    Alerts.okCancelAlert(
      t('warning'),
      'Esti sigur ca vrei sa anulezi toate programarile?',
      () => {
        dispatch(cancelAllAppointmentsByDoctorRequest({ date: selectedDate }));
        modalOptionsAppointmentsClose();
      },
      () => {},
    );
  };

  const renderItem = (item, isNextScheduledAppointment) => {
    const { patient, user, doctor } = item;
    const { picture, firstName = '', lastName = '' } = patient ?? user ?? {};

    return (
      <UpcomingConsultationListItem
        onCallNowClicked={() => {}}
        listItem={{ ...item, paymentLimitText: [], isNextScheduledAppointment }}
        isDoctor
        user={{
          ...patient,
          specialization: doctor.specialization,
          imgUrl: picture,
          fullNameWithTitle: `${firstName} ${lastName}`,
        }}
      />
    );
  };

  const getTranslatedDate = (dateValue) => {
    const months = {
      Ianuarie: 'January',
      Februarie: 'February',
      Martie: 'March',
      Aprilie: 'April',
      Mai: 'May',
      Iunie: 'June',
      Iulie: 'July',
      August: 'August',
      Septembrie: 'September',
      Octombrie: 'October',
      Noiembrie: 'November',
      Decembrie: 'December',
    };
    const arrayData = dateValue.split(' ');
    const newDateValue = `${arrayData[0]} ${months[arrayData[1]]} ${arrayData[2]}`;

    return dayjs(newDateValue).format('YYYY-MM-DD');
  };
  return (
    <div className="appointments-tab-future" id="appointments-tab-future">
      {upcomingAppointments.length > 0 ? (
        <InfiniteScroll
          dataLength={upcomingAppointments.length}
          next={loadNextPageUpcomingAppointments}
          hasMore={upcomingAppointmentsCurrentPage <= upcomingAppointmentsNoOfPages}
          loader={<LoadingSpinner isLoading />}
        >
          {!upcomingAppointments.length && (
            <div className="empty-container">{t('history.noData')}</div>
          )}
          {formattedUpcomingAppointments.map((section) => (
            <div key={section.key}>
              <div className="subtitle">
                {section.key}
                <div
                  className="options"
                  onClick={() => modalOptionsAppointmentsShow(getTranslatedDate(section.key))}
                >
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.mediumGrey}
                    size="24"
                    icon="List"
                  />
                </div>
              </div>
              {section?.data?.length ? (
                <>
                  {section?.data?.map((appointment) =>
                    renderItem(appointment, nextScheduledAppointment?.id === appointment.id),
                  )}
                </>
              ) : (
                <div className="empty-container">{t('history.noData')}</div>
              )}
            </div>
          ))}
        </InfiniteScroll>
      ) : (
        <div className="empty-container">{t('history.noData')}</div>
      )}

      <Modal
        show={showModalOptionsAppointments}
        onHide={modalOptionsAppointmentsClose}
        animation
        centered
        className="modal-options-appointments medic-modal"
      >
        <Modal.Header closeButton closeLabel={t('close2')}>
          <Modal.Title>
            {t('history.optionsConsultations')}
            {/* <div className="data">{selectedDate}</div> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <button
            type="button"
            onClick={cancelAppointments}
            className="cancel-all-appointments-btn"
          >
            <CustomIcon
              className="custom-icon"
              color={Colors.warningColor}
              size="24"
              icon="Delete"
            />
            {t('history.cancelAllConsultations')}
            <CustomIcon
              className="custom-icon"
              color={Colors.warningColor}
              size="24"
              icon="Right_pointer"
            />
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

DoctorAppointmentsTabFuture.defaultProps = {
  match: {},
  location: {},
};
DoctorAppointmentsTabFuture.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({}),
  }),
};
export default DoctorAppointmentsTabFuture;
