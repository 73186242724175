const positiveLabel = 'positive';
const negativeLabel = 'negative';
export default [
  {
    id: 'A+',
    name: `A`,
    name2: `${positiveLabel}`,
  },
  {
    id: 'A-',
    name: `A`,
    name2: `${negativeLabel}`,
  },
  {
    id: 'B+',
    name: `B`,
    name2: `${positiveLabel}`,
  },
  {
    id: 'B-',
    name: `B`,
    name2: `${negativeLabel}`,
  },
  {
    id: 'O+',
    name: `O`,
    name2: `${positiveLabel}`,
  },
  {
    id: 'O-',
    name: `O`,
    name2: `${negativeLabel}`,
  },
  {
    id: 'AB+',
    name: `AB`,
    name2: `${positiveLabel}`,
  },
  {
    id: 'AB-',
    name: `AB`,
    name2: `${negativeLabel}`,
  },
];
