import { ConnectOptions } from 'twilio-video';
import { isMobile } from './utils';

const connectionOptions: ConnectOptions = {
  // Bandwidth Profile, Dominant Speaker, and Network Quality
  // features are only available in Small Group or Group Rooms.
  // Please set "Room Type" to "Group" or "Small Group" in your
  // Twilio Console: https://www.twilio.com/console/video/configure
  bandwidthProfile: {
    video: {
      mode: 'collaboration',
      dominantSpeakerPriority: 'standard',
      trackSwitchOffMode: 'disabled',
      contentPreferencesMode: 'auto',
      clientTrackSwitchOffControl: 'manual',
    },
  },
  dominantSpeaker: true,
  networkQuality: { local: 1, remote: 1 },

  // Comment this line if you are playing music.
  maxAudioBitrate: 16000,

  preferredVideoCodecs: 'auto',

  // @ts-ignore - Internal use only. This property is not exposed in type definitions.
  environment: process.env.REACT_APP_TWILIO_ENVIRONMENT,
};

// For mobile browsers, limit the maximum incoming video bitrate to 2.5 Mbps.
if (isMobile && connectionOptions?.bandwidthProfile?.video) {
  connectionOptions!.bandwidthProfile!.video!.maxSubscriptionBitrate = 2500000;
}

export default connectionOptions;
