import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Alerts from 'shared/components/Alerts';
import { changePasswordRequest } from 'shared/redux/actions/AuthActions';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { useTranslation } from 'react-i18next';

const DoctorAccountChangePasswordBlock = () => {
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const dispatch = useDispatch();
  const [actualPasswordShown, setActualPasswordShown] = useState(false);
  const toggleActualPasswordVisibility = () => {
    setActualPasswordShown(!actualPasswordShown);
  };
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const toggleNewPasswordVisibility = () => {
    setNewPasswordShown(!newPasswordShown);
  };
  const [repeatNewPasswordShown, setRepeatNewPasswordShown] = useState(false);
  const toggleRepeatNewPasswordVisibility = () => {
    setRepeatNewPasswordShown(!repeatNewPasswordShown);
  };
  const onPress = (e) => {
    e.preventDefault();
    if (!oldPassword || !newPassword || !confirmNewPassword) {
      Alerts.simpleAlert(t('error'), t('account.changePasswordValidation3'));
      return;
    }
    if (!oldPassword) {
      Alerts.simpleAlert(t('error'), t('account.changePasswordValidation1'));
      return;
    }
    if (newPassword !== confirmNewPassword) {
      Alerts.simpleAlert(t('error'), t('account.changePasswordValidation2'));
      return;
    }
    dispatch(
      changePasswordRequest({
        currentPassword: oldPassword,
        newPassword: { first: newPassword, second: confirmNewPassword },
      }),
    );
  };
  return (
    <div className="account-change-password">
      <div className="title-section">
        <h2>{t('settings.changePassword')}</h2>
      </div>
      <form onSubmit={onPress}>
        <div className="password-eye">
          <input
            name="actual-password"
            id="actual-password"
            type={actualPasswordShown ? 'text' : 'password'}
            value={oldPassword}
            onChange={(event) => setOldPassword(event.target.value)}
            className="form-control"
            placeholder=" "
          />
          <label htmlFor="actual-password">{t('account.oldPassword')}</label>
          <div className="eye" onClick={toggleActualPasswordVisibility}>
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="24"
              icon={`${actualPasswordShown ? 'Eye_off' : 'Eye'}`}
            />
          </div>
        </div>
        <div className="password-eye">
          <input
            name="new-password"
            id="new-password"
            type={newPasswordShown ? 'text' : 'password'}
            value={newPassword}
            onChange={(event) => setNewPassword(event.target.value)}
            className="form-control"
            placeholder=" "
          />
          <label htmlFor="new-password">{t('account.newPassword')}</label>
          <div className="eye" onClick={toggleNewPasswordVisibility}>
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="24"
              icon={`${newPasswordShown ? 'Eye_off' : 'Eye'}`}
            />
          </div>
        </div>
        <div className="password-eye">
          <input
            name="repeat-new-password"
            id="repeat-new-password"
            type={repeatNewPasswordShown ? 'text' : 'password'}
            value={confirmNewPassword}
            onChange={(event) => setConfirmNewPassword(event.target.value)}
            className="form-control"
            placeholder=" "
          />
          <label htmlFor="repeat-new-password">{t('account.confirmNewPassword')}</label>
          <div className="eye" onClick={toggleRepeatNewPasswordVisibility}>
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="24"
              icon={`${repeatNewPasswordShown ? 'Eye_off' : 'Eye'}`}
            />
          </div>
        </div>
        <button type="submit" className="green-button">
          {t('account.changePasswordBtn')}
        </button>
      </form>
    </div>
  );
};

export default DoctorAccountChangePasswordBlock;
