import 'patient/styles/appointment-steps.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchDoctorProfileRequest } from 'shared/redux/actions/DoctorProfileActions';
import { useTranslation } from 'react-i18next';
import CustomIcon from 'shared/components/CustomIcon';
import {
  getAuthState,
  getDoctorProfileState,
  getPatientAppointmentState,
} from 'shared/redux/src/StatesGetter';
import Colors from 'shared/themes/Colors';
import { appointmentLocalStepsRequest } from 'shared/redux/actions/PatientAppointmentActions';
import Alerts from 'shared/components/Alerts';
import {
  isAnyAppointmentInProgress,
  requestAppointment,
} from 'shared/modules/PatientAppointmentUtils';
import { patientLocallyChooseSpecialization } from 'shared/redux/actions/DoctorsActions';

const PatientAppointmentSpecialization = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    status: appointmentStatus,
    stickyStatus,
    doctor,
    specializationId,
    type,
    noOfSteps,
    id: appointmentId,
  } = useSelector(getPatientAppointmentState, shallowEqual);
  const {
    isAvailableForCallNow,
    pictureMedia,
    firstName,
    lastName,
    fullNameWithTitle,
    averageRating,
    countRating,
  } = doctor;
  const doctorProfile = useSelector(getDoctorProfileState, shallowEqual);
  const { doctorSpecializations } = doctorProfile;
  // const activeDoctorServices = doctorSpecializations
  //   .filter((specialization) => {
  //     // @ts-ignore
  //     return specialization.id === parseInt(doctorSpecializationParam, 10);
  //   })
  //   .map((specialization) => {
  //     return specialization.doctorServices.filter((service) => {
  //       return service.is_active === true;
  //     });
  //   });

  const stepString = `${t('step')} 1/${noOfSteps}`;

  const onSpecializationClick = (e, selectedSpecialization) => {
    e.preventDefault();
    if (isAnyAppointmentInProgress(appointmentStatus, stickyStatus)) {
      Alerts.simpleAlert(t('info'), t('validations.alreadyHasAppointment'));
      return;
    }
    const selectedSpecializationId = selectedSpecialization.parentId;
    const selectedSpecializationName = selectedSpecialization.name;
    dispatch(
      patientLocallyChooseSpecialization({
        id: selectedSpecializationId,
        name: selectedSpecializationName,
      }),
    );
    switch (type) {
      case 'callNow':
        if (isAvailableForCallNow) {
          requestAppointment('callNow', {
            forRequest: {
              doctor: `${doctor?.id}`,
              specialization: `${selectedSpecializationId}`,
            },
            forUpdate: {
              specializationId: selectedSpecializationId,
              doctor: { ...doctor, specializationName: selectedSpecializationName },
            },
          });
        }
        break;
      default:
        if (
          selectedSpecialization.doctorServices.filter((service) => {
            return service.is_active === true;
          }).length === 2
        ) {
          dispatch(
            appointmentLocalStepsRequest({
              newAppointment: true,
              noOfSteps: 6,
              specializationId: selectedSpecializationId,
              type: 'programmed',
              doctor: { ...doctor, specializationName: selectedSpecializationName },
            }),
          );
          dispatch(
            fetchDoctorProfileRequest({
              id: doctor?.id,
            }),
          );
          navigate(`/patient/appointment/choose-service?ds=${selectedSpecialization.id}`);
        } else {
          dispatch(
            appointmentLocalStepsRequest({
              newAppointment: true,
              noOfSteps: 5,
              specializationId: selectedSpecializationId,
              type: 'programmed',
              doctor: { ...doctor, specializationName: selectedSpecializationName },
            }),
          );
          dispatch(
            fetchDoctorProfileRequest({
              id: doctor?.id,
            }),
          );
          navigate('/patient/appointment/set-date');
        }

        break;
    }
  };

  const abortAction = () => navigate(-1);

  return (
    <section className="appointment-steps">
      <div className="title-section">
        <div className="steps">
          <div className="icon-wrapper" onClick={() => navigate(-1)}>
            <CustomIcon
              className="custom-icon"
              color={Colors.darkGrey}
              size="24"
              icon="Left_arrow"
            />
          </div>
          {stepString}
          <div className="cancel-button">
            <button type="button" onClick={abortAction}>
              {t('abort')}
            </button>
          </div>
        </div>
        <h1>{t('specialization')}</h1>
      </div>
      <div className="medic-box">
        <div
          className="avatar"
          style={
            pictureMedia
              ? { backgroundImage: `url(${pictureMedia})` }
              : { backgroundColor: '#E5E7E8' }
          }
        >
          {!pictureMedia && (
            <div className="image-placeholder">{`${firstName?.charAt(0)}${lastName?.charAt(
              0,
            )}`}</div>
          )}
        </div>
        <div className="info">
          <div className="name">{fullNameWithTitle}</div>
          <div className="rating">
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="16"
              icon="Rating_2"
            />
            <i className="rating-icon" />
            {t('grade')} {averageRating} ({countRating} {t('noOfReviews')})
          </div>
        </div>
      </div>
      <form className="specializations">
        {doctorSpecializations?.length > 0 &&
          doctorSpecializations.map((specialization) => {
            return (
              <div
                key={specialization.id}
                className="specialization-item"
                onClick={(e) => onSpecializationClick(e, specialization)}
              >
                <div>
                  <div className="specialization-name">{specialization.name}</div>
                  <div className="specialization-details">
                    {specialization.doctorServices
                      .filter((row) => {
                        // @ts-ignore
                        return row.service.slug === 'consultation';
                      })
                      .map((row) => {
                        // @ts-ignore
                        return row.price_as_string;
                      })}{' '}
                    /{' '}
                    {specialization.doctorServices
                      .filter((row) => {
                        // @ts-ignore
                        return row.service.slug === 'consultation';
                      })
                      .map((row) => {
                        // @ts-ignore
                        return row.duration;
                      })}{' '}
                    {t('minutes')}
                  </div>
                </div>

                <CustomIcon
                  className="custom-icon"
                  color={Colors.darkGrey}
                  size="24"
                  icon="Right_pointer"
                />
              </div>
            );
          })}
      </form>
    </section>
  );
};

export default PatientAppointmentSpecialization;
