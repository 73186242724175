import { Form, Modal } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import {
  getAuthState,
  getLocalSpecializationState,
  servicesState,
} from 'shared/redux/src/StatesGetter';
import {
  associateSpecializationRequest,
  locallyRemoveSpecialization,
} from 'shared/redux/actions/UserProfileActions';
import Alerts from 'shared/components/Alerts';
import _ from 'lodash';
import { Simulate } from 'react-dom/test-utils';
import SpecializationPicker from './SpecializationPicker';
import Utils from '../../../shared/modules/Utils';
import { getServicesRequest } from '../../../shared/redux/actions/ServiceActions';

const AddSpecializationModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showModalNewSpecialization, setModalNewSpecializationShow, contextType } = props;
  const specialization = useSelector(getLocalSpecializationState, shallowEqual);
  const services = useSelector(servicesState, shallowEqual);
  const [showModalSpecializationPicker, setModalSpecializationPickerShow] = useState(false);
  const modalSpecializationPickerShow = () => {
    setModalSpecializationPickerShow(true);
    setModalNewSpecializationShow(false);
  };
  const [proofName, setProofName] = useState('');
  const [proofSize, setProofSize] = useState(0);
  const [proofType, setProofType] = useState('');
  const [proofData, setProofData] = useState(null);
  const [acceptChildren, setAcceptChildren] = useState(false);

  const [consultationPrice, setConsultationPrice] = useState('');
  const [consultationDuration, setConsultationDuration] = useState('');
  const [consultationPause, setConsultationPause] = useState('');
  const hiddenFileInput = useRef(null);

  const modalNewSpecializationClose = () => {
    setProofData(null);
    setConsultationPrice('');
    setConsultationDuration('');
    setConsultationPause('');
    setModalNewSpecializationShow(false);
    dispatch(locallyRemoveSpecialization({}));
  };

  useEffect(() => {
    dispatch(getServicesRequest({}));
  }, []);

  const servicesWOConsultation = Object.values(services).filter((service) => {
    // @ts-ignore
    return service.slug !== 'consultation';
  });
  const [servicesOffered, setServicesOffered] = useState(
    Utils.getServiceCheckboxValue(servicesWOConsultation),
  );

  const [prices, setPrices] = useState<Record<string, any>>(
    Utils.getAddSpecializationPrices(servicesWOConsultation),
  );
  const [durations, setDurations] = useState<Record<string, any>>(
    Utils.getAddSpecializationDuration(servicesWOConsultation),
  );
  const [pauses, setPauses] = useState<Record<string, any>>(
    Utils.getAddSpecializationPauses(servicesWOConsultation),
  );

  const updateServicesOffered = (key, value) => {
    setServicesOffered((prevState) => {
      // eslint-disable-next-line no-param-reassign
      prevState[key] = value;
      return { ...prevState };
    });
  };

  const updatePrice = (key, value) => {
    prices[key] = value;
    setPrices(prices);
  };
  const updateDuration = (key, value) => {
    durations[key] = value;
    setDurations(durations);
  };
  const updatePause = (key, value) => {
    pauses[key] = value;
    setPauses(pauses);
  };

  const onFileChange = (event) => {
    const chosenFile = event.target.files[0] ?? {};
    setProofData(chosenFile);
    setProofName(chosenFile.name);
    setProofSize(chosenFile.size);
    setProofType(chosenFile.type);
  };
  const onPress = (e) => {
    e.preventDefault();
    if (proofName?.length) {
      let toAdd = {
        specialization: specialization?.id,
        acceptChildren: acceptChildren ? 1 : 0,
        files: [
          {
            originalName: proofName,
            mimeType: proofType,
            size: proofSize,
          },
        ],
        consultation: [
          {
            price: consultationPrice,
            duration: consultationDuration,
            pause: consultationPause,
          },
        ],
      };

      servicesWOConsultation
        .filter((service) => {
          // @ts-ignore
          return servicesOffered[service.slug];
        })
        .map((service) => {
          // @ts-ignore
          const { slug } = service;
          const addedService = {};
          addedService[slug] = [
            {
              price: prices[slug],
              duration: durations[slug],
              pause: pauses[slug],
            },
          ];

          toAdd = { ...toAdd, ...addedService };
          return true;
        });

      dispatch(
        associateSpecializationRequest({
          toAdd,
          destination: contextType === 'unprofiled' ? 'unprofiled' : 'userProfile',
          proofFile: proofData,
        }),
      );

      // modalNewSpecializationClose();
    } else {
      Alerts.simpleAlert(t('error'), t('alerts.addFile'));
    }
  };
  return (
    <>
      <Modal
        show={showModalNewSpecialization}
        onHide={modalNewSpecializationClose}
        animation
        centered
        className="modal-new-specialization medic-modal"
      >
        <Modal.Header closeButton closeLabel={t('close2')}>
          <Modal.Title>{t('newSpecialization')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="info-message">
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="24"
              icon="Warning_2"
            />
            {t('addSpecializationText')}
          </div>
          <Form>
            <div
              className="choose-specialization-type-select"
              onClick={modalSpecializationPickerShow}
            >
              <div className="name">{specialization?.name ?? t('addSpecialization')}</div>
              <CustomIcon
                className="custom-icon rotate-90"
                color={Colors.mediumGrey}
                size="24"
                icon="Right_pointer"
              />
            </div>
            {proofData ? (
              <div className="uploaded-box">
                <div className="uploaded-file">
                  <div className="file-name">
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.darkGrey}
                      size="24"
                      icon="Document"
                    />
                    <span>{proofName}</span>
                  </div>
                  <button type="button" onClick={() => setProofData(null)}>
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.mediumGrey}
                      size="24"
                      icon="Delete"
                    />
                  </button>
                </div>
              </div>
            ) : (
              <div className="upload-files">
                <Form.Group controlId="proofFile" className="files-box">
                  <Form.Label>
                    <div className="info-file cursor-pointer">
                      <div className="title">{t('uploadProof')}</div>
                      <div className="details">{t('account.limitUpload20')}</div>
                    </div>
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.medicColor}
                      size="24"
                      icon="Upload"
                    />
                  </Form.Label>
                  <Form.Control type="file" ref={hiddenFileInput} onChange={onFileChange} />
                </Form.Group>
              </div>
            )}
            <div className="custom-control custom-checkbox green-checkbox align-center-checkbox left-justify-content">
              <input
                className="custom-control-input"
                id="consult_children"
                type="checkbox"
                checked={acceptChildren}
                onChange={() => {
                  setAcceptChildren(!acceptChildren);
                }}
              />
              <label htmlFor="consult_children" className="custom-control-label">
                {t('acceptChildren')}
              </label>
            </div>
            {servicesWOConsultation.map((service) => {
              // @ts-ignore
              const { slug } = service;
              return (
                <div
                  key={_.uniqueId()}
                  className="custom-control custom-checkbox green-checkbox align-center-checkbox left-justify-content"
                >
                  <input
                    className="custom-control-input"
                    id="control-service-offer"
                    type="checkbox"
                    checked={servicesOffered[slug]}
                    onChange={() => {
                      updateServicesOffered(slug, !servicesOffered[slug]);
                    }}
                  />
                  <label htmlFor="control-service-offer" className="custom-control-label">
                    {t(`${slug}-service-offer`)}
                  </label>
                </div>
              );
            })}
            {/* CONSULTATION */}
            <div className="mt-4">
              <div className="mb-3">
                <div className="subtitle-services">
                  <div className="col-lg-6 col-xl-6 col-md-6 col-12 pt-2">Consultatie</div>
                </div>
                <div className="row">
                  <div>
                    <div className="form-input">
                      <input
                        autoComplete="off"
                        id="price_consultation"
                        name="price_consultation"
                        type="text"
                        className="form-control"
                        placeholder=" "
                        value={consultationPrice}
                        onChange={(event) => setConsultationPrice(event.target.value)}
                      />
                      <label htmlFor="price_consultation">
                        {t('appointments.appointmentPrice')}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row rowp10">
                  <div className="col-lg-6 col-xl-6 col-md-6 col-12 colp10">
                    <div className="form-input">
                      <input
                        autoComplete="off"
                        id="duration_consultation"
                        name="duration_consultation"
                        type="text"
                        className="form-control"
                        placeholder=" "
                        value={consultationDuration}
                        onChange={(event) => setConsultationDuration(event.target.value)}
                      />
                      <label htmlFor="duration_consultation">{t('appointments.duration')}</label>
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-6 col-md-6 col-12 colp10">
                    <div className="form-input">
                      <input
                        autoComplete="off"
                        id="pause_consultation"
                        name="pause_consultation"
                        type="text"
                        className="form-control"
                        placeholder=" "
                        value={consultationPause}
                        onChange={(event) => setConsultationPause(event.target.value)}
                      />
                      <label htmlFor="pause_consultation">{t('appointments.pause')}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* SERVICES */}
            {servicesWOConsultation.map((service) => {
              // @ts-ignore
              const { slug } = service;
              return (
                <div key={_.uniqueId()} className={`${servicesOffered[slug] ? '' : 'd-none'}`}>
                  <div className="mb-3">
                    <div className="subtitle-services">
                      <div className="col-lg-6 col-xl-6 col-md-6 col-12 pt-2">
                        {Utils.capitalizeFirstLetter(t(`${slug}`))}
                      </div>
                    </div>
                    <div className="row">
                      <div>
                        <div className="form-input">
                          <input
                            autoComplete="off"
                            id={`price_${slug}`}
                            name={`price_${slug}`}
                            type="text"
                            className="form-control"
                            placeholder=" "
                            onChange={(event) => updatePrice(slug, event.target.value)}
                          />
                          <label htmlFor={`price_${slug}`}>{t(`appointments.${slug}Price`)}</label>
                        </div>
                      </div>
                    </div>
                    <div className="row rowp10">
                      <div className="col-lg-6 col-xl-6 col-md-6 col-12 colp10">
                        <div className="form-input">
                          <input
                            autoComplete="off"
                            id={`duration_${slug}`}
                            name={`duration_${slug}`}
                            type="text"
                            className="form-control"
                            placeholder=" "
                            onChange={(event) => updateDuration(slug, event.target.value)}
                          />
                          <label htmlFor={`duration_${slug}`}>{t('appointments.duration')}</label>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-md-6 col-12 colp10">
                        <div className="form-input">
                          <input
                            autoComplete="off"
                            id={`pause_${slug}`}
                            name={`pause_${slug}`}
                            type="text"
                            className="form-control"
                            placeholder=" "
                            onChange={(event) => updatePause(slug, event.target.value)}
                          />
                          <label htmlFor={`pause_${slug}`}>{t('appointments.pause')}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="green-button" onClick={onPress}>
            {t('addSpecialization')}
          </button>
        </Modal.Footer>
      </Modal>
      <SpecializationPicker
        showModalSpecializationPicker={showModalSpecializationPicker}
        setModalSpecializationPickerShow={setModalSpecializationPickerShow}
        showModalNewSpecialization={showModalNewSpecialization}
        setModalNewSpecializationShow={setModalNewSpecializationShow}
      />
    </>
  );
};

export default AddSpecializationModal;
