import 'shared/styles/partners.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { isDoctor } from 'shared/services/EnvService';
import FooterSection from 'shared/components/FooterSection';
import HeaderMenu from 'shared/components/HeaderMenu';
import HeaderMenuMobile from 'shared/components/HeaderMenuMobile';
import CustomIcon from 'shared/components/CustomIcon';
import partnersImage from 'shared/assets/images/telefon-parteneri.png';
import logoCardioRec from 'shared/assets/images/logo-cardio-rec.png';
import logoMacMac from 'shared/assets/images/logo-macmac.png';
import logoNord from 'shared/assets/images/logo-nord.png';
import appPatientImage from 'shared/assets/images/about-us/aplicatie-pacient-image.png';
import openGraphImage from 'shared/assets/images/ringdoc_image_open_graph.png';
import Colors from 'shared/themes/Colors';
import { isMobile } from 'react-device-detect';
import Utils from '../modules/Utils';
import appMedicImage from '../assets/images/aplicatie-medic-image.png';

const Partners = () => {
  const { t } = useTranslation();

  let doctorAppStoreLink;
  if (Utils.isiPhone()) {
    doctorAppStoreLink = 'itms-apps://apps.apple.com/us/app/ringdoc-medic/id1574488917';
  } else {
    doctorAppStoreLink = 'https://apps.apple.com/us/app/ringdoc-medic/id1574488917';
  }
  let patientAppStoreLink;
  if (Utils.isiPhone()) {
    patientAppStoreLink = 'itms-apps://apps.apple.com/us/app/ringdoc/id1574488837';
  } else {
    patientAppStoreLink = 'https://apps.apple.com/us/app/ringdoc/id1574488837';
  }

  const currentUrl = window.location.href;
  const baseUrl = window.location.origin;

  return (
    <div className="partners-page">
      <Helmet>
        <title>{t('partners.titlePage')}</title>
        <meta
          name="description"
          content="Ringdoc este o clinica medicala care opereaza o platforma medicala virtuala oferind astfel medici online disponibili rapid"
        />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:locale" content="ro_RO" />
        <meta property="og:locale:alternate" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={t('partners.titlePage')} />
        <meta
          property="og:description"
          content="Ringdoc este o clinica medicala care opereaza o platforma medicala virtuala oferind astfel medici online disponibili rapid"
        />
        <meta property="og:image" content={`${baseUrl}${openGraphImage}`} />
      </Helmet>

      {isDoctor(true) && <HeaderMenuMobile />}

      <div className="container-fluid">
        <div className="row">
          <HeaderMenu />

          <main className="container">
            <section className="title">
              <h1>{t('partners.titlePage')}</h1>
            </section>
            <section className="partners">
              <div className="partners-info">
                {!isMobile && (
                  <div className="partners-image">
                    <img src={partnersImage} className="img-fluid" alt={t('partners.titlePage')} />
                  </div>
                )}
                <div
                  className={
                    isMobile
                      ? 'partners-description partners-description-mobile'
                      : 'partners-description'
                  }
                >
                  <p>{t('partners.subtitle1')}</p>
                </div>
              </div>
            </section>

            <section className={isMobile ? 'logos logos-mobile' : 'logos'}>
              <div className="white-box">
                <img src={logoNord} className="img-fluid" alt="" />
              </div>
              <div className="white-box">
                <img src={logoMacMac} className="img-fluid" alt="" />
              </div>
              <div className="white-box">
                <img src={logoCardioRec} className="img-fluid" alt="" />
              </div>
            </section>

            <section className="download-app patient">
              <div className="column">
                <h3>{t('aboutUsPage.downloadAppPatient')}</h3>
                <div className="apps-button">
                  <a target="_blank" href={patientAppStoreLink} rel="noreferrer">
                    <i className="app-store-icon cursor-pointer" />
                  </a>
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=ro.ascendnet.alodoc.patient.ro"
                    rel="noreferrer"
                  >
                    <i className="google-play-icon cursor-pointer" />
                  </a>
                </div>
              </div>
              <div className="column">
                <img src={appPatientImage} alt="Descarca Aplicatia" />
              </div>
            </section>

            <section className="download-app medic">
              <div className="column">
                <h3>{t('homepageDoctor.downloadAppDoctor')}</h3>
                <div className="apps-button">
                  <a
                    target="_blank"
                    href={
                      Utils.isiPhone()
                        ? 'itms-apps://apps.apple.com/us/app/ringdoc-medic/id1574488917'
                        : 'https://apps.apple.com/us/app/ringdoc-medic/id1574488917'
                    }
                    rel="noreferrer"
                  >
                    <i className="app-store-icon cursor-pointer" />
                  </a>
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=ro.ascendnet.alodoc.doctor.ro"
                    rel="noreferrer"
                  >
                    <i className="google-play-icon cursor-pointer" />
                  </a>
                </div>
              </div>
              <div className="column">
                <img src={appMedicImage} alt="Descarca Aplicatia" />
              </div>
            </section>
          </main>

          <FooterSection />
        </div>
      </div>
    </div>
  );
};

export default Partners;
