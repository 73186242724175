import 'patient/styles/medical-folder.css';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import Alerts from 'shared/components/Alerts';
import { getAuthState, getFamilyMembersState } from 'shared/redux/src/StatesGetter';
import {
  getFamilyMembersRequest,
  removeFamilyMemberRequest,
} from 'shared/redux/actions/FamilyMembersActions';
import { locallyCurrentPatientProfile } from 'shared/redux/actions/PatientProfileActions';
import { useTranslation } from 'react-i18next';

const PatientMedicalFolderSelectProfile = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const familyMembers = useSelector(getFamilyMembersState, shallowEqual);
  const [modifyShown, setModifyShown] = useState(false);
  const toggleModify = () => setModifyShown(!modifyShown);
  const removeProfile = (id) => {
    Alerts.okCancelAlert(t('warning'), t('validations.deleteMemberProfile'), () => {
      dispatch(removeFamilyMemberRequest(id));
      dispatch(locallyCurrentPatientProfile({}));
    });
  };
  const [selectedProfile, setSelectedProfile] = useState({
    id: familyMembers ? familyMembers[0]?.id : 0,
    data: familyMembers ? familyMembers[0] : {},
  });
  const onProfileSelection = (profile) => {
    dispatch(locallyCurrentPatientProfile(profile));
    setSelectedProfile({ id: profile.id, data: profile });
    navigate('/patient/medicalFolder', { state: 'profile' });
  };

  useEffect(() => {
    dispatch(getFamilyMembersRequest({}));
  }, []);
  useEffect(() => {
    setSelectedProfile({
      id: familyMembers ? familyMembers[0]?.id : 0,
      data: familyMembers ? familyMembers[0] : {},
    });
  }, [familyMembers]);

  return (
    <section className="medical-folder select-profile small-container">
      <div className="title-section">
        <h1>{t('medicalRecord')}</h1>
      </div>

      <div className="header-profile">
        <div className="subtitle">Profile</div>
        <button type="button" className="modify-btn" onClick={toggleModify}>
          {modifyShown ? t('done') : t('edit')}
        </button>
      </div>

      <Link
        to="/patient/medicalFolder/add-profile"
        className={`${modifyShown ? 'white-button disabled' : 'white-button'}`}
      >
        <CustomIcon className="custom-icon" color={Colors.darkGrey} size="24" icon="Plus" />
        {t('addProfile')}
      </Link>

      <div className="profile-list">
        {familyMembers.map((member) => {
          return (
            <div
              key={member.id}
              className={`${!modifyShown ? 'cursor-pointer' : ''} ${
                modifyShown && member.isMainProfile ? 'patient-box disabled' : 'patient-box'
              }`}
              onClick={!modifyShown ? () => onProfileSelection(member) : undefined}
            >
              <div className="details">
                <div
                  className="avatar"
                  style={
                    member?.imageUrl
                      ? { backgroundImage: `url(${member?.imageUrl})` }
                      : { backgroundColor: '#E5E7E8' }
                  }
                >
                  {!member?.imageUrl && (
                    <div className="image-placeholder">{`${member?.firstName?.charAt(
                      0,
                    )}${member?.lastName?.charAt(0)}`}</div>
                  )}
                </div>
                <div className="info">
                  <div className="name">{member.fullNameWithTitle}</div>
                  <div className="age">
                    {member.age === 0
                      ? t('medicalProfile.under_a_year')
                      : member.age && `${member.age} ${t('years')} - ${t(member.type)}`}
                  </div>
                </div>
              </div>
              {modifyShown && !member.isMainProfile && (
                <button type="button" onClick={() => removeProfile(member.id)}>
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.mediumGrey}
                    size="24"
                    icon="Delete"
                  />
                </button>
              )}
            </div>
          );
        })}
      </div>

      <div className={`${modifyShown ? 'info-message gray disabled' : 'info-message gray'}`}>
        <CustomIcon className="custom-icon" color={Colors.mediumGrey} size="24" icon="Warning_2" />
        {t('profileScreenFooterWarn')}
      </div>
    </section>
  );
};

export default PatientMedicalFolderSelectProfile;
