import 'patient/styles/account-delete-account.css';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import Alerts from 'shared/components/Alerts';
import { useTranslation } from 'react-i18next';
import { deleteAccountRequest } from 'shared/redux/actions/AuthActions';

const PatientAccountDeleteAccountBlock = ({ isCreatedWithSocialLogin }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [requestedInput, setRequestedInput] = useState('');
  const handleRequestedInput = (e) => setRequestedInput(e.target.value);
  const [requestedInputShown, setRequestedInputShown] = useState(false);
  const togglePasswordVisibility = () => setRequestedInputShown(!requestedInputShown);
  const onPress = () => {
    if (!requestedInput) {
      Alerts.simpleAlert(`${t('error')}`, `${t('account.deleteAccountValidation')}`);
      return;
    }

    let payloadToSend;
    if (isCreatedWithSocialLogin) {
      payloadToSend = { email: requestedInput };
    } else {
      payloadToSend = { password: requestedInput };
    }

    Alerts.okCancelAlert(`${t('warning')}`, `${t('validations.deleteAccount')}`, () => {
      dispatch(deleteAccountRequest(payloadToSend));
    });
  };

  useEffect(() => {
    if (isCreatedWithSocialLogin) {
      setRequestedInputShown(true);
    }
  }, [isCreatedWithSocialLogin]);

  const fieldType = () => {
    if (!isCreatedWithSocialLogin) {
      return 'password';
    }

    if (requestedInputShown) {
      return 'text';
    }

    return 'password';
  };

  return (
    <div className="account-delete-account">
      <div className="title-section">
        <h2>{t('settings.deleteAccount')}</h2>
      </div>
      <div className="info-message">
        <CustomIcon className="custom-icon" color={Colors.mediumGrey} size="24" icon="Warning_2" />
        <div>
          {t('account.deleteAccountSubtitle')}
          <br />
          <br />
          {!isCreatedWithSocialLogin
            ? t('account.deleteAccountConfirm')
            : t('account.deleteAccountConfirm2')}
        </div>
      </div>
      <div className="password-eye">
        <input
          name="requestedInput"
          id="requestedInput"
          type={fieldType()}
          className="form-control"
          placeholder=" "
          value={requestedInput}
          onChange={handleRequestedInput}
          autoComplete="off"
        />
        <label htmlFor="requestedInput">
          {!isCreatedWithSocialLogin ? t('password') : t('email')}
        </label>
        {!isCreatedWithSocialLogin && (
          <div className="eye" onClick={togglePasswordVisibility}>
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="24"
              icon={`${requestedInputShown ? 'Eye_off' : 'Eye'}`}
            />
          </div>
        )}
      </div>
      <button type="button" className="orange-button" onClick={onPress}>
        {t('account.deleteAccountBtn')}
      </button>
    </div>
  );
};

export default PatientAccountDeleteAccountBlock;
