import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { getEnv } from 'shared/services/EnvService';
import { getAuthState, getUserProfileState } from 'shared/redux/src/StatesGetter';
import StorageService from 'shared/services/StorageService';
import { updateLocaleRequest } from 'shared/redux/actions/UngroupedActions';
import { updateNotificationsSettingsRequest } from 'shared/redux/actions/NotificationActions';
import { fetchUserProfileRequest } from 'shared/redux/actions/UserProfileActions';
import { useTranslation } from 'react-i18next';
import Alerts from 'shared/components/Alerts';
import i18next from 'i18next';
import dayjs from 'dayjs';

const DoctorAccountSettingsBlock = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [selectedLocale, setSelectedLocale] = useState(getEnv('LOCALE'));
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const profileData = useSelector(getUserProfileState);
  const { marketingNotificationSetting } = profileData;
  const [receiveMarketing, setReceiveMarketing] = useState(marketingNotificationSetting);
  useEffect(() => {
    StorageService.getData('APP_LANGUAGE', i18next.language ?? getEnv('LOCALE')).then((loc) => {
      setSelectedLocale(loc);
    });
  });
  const locales = [
    { key: 'ro', isChecked: selectedLocale === 'ro', translationKey: 'ro_RO' },
    { key: 'en', isChecked: selectedLocale === 'en', translationKey: 'en_US' },
  ];
  const updateLocale = (item) => {
    if (item.key !== selectedLocale) {
      Alerts.okCancelAlert(
        t('settings.change_language'),
        t('settings.change_language_question'),
        () => {
          StorageService.setData('APP_LANGUAGE', item.key).then(() => {
            dispatch(updateLocaleRequest({ locale: item.key, isLoggedIn }));
            setSelectedLocale(item.key);
            i18n.changeLanguage(item.key).then(() => {});
            dayjs.locale(item.key);
          });
        },
      );
    }
  };
  useEffect(() => {
    setReceiveMarketing(marketingNotificationSetting);
  }, [marketingNotificationSetting]);
  const toggleReceiveMarketing = () => {
    setReceiveMarketing((previousState) => !previousState);
    dispatch(
      updateNotificationsSettingsRequest({
        marketingNotificationSetting: receiveMarketing ? 0 : 1,
      }),
    );
    // setReceiveMarketing((previousState) => !previousState);
    dispatch(fetchUserProfileRequest({}));
  };
  return (
    <div className="account-settings">
      <div className="title-section">
        <h2>{t('settings.settings')}</h2>
      </div>
      <div className="language-app">
        <div className="subtitle">{t('settings.languageApp')}</div>
        {locales.map((item) => (
          <div className="box" key={item.key}>
            <div className="custom-control custom-radio green-radio align-center-radio active-label-green">
              <input
                className="custom-control-input"
                onChange={() => updateLocale(item)}
                id={`checkbox_${item.key}`}
                name={`checkbox_${item.key}`}
                type="radio"
                checked={selectedLocale === item.key}
              />
              <label htmlFor={`checkbox_${item.key}`} className="custom-control-label">
                <div>
                  <div className="title">{t(`locale.${item.translationKey}`)}</div>
                </div>
              </label>
            </div>
          </div>
        ))}
      </div>
      <div className="terms-communications">
        <div className="subtitle">{t('terms&comm')}</div>
        <Form>
          <div className="box">
            <Form.Check
              disabled
              checked
              type="switch"
              className="custom-switch green-switch"
              id="custom-switch1"
              label={`${t('iAgreeWith')} ${t('termsAndConditions')}`}
            />
          </div>
          <div className="box">
            <Form.Check
              disabled
              checked
              type="switch"
              className="custom-switch green-switch"
              id="custom-switch2"
              label={`${t('iAgreeWith')} ${t('stripeTermsAndConditions')}`}
            />
          </div>
          <div className="box">
            <Form.Check
              disabled
              checked
              type="switch"
              className="custom-switch green-switch"
              id="custom-switch3"
              label={`${t('iUnderstandAnd')} ${t('policy')}`}
            />
          </div>
          <div className="box">
            <Form.Check
              disabled
              checked
              type="switch"
              className="custom-switch green-switch"
              id="custom-switch4"
              label={t('olderAndRealData')}
            />
          </div>
          <div className="box">
            <Form.Check
              checked={receiveMarketing}
              onChange={toggleReceiveMarketing}
              type="switch"
              className="custom-switch green-switch"
              id="custom-switch5"
              label={t('terms&commMessage')}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default DoctorAccountSettingsBlock;
