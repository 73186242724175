import 'doctor/styles/appointments.css';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import { Nav } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  fetchDoctorPastAppointmentsRequest,
  fetchDoctorUpcomingAppointmentsRequest,
} from 'shared/redux/actions/DoctorAppointmentsActions';
import DoctorAppointmentsTabFuture from 'doctor/components/appointments/DoctorAppointmentsTabFuture';
import DoctorAppointmentsTabHistory from 'doctor/components/appointments/DoctorAppointmentsTabHistory';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getEnv } from '../../../shared/services/EnvService';
import StorageService from '../../../shared/services/StorageService';

const DoctorAppointments = () => {
  const { t } = useTranslation();
  const { tab = 'upcoming' } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(tab);

  useEffect(() => {
    if (activeTab === 'upcoming') {
      dispatch(fetchDoctorUpcomingAppointmentsRequest({}));
      return;
    }
    dispatch(fetchDoctorPastAppointmentsRequest({}));
  }, [activeTab]);

  const changeTab = (k) => {
    setActiveTab(k);
    navigate(`/doctor/appointments/${k}`);
  };

  return (
    <section className="appointments doctor">
      <div className="title-section">
        <h1>{t('mainScreenRoutes.appointments')}</h1>
      </div>
      <Tab.Container onSelect={changeTab} id="appointments-tabs-menu" defaultActiveKey={tab}>
        <div className="small-container">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="upcoming">{t('appointments.futureAppointments')}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="past">{t('appointments.pastAppointments')}</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <Tab.Content>
          <Tab.Pane eventKey="upcoming">
            <DoctorAppointmentsTabFuture />
          </Tab.Pane>
          <Tab.Pane eventKey="past">
            <DoctorAppointmentsTabHistory />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </section>
  );
};

DoctorAppointments.defaultProps = {
  match: {},
  location: {},
};
DoctorAppointments.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({}),
  }),
};

export default DoctorAppointments;
