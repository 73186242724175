import { actionChannel, ActionPattern, call, fork, put, take } from 'redux-saga/effects';
import { Action } from 'redux';
import { TWILIO_TOKEN_REQUEST } from 'shared/redux/types/TwilioTypes';
import { getTwilioTokenAPI } from 'shared/redux/api/TwilioApi';
import { getTwilioTokenError, getTwilioTokenSuccess } from 'shared/redux/actions/TwilioActions';
import LocalEventsService from 'shared/services/LocalEventsService';

function* twilioToken(actionType: ActionPattern<Action<any>>) {
  const twilioTokenChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(twilioTokenChannel);
    try {
      const response = yield call(getTwilioTokenAPI, payload.appointmentId);
      yield put(getTwilioTokenSuccess(response?.data));
      LocalEventsService.emit('joinTwilioCall');
    } catch ({ message }) {
      LocalEventsService.emit('twilioError');
      yield put(getTwilioTokenError({ message }));
    }
  }
}

function* twilioSaga() {
  yield fork(twilioToken, TWILIO_TOKEN_REQUEST);
}

export default twilioSaga;
