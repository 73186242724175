import { AnyAction } from 'redux';
import {
  FETCH_DOCTOR_TIMETABLE_ERROR,
  FETCH_DOCTOR_TIMETABLE_REQUEST,
  FETCH_DOCTOR_TIMETABLE_SUCCESS,
  PATCH_DOCTOR_TIMETABLE_ERROR,
  PATCH_DOCTOR_TIMETABLE_REQUEST,
  PATCH_DOCTOR_TIMETABLE_SUCCESS,
  ADD_DOCTOR_TIMETABLE_ERROR,
  ADD_DOCTOR_TIMETABLE_REQUEST,
  ADD_DOCTOR_TIMETABLE_SUCCESS,
  REMOVE_DOCTOR_TIMETABLE_ERROR,
  REMOVE_DOCTOR_TIMETABLE_REQUEST,
  REMOVE_DOCTOR_TIMETABLE_SUCCESS,
  GET_DOCTOR_PAST_TIMETABLE_REQUEST,
  GET_DOCTOR_PAST_TIMETABLE_SUCCESS,
  GET_DOCTOR_PAST_TIMETABLE_ERROR,
  ADD_BULK_DOCTOR_TIMETABLE_REQUEST,
  ADD_BULK_DOCTOR_TIMETABLE_SUCCESS,
  ADD_BULK_DOCTOR_TIMETABLE_ERROR,
  LOCALLY_ADD_DATE,
  FETCH_MERGED_DOCTOR_TIMETABLE_ERROR,
  FETCH_MERGED_DOCTOR_TIMETABLE_REQUEST,
  FETCH_MERGED_DOCTOR_TIMETABLE_SUCCESS,
  ADD_DOCTOR_EXCEPTION_TIMETABLE_ERROR,
  ADD_DOCTOR_EXCEPTION_TIMETABLE_REQUEST,
  ADD_DOCTOR_EXCEPTION_TIMETABLE_SUCCESS,
  ADD_DOCTOR_RECURRENT_TIMETABLE_ERROR,
  ADD_DOCTOR_RECURRENT_TIMETABLE_REQUEST,
  ADD_DOCTOR_RECURRENT_TIMETABLE_SUCCESS,
  REMOVE_DOCTOR_EXCEPTION_TIMETABLE_ERROR,
  REMOVE_DOCTOR_EXCEPTION_TIMETABLE_REQUEST,
  REMOVE_DOCTOR_EXCEPTION_TIMETABLE_SUCCESS,
  REMOVE_DOCTOR_RECURRENT_TIMETABLE_ERROR,
  REMOVE_DOCTOR_RECURRENT_TIMETABLE_REQUEST,
  REMOVE_DOCTOR_RECURRENT_TIMETABLE_SUCCESS,
  PATCH_DOCTOR_EXCEPTION_TIMETABLE_ERROR,
  PATCH_DOCTOR_EXCEPTION_TIMETABLE_REQUEST,
  PATCH_DOCTOR_EXCEPTION_TIMETABLE_SUCCESS,
  PATCH_DOCTOR_RECURRENT_TIMETABLE_ERROR,
  PATCH_DOCTOR_RECURRENT_TIMETABLE_REQUEST,
  PATCH_DOCTOR_RECURRENT_TIMETABLE_SUCCESS,
} from 'shared/redux/types/DoctorTimetableTypes';

export const fetchDoctorTimetableRequest = (payload): AnyAction => ({
  type: FETCH_DOCTOR_TIMETABLE_REQUEST,
  payload,
});

export const fetchDoctorTimetableSuccess = (payload): AnyAction => ({
  type: FETCH_DOCTOR_TIMETABLE_SUCCESS,
  payload,
});

export const fetchDoctorTimetableError = (payload): AnyAction => ({
  type: FETCH_DOCTOR_TIMETABLE_ERROR,
  payload,
});

export const patchDoctorTimetableRequest = (payload): AnyAction => ({
  type: PATCH_DOCTOR_TIMETABLE_REQUEST,
  payload,
});

export const patchDoctorTimetableSuccess = (payload): AnyAction => ({
  type: PATCH_DOCTOR_TIMETABLE_SUCCESS,
  payload,
});

export const patchDoctorTimetableError = (payload): AnyAction => ({
  type: PATCH_DOCTOR_TIMETABLE_ERROR,
  payload,
});

export const addDoctorTimetableRequest = (payload): AnyAction => ({
  type: ADD_DOCTOR_TIMETABLE_REQUEST,
  payload,
});

export const addDoctorTimetableSuccess = (payload): AnyAction => ({
  type: ADD_DOCTOR_TIMETABLE_SUCCESS,
  payload,
});

export const addDoctorTimetableError = (payload): AnyAction => ({
  type: ADD_DOCTOR_TIMETABLE_ERROR,
  payload,
});

export const removeDoctorTimetableRequest = (payload): AnyAction => ({
  type: REMOVE_DOCTOR_TIMETABLE_REQUEST,
  payload,
});

export const removeDoctorTimetableSuccess = (payload): AnyAction => ({
  type: REMOVE_DOCTOR_TIMETABLE_SUCCESS,
  payload,
});

export const removeDoctorTimetableError = (payload): AnyAction => ({
  type: REMOVE_DOCTOR_TIMETABLE_ERROR,
  payload,
});

export const getDoctorPastTimetableRequest = (payload): AnyAction => ({
  type: GET_DOCTOR_PAST_TIMETABLE_REQUEST,
  payload,
});

export const getDoctorPastTimetableSuccess = (payload): AnyAction => ({
  type: GET_DOCTOR_PAST_TIMETABLE_SUCCESS,
  payload,
});

export const getDoctorPastTimetableError = (payload): AnyAction => ({
  type: GET_DOCTOR_PAST_TIMETABLE_ERROR,
  payload,
});

export const addBulkDoctorTimetableRequest = (payload): AnyAction => ({
  type: ADD_BULK_DOCTOR_TIMETABLE_REQUEST,
  payload,
});

export const addBulkDoctorTimetableSuccess = (payload): AnyAction => ({
  type: ADD_BULK_DOCTOR_TIMETABLE_SUCCESS,
  payload,
});

export const addBulkDoctorTimetableError = (payload): AnyAction => ({
  type: ADD_BULK_DOCTOR_TIMETABLE_ERROR,
  payload,
});

export const locallyAddDate = (payload): AnyAction => ({
  type: LOCALLY_ADD_DATE,
  payload,
});

export const fetchMergedDoctorTimetableRequest = (payload): AnyAction => ({
  type: FETCH_MERGED_DOCTOR_TIMETABLE_REQUEST,
  payload,
});

export const fetchMergedDoctorTimetableSuccess = (payload): AnyAction => ({
  type: FETCH_MERGED_DOCTOR_TIMETABLE_SUCCESS,
  payload,
});

export const fetchMergedDoctorTimetableError = (payload): AnyAction => ({
  type: FETCH_MERGED_DOCTOR_TIMETABLE_ERROR,
  payload,
});

export const addDoctorExceptionTimetableRequest = (payload): AnyAction => ({
  type: ADD_DOCTOR_EXCEPTION_TIMETABLE_REQUEST,
  payload,
});

export const addDoctorExceptionTimetableSuccess = (payload): AnyAction => ({
  type: ADD_DOCTOR_EXCEPTION_TIMETABLE_SUCCESS,
  payload,
});

export const addDoctorExceptionTimetableError = (payload): AnyAction => ({
  type: ADD_DOCTOR_EXCEPTION_TIMETABLE_ERROR,
  payload,
});

export const removeDoctorExceptionTimetableRequest = (payload): AnyAction => ({
  type: REMOVE_DOCTOR_EXCEPTION_TIMETABLE_REQUEST,
  payload,
});

export const removeDoctorExceptionTimetableSuccess = (payload): AnyAction => ({
  type: REMOVE_DOCTOR_EXCEPTION_TIMETABLE_SUCCESS,
  payload,
});

export const removeDoctorExceptionTimetableError = (payload): AnyAction => ({
  type: REMOVE_DOCTOR_EXCEPTION_TIMETABLE_ERROR,
  payload,
});

export const addDoctorRecurrentTimetableRequest = (payload): AnyAction => ({
  type: ADD_DOCTOR_RECURRENT_TIMETABLE_REQUEST,
  payload,
});

export const addDoctorRecurrentTimetableSuccess = (payload): AnyAction => ({
  type: ADD_DOCTOR_RECURRENT_TIMETABLE_SUCCESS,
  payload,
});

export const addDoctorRecurrentTimetableError = (payload): AnyAction => ({
  type: ADD_DOCTOR_RECURRENT_TIMETABLE_ERROR,
  payload,
});

export const removeDoctorRecurrentTimetableRequest = (payload): AnyAction => ({
  type: REMOVE_DOCTOR_RECURRENT_TIMETABLE_REQUEST,
  payload,
});

export const removeDoctorRecurrentTimetableSuccess = (payload): AnyAction => ({
  type: REMOVE_DOCTOR_RECURRENT_TIMETABLE_SUCCESS,
  payload,
});

export const removeDoctorRecurrentTimetableError = (payload): AnyAction => ({
  type: REMOVE_DOCTOR_RECURRENT_TIMETABLE_ERROR,
  payload,
});

export const patchDoctorExceptionTimetableRequest = (payload): AnyAction => ({
  type: PATCH_DOCTOR_EXCEPTION_TIMETABLE_REQUEST,
  payload,
});

export const patchDoctorExceptionTimetableSuccess = (payload): AnyAction => ({
  type: PATCH_DOCTOR_EXCEPTION_TIMETABLE_SUCCESS,
  payload,
});

export const patchDoctorExceptionTimetableError = (payload): AnyAction => ({
  type: PATCH_DOCTOR_EXCEPTION_TIMETABLE_ERROR,
  payload,
});

export const patchDoctorRecurrentTimetableRequest = (payload): AnyAction => ({
  type: PATCH_DOCTOR_RECURRENT_TIMETABLE_REQUEST,
  payload,
});

export const patchDoctorRecurrentTimetableSuccess = (payload): AnyAction => ({
  type: PATCH_DOCTOR_RECURRENT_TIMETABLE_SUCCESS,
  payload,
});

export const patchDoctorRecurrentTimetableError = (payload): AnyAction => ({
  type: PATCH_DOCTOR_RECURRENT_TIMETABLE_ERROR,
  payload,
});
