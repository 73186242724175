import '../../styles/appointment-steps.css';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PatientAppointmentConfirmed = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <section className="appointment-steps">
      <div className="confirmed-box">
        <CustomIcon
          className="custom-icon"
          color={Colors.confirmedColor}
          size="130"
          icon="Big_check"
        />
        <div className="title">{t('appointmentConfirmed')}</div>
      </div>
      <button
        type="button"
        className="white-button"
        onClick={() => navigate('/patient/consultations', { state: 'upcoming' })}
      >
        {t('backToAppointmentsScreen')}
      </button>
    </section>
  );
};

export default PatientAppointmentConfirmed;
