import { actionChannel, call, fork, put, take, select } from 'redux-saga/effects';
import {
  addPatientMedicalRecordAPI,
  deletePatientMedicalRecordAPI,
  getPatientMedicalRecordsAPI,
  getPatientProfileAPI,
  updatePatientProfileAPI,
  updatePatientMedicalRecordAPI,
} from 'shared/redux/api/PatientProfileApi';
import {
  fetchPatientProfileSuccess,
  fetchPatientProfileError,
  patchPatientProfileSuccess,
  patchPatientProfileError,
  patientAddMedicalRecordError,
  patientAddMedicalRecordSuccess,
  getPatientMedicalRecordsError,
  getPatientMedicalRecordsSuccess,
  patientDeleteMedicalRecordError,
  patientDeleteMedicalRecordSuccess,
  getPatientMedicalRecordsRequest,
  patientUpdateMedicalRecordError,
  patientUpdateMedicalRecordSuccess,
} from 'shared/redux/actions/PatientProfileActions';
import {
  FETCH_PATIENT_PROFILE_REQUEST,
  GET_PATIENT_MEDICAL_RECORDS_REQUEST,
  GET_PATIENT_VOUCHERS_REQUEST,
  PATCH_PATIENT_PROFILE_REQUEST,
  PATIENT_ADD_MEDICAL_RECORD_REQUEST,
  PATIENT_DELETE_MEDICAL_RECORD_REQUEST,
  PATIENT_UPDATE_MEDICAL_RECORD_REQUEST,
} from 'shared/redux/types/PatientProfileTypes';
import GCStorageService from 'shared/services/GCStorageService';
import Alerts from 'shared/components/Alerts';
import ApiUtils from 'shared/modules/ApiUtils';
import { updateSharedStateAction } from 'shared/redux/actions/UngroupedActions';
import {
  medicalRecordsSelector,
  patientProfileSelector,
} from 'shared/redux/selector/PatientProfileSelector';
import { getAuthState } from 'shared/redux/src/StatesGetter';
import Utils from 'shared/modules/Utils';
import { handleExtraLoginResponse } from 'shared/modules/AuthUtils';
import i18next from 'i18next';
import StorageService from '../../services/StorageService';
import NavigationService from '../../services/NavigationService';

function* getPatientProfile(actionType) {
  const patientProfileChannel = yield actionChannel(actionType);
  while (true) {
    yield take(patientProfileChannel);
    sessionStorage.setItem('userType', 'patient');
    try {
      const response = yield call(getPatientProfileAPI);
      yield put(fetchPatientProfileSuccess(patientProfileSelector(response.data)));
    } catch ({ message }) {
      yield put(fetchPatientProfileError({ message }));
    }
  }
}

function* updatePatientProfile(actionType) {
  const updateUserProfileChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(updateUserProfileChannel);
    try {
      const response = yield call(updatePatientProfileAPI, {
        id: payload.id,
        data: payload.toUpdate,
      });
      if (
        payload.toUpdate.picture &&
        response?.data?.picture_media &&
        response.data.picture_media?.gcloud_upload_policy_v4?.url &&
        payload.toUpload
      ) {
        yield call(
          GCStorageService.uploadToGCWithSignedUrl,
          response.data.picture_media.gcloud_upload_policy_v4.url,
          response.data.picture_media.gcloud_upload_policy_v4.fields,
          payload.toUpload,
        );
      }
      yield put(patchPatientProfileSuccess(patientProfileSelector(response.data)));
      const { isProfileCompleted } = yield select(getAuthState);
      if (isProfileCompleted === false) {
        yield call(ApiUtils.doRefreshToken);
      }
      const isProfiled = response.data?.is_patient;
      yield call(handleExtraLoginResponse, isProfileCompleted ? 'true' : 'Not Completed', 'true');
      yield put(
        updateSharedStateAction({
          isLoggedIn: true,
          isConfirmed: true,
          isProfileCompleted: isProfiled,
          hasFullAccess: isProfiled,
          lastUpdate: isProfiled ? 'justNow' : '',
        }),
      );
      Alerts.simpleAlert(`${i18next.t('success')}`, `${i18next.t('alerts.profileChanged')}`);
      if (isProfileCompleted === false) {
        if (payload.toUpdate.invitationCode === '') {
          NavigationService.navigate('/patient/mlm');
        }
      }
    } catch ({ message }) {
      yield put(patchPatientProfileError({ message }));
    }
  }
}

function* patientAddMedicalRecord(actionType) {
  const addMedicalRecordChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(addMedicalRecordChannel);
    try {
      const response = yield call(addPatientMedicalRecordAPI, payload.userId, payload.data);
      yield call(Utils.sendToCloud, payload.files, response.data.media);
      yield put(patientAddMedicalRecordSuccess(response.data));
      yield put(getPatientMedicalRecordsRequest(payload.userId));
    } catch ({ message }) {
      yield put(patientAddMedicalRecordError({ message }));
    }
  }
}

function* patientUpdateMedicalRecord(actionType) {
  const updateMedicalRecordChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(updateMedicalRecordChannel);
    try {
      const response = yield call(
        updatePatientMedicalRecordAPI,
        payload.userId,
        payload.mediaId,
        payload.data,
      );
      yield put(patientUpdateMedicalRecordSuccess(response.data));
      yield put(getPatientMedicalRecordsRequest(payload.userId));
      // yield put(fetchMedicalRecordCategoryRequest({}));
      // NavigationService.goBack();
    } catch ({ message }) {
      yield put(patientUpdateMedicalRecordError({ message }));
    }
  }
}

function* patientDeleteMedicalRecord(actionType) {
  const deleteMedicalRecordChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(deleteMedicalRecordChannel);
    try {
      yield call(deletePatientMedicalRecordAPI, payload.userId, payload.mediaId);
      yield put(patientDeleteMedicalRecordSuccess(payload));
    } catch ({ message }) {
      yield put(patientDeleteMedicalRecordError({ message }));
    }
  }
}

function* getPatientMedicalRecords(actionType) {
  const getMedicalRecordsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(getMedicalRecordsChannel);
    try {
      const response = yield call(getPatientMedicalRecordsAPI, payload);
      yield put(getPatientMedicalRecordsSuccess(medicalRecordsSelector(response.data)));
    } catch ({ message }) {
      yield put(getPatientMedicalRecordsError({ message }));
    }
  }
}

function* patientProfileSaga() {
  yield fork(getPatientProfile, FETCH_PATIENT_PROFILE_REQUEST);
  yield fork(updatePatientProfile, PATCH_PATIENT_PROFILE_REQUEST);
  yield fork(patientAddMedicalRecord, PATIENT_ADD_MEDICAL_RECORD_REQUEST);
  yield fork(patientUpdateMedicalRecord, PATIENT_UPDATE_MEDICAL_RECORD_REQUEST);
  yield fork(patientDeleteMedicalRecord, PATIENT_DELETE_MEDICAL_RECORD_REQUEST);
  yield fork(getPatientMedicalRecords, GET_PATIENT_MEDICAL_RECORDS_REQUEST);
}

export default patientProfileSaga;
