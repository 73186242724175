import { actionChannel, call, fork, put, take } from 'redux-saga/effects';
import i18next from 'i18next';
import {
  ADD_APPOINTMENT_CONCLUSION_REQUEST,
  ADD_APPOINTMENT_PRESCRIPTION_MEDICATION_REQUEST,
  ADD_APPOINTMENT_PRESCRIPTION_REQUEST,
  CANCEL_ALL_APPOINTMENTS_BY_DOCTOR_REQUEST,
  CANCEL_APPOINTMENT_BY_DOCTOR_REQUEST,
  DOCTOR_ACCEPTS_CALL_REQUEST,
  DOCTOR_REJECTS_CALL_REQUEST,
  FETCH_DOCTOR_PAST_APPOINTMENTS_REQUEST,
  FETCH_DOCTOR_TODAY_APPOINTMENTS_REQUEST,
  FETCH_DOCTOR_UPCOMING_APPOINTMENTS_REQUEST,
  GET_APPOINTMENT_DETAILS_REQUEST,
  GET_CALL_SPECIALIST_INFO_REQUEST,
  REMOVE_APPOINTMENT_PRESCRIPTION_MEDICATION_REQUEST,
  REMOVE_APPOINTMENT_PRESCRIPTION_REQUEST,
  UPDATE_APPOINTMENT_PRESCRIPTION_MEDICATION_REQUEST,
} from 'shared/redux/types/DoctorAppointmentsTypes';
import {
  addAppointmentConclusionAPI,
  addAppointmentPrescriptionAPI,
  addAppointmentPrescriptionMedicationAPI,
  cancelAllAppointmentsByDoctorAPI,
  cancelAppointmentByDoctorAPI,
  doctorAcceptsCallAPI,
  doctorRejectsCallAPI,
  getAppointmentDetailsAPI,
  getCallSpecialistInfoAPI,
  getDoctorPastAppointmentsAPI,
  getDoctorUpcomingAppointmentsAPI,
  removeAppointmentPrescriptionAPI,
  removeAppointmentPrescriptionMedicationAPI,
  updateAppointmentPrescriptionMedicationAPI,
} from 'shared/redux/api/DoctorAppointmentsApi';
import {
  acceptedDoctorCallsStackRequest,
  addAppointmentConclusionError,
  addAppointmentConclusionSuccess,
  addAppointmentPrescriptionError,
  addAppointmentPrescriptionMedicationError,
  addAppointmentPrescriptionMedicationSuccess,
  addAppointmentPrescriptionSuccess,
  cancelAllAppointmentsByDoctorError,
  cancelAllAppointmentsByDoctorSuccess,
  cancelAppointmentByDoctorError,
  cancelAppointmentByDoctorSuccess,
  doctorAcceptsCallError,
  doctorAcceptsCallSuccess,
  doctorAppointmentUpdatesRequest,
  doctorRejectsCallError,
  doctorRejectsCallSuccess,
  fetchDoctorPastAppointmentsError,
  fetchDoctorPastAppointmentsSuccess,
  fetchDoctorTodayAppointmentsError,
  fetchDoctorTodayAppointmentsRequest,
  fetchDoctorTodayAppointmentsSuccess,
  fetchDoctorUpcomingAppointmentsError,
  fetchDoctorUpcomingAppointmentsRequest,
  fetchDoctorUpcomingAppointmentsSuccess,
  getAppointmentDetailsError,
  getAppointmentDetailsRequest,
  getAppointmentDetailsSuccess,
  getCallSpecialistInfoError,
  getCallSpecialistInfoSuccess,
  updateAppointmentPrescriptionMedicationError,
  updateAppointmentPrescriptionMedicationSuccess,
} from 'shared/redux/actions/DoctorAppointmentsActions';
import {
  doctorAppointmentDetailsSelector,
  doctorAppointmentsSelector,
  doctorLocalAppointmentSelector,
  doctorTodayAppointmentsSelector,
} from 'shared/redux/selector/DoctorAppointmentsSelector';
import Alerts from 'shared/components/Alerts';
import { updateSharedStateAction } from 'shared/redux/actions/UngroupedActions';
import { fetchUserProfileRequest } from 'shared/redux/actions/UserProfileActions';
import NavigationService from 'shared/services/NavigationService';
import { videoCallGetTokenRequest } from 'shared/redux/actions/VideoCallActions';
import Utils from 'shared/modules/Utils';
import { serverTimeAPI } from 'shared/redux/api/UngroupedApi';
import dayjs from 'dayjs';
import StorageService from '../../services/StorageService';

function* getDoctorPastAppointments(actionType) {
  const doctorPastAppointmentsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(doctorPastAppointmentsChannel);
    try {
      const safeAction = {
        limit: 10,
        page: 1,
        ...payload,
      };
      const response = yield call(getDoctorPastAppointmentsAPI, safeAction);
      yield put(
        fetchDoctorPastAppointmentsSuccess({
          ...doctorAppointmentsSelector(response.data, false),
          nextPage: payload.nextPage,
        }),
      );
    } catch ({ message }) {
      yield put(fetchDoctorPastAppointmentsError({ message }));
    }
  }
}

function* getDoctorUpcomingAppointments(actionType) {
  const doctorUpcomingAppointmentsChannel = yield actionChannel(actionType);
  let isPharmacist = 'false';
  StorageService.getData('IS_PHARMACIST', 'false').then((response) => {
    isPharmacist = response;
  });
  while (true) {
    const { payload } = yield take(doctorUpcomingAppointmentsChannel);
    try {
      const safeAction = {
        limit: 10,
        page: 1,
        ...payload,
      };
      let response = { data: [] };
      if (isPharmacist === 'false') {
        response = yield call(getDoctorUpcomingAppointmentsAPI, safeAction);
      }
      yield put(
        fetchDoctorUpcomingAppointmentsSuccess({
          ...doctorAppointmentsSelector(response.data, true),
          nextPage: payload.nextPage,
        }),
      );
    } catch ({ message }) {
      yield put(fetchDoctorUpcomingAppointmentsError({ message }));
    }
  }
}

function* getDoctorTodayAppointments(actionType) {
  const doctorTodayAppointmentsChannel = yield actionChannel(actionType);
  let isPharmacist = 'false';
  StorageService.getData('IS_PHARMACIST', 'false').then((response) => {
    isPharmacist = response;
  });
  while (true) {
    const { payload } = yield take(doctorTodayAppointmentsChannel);

    try {
      const safeAction = {
        limit: 5,
        page: 1,
        date: dayjs().format('YYYY-MM-DD'),
        ...payload,
      };
      let response = { data: [] };
      if (isPharmacist === 'false') {
        response = yield call(getDoctorUpcomingAppointmentsAPI, safeAction);
      }
      const formattedAppointments = doctorAppointmentsSelector(response.data, true);
      yield put(
        fetchDoctorTodayAppointmentsSuccess(
          doctorTodayAppointmentsSelector(formattedAppointments.data),
        ),
      );
    } catch ({ message }) {
      yield put(fetchDoctorTodayAppointmentsError({ message }));
    }
  }
}

function* getAppointmentDetails(actionType) {
  const appointmentDetailsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(appointmentDetailsChannel);
    try {
      const response = yield call(getAppointmentDetailsAPI, payload);
      if (payload.updateLocal) {
        yield put(doctorAppointmentUpdatesRequest(doctorLocalAppointmentSelector(response.data)));
      }
      if (payload.alsoTwilio) {
        yield put(videoCallGetTokenRequest({ appointmentId: response?.data?.id }));
      }
      yield put(getAppointmentDetailsSuccess(doctorAppointmentDetailsSelector(response.data)));
      if (payload.withNavigate) {
        NavigationService.navigate(`/doctor/consultation-details/${payload.id}`, {
          state: {
            templateType: payload.navigationTemplate,
          },
        });
      }
    } catch ({ message }) {
      yield put(getAppointmentDetailsError({ message }));
    }
  }
}

function* cancelAppointmentByDoctor(actionType) {
  const cancelAppointmentByDoctorChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(cancelAppointmentByDoctorChannel);
    try {
      const response = yield call(cancelAppointmentByDoctorAPI, payload);
      yield put(cancelAppointmentByDoctorSuccess(response.data));
      yield put(getAppointmentDetailsRequest({ id: payload.id }));
      Alerts.actionAlert(
        `${i18next.t('success')}`,
        `${i18next.t('appointmentCanceled')}`,
        'Ok',
        () => NavigationService.navigate(`/doctor/appointments/upcoming`),
      );
    } catch ({ message }) {
      yield put(cancelAppointmentByDoctorError({ message }));
    }
  }
}

function* cancelAllAppointmentsByDoctor(actionType) {
  const cancelAllAppointmentsByDoctorChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(cancelAllAppointmentsByDoctorChannel);
    try {
      const response = yield call(cancelAllAppointmentsByDoctorAPI, payload);
      yield put(cancelAllAppointmentsByDoctorSuccess(response.data));
      yield put(fetchDoctorUpcomingAppointmentsRequest({}));
      yield put(fetchDoctorTodayAppointmentsRequest({}));
      Alerts.actionAlert(
        `${i18next.t('success')}`,
        `${response.data.cancelled_appointments}  ${i18next.t('appointmentsCanceled')}`,
        'Ok',
        () => {
          NavigationService.navigate('doctor/appointments/upcoming');
        },
      );
    } catch ({ message }) {
      yield put(cancelAllAppointmentsByDoctorError({ message }));
    }
  }
}

function* doctorAcceptsCall(actionType) {
  const doctorAcceptsCallChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(doctorAcceptsCallChannel);
    try {
      const response = yield call(doctorAcceptsCallAPI, payload.data);
      const minutes = payload.data?.body?.bidTime ?? payload.data?.body?.timeStart;
      const { data: serverTime } = yield call(serverTimeAPI, minutes);
      yield put(doctorAcceptsCallSuccess(response.data));
      yield put(
        updateSharedStateAction({
          showWaitingTime: false,
          showNextAppointment: !payload.data?.bidTime,
          nextAppointmentStart: serverTime,
        }),
      );
      if (payload.data?.bidTime) {
        yield put(acceptedDoctorCallsStackRequest(response.data.id));
      }
      yield put(fetchDoctorUpcomingAppointmentsRequest({}));
      yield put(fetchDoctorTodayAppointmentsRequest({}));
      NavigationService.navigate('/doctor/home');
    } catch ({ message }) {
      yield put(updateSharedStateAction({ showWaitingTime: false }));
      yield put(doctorAcceptsCallError({ message }));
      NavigationService.navigate('/doctor/home');
    }
  }
}

function* doctorRejectsCall(actionType) {
  const doctorRejectsCallChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(doctorRejectsCallChannel);
    try {
      const response = yield call(doctorRejectsCallAPI, payload);
      yield put(doctorRejectsCallSuccess(response.data));
      yield put(fetchUserProfileRequest({}));
      yield put(fetchDoctorUpcomingAppointmentsRequest({}));
      yield put(fetchDoctorTodayAppointmentsRequest({}));
      NavigationService.navigate('/doctor/home');
    } catch ({ message }) {
      yield put(doctorRejectsCallError({ message }));
    }
  }
}

function* addAppointmentConclusion(actionType) {
  const addAppointmentConclusionChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(addAppointmentConclusionChannel);
    try {
      const response = yield call(addAppointmentConclusionAPI, {
        add: payload.toAdd,
        id: payload?.id,
      });
      const media = response.data?.prescriptions_portfolio.media_portfolios?.map((mediaFile) => {
        return {
          original_file_name: mediaFile.media.original_file_name,
          gcloud_upload_policy_v4: mediaFile.media.gcloud_upload_policy_v4,
        };
      });
      yield call(Utils.sendToCloud, payload.files, media);
      yield put(addAppointmentConclusionSuccess(payload.toUpdate));
      yield put(getAppointmentDetailsRequest({ id: payload?.id }));
      if (payload.toAdd.isCompleted) {
        Alerts.simpleAlert(
          `${i18next.t('success')}`,
          `${i18next.t('appointments.conclusionSuccess')}`,
        );
        NavigationService.navigate('/doctor/home');
      }
    } catch ({ message }) {
      yield put(addAppointmentConclusionError({ message }));
    }
  }
}

function* addAppointmentPrescription(actionType) {
  const addPrescriptionChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(addPrescriptionChannel);
    try {
      const response = yield call(addAppointmentPrescriptionAPI, {
        add: payload.toAdd,
        id: payload?.id,
      });
      yield put(addAppointmentPrescriptionSuccess(response.data));

      localStorage.setItem('REDIRECT_MEDICATION', 'true');
      localStorage.setItem('SAVED_PRESCRIPTION', 'true');
    } catch ({ message }) {
      yield put(addAppointmentPrescriptionError({ message }));
    }
  }
}

function* removeAppointmentPrescription(actionType) {
  const removePrescriptionChannel = yield actionChannel(actionType);

  while (true) {
    const { payload } = yield take(removePrescriptionChannel);
    try {
      const response = yield call(removeAppointmentPrescriptionAPI, { id: payload?.id });
      yield put(addAppointmentPrescriptionSuccess(response));
    } catch ({ message }) {
      yield put(addAppointmentPrescriptionError({ message }));
    }
  }
}

function* addAppointmentPrescriptionMedication(actionType) {
  const addPrescriptionChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(addPrescriptionChannel);
    try {
      const response = yield call(addAppointmentPrescriptionMedicationAPI, {
        add: payload.toAdd,
        id: payload?.id,
      });
      yield put(addAppointmentPrescriptionMedicationSuccess(response.data));
      localStorage.setItem('REDIRECT_MEDICATION', 'false');
    } catch ({ message }) {
      yield put(addAppointmentPrescriptionMedicationError({ message }));
    }
  }
}

function* updateAppointmentPrescriptionMedication(actionType) {
  const addPrescriptionChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(addPrescriptionChannel);
    try {
      const response = yield call(updateAppointmentPrescriptionMedicationAPI, {
        add: payload.toAdd,
        id: payload?.id,
      });
      yield put(updateAppointmentPrescriptionMedicationSuccess(response.data));
    } catch ({ message }) {
      yield put(updateAppointmentPrescriptionMedicationError({ message }));
    }
  }
}

function* removeAppointmentPrescriptionMedication(actionType) {
  const addPrescriptionChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(addPrescriptionChannel);
    try {
      const response = yield call(removeAppointmentPrescriptionMedicationAPI, {
        id: payload?.id,
      });
      yield put(addAppointmentPrescriptionSuccess(response));
    } catch ({ message }) {
      yield put(addAppointmentPrescriptionError({ message }));
    }
  }
}

function* getCallSpecialistInfo(actionType) {
  const getCallSpecialistInfoChannel = yield actionChannel(actionType);
  let isPharmacist = 'false';
  StorageService.getData('IS_PHARMACIST', 'false').then((response) => {
    isPharmacist = response;
  });
  while (true) {
    const { payload } = yield take(getCallSpecialistInfoChannel);
    try {
      let response = { data: [] };
      if (isPharmacist === 'false') {
        response = yield call(getCallSpecialistInfoAPI);
      }
      yield put(getCallSpecialistInfoSuccess(response?.data));
    } catch ({ message }) {
      yield put(getCallSpecialistInfoError({ message }));
    }
  }
}

function* doctorAppointmentsSaga() {
  yield fork(getDoctorPastAppointments, FETCH_DOCTOR_PAST_APPOINTMENTS_REQUEST);
  yield fork(getDoctorUpcomingAppointments, FETCH_DOCTOR_UPCOMING_APPOINTMENTS_REQUEST);
  yield fork(getDoctorTodayAppointments, FETCH_DOCTOR_TODAY_APPOINTMENTS_REQUEST);
  yield fork(getAppointmentDetails, GET_APPOINTMENT_DETAILS_REQUEST);
  yield fork(cancelAppointmentByDoctor, CANCEL_APPOINTMENT_BY_DOCTOR_REQUEST);
  yield fork(cancelAllAppointmentsByDoctor, CANCEL_ALL_APPOINTMENTS_BY_DOCTOR_REQUEST);
  yield fork(doctorAcceptsCall, DOCTOR_ACCEPTS_CALL_REQUEST);
  yield fork(doctorRejectsCall, DOCTOR_REJECTS_CALL_REQUEST);
  yield fork(addAppointmentConclusion, ADD_APPOINTMENT_CONCLUSION_REQUEST);
  yield fork(addAppointmentPrescription, ADD_APPOINTMENT_PRESCRIPTION_REQUEST);
  yield fork(addAppointmentPrescriptionMedication, ADD_APPOINTMENT_PRESCRIPTION_MEDICATION_REQUEST);
  yield fork(
    updateAppointmentPrescriptionMedication,
    UPDATE_APPOINTMENT_PRESCRIPTION_MEDICATION_REQUEST,
  );
  yield fork(
    removeAppointmentPrescriptionMedication,
    REMOVE_APPOINTMENT_PRESCRIPTION_MEDICATION_REQUEST,
  );
  yield fork(removeAppointmentPrescription, REMOVE_APPOINTMENT_PRESCRIPTION_REQUEST);

  yield fork(getCallSpecialistInfo, GET_CALL_SPECIALIST_INFO_REQUEST);
}

export default doctorAppointmentsSaga;
