import { translatedFullDate } from 'shared/modules/DateTimeUtils';
import Utils from 'shared/modules/Utils';
import { useTranslation } from 'react-i18next';

const PatientTransactionHistoryTransactionBox = ({ transaction, refund }) => {
  const { t } = useTranslation();
  const cardImage = Utils.solveImageLogoByType(transaction.card.brand);

  return (
    <div className="box">
      <div className="header">
        <div className="details">
          <div className="title">{refund ? t('refund') : t('appointments.appointment')}</div>
          <div className="fullName">{transaction.doctor.name}</div>
          <div className="date">{translatedFullDate(transaction.createdAt)}</div>
        </div>

        {/* <CustomIcon */}
        {/*  className="custom-icon" */}
        {/*  color={Colors.darkGrey} */}
        {/*  size="24" */}
        {/*  icon="Right_pointer" */}
        {/* /> */}
      </div>

      <div className="footer">
        <div className="card-info">
          {cardImage ? <img src={cardImage} alt={transaction.card.brand} /> : null}
          <span>•••• {transaction.card.last4}</span>
        </div>

        <div className="price">
          {refund ? `+ ${transaction.amount}` : `- ${transaction.amount}`}
        </div>
      </div>
    </div>
  );
};

export default PatientTransactionHistoryTransactionBox;
