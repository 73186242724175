import 'patient/styles/free-call.css';
import NavigationService from 'shared/services/NavigationService';
import ProfilePicture from 'shared/assets/images/avatar-picture-doctor.jpg';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';

const PatientFreeCallMedicFound = () => {
  return (
    <section className="free-call-medic">
      <div className="info-message">
        <CustomIcon className="custom-icon" color={Colors.mediumGrey} size="24" icon="Warning_2" />
        Pentru probleme medicale grave sună la 112!
      </div>
      <div className="box">
        <div className="medic-avatar" style={{ backgroundImage: `url(${ProfilePicture})` }} />
        <h1>Med. Rez. Ioana Călinescu</h1>
      </div>
      <div className="box">
        <div className="will-start">Apelul va începe în</div>
        <div className="timer">4:35</div>
      </div>
      <div className="box">
        <ul>
          <li>
            <div className="field-title">Durată apel</div>
            <div className="field-value">10 minute</div>
          </li>
          <li>
            <div className="field-title">Total de plată</div>
            <div className="field-value">Gratuit</div>
          </li>
        </ul>
      </div>
      <button
        type="button"
        onClick={() => NavigationService.navigate('/patient')}
        className="white-button position-relative"
      >
        Anulează apelul
      </button>
    </section>
  );
};

export default PatientFreeCallMedicFound;
