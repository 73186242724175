import 'shared/styles/static-pages.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getStripeTermsState } from 'shared/redux/src/StatesGetter';
import React, { useEffect, useRef } from 'react';
import { getStripeTermsRequest } from '../../redux/actions/StaticPagesActions';

const SettingsStripeTermsConditions = () => {
  const devRef = useRef<HTMLDivElement>(null);
  const { name, content } = useSelector(getStripeTermsState, shallowEqual);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStripeTermsRequest({}));
    if (devRef.current) devRef.current.innerHTML = content;
  }, [content]);
  return (
    <section className="static-pages">
      <div className="title-section">
        {/* <Link to="/doctor/account" className="back-to-account"> */}
        {/*  <CustomIcon className="custom-icon" color={Colors.darkGrey} size="24" icon="Left_arrow" /> */}
        {/*  Înapoi la Cont */}
        {/* </Link> */}
        <h1>{name}</h1>
      </div>

      <div ref={devRef} />
    </section>
  );
};

export default SettingsStripeTermsConditions;
