export const APPOINTMENT_END_CONSULTATION_REQUEST = 'APPOINTMENT_END_CONSULTATION_REQUEST';
export const APPOINTMENT_END_CONSULTATION_SUCCESS = 'APPOINTMENT_END_CONSULTATION_SUCCESS';
export const APPOINTMENT_END_CONSULTATION_ERROR = 'APPOINTMENT_END_CONSULTATION_ERROR';

export const APPOINTMENT_REPORT_ISSUE_REQUEST = 'APPOINTMENT_REPORT_ISSUE_REQUEST';
export const APPOINTMENT_REPORT_ISSUE_SUCCESS = 'APPOINTMENT_REPORT_ISSUE_SUCCESS';
export const APPOINTMENT_REPORT_ISSUE_ERROR = 'APPOINTMENT_REPORT_ISSUE_ERROR';

export const GET_APPOINTMENT_SETTINGS_REQUEST = 'GET_APPOINTMENT_SETTINGS_REQUEST';
export const GET_APPOINTMENT_SETTINGS_SUCCESS = 'GET_APPOINTMENT_SETTINGS_SUCCESS';
export const GET_APPOINTMENT_SETTINGS_ERROR = 'GET_APPOINTMENT_SETTINGS_ERROR';

export const GET_CURRENT_APPOINTMENT_REQUEST = 'GET_CURRENT_APPOINTMENT_REQUEST';
export const GET_CURRENT_APPOINTMENT_SUCCESS = 'GET_CURRENT_APPOINTMENT_SUCCESS';
export const GET_CURRENT_APPOINTMENT_ERROR = 'GET_CURRENT_APPOINTMENT_ERROR';
