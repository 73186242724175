import 'patient/styles/rating.css';
import { ToggleButtonGroup } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getPatientAppointmentDetailsState,
  getPatientProfileState,
} from 'shared/redux/src/StatesGetter';
import {
  appointmentAddReviewRequest,
  getPatientAppointmentDetailsRequest,
} from 'shared/redux/actions/PatientAppointmentActions';
import Alerts from 'shared/components/Alerts';
import { useTranslation } from 'react-i18next';
import NavigationService from '../../../shared/services/NavigationService';

const PatientRating = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: appointmentId, doctor } = useSelector(
    getPatientAppointmentDetailsState,
    shallowEqual,
  );
  const patientProfile = useSelector(getPatientProfileState, shallowEqual);

  const [rating, setRating] = useState(0);
  const [textReview, setTextReview] = useState('');
  const [socialModalDisplayed, setSocialModalDisplayed] = useState(false);
  const showSocialModal = () => setSocialModalDisplayed(true);
  const hideModal = () => setSocialModalDisplayed(false);
  const url = `http://bit.ly/ringdocpacienti`;
  const iconsSize = 48;

  const invitationCode = patientProfile.personalCode;

  const invitationText = `${t('sharePersonalCodeTextWeb', {
    personalCode: invitationCode,
  })}`;
  const invitationTitle = `${t('recommendApp')}`;
  const emailUrl = `${t('sharePersonalCodeTextWeb', {
    personalCode: invitationCode,
  })} ${url}`;

  let ratingClass = t('giveReview');
  if (rating === 1) {
    ratingClass = t('1stars');
  } else if (rating === 2) {
    ratingClass = t('2stars');
  } else if (rating === 3) {
    ratingClass = t('3stars');
  } else if (rating === 4) {
    ratingClass = t('4stars');
  } else if (rating === 5) {
    ratingClass = t('5stars');
  }

  const reviewLater = () => {
    NavigationService.navigate('/', { state: 'history' });
    Alerts.shareAlert(
      `${t('shareRingdoc')}`,
      {
        body1: t('referralInviteAFriendDescription1'),
        body2: t('referralInviteAFriendDescription2'),
      },
      invitationCode,
    );
  };
  const submitReview = () => {
    if (!textReview.length || textReview.length < 3) {
      Alerts.simpleAlert(t('error'), t('alerts.shortReview'));
    } else {
      dispatch(
        appointmentAddReviewRequest({
          appointmentId,
          body: { rating, remarks: textReview },
          withAlert: true,
          withNavigate: true,
        }),
      );
      Alerts.shareAlert(
        `${t('shareRingdoc')}`,
        {
          body1: t('referralInviteAFriendDescription1'),
          body2: t('referralInviteAFriendDescription2'),
        },
        invitationCode,
      );
      NavigationService.navigate('/patient/mediclist');
    }
  };

  useEffect(() => {
    if (appointmentId) {
      dispatch(getPatientAppointmentDetailsRequest({ id: appointmentId }));
    } else {
      navigate(`/`);
    }
  }, []);

  return (
    <section className="rating">
      <div className="title-section">
        <h1>{t('feedback')}</h1>
      </div>
      <div className="medic-box">
        <div
          className="avatar"
          style={
            doctor?.imgUrl
              ? { backgroundImage: `url(${doctor?.imgUrl})` }
              : { backgroundColor: '#E5E7E8' }
          }
        >
          {!doctor?.imgUrl && (
            <div className="image-placeholder">{`${doctor?.firstName?.charAt(
              0,
            )}${doctor?.lastName?.charAt(0)}`}</div>
          )}
        </div>
        <div className="with">{t('evaluate')}</div>
        <div className="fullName">{doctor?.fullNameWithTitle}</div>
      </div>
      <div className="rating-box">
        <ToggleButtonGroup
          type="radio"
          name="rating"
          custom-attribute={rating}
          onChange={(e) => setRating(e)}
        >
          <ToggleButton id="rating1" value={1}>
            1
          </ToggleButton>
          <ToggleButton id="rating2" value={2}>
            2
          </ToggleButton>
          <ToggleButton id="rating3" value={3}>
            3
          </ToggleButton>
          <ToggleButton id="rating4" value={4}>
            4
          </ToggleButton>
          <ToggleButton id="rating5" value={5}>
            5
          </ToggleButton>
        </ToggleButtonGroup>
        <div className="rating-class">{ratingClass}</div>
      </div>
      <div className="form-textarea">
        <textarea
          id="write-review"
          name="write-review"
          className="form-control"
          placeholder=" "
          rows={4}
          onChange={(e) => setTextReview(e.target.value)}
          value={textReview}
        />
        <label htmlFor="write-review">{t('writeReview')}</label>
      </div>
      <div className="actions">
        <button type="submit" className="white-button" onClick={reviewLater}>
          {t('alerts.later')}
        </button>
        <button type="submit" className="orange-button" onClick={submitReview}>
          {t('sendFeedback')}
        </button>
      </div>
    </section>
  );
};

export default PatientRating;
