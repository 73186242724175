const stripeElementOptions = {
  classes: {
    base: 'StripeElement form-control',
  },
  style: {
    base: {
      fontFamily: 'Rota',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '18px',
      // lineHeight: '22px',
      // letterSpacing: '-0.5px',
      color: '#001315',
      ':focus': {
        backgroundColor: '#FFFFFF',
      },
      '::placeholder': {
        color: '#FFFFFF',
      },
    },
    invalid: {
      color: '#FFFFFF',
      fontWeight: 'bold',
      ':focus': {
        backgroundColor: '#E25950',
      },
    },
  },
};

export default stripeElementOptions;
