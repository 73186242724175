import 'doctor/styles/wallet.css';
import PropTypes from 'prop-types';
import DoctorWalletTabIncome from 'doctor/components/wallet/DoctorWalletTabIncome';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getIncomeState } from '../../../shared/redux/src/StatesGetter';
import { fetchIncomeRequest } from '../../../shared/redux/actions/TransactionActions';

const DoctorWallet = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    data: incomeData,
    currentPage: incomeCurrentPage,
    noOfPages: incomeNoOfPages,
  } = useSelector(getIncomeState, shallowEqual);

  useEffect(() => {
    dispatch(fetchIncomeRequest({}));
  }, []);

  const loadNextPageIncomeData = () => {
    if (incomeCurrentPage <= incomeNoOfPages) {
      dispatch(
        fetchIncomeRequest({
          page: incomeCurrentPage,
          nextPage: true,
        }),
      );
    }
  };

  return (
    <section className="wallet">
      <div className="title-section">
        <h1>{t('wallet.header')}</h1>
      </div>
      <DoctorWalletTabIncome
        incomeData={incomeData}
        incomeCurrentPage={incomeCurrentPage}
        incomeNoOfPages={incomeNoOfPages}
        loadNextPageIncomeData={loadNextPageIncomeData}
      />
    </section>
  );
};

DoctorWallet.defaultProps = {
  match: {},
  location: {},
};
DoctorWallet.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({}),
  }),
};
export default DoctorWallet;
