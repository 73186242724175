import 'doctor/styles/appointments.css';
import 'doctor/styles/consultations.css';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getDoctorPastAppointmentsState } from 'shared/redux/src/StatesGetter';
import Utils from 'shared/modules/Utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import { fetchDoctorPastAppointmentsRequest } from 'shared/redux/actions/DoctorAppointmentsActions';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import PastConsultationListItem from 'shared/components/consultations/PastConsultationListItem';
import _ from 'lodash';

const DoctorAppointmentsTabHistory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    data: pastAppointments,
    currentPage: pastAppointmentsCurrentPage,
    noOfPages: pastAppointmentsNoOfPages,
  } = useSelector(getDoctorPastAppointmentsState, shallowEqual);
  const [formattedPastAppointments, setFormattedPastAppointments] = useState(
    Utils.makeSectionListFormat(pastAppointments, 'dateFormatted'),
  );
  const loadNextPagePastAppointments = () => {
    if (pastAppointmentsCurrentPage <= pastAppointmentsNoOfPages) {
      dispatch(
        fetchDoctorPastAppointmentsRequest({
          page: pastAppointmentsCurrentPage,
          nextPage: true,
        }),
      );
    }
  };
  const fetchPastAppointments = () => dispatch(fetchDoctorPastAppointmentsRequest({}));

  useEffect(() => {
    fetchPastAppointments();
  }, []);

  useEffect(() => {
    setFormattedPastAppointments(Utils.makeSectionListFormat(pastAppointments, 'dateFormatted'));
  }, [pastAppointments]);

  const renderItem = (item) => {
    const { patient, user } = item;
    const { picture, firstName = '', lastName = '' } = patient ?? user ?? {};

    return (
      <PastConsultationListItem
        key={_.uniqueId()}
        isDoctor
        listItem={item}
        user={{
          ...patient,
          imgUrl: picture,
          fullNameWithTitle: `${firstName} ${lastName}`,
        }}
      />
    );
  };

  return (
    <div className="appointments-tab-history" id="appointments-tab-history">
      <InfiniteScroll
        dataLength={pastAppointments.length}
        next={loadNextPagePastAppointments}
        hasMore={pastAppointmentsCurrentPage <= pastAppointmentsNoOfPages}
        loader={<LoadingSpinner isLoading />}
      >
        {!pastAppointments.length && <div className="empty-container">{t('history.noData')}</div>}
        {formattedPastAppointments.map((section) => (
          <div key={section.key}>
            <div className="subtitle">{`${section.key} • ${section?.data?.length}`}</div>
            {section?.data?.length ? (
              <>{section?.data?.map((appointment) => renderItem(appointment))}</>
            ) : (
              <div className="empty-container">{t('history.noData')}</div>
            )}
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};

DoctorAppointmentsTabHistory.defaultProps = {
  match: {},
  location: {},
};
DoctorAppointmentsTabHistory.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({}),
  }),
};
export default DoctorAppointmentsTabHistory;
