import { useEffect, useState } from 'react';
import Utils from 'shared/modules/Utils';

const usePatientProfile = (
  profile = {
    firstName: '',
    lastName: '',
    birthDate: undefined,
    patientKinshipId: undefined,
    height: undefined,
    weight: undefined,
    rawGender: undefined,
    bloodSystem: undefined,
    knownAllergies: undefined,
    isSmoker: false,
    isAlcoholConsumer: false,
    currentMedicalConditions: undefined,
    pastMedicalConditions: undefined,
  },
) => {
  const [firstName, setFirstName] = useState(profile.firstName);
  const [lastName, setLastName] = useState(profile.lastName);
  const [birthDate, setBirthDate] = useState<string | null | undefined>(profile.birthDate);
  const [relation, setRelation] = useState<number | null | undefined>(profile.patientKinshipId);
  const [height, setHeight] = useState<number | null | undefined>(profile.height);
  const [weight, setWeight] = useState<number | null | undefined>(profile.weight);
  const [gender, setGender] = useState<string | null | undefined>(profile.rawGender);
  const [bloodSystem, setBloodSystem] = useState<string | null | undefined>(profile.bloodSystem);
  const [knownAllergies, setKnownAllergies] = useState<string | null | undefined>(
    profile.knownAllergies,
  );
  const [isSmoker, setIsSmoker] = useState(profile.isSmoker);
  const [isAlcoholConsumer, setIsAlcoholConsumer] = useState(profile.isAlcoholConsumer);
  const [currentMedicalConditions, setCurrentMedicalConditions] = useState<
    string | null | undefined
  >(profile.currentMedicalConditions);
  const [pastMedicalConditions, setPastMedicalConditions] = useState<string | null | undefined>(
    profile.pastMedicalConditions,
  );
  const handleFirstNameChange = (e) => setFirstName(e.target.value.trim());
  const handleLastNameChange = (e) => setLastName(e.target.value.trim());
  const handleBirthDateChange = (e) => setBirthDate(e.target.value);
  // @ts-ignore
  const handleRelationChange = (e) => setRelation(Utils.parseToInt(e.target.value));
  // @ts-ignore
  const handleHeightChange = (e) => setHeight(Utils.parseToInt(e.target.value));
  // @ts-ignore
  const handleWeightChange = (e) => setWeight(Utils.parseToInt(e.target.value));
  const handleGenderChange = (e) => setGender(e.target.value);
  const handleBloodSystemChange = (e) => setBloodSystem(e.target.value);
  const handleKnownAllergiesChange = (e) => setKnownAllergies(e.target.value);
  const handleIsSmokerChange = (e) => setIsSmoker(!isSmoker);
  const handleIsAlcoholConsumerChange = (e) => setIsAlcoholConsumer(!isAlcoholConsumer);
  const handleCurrentMedicalConditionsChange = (e) => setCurrentMedicalConditions(e.target.value);
  const handlePastMedicalConditionsChange = (e) => setPastMedicalConditions(e.target.value);

  return {
    firstName,
    lastName,
    relation,
    birthDate,
    height,
    weight,
    gender,
    bloodSystem,
    knownAllergies,
    isSmoker,
    isAlcoholConsumer,
    currentMedicalConditions,
    pastMedicalConditions,
    setFirstName,
    setLastName,
    setBirthDate,
    setRelation,
    setHeight,
    setWeight,
    setGender,
    setBloodSystem,
    setKnownAllergies,
    setIsSmoker,
    setIsAlcoholConsumer,
    setCurrentMedicalConditions,
    setPastMedicalConditions,
    handleFirstNameChange,
    handleLastNameChange,
    handleBirthDateChange,
    handleRelationChange,
    handleHeightChange,
    handleWeightChange,
    handleGenderChange,
    handleBloodSystemChange,
    handleKnownAllergiesChange,
    handleIsSmokerChange,
    handleIsAlcoholConsumerChange,
    handleCurrentMedicalConditionsChange,
    handlePastMedicalConditionsChange,
  };
};

export default usePatientProfile;
