import 'patient/styles/appointment-steps.css';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { getPatientAppointmentState } from 'shared/redux/src/StatesGetter';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PatientAppointmentWaitForDoctorTimer from './PatientAppointmentWaitForDoctorTimer';
import TimerComponent from '../../../shared/components/TimerComponent';

const PatientAppointmentWaitingScreen = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {
    id: appointmentId,
    doctor,
    startTime,
    duration,
    price,
    type: appointmentType,
  } = useSelector(getPatientAppointmentState, shallowEqual);

  return (
    <section className="appointment-steps waiting-screen">
      <div className="countdown-box countdown">
        <div className="will-start">{t('medicSpecialistStartingCallIn')}</div>
        <div className="timer">
          <PatientAppointmentWaitForDoctorTimer
            key={startTime}
            action={() => {}}
            /* action={() => navigate('/patient/consultations', { state: 'upcoming' })} */
            endTime={startTime}
          />
        </div>
      </div>
      <div className="medic-call-box">
        <div
          className="medic-avatar"
          style={
            doctor?.imgUrl
              ? { backgroundImage: `url(${doctor?.imgUrl})` }
              : { backgroundColor: '#E5E7E8' }
          }
        >
          {!doctor?.imgUrl && (
            <div className="image-placeholder">{`${doctor?.firstName?.charAt(
              0,
            )}${doctor?.lastName?.charAt(0)}`}</div>
          )}
        </div>
        <div className="rating">
          <CustomIcon className="custom-icon" color={Colors.mediumGrey} size="16" icon="Rating_2" />
          <i className="rating-icon" />
          {t('grade')} {Math.round(doctor.averageRating) * 2} ({doctor.countRating ?? 0}{' '}
          {t('noOfReviews')})
        </div>
        <div className="name">{doctor.fullNameWithTitle}</div>
        <div className="specialization">{doctor.specialization}</div>
        <div className="clinic">{doctor.organization?.clinicName}</div>
      </div>
      <div className="info-message">
        <CustomIcon className="custom-icon" color={Colors.mediumGrey} size="24" icon="Warning_2" />
        {t('waitingPushNotification')}
      </div>
    </section>
  );
};

export default PatientAppointmentWaitingScreen;
