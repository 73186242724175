import i18next from 'i18next';
import Utils from 'shared/modules/Utils';
import dayjs from 'dayjs';

const pushesSelector = (pushes) => {
  const { pages, page, unseen, total } = pushes;
  const notifications: Array<Record<string, unknown>> = [];
  // eslint-disable-next-line array-callback-return
  pushes.data.map((row) => {
    notifications.push({
      id: row?.id,
      body: row.body,
      isReceived: row.isReceived,
      isAnswered: row.isAnswered,
      createdAt: row.createdAt,
      createdAtFormatted: dayjs(row.createdAt).format('YYYY-MM-DD'),
      updatedAt: row.updatedAt,
      name: row.title,
      title: row.title,
      notificationType: row?.notificationType?.typeName,
    });
  });
  return {
    noOfPages: pages,
    currentPage: page,
    data: notifications,
    unseen,
    total: Utils.parseToInt(total),
  };
};

const notificationsSettingsSelector = (settings) => {
  return settings.map((row) => {
    return {
      id: row?.id,
      value: row.value,
      category: row.account_notification_type.type,
      name:
        row.account_notification_type.translations.find((r) => r.locale === i18next.language)
          ?.name ?? '',
    };
  });
};

export { pushesSelector, notificationsSettingsSelector };
