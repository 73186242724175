import 'patient/styles/main-screen.css';
import React from 'react';

import PatientMainScreenComponent from '../../components/PatientMainScreenComponent';

const PatientLanding = () => {
  return <PatientMainScreenComponent page="isLanding" />;
};

export default PatientLanding;
