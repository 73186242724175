import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { isDoctor } from 'shared/services/EnvService';
import { useTranslation } from 'react-i18next';
import Colors from 'shared/themes/Colors';
import {
  getAuthState,
  getPatientProfileState,
  getPushesState,
  getUserProfileState,
} from 'shared/redux/src/StatesGetter';
import HeaderSection from 'patient/components/HeaderSection';
import HeaderMenuDoctor from 'shared/components/HeaderMenuDoctor';

const HeaderMenu = () => {
  const { t } = useTranslation();
  const { unseen = 0 } = useSelector(getPushesState, shallowEqual);
  const { isLoggedIn, isDoctor: isDoctorUser } = useSelector(getAuthState, shallowEqual);
  const goToBlog = () => window.open(`https://blog.ringdoc.ro`, '_self');
  const profileData = useSelector(
    isDoctorUser ? getUserProfileState : getPatientProfileState,
    shallowEqual,
  );

  const homeClass = (() => {
    if (window.location.pathname === '/') return 'active';
    if (window.location.pathname.match(/\/landing/)) return 'active';
    return '';
  })();
  const aboutClass = window.location.pathname.match(/\/about-us/) ? 'active' : '';
  const howItWorksClass = window.location.pathname.match(/\/how-it-works/) ? 'active' : '';
  const contactClass = window.location.pathname.match(/\/contact/) ? 'active' : '';

  // @ts-ignore
  return (
    <>
      {isDoctor(true) && (
        <header
          className={`${
            isDoctor(true)
              ? 'menu menu-medic landing primary-pages'
              : 'menu menu-patient landing primary-pages'
          }`}
        >
          {isDoctor(true) && <HeaderMenuDoctor />}
        </header>
      )}
      {!isDoctor(true) && <HeaderSection unseen={unseen} />}
    </>
  );
};

export default HeaderMenu;
