import 'patient/styles/medical-folder.css';
import React, { useState, useEffect, useRef } from 'react';
import i18next from 'i18next';
import { Form, Modal } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Utils from 'shared/modules/Utils';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { bloodSystemOptions, genderOptions } from 'shared/settings/FormSettings';
import usePatientProfile from 'patient/hooks/usePatientProfile';
import useProfilePictureUpload from 'patient/hooks/useProfilePictureUpload';
import {
  getAuthState,
  getCountriesState,
  getPossibleKinshipState,
} from 'shared/redux/src/StatesGetter';
import { getEnv } from 'shared/services/EnvService';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { styles } from 'shared/settings/GooglePlacesAutocomplete';
import Alerts from 'shared/components/Alerts';
import {
  updateProfileRequest,
  addFamilyMemberDiseaseRequest,
  deleteFamilyMemberDiseaseRequest,
  updateFamilyMemberDiseaseRequest,
} from 'shared/redux/actions/FamilyMembersActions';
import Validators from 'shared/modules/Validators';
import { useTranslation } from 'react-i18next';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import { createLoadingSelector } from '../../../shared/redux/src/GenericSeletors';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';

const acceptedFiles: Array<string> = ['image/jpeg', 'image/png'];

const PatientMedicalFolderTabProfile = ({ profileData }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const loadingSelector = createLoadingSelector();
  const isLoading = useSelector(loadingSelector);
  const relations = useSelector(getPossibleKinshipState, shallowEqual);
  const profile = usePatientProfile(profileData);
  const picture = useProfilePictureUpload(profileData.imageUrl);
  const [familyHistory, setFamilyHistory] = useState(profileData.patientKinshipDiseases);
  const [phoneNumber, setPhoneNumber] = useState(profileData.phoneNumber);
  const [address, setAddress] = useState(profileData.address?.addressAsString);
  const [rawAddress, setRawAddress] = useState('');
  const [isReadMore, setIsReadMore] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

  const handlePhoneNumberChange = (e) => setPhoneNumber(e.target.value);
  const [addFamilyHistoryRelation, setAddFamilyHistoryRelation] = useState<
    number | null | undefined
  >(undefined);
  const [addFamilyHistoryDiseases, setAddFamilyHistoryDiseases] = useState('');
  const handleAddFamilyHistoryRelationChange = (e) => setAddFamilyHistoryRelation(e.target.value);
  const handleAddFamilyHistoryDiseasesChange = (e) => setAddFamilyHistoryDiseases(e.target.value);
  const [showModalAddFamilyHistory, setModalAddFamilyHistoryShow] = useState(false);
  const modalAddFamilyHistoryClose = () => setModalAddFamilyHistoryShow(false);
  const modalAddFamilyHistoryShow = () => {
    setAddFamilyHistoryRelation(undefined);
    setAddFamilyHistoryDiseases('');
    setModalAddFamilyHistoryShow(true);
  };

  const [updateFamilyHistoryRecordId, setUpdateFamilyHistoryRecordId] = useState<
    number | null | undefined
  >(undefined);
  const [updateFamilyHistoryRelation, setUpdateFamilyHistoryRelation] = useState<
    number | null | undefined
  >(undefined);
  const [updateFamilyHistoryDiseases, setUpdateFamilyHistoryDiseases] = useState('');
  const handleUpdateFamilyHistoryRelationChange = (e) =>
    setUpdateFamilyHistoryRelation(e.target.value);
  const handleUpdateFamilyHistoryDiseasesChange = (e) =>
    setUpdateFamilyHistoryDiseases(e.target.value);
  const [showModalModifyFamilyHistory, setModalModifyFamilyHistoryShow] = useState(false);
  const modalModifyFamilyHistoryClose = () => setModalModifyFamilyHistoryShow(false);
  const modalModifyFamilyHistoryShow = (record) => {
    setUpdateFamilyHistoryRecordId(record.id);
    setUpdateFamilyHistoryRelation(record.profileId);
    setUpdateFamilyHistoryDiseases(record.diseases);
    setModalModifyFamilyHistoryShow(true);
  };
  const phoneNumberRef = useRef(null);

  const updatePatientProfile = () => {
    if (picture.type !== null && !acceptedFiles.includes(picture.type)) {
      Alerts.errorAlert(t('settings.personalDataAcceptedFiles'));
      return;
    }

    const parsedPhone = phoneNumber && Utils.withoutMetadata(parsePhoneNumber(phoneNumber));
    const getNumberAfterPossiblyEliminatingZero = Utils.getNumberAfterPossiblyEliminatingZero(
      parsedPhone?.nationalNumber,
      parsedPhone?.countryCallingCode,
    );

    if (
      !profile.firstName ||
      !profile.lastName ||
      !profile.birthDate ||
      (!profile.relation && !profileData.isMainProfile) ||
      (profileData.isMainProfile && rawAddress && !Validators.isValidAddress(rawAddress)) ||
      (profileData.isMainProfile && !parseInt(getNumberAfterPossiblyEliminatingZero.number, 10))
    ) {
      Alerts.simpleAlert(t('error'), t('markedFieldsMandatory'));
      return;
    }

    const pictureDataToPost = {
      originalName: picture.name,
      mimeType: picture.type,
      size: picture.size,
    };

    const patientData = {
      firstName: profile.firstName,
      lastName: profile.lastName,
      birthDate: profile.birthDate,
      phoneNumber: getNumberAfterPossiblyEliminatingZero?.formattedNumber || undefined,
      rawAddress: rawAddress || undefined,
      patientKinship: profile.relation || undefined,
      height: profile.height || undefined,
      weight: profile.weight || undefined,
      gender: profile.gender || undefined,
      bloodSystem: profile.bloodSystem || undefined,
      knownAllergies: profile.knownAllergies || '',
      isSmoker: profile.isSmoker,
      isAlcoholConsumer: profile.isAlcoholConsumer,
      currentMedicalConditions: profile.currentMedicalConditions || undefined,
      pastMedicalConditions: profile.pastMedicalConditions || undefined,
    };

    let payloadToUpdate;
    if (picture.type) {
      payloadToUpdate = { ...patientData, picture: pictureDataToPost };
    } else {
      payloadToUpdate = patientData;
    }

    Alerts.okCancelAlert(t('warning'), t('validations.saveProfile'), () => {
      dispatch(
        updateProfileRequest({
          id: profileData.id,
          toUpdate: payloadToUpdate,
          toUpload: picture.dataToUpload,
        }),
      );
    });
  };
  const addPatientDiseaseHistory = () => {
    if (!addFamilyHistoryDiseases || !addFamilyHistoryRelation) {
      Alerts.simpleAlert(t('error'), t('allFieldsMandatory'));
      return;
    }

    dispatch(
      addFamilyMemberDiseaseRequest({
        toRequest: {
          patientId: profileData.id,
          data: {
            kinship: addFamilyHistoryRelation,
            diseases: addFamilyHistoryDiseases,
          },
        },
      }),
    );

    modalAddFamilyHistoryClose();
  };
  const updatePatientDiseaseHistory = () => {
    if (!updateFamilyHistoryDiseases || !updateFamilyHistoryRelation) {
      Alerts.simpleAlert(t('error'), t('allFieldsMandatory'));
      return;
    }

    dispatch(
      updateFamilyMemberDiseaseRequest({
        toUpdate: {
          patientId: profileData.id,
          recordId: updateFamilyHistoryRecordId,
          data: {
            kinship: updateFamilyHistoryRelation,
            diseases: updateFamilyHistoryDiseases,
          },
        },
      }),
    );

    modalModifyFamilyHistoryClose();
  };
  const deletePatientDiseaseHistory = (recordId) => {
    Alerts.okCancelAlert(
      `${t('warning')}`,
      `${t('medicalProfile.deleteMemberFromHistory')}`,
      () => {
        dispatch(deleteFamilyMemberDiseaseRequest({ patientId: profileData.id, recordId }));
      },
      () => {},
    );
  };

  const currentDate = new Date().toISOString().slice(0, 10);

  useEffect(() => {
    profile.setFirstName(profileData.firstName);
    profile.setLastName(profileData.lastName);
    profile.setBirthDate(profileData.birthDate);
    profile.setRelation(profileData.patientKinshipId);
    profile.setHeight(profileData.height);
    profile.setWeight(profileData.weight);
    profile.setGender(profileData.rawGender);
    profile.setBloodSystem(profileData.bloodSystem);
    profile.setKnownAllergies(profileData.knownAllergies);
    profile.setIsSmoker(profileData.isSmoker);
    profile.setIsAlcoholConsumer(profileData.isAlcoholConsumer);
    profile.setCurrentMedicalConditions(profileData.currentMedicalConditions);
    profile.setPastMedicalConditions(profileData.pastMedicalConditions);
    picture.setUri(profileData.imageUrl);
    setPhoneNumber(profileData.phoneNumber);
    setAddress(profileData.address?.addressAsString);
    setRawAddress('');
    setFamilyHistory(profileData.patientKinshipDiseases);
  }, [profileData]);

  useEffect(() => {
    setShowLoader(isLoading);
  }, [isLoading]);

  return (
    <div className="row rowp10 profile-tab justify-content-center">
      {showLoader && <LoadingSpinner isLoading={isLoading} />}
      <div className="col-12 colp10">
        <div className="profile-picture">
          <div className="subtitle">{t('profilePhoto')}</div>
          <div className="change-picture">
            <Form.Group controlId="formFile" className="files-box">
              <Form.Label className="modify">{t('changeProfilePhoto')}</Form.Label>
              <Form.Control type="file" onChange={picture.handleProfilePictureChange} />
            </Form.Group>
            {!picture.uri && (
              <div className="no-avatar">
                <CustomIcon
                  className="custom-icon"
                  color={Colors.mediumGrey}
                  size="40"
                  icon="Profil"
                />
              </div>
            )}
            {picture.uri && (
              <div className="avatar" style={{ backgroundImage: `url(${picture.uri})` }} />
            )}
          </div>
          <div className="profile-picture-file-extention-tooltip">
            {t('settings.personalDataAcceptedFiles')}
          </div>
        </div>

        <div className="personal-data">
          <div className="subtitle">{t('settings.personalData')}</div>

          {profileData.isMainProfile && (
            <div className="form-input">
              <input
                disabled
                autoComplete="off"
                id="email"
                name="email"
                type="email"
                defaultValue={profileData.email || ''}
                className="form-control"
                placeholder=" "
              />
              <label htmlFor="email">{t('email')}*</label>
            </div>
          )}

          <div className="form-input">
            <input
              autoComplete="off"
              id="firstName"
              name="firstName"
              type="text"
              className="form-control"
              placeholder=" "
              value={profile.firstName}
              onChange={profile.handleFirstNameChange}
            />
            <label htmlFor="firstName">{t('firstName')}*</label>
          </div>

          <div className="form-input">
            <input
              autoComplete="off"
              id="lastName"
              name="lastName"
              type="text"
              className="form-control"
              placeholder=" "
              value={profile.lastName}
              onChange={profile.handleLastNameChange}
            />
            <label htmlFor="lastName">{t('lastName')}*</label>
          </div>

          <div className="form-input">
            <input
              autoComplete="off"
              id="birthDate"
              name="birthDate"
              type="date"
              className="form-control"
              placeholder=" "
              value={profile.birthDate ? profile.birthDate.split(' ')[0] : ''}
              onChange={profile.handleBirthDateChange}
              max={currentDate}
            />
            <label htmlFor="birthDate">{t('birthDate')}*</label>
          </div>

          {!profileData.isMainProfile && (
            <div className="form-select-label">
              <select
                required
                id="possible-kinship"
                name="possible-kinship"
                className="custom-select"
                value={profile.relation || ''}
                onChange={profile.handleRelationChange}
              >
                <option value="" disabled hidden>
                  {t('userRelation')}*
                </option>
                {relations.length &&
                  Utils.makeSelectOptions(relations, 'name', 'id').map((rel) => {
                    return (
                      <option key={rel.value} value={rel.value}>
                        {Utils.capitalizeFirstLetter(rel.label)}
                      </option>
                    );
                  })}
              </select>
              <label htmlFor="possible-kinship">{t('userRelation')}*</label>
            </div>
          )}

          {profileData.isMainProfile && (
            <>
              <div className="phone-number-with-prefix">
                <PhoneInput
                  className={phoneNumber && 'has-value'}
                  placeholder=" "
                  value={phoneNumber}
                  defaultCountry="RO"
                  id="phoneNumber"
                  name="phoneNumber"
                  /* @ts-ignore */
                  onChange={(value) => setPhoneNumber(value)}
                  ref={phoneNumberRef}
                />
                <label htmlFor="phoneNumber">{t('phoneNumber')}*</label>
              </div>
              <GooglePlacesAutocomplete
                apiKey={getEnv('GOOGLE_PLACES_API_KEY')}
                apiOptions={{ language: 'ro', region: 'ro' }}
                minLengthAutocomplete={3}
                selectProps={{
                  placeholder: t('address*'),
                  defaultInputValue: address,
                  onChange: (o) => {
                    geocodeByPlaceId(o.value.place_id)
                      .then((results) => setRawAddress(JSON.stringify(results[0])))
                      .catch((error) => console.error(error));
                  },
                  styles,
                }}
              />
            </>
          )}
        </div>
      </div>

      <div className="col-12 colp10">
        <div className="biometric-data">
          <div className="subtitle">{t('bioData')}</div>
          <div className="row rowp5">
            <div className="col-lg-6 col-xl-6 col-md-6 col-12 colp5">
              <div className="form-input">
                <input
                  autoComplete="off"
                  id="height"
                  name="height"
                  type="text"
                  className="form-control"
                  placeholder=" "
                  value={profile.height || ''}
                  onChange={profile.handleHeightChange}
                />
                <label htmlFor="height">{t('medicalProfile.height')}</label>
              </div>
            </div>

            <div className="col-lg-6 col-xl-6 col-md-6 col-12 colp5">
              <div className="form-input">
                <input
                  autoComplete="off"
                  id="weight"
                  name="weight"
                  type="text"
                  className="form-control"
                  placeholder=" "
                  value={profile.weight || ''}
                  onChange={profile.handleWeightChange}
                />
                <label htmlFor="weight">{t('medicalProfile.weight')}</label>
              </div>
            </div>
            {/* TODO: Add gender back */}
            {/* <div className="col-lg-6 col-xl-6 col-md-6 col-12 colp5"> */}
            {/*   <div className="form-select-label"> */}
            {/*     <select */}
            {/*       required */}
            {/*       id="gender" */}
            {/*       name="gender" */}
            {/*       className="custom-select" */}
            {/*       value={profile.gender || ''} */}
            {/*       onChange={profile.handleGenderChange} */}
            {/*     > */}
            {/*       <option value="" disabled hidden> */}
            {/*         {t('medicalProfile.gender')} */}
            {/*       </option> */}
            {/*       {Utils.makeSelectOptions(genderOptions, 'name', 'id').map((genderOption) => { */}
            {/*         return ( */}
            {/*           <option key={genderOption.value} value={genderOption.value}> */}
            {/*             {Utils.capitalizeFirstLetter(i18next.t(genderOption.label))} */}
            {/*           </option> */}
            {/*         ); */}
            {/*       })} */}
            {/*     </select> */}
            {/*     <label htmlFor="sex">{t('medicalProfile.gender')}</label> */}
            {/*   </div> */}
            {/* </div> */}
          </div>

          <div className="form-select-label">
            <select
              required
              id="blood-system"
              name="blood-system"
              className="custom-select"
              value={profile.bloodSystem || ''}
              onChange={profile.handleBloodSystemChange}
            >
              <option value="" disabled hidden>
                {t('medicalProfile.bloodType')}
              </option>
              {Utils.makeSelectOptions2(bloodSystemOptions, 'name', 'id', 'name2').map(
                (bloodSystemOption) => {
                  return (
                    <option key={bloodSystemOption.value} value={bloodSystemOption.value}>
                      {Utils.capitalizeFirstLetter(bloodSystemOption.label)}{' '}
                      {`${i18next.t(bloodSystemOption.name2)}`}
                    </option>
                  );
                },
              )}
            </select>
            <label htmlFor="blood-type">{t('medicalProfile.bloodType')}</label>
          </div>

          <div className="form-input">
            <input
              autoComplete="off"
              id="known-allergies"
              name="known-allergies"
              type="text"
              className="form-control"
              placeholder=" "
              value={profile.knownAllergies || ''}
              onChange={profile.handleKnownAllergiesChange}
            />
            <label htmlFor="known-allergies">{t('medicalProfile.allergies')}</label>

            <div className="switch-box">
              <Form.Switch
                className="custom-switch"
                id="smoker"
                label={t('medicalProfile.smoking')}
                checked={profile.isSmoker}
                onChange={profile.handleIsSmokerChange}
              />
            </div>

            <div className="switch-box">
              <Form.Switch
                className="custom-switch"
                id="alcohol-consumer"
                label={t('medicalProfile.alcohol')}
                checked={profile.isAlcoholConsumer}
                onChange={profile.handleIsAlcoholConsumerChange}
              />
            </div>
          </div>
        </div>

        <div className="disease">
          <div className="subtitle">{t('medicalProfile.issues')}</div>

          <div className="form-input">
            <input
              autoComplete="off"
              id="current-medical-conditions"
              name="current-medical-conditions"
              type="text"
              className="form-control"
              placeholder=" "
              value={profile.currentMedicalConditions || ''}
              onChange={profile.handleCurrentMedicalConditionsChange}
            />
            <label htmlFor="current-medical-conditions">{t('medicalProfile.knownIssues')}</label>
          </div>

          <div className="form-input">
            <input
              autoComplete="off"
              id="past-medical-conditions"
              name="past-medical-conditions"
              type="text"
              className="form-control"
              placeholder=" "
              value={profile.pastMedicalConditions || ''}
              onChange={profile.handlePastMedicalConditionsChange}
            />
            <label htmlFor="past-medical-conditions">{t('medicalProfile.pastIssues')}</label>
          </div>
        </div>

        <div className="family-history">
          <div className="subtitle">{t('medicalProfile.familyHistory')}</div>
          <button
            type="button"
            className="white-button add-btn mt-4"
            onClick={modalAddFamilyHistoryShow}
          >
            <CustomIcon className="custom-icon" color={Colors.patientColor} size="24" icon="Plus" />
            {t('addFamilyHistory')}
          </button>

          {familyHistory?.map((kinshipDisease) => {
            const toggleReadMore = () => {
              setIsReadMore(!isReadMore);
            };
            return (
              <div className="box" key={kinshipDisease.id}>
                <div className="title">{Utils.capitalizeFirstLetter(kinshipDisease.profile)}</div>

                <div className="description">
                  {isReadMore
                    ? Utils.maybeLongText(kinshipDisease.diseases, 130, ' ')
                    : kinshipDisease.diseases}
                  {/* {Utils.maybeLongText(kinshipDisease.diseases, 100)} */}
                </div>

                {isReadMore && (
                  <button type="button" onClick={toggleReadMore} className="read-all-btn">
                    {t('readAll')}
                  </button>
                )}

                <div className="action-buttons">
                  <button
                    type="button"
                    className="modify-btn"
                    onClick={() => modalModifyFamilyHistoryShow(kinshipDisease)}
                  >
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.patientColor}
                      size="20"
                      icon="Edit"
                    />
                    {t('edit')}
                  </button>

                  <button
                    type="button"
                    className="delete-btn"
                    onClick={() => deletePatientDiseaseHistory(kinshipDisease.id)}
                  >
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.mediumGrey}
                      size="20"
                      icon="Delete"
                    />
                    {t('delete')}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="col-12">
        <button type="button" className="orange-button" onClick={updatePatientProfile}>
          {t('saveChanges')}
        </button>
      </div>

      <Modal
        show={showModalAddFamilyHistory}
        onHide={modalAddFamilyHistoryClose}
        animation
        centered
        className="modal-add-family-history"
      >
        <Modal.Header closeButton closeLabel={t('close')}>
          <Modal.Title>{t('addFamilyHistory')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form>
            <div className="form-select-label">
              <select
                required
                id="degree-kinship"
                name="degree-kinship"
                className="custom-select"
                value={addFamilyHistoryRelation || ''}
                onChange={handleAddFamilyHistoryRelationChange}
              >
                <option value="" disabled hidden>
                  {t('medicalProfile.relativeType')}
                </option>
                {relations.length &&
                  Utils.makeSelectOptions(relations, 'name', 'id').map((rel) => {
                    return (
                      <option key={rel.value} value={rel.value}>
                        {Utils.capitalizeFirstLetter(rel.label)}
                      </option>
                    );
                  })}
              </select>
              <label htmlFor="degree-kinship">{t('medicalProfile.relativeType')}</label>
            </div>

            <div className="form-textarea">
              <textarea
                id="describe-ailments"
                name="describe-ailments"
                className="form-control"
                placeholder=" "
                rows={5}
                value={addFamilyHistoryDiseases}
                onChange={handleAddFamilyHistoryDiseasesChange}
              />
              <label htmlFor="describe-ailments">{t('medicalProfile.describeIssues')}</label>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <button type="button" className="orange-button" onClick={addPatientDiseaseHistory}>
            {t('add')}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModalModifyFamilyHistory}
        onHide={modalModifyFamilyHistoryClose}
        animation
        centered
        className="modal-modify-family-history"
      >
        <Modal.Header closeButton closeLabel={t('close')}>
          <Modal.Title>{t('updateFamilyHistory')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form>
            <div className="form-select-label">
              <select
                required
                id="degree-kinship"
                name="degree-kinship"
                className="custom-select"
                value={updateFamilyHistoryRelation || ''}
                onChange={handleUpdateFamilyHistoryRelationChange}
              >
                <option value="" disabled hidden>
                  {t('medicalProfile.relativeType')}
                </option>
                {relations.length &&
                  Utils.makeSelectOptions(relations, 'name', 'id').map((rel) => {
                    return (
                      <option key={rel.value} value={rel.value}>
                        {Utils.capitalizeFirstLetter(rel.label)}
                      </option>
                    );
                  })}
              </select>
              <label htmlFor="degree-kinship">{t('medicalProfile.relativeType')}</label>
            </div>

            <div className="form-textarea">
              <textarea
                id="describe-ailments"
                name="describe-ailments"
                className="form-control"
                placeholder=" "
                rows={5}
                value={updateFamilyHistoryDiseases}
                onChange={handleUpdateFamilyHistoryDiseasesChange}
              />
              <label htmlFor="describe-ailments">{t('medicalProfile.describeIssues')}</label>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <button type="button" className="orange-button" onClick={updatePatientDiseaseHistory}>
            {t('saveChanges')}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PatientMedicalFolderTabProfile;
