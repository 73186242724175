import { actionChannel, call, fork, put, take } from 'redux-saga/effects';
import {
  FETCH_BALANCE_REQUEST,
  FETCH_INCOME_REQUEST,
  FETCH_INCOME_TRANSACTIONS_REQUEST,
  FETCH_PAYOUT_TRANSACTIONS_REQUEST,
  FETCH_PAYOUTS_REQUEST,
} from 'shared/redux/types/TransactionTypes';
import {
  getBalanceAPI,
  getIncomeAPI,
  getIncomeTransactionsAPI,
  getPayoutsAPI,
  getPayoutTransactionsAPI,
} from 'shared/redux/api/TransactionApi';
import {
  fetchBalanceError,
  fetchBalanceSuccess,
  fetchIncomeError,
  fetchIncomeSuccess,
  fetchIncomeTransactionsError,
  fetchIncomeTransactionsMonthlySuccess,
  fetchIncomeTransactionsSuccess,
  fetchPayoutsError,
  fetchPayoutsSuccess,
  fetchPayoutTransactionsError,
  fetchPayoutTransactionsSuccess,
} from 'shared/redux/actions/TransactionActions';
import {
  balanceSelector,
  incomeSelector,
  incomeTransactionsMonthlySelector,
  incomeTransactionsSelector,
  payoutsSelector,
  payoutTransactionsSelector,
} from 'shared/redux/selector/TransactionSelector';
import _ from 'lodash';
import StorageService from '../../services/StorageService';

function* getBalance(actionType) {
  const balanceChannel = yield actionChannel(actionType);
  let isPharmacist = 'false';
  StorageService.getData('IS_PHARMACIST', 'false').then((response) => {
    isPharmacist = response;
  });
  while (true) {
    yield take(balanceChannel);
    try {
      let response = { data: [] };
      if (isPharmacist === 'false') {
        response = yield call(getBalanceAPI);
      }
      yield put(fetchBalanceSuccess(balanceSelector(response.data)));
    } catch ({ message }) {
      yield put(fetchBalanceError({ message }));
    }
  }
}

function* getPayouts(actionType) {
  const payoutsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(payoutsChannel);
    try {
      const safeAction = {
        limit: 5,
        page: 1,
        ...payload,
      };
      const response = yield call(getPayoutsAPI, safeAction);
      yield put(
        fetchPayoutsSuccess({
          ...payoutsSelector(response.data),
          nextPage: payload.nextPage,
        }),
      );
    } catch ({ message }) {
      yield put(fetchPayoutsError({ message }));
    }
  }
}

function* getPayoutTransactions(actionType) {
  const payoutTransactionsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(payoutTransactionsChannel);
    try {
      const safeAction = {
        limit: 5,
        page: 1,
        ...payload,
      };
      const response = yield call(getPayoutTransactionsAPI, safeAction);
      yield put(
        fetchPayoutTransactionsSuccess({
          data: payoutTransactionsSelector(response.data),
          page: safeAction.page,
          noOfPages: response.data.pages,
        }),
      );
    } catch ({ message }) {
      yield put(fetchPayoutTransactionsError({ message }));
    }
  }
}

function* getIncome(actionType) {
  const incomeChannel = yield actionChannel(actionType);
  let isPharmacist = 'false';
  StorageService.getData('IS_PHARMACIST', 'false').then((response) => {
    isPharmacist = response;
  });
  while (true) {
    const { payload } = yield take(incomeChannel);
    try {
      const safeAction = {
        limit: 5,
        page: 1,
        ...payload,
      };
      let response = { data: [] };
      if (isPharmacist === 'false') {
        response = yield call(getIncomeAPI, safeAction);
      }
      yield put(
        fetchIncomeSuccess({
          ...incomeSelector(response.data),
          nextPage: payload.nextPage,
        }),
      );
      yield put(
        fetchIncomeTransactionsSuccess({
          data: [],
          page: 1,
          noOfPages: 1,
        }),
      );
    } catch ({ message }) {
      yield put(fetchIncomeError({ message }));
    }
  }
}

function* getIncomeTransactions(actionType) {
  const incomeTransactionsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(incomeTransactionsChannel);
    try {
      const safeAction = {
        limit: 5,
        page: 1,
        ...payload,
      };
      const response = yield call(getIncomeTransactionsAPI, safeAction);

      // eslint-disable-next-line no-underscore-dangle
      const items = response.data.items ?? [];
      const monthlyValues = response.data.monthly;
      _.unset(items, 'monthly');
      yield put(
        fetchIncomeTransactionsSuccess({
          data: incomeTransactionsSelector(items),
          page: safeAction.page,
          noOfPages: response.data.pagination.total_pages,
        }),
      );
      yield put(
        fetchIncomeTransactionsMonthlySuccess({
          data: incomeTransactionsMonthlySelector(monthlyValues),
        }),
      );
    } catch ({ message }) {
      yield put(fetchIncomeTransactionsError({ message }));
    }
  }
}

function* transactionSaga() {
  yield fork(getBalance, FETCH_BALANCE_REQUEST);
  yield fork(getPayouts, FETCH_PAYOUTS_REQUEST);
  yield fork(getPayoutTransactions, FETCH_PAYOUT_TRANSACTIONS_REQUEST);
  yield fork(getIncome, FETCH_INCOME_REQUEST);
  yield fork(getIncomeTransactions, FETCH_INCOME_TRANSACTIONS_REQUEST);
}

export default transactionSaga;
