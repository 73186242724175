import 'patient/styles/auth.css';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getAuthState,
  getPatientAppointmentState,
  getPendingAppointmentState,
} from 'shared/redux/src/StatesGetter';
import Alerts from 'shared/components/Alerts';
import { requestLogin } from 'shared/redux/actions/AuthActions';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';
import SocialLogin from 'shared/components/SocialLogin';
import { useTranslation } from 'react-i18next';
import {
  allowNewAppointment,
  isAnyAppointmentInProgress,
  requestAppointment,
} from 'shared/modules/PatientAppointmentUtils';
import {
  appointmentLocalStepsRequest,
  setPendingAppointment,
} from 'shared/redux/actions/PatientAppointmentActions';
import { fetchDoctorProfileRequest } from 'shared/redux/actions/DoctorProfileActions';
import Validators from 'shared/modules/Validators';
import { createLoadingSelector } from '../../../shared/redux/src/GenericSeletors';

const PatientLogin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [requiredFieldsError, setRequiredFieldsError] = useState(false);
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const {
    type: consultationType,
    doctor,
    specializationId,
  } = useSelector(getPendingAppointmentState, shallowEqual);
  const { status: appointmentStatus, stickyStatus } = useSelector(
    getPatientAppointmentState,
    shallowEqual,
  );
  const loadingSelector = createLoadingSelector();
  const isLoading = useSelector(loadingSelector);

  const dispatchLogin = (type, data, unconfirmedModalAlert) => {
    dispatch(requestLogin({ type, data, unconfirmedModalAlert }));
  };
  const onLogin = (e) => {
    e.preventDefault();
    if (!email || !password) {
      Alerts.simpleAlert(t('error'), t('allFieldsMandatory'));
      setRequiredFieldsError(true);
      return;
    }

    const isValidEmail = Validators.validateEmail(email);
    if (!isValidEmail) {
      Alerts.simpleAlert(t('error'), t('alerts.invalidEmail'));
      setRequiredFieldsError(true);
      return;
    }

    dispatchLogin('classic', { email, password }, true);
  };
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };
  const handleCreateAccount = () => {
    navigate('/patient/register');
  };
  const forgotPasswordAction = () => {
    navigate('/patient/forgot-password');
  };

  const onPendingScheduledAppointment = () => {
    if (!allowNewAppointment(appointmentStatus, stickyStatus)) {
      return;
    }
    if (specializationId) {
      dispatch(
        appointmentLocalStepsRequest({
          newAppointment: true,
          noOfSteps: 5,
          specializationId,
          type: 'programmed',
          doctor,
        }),
      );
      dispatch(fetchDoctorProfileRequest({ id: doctor?.id }));
      dispatch(setPendingAppointment({}));
      navigate('/patient/appointment/set-date');
    } else {
      dispatch(
        appointmentLocalStepsRequest({
          newAppointment: true,
          noOfSteps: 5,
          type: 'programmed',
          doctor,
        }),
      );
      dispatch(setPendingAppointment({}));
      navigate('/patient/appointment/specialization');
    }
  };

  const onPendingCallNowAppointment = () => {
    if (!allowNewAppointment(appointmentStatus, stickyStatus)) {
      return;
    }
    if (specializationId) {
      requestAppointment('callNow', {
        forRequest: {
          doctor: `${doctor?.id}`,
          specialization: `${specializationId}`,
        },
        forUpdate: {
          specializationId,
          doctor,
        },
      });
      dispatch(setPendingAppointment({}));
    } else {
      if (isAnyAppointmentInProgress(appointmentStatus, stickyStatus)) {
        Alerts.simpleAlert(t('info'), t('validations.alreadyHasAppointment'));
        return;
      }
      if (doctor?.isAvailableForCallNow) {
        dispatch(
          appointmentLocalStepsRequest({
            doctor,
            type: 'callNow',
            noOfSteps: 4,
            newAppointment: true,
          }),
        );
        dispatch(setPendingAppointment({}));
        navigate('/patient/appointment/specialization');
      }
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      if (consultationType === 'callNow') {
        onPendingCallNowAppointment();
      } else if (consultationType === 'programmed') {
        onPendingScheduledAppointment();
      } else {
        navigate('/');
      }
    }
  }, [isLoggedIn]);

  return (
    <section className="login">
      <form onSubmit={onLogin}>
        <div className="title-section">
          <div className="small-title-text">{t('welcome')}</div>
          <h1>{t('patientApp.login')}</h1>
        </div>
        <SocialLogin />
        <div className="or">{t('or')}</div>
        <div className="form-input">
          <input
            type="email"
            name="email"
            id="email"
            className={`form-control ${
              requiredFieldsError && (!email || !Validators.validateEmail(email)) && 'is-invalid'
            }`}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            placeholder=" "
          />
          <label htmlFor="email">{t('email')}*</label>
        </div>
        <div className="password-eye">
          <input
            value={password}
            name="password"
            id="password"
            onChange={(event) => setPassword(event.target.value)}
            type={passwordShown ? 'text' : 'password'}
            className={`form-control ${requiredFieldsError && !password && 'is-invalid'}`}
            placeholder=" "
          />
          <label htmlFor="password">{t('password')}*</label>
          <div className="eye" onClick={togglePasswordVisibility}>
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="24"
              icon={`${passwordShown ? 'Eye_off' : 'Eye'}`}
            />
          </div>
        </div>
        <button
          type="submit"
          className={`orange-button ${isLoading ? 'disabled-button' : ''}`}
          disabled={!!isLoading}
        >
          {t('patientApp.login')}
        </button>
        <div onClick={forgotPasswordAction} className="forgot-pass-btn cursor-pointer">
          <CustomIcon
            className="custom-icon"
            color={Colors.darkGrey}
            size="18"
            icon="Password_unknown"
          />
          {t('forgotPassword')}
        </div>
        <hr />
        <button
          type="button"
          onClick={handleCreateAccount}
          className="white-button position-relative"
        >
          {`${t('patientApp.noAccount')} ${t('patientApp.createAccount')}`}
          <CustomIcon
            className="custom-icon"
            color={Colors.darkGrey}
            size="24"
            icon="Right_pointer"
          />
        </button>
      </form>
    </section>
  );
};

export default PatientLogin;
