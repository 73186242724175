import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getSharedState } from 'shared/redux/src/StatesGetter';
import Alerts from 'shared/components/Alerts';
import { resetPasswordRequest } from 'shared/redux/actions/AuthActions';
import { isDoctor } from 'shared/services/EnvService';

const useResetPassword = (emailParam, tokenParam) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { forgotPassword, passwordReset } = useSelector(getSharedState, shallowEqual);
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const togglePasswordVisibility = () => setPasswordShown(!passwordShown);
  const toggleConfirmPasswordVisibility = () => setConfirmPasswordShown(!confirmPasswordShown);

  useEffect(() => {
    setToken(tokenParam);
    setEmail(emailParam.replace(' ', '+'));
  }, [emailParam, tokenParam]);

  useEffect(() => {
    if (passwordReset && !forgotPassword) {
      Alerts.simpleAlert(t('info'), t('alerts.passwordReset'));
      navigate(`/${isDoctor() ? 'doctor' : 'patient'}/home`);
    }
  }, [forgotPassword, passwordReset]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!password || !confirmPassword) {
      Alerts.simpleAlert(t('error'), t('allFieldsMandatory'));
      return;
    }
    if (password !== confirmPassword) {
      Alerts.simpleAlert(t('error'), t('account.changePasswordValidation2'));
      return;
    }
    const urlencoded = new URLSearchParams();
    urlencoded.append('token', token);
    urlencoded.append('password[first]', password);
    urlencoded.append('password[second]', confirmPassword);
    urlencoded.append('email', email);
    dispatch(
      resetPasswordRequest({
        data: urlencoded,
      }),
    );
  };

  return {
    password,
    setPassword,
    passwordShown,
    togglePasswordVisibility,
    confirmPassword,
    setConfirmPassword,
    confirmPasswordShown,
    toggleConfirmPasswordVisibility,
    onSubmit,
  };
};

export default useResetPassword;
