import { resolveAvailability } from 'shared/modules/DoctorDataFormatter';
import {
  resolveDoctorSpecializations,
  resolveUserLanguages,
} from 'shared/modules/PatientDataFormatter';
import Utils from '../../modules/Utils';

const favouriteDoctorSelector = (doctors) => {
  // eslint-disable-next-line no-underscore-dangle
  const doctorsArray = doctors._embedded?.items ?? [];
  const data: Array<Record<string, unknown>> = [];
  const ids: number[] = [];

  // eslint-disable-next-line array-callback-return
  doctorsArray.map((row) => {
    ids.push(row?.id);
    data.push({
      id: row?.id,
      email: row.email,
      locale: row.locale,
      accountStatus: row.account_status,
      firstName: row.first_name,
      lastName: row.last_name,
      fullNameWithTitle: row.full_name_with_title,
      doctorDescription: row.doctor_description,
      numericPersonalCode: row.numeric_personal_code,
      gender: Utils.solveGender(row?.gender),
      birthDate: row.birth_date,
      phoneNumberCountry: row.phone_number_country?.calling_code,
      phoneNumber: row.phone_number,
      pictureMedia: row.picture_media?.public_url ?? null,
      imgUrl: row.picture_media?.public_url ?? null,
      userLanguages: resolveUserLanguages(row?.user_languages),
      stripeConnectedAccount: row.stripe_connected_account,
      createdAt: row.created_at,
      updatedAt: row.updated_at,
      deletedAt: row.deleted_at,
      managedBy: row.managed_by,
      patientMedicalRecords: row.patient_medical_records,
      organization: row.organization,
      address: row.address,
      identityCardMedia: row?.identity_card_media,
      additionalDocumentMedia: row.additional_document_media,
      cvMedia: row.cv_media,
      averageRating: row.average_rating,
      countRating: row.count_rating,
      isAvailableForCallNow: row.is_available_for_call_now,
      isAvailableForEmergency: row.is_available_for_emergency,
      hasSchedule: row?.has_schedule ?? true,
      doctorSpecializations: resolveDoctorSpecializations(row.doctor_specializations),
      organizationClinicName: row.organization?.clinic_name,
      organizationLogo: row.organization?.logo_media?.public_url,
      availability: resolveAvailability(
        row.is_available_for_call_now,
        row.is_busy,
        row.is_fake_busy,
      ),
    });
  });
  const { pages, page } = doctors;
  return { noOfPages: pages, currentPage: page, data, ids };
};

export default favouriteDoctorSelector;
