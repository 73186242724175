export const FETCH_DOCTOR_TIMETABLE_REQUEST = 'FETCH_DOCTOR_TIMETABLE_REQUEST';
export const FETCH_DOCTOR_TIMETABLE_SUCCESS = 'FETCH_DOCTOR_TIMETABLE_SUCCESS';
export const FETCH_DOCTOR_TIMETABLE_ERROR = 'FETCH_DOCTOR_TIMETABLE_ERROR';

export const PATCH_DOCTOR_TIMETABLE_REQUEST = 'PATCH_DOCTOR_TIMETABLE_REQUEST';
export const PATCH_DOCTOR_TIMETABLE_SUCCESS = 'PATCH_DOCTOR_TIMETABLE_SUCCESS';
export const PATCH_DOCTOR_TIMETABLE_ERROR = 'PATCH_DOCTOR_TIMETABLE_ERROR';

export const ADD_DOCTOR_TIMETABLE_REQUEST = 'ADD_DOCTOR_TIMETABLE_REQUEST';
export const ADD_DOCTOR_TIMETABLE_SUCCESS = 'ADD_DOCTOR_TIMETABLE_SUCCESS';
export const ADD_DOCTOR_TIMETABLE_ERROR = 'ADD_DOCTOR_TIMETABLE_ERROR';

export const REMOVE_DOCTOR_TIMETABLE_REQUEST = 'REMOVE_DOCTOR_TIMETABLE_REQUEST';
export const REMOVE_DOCTOR_TIMETABLE_SUCCESS = 'REMOVE_DOCTOR_TIMETABLE_SUCCESS';
export const REMOVE_DOCTOR_TIMETABLE_ERROR = 'REMOVE_DOCTOR_TIMETABLE_ERROR';

export const GET_DOCTOR_PAST_TIMETABLE_REQUEST = 'GET_DOCTOR_PAST_TIMETABLE_REQUEST';
export const GET_DOCTOR_PAST_TIMETABLE_SUCCESS = 'GET_DOCTOR_PAST_TIMETABLE_SUCCESS';
export const GET_DOCTOR_PAST_TIMETABLE_ERROR = 'REMOVE_DOCTOR_TIMETABLE_ERROR';

export const ADD_BULK_DOCTOR_TIMETABLE_REQUEST = 'ADD_BULK_DOCTOR_TIMETABLE_REQUEST';
export const ADD_BULK_DOCTOR_TIMETABLE_SUCCESS = 'ADD_BULK_DOCTOR_TIMETABLE_SUCCESS';
export const ADD_BULK_DOCTOR_TIMETABLE_ERROR = 'ADD_BULK_DOCTOR_TIMETABLE_ERROR';

export const LOCALLY_ADD_DATE = 'LOCALLY_ADD_DATE';

export const FETCH_MERGED_DOCTOR_TIMETABLE_REQUEST = 'FETCH_MERGED_DOCTOR_TIMETABLE_REQUEST';
export const FETCH_MERGED_DOCTOR_TIMETABLE_SUCCESS = 'FETCH_MERGED_DOCTOR_TIMETABLE_SUCCESS';
export const FETCH_MERGED_DOCTOR_TIMETABLE_ERROR = 'FETCH_MERGED_DOCTOR_TIMETABLE_ERROR';

export const ADD_DOCTOR_EXCEPTION_TIMETABLE_REQUEST = 'ADD_DOCTOR_EXCEPTION_TIMETABLE_REQUEST';
export const ADD_DOCTOR_EXCEPTION_TIMETABLE_SUCCESS = 'ADD_DOCTOR_EXCEPTION_TIMETABLE_SUCCESS';
export const ADD_DOCTOR_EXCEPTION_TIMETABLE_ERROR = 'ADD_DOCTOR_EXCEPTION_TIMETABLE_ERROR';

export const REMOVE_DOCTOR_EXCEPTION_TIMETABLE_REQUEST =
  'REMOVE_DOCTOR_EXCEPTION_TIMETABLE_REQUEST';
export const REMOVE_DOCTOR_EXCEPTION_TIMETABLE_SUCCESS =
  'REMOVE_DOCTOR_EXCEPTION_TIMETABLE_SUCCESS';
export const REMOVE_DOCTOR_EXCEPTION_TIMETABLE_ERROR = 'REMOVE_DOCTOR_EXCEPTION_TIMETABLE_ERROR';

export const PATCH_DOCTOR_EXCEPTION_TIMETABLE_REQUEST = 'PATCH_DOCTOR_EXCEPTION_TIMETABLE_REQUEST';
export const PATCH_DOCTOR_EXCEPTION_TIMETABLE_SUCCESS = 'PATCH_DOCTOR_EXCEPTION_TIMETABLE_SUCCESS';
export const PATCH_DOCTOR_EXCEPTION_TIMETABLE_ERROR = 'PATCH_DOCTOR_EXCEPTION_TIMETABLE_ERROR';

export const ADD_DOCTOR_RECURRENT_TIMETABLE_REQUEST = 'ADD_DOCTOR_RECURRENT_TIMETABLE_REQUEST';
export const ADD_DOCTOR_RECURRENT_TIMETABLE_SUCCESS = 'ADD_DOCTOR_RECURRENT_TIMETABLE_SUCCESS';
export const ADD_DOCTOR_RECURRENT_TIMETABLE_ERROR = 'ADD_DOCTOR_RECURRENT_TIMETABLE_ERROR';

export const REMOVE_DOCTOR_RECURRENT_TIMETABLE_REQUEST =
  'REMOVE_DOCTOR_RECURRENT_TIMETABLE_REQUEST';
export const REMOVE_DOCTOR_RECURRENT_TIMETABLE_SUCCESS =
  'REMOVE_DOCTOR_RECURRENT_TIMETABLE_SUCCESS';
export const REMOVE_DOCTOR_RECURRENT_TIMETABLE_ERROR = 'REMOVE_DOCTOR_RECURRENT_TIMETABLE_ERROR';

export const PATCH_DOCTOR_RECURRENT_TIMETABLE_REQUEST = 'PATCH_DOCTOR_RECURRENT_TIMETABLE_REQUEST';
export const PATCH_DOCTOR_RECURRENT_TIMETABLE_SUCCESS = 'PATCH_DOCTOR_RECURRENT_TIMETABLE_SUCCESS';
export const PATCH_DOCTOR_RECURRENT_TIMETABLE_ERROR = 'PATCH_DOCTOR_RECURRENT_TIMETABLE_ERROR';
