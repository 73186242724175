import { actionChannel, ActionPattern, call, fork, put, take } from 'redux-saga/effects';
import { Action } from 'redux';
import { VIDEO_CALL_GET_TOKEN_REQUEST } from '../types/VideoCallTypes';
import { getVideoCallGetTokenAPI } from '../api/VideoCallApi';
import { videoCallGetTokenError, videoCallGetTokenSuccess } from '../actions/VideoCallActions';
import LocalEventsService from '../../services/LocalEventsService';

function* videoCallGetToken(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const deviceRegIdChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(deviceRegIdChannel);
    try {
      const response = yield call(getVideoCallGetTokenAPI, payload.appointmentId);
      yield put(videoCallGetTokenSuccess(response?.data));
      LocalEventsService.emit('joinVideoCall');
    } catch ({ message }) {
      yield put(videoCallGetTokenError({ message }));
    }
  }
}

function* videoCallSaga() {
  yield fork(videoCallGetToken, VIDEO_CALL_GET_TOKEN_REQUEST);
}

export default videoCallSaga;
