import { AnyAction } from 'redux';
import { FETCH_DOCTOR_REVIEWS_SUCCESS } from 'shared/redux/types/DoctorReviewsTypes';
import { antiDuplication } from 'shared/modules/ArrayUtils';

const DoctorReviewsReducer = (
  state = { data: [], currentPage: 1, noOfPages: undefined },
  action: AnyAction = (() => {}) as unknown as AnyAction,
) => {
  switch (action.type) {
    case FETCH_DOCTOR_REVIEWS_SUCCESS:
      return {
        data: action.payload.nextPage
          ? antiDuplication([...state.data, ...action.payload.data], 'id')
          : action.payload.data,
        noOfPages: action.payload.noOfPages,
        currentPage: action.payload.nextPage ? state.currentPage + 1 : 2,
      };
    default:
      return state;
  }
};

export default DoctorReviewsReducer;
