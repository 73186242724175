import * as PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import * as animationData from 'shared/assets/loader/loading_ringdoc.json';

const LoadingSpinner = ({ isLoading }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return isLoading ? (
    <div className="showbox">
      <div className="loader">
        <Lottie options={defaultOptions} />
      </div>
    </div>
  ) : (
    <div className="tinyBox" />
  );
};

LoadingSpinner.defaultProps = {
  isLoading: false,
};

LoadingSpinner.propTypes = {
  isLoading: PropTypes.bool,
};
export default LoadingSpinner;
