import { AnyAction } from 'redux';
import {
  UPDATE_SERVICES_PRICES_REQUEST,
  UPDATE_SERVICES_PRICES_SUCCESS,
  UPDATE_SERVICES_PRICES_ERROR,
} from 'shared/redux/types/ServicesTypes';

export const updateServicesPricesRequest = (payload): AnyAction => ({
  type: UPDATE_SERVICES_PRICES_REQUEST,
  payload,
});

export const updateServicesPricesSuccess = (payload): AnyAction => ({
  type: UPDATE_SERVICES_PRICES_SUCCESS,
  payload,
});

export const updateServicesPricesError = (payload): AnyAction => ({
  type: UPDATE_SERVICES_PRICES_ERROR,
  payload,
});
