import dayjs from 'dayjs';
import i18next from 'i18next';

const recommendationsSelector = (recommendations) => {
  // eslint-disable-next-line no-underscore-dangle
  const recommendationsArray = recommendations._embedded?.items ?? [];

  const data: Array<Record<string, unknown>> = [];
  // eslint-disable-next-line array-callback-return
  recommendationsArray.map((row) => {
    const translatedSpecialization: any = [];
    row?.appointment?.doctor_specialization?.specialization?.translations?.forEach((r) => {
      const translatedSpec: any = {};
      translatedSpecialization[r.locale] = r.name;
    });
    if (row?.sent_to_patient) {
      data.push({
        id: row?.id,
        symptoms: row?.symptoms,
        knownAllergies: row?.known_allergies,
        ongoingTreatments: row?.ongoing_treatments,
        treatment: row?.treatment,
        recommendations: row?.recommendations,
        diagnosis: row?.diagnosis,
        recommendFutureAppointment: row?.recommend_future_appointment,
        prescriptionsPortfolio: row?.prescriptions_portfolio,
        sentToPatient: row?.sent_to_patient,
        specialization:
          row?.appointment?.doctor_specialization?.specialization?.translations?.find(
            (r) => r.locale === i18next.language,
          )?.name ?? '',
        specializationTranslations: translatedSpecialization,
        appointmentId: row?.appointment?.id,
        doctorFirstName: row?.appointment?.doctor_specialization?.doctor?.first_name,
        doctorLastName: row?.appointment?.doctor_specialization?.doctor?.last_name,
        doctorFullName: row?.appointment?.doctor_specialization?.doctor?.full_name_with_title,
        doctorImageUrl: row?.appointment?.doctor_specialization?.doctor?.picture_media?.public_url,
        createdAt: dayjs(row?.created_at).format('DD MMM YYYY'),
      });
    }
  });
  const { pages, page } = recommendations;
  return { noOfPages: pages, currentPage: page, data };
};

export default recommendationsSelector;
