import { default as ApiConstants } from 'shared/constants/ApiConstants.json';
import ApiUtils from 'shared/modules/ApiUtils';

const registerAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.postRequest(ApiConstants.REGISTER_ENDPOINT, payload);

const UpdateProfileAPI = () => ApiUtils.patchRequest(ApiConstants.LOGOUT_ENDPOINT);

const UpdateDeviceRegIdAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.patchRequest(ApiConstants.UPDATE_DEVICE_REG_ID_ENDPOINT, payload);

export { registerAPI, UpdateProfileAPI, UpdateDeviceRegIdAPI };
