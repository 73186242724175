import { AnyAction } from 'redux';
import {
  GET_SPECIALIZATIONS_ERROR,
  GET_SPECIALIZATIONS_REQUEST,
  GET_SPECIALIZATIONS_SUCCESS,
  GET_SPECIALIZATIONS_HOME_ERROR,
  GET_SPECIALIZATIONS_HOME_REQUEST,
  GET_SPECIALIZATIONS_HOME_SUCCESS,
} from 'shared/redux/types/SpecializationTypes';

export const getSpecializationsRequest = (payload: any): AnyAction => ({
  type: GET_SPECIALIZATIONS_REQUEST,
  payload,
});

export const getSpecializationsSuccess = (payload: any): AnyAction => ({
  type: GET_SPECIALIZATIONS_SUCCESS,
  payload,
});

export const getSpecializationsError = (payload: any): AnyAction => ({
  type: GET_SPECIALIZATIONS_ERROR,
  payload,
});

export const getSpecializationsHomeRequest = (payload: any): AnyAction => ({
  type: GET_SPECIALIZATIONS_HOME_REQUEST,
  payload,
});

export const getSpecializationsHomeSuccess = (payload: any): AnyAction => ({
  type: GET_SPECIALIZATIONS_HOME_SUCCESS,
  payload,
});

export const getSpecializationsHomeError = (payload: any): AnyAction => ({
  type: GET_SPECIALIZATIONS_HOME_ERROR,
  payload,
});
