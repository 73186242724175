import * as PropTypes from 'prop-types';
import Colors from '../themes/Colors';

const Avatar = ({ imageURL, firstName, lastName, size }) => {
  if (!imageURL) {
    return (
      <div
        className="user-image"
        style={{
          width: size,
          height: size,
          marginRight: 10,
          backgroundColor: Colors.border,
          borderRadius: 100,
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <p
          className="user-text"
          style={{
            color: 'black',
            marginBottom: 0,
            lineHeight: 1,
            fontSize: 12,
            textTransform: 'uppercase',
          }}
        >{`${firstName?.charAt(0)}${lastName?.charAt(0)}`}</p>
      </div>
    );
  }
  return (
    <div
      className="user-image"
      style={{ width: size, height: size, borderRadius: 100, marginRight: 10 }}
    >
      <div
        className="avatar-picture"
        style={{
          backgroundImage: `url(${imageURL})`,
          width: size,
          height: size,
          borderRadius: 100,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }}
      />
    </div>
  );
};

Avatar.defaultProps = {
  imageURL: undefined,
  firstName: 'unknown',
  lastName: 'unknown',
  size: 80,
};

Avatar.propTypes = {
  imageURL: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  size: PropTypes.number,
};

export default Avatar;
