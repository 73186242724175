import { actionChannel, call, fork, put, take } from 'redux-saga/effects';
import {
  applyVoucherAPI,
  getValidVouchersAPI,
  getVouchersAPI,
  removeVoucherAPI,
} from 'shared/redux/api/VouchersApi';
import {
  applyVoucherError,
  applyVoucherSuccess,
  fetchValidVouchersError,
  fetchValidVouchersRequest,
  fetchValidVouchersSuccess,
  fetchVouchersError,
  fetchVouchersSuccess,
  locallyChooseVoucher,
  removeVoucherError,
  removeVoucherSuccess,
} from 'shared/redux/actions/VouchersActions';
import {
  APPLY_VOUCHER_REQUEST,
  FETCH_VALID_VOUCHERS_REQUEST,
  FETCH_VOUCHERS_REQUEST,
  REMOVE_VOUCHER_REQUEST,
} from 'shared/redux/types/VouchersTypes';
import { vouchersSelector } from 'shared/redux/selector/VouchersSelector';
import { getPatientAppointmentDetailsRequest } from 'shared/redux/actions/PatientAppointmentActions';
import NavigationService from 'shared/services/NavigationService';

function* getVouchers(actionType) {
  const vouchersChannel = yield actionChannel(actionType);
  while (true) {
    try {
      yield take(vouchersChannel);
      const response = yield call(getVouchersAPI);
      yield put(fetchVouchersSuccess(vouchersSelector(response.data)));
    } catch ({ message }) {
      yield put(fetchVouchersError({ message }));
    }
  }
}

function* getValidVouchers(actionType) {
  const validVouchersChannel = yield actionChannel(actionType);
  while (true) {
    try {
      const { payload } = yield take(validVouchersChannel);
      const response = yield call(getValidVouchersAPI, payload);
      yield put(fetchValidVouchersSuccess(vouchersSelector(response.data)));
    } catch ({ message }) {
      yield put(fetchValidVouchersError({ message }));
    }
  }
}

function* applyVoucher(actionType) {
  const applyVoucherChannel = yield actionChannel(actionType);
  while (true) {
    try {
      const { payload } = yield take(applyVoucherChannel);
      const response = yield call(applyVoucherAPI, payload);
      yield put(applyVoucherSuccess(response));
      yield put(fetchValidVouchersRequest({ id: payload.id }));
      yield put(
        locallyChooseVoucher({ code: payload.data?.voucher, voucherName: payload.name ?? '' }),
      );
      yield put(getPatientAppointmentDetailsRequest({ id: payload.id }));
    } catch ({ message }) {
      yield put(applyVoucherError({ message }));
    }
  }
}

function* removeVoucher(actionType) {
  const removeVoucherChannel = yield actionChannel(actionType);
  while (true) {
    try {
      const { payload } = yield take(removeVoucherChannel);
      const response = yield call(removeVoucherAPI, payload);
      yield put(removeVoucherSuccess(response));
      yield put(fetchValidVouchersRequest({ id: payload.id }));
      yield put(locallyChooseVoucher({}));
      yield put(getPatientAppointmentDetailsRequest({ id: payload.id }));
    } catch ({ message }) {
      yield put(removeVoucherError({ message }));
    }
  }
}

function* vouchersSaga() {
  yield fork(getVouchers, FETCH_VOUCHERS_REQUEST);
  yield fork(getValidVouchers, FETCH_VALID_VOUCHERS_REQUEST);
  yield fork(applyVoucher, APPLY_VOUCHER_REQUEST);
  yield fork(removeVoucher, REMOVE_VOUCHER_REQUEST);
}

export default vouchersSaga;
