import { AnyAction } from 'redux';
import {
  APPOINTMENT_BOOK_ERROR,
  APPOINTMENT_BOOK_REQUEST,
  APPOINTMENT_BOOK_SUCCESS,
  APPOINTMENT_CALL_NOW_REQUEST,
  APPOINTMENT_CALL_NOW_SUCCESS,
  APPOINTMENT_CALL_NOW_ERROR,
  APPOINTMENT_CANCEL_ERROR,
  APPOINTMENT_CANCEL_REQUEST,
  APPOINTMENT_CANCEL_SUCCESS,
  APPOINTMENT_DELETE_ERROR,
  APPOINTMENT_DELETE_REQUEST,
  APPOINTMENT_DELETE_SUCCESS,
  APPOINTMENT_EMERGENCY_ERROR,
  APPOINTMENT_EMERGENCY_REQUEST,
  APPOINTMENT_EMERGENCY_SUCCESS,
  APPOINTMENT_SCHEDULE_REQUEST,
  APPOINTMENT_SCHEDULE_SUCCESS,
  APPOINTMENT_SCHEDULE_ERROR,
  APPOINTMENT_SELECT_PROFILE_ERROR,
  APPOINTMENT_SELECT_PROFILE_REQUEST,
  APPOINTMENT_SELECT_PROFILE_SUCCESS,
  FETCH_PATIENT_PAST_APPOINTMENTS_ERROR,
  FETCH_PATIENT_PAST_APPOINTMENTS_REQUEST,
  FETCH_PATIENT_PAST_APPOINTMENTS_SUCCESS,
  FETCH_PATIENT_UPCOMING_APPOINTMENTS_ERROR,
  FETCH_PATIENT_UPCOMING_APPOINTMENTS_REQUEST,
  FETCH_PATIENT_UPCOMING_APPOINTMENTS_SUCCESS,
  GET_PATIENT_APPOINTMENT_DETAILS_REQUEST,
  GET_PATIENT_APPOINTMENT_DETAILS_SUCCESS,
  GET_PATIENT_APPOINTMENT_DETAILS_ERROR,
  APPOINTMENT_LOCAL_STEPS_REQUEST,
  APPOINTMENT_ADD_REVIEW_REQUEST,
  APPOINTMENT_ADD_REVIEW_SUCCESS,
  APPOINTMENT_ADD_REVIEW_ERROR,
  APPOINTMENT_SHARE_MEDICAL_RECORD_REQUEST,
  APPOINTMENT_SHARE_MEDICAL_RECORD_SUCCESS,
  APPOINTMENT_SHARE_MEDICAL_RECORD_ERROR,
  APPOINTMENT_CALL_SPECIALIST_REQUEST,
  APPOINTMENT_CALL_SPECIALIST_SUCCESS,
  APPOINTMENT_CALL_SPECIALIST_ERROR,
  SET_PENDING_APPOINTMENT,
} from 'shared/redux/types/PatientAppointmentTypes';

export const appointmentScheduleRequest = (payload): AnyAction => ({
  type: APPOINTMENT_SCHEDULE_REQUEST,
  payload,
});

export const appointmentScheduleSuccess = (payload): AnyAction => ({
  type: APPOINTMENT_SCHEDULE_SUCCESS,
  payload,
});

export const appointmentScheduleError = (payload): AnyAction => ({
  type: APPOINTMENT_SCHEDULE_ERROR,
  payload,
});

export const callNowAppointmentRequest = (payload): AnyAction => ({
  type: APPOINTMENT_CALL_NOW_REQUEST,
  payload,
});

export const callNowAppointmentSuccess = (payload): AnyAction => ({
  type: APPOINTMENT_CALL_NOW_SUCCESS,
  payload,
});

export const callNowAppointmentError = (payload): AnyAction => ({
  type: APPOINTMENT_CALL_NOW_ERROR,
  payload,
});

export const emergencyAppointmentRequest = (payload): AnyAction => ({
  type: APPOINTMENT_EMERGENCY_REQUEST,
  payload,
});

export const emergencyAppointmentSuccess = (payload): AnyAction => ({
  type: APPOINTMENT_EMERGENCY_SUCCESS,
  payload,
});

export const emergencyAppointmentError = (payload): AnyAction => ({
  type: APPOINTMENT_EMERGENCY_ERROR,
  payload,
});

export const callSpecialistAppointmentRequest = (payload): AnyAction => ({
  type: APPOINTMENT_CALL_SPECIALIST_REQUEST,
  payload,
});

export const callSpecialistAppointmentSuccess = (payload): AnyAction => ({
  type: APPOINTMENT_CALL_SPECIALIST_SUCCESS,
  payload,
});

export const callSpecialistAppointmentError = (payload): AnyAction => ({
  type: APPOINTMENT_CALL_SPECIALIST_ERROR,
  payload,
});

export const appointmentSelectProfileRequest = (payload): AnyAction => ({
  type: APPOINTMENT_SELECT_PROFILE_REQUEST,
  payload,
});

export const appointmentSelectProfileSuccess = (payload): AnyAction => ({
  type: APPOINTMENT_SELECT_PROFILE_SUCCESS,
  payload,
});

export const appointmentSelectProfileError = (payload): AnyAction => ({
  type: APPOINTMENT_SELECT_PROFILE_ERROR,
  payload,
});

export const appointmentDeleteRequest = (payload): AnyAction => ({
  type: APPOINTMENT_DELETE_REQUEST,
  payload,
});

export const appointmentDeleteSuccess = (payload): AnyAction => ({
  type: APPOINTMENT_DELETE_SUCCESS,
  payload,
});

export const appointmentDeleteError = (payload): AnyAction => ({
  type: APPOINTMENT_DELETE_ERROR,
  payload,
});

export const appointmentBookRequest = (payload): AnyAction => ({
  type: APPOINTMENT_BOOK_REQUEST,
  payload,
});

export const appointmentBookSuccess = (payload): AnyAction => ({
  type: APPOINTMENT_BOOK_SUCCESS,
  payload,
});

export const appointmentBookError = (payload): AnyAction => ({
  type: APPOINTMENT_BOOK_ERROR,
  payload,
});

// export const appointmentCancelSearchRequest = (payload): AnyAction => ({
//   type: APPOINTMENT_CANCEL_SEARCH_REQUEST,
//   payload,
// });
//
// export const appointmentCancelSearchSuccess = (payload): AnyAction => ({
//   type: APPOINTMENT_CANCEL_SEARCH_SUCCESS,
//   payload,
// });
//
// export const appointmentCancelSearchError = (payload): AnyAction => ({
//   type: APPOINTMENT_CANCEL_SEARCH_ERROR,
//   payload,
// });

export const appointmentCancelRequest = (payload): AnyAction => ({
  type: APPOINTMENT_CANCEL_REQUEST,
  payload,
});

export const appointmentCancelSuccess = (payload): AnyAction => ({
  type: APPOINTMENT_CANCEL_SUCCESS,
  payload,
});

export const appointmentCancelError = (payload): AnyAction => ({
  type: APPOINTMENT_CANCEL_ERROR,
  payload,
});

export const appointmentLocalStepsRequest = (payload): AnyAction => ({
  type: APPOINTMENT_LOCAL_STEPS_REQUEST,
  payload,
});

export const fetchPatientPastAppointmentsRequest = (payload): AnyAction => ({
  type: FETCH_PATIENT_PAST_APPOINTMENTS_REQUEST,
  payload,
});

export const fetchPatientPastAppointmentsSuccess = (payload): AnyAction => ({
  type: FETCH_PATIENT_PAST_APPOINTMENTS_SUCCESS,
  payload,
});

export const fetchPatientPastAppointmentsError = (payload): AnyAction => ({
  type: FETCH_PATIENT_PAST_APPOINTMENTS_ERROR,
  payload,
});

export const fetchPatientUpcomingAppointmentsRequest = (payload): AnyAction => ({
  type: FETCH_PATIENT_UPCOMING_APPOINTMENTS_REQUEST,
  payload,
});

export const fetchPatientUpcomingAppointmentsSuccess = (payload): AnyAction => ({
  type: FETCH_PATIENT_UPCOMING_APPOINTMENTS_SUCCESS,
  payload,
});

export const fetchPatientUpcomingAppointmentsError = (payload): AnyAction => ({
  type: FETCH_PATIENT_UPCOMING_APPOINTMENTS_ERROR,
  payload,
});

export const getPatientAppointmentDetailsRequest = (payload): AnyAction => ({
  type: GET_PATIENT_APPOINTMENT_DETAILS_REQUEST,
  payload,
});

export const getPatientAppointmentDetailsSuccess = (payload): AnyAction => ({
  type: GET_PATIENT_APPOINTMENT_DETAILS_SUCCESS,
  payload,
});

export const getPatientAppointmentDetailsError = (payload): AnyAction => ({
  type: GET_PATIENT_APPOINTMENT_DETAILS_ERROR,
  payload,
});

export const appointmentAddReviewRequest = (payload): AnyAction => ({
  type: APPOINTMENT_ADD_REVIEW_REQUEST,
  payload,
});

export const appointmentAddReviewSuccess = (payload): AnyAction => ({
  type: APPOINTMENT_ADD_REVIEW_SUCCESS,
  payload,
});

export const appointmentAddReviewError = (payload): AnyAction => ({
  type: APPOINTMENT_ADD_REVIEW_ERROR,
  payload,
});

export const appointmentShareMedicalRecordRequest = (payload): AnyAction => ({
  type: APPOINTMENT_SHARE_MEDICAL_RECORD_REQUEST,
  payload,
});

export const appointmentShareMedicalRecordSuccess = (payload): AnyAction => ({
  type: APPOINTMENT_SHARE_MEDICAL_RECORD_SUCCESS,
  payload,
});

export const appointmentShareMedicalRecordError = (payload): AnyAction => ({
  type: APPOINTMENT_SHARE_MEDICAL_RECORD_ERROR,
  payload,
});

export const setPendingAppointment = (payload): AnyAction => ({
  type: SET_PENDING_APPOINTMENT,
  payload,
});
