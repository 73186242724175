import { actionChannel, call, fork, put, select, take } from 'redux-saga/effects';
import i18next from 'i18next';
import GCStorageService from 'shared/services/GCStorageService';
import NavigationService from 'shared/services/NavigationService';
import {
  ADD_FAMILY_MEMBER_DISEASE_REQUEST,
  ADD_FAMILY_MEMBER_REQUEST,
  DELETE_FAMILY_MEMBER_DISEASE_REQUEST,
  GET_FAMILY_MEMBERS_REQUEST,
  GET_PROFILE_REQUEST,
  REMOVE_FAMILY_MEMBER_REQUEST,
  UPDATE_FAMILY_MEMBER_DISEASE_REQUEST,
  UPDATE_FAMILY_MEMBER_REQUEST,
  UPDATE_PROFILE_REQUEST,
} from 'shared/redux/types/FamilyMembersTypes';
import {
  addFamilyMemberAPI,
  addFamilyMemberDiseaseAPI,
  getFamilyMembersAPI,
  removeFamilyMemberAPI,
  removeFamilyMemberDiseaseAPI,
  updateFamilyMemberProfileAPI,
  getProfileDetailsAPI,
  updateProfileDetailsAPI,
  updateFamilyMemberDiseaseAPI,
} from 'shared/redux/api/FamilyMembersApi';
import {
  addFamilyMemberDiseaseError,
  addFamilyMemberDiseaseSuccess,
  addFamilyMemberError,
  addFamilyMemberSuccess,
  deleteFamilyMemberDiseaseError,
  deleteFamilyMemberDiseaseSuccess,
  getFamilyMembersError,
  getFamilyMembersRequest,
  getFamilyMembersSuccess,
  getProfileError,
  getProfileRequest,
  getProfileSuccess,
  removeFamilyMemberError,
  removeFamilyMemberSuccess,
  updateFamilyMemberDiseaseError,
  updateFamilyMemberDiseaseSuccess,
  updateFamilyMemberError,
  updateFamilyMemberSuccess,
  updateProfileError,
  updateProfileSuccess,
} from 'shared/redux/actions/FamilyMembersActions';
import { familyMemberSelector } from 'shared/redux/selector/FamilyMemeberSelector';
import { getPatientProfileState, getAuthState } from 'shared/redux/src/StatesGetter';
import Alerts from 'shared/components/Alerts';
import {
  fetchPatientProfileRequest,
  locallyCurrentPatientProfile,
} from 'shared/redux/actions/PatientProfileActions';
import { profileDetailsSelector } from 'shared/redux/selector/ProfileDetailsSelector';
import ApiUtils from 'shared/modules/ApiUtils';
import { handleExtraLoginResponse } from 'shared/modules/AuthUtils';
import { updateSharedStateAction } from 'shared/redux/actions/UngroupedActions';

function* getFamilyMembers(actionType) {
  const getFamilyMembersChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(getFamilyMembersChannel);
    try {
      const response = yield call(getFamilyMembersAPI);
      const { id: userId } = yield select(getPatientProfileState);
      const formattedResponse = familyMemberSelector(response.data, userId);
      yield put(getFamilyMembersSuccess(formattedResponse));
    } catch ({ message }) {
      yield put(getFamilyMembersError({ message }));
    }
  }
}

function* getProfileDetails(actionType) {
  const getProfileDetailsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(getProfileDetailsChannel);
    try {
      const response = yield call(getProfileDetailsAPI, payload);
      const profileSelector = profileDetailsSelector(response.data);
      yield put(getProfileSuccess(profileSelector));
      yield put(locallyCurrentPatientProfile(profileSelector));
    } catch ({ message }) {
      yield put(getProfileError({ message }));
    }
  }
}

function* updateProfileDetails(actionType) {
  const updateProfileDetailsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(updateProfileDetailsChannel);
    try {
      const response = yield call(updateProfileDetailsAPI, {
        id: payload.id,
        data: payload.toUpdate,
      });
      if (
        payload.toUpdate.picture &&
        response?.data?.picture_media &&
        response.data.picture_media?.gcloud_upload_policy_v4?.url &&
        payload.toUpload
      ) {
        yield call(
          GCStorageService.uploadToGCWithSignedUrl,
          response.data.picture_media.gcloud_upload_policy_v4.url,
          response.data.picture_media.gcloud_upload_policy_v4.fields,
          payload.toUpload,
        );
      }
      const profileSelector = profileDetailsSelector(response.data);
      yield put(updateProfileSuccess(profileSelector));
      yield put(fetchPatientProfileRequest({}));
      yield put(locallyCurrentPatientProfile(profileSelector));
      const { isProfileCompleted } = yield select(getAuthState);
      if (isProfileCompleted === false) {
        yield call(ApiUtils.doRefreshToken);
      }
      const isProfiled = response.data?.is_patient;
      yield call(handleExtraLoginResponse, isProfileCompleted ? 'true' : 'Not Completed', 'true');
      yield put(
        updateSharedStateAction({
          isLoggedIn: true,
          isConfirmed: true,
          isProfileCompleted: isProfiled,
          lastUpdate: isProfiled ? 'justNow' : '',
        }),
      );
      if (isProfiled) {
        // NavigationService.navigate('HomeStackNavigator', { screen: 'MainScreen' });
      }
      Alerts.simpleAlert(`${i18next.t('success')}`, `${i18next.t('alerts.profileChanged')}`);
    } catch ({ message }) {
      yield put(updateProfileError({ message }));
    }
  }
}

function* addFamilyMember(actionType) {
  const addFamilyMemberChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(addFamilyMemberChannel);
    try {
      const response = yield call(addFamilyMemberAPI, payload.toAdd);
      if (
        payload.toAdd.picture &&
        response?.data?.picture_media &&
        response.data.picture_media?.gcloud_upload_policy_v4?.url &&
        payload.toUpload
      ) {
        yield call(
          GCStorageService.uploadToGCWithSignedUrl,
          response.data.picture_media.gcloud_upload_policy_v4.url,
          response.data.picture_media.gcloud_upload_policy_v4.fields,
          payload.toUpload,
        );
      }
      yield put(addFamilyMemberSuccess(response.data));
      yield put(getFamilyMembersRequest({}));
      if (payload?.goBack) {
        NavigationService.goBack();
      } else {
        yield put(getProfileRequest({ id: response.data.id }));
        NavigationService.navigate('/patient/medicalFolder', { state: 'profile' });
      }
    } catch ({ message }) {
      yield put(addFamilyMemberError({ message }));
    }
  }
}

// use updateProfileDetails
function* updateFamilyMember(actionType) {
  const updateFamilyMemberChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(updateFamilyMemberChannel);
    try {
      const response = yield call(updateFamilyMemberProfileAPI, payload.toUpdate);
      if (
        payload.toUpdate.data.picture &&
        response?.data?.picture_media &&
        response.data.picture_media?.gcloud_upload_policy_v4?.url &&
        payload.toUpload
      ) {
        yield call(
          GCStorageService.uploadToGCWithSignedUrl,
          response.data.picture_media.gcloud_upload_policy_v4.url,
          response.data.picture_media.gcloud_upload_policy_v4.fields,
          payload.toUpload,
        );
      }
      const { id: userId } = yield select(getPatientProfileState);
      yield put(updateFamilyMemberSuccess(familyMemberSelector([response.data], userId)[0]));
      yield put(getFamilyMembersRequest({}));
      Alerts.simpleAlert(`${i18next.t('success')}`, `${i18next.t('alerts.dataSaved')}`);
      if (payload?.goBack) {
        NavigationService.goBack();
      }
    } catch ({ message }) {
      yield put(updateFamilyMemberError({ message }));
    }
  }
}

function* removeFamilyMember(actionType) {
  const removeFamilyMemberChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(removeFamilyMemberChannel);
    try {
      const response = yield call(removeFamilyMemberAPI, payload);
      yield put(removeFamilyMemberSuccess(response.data));
      yield put(getFamilyMembersRequest({}));
    } catch ({ message }) {
      yield put(removeFamilyMemberError({ message }));
    }
  }
}

function* addFamilyMemberDisease(actionType) {
  const addFamilyMemberDiseaseChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(addFamilyMemberDiseaseChannel);
    try {
      yield call(addFamilyMemberDiseaseAPI, payload.toRequest);
      yield put(addFamilyMemberDiseaseSuccess({}));
      yield put(getFamilyMembersRequest({}));
      yield put(getProfileRequest({ id: payload.toRequest.patientId }));
    } catch ({ message }) {
      yield put(addFamilyMemberDiseaseError({ message }));
    }
  }
}

function* updateFamilyMemberDisease(actionType) {
  const updateFamilyMemberDiseaseChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(updateFamilyMemberDiseaseChannel);
    try {
      yield call(updateFamilyMemberDiseaseAPI, payload.toUpdate);
      yield put(updateFamilyMemberDiseaseSuccess({}));
      yield put(getFamilyMembersRequest({}));
      yield put(getProfileRequest({ id: payload.toUpdate.patientId }));
    } catch ({ message }) {
      yield put(updateFamilyMemberDiseaseError({ message }));
    }
  }
}

function* deleteFamilyMemberDisease(actionType) {
  const deleteFamilyMemberDiseaseChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(deleteFamilyMemberDiseaseChannel);
    try {
      yield call(removeFamilyMemberDiseaseAPI, payload);
      yield put(deleteFamilyMemberDiseaseSuccess({}));
      yield put(getProfileRequest({ id: payload.patientId }));
    } catch ({ message }) {
      yield put(deleteFamilyMemberDiseaseError({ message }));
    }
  }
}

function* familyMembersSaga() {
  yield fork(getFamilyMembers, GET_FAMILY_MEMBERS_REQUEST);
  yield fork(addFamilyMember, ADD_FAMILY_MEMBER_REQUEST);
  yield fork(updateFamilyMember, UPDATE_FAMILY_MEMBER_REQUEST);
  yield fork(removeFamilyMember, REMOVE_FAMILY_MEMBER_REQUEST);
  yield fork(addFamilyMemberDisease, ADD_FAMILY_MEMBER_DISEASE_REQUEST);
  yield fork(deleteFamilyMemberDisease, DELETE_FAMILY_MEMBER_DISEASE_REQUEST);
  yield fork(updateFamilyMemberDisease, UPDATE_FAMILY_MEMBER_DISEASE_REQUEST);
  yield fork(getProfileDetails, GET_PROFILE_REQUEST);
  yield fork(updateProfileDetails, UPDATE_PROFILE_REQUEST);
}

export default familyMembersSaga;
