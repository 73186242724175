import { actionChannel, call, fork, put, take } from 'redux-saga/effects';
import { getMyReviewsAPI } from 'shared/redux/api/MyReviewsApi';
import { fetchMyReviewsError, fetchMyReviewsSuccess } from 'shared/redux/actions/MyReviewsActions';
import myReviewsSelector from 'shared/redux/selector/MyReviewsSelector';
import { FETCH_MY_REVIEWS_REQUEST } from 'shared/redux/types/MyReviewsTypes';
import StorageService from '../../services/StorageService';

function* getMyReviews(actionType) {
  const doctorReviewsChannel = yield actionChannel(actionType);
  let isPharmacist = 'false';
  StorageService.getData('IS_PHARMACIST', 'false').then((response) => {
    isPharmacist = response;
  });
  while (true) {
    const { payload } = yield take(doctorReviewsChannel);
    try {
      const safeAction = {
        limit: 5,
        page: 1,
        ...payload,
      };
      let response = { data: { pages: '' } };
      if (isPharmacist === 'false') {
        response = yield call(getMyReviewsAPI, safeAction);
      }
      yield put(
        fetchMyReviewsSuccess({
          data: myReviewsSelector(response.data),
          noOfPages: response.data.pages,
          page: safeAction.page,
        }),
      );
    } catch ({ message }) {
      yield put(fetchMyReviewsError({ message }));
    }
  }
}

function* myReviewsSaga() {
  yield fork(getMyReviews, FETCH_MY_REVIEWS_REQUEST);
}

export default myReviewsSaga;
