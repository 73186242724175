import { default as ApiConstants } from 'shared/constants/ApiConstants.json';
import ApiUtils from 'shared/modules/ApiUtils';

const getVouchersAPI = () => ApiUtils.getRequest(ApiConstants.VOUCHERS_ENDPOINT);

const getValidVouchersAPI = (payload) =>
  ApiUtils.getRequest(`${ApiConstants.VOUCHERS_ENDPOINT}/${payload?.id}`);

const applyVoucherAPI = (payload) =>
  ApiUtils.postRequest(
    `${ApiConstants.PATIENT_APPOINTMENT_ENDPOINT}/${payload.id}/vouchers/add`,
    payload.data,
  );

const removeVoucherAPI = (payload) =>
  ApiUtils.postRequest(
    `${ApiConstants.PATIENT_APPOINTMENT_ENDPOINT}/${payload.id}/vouchers/remove`,
  );

export { getVouchersAPI, getValidVouchersAPI, applyVoucherAPI, removeVoucherAPI };
