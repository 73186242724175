import 'patient/styles/account-referral.css';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { translatedDate } from 'shared/modules/DateTimeUtils';
import { useTranslation } from 'react-i18next';
import __ from 'lodash/fp/__';
import React from 'react';
import Referral from '../../components/Referral';

const PatientAccountInviteAFriendBlock = ({ invitation }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="account-my-vouchers">
      <div className="title-section">
        <h2>{t('referralInviteAFriendHeader')}</h2>
      </div>
      <div key="invitationCode" className="voucher-box">
        <div className="code invitation-code">{invitation}</div>
        <div className="description mt-3">{t('referralInviteAFriendDescription1')}</div>
        <div className="description mt-3">{t('referralInviteAFriendDescription2')}</div>
        <Referral invitationCode={invitation} />
      </div>
    </div>
  );
};

export default PatientAccountInviteAFriendBlock;
