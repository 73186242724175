import { Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  addDoctorRecurrentTimetableRequest,
  patchDoctorRecurrentTimetableRequest,
} from 'shared/redux/actions/DoctorTimetableActions';
import { generateTimeSlots, translatedDayOfWeek } from 'shared/modules/DateTimeUtils';
import { useTranslation } from 'react-i18next';
import Utils from '../../../shared/modules/Utils';

const AddAvailabilityModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    setModalAddIntervalShow,
    setCurrentAddAvailabilityModal,
    showModalAddInterval,
    currentAddAvailabilityModal,
  } = props;

  const { type = 'add', action = 'server', data = { id: 0 } } = currentAddAvailabilityModal;
  const modalAddIntervalClose = () => {
    setModalAddIntervalShow(false);
    setCurrentAddAvailabilityModal({ type: '', data: {} });
  };
  const [startHour, setStartHour] = useState(
    Utils.getTypeAddExceptionOrAvailability(data, 15, 'HH', type),
  );
  const [startMin, setStartMin] = useState(
    Utils.getTypeAddExceptionOrAvailability(data, 15, 'mm', type),
  );
  const [endHour, setEndHour] = useState(
    Utils.getTypeAddExceptionOrAvailability(data, 45, 'HH', type),
  );
  const [endMin, setEndMin] = useState(
    Utils.getTypeAddExceptionOrAvailability(data, 45, 'mm', type),
  );

  useEffect(() => {
    setStartHour(Utils.getTypeAddExceptionOrAvailability(data, 15, 'HH', type));
    setStartMin(Utils.getTypeAddExceptionOrAvailability(data, 15, 'mm', type));
    setEndHour(Utils.getTypeAddExceptionOrAvailability(data, 45, 'HH', type));
    setEndMin(Utils.getTypeAddExceptionOrAvailability(data, 45, 'mm', type));
  }, [type]);
  const onTimeSelected = (dateType, timePart, slot) => {
    if (dateType === 'start') {
      if (timePart === 'hour') {
        setStartHour(slot);
      } else {
        setStartMin(slot);
      }
      return;
    }
    if (timePart === 'hour') {
      setEndHour(slot);
    } else {
      setEndMin(slot);
    }
  };
  const addTimetable = () => {
    dispatch(
      addDoctorRecurrentTimetableRequest({
        toRequest: {
          dayOfWeek: data.dayOfWeek,
          timeStart: `${startHour}:${startMin}`,
          timeStop: `${endHour}:${endMin}`,
        },
        action,
      }),
    );
    modalAddIntervalClose();
  };
  const editTimetable = (id) => {
    dispatch(
      patchDoctorRecurrentTimetableRequest({
        toRequest: {
          id,
          data: { timeStart: `${startHour}:${startMin}`, timeStop: `${endHour}:${endMin}` },
        },
      }),
    );
    modalAddIntervalClose();
  };
  return (
    <Modal
      show={showModalAddInterval}
      onHide={modalAddIntervalClose}
      animation
      centered
      className="modal-add-interval medic-modal"
    >
      <Modal.Header closeButton closeLabel={t('close2')}>
        <Modal.Title>{type === 'add' ? t('addInterval') : t('editTheInterval')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="day">{translatedDayOfWeek(data.dayOfWeek)}</div>
        <div className="subtitle">{t('dayInterval')}</div>
        <div className="interval-hour">
          <div className="form-select-label">
            <select
              onChange={(event) => onTimeSelected('start', 'hour', event.target.value)}
              value={startHour}
              required
              id="interval1"
              name="interval1"
              className="custom-select"
            >
              {generateTimeSlots(24).map((h) => {
                return <option key={`interval1_${h}`}>{h}</option>;
              })}
            </select>
          </div>
          <span>:</span>
          <div className="form-select-label">
            <select
              onChange={(event) => onTimeSelected('start', 'min', event.target.value)}
              value={startMin}
              required
              id="interval2"
              name="interval2"
              className="custom-select"
            >
              {generateTimeSlots(60).map((h) => {
                return <option key={`interval2_${h}`}>{h}</option>;
              })}
            </select>
          </div>
          <span>-</span>
          <div className="form-select-label">
            <select
              onChange={(event) => onTimeSelected('end', 'hour', event.target.value)}
              value={endHour}
              required
              id="interval3"
              name="interval3"
              className="custom-select"
            >
              {generateTimeSlots(24).map((h) => {
                return <option key={`interval3_${h}`}>{h}</option>;
              })}
            </select>
          </div>
          <span>:</span>
          <div className="form-select-label">
            <select
              onChange={(event) => onTimeSelected('end', 'min', event.target.value)}
              value={endMin}
              required
              id="interval4"
              name="interval4"
              className="custom-select"
            >
              {generateTimeSlots(60).map((h) => {
                return <option key={`interval4_${h}`}>{h}</option>;
              })}
            </select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="green-button"
          onClick={(e) => (type === 'add' ? addTimetable() : editTimetable(data?.id))}
        >
          {type === 'add' ? t('addInterval') : t('editTheInterval')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddAvailabilityModal;
