import { AnyAction } from 'redux';
import { FETCH_COUNTRIES_SUCCESS } from 'shared/redux/types/CountryTypes';

const CountryReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case FETCH_COUNTRIES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default CountryReducer;
