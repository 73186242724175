import _ from 'lodash';

const translationsSelector = (translations) => {
  const languageTranslations = {
    ro: {},
    en: {},
  };

  Object.entries(translations).forEach(([index, content]) => {
    // @ts-ignore
    _.set(languageTranslations.ro, index, content.ro);
    // @ts-ignore
    _.set(languageTranslations.en, index, content.en);
  });

  return languageTranslations;
};

export { translationsSelector };
