import React from 'react';
import { useInView } from 'react-intersection-observer';

const LazyImage = ({ width, src, height, alt, hidden = false, ...rest }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px',
  });

  return (
    <div
      ref={ref}
      style={{
        position: 'relative',
        paddingBottom: `${(height / width) * 100}%`,
      }}
      className={`lazy-image-wrapper ${hidden ? 'hidden' : ''}`}
    >
      {inView ? (
        <img
          {...rest}
          src={src}
          width={width}
          height={height}
          loading="lazy"
          style={{ position: 'absolute', width: '100%', height: '100%', left: '0', top: '0' }}
          alt={alt}
        />
      ) : null}
    </div>
  );
};

export default LazyImage;
