import 'shared/styles/static-pages.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getPrivacyPolicyState } from 'shared/redux/src/StatesGetter';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import { getPrivacyPolicyRequest } from 'shared/redux/actions/StaticPagesActions';
import FooterSection from 'shared/components/FooterSection';
import HeaderMenuMobile from '../../components/HeaderMenuMobile';
import HeaderMenu from '../../components/HeaderMenu';

const SettingsPrivacyPolicy = () => {
  const { t } = useTranslation();
  const devRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { name, content } = useSelector(getPrivacyPolicyState, shallowEqual);
  const goToBlog = () => window.open(`https://blog.ringdoc.ro`, '_self');
  useEffect(() => {
    dispatch(getPrivacyPolicyRequest({}));
    if (devRef.current) devRef.current.innerHTML = content;
  }, [content]);
  return (
    <div className="privacy-page">
      <Helmet>
        <title>{t('contactPage.titlePage')}</title>
        <meta
          name="description"
          content="Completeaza formularul si cere detalii despre Ringdoc si modul de functionare al aplicatiei chiar acum"
        />
      </Helmet>

      <div className="container-fluid">
        <div className="row">
          <HeaderMenu />

          <main className="container">
            <section className="static-pages">
              <div className="title-section">
                {/* <Link to="/doctor/account" className="back-to-account"> */}
                {/*   <CustomIcon */}
                {/*     className="custom-icon" */}
                {/*     color={Colors.darkGrey} */}
                {/*     size="24" */}
                {/*     icon="Left_arrow" */}
                {/*   /> */}
                {/*   Înapoi la Cont */}
                {/* </Link> */}
                <h1>{name}</h1>
              </div>

              <div ref={devRef} />
            </section>
          </main>

          <FooterSection />
        </div>
      </div>
    </div>
  );
};

export default SettingsPrivacyPolicy;
