import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import AppointmentConclusion from '../../screens/appointment/AppointmentConclusion';
import {
  getDoctorAppointmentDetailsState,
  getUserProfileState,
} from '../../../shared/redux/src/StatesGetter';
import Avatar from '../../../shared/components/Avatar';
import Utils from '../../../shared/modules/Utils';
import CustomIcon from '../../../shared/components/CustomIcon';
import Colors from '../../../shared/themes/Colors';
import Prescription from '../appointments/Prescription';
import { getAppointmentDetailsRequest } from '../../../shared/redux/actions/DoctorAppointmentsActions';
import { getEnv } from '../../../shared/services/EnvService';

const DoctorConsultationDetailsTabRecommendationPrescription = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const doctor = useSelector(getUserProfileState, shallowEqual);
  const {
    patient,
    user,
    appointmentType = { duration: 0, type: '' },
    medicalRecords,
    conclusion,
    serverStatus,
    id: appointmentId,
    specializationName,
  } = useSelector(getDoctorAppointmentDetailsState, shallowEqual);
  const { isResident = false } = useSelector(getUserProfileState, shallowEqual);

  const { firstName = '', lastName = '', picture, birthDate } = patient ?? user ?? {};

  const renderSubTitle = () => {
    if (Utils.calculateAge(birthDate) > 0) {
      if (
        medicalRecords?.length &&
        ['call_ended', 'completed'].indexOf(serverStatus) > -1 &&
        !isResident
      ) {
        return (
          <div className="age">
            {Utils.calculateAge(birthDate) > 0 && `${Utils.calculateAge(birthDate)} ani • `}
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="15"
              icon="Document"
            />
            {t('attachedFiles')}
          </div>
        );
      }
      return <div className="age">{`${Utils.calculateAge(birthDate)} ani`}</div>;
    }
    return null;
  };
  const activeKey = 'prescription';
  const redirectToConsultationDetailsRecords = () => {
    navigate(`/doctor/consultation-details/${appointmentId}?t=records`, {
      state: {
        templateType: 'past',
      },
    });
  };
  const redirectToConsultationDetailsRecomandations = () => {
    navigate(`/doctor/consultation-details/${appointmentId}?t=r`, {
      state: {
        templateType: 'past',
      },
    });
  };
  const renderTabNav = () => {
    return (
      <Tab.Container id="consultation-details-tabs-menu" defaultActiveKey={activeKey}>
        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link onClick={redirectToConsultationDetailsRecords}>{t('records')}</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link onClick={redirectToConsultationDetailsRecomandations}>
              {t('recommendation')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="prescription">{t('prescription.title')}</Nav.Link>
          </Nav.Item>
        </Nav>
      </Tab.Container>
    );
  };
  const { prescription } = useSelector(getDoctorAppointmentDetailsState, shallowEqual);
  const [addPrescription, setAddPrescription] = useState(true);
  const [addNewMedication, setAddNewMedication] = useState(false);
  const [prescriptionSaved, setPrescriptionSaved] = useState(false);
  const [currentMedication, setCurrentMedication] = useState({
    id: '',
    name: '',
    concentration: '',
    administration: '',
    quantity: '',
    duration: '',
    type: '',
  });

  const makeAddPrescriptionFalse = () => {
    dispatch(getAppointmentDetailsRequest({ id: appointmentId }));
    setTimeout(() => {
      if (localStorage.getItem('SAVED_PRESCRIPTION') === 'true') {
        navigate(`/doctor/consultation-details/${appointmentId}?t=r`, {
          state: {
            templateType: 'past',
          },
        });
      }
      // window.location.href = `/doctor/consultation-details/${appointmentId}?t=r`;
    }, 1000);
  };

  const removePrescription = () => {
    setAddPrescription(false);
    setTimeout(() => {
      dispatch(getAppointmentDetailsRequest({ id: appointmentId }));

      if (location.pathname.indexOf('/doctor/consultation-details') === 0) {
        navigate(`/doctor/consultation-details/${prescription.id}?t=r`, {
          state: {
            templateType: 'past',
          },
        });
        // window.location.href = `/doctor/final-consultation/${appointmentId}/review-conclusions`;
      }
    }, 1000);
  };

  const savePrescriptionAddMedication = () => {
    dispatch(getAppointmentDetailsRequest({ id: appointmentId }));
    setPrescriptionSaved(true);
    navigate(`/doctor/consultation-details/${prescription.id}/medication`, {
      state: {
        templateType: 'past',
      },
    });
  };

  return (
    <section className="consultation-details">
      <div className="title-section">
        <h1>{t('history.details')}</h1>
      </div>
      <div className="small-container">
        <div className="patient-box">
          <Avatar size={40} imageURL={picture} firstName={firstName} lastName={lastName} />
          <div className="info">
            <div className="fullName">{`${firstName} ${lastName}`}</div>
            <div className="requested-specialization">{specializationName}</div>
            {renderSubTitle()}
          </div>
        </div>
        {renderTabNav()}
        <Prescription
          prescription={prescription}
          doctor={doctor}
          serverStatus={serverStatus}
          appointmentId={appointmentId}
          setAddPrescription={setAddPrescription}
          addNewMedication={addNewMedication}
          setAddNewMedication={setAddNewMedication}
          makeAddPrescriptionFalse={makeAddPrescriptionFalse}
          removePrescription={removePrescription}
          savePrescriptionAddMedication={savePrescriptionAddMedication}
          setCurrentMedication={setCurrentMedication}
          currentMedication={currentMedication}
        />
      </div>
    </section>
  );
};

export default DoctorConsultationDetailsTabRecommendationPrescription;
