export const FETCH_PATIENT_PROFILE_REQUEST = 'FETCH_PATIENT_PROFILE_REQUEST';
export const FETCH_PATIENT_PROFILE_SUCCESS = 'FETCH_PATIENT_PROFILE_SUCCESS';
export const FETCH_PATIENT_PROFILE_ERROR = 'FETCH_PATIENT_PROFILE_ERROR';

export const PATCH_PATIENT_PROFILE_REQUEST = 'PATCH_PATIENT_PROFILE_REQUEST';
export const PATCH_PATIENT_PROFILE_SUCCESS = 'PATCH_PATIENT_PROFILE_SUCCESS';
export const PATCH_PATIENT_PROFILE_ERROR = 'PATCH_PATIENT_PROFILE_ERROR';

export const POST_PATIENT_PROFILE_REQUEST = 'POST_PATIENT_PROFILE_REQUEST';
export const POST_PATIENT_PROFILE_SUCCESS = 'POST_PATIENT_PROFILE_SUCCESS';
export const POST_PATIENT_PROFILE_ERROR = 'POST_PATIENT_PROFILE_ERROR';

export const LOCALLY_UPDATE_PATIENT_PROFILE = 'LOCALLY_UPDATE_PATIENT_PROFILE';
export const LOCALLY_CURRENT_PATIENT_PROFILE = 'LOCALLY_CURRENT_PATIENT_PROFILE';

export const PATIENT_ADD_MEDICAL_RECORD_REQUEST = 'PATIENT_ADD_MEDICAL_RECORD_REQUEST';
export const PATIENT_ADD_MEDICAL_RECORD_SUCCESS = 'PATIENT_ADD_MEDICAL_RECORD_SUCCESS';
export const PATIENT_ADD_MEDICAL_RECORD_ERROR = 'PATIENT_ADD_MEDICAL_RECORD_ERROR';

export const PATIENT_UPDATE_MEDICAL_RECORD_REQUEST = 'PATIENT_UPDATE_MEDICAL_RECORD_REQUEST';
export const PATIENT_UPDATE_MEDICAL_RECORD_SUCCESS = 'PATIENT_UPDATE_MEDICAL_RECORD_SUCCESS';
export const PATIENT_UPDATE_MEDICAL_RECORD_ERROR = 'PATIENT_UPDATE_MEDICAL_RECORD_ERROR';

export const PATIENT_DELETE_MEDICAL_RECORD_REQUEST = 'PATIENT_DELETE_MEDICAL_RECORD_REQUEST';
export const PATIENT_DELETE_MEDICAL_RECORD_SUCCESS = 'PATIENT_DELETE_MEDICAL_RECORD_SUCCESS';
export const PATIENT_DELETE_MEDICAL_RECORD_ERROR = 'PATIENT_DELETE_MEDICAL_RECORD_ERROR';

export const GET_PATIENT_MEDICAL_RECORDS_REQUEST = 'GET_PATIENT_MEDICAL_RECORDS_REQUEST';
export const GET_PATIENT_MEDICAL_RECORDS_SUCCESS = 'GET_PATIENT_MEDICAL_RECORDS_SUCCESS';
export const GET_PATIENT_MEDICAL_RECORDS_ERROR = 'GET_PATIENT_MEDICAL_RECORDS_ERROR';

export const GET_PATIENT_VOUCHERS_REQUEST = 'GET_PATIENT_VOUCHERS_REQUEST';
export const GET_PATIENT_VOUCHERS_SUCCESS = 'GET_PATIENT_VOUCHERS_SUCCESS';
export const GET_PATIENT_VOUCHERS_ERROR = 'GET_PATIENT_VOUCHERS_ERROR';
