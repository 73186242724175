import { default as ApiConstants } from 'shared/constants/ApiConstants.json';
import ApiUtils from 'shared/modules/ApiUtils';

const getFavouriteDoctorsAPI = ({ page, limit }) =>
  ApiUtils.getRequest(`${ApiConstants.GET_FAVOURITE_DOCTORS_ENDPOINT}?page=${page}&limit=${limit}`);

const addFavouriteDoctorAPI = (payload) =>
  ApiUtils.postRequest(`${ApiConstants.ADD_FAVOURITE_DOCTOR_ENDPOINT}/${payload}`, payload);

const removeFavouriteDoctorAPI = (payload) =>
  ApiUtils.deleteRequest(`${ApiConstants.REMOVE_FAVOURITE_DOCTOR_ENDPOINT}/${payload}`, payload);

export { getFavouriteDoctorsAPI, addFavouriteDoctorAPI, removeFavouriteDoctorAPI };
