import 'doctor/styles/auth.css';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { requestLogout } from 'shared/redux/actions/AuthActions';
import { useTranslation } from 'react-i18next';
import { fetchCurrencyRequest } from 'shared/redux/actions/CurrencyActions';
import { fetchUserProfileRequest } from 'shared/redux/actions/UserProfileActions';
import DoctorAccountProfileTabInvoice from 'doctor/components/account/DoctorAccountProfileTabInvoice';

const DoctorRegisterInvoice = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCurrencyRequest({}));
    dispatch(fetchUserProfileRequest({}));
  }, []);
  const onLogOut = useCallback(() => {
    dispatch(requestLogout({}));
  }, []);
  return (
    <section className="register-steps">
      <div className="title-section">
        <div className="steps">{t('step')} 2/3</div>
        <h1>{t('billing')}</h1>
      </div>
      <DoctorAccountProfileTabInvoice type="unprofiled" />
    </section>
  );
};

export default DoctorRegisterInvoice;
