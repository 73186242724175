import 'shared/styles/link-redirect.css';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { isAndroid, isIOS, isMacOs, isMobile } from 'react-device-detect';
import RingdocMedicIcon from 'shared/assets/icons/ringdoc-medic-icon.svg';
import RingdocPatientIcon from 'shared/assets/icons/ringdoc-patient-icon.svg';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getPushesState } from '../redux/src/StatesGetter';
import { requestLogout } from '../redux/actions/AuthActions';
import { isDoctor } from '../services/EnvService';
import { getAboutUsRequest } from '../redux/actions/StaticPagesActions';

const LinkRedirectScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (isIOS && !isMacOs && isMobile) {
    if (isDoctor(true)) {
      window.location.replace('itms-apps://apps.apple.com/us/app/ringdoc-medic/id1574488917');
    } else {
      window.location.replace('itms-apps://apps.apple.com/us/app/ringdoc/id1574488837');
    }
  }

  if (isAndroid && isMobile) {
    if (isDoctor(true)) {
      window.location.replace(
        'https://play.google.com/store/apps/details?id=ro.ascendnet.alodoc.doctor.ro',
      );
    } else {
      window.location.replace(
        'https://play.google.com/store/apps/details?id=ro.ascendnet.alodoc.patient.ro',
      );
    }
  }

  return (
    <div className="link-redirect d-flex flex-column min-vh-100">
      <div className="container-fluid d-flex flex-column min-vh-100">
        <div className="row d-flex flex-column min-vh-100">
          <main className="container-fluid mt-auto mb-auto">
            <section>
              <img src={isDoctor(true) ? RingdocMedicIcon : RingdocPatientIcon} alt="Ringdoc" />
              <p>{t('mlm.donwloadApp')}</p>
              <div className="apps-button">
                {isIOS && !isMacOs && (
                  <a
                    target="_blank"
                    href={
                      isDoctor(true)
                        ? 'itms-apps://apps.apple.com/us/app/ringdoc-medic/id1574488917'
                        : 'itms-apps://apps.apple.com/us/app/ringdoc/id1574488837'
                    }
                    rel="noreferrer"
                  >
                    <i className="app-store-icon cursor-pointer" />
                  </a>
                )}
                {isAndroid && (
                  <a
                    target="_blank"
                    href={
                      isDoctor(true)
                        ? 'https://play.google.com/store/apps/details?id=ro.ascendnet.alodoc.doctor.ro'
                        : 'https://play.google.com/store/apps/details?id=ro.ascendnet.alodoc.patient.ro'
                    }
                    rel="noreferrer"
                  >
                    <i className="google-play-icon cursor-pointer" />
                  </a>
                )}
                {!isIOS && !isAndroid && (
                  <>
                    <a
                      target="_blank"
                      href={
                        isDoctor(true)
                          ? 'https://apps.apple.com/us/app/ringdoc-medic/id1574488917'
                          : 'https://apps.apple.com/us/app/ringdoc/id1574488837'
                      }
                      rel="noreferrer"
                    >
                      <i className="app-store-icon cursor-pointer" />
                    </a>
                    <a
                      target="_blank"
                      href={
                        isDoctor(true)
                          ? 'https://play.google.com/store/apps/details?id=ro.ascendnet.alodoc.doctor.ro'
                          : 'https://play.google.com/store/apps/details?id=ro.ascendnet.alodoc.patient.ro'
                      }
                      rel="noreferrer"
                    >
                      <i className="google-play-icon cursor-pointer" />
                    </a>
                  </>
                )}
              </div>
            </section>
            <div className="push" />
          </main>
        </div>
      </div>
    </div>
  );
};

export default LinkRedirectScreen;
