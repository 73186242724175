import { shallowEqual, useSelector } from 'react-redux';
import { getAuthState } from 'shared/redux/src/StatesGetter';
import LanguageNavSelector from 'patient/components/LanguageNavSelector';
import React from 'react';
import _ from 'lodash';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import Fonts from 'shared/themes/Fonts';
import i18next from 'i18next';
import { NavLink, useLocation } from 'react-router-dom';

const PatientTopNavMob = ({ unseen }) => {
  const location = useLocation();
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);

  let notificationNumber;
  let notificationClass;
  if (unseen <= 9) {
    notificationNumber = <div className="number">{unseen}</div>;
    notificationClass = 'count';
  } else if (unseen > 100) {
    notificationNumber = <div className="number">99+</div>;
    notificationClass = 'count plus';
  } else {
    notificationNumber = <div className="number">{unseen}</div>;
    notificationClass = 'count big';
  }

  return (
    isLoggedIn && (
      <div className="top-navbar-mobile">
        <LanguageNavSelector key="LANGUAGE_SELECTOR" />
        <NavLink
          key="PATIENT_NOTIFICATIONS_MENU"
          to="/patient/notifications"
          className={({ isActive }) => {
            let finalClassName = 'small-notifications';
            if (isActive || _.startsWith(location.pathname, '/patient/notifications', 0)) {
              finalClassName += ' active';
            }
            return finalClassName;
          }}
        >
          <CustomIcon
            className="custom-icon"
            color={Colors.menuColor}
            size={Fonts.s32}
            icon="Notifications"
          />
          {!!unseen && unseen !== '0' && (
            <div className={notificationClass}>{notificationNumber}</div>
          )}
        </NavLink>
      </div>
    )
  );
};

export default PatientTopNavMob;
