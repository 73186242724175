import 'doctor/styles/account-profile.css';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  geocodeByAddress,
} from 'react-google-places-autocomplete';
import PropTypes from 'prop-types';
import { getCurrencyState, getUserProfileState } from 'shared/redux/src/StatesGetter';
import Utils from 'shared/modules/Utils';
import Alerts from 'shared/components/Alerts';
import { patchDoctorOrganizationRequest } from 'shared/redux/actions/UserProfileActions';
import { getEnv } from 'shared/services/EnvService';
import { useTranslation } from 'react-i18next';

const DoctorAccountProfileTabInvoice = ({ type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currencies = useSelector(getCurrencyState, shallowEqual);
  const profileData = useSelector(getUserProfileState, shallowEqual);
  const {
    organizationName,
    organizationUIN,
    organizationCRN,
    organizationBank,
    organizationIban,
    organizationCurrency,
    organizationAddress,
    isOrganizationOwner,
    roles,
  } = profileData;
  const [address, setAddress] = useState(organizationAddress?.address_as_string ?? '');
  const [rawAddress, setRawAddress] = useState<any>('');
  const [companyName, setCompanyName] = useState(organizationName ?? '');
  const [cif, setCif] = useState(organizationUIN ?? '');
  const [companyRegistration, setCompanyRegistration] = useState(organizationCRN ?? '');
  const [bank, setBank] = useState(organizationBank ?? '');
  const [companyAccount, setCompanyAccount] = useState(organizationIban ?? '');
  const [currencyId, setCurrencyId] = useState(
    Utils.getCurrencyIdByCode(currencies, 'code', organizationCurrency, 'id'),
  );
  useEffect(() => {
    if (type !== 'unprofiled') {
      setCompanyName(organizationName);
      setCif(organizationUIN);
      setCompanyRegistration(organizationCRN);
      setBank(organizationBank);
      setCompanyAccount(organizationIban);
      if (type === 'profiled' && organizationAddress?.address_as_string) {
        setAddress(organizationAddress?.address_as_string);
      }
    }
  }, [profileData]);

  const submitOrganization = (_rawAddress) => {
    if ((type === 'unprofiled' && !_rawAddress) || (type === 'profiled' && !_rawAddress)) {
      Alerts.simpleAlert(`${t('error')}`, `${t('addressFieldMandatory')}`);
      return;
    }

    if (!companyName) {
      Alerts.simpleAlert(
        `${t('error')}`,
        `${t('contactPage.missingFieldValue', {
          field: t('account.companyName'),
        })}`,
      );
      return;
    }

    if (!cif) {
      Alerts.simpleAlert(
        `${t('error')}`,
        `${t('contactPage.missingFieldValue', {
          field: t('account.cif'),
        })}`,
      );
      return;
    }

    if (!bank) {
      Alerts.simpleAlert(
        `${t('error')}`,
        `${t('account.bankValidation', {
          field: t('account.companyName'),
        })}`,
      );
      return;
    }

    if (!companyAccount) {
      Alerts.simpleAlert(
        `${t('error')}`,
        `${t('contactPage.missingFieldValue', {
          field: t('account.iban'),
        })}`,
      );
      return;
    }

    dispatch(
      patchDoctorOrganizationRequest({
        data: {
          name: companyName,
          uniqueIdentificationNumber: cif,
          commercialRegistrationNumber: companyRegistration,
          bankName: bank,
          iban: companyAccount,
          currency: currencyId,
          rawAddress:
            typeof _rawAddress !== 'string'
              ? JSON.stringify(_rawAddress)
              : _rawAddress || undefined,
        },
        withNavigation: true,
        type,
      }),
    );
  };

  const saveOrganization = async (e) => {
    e.preventDefault();
    if (typeof rawAddress !== 'object') {
      try {
        await geocodeByAddress(organizationAddress?.address_as_string)
          .then((results) => {
            submitOrganization(results[0]);
            setRawAddress(results[0]);
          })
          .catch((error) => {
            Alerts.simpleAlert(`${t('error')}`, `${t('addressFieldCompleted')}`);
            console.error(error);
            console.error(typeof rawAddress);
            console.error(profileData.address?.address_as_string ?? address);
          });
      } catch (err) {
        Alerts.simpleAlert(`${t('error')}`, `${t('addressFieldCompleted')}`);
        console.error(err);
      }
    } else {
      submitOrganization(rawAddress);
    }
  };

  let hasPermission = true;
  if (type !== 'unprofiled' && !isOrganizationOwner) {
    hasPermission = false;
  }
  let btnText = t('nextStep');
  if (type === 'profiled') {
    if (!isOrganizationOwner) {
      btnText = t('notAllowedToSaveChanges');
    } else {
      btnText = t('saveChanges');
    }
  }
  return (
    <>
      <div className="subtitle">{t('doctorApp.fiscalData')}</div>
      <div className="form-input">
        <input
          autoComplete="off"
          id="name-company"
          name="name-company"
          type="text"
          className="form-control"
          placeholder=" "
          readOnly={!hasPermission}
          value={companyName}
          onChange={(e) => setCompanyName(e.target?.value)}
        />
        <label htmlFor="name-company">{t('account.companyName')}</label>
      </div>
      <div className="form-input">
        <input
          autoComplete="off"
          id="cif-cui"
          name="cif-cui"
          type="text"
          className="form-control"
          placeholder=" "
          readOnly={!hasPermission}
          value={cif}
          onChange={(e) => setCif(e.target?.value)}
        />
        <label htmlFor="cif-cui">{t('account.cif')}</label>
      </div>
      <div className="form-input">
        <input
          autoComplete="off"
          id="nr-reg-com"
          name="nr-reg-com"
          type="text"
          className="form-control"
          placeholder=" "
          readOnly={!hasPermission}
          value={companyRegistration}
          onChange={(e) => setCompanyRegistration(e.target?.value)}
        />
        <label htmlFor="nr-reg-com">{t('account.companyRegistration')}</label>
      </div>
      <div className="subtitle">{t('doctorApp.bankData')}</div>
      <div className="form-input">
        <input
          autoComplete="off"
          id="bank"
          name="bank"
          type="text"
          className="form-control"
          placeholder=" "
          readOnly={!hasPermission}
          value={bank}
          onChange={(e) => setBank(e.target?.value)}
        />
        <label htmlFor="bank">{t('account.bank')}</label>
      </div>
      <div className="form-select-label">
        <select
          onChange={(e) => setCurrencyId(e?.target?.value)}
          required
          id="currency"
          name="currency"
          className="custom-select"
          disabled={!hasPermission}
          value={currencyId}
        >
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <option value=" " />
          {currencies.length ? (
            currencies.map((currency) => {
              return (
                <option key={currency.id} value={currency.id}>
                  {currency.code}
                </option>
              );
            })
          ) : (
            <option>{t('account.noCurrency')}</option>
          )}
        </select>
        <label htmlFor="currency">{t('account.currency')}</label>
      </div>
      <div className="form-input">
        <input
          autoComplete="off"
          id="iban"
          name="iban"
          type="text"
          className="form-control"
          placeholder=" "
          readOnly={!hasPermission}
          value={companyAccount}
          onChange={(e) => setCompanyAccount(e?.target?.value)}
        />
        <label htmlFor="iban">{t('account.iban')}</label>
      </div>
      <div className="subtitle">{t('doctorApp.companyData')}</div>
      <div className="address-google-places-autocomplete">
        <GooglePlacesAutocomplete
          apiKey={getEnv('GOOGLE_PLACES_API_KEY')}
          apiOptions={{ language: 'ro', region: 'ro' }}
          minLengthAutocomplete={3}
          selectProps={{
            placeholder: t('address'),
            defaultInputValue: address,
            onChange: (o) => {
              geocodeByPlaceId(o.value.place_id)
                .then((results) => setRawAddress(results[0]))
                .catch((error) => console.error(error));
            },
          }}
        />
        <label htmlFor="react-select-3-input">{t('address')}</label>
      </div>
      <button
        type="button"
        onClick={saveOrganization}
        disabled={!hasPermission}
        className="green-button"
      >
        {btnText}
      </button>
    </>
  );
};

DoctorAccountProfileTabInvoice.defaultProps = {
  type: 'profiled',
};

DoctorAccountProfileTabInvoice.propTypes = {
  type: PropTypes.string,
};

export default DoctorAccountProfileTabInvoice;
