import 'patient/styles/appointment-steps.css';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import {
  getFamilyMembersState,
  getLocalCurrentPatientProfileState,
  getPatientAppointmentDetailsState,
  getPatientAppointmentState,
  getPatientLocalChooseSpecializationState,
  getPatientProfileState,
} from 'shared/redux/src/StatesGetter';
import {
  appointmentCancelRequest,
  appointmentLocalStepsRequest,
  appointmentSelectProfileRequest,
  getPatientAppointmentDetailsRequest,
} from 'shared/redux/actions/PatientAppointmentActions';
import { locallyCurrentPatientProfile } from 'shared/redux/actions/PatientProfileActions';
import { getFamilyMembersRequest } from 'shared/redux/actions/FamilyMembersActions';
import { useTranslation } from 'react-i18next';
import Alerts from 'shared/components/Alerts';

const Profile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const patientProfile = useSelector(getPatientProfileState, shallowEqual);
  const selectedProfile = useSelector(getLocalCurrentPatientProfileState, shallowEqual);
  const familyMembers = useSelector(getFamilyMembersState, shallowEqual);
  const { name: specializationName, title = '' } = useSelector(
    getPatientLocalChooseSpecializationState,
    shallowEqual,
  );
  const {
    id: appointmentId,
    noOfSteps,
    type: appointmentType,
    service: appointmentService,
  } = useSelector(getPatientAppointmentState, shallowEqual);
  const { serverStatus: appointmentStatus } = useSelector(
    getPatientAppointmentDetailsState,
    shallowEqual,
  );

  const service = appointmentService ?? 'consultation';

  const stepString = `${t('step')} 2/4`;
  const onProfileClick = (e, profile) => {
    dispatch(locallyCurrentPatientProfile(profile));
    navigate('/patient/appointment/medical-documents');
  };

  const cancelAppointment = () => {
    Alerts.okCancelAlert(t('warning'), t('validations.cancelCallSpecialis'), () => {
      dispatch(appointmentCancelRequest({ appointmentId }));
      dispatch(
        appointmentLocalStepsRequest({
          newAppointment: true,
        }),
      );
      navigate('/patient/medicList');
    });
  };

  useEffect(() => {
    dispatch(getFamilyMembersRequest({}));

    if (appointmentId) {
      dispatch(getPatientAppointmentDetailsRequest({ id: appointmentId }));
    }

    if (_.isEmpty(selectedProfile)) {
      dispatch(locallyCurrentPatientProfile(patientProfile));
    }
  }, []);

  return (
    <section className="call-specialist">
      <div className="title-section">
        <div className="steps">
          <div className="icon-wrapper" onClick={() => navigate(-1)}>
            <CustomIcon
              className="custom-icon"
              color={Colors.darkGrey}
              size="24"
              icon="Left_arrow"
            />
          </div>
          {stepString}
          <div className="cancel-button">
            <button type="button" onClick={cancelAppointment}>
              {t('abort')}
            </button>
          </div>
        </div>
        <h1>{t('profile')}</h1>
      </div>
      <div className="text-section">{`${t('profileScreenSubtitle', {
        serviceType: t(service),
      })} ${specializationName}`}</div>
      <div className="family-members">
        {familyMembers.map((member) => (
          <div className="profile-item" key={member.id} onClick={(e) => onProfileClick(e, member)}>
            <div className="profile-box">
              <div
                className="avatar"
                style={
                  member?.imageUrl
                    ? { backgroundImage: `url(${member?.imageUrl})` }
                    : { backgroundColor: '#E5E7E8' }
                }
              >
                {!member?.imageUrl && (
                  <div className="image-placeholder">{`${member?.firstName?.charAt(
                    0,
                  )}${member?.lastName?.charAt(0)}`}</div>
                )}
              </div>
              <div className="info">
                <div className="profile-name">{member.fullNameWithTitle}</div>
                <div className="profile-details">
                  {member.age === 0
                    ? t('medicalProfile.under_a_year')
                    : member.age && `${member.age} ${t('years')} - ${member.type}`}
                </div>
              </div>
            </div>

            <CustomIcon
              className="custom-icon"
              color={Colors.darkGrey}
              size="24"
              icon="Right_pointer"
            />
          </div>
        ))}

        <button
          type="button"
          className="white-button"
          onClick={() =>
            navigate('/patient/medicalFolder/add-profile', { state: { goBack: true } })
          }
        >
          <CustomIcon className="custom-icon" color={Colors.darkGrey} size="24" icon="Plus" />
          {t('addProfile')}
        </button>
        <div className="info-message dark">
          <CustomIcon
            className="custom-icon"
            color={Colors.mediumGrey}
            size="24"
            icon="Warning_2"
          />
          {t('profileScreenFooterWarn')}
        </div>
      </div>
    </section>
  );
};
export default Profile;
