import 'patient/styles/account-cards.css';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import CVVExampleImage from 'patient/assets/images/cvv-example-image.png';
import {
  removePaymentMethodRequest,
  savePaymentMethodRequest,
} from 'shared/redux/actions/PatientPaymentActions';
import { useTranslation } from 'react-i18next';
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  CardElement,
} from '@stripe/react-stripe-js';
import stripeElementOptions from 'shared/settings/StripeElementOptions';
import Alerts from 'shared/components/Alerts';
import Utils from 'shared/modules/Utils';

const PatientAccountCardsBlock = ({ paymentMethods }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const elements = useElements();
  const stripe = useStripe();
  const [showModalAddCard, setModalAddCardShow] = useState(false);
  const [checkedAsMainCard, setCheckedAsMainCard] = useState(false);
  const [cardHolderName, setCardHolderName] = useState('');
  const closeModalAddCard = () => setModalAddCardShow(false);
  const savePaymentMethod = async () => {
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardNumberElement);

    if (!card) {
      return;
    }

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card,
      billing_details: {
        name: cardHolderName,
      },
    });

    if (error) {
      Alerts.simpleAlert(`${t('error')}`, t(`stripe.${error.code}`));
      console.warn(error);
      return;
    }

    dispatch(
      savePaymentMethodRequest({
        forRequest: paymentMethod,
      }),
    );

    setModalAddCardShow(false);
    setCardHolderName('');
  };
  const handleCardHolderName = (e) => setCardHolderName(e.target.value);
  const modalAddCardShow = () => setModalAddCardShow(true);
  const removePaymentMethod = (id, uniqueId) =>
    Alerts.okCancelAlert(`${t('info')}`, `${t('wallet.deleteCard')}`, () => {
      dispatch(
        removePaymentMethodRequest({
          forRequest: { paymentMethodId: id },
          uniqueId,
        }),
      );
    });

  return (
    <div className="account-cards">
      <div className="title-section">
        <h2>{t('wallet.cards')}</h2>
      </div>

      <button type="button" className="white-button" onClick={modalAddCardShow}>
        <CustomIcon className="custom-icon" color={Colors.darkGrey} size="24" icon="Plus" />
        {t('wallet.addCard')}
      </button>

      <div className="saved-cards">
        {paymentMethods.cards.map((card) => {
          const cardImage = Utils.solveImageLogoByType(card.brand);

          return (
            <div className="card-box" key={card.id}>
              <div className="details">
                {cardImage ? <img src={cardImage} alt={card.brand} /> : null}

                <div className="info">
                  <div className="number">•••• {card.last4}</div>
                  <div className="date">{`${card.expiryMonth} / ${card.expiryYear}`}</div>
                </div>
              </div>

              <div onClick={() => removePaymentMethod(card.id, card.uniqueId)}>
                <CustomIcon
                  className="custom-icon cursor-pointer"
                  color={Colors.mediumGrey}
                  size="24"
                  icon="Delete"
                />
              </div>
            </div>
          );
        })}
      </div>

      <Modal
        show={showModalAddCard}
        onHide={closeModalAddCard}
        animation
        centered
        className="modal-add-card"
      >
        <Modal.Header closeButton closeLabel={t('close')}>
          <Modal.Title>{t('wallet.addCard')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-input StripeElementContainer">
              <CardNumberElement id="number-card" options={stripeElementOptions} />
              <label htmlFor="number-card">{t('wallet.numberCard')}</label>
            </div>

            <div className="row rowp5">
              <div className="col-6 colp5">
                <div className="form-input StripeElementContainer">
                  <CardExpiryElement id="expiration" options={stripeElementOptions} />
                  <label htmlFor="expiration">{t('wallet.llaa')}</label>
                </div>
              </div>

              <div className="col-6 colp5">
                <div className="form-input StripeElementContainer">
                  <CardCvcElement id="cvv" options={stripeElementOptions} />
                  <label htmlFor="cvv">CVC</label>
                </div>
              </div>
            </div>

            <div className="form-input">
              <input
                autoComplete="off"
                id="name-card"
                name="name-card"
                type="text"
                className="form-control"
                placeholder=" "
                value={cardHolderName}
                onChange={handleCardHolderName}
              />
              <label htmlFor="name-card">{t('wallet.nameCard')}</label>
            </div>

            <div className="custom-control custom-checkbox orange-checkbox">
              <input
                className="custom-control-input"
                id="set_as_main_card"
                type="checkbox"
                checked={checkedAsMainCard}
                onChange={() => setCheckedAsMainCard(!checkedAsMainCard)}
              />
              <label htmlFor="set_as_main_card" className="custom-control-label">
                {t('wallet.mainCardCheckbox')}
              </label>
            </div>

            <div className="info-message">
              <CustomIcon
                className="custom-icon"
                color={Colors.mediumGrey}
                size="24"
                icon="Warning_2"
              />
              <div>
                {t('wallet.cvcText')}
                <img src={CVVExampleImage} alt="CVC Example" />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="orange-button" onClick={savePaymentMethod}>
            {t('wallet.addCard')}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PatientAccountCardsBlock;
