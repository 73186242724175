import { actionChannel, call, fork, put, take } from 'redux-saga/effects';
import { getDoctorProfileAPI } from 'shared/redux/api/DoctorProfileApi';
import {
  fetchDoctorProfileError,
  fetchDoctorProfileSuccess,
} from 'shared/redux/actions/DoctorProfileActions';
import { FETCH_DOCTOR_PROFILE_REQUEST } from 'shared/redux/types/DoctorProfileTypes';
import doctorProfileSelector from 'shared/redux/selector/DoctorProfileSelector';

function* getDoctorProfile(actionType) {
  const doctorProfileChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(doctorProfileChannel);
    try {
      const response = yield call(getDoctorProfileAPI, payload.id);
      yield put(fetchDoctorProfileSuccess(doctorProfileSelector(response.data)));
    } catch ({ message }) {
      yield put(fetchDoctorProfileError({ message }));
    }
  }
}

function* doctorProfileSaga() {
  yield fork(getDoctorProfile, FETCH_DOCTOR_PROFILE_REQUEST);
}

export default doctorProfileSaga;
