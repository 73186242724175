import { actionChannel, ActionPattern, call, fork, put, take } from 'redux-saga/effects';
import { Action } from 'redux';
import {
  GET_ABOUT_US_REQUEST,
  GET_PRIVACY_POLICY_REQUEST,
  GET_STRIPE_TERMS_REQUEST,
  GET_TERMS_REQUEST,
  GET_GDPR_REQUEST,
  GET_TWILIO_TERMS_REQUEST,
} from 'shared/redux/types/StaticPagesTypes';
import {
  getAboutUsAPI,
  getPrivacyPolicyAPI,
  getStripeTermsAPI,
  getTermsAPI,
  getGDPRAPI,
  getTwilioTermsAPI,
} from 'shared/redux/api/StaticPagesApi';
import {
  getAboutUsError,
  getAboutUsSuccess,
  getPrivacyPolicyError,
  getPrivacyPolicySuccess,
  getStripeTermsError,
  getStripeTermsSuccess,
  getTermsError,
  getTermsSuccess,
  getTwilioTermsError,
  getTwilioTermsSuccess,
  getGDPRError,
  getGDPRSuccess,
} from 'shared/redux/actions/StaticPagesActions';
import { staticPagesSelector } from 'shared/redux/selector/StaticPagesSelector';

function* getAboutUs(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const aboutUsChannel = yield actionChannel(actionType);
  while (true) {
    yield take(aboutUsChannel);
    try {
      // @ts-ignore
      const response = yield call(getAboutUsAPI);
      yield put(getAboutUsSuccess(staticPagesSelector(response.data)));
    } catch ({ message }) {
      yield put(getAboutUsError({ message }));
    }
  }
}
function* getPrivacyPolicy(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const privacyPolicyChannel = yield actionChannel(actionType);
  while (true) {
    yield take(privacyPolicyChannel);
    try {
      // @ts-ignore
      const response = yield call(getPrivacyPolicyAPI);
      yield put(getPrivacyPolicySuccess(staticPagesSelector(response.data)));
    } catch ({ message }) {
      yield put(getPrivacyPolicyError({ message }));
    }
  }
}
function* getTerms(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const termsChannel = yield actionChannel(actionType);
  while (true) {
    yield take(termsChannel);
    try {
      // @ts-ignore
      const response = yield call(getTermsAPI);
      yield put(getTermsSuccess(staticPagesSelector(response.data)));
    } catch ({ message }) {
      yield put(getTermsError({ message }));
    }
  }
}
function* getGDPR(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const GDPRChannel = yield actionChannel(actionType);
  while (true) {
    yield take(GDPRChannel);
    try {
      // @ts-ignore
      const response = yield call(getGDPRAPI);
      yield put(getGDPRSuccess(staticPagesSelector(response.data)));
    } catch ({ message }) {
      yield put(getGDPRError({ message }));
    }
  }
}
function* getStripeTerms(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const stripeTermsChannel = yield actionChannel(actionType);
  while (true) {
    yield take(stripeTermsChannel);
    try {
      // @ts-ignore
      const response = yield call(getStripeTermsAPI);
      yield put(getStripeTermsSuccess(staticPagesSelector(response.data)));
    } catch ({ message }) {
      yield put(getStripeTermsError({ message }));
    }
  }
}
function* getTwilioTerms(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const twilioTermsChannel = yield actionChannel(actionType);
  while (true) {
    yield take(twilioTermsChannel);
    try {
      // @ts-ignore
      const response = yield call(getTwilioTermsAPI);
      yield put(getTwilioTermsSuccess(staticPagesSelector(response.data)));
    } catch ({ message }) {
      yield put(getTwilioTermsError({ message }));
    }
  }
}

function* staticPagesSaga() {
  yield fork(getAboutUs, GET_ABOUT_US_REQUEST);
  yield fork(getPrivacyPolicy, GET_PRIVACY_POLICY_REQUEST);
  yield fork(getTerms, GET_TERMS_REQUEST);
  yield fork(getGDPR, GET_GDPR_REQUEST);
  yield fork(getStripeTerms, GET_STRIPE_TERMS_REQUEST);
  yield fork(getTwilioTerms, GET_TWILIO_TERMS_REQUEST);
}

export default staticPagesSaga;
