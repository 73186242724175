import { AnyAction } from 'redux';
import {
  FETCH_DOCTORS_ALL_INDEX_SUCCESS,
  FETCH_REFERRAL_BONUS_SUCCESS,
  FETCH_REFERRALS_SUCCESS,
  FETCH_SENT_REFERRALS_SUCCESS,
  SEND_REFERRAL_INVITATION_SUCCESS,
  SET_REFERRAL_SUCCESS,
} from '../types/Referrals';
import { antiDuplication } from '../../modules/ArrayUtils';

const MLMBonusesReducer = (state = [], action: AnyAction = (() => {}) as unknown as AnyAction) => {
  switch (action.type) {
    case FETCH_REFERRAL_BONUS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const MLMSendInviteReducer = (
  state = [],
  action: AnyAction = (() => {}) as unknown as AnyAction,
) => {
  switch (action.type) {
    case SEND_REFERRAL_INVITATION_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const MLMAcceptedInvitationsReducer = (
  state = { data: [], currentPage: 1, noOfPages: undefined },
  action: AnyAction = (() => {}) as unknown as AnyAction,
) => {
  switch (action.type) {
    case FETCH_REFERRALS_SUCCESS:
      return {
        data: action.payload.nextPage
          ? antiDuplication([...state.data, ...action.payload.data], 'id')
          : action.payload.data,
        noOfPages: action.payload.noOfPages,
        total: action.payload.total,
        currentPage: action.payload.nextPage ? state.currentPage + 1 : 2,
      };
    default:
      return state;
  }
};

const MLMSentInvitationsReducer = (
  state = { data: [], currentPage: 1, noOfPages: undefined },
  action: AnyAction = (() => {}) as unknown as AnyAction,
) => {
  switch (action.type) {
    case FETCH_SENT_REFERRALS_SUCCESS:
      return {
        data: action.payload.nextPage
          ? antiDuplication([...state.data, ...action.payload.data], 'id')
          : action.payload.data,
        noOfPages: action.payload.noOfPages,
        total: action.payload.total,
        currentPage: action.payload.nextPage ? state.currentPage + 1 : 2,
      };
    default:
      return state;
  }
};

const MLMAddReferralReducer = (
  state = [],
  action: AnyAction = (() => {}) as unknown as AnyAction,
) => {
  switch (action.type) {
    case SET_REFERRAL_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const AllDoctorsMlmReducer = (
  state = { data: [], currentPage: 1, noOfPages: undefined },
  action: AnyAction = (() => {}) as unknown as AnyAction,
) => {
  switch (action.type) {
    case FETCH_DOCTORS_ALL_INDEX_SUCCESS:
      return {
        data: action.payload.nextPage
          ? antiDuplication([...state.data, ...action.payload.data], 'id')
          : action.payload.data,
        noOfPages: action.payload.noOfPages,
        currentPage: action.payload.nextPage ? state.currentPage + 1 : 2,
      };
    default:
      return state;
  }
};

export {
  MLMAcceptedInvitationsReducer,
  MLMSentInvitationsReducer,
  MLMBonusesReducer,
  MLMSendInviteReducer,
  MLMAddReferralReducer,
  AllDoctorsMlmReducer,
};
