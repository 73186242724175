import i18next from 'i18next';

const languageSelector = (lang: any[]) => {
  return lang.map((row) => {
    return {
      id: row?.id,
      name: row.translations?.find((r) => r.locale === i18next.language)?.name ?? '',
    };
  });
};

export default languageSelector;
