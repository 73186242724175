import 'doctor/styles/account-profile.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import { Link, useNavigate } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getAuthState, getUserProfileState } from 'shared/redux/src/StatesGetter';
import { fetchUserProfileRequest } from 'shared/redux/actions/UserProfileActions';
import { useTranslation } from 'react-i18next';
import Avatar from 'shared/components/Avatar';
import DoctorAccountProfileTabPersonal from 'doctor/components/account/DoctorAccountProfileTabPersonal';
import DoctorAccountProfileTabInvoice from 'doctor/components/account/DoctorAccountProfileTabInvoice';
import DoctorAccountProfileTabSpecialization from 'doctor/components/account/DoctorAccountProfileTabSpecialization';

const DoctorAccountProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tab = 'personal' } = useParams();
  const [activeTab, setActiveTab] = useState(tab ?? 'personal');
  const { isLoggedIn, isProfileCompleted } = useSelector(getAuthState, shallowEqual);
  const { firstName, lastName, name, pictureMedia, isUnconfirmedFromSocial } = useSelector(
    getUserProfileState,
    shallowEqual,
  );
  const profileData = useSelector(getUserProfileState, shallowEqual);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchUserProfileRequest({}));
    } else if (isProfileCompleted === undefined) {
      navigate('/doctor/login');
    } else if (isProfileCompleted === false) {
      navigate('/doctor/register-step-personal-data');
    }
  }, [isLoggedIn, isProfileCompleted]);
  const changeTab = (k) => {
    setActiveTab(k);
    navigate(`/doctor/account-profile/${k}`);
  };
  const doGoBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };
  return (
    <section className="account-profile">
      <div className="title-section">
        <Link to="/doctor/account" className="back-to-account">
          <CustomIcon className="custom-icon" color={Colors.darkGrey} size="24" icon="Left_arrow" />
          {t('backToAccount')}
        </Link>
        <h1>{t('profile')}</h1>
      </div>

      <div className="small-container">
        <div className="medic-small-box">
          <div className="avatar-section">
            <Avatar size={36} imageURL={pictureMedia} firstName={firstName} lastName={lastName} />
          </div>
          <div className="info">
            <div className="name">{name}</div>
          </div>
        </div>

        {!profileData.isPharmacist && (
          <Tab.Container
            onSelect={changeTab}
            id="account-profile-tabs-menu"
            defaultActiveKey={activeTab}
          >
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="personal">{t('personal')}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="billing">{t('billing')}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="specialization">{t('medicalApp.specialization')}</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="personal">
                <DoctorAccountProfileTabPersonal />
              </Tab.Pane>
              <Tab.Pane eventKey="billing">
                <DoctorAccountProfileTabInvoice />
              </Tab.Pane>
              <Tab.Pane eventKey="specialization">
                <DoctorAccountProfileTabSpecialization />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        )}

        {profileData.isPharmacist && <DoctorAccountProfileTabPersonal />}
      </div>
    </section>
  );
};

export default DoctorAccountProfile;
