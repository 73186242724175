import { all, fork } from 'redux-saga/effects';
import authSaga from './AuthSaga';
import userSaga from './UserSaga';
import updateLocaleSaga from './LocaleSaga';
import twilioSaga from './TwilioSaga';
import supportSaga from './SupportSaga';
import countriesSaga from './CountrySaga';
import specializationSaga from './SpecializationSaga';
import medicalRecordCategorySaga from './MedicalRecordCategorySaga';
import languagesSaga from './LanguageSaga';
import pushesSaga from './NotificationSaga';
import kinshipSaga from './KinshipSaga';
import sharedAppointmentSaga from './SharedAppointmentSaga';
import mediaFilesSaga from './MediaFileSaga';
import currencySaga from './CurrencySaga';
import userProfileSaga from './UserProfileSaga';
import myReviewsSaga from './MyReviewsSaga';
import doctorAppointmentsSaga from './DoctorAppointmentsSaga';
import doctorTimetableSaga from './DoctorTimetableSaga';
import transactionSaga from './TransactionSaga';
import staticPagesSaga from './StaticPagesSaga';
import patientProfileSaga from './PatientProfileSaga';
import patientPaymentSaga from './PatientPaymentSaga';
import favouriteDoctorsSaga from './FavouriteDoctorsSaga';
import doctorSaga from './DoctorsSaga';
import familyMembersSaga from './FamilyMembersSaga';
import doctorProfileSaga from './DoctorProfileSaga';
import doctorReviewsSaga from './DoctorReviewsSaga';
import patientAppointmentSaga from './PatientAppointmentSaga';
import recommendationsSaga from './RecommendationsSaga';
import vouchersSaga from './VouchersSaga';
import specializationsSaga from './SpecializationsSaga';
import referralSaga from './ReferralSaga';
import translationsSaga from './TranslationsSaga';
import allReviewsSaga from './AllReviewsSaga';
import settingsSaga from './SettingsSaga';
import servicesSaga from './ServicesSaga';
import videoCallSaga from './VideoCallSaga';

function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(userSaga),
    fork(updateLocaleSaga),
    fork(twilioSaga),
    fork(supportSaga),
    fork(countriesSaga),
    fork(specializationSaga),
    fork(medicalRecordCategorySaga),
    fork(languagesSaga),
    fork(pushesSaga),
    fork(kinshipSaga),
    fork(sharedAppointmentSaga),
    fork(mediaFilesSaga),
    fork(currencySaga),
    fork(userProfileSaga),
    fork(myReviewsSaga),
    fork(doctorAppointmentsSaga),
    fork(doctorTimetableSaga),
    fork(transactionSaga),
    fork(staticPagesSaga),
    fork(patientProfileSaga),
    fork(patientPaymentSaga),
    fork(favouriteDoctorsSaga),
    fork(doctorSaga),
    fork(familyMembersSaga),
    fork(doctorProfileSaga),
    fork(doctorReviewsSaga),
    fork(patientAppointmentSaga),
    fork(recommendationsSaga),
    fork(vouchersSaga),
    fork(specializationsSaga),
    fork(referralSaga),
    fork(translationsSaga),
    fork(allReviewsSaga),
    fork(settingsSaga),
    fork(servicesSaga),
    fork(videoCallSaga),
  ]);
}

export default rootSaga;
