import { AnyAction } from 'redux';
import { mergePaymentMethodSelector } from 'shared/redux/selector/PaymentMethodSelector';
import {
  CREATE_PAYMENT_METHOD_SUCCESS,
  SAVE_PAYMENT_METHOD_SUCCESS,
  CREATE_PAYMENT_SUCCESS,
  GET_PATIENT_TRANSACTIONS_SUCCESS,
  GET_PAYMENT_METHODS_SUCCESS,
  REMOVE_PAYMENT_METHOD_SUCCESS,
  LOCALLY_CHOOSE_CARD,
} from 'shared/redux/types/PatientPaymentTypes';
import { antiDuplication } from '../../modules/ArrayUtils';

const PaymentMethodsReducer = (
  state = { cards: [], uniqueIds: [], all: [], lastAction: '' },
  action: AnyAction,
) => {
  switch (action.type) {
    case GET_PAYMENT_METHODS_SUCCESS:
      return action.payload;
    case SAVE_PAYMENT_METHOD_SUCCESS:
    case CREATE_PAYMENT_METHOD_SUCCESS:
      return { ...mergePaymentMethodSelector(state, action.payload), lastAction: 'added' };
    case REMOVE_PAYMENT_METHOD_SUCCESS:
      return {
        cards: state.cards.filter((i: Record<string, string>) => i.uniqueId !== action.payload),
        all: state.all.filter((i: Record<string, string>) => i.uniqueId !== action.payload),
        uniqueIds: state.uniqueIds.filter((j) => j !== action.payload),
        lastAction: 'removed',
      };
    default:
      return state;
  }
};

const PatientPaymentReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case CREATE_PAYMENT_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const PatientTransactionsReducer = (
  state = { data: [], currentPage: 1, noOfPages: undefined },
  action: AnyAction,
) => {
  switch (action.type) {
    case GET_PATIENT_TRANSACTIONS_SUCCESS:
      return {
        data: action.payload.nextPage
          ? antiDuplication([...state.data, ...action.payload.data], 'id')
          : action.payload.data,
        noOfPages: action.payload.noOfPages,
        currentPage: action.payload.nextPage ? state.currentPage + 1 : 2,
      };
    default:
      return state;
  }
};

const LocalChooseCardReducer = (
  state = {},
  action: AnyAction = (() => {}) as unknown as AnyAction,
) => {
  switch (action.type) {
    case LOCALLY_CHOOSE_CARD:
      return action.payload;
    default:
      return state;
  }
};

export {
  PaymentMethodsReducer,
  PatientPaymentReducer,
  PatientTransactionsReducer,
  LocalChooseCardReducer,
};
