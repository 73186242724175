import 'patient/styles/call-specialist.css';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ListGroup from 'react-bootstrap/ListGroup';
import PaymentVoucherComponent from 'patient/components/PaymentVoucherComponent';
import PaymentCardComponent from 'patient/components/PaymentCardComponent';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { useNavigate } from 'react-router-dom';
import TimerComponent from 'shared/components/TimerComponent';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getLocalChooseVoucherState,
  getPatientAppointmentState,
  getValidVouchersState,
} from 'shared/redux/src/StatesGetter';
import { fetchValidVouchersRequest } from 'shared/redux/actions/VouchersActions';
import i18next from 'i18next';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementLocale } from '@stripe/stripe-js/types/stripe-js/elements-group';
import dayjs from 'dayjs';
import { getEnv } from '../../../shared/services/EnvService';

const stripePromise = loadStripe(`${getEnv('STRIPE_PK_KEY')}`);
const SummaryPayment = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    id: appointmentId,
    doctor: foundDoctor,
    price: priceString,
    startTime,
    duration,
    patient,
    type: appointmentType,
    scheduledAt = dayjs().format('YYYY-MM-DD HH:mm:ss'),
    discountAsString,
    voucher: serverVoucher,
    specializationName,
  } = useSelector(getPatientAppointmentState, shallowEqual);

  const vouchers = useSelector(getValidVouchersState, shallowEqual);
  const localChosenVoucher = useSelector(getLocalChooseVoucherState, shallowEqual);
  const stepString = `${t('step')} 4/4`;

  useEffect(() => {
    if (appointmentId) {
      dispatch(fetchValidVouchersRequest({ id: appointmentId }));
    }
  }, []);
  const stripeOptions = (language) => {
    return {
      locale: language as StripeElementLocale,
      fonts: [
        {
          family: 'Rota',
          src: 'url(/fonts/Rota-SemiBold.woff2)',
          weight: '600',
        },
      ],
    };
  };
  // Warning: Unsupported prop change on Elements: You cannot change the `stripe` prop after setting it.
  // TODO: Find a way to change the locale on existing stripe instance
  useEffect(() => {
    stripeOptions(i18next.language);
  }, [i18next.language]);

  return (
    <Elements stripe={stripePromise} options={stripeOptions(i18next.language)}>
      <section className="call-specialist">
        <div className="title-section">
          <div className="steps">
            <div className="icon-wrapper" onClick={() => navigate(-1)}>
              <CustomIcon
                className="custom-icon"
                color={Colors.darkGrey}
                size="24"
                icon="Left_arrow"
              />
            </div>
            {stepString}
          </div>
          <h1>{t('payment')}</h1>
        </div>
        <div className="countdown-box countdown">
          <div className="will-start">{t('medicSpecialistStartingCallIn')}</div>
          <div className="timer">
            <TimerComponent action={() => {}} endTime={startTime} onTimeAboutToEnd={() => {}} />
            <p>&nbsp;{t('min')}</p>
          </div>
        </div>
        <div className="medic-box">
          <div
            className="avatar"
            style={
              foundDoctor?.imgUrl
                ? { backgroundImage: `url(${foundDoctor?.imgUrl})` }
                : { backgroundColor: '#E5E7E8' }
            }
          >
            {!foundDoctor?.imgUrl && (
              <div className="image-placeholder">{`${foundDoctor?.firstName?.charAt(
                0,
              )}${foundDoctor?.lastName?.charAt(0)}`}</div>
            )}
          </div>
          <div className="info">
            <div className="name">{foundDoctor.fullNameWithTitle}</div>
            <div className="specialization">{specializationName}</div>
          </div>
        </div>
        <div className="details-box">
          <ListGroup.Item>
            <div className="title">{t('patient')}</div>
            <div className="details">{patient.fullNameWithTitle}</div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="title">{t('dateAndTime')}</div>
            <div className="details">
              {dayjs(scheduledAt ?? dayjs().format('YYYY-MM-DD HH:mm:ss')).format(
                'DD MMM YYYY, HH:mm',
              )}
            </div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="title">{t('appointments.duration')}</div>
            <div className="details">{duration} min</div>
          </ListGroup.Item>

          <ListGroup>
            <ListGroup.Item>
              <div className="title">{t('totalToPay')}</div>
              <div className="details">{priceString}</div>
            </ListGroup.Item>
          </ListGroup>
        </div>
        <PaymentVoucherComponent
          appointmentId={appointmentId}
          vouchers={vouchers}
          serverVoucher={serverVoucher}
          localChosenVoucher={localChosenVoucher}
        />
        <PaymentCardComponent
          appointmentId={appointmentId}
          skippablePayment={false}
          appointmentType="callSpecialist"
        />
      </section>
    </Elements>
  );
};

export default SummaryPayment;
