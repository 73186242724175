import 'patient/styles/call-specialist.css';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';
import Lottie from 'react-lottie';
import * as animationData from 'shared/animations/ringdoc-animation-searching-doctor-2.json';
import ListGroup from 'react-bootstrap/ListGroup';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import {
  appointmentCancelRequest,
  appointmentLocalStepsRequest,
} from 'shared/redux/actions/PatientAppointmentActions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getPatientAppointmentState,
  getPatientLocalChooseSpecializationState,
} from 'shared/redux/src/StatesGetter';
import TimerComponent from 'shared/components/TimerComponent';
import Alerts from 'shared/components/Alerts';

const SearchingMedic = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    id: appointmentId,
    doctor: foundDoctor,
    price: priceString,
    startTime,
    specializationName,
    duration,
    type: appointmentType,
    voucher: serverVoucher,
  } = useSelector(getPatientAppointmentState, shallowEqual);

  const { title = '' } = useSelector(getPatientLocalChooseSpecializationState, shallowEqual);
  const cancelAppointment = () => {
    Alerts.okCancelAlert(t('warning'), t('validations.cancelAppointment'), () => {
      dispatch(appointmentCancelRequest({ appointmentId }));
      dispatch(
        appointmentLocalStepsRequest({
          newAppointment: true,
        }),
      );
      navigate('/');
    });
  };

  useEffect(() => {
    console.warn(`Auction ends at: ${startTime}`);
  }, [startTime]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <section className="call-specialist">
      <div className="box">
        <h1>
          {t('searchingMedicSpecialist', {
            specialist: title,
          })}
        </h1>
        <Lottie options={defaultOptions} height={300} width={300} />
        <div className="countdown">
          <div className="will-start">{t('waitingTime')}</div>
          <div className="timer">
            <TimerComponent action={() => {}} endTime={startTime} onTimeAboutToEnd={() => {}} />
            <p>&nbsp;{t('min')}</p>
          </div>
        </div>
      </div>
      <div className="details-box">
        <ListGroup>
          <ListGroup.Item>
            <div className="title">{t('specialization')}</div>
            <div className="details">{specializationName}</div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="title">{t('examinationDuration')}</div>
            <div className="details">{duration} min</div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="title">{t('totalToPay')}</div>
            <div className="details">{priceString}</div>
          </ListGroup.Item>
        </ListGroup>
      </div>
      <button type="button" onClick={cancelAppointment} className="white-button font-weight-bold">
        {t('abortSearchCallSpecialist')}
      </button>
      <div className="info-message dark">
        <CustomIcon className="custom-icon" color={Colors.mediumGrey} size="24" icon="Warning_2" />
        {t('forMedicalEmergencyCallMedicalAuthorities')}
      </div>
    </section>
  );
};

export default SearchingMedic;
