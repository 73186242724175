import 'patient/styles/appointment-steps.css';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import {
  getFamilyMembersState,
  getLocalCurrentPatientProfileState,
  getPatientAppointmentState,
  getPatientProfileState,
} from 'shared/redux/src/StatesGetter';
import { appointmentCancelRequest } from 'shared/redux/actions/PatientAppointmentActions';
import { locallyCurrentPatientProfile } from 'shared/redux/actions/PatientProfileActions';
import { getFamilyMembersRequest } from 'shared/redux/actions/FamilyMembersActions';
import { useTranslation } from 'react-i18next';

const PatientAppointmentProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const patientProfile = useSelector(getPatientProfileState, shallowEqual);
  const selectedProfile = useSelector(getLocalCurrentPatientProfileState, shallowEqual);
  const familyMembers = useSelector(getFamilyMembersState, shallowEqual);
  const appointmentState = useSelector(getPatientAppointmentState, shallowEqual);
  const {
    noOfSteps,
    doctor: foundDoctor,
    service: appointmentService,
    hasControl,
  } = appointmentState;

  const service = appointmentService ?? 'consultation';

  useEffect(() => {
    dispatch(getFamilyMembersRequest({}));

    if (_.isEmpty(selectedProfile)) {
      dispatch(locallyCurrentPatientProfile(patientProfile));
    }
  }, []);

  const stepString = `${t('step')} ${noOfSteps - 2}/${noOfSteps}`;

  const onProfileClick = (e, profile) => {
    dispatch(locallyCurrentPatientProfile(profile));
    navigate('/patient/appointment/medical-documents');
  };

  const abortAction = () => {
    dispatch(appointmentCancelRequest({ appointmentId: appointmentState?.id }));
    navigate('/patient/medicList');
  };

  return (
    <section className="appointment-steps">
      <div className="title-section">
        <div className="steps">
          <div className="icon-wrapper" onClick={() => navigate(-1)}>
            <CustomIcon
              className="custom-icon"
              color={Colors.darkGrey}
              size="24"
              icon="Left_arrow"
            />
          </div>
          <div>{stepString}</div>
          <div className="cancel-button">
            <button type="button" onClick={abortAction}>
              {t('abort')}
            </button>
          </div>
        </div>
        <h1>{t('profile')}</h1>
      </div>
      <div className="text-section">{`${t('profileScreenSubtitle', { serviceType: t(service) })} ${
        foundDoctor?.specializationName
      }`}</div>
      <form className="family-members">
        {familyMembers.map((member) => (
          <div className="profile-item" key={member.id} onClick={(e) => onProfileClick(e, member)}>
            <div className="profile-box">
              <div
                className="avatar"
                style={
                  member?.imageUrl
                    ? { backgroundImage: `url(${member?.imageUrl})` }
                    : { backgroundColor: '#E5E7E8' }
                }
              >
                {!member?.imageUrl && (
                  <div className="image-placeholder">{`${member?.firstName?.charAt(
                    0,
                  )}${member?.lastName?.charAt(0)}`}</div>
                )}
              </div>
              <div className="info">
                <div className="profile-name">{member.fullNameWithTitle}</div>
                <div className="profile-details">
                  {member.age === 0
                    ? t('medicalProfile.under_a_year')
                    : member.age && `${member.age} ${t('years')} - ${member.type}`}
                </div>
              </div>
            </div>

            <CustomIcon
              className="custom-icon"
              color={Colors.darkGrey}
              size="24"
              icon="Right_pointer"
            />
          </div>
        ))}
        <button
          type="button"
          className="white-button"
          onClick={() =>
            navigate('/patient/medicalFolder/add-profile', { state: { goBack: true } })
          }
        >
          <CustomIcon className="custom-icon" color={Colors.darkGrey} size="24" icon="Plus" />
          {t('addProfile')}
        </button>
        <div className="info-message">
          <CustomIcon
            className="custom-icon"
            color={Colors.mediumGrey}
            size="24"
            icon="Warning_2"
          />
          {t('profileScreenFooterWarn')}
        </div>
      </form>
    </section>
  );
};
export default PatientAppointmentProfile;
