import { AnyAction } from 'redux';
import {
  FETCH_MY_REVIEWS_REQUEST,
  FETCH_MY_REVIEWS_ERROR,
  FETCH_MY_REVIEWS_SUCCESS,
} from 'shared/redux/types/MyReviewsTypes';

export const fetchMyReviewsRequest = (payload): AnyAction => ({
  type: FETCH_MY_REVIEWS_REQUEST,
  payload,
});

export const fetchMyReviewsSuccess = (payload): AnyAction => ({
  type: FETCH_MY_REVIEWS_SUCCESS,
  payload,
});

export const fetchMyReviewsError = (payload): AnyAction => ({
  type: FETCH_MY_REVIEWS_ERROR,
  payload,
});
