import 'patient/styles/call-specialist.css';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { getPatientAppointmentState } from 'shared/redux/src/StatesGetter';
import TimerComponent from 'shared/components/TimerComponent';
import dayjs from 'dayjs';

const WaitingScreen = () => {
  const { t } = useTranslation();
  const {
    id: appointmentId,
    doctor: foundDoctor,
    price: priceString,
    priceGrossAsString,
    startTime,
    endTime,
    noOfSteps,
    specializationId,
    duration,
    patient,
    type: appointmentType,
    scheduledAt = dayjs().format('YYYY-MM-DD HH:mm:ss'),
    discountAsString,
    voucher: serverVoucher,
  } = useSelector(getPatientAppointmentState, shallowEqual);

  return (
    <section className="call-specialist">
      <div className="countdown-box countdown">
        <div className="will-start">{t('medicSpecialistStartingCallIn')}</div>
        <div className="timer">
          <TimerComponent action={() => {}} endTime={startTime} onTimeAboutToEnd={() => {}} />
          <p>&nbsp;{t('min')}</p>
        </div>
      </div>
      <div className="medic-call-box">
        <div
          className="medic-avatar"
          style={
            foundDoctor?.imgUrl
              ? { backgroundImage: `url(${foundDoctor?.imgUrl})` }
              : { backgroundColor: '#E5E7E8' }
          }
        >
          {!foundDoctor?.imgUrl && (
            <div className="image-placeholder">{`${foundDoctor?.firstName?.charAt(
              0,
            )}${foundDoctor?.lastName?.charAt(0)}`}</div>
          )}
        </div>
        <div className="rating">
          <CustomIcon className="custom-icon" color={Colors.mediumGrey} size="16" icon="Rating_2" />
          <i className="rating-icon" />
          {t('note')} {foundDoctor.averageRating} ({foundDoctor.countRating ?? 0} {t('noOfReviews')}
          )
        </div>
        <div className="name">{foundDoctor.fullNameWithTitle}</div>
        <div className="specialization">{foundDoctor.specialization}</div>
        <div className="clinic">{foundDoctor.organization?.clinicName}</div>
      </div>
      <div className="info-message">
        <CustomIcon className="custom-icon" color={Colors.mediumGrey} size="24" icon="Warning_2" />
        {t('waitingPushNotification')}
      </div>
    </section>
  );
};

export default WaitingScreen;
