import 'patient/styles/appointment-steps.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Utils from '../../../shared/modules/Utils';

const PatientAppointmentWaitForDoctorTimer = (props) => {
  const { t, i18n } = useTranslation();
  const { action, endTime } = props;
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  let intervalRef: NodeJS.Timeout | null = null;
  const clear = () => {
    if (intervalRef) {
      clearInterval(intervalRef);
    }
  };

  const doUpdate = () => {
    if (!endTime?.length) return;
    const { days, hours, minutes: newMinutes, seconds: newSeconds } = Utils.timeUntil(endTime);
    if (hours < 0 || days < 0) {
      setMinutes(0);
      setSeconds(0);
      action();
      clear();
    } else {
      setMinutes(newMinutes);
      setSeconds(newSeconds);
    }
  };
  useEffect(() => {
    doUpdate();
    clear();
    intervalRef = setInterval(() => {
      doUpdate();
    }, 100);
    return () => {
      clear();
    };
  }, []);

  if (endTime?.length && minutes < 30 && (minutes > 0 || seconds > 0)) {
    return (
      <>
        {` ${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`} {t('min')}
      </>
    );
  }

  return <>{t('startSoon')}</>;
};

export default PatientAppointmentWaitForDoctorTimer;
