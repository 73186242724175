import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import { fetchDoctorReviewsRequest } from 'shared/redux/actions/DoctorReviewsActions';
import { getAuthState, getDoctorReviewsState } from 'shared/redux/src/StatesGetter';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { getEnv } from '../../shared/services/EnvService';
import StorageService from '../../shared/services/StorageService';
import { updateLocaleRequest } from '../../shared/redux/actions/UngroupedActions';

const PatientMedicReviewsModal = ({
  id,
  medic,
  modalVisibility,
  hideModal,
  modalReviewReadAllShow,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    data: reviews,
    currentPage,
    noOfPages,
  } = useSelector(getDoctorReviewsState, shallowEqual);

  const loadNextPageReviews = () => {
    if (currentPage <= noOfPages) {
      dispatch(
        fetchDoctorReviewsRequest({
          id,
          page: currentPage,
          nextPage: true,
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(fetchDoctorReviewsRequest({ id }));
  }, []);

  return (
    <Modal
      show={modalVisibility}
      onHide={hideModal}
      animation
      centered
      className="modal-all-reviews"
    >
      <Modal.Header closeButton closeLabel={t('close')}>
        <Modal.Title>{t('allReviews')}</Modal.Title>
      </Modal.Header>

      <Modal.Header className="subheader">
        <div className="medic-small-box">
          <div className="avatar" style={{ backgroundImage: `url(${medic.pictureMedia})` }} />

          <div className="info">
            <div className="name">{medic.name}</div>
            <div className="rating">
              <CustomIcon
                className="custom-icon"
                color={Colors.mediumGrey}
                size="14"
                icon="Rating_2"
              />
              {t('note')} {medic.averageRating} ({medic.countRating} {t('noOfReviews')})
            </div>
          </div>
        </div>
      </Modal.Header>

      <Modal.Body>
        <div id="infiniteScrollContainerReviews" className="scroll-list">
          <InfiniteScroll
            dataLength={reviews.length}
            next={loadNextPageReviews}
            hasMore={currentPage <= noOfPages}
            loader={<LoadingSpinner isLoading />}
            scrollableTarget="infiniteScrollContainerReviews"
          >
            {reviews?.map((review) => {
              return (
                <div key={review.id} className="review">
                  <div className="header">
                    <div className="name">{review.originator}</div>
                    <div className="rating">
                      <CustomIcon
                        className="custom-icon"
                        color={Colors.mediumGrey}
                        size="14"
                        icon="Rating_2"
                      />
                      {t('note')} {review.rating} / 5
                    </div>
                  </div>

                  <div className="date">{dayjs(review.createdAt).format('D MMMM YYYY')}</div>

                  <div className="text">
                    {review.remarks.split(' ').length < 20 || !modalReviewReadAllShow
                      ? review.remarks
                      : `${review.remarks.split(' ').slice(0, 20).join(' ')} ...`}
                  </div>

                  {review.remarks.split(' ').length >= 20 && modalReviewReadAllShow && (
                    <button
                      type="button"
                      className="read-all"
                      onClick={() => {
                        modalReviewReadAllShow(review);
                        hideModal();
                      }}
                    >
                      {t('readAll')}
                    </button>
                  )}
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PatientMedicReviewsModal;
