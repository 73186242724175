import { default as ApiConstants } from 'shared/constants/ApiConstants.json';
import ApiUtils from 'shared/modules/ApiUtils';

const getBalanceAPI = () => ApiUtils.getRequest(`${ApiConstants.DOCTOR_BALANCE_ENDPOINT}`);

const getPayoutsAPI = ({ page, limit }) =>
  ApiUtils.getRequest(`${ApiConstants.DOCTOR_PAYOUTS_ENDPOINT}?page=${page}&limit=${limit}`);

const getPayoutTransactionsAPI = ({ payoutId, page, limit }) =>
  ApiUtils.getRequest(
    `${ApiConstants.DOCTOR_PAYOUTS_ENDPOINT}/${payoutId}?page=${page}&limit=${limit}`,
  );

const getIncomeAPI = ({ page, limit }) =>
  ApiUtils.getRequest(`${ApiConstants.DOCTOR_INCOME_ENDPOINT}?page=${page}&limit=${limit}`);

const getIncomeTransactionsAPI = ({ dateStart, dateEnd, page, limit }) =>
  ApiUtils.getRequest(
    `${ApiConstants.DOCTOR_INCOME_TRANSACTIONS_ENDPOINT}?dateStart=${dateStart}&dateEnd=${dateEnd}&page=${page}&limit=${limit}`,
  );

export {
  getBalanceAPI,
  getPayoutsAPI,
  getPayoutTransactionsAPI,
  getIncomeAPI,
  getIncomeTransactionsAPI,
};
