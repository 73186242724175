import i18next from 'i18next';
import { PhoneNumberUtil } from 'google-libphonenumber';
import Utils from '../../modules/Utils';

const resolveLanguages = (languages) => {
  return languages.map((lang) => {
    return `${lang.translations?.find((row) => row.locale === i18next.language)?.name ?? ''} `;
  });
};

const resolvePhoneNumber = (phoneNumber) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    const parsedNo = phoneUtil.parse(phoneNumber, '');
    if (parsedNo.hasNationalNumber()) {
      const code = parsedNo.getCountryCodeOrDefault();
      const nationalNumber = parsedNo.getNationalNumberOrDefault()?.toString();
      const countryCode = phoneUtil.getRegionCodeForCountryCode(code);
      return {
        nationalNumber,
        countryCode,
        code,
      };
    }
    return {
      nationalNumber: phoneNumber?.length ? phoneNumber : '',
      countryCode: 'RO',
      code: '40',
    };
  } catch {
    return {
      nationalNumber: phoneNumber?.length ? phoneNumber : '',
      countryCode: 'RO',
      code: '40',
    };
  }
};

const resolveSpecializations = (specializations) => {
  return specializations
    .filter((r) => r.specialization.is_visible)
    .map((specialization) => {
      return {
        id: specialization.specialization?.id,
        doctorSpecializationId: specialization.id,
        isActive: specialization.specialization.is_active,
        isVisible: specialization.specialization.is_visible,
        hasEmergency: specialization.specialization.has_emergency,
        isValidated: specialization.is_validated,
        isAvailable: specialization.is_available,
        price: specialization.price_as_string,
        duration: specialization.duration,
        pause: specialization.pause,
        acceptChildren: specialization.accept_children,
        name:
          specialization.specialization.translations?.find((row) => row.locale === i18next.language)
            ?.name ?? '',
        description:
          specialization.specialization.translations?.find((row) => row.locale === i18next.language)
            ?.description ?? '',
        doctorServices: specialization.doctor_services,
      };
    });
};

const userProfileSelector = (response) => {
  if (!('id' in response)) return {};
  return {
    id: response?.id,
    email: response.email,
    locale: i18next.language,
    accountStatus: response.account_status,
    firstName: response.first_name,
    lastName: response.last_name,
    name: response.full_name_with_title,
    numericPersonalCode: response.numeric_personal_code,
    gender: Utils.solveGender(response?.gender),
    averageRating: response.average_rating,
    countRating: response.count_rating,
    birthDate: response.birth_date,
    phonePrefix: response.phone_number_country?.calling_code,
    phonePrefixId: response.phone_number_country?.id,
    titleId: response.user_title?.id,
    title:
      response.user_title?.translations?.find((row) => row.locale === i18next.language)?.name ?? '',
    titleAbbreviation:
      response.user_title?.translations?.find((row) => row.locale === i18next.language)
        ?.abbreviation ?? '',
    phoneNumber: response.phone_number,
    ...resolvePhoneNumber(response.phone_number),
    pictureMedia: response.picture_media?.public_url,
    userLanguages: resolveLanguages(response?.user_languages),
    organizationId: response.organization?.id,
    organizationName: response.organization?.name,
    organizationUIN: response.organization?.unique_identification_number,
    organizationCRN: response.organization?.commercial_registration_number,
    organizationAddress: response?.organization?.address,
    organizationAddressLine1: response.organization?.address?.line1,
    organizationAddressLine2: response.organization?.address?.line2,
    organizationZipCode: response.organization?.address?.zip_code,
    organizationCity: response.organization?.address?.city,
    organizationCounty: response.organization?.address?.county,
    organizationCountry:
      response.organization?.address?.country?.translations?.find(
        (row) => row.locale === i18next.language,
      )?.name ?? '',
    organizationCountryId: response.organization?.address?.country?.id,
    organizationBank: response?.organization?.bank_name,
    organizationIban: response?.organization?.iban,
    organizationCurrency: response?.organization?.currency_code,
    addressLine1: response?.address?.line1,
    addressLine2: response?.address?.line2,
    zipCode: response?.address?.zip_code,
    city: response?.address?.city,
    county: response?.address?.county,
    country:
      response?.address?.country?.translations?.find((row) => row.locale === i18next.language)
        ?.name ?? '',
    countryId: response?.address?.country?.id,
    countryPhoneCode: response?.address?.country?.calling_code,
    identityCardMedia: response?.identity_card_media,
    additionalDocumentMedia: response?.additional_document_media,
    cvMedia: response?.cv_media,
    doctorSealMedia: response?.doctor_seal_media,
    isAvailableForCallNow: response?.is_available_for_call_now,
    isAvailableForFreeTalk: response?.is_available_for_free_talk,
    isAvailableForEmergency: response?.is_available_for_emergency,
    doctorSpecializations: resolveSpecializations(response?.doctor_specializations),
    isCreatedWithSocialLogin: response?.is_created_with_social_login,
    isOrganizationOwner: response?.is_organization_owner,
    hasOwnOrganization: response?.has_own_organization,
    isResident: response?.is_resident,
    isPatient: response.is_patient,
    isDoctor: response.is_doctor,
    address: response?.address,
    canUseSeal: response?.can_use_seal,
    marketingNotificationSetting: response?.doctor_marketing_notification_setting,
    isUnconfirmedFromSocial: response?.is_unconfirmed_from_social,
    roles: response?.roles,
    isPharmacist: response?.is_pharmacist,
  };
};

const mySpecializationsSelector = (response) => {
  return resolveSpecializations(response);
};

const titlesSelector = (titles) => {
  return titles?.map((title) => {
    return {
      id: title?.id,
      name: title.translations?.find((row) => row.locale === i18next.language)?.name ?? '',
      abbreviation:
        title.translations?.find((row) => row.locale === i18next.language)?.abbreviation ?? '',
    };
  });
};

export { userProfileSelector, mySpecializationsSelector, titlesSelector };
