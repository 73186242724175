import 'doctor/styles/account-rates.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  getAppointmentSettingsState,
  getMySpecializationsState,
  getUserProfileState,
  servicesState,
} from 'shared/redux/src/StatesGetter';
import Utils from 'shared/modules/Utils';
import { fetchMySpecializationsRequest } from 'shared/redux/actions/UserProfileActions';
import Alerts from 'shared/components/Alerts';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import CustomIcon from '../../../shared/components/CustomIcon';
import Colors from '../../../shared/themes/Colors';
import { updateServicesPricesRequest } from '../../../shared/redux/actions/ServicesActions';
import { getServicesRequest } from '../../../shared/redux/actions/ServiceActions';

const DoctorAccountRatesBlock = (props) => {
  const { setRedirect } = props;
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const specializations = useSelector(getMySpecializationsState, shallowEqual);
  const [selectedSpecialization, setSelectedSpecialization] = useState(null);
  const appointmentSettings = useSelector(getAppointmentSettingsState, shallowEqual);
  const profileData = useSelector(getUserProfileState, shallowEqual);
  const [prices, setPrice] = useState<Record<string, any>>(
    Utils.getSpecializationPrices(specializations),
  );

  const { doctorSpecializations } = profileData;

  const [durations, setDurations] = useState<Record<string, any>>(
    Utils.getSpecializationDuration(specializations),
  );
  const [pauses, setPauses] = useState<Record<string, any>>(
    Utils.getSpecializationPauses(specializations),
  );
  const [activeServices, setActiveServices] = useState<Record<string, any>>(
    Utils.getSpecializationActiveService(specializations),
  );

  const updatePrice = (specializationId, serviceId, newPrice) => {
    setPrice((prevState) => {
      // eslint-disable-next-line no-param-reassign
      prevState[specializationId][serviceId] = newPrice;
      return { ...prevState };
    });
  };
  const updateDuration = (specializationId, serviceId, newDuration) => {
    setDurations((prevState) => {
      // eslint-disable-next-line no-param-reassign
      prevState[specializationId][serviceId] = newDuration;
      return { ...prevState };
    });
  };
  const updatePause = (specializationId, serviceId, newPause) => {
    setPauses((prevState) => {
      // eslint-disable-next-line no-param-reassign
      prevState[specializationId][serviceId] = newPause;
      return { ...prevState };
    });
  };

  const updateActiveService = (specializationId, serviceId, newState) => {
    setActiveServices((prevState) => {
      // eslint-disable-next-line no-param-reassign
      prevState[specializationId][serviceId] = newState;
      return { ...prevState };
    });
  };
  const [formattedSpecializations, setFormattedSpecializations] = useState(
    Utils.makeSectionListFormat(doctorSpecializations, 'name'),
  );

  const [formattedSpecializationsList, setFormattedSpecializationsList] = useState(
    Utils.makeSectionListFormat(doctorSpecializations, 'name'),
  );

  const services = useSelector(servicesState, shallowEqual);

  useEffect(() => {
    dispatch(getServicesRequest({}));
    dispatch(fetchMySpecializationsRequest({}));
  }, []);

  useEffect(() => {
    dispatch(fetchMySpecializationsRequest({}));
  }, [doctorSpecializations.length]);
  useEffect(() => {
    setFormattedSpecializations(Utils.makeSectionListFormat(doctorSpecializations, 'name'));
  }, [doctorSpecializations]);
  const saveAction = (slug, item) => {
    let allGood = true;
    let validPrice = true;

    let itemDoctorService = item.doctorServices.filter((doctorService) => {
      return doctorService.service.slug === slug;
    });
    itemDoctorService = itemDoctorService.length > 0 ? Object.values(itemDoctorService)[0] : false;

    const { doctorSpecializationId: doctorSpecializationIdValue } = item;
    const { id: itemDoctorServiceId } = itemDoctorService;

    if (Number.isNaN(prices[doctorSpecializationIdValue][itemDoctorServiceId])) {
      validPrice = false;
    }
    if (!_.has(durations[doctorSpecializationIdValue], itemDoctorServiceId)) {
      allGood = false;
    }
    if (!_.has(pauses[doctorSpecializationIdValue], itemDoctorServiceId)) {
      allGood = false;
    }

    if (durations[item.doctorSpecializationId][itemDoctorServiceId] === '') {
      allGood = false;
    }

    if (pauses[item.doctorSpecializationId][itemDoctorServiceId] === '') {
      allGood = false;
    }

    if (!validPrice) {
      Alerts.errorAlert(`${t('alerts.invalidNumber')}`);
      return;
    }

    if (!allGood) {
      Alerts.simpleAlert(
        `${t('error')}`,
        `${t('allFieldsMandatoryAndBetweenValues')} ${
          appointmentSettings?.callNow?.minPriceAsStr
        } - ${appointmentSettings?.callNow?.maxPriceAsStr} ${t('allNumbers')}`,
      );
      return;
    }

    const doctorServicesToBeUpdated: Record<string, any> = [
      {
        price: prices[doctorSpecializationIdValue][itemDoctorServiceId],
        duration: durations[doctorSpecializationIdValue][itemDoctorServiceId],
        pause: pauses[doctorSpecializationIdValue][itemDoctorServiceId],
        service: slug === 'control' ? '2' : '1',
        specialization: item.id,
        isActive: activeServices[doctorSpecializationIdValue][itemDoctorServiceId] ? 1 : 0,
      },
    ];

    dispatch(updateServicesPricesRequest({ doctorServices: doctorServicesToBeUpdated }));
  };

  const [showServiceValue, setShowServiceValue] = useState<string>('');
  const updateShowServiceValue = (doctorSpecializationId, itemDoctorServiceId) => {
    setShowServiceValue(`${doctorSpecializationId}_${itemDoctorServiceId}`);
  };

  const renderItem = (index, item) => {
    const { doctorSpecializationId } = item;
    return (
      <div key={`item_${index}_${doctorSpecializationId}`}>
        <div className="subtitle">{Utils.capitalizeFirstLetter(item.name)}</div>
        {Object.values(services).map((service) => {
          // @ts-ignore
          const { slug } = service;
          let itemDoctorService = item.doctorServices.filter((doctorService) => {
            return doctorService.service.slug === slug;
          });
          itemDoctorService =
            itemDoctorService.length > 0 ? Object.values(itemDoctorService)[0] : false;
          const { id: itemDoctorServiceId } = itemDoctorService;

          return (
            <div key={`service_${index}_${doctorSpecializationId}_${itemDoctorServiceId}`}>
              <div
                className="specializations specializations-rates"
                // @ts-ignore
                onClick={(e) => {
                  e.preventDefault();
                  updateShowServiceValue(doctorSpecializationId, itemDoctorServiceId);
                }}
              >
                <div className="specialization-item">
                  <div className="specialization-name">{Utils.capitalizeFirstLetter(t(slug))}</div>
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.darkGrey}
                    size="24"
                    icon="Right_pointer"
                  />
                </div>
              </div>
              {showServiceValue === `${doctorSpecializationId}_${itemDoctorServiceId}` && (
                <div
                  className={`serviceValues pb-4 service_${index}_${doctorSpecializationId}_${itemDoctorServiceId}`}
                >
                  {slug !== 'consultation' && (
                    <div className="col-12 colp10 pb-3">
                      <div className="box">
                        <Form.Check
                          checked={activeServices[doctorSpecializationId][itemDoctorServiceId]}
                          onChange={() => {
                            updateActiveService(
                              doctorSpecializationId,
                              itemDoctorServiceId,
                              !activeServices[doctorSpecializationId][itemDoctorServiceId],
                            );
                          }}
                          type="switch"
                          className="custom-switch green-switch"
                          id="custom-switch2"
                          label={`${t('appointments.activeService')}`}
                        />
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div>
                      <div className="form-input">
                        <input
                          disabled={!activeServices[doctorSpecializationId][itemDoctorServiceId]}
                          autoComplete="off"
                          id={`price_${slug}_${doctorSpecializationId}_${itemDoctorServiceId}`}
                          name={`price_${slug}_${doctorSpecializationId}_${itemDoctorServiceId}`}
                          type="text"
                          className="form-control"
                          placeholder=" "
                          value={prices[doctorSpecializationId][itemDoctorServiceId]}
                          onChange={(event) =>
                            updatePrice(
                              doctorSpecializationId,
                              itemDoctorServiceId,
                              event.target.value,
                            )
                          }
                        />
                        <label
                          htmlFor={`price_${slug}_${doctorSpecializationId}_${itemDoctorServiceId}`}
                        >
                          {t('appointments.appointmentPrice')}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row rowp10">
                    <div className="col-lg-6 col-xl-6 col-md-6 col-12 colp10">
                      <div className="form-input">
                        <input
                          disabled={!activeServices[doctorSpecializationId][itemDoctorServiceId]}
                          autoComplete="off"
                          id={`duration_${slug}_${doctorSpecializationId}_${itemDoctorServiceId}`}
                          name={`duration_${slug}_${doctorSpecializationId}_${itemDoctorServiceId}`}
                          type="text"
                          className="form-control"
                          placeholder=" "
                          value={durations[doctorSpecializationId][itemDoctorServiceId]}
                          onChange={(event) =>
                            updateDuration(
                              doctorSpecializationId,
                              itemDoctorServiceId,
                              event.target.value,
                            )
                          }
                        />
                        <label
                          htmlFor={`duration_${slug}_${doctorSpecializationId}_${itemDoctorServiceId}`}
                        >
                          {t('appointments.duration')}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-6 col-md-6 col-12 colp10">
                      <div className="form-input">
                        <input
                          disabled={!activeServices[doctorSpecializationId][itemDoctorServiceId]}
                          autoComplete="off"
                          id={`pause_${slug}_${doctorSpecializationId}_${itemDoctorServiceId}`}
                          name={`pause_${slug}_${doctorSpecializationId}_${itemDoctorServiceId}`}
                          type="text"
                          className="form-control"
                          placeholder=" "
                          value={pauses[doctorSpecializationId][itemDoctorServiceId]}
                          onChange={(event) =>
                            updatePause(
                              doctorSpecializationId,
                              itemDoctorServiceId,
                              event.target.value,
                            )
                          }
                        />
                        <label
                          htmlFor={`pause_${slug}_${doctorSpecializationId}_${itemDoctorServiceId}`}
                        >
                          {t('appointments.pause')}
                        </label>
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={(event) => {
                      event.preventDefault();
                      saveAction(slug, item);
                    }}
                    type="button"
                    className="green-button"
                  >
                    {t('saveChanges')}
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {selectedSpecialization !== null ? (
        <div className="account-rates">
          <div className="title-section">
            <h2
              className={`${
                ['/doctor/account'].indexOf(location.pathname) !== -1
                  ? 'title-section-with-back'
                  : ''
              }
              `}
            >
              {['/doctor/account'].indexOf(location.pathname) !== -1 && (
                <div className="icon-wrapper" onClick={() => setSelectedSpecialization(null)}>
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.darkGrey}
                    size="24"
                    icon="Left_arrow"
                    // @ts-ignore
                    onClick={(e) => {
                      e.preventDefault();
                      setShowServiceValue('');
                    }}
                  />
                </div>
              )}
              {t('mainScreenRoutes.prices')}
            </h2>
          </div>
          {doctorSpecializations.length > 0 ? (
            formattedSpecializationsList
              .filter((specialization) => {
                return specialization.data[0].id === selectedSpecialization;
              })
              .map((section) => {
                return (
                  <div key={section.key}>
                    {section?.data?.length ? (
                      <div>
                        {section?.data?.map((sp, index) => {
                          return renderItem(index, sp);
                        })}
                      </div>
                    ) : (
                      <li>{t('noSpecialization')}</li>
                    )}
                  </div>
                );
              })
          ) : (
            <div className="d-flex">
              <p>{t('noSpecialization')}</p>
            </div>
          )}
        </div>
      ) : (
        <div className="account-rates">
          <div className="title-section">
            <h2>{t('mainScreenRoutes.prices')}</h2>
          </div>
          <div className="specializations specializations-rates">
            {doctorSpecializations.length > 0 ? (
              doctorSpecializations
                .filter((specialization) => {
                  return specialization.isAvailable;
                })
                .map((specialization) => {
                  let specializationIsActive = true;
                  specialization.doctorServices
                    .filter((doctorService) => {
                      return doctorService.service.slug === 'consultation';
                    })
                    .map((doctorService) => {
                      specializationIsActive = doctorService.is_active;
                      return true;
                    });
                  return (
                    <div
                      key={specialization.id}
                      className={`specialization-item ${specializationIsActive ? '' : 'disabled'}`}
                      onClick={(e) => {
                        if (specializationIsActive) {
                          setRedirect('/doctor/rates');
                          setSelectedSpecialization(specialization.id);
                        }
                      }}
                    >
                      <div className="specialization-name">{specialization.name}</div>
                      <CustomIcon
                        className="custom-icon"
                        color={Colors.darkGrey}
                        size="24"
                        icon="Right_pointer"
                      />
                    </div>
                  );
                })
            ) : (
              <div className="d-flex">
                <p>{t('noSpecialization')}</p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DoctorAccountRatesBlock;
