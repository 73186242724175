import { AnyAction } from 'redux';
import {
  FETCH_CURRENCY_REQUEST,
  FETCH_CURRENCY_ERROR,
  FETCH_CURRENCY_SUCCESS,
} from 'shared/redux/types/CurrencyTypes';

export const fetchCurrencyRequest = (payload: any): AnyAction => ({
  type: FETCH_CURRENCY_REQUEST,
  payload,
});

export const fetchCurrencySuccess = (payload: any): AnyAction => ({
  type: FETCH_CURRENCY_SUCCESS,
  payload,
});

export const fetchCurrencyError = (payload: any): AnyAction => ({
  type: FETCH_CURRENCY_ERROR,
  payload,
});
