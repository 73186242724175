import { AnyAction } from 'redux';
import {
  ASSOCIATE_SPECIALIZATION_SUCCESS,
  FETCH_TITLES_SUCCESS,
  FETCH_USER_PROFILE_SUCCESS,
  GET_MY_SPECIALIZATIONS_SUCCESS,
  LOCALLY_ADD_CITY,
  LOCALLY_ADD_SPECIALIZATION,
  LOCALLY_REMOVE_SPECIALIZATION,
  PATCH_USER_PROFILE_SUCCESS,
  SET_AVAILABILITY_SUCCESS,
} from 'shared/redux/types/UserProfileTypes';
import { LOGIN_SUCCESS } from 'shared/redux/types/AuthTypes';

const UserProfileReducer = (state = {}, action: AnyAction = { type: '' }) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, ...action.payload.userData };
    case FETCH_USER_PROFILE_SUCCESS:
    case PATCH_USER_PROFILE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const AssociateSpecializationReducer = (state = {}, action: AnyAction = { type: '' }) => {
  switch (action.type) {
    case ASSOCIATE_SPECIALIZATION_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const SetAvailabilityReducer = (state = {}, action: AnyAction = { type: '' }) => {
  switch (action.type) {
    case SET_AVAILABILITY_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const LocalSpecializationReducer = (state = {}, action: AnyAction = { type: '' }) => {
  switch (action.type) {
    case LOCALLY_ADD_SPECIALIZATION:
      return action.payload;
    case LOCALLY_REMOVE_SPECIALIZATION:
      return null;
    default:
      return state;
  }
};

const MySpecializationsReducer = (state = [], action: AnyAction = { type: '' }) => {
  switch (action.type) {
    case GET_MY_SPECIALIZATIONS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const LocalCityReducer = (state = {}, action: AnyAction = (() => {}) as unknown as AnyAction) => {
  switch (action.type) {
    case LOCALLY_ADD_CITY:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const TitlesReducer = (state = [], action: AnyAction = (() => {}) as unknown as AnyAction) => {
  switch (action.type) {
    case FETCH_TITLES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
export {
  UserProfileReducer,
  AssociateSpecializationReducer,
  SetAvailabilityReducer,
  LocalSpecializationReducer,
  MySpecializationsReducer,
  LocalCityReducer,
  TitlesReducer,
};
