import { default as ApiConstants } from 'shared/constants/ApiConstants.json';
import ApiUtils from 'shared/modules/ApiUtils';

const getDoctorTimetableAPI = () => ApiUtils.getRequest(ApiConstants.GET_DOCTOR_TIMETABLE_ENDPOINT);

const patchDoctorTimetableAPI = ({ id, data }) =>
  ApiUtils.patchRequest(`${ApiConstants.GET_DOCTOR_TIMETABLE_ENDPOINT}/${id}`, data);

const addDoctorTimetableAPI = (payload) =>
  ApiUtils.postRequest(`${ApiConstants.GET_DOCTOR_TIMETABLE_ENDPOINT}/`, payload);

const addBulkDoctorTimetableAPI = (payload) =>
  ApiUtils.postRequest(ApiConstants.CREAT_DOCTOR_TIMETABLE_ENDPOINT, payload);

const removeDoctorTimetableAPI = (payload) =>
  ApiUtils.deleteRequest(`${ApiConstants.GET_DOCTOR_TIMETABLE_ENDPOINT}/${payload}`);

const getDoctorPastTimetableAPI = ({ startDate, daysLimit }) =>
  ApiUtils.getRequest(`${ApiConstants.GET_DOCTOR_TIMETABLE_ENDPOINT}/${startDate}/${daysLimit}`);

const getMergedDoctorTimetableAPI = () =>
  ApiUtils.getRequest(ApiConstants.GET_MERGED_DOCTOR_TIMETABLE_ENDPOINT);

const patchDoctorExceptionTimetableAPI = ({ id, data }) =>
  ApiUtils.putRequest(`${ApiConstants.DOCTOR_EXCEPTIONS_TIMETABLE_ENDPOINT}/${id}`, data);

const addDoctorExceptionTimetableAPI = (payload) =>
  ApiUtils.postRequest(`${ApiConstants.DOCTOR_EXCEPTION_TIMETABLE_ENDPOINT}`, payload);

const removeDoctorExceptionTimetableAPI = (payload) =>
  ApiUtils.deleteRequest(`${ApiConstants.DOCTOR_EXCEPTIONS_TIMETABLE_ENDPOINT}/${payload}`);

const patchDoctorRecurrentTimetableAPI = ({ id, data }) =>
  ApiUtils.putRequest(`${ApiConstants.DOCTOR_RECURRENCES_TIMETABLE_ENDPOINT}/${id}`, data);

const addDoctorRecurrentTimetableAPI = (payload) =>
  ApiUtils.postRequest(`${ApiConstants.DOCTOR_RECURRENCE_TIMETABLE_ENDPOINT}`, payload);

const removeDoctorRecurrentTimetableAPI = (payload) =>
  ApiUtils.deleteRequest(`${ApiConstants.DOCTOR_RECURRENCES_TIMETABLE_ENDPOINT}/${payload}`);

export {
  getDoctorTimetableAPI,
  patchDoctorTimetableAPI,
  addDoctorTimetableAPI,
  removeDoctorTimetableAPI,
  getDoctorPastTimetableAPI,
  addBulkDoctorTimetableAPI,
  getMergedDoctorTimetableAPI,
  patchDoctorExceptionTimetableAPI,
  patchDoctorRecurrentTimetableAPI,
  addDoctorExceptionTimetableAPI,
  removeDoctorExceptionTimetableAPI,
  addDoctorRecurrentTimetableAPI,
  removeDoctorRecurrentTimetableAPI,
};
