import { profileDetailsSelector } from 'shared/redux/selector/ProfileDetailsSelector';

export const familyMemberSelector = (familyMembers, userId) => {
  if (Array.isArray(familyMembers)) {
    return familyMembers
      .filter((row) => row.account_status === 'active')
      .map((familyMember) => {
        return { ...profileDetailsSelector(familyMember), hasParent: familyMember?.id !== userId };
      });
  }
  return [];
};
