import { AnyAction } from 'redux';
import {
  FETCH_PATIENT_PROFILE_ERROR,
  FETCH_PATIENT_PROFILE_REQUEST,
  FETCH_PATIENT_PROFILE_SUCCESS,
  GET_PATIENT_MEDICAL_RECORDS_ERROR,
  GET_PATIENT_MEDICAL_RECORDS_REQUEST,
  GET_PATIENT_MEDICAL_RECORDS_SUCCESS,
  LOCALLY_UPDATE_PATIENT_PROFILE,
  PATCH_PATIENT_PROFILE_ERROR,
  PATCH_PATIENT_PROFILE_REQUEST,
  PATCH_PATIENT_PROFILE_SUCCESS,
  PATIENT_ADD_MEDICAL_RECORD_ERROR,
  PATIENT_ADD_MEDICAL_RECORD_REQUEST,
  PATIENT_ADD_MEDICAL_RECORD_SUCCESS,
  PATIENT_DELETE_MEDICAL_RECORD_ERROR,
  PATIENT_DELETE_MEDICAL_RECORD_REQUEST,
  PATIENT_DELETE_MEDICAL_RECORD_SUCCESS,
  POST_PATIENT_PROFILE_ERROR,
  POST_PATIENT_PROFILE_REQUEST,
  POST_PATIENT_PROFILE_SUCCESS,
  GET_PATIENT_VOUCHERS_REQUEST,
  GET_PATIENT_VOUCHERS_SUCCESS,
  GET_PATIENT_VOUCHERS_ERROR,
  LOCALLY_CURRENT_PATIENT_PROFILE,
  PATIENT_UPDATE_MEDICAL_RECORD_REQUEST,
  PATIENT_UPDATE_MEDICAL_RECORD_SUCCESS,
  PATIENT_UPDATE_MEDICAL_RECORD_ERROR,
} from 'shared/redux/types/PatientProfileTypes';

export const fetchPatientProfileRequest = (payload): AnyAction => ({
  type: FETCH_PATIENT_PROFILE_REQUEST,
  payload,
});

export const fetchPatientProfileSuccess = (payload): AnyAction => ({
  type: FETCH_PATIENT_PROFILE_SUCCESS,
  payload,
});

export const fetchPatientProfileError = (payload): AnyAction => ({
  type: FETCH_PATIENT_PROFILE_ERROR,
  payload,
});

export const postPatientProfileRequest = (payload): AnyAction => ({
  type: POST_PATIENT_PROFILE_REQUEST,
  payload,
});

export const postPatientProfileSuccess = (payload): AnyAction => ({
  type: POST_PATIENT_PROFILE_SUCCESS,
  payload,
});

export const postPatientProfileError = (payload): AnyAction => ({
  type: POST_PATIENT_PROFILE_ERROR,
  payload,
});

export const patchPatientProfileRequest = (payload): AnyAction => ({
  type: PATCH_PATIENT_PROFILE_REQUEST,
  payload,
});

export const patchPatientProfileSuccess = (payload): AnyAction => ({
  type: PATCH_PATIENT_PROFILE_SUCCESS,
  payload,
});

export const patchPatientProfileError = (payload): AnyAction => ({
  type: PATCH_PATIENT_PROFILE_ERROR,
  payload,
});

export const locallyUpdatePatientProfile = (payload): AnyAction => ({
  type: LOCALLY_UPDATE_PATIENT_PROFILE,
  payload,
});

export const locallyCurrentPatientProfile = (payload): AnyAction => ({
  type: LOCALLY_CURRENT_PATIENT_PROFILE,
  payload,
});

export const patientAddMedicalRecordRequest = (payload): AnyAction => ({
  type: PATIENT_ADD_MEDICAL_RECORD_REQUEST,
  payload,
});

export const patientAddMedicalRecordSuccess = (payload): AnyAction => ({
  type: PATIENT_ADD_MEDICAL_RECORD_SUCCESS,
  payload,
});

export const patientAddMedicalRecordError = (payload): AnyAction => ({
  type: PATIENT_ADD_MEDICAL_RECORD_ERROR,
  payload,
});

export const patientUpdateMedicalRecordRequest = (payload): AnyAction => ({
  type: PATIENT_UPDATE_MEDICAL_RECORD_REQUEST,
  payload,
});

export const patientUpdateMedicalRecordSuccess = (payload): AnyAction => ({
  type: PATIENT_UPDATE_MEDICAL_RECORD_SUCCESS,
  payload,
});

export const patientUpdateMedicalRecordError = (payload): AnyAction => ({
  type: PATIENT_UPDATE_MEDICAL_RECORD_ERROR,
  payload,
});

export const getPatientMedicalRecordsRequest = (payload): AnyAction => ({
  type: GET_PATIENT_MEDICAL_RECORDS_REQUEST,
  payload,
});

export const getPatientMedicalRecordsSuccess = (payload): AnyAction => ({
  type: GET_PATIENT_MEDICAL_RECORDS_SUCCESS,
  payload,
});

export const getPatientMedicalRecordsError = (payload): AnyAction => ({
  type: GET_PATIENT_MEDICAL_RECORDS_ERROR,
  payload,
});

export const patientDeleteMedicalRecordRequest = (payload): AnyAction => ({
  type: PATIENT_DELETE_MEDICAL_RECORD_REQUEST,
  payload,
});

export const patientDeleteMedicalRecordSuccess = (payload): AnyAction => ({
  type: PATIENT_DELETE_MEDICAL_RECORD_SUCCESS,
  payload,
});

export const patientDeleteMedicalRecordError = (payload): AnyAction => ({
  type: PATIENT_DELETE_MEDICAL_RECORD_ERROR,
  payload,
});
