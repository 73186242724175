import { Link } from 'react-router-dom';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const NavTopMenu = () => {
  const { t } = useTranslation();
  return (
    <div className="nav-top-menu">
      <div className="container-xxl">
        <ul>
          <li>
            <Link className="" to="/doctor/landing">
              {t('imADoctor')}
            </Link>
          </li>
          <li>
            <Link className="" to="/patient/login">
              {t('patientAuthentication')}
            </Link>
          </li>
          <li className="register">
            <Link className="cursor-pointer" to="/patient/register">
              {t('patientApp.createAccount')}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavTopMenu;
