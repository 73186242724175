import { AnyAction } from 'redux';
import { FETCH_DOCTOR_PROFILE_SUCCESS } from 'shared/redux/types/DoctorProfileTypes';

const DoctorProfileReducer = (
  state = {
    fullNameWithTitle: '',
    firstName: '',
    lastName: '',
    doctorSpecializations: [],
    organization: '',
    address: '',
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case FETCH_DOCTOR_PROFILE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default DoctorProfileReducer;
