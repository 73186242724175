export const GET_PAYMENT_METHODS_REQUEST = 'GET_PAYMENT_METHODS_REQUEST';
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_ERROR = 'GET_PAYMENT_METHODS_ERROR';

export const CREATE_PAYMENT_REQUEST = 'CREATE_PAYMENT_REQUEST';
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_ERROR = 'CREATE_PAYMENT_ERROR';

export const APPOINTMENT_PAYMENT_REQUEST = 'APPOINTMENT_PAYMENT_REQUEST';
export const APPOINTMENT_PAYMENT_SUCCESS = 'APPOINTMENT_PAYMENT_SUCCESS';
export const APPOINTMENT_PAYMENT_ERROR = 'APPOINTMENT_PAYMENT_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'CONFIRM_PAYMENT_ERROR';

export const CREATE_PAYMENT_METHOD_REQUEST = 'CREATE_PAYMENT_METHOD_REQUEST';
export const CREATE_PAYMENT_METHOD_SUCCESS = 'CREATE_PAYMENT_METHOD_SUCCESS';
export const CREATE_PAYMENT_METHOD_ERROR = 'CREATE_PAYMENT_METHOD_ERROR';

export const SAVE_PAYMENT_METHOD_REQUEST = 'SAVE_PAYMENT_METHOD_REQUEST';
export const SAVE_PAYMENT_METHOD_SUCCESS = 'SAVE_PAYMENT_METHOD_SUCCESS';
export const SAVE_PAYMENT_METHOD_ERROR = 'SAVE_PAYMENT_METHOD_ERROR';

export const REMOVE_PAYMENT_METHOD_REQUEST = 'REMOVE_PAYMENT_METHOD_REQUEST';
export const REMOVE_PAYMENT_METHOD_SUCCESS = 'REMOVE_PAYMENT_METHOD_SUCCESS';
export const REMOVE_PAYMENT_METHOD_ERROR = 'REMOVE_PAYMENT_METHOD_ERROR';

export const GET_PATIENT_TRANSACTIONS_REQUEST = 'GET_PATIENT_TRANSACTIONS_REQUEST';
export const GET_PATIENT_TRANSACTIONS_SUCCESS = 'GET_PATIENT_TRANSACTIONS_SUCCESS';
export const GET_PATIENT_TRANSACTIONS_ERROR = 'GET_PATIENT_TRANSACTIONS_ERROR';

export const LOCALLY_CHOOSE_CARD = 'LOCALLY_CHOOSE_CARD';
