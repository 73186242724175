export const GET_SORTING_OPTIONS_REQUEST = 'GET_SORTING_OPTIONS_REQUEST';
export const GET_SORTING_OPTIONS_SUCCESS = 'GET_SORTING_OPTIONS_SUCCESS';
export const GET_SORTING_OPTIONS_ERROR = 'GET_SORTING_OPTIONS_ERROR';

export const GET_SPECIALIZATION_DOCTORS_REQUEST = 'GET_SPECIALIZATION_DOCTORS_REQUEST';
export const GET_SPECIALIZATION_DOCTORS_SUCCESS = 'GET_SPECIALIZATION_DOCTORS_SUCCESS';
export const GET_SPECIALIZATION_DOCTORS_ERROR = 'GET_SPECIALIZATION_DOCTORS_ERROR';

export const GET_DOCTOR_TIME_SLOTS_REQUEST = 'GET_DOCTOR_TIME_SLOTS_REQUEST';
export const GET_DOCTOR_TIME_SLOTS_SUCCESS = 'GET_DOCTOR_TIME_SLOTS_SUCCESS';
export const GET_DOCTOR_TIME_SLOTS_ERROR = 'GET_DOCTOR_TIME_SLOTS_ERROR';

export const AVAILABLE_DOCTORS_REQUEST = 'AVAILABLE_DOCTORS_REQUEST';
export const AVAILABLE_DOCTORS_SUCCESS = 'AVAILABLE_DOCTORS_SUCCESS';
export const AVAILABLE_DOCTORS_ERROR = 'AVAILABLE_DOCTORS_ERROR';

export const PATIENT_LOCALLY_CHOOSE_SPECIALIZATION = 'PATIENT_LOCALLY_CHOOSE_SPECIALIZATION';
