const referralSelector = (invitations) => {
  // eslint-disable-next-line no-underscore-dangle
  const invitationsArray = invitations._embedded?.items ?? [];

  return invitationsArray.map((row) => {
    return {
      id: row?.id,
      name: row.phone_number,
      invitationDate: row.created_at,
    };
  });
};

const fetchDoctorsAllIndexSelector = (doctors) => {
  // eslint-disable-next-line no-underscore-dangle
  const doctorsCollection = doctors._embedded?.items ?? [];
  const data: Array<Record<string, unknown>> = [];

  // eslint-disable-next-line array-callback-return
  doctorsCollection.map((row) => {
    const name = row.full_name_with_title;
    const specializations: Array<Record<string, unknown>> = [];
    // eslint-disable-next-line array-callback-return
    row.doctor_specializations.map((doctorSpecialization) => {
      specializations.push(doctorSpecialization.specialization.name);
    });
    data.push({
      id: row.id,
      firstName: row.first_name,
      lastName: row.last_name,
      name,
      imgUrl: row.picture_media?.public_url ?? null,
      specializations,
    });
  });

  return { data };
};

export { referralSelector, fetchDoctorsAllIndexSelector };
