import dayjs from 'dayjs';

const doctorTimetableSelector = (timetables, past = false) => {
  const timetableObject = {};
  timetables.map((row) => {
    const formattedDate = past
      ? dayjs(row.date).add(7, 'day').format('YYYY-MM-DD')
      : dayjs(row.date).format('YYYY-MM-DD');
    timetableObject[formattedDate] = timetableObject[formattedDate] ?? [];
    timetableObject[formattedDate].push({
      id: row?.id,
      date: formattedDate,
      timeStart: dayjs(row.time_start).format('HH:mm'),
      timeStop: dayjs(row.time_stop).format('HH:mm'),
    });
    return true;
  });
  return timetableObject;
};

const doctorMergedTimetableSelector = (days) => {
  return days?.map((timetables) => {
    return timetables?.map((row) => {
      return {
        id: row?.id,
        dayOfWeek: row.day_of_week,
        entryType: row.entry_type,
        type: row?.type,
        date: row?.date ? dayjs(row?.date).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
        timeStart: row.time_start ? dayjs(row.time_start).format('HH:mm') : dayjs().format('HH:mm'),
        timeStop: row.time_stop ? dayjs(row.time_stop).format('HH:mm') : dayjs().format('HH:mm'),
      };
    });
  });
};

export { doctorTimetableSelector, doctorMergedTimetableSelector };
