export const FETCH_REFERRALS_REQUEST = 'FETCH_REFERRALS_REQUEST';
export const FETCH_REFERRALS_SUCCESS = 'FETCH_REFERRALS_SUCCESS';
export const FETCH_REFERRALS_ERROR = 'FETCH_REFERRALS_ERROR';

export const FETCH_SENT_REFERRALS_REQUEST = 'FETCH_SENT_REFERRALS_REQUEST';
export const FETCH_SENT_REFERRALS_SUCCESS = 'FETCH_SENT_REFERRALS_SUCCESS';
export const FETCH_SENT_REFERRALS_ERROR = 'FETCH_SENT_REFERRALS_ERROR';

export const SET_REFERRAL_REQUEST = 'SET_REFERRAL_REQUEST';
export const SET_REFERRAL_SUCCESS = 'SET_REFERRAL_SUCCESS';
export const SET_REFERRAL_ERROR = 'SET_REFERRAL_ERROR';

export const SEND_REFERRAL_INVITATION_REQUEST = 'SEND_REFERRAL_INVITATION_REQUEST';
export const SEND_REFERRAL_INVITATION_SUCCESS = 'SEND_REFERRAL_INVITATION_SUCCESS';
export const SEND_REFERRAL_INVITATION_ERROR = 'SEND_REFERRAL_INVITATION_ERROR';

export const FETCH_REFERRAL_BONUS_REQUEST = 'FETCH_REFERRAL_BONUS_REQUEST';
export const FETCH_REFERRAL_BONUS_SUCCESS = 'FETCH_REFERRAL_BONUS_SUCCESS';
export const FETCH_REFERRAL_BONUS_ERROR = 'FETCH_REFERRAL_BONUS_ERROR';

export const FETCH_DOCTORS_ALL_INDEX_REQUEST = 'FETCH_DOCTORS_ALL_INDEX_REQUEST';
export const FETCH_DOCTORS_ALL_INDEX_SUCCESS = 'FETCH_DOCTORS_ALL_INDEX_SUCCESS';
export const FETCH_DOCTORS_ALL_INDEX_ERROR = 'FETCH_DOCTORS_ALL_INDEX_ERROR';
