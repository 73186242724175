import { actionChannel, call, fork, put, take } from 'redux-saga/effects';
import i18next from 'i18next';
import {
  fetchDoctorsAllIndexError,
  fetchDoctorsAllIndexSuccess,
  fetchReferralBonusError,
  fetchReferralBonusSuccess,
  fetchReferralsError,
  fetchReferralsSuccess,
  fetchSentReferralsError,
  fetchSentReferralsSuccess,
  sendReferralInvitationError,
  sendReferralInvitationSuccess,
  setReferralError,
} from '../actions/ReferralsActions';
import {
  fetchDoctorsAllIndexApi,
  fetchReferralBonusApi,
  getReferralApi,
  sendReferralInvitationApi,
  setReferralApi,
} from '../api/ReferralApi';
import {
  FETCH_DOCTORS_ALL_INDEX_REQUEST,
  FETCH_REFERRAL_BONUS_REQUEST,
  FETCH_REFERRALS_REQUEST,
  FETCH_SENT_REFERRALS_REQUEST,
  SEND_REFERRAL_INVITATION_REQUEST,
  SET_REFERRAL_REQUEST,
} from '../types/Referrals';
import { fetchDoctorsAllIndexSelector, referralSelector } from '../selector/ReferralSelector';
import Alerts from '../../components/Alerts';
import NavigationService from '../../services/NavigationService';
import { getDestination } from '../../modules/AuthUtils';

function* setReferral(actionType) {
  const setReferralChanel = yield actionChannel(actionType);

  while (true) {
    const { payload } = yield take(setReferralChanel);

    try {
      const destination = getDestination();
      if (payload.referrer !== undefined) {
        const response = yield call(setReferralApi, { referer: payload.referrer });

        Alerts.actionAlert(
          i18next.t('info'),
          i18next.t('mlm.referralSuccess'),
          i18next.t('ok'),
          () => {
            NavigationService.navigate(`/${destination}/login`);
          },
        );
      }
      NavigationService.navigate(`/${destination}/login`);
    } catch ({ message }) {
      yield put(setReferralError({ message }));
    }
  }
}

function* getReferral(actionType) {
  const getReferralChanel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(getReferralChanel);
    try {
      const safeAction = {
        limit: 15,
        page: 1,
        ...payload,
      };
      const response = yield call(getReferralApi, safeAction);
      yield put(
        fetchReferralsSuccess({
          data: referralSelector(response.data),
          nextPage: payload.nextPage,
          noOfPages: response.data.pages,
          total: response.data.total,
        }),
      );
    } catch ({ message }) {
      yield put(fetchReferralsError({ message }));
    }
  }
}

function* getSentReferral(actionType) {
  const getReferralChanel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(getReferralChanel);
    try {
      const safeAction = {
        limit: 15,
        page: 1,
        ...payload,
      };
      const response = yield call(getReferralApi, safeAction);
      yield put(
        fetchSentReferralsSuccess({
          data: referralSelector(response.data),
          nextPage: payload.nextPage,
          noOfPages: response.data.pages,
          total: response.data.total,
        }),
      );
    } catch ({ message }) {
      yield put(fetchSentReferralsError({ message }));
    }
  }
}

function* sendReferralInvitation(actionType) {
  const sendReferralInvitationChanel = yield actionChannel(actionType);

  while (true) {
    const { payload } = yield take(sendReferralInvitationChanel);

    try {
      yield call(sendReferralInvitationApi, payload);
      yield put(sendReferralInvitationSuccess({}));
      Alerts.actionAlert(
        `${i18next.t('success')}`,
        `${i18next.t('mlm.invitationSent')}`,
        'Ok',
        () => {
          window.location.href = `/doctor/recommend`;
        },
      );
    } catch ({ message }) {
      yield put(sendReferralInvitationError({ message }));
    }
  }
}

function* fetchReferralBonus(actionType) {
  const fetchReferralBonusChanel = yield actionChannel(actionType);

  while (true) {
    yield take(fetchReferralBonusChanel);
    try {
      const response = yield call(fetchReferralBonusApi);
      yield put(fetchReferralBonusSuccess(response.data));
    } catch ({ message }) {
      yield put(fetchReferralBonusError({ message }));
    }
  }
}

function* fetchDoctorsAllIndex(actionType) {
  const fetchDoctorsAllIndexChanel = yield actionChannel(actionType);

  while (true) {
    const { payload } = yield take(fetchDoctorsAllIndexChanel);

    try {
      const response = yield call(fetchDoctorsAllIndexApi, payload);
      yield put(fetchDoctorsAllIndexSuccess(fetchDoctorsAllIndexSelector(response.data)));
    } catch ({ message }) {
      yield put(fetchDoctorsAllIndexError({ message }));
    }
  }
}

function* referralSaga() {
  yield fork(setReferral, SET_REFERRAL_REQUEST);
  yield fork(getReferral, FETCH_REFERRALS_REQUEST);
  yield fork(getSentReferral, FETCH_SENT_REFERRALS_REQUEST);
  yield fork(sendReferralInvitation, SEND_REFERRAL_INVITATION_REQUEST);
  yield fork(fetchReferralBonus, FETCH_REFERRAL_BONUS_REQUEST);
  yield fork(fetchDoctorsAllIndex, FETCH_DOCTORS_ALL_INDEX_REQUEST);
}

export default referralSaga;
