import { AnyAction } from 'redux';
import {
  FETCH_DOCTOR_PROFILE_ERROR,
  FETCH_DOCTOR_PROFILE_REQUEST,
  FETCH_DOCTOR_PROFILE_SUCCESS,
} from 'shared/redux/types/DoctorProfileTypes';

export const fetchDoctorProfileRequest = (payload): AnyAction => ({
  type: FETCH_DOCTOR_PROFILE_REQUEST,
  payload,
});

export const fetchDoctorProfileSuccess = (payload): AnyAction => ({
  type: FETCH_DOCTOR_PROFILE_SUCCESS,
  payload,
});

export const fetchDoctorProfileError = (payload): AnyAction => ({
  type: FETCH_DOCTOR_PROFILE_ERROR,
  payload,
});
