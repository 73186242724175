import { default as ApiConstants } from 'shared/constants/ApiConstants.json';
import ApiUtils from 'shared/modules/ApiUtils';

const appointmentEndConsultationAPI = ({ appointmentId, data }) =>
  ApiUtils.putRequest(
    `${ApiConstants.USER_APPOINTMENT_ENDPOINT}/${appointmentId}/end-consultation`,
    data,
  );

const appointmentReportIssueAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.postRequest(ApiConstants.USER_REPORT_APPOINTMENT_ISSUE_ENDPOINT, payload);

const getAppointmentSettingsAPI = () =>
  ApiUtils.getRequest(ApiConstants.APPOINTMENT_SETTINGS_ENDPOINT);

const getCurrentAppointmentAPI = () =>
  ApiUtils.getRequest(ApiConstants.GET_CURRENT_APPOINTMENT_ENDPOINT);

export {
  appointmentEndConsultationAPI,
  appointmentReportIssueAPI,
  getAppointmentSettingsAPI,
  getCurrentAppointmentAPI,
};
