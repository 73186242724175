import React, { useEffect, useState } from 'react';
import 'patient/styles/account-transaction-history.css';
import PatientTransactionHistoryTransactionBox from 'patient/components/PatientTransactionHistoryTransactionBox';
import InfiniteScroll from 'react-infinite-scroll-component';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import { getPatientTransactionsState } from 'shared/redux/src/StatesGetter';
import { getPatientTransactionsRequest } from 'shared/redux/actions/PatientPaymentActions';
import { useTranslation } from 'react-i18next';

const PatientAccountTransactionHistoryBlock = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const {
    data: transactionsData,
    currentPage,
    noOfPages,
  } = useSelector(getPatientTransactionsState, shallowEqual);
  const loadNextPageTransactions = () => {
    if (currentPage <= noOfPages) {
      dispatch(
        getPatientTransactionsRequest({
          page: currentPage,
          nextPage: true,
        }),
      );
    }
  };

  return (
    <div className="account-transaction-history">
      <div className="title-section">
        <h2>{t('wallet.transactions')}</h2>
      </div>
      <InfiniteScroll
        dataLength={transactionsData.length}
        next={loadNextPageTransactions}
        hasMore={currentPage <= noOfPages}
        loader={<LoadingSpinner isLoading />}
        scrollableTarget="infiniteScrollContainerTransactions"
      >
        {!transactionsData.length && (
          <div className="empty-section">{t('wallet.noTransactions')}</div>
        )}

        {transactionsData?.map((transaction, index, transactions) => {
          return (
            <React.Fragment key={transaction.id}>
              {transaction.createdAtFormatted !== transactions[index - 1]?.createdAtFormatted && (
                <div className="subtitle">{transaction.createdAtFormatted}</div>
              )}
              <div className="transaction">
                {transaction.refund ? (
                  <>
                    <PatientTransactionHistoryTransactionBox transaction={transaction} refund />
                    <PatientTransactionHistoryTransactionBox
                      transaction={transaction}
                      refund={false}
                    />
                  </>
                ) : (
                  <PatientTransactionHistoryTransactionBox
                    transaction={transaction}
                    refund={false}
                  />
                )}
              </div>
            </React.Fragment>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default PatientAccountTransactionHistoryBlock;
