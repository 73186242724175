import { actionChannel, call, put, take, fork, ActionPattern, select } from 'redux-saga/effects';
import i18next from 'i18next';
import { Action } from 'redux';
import {
  changePasswordError,
  changePasswordSuccess,
  confirmEmailError,
  confirmEmailSuccess,
  deleteAccountError,
  deleteAccountSuccess,
  forceLogoutError,
  forceLogoutSuccess,
  forgotPasswordError,
  forgotPasswordSuccess,
  requestLoginError,
  requestLoginSuccess,
  requestLogoutError,
  requestLogoutSuccess,
  resendTokenError,
  resendTokenSuccess,
  resetPasswordError,
  resetPasswordSuccess,
} from 'shared/redux/actions/AuthActions';
import {
  googleLoginAPI,
  facebookLoginAPI,
  logoutUserAPI,
  loginUserAPI,
  appleLoginAPI,
  forgotPasswordAPI,
  changePasswordAPI,
  resetPasswordAPI,
  deleteAccountAPI,
  confirmEmailAPI,
  forceLogoutAPI,
  resendActivationAPI,
} from 'shared/redux/api/AuthApi';
import {
  CHANGE_PASSWORD_REQUEST,
  CONFIRM_EMAIL_REQUEST,
  DELETE_ACCOUNT_REQUEST,
  FORCE_LOGOUT_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  RESEND_TOKEN_REQUEST,
  RESET_PASSWORD_REQUEST,
} from 'shared/redux/types/AuthTypes';
import { fetchCountriesRequest } from 'shared/redux/actions/CountryActions';
import { fetchLanguagesRequest } from 'shared/redux/actions/LanguageActions';
import { getPossibleKinshipRequest } from 'shared/redux/actions/KinshipActions';
import Alerts from 'shared/components/Alerts';
import StorageService from 'shared/services/StorageService';
import { getEnv } from 'shared/services/EnvService';
import LocalEventsService from 'shared/services/LocalEventsService';
import firebase from 'firebase';
import {
  getActiveRole,
  getDestination,
  handleExtraLoginResponse,
  handleLoginResponse,
} from 'shared/modules/AuthUtils';
import NavigationService from 'shared/services/NavigationService';
import Utils from 'shared/modules/Utils';

const resolveLoginApi = (loginType: any, loginData: Record<string, unknown> | null | undefined) => {
  // eslint-disable-next-line consistent-return
  return new Promise((resolve, reject) => {
    switch (loginType) {
      case 'classic':
        loginUserAPI(loginData)
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
        break;
      case 'google':
        googleLoginAPI(loginData)
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
        break;
      case 'facebook':
        facebookLoginAPI(loginData)
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
        break;
      case 'apple':
        appleLoginAPI(loginData)
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
        break;
      default:
        loginUserAPI(loginData)
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
    }
  });
};

const handleLoginSuccessResponse = async (response) => {
  const rolesArray = response?.roles ?? [];
  const isProfileCompleted =
    getEnv('APP_TYPE', true) === 'doctor'
      ? rolesArray.indexOf('ROLE_UNPROFILED_DOCTOR') === -1
      : rolesArray.indexOf('ROLE_UNPROFILED_PATIENT') === -1;
  const isConfirmed = response.accountStatus === 'active';
  const { activeRole } = response;
  const isLoggedIn = true;
  const hasFullAccess = isConfirmed && isProfileCompleted;
  await handleLoginResponse(response.auth);
  await handleExtraLoginResponse(
    isProfileCompleted ? 'true' : 'Not Completed',
    isConfirmed ? 'true' : 'Not Confirmed',
  );
  localStorage.setItem('APP_VERSION', `${getEnv('VERSION')}`);
  return { isProfileCompleted, isConfirmed, isLoggedIn, hasFullAccess, activeRole };
};

function* logIn(actionType: ActionPattern<Action<any>>) {
  const loginChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(loginChannel);
    try {
      const deviceRegistrationId = null;

      const response = yield call(resolveLoginApi, payload.type, {
        ...payload.data,
        deviceRegistrationId,
      });

      if (!response || !response.data || response?.data?.status === 'failure') {
        yield put(requestLoginError({ message: i18next.t('wrongUser') }));
      } else {
        if (
          response.data.accountStatus === 'unconfirmed' &&
          response.data.activeRole === 'ROLE_PATIENT'
        ) {
          Alerts.actionAlert(
            i18next.t('info'),
            i18next.t('alerts.registerCheck'),
            i18next.t('ok'),
            () => {
              NavigationService.navigate(`/patient/resend-confirmation-token`);
            },
          );
          yield put(requestLoginError({}));
        }
        const { isProfileCompleted, isConfirmed, isLoggedIn, hasFullAccess, activeRole } =
          yield call(handleLoginSuccessResponse, response.data);

        StorageService.setData('IS_PHARMACIST', activeRole === 'ROLE_PHARMACIST');

        yield put(
          requestLoginSuccess({
            isProfileCompleted,
            isConfirmed,
            isLoggedIn,
            hasFullAccess,
            activeRole,
            isDoctor: activeRole === 'ROLE_DOCTOR',
            userData: response.data?.userData,
          }),
        );
        yield call(
          handleExtraLoginResponse,
          isProfileCompleted ? 'true' : 'Not Completed',
          isConfirmed ? 'true' : 'Not Confirmed',
        );
        LocalEventsService.emit('loginDone', {
          isProfileCompleted,
          isConfirmed,
          isLoggedIn,
          activeRole,
        });
        yield put(fetchCountriesRequest({}));
        yield put(fetchLanguagesRequest({}));
        yield put(getPossibleKinshipRequest({}));

        const currentLink = localStorage.getItem('CURRENT_LINK');
        if (currentLink !== '' && currentLink !== null) {
          window.location.href = currentLink;
          localStorage.removeItem('CURRENT_LINK');
        }
      }
      // }
    } catch (e: any) {
      const destination = getDestination();
      if (!payload.unconfirmedModalAlert && e?.status !== 412) {
        // classic login failure
        yield put(requestLoginError({ message: e?.message }));
      } else if (payload.unconfirmedModalAlert && e?.status === 412) {
        // unconfirmed login failure (normally from any login type)
        Alerts.actionAlert(
          i18next.t('info'),
          i18next.t('alerts.registerCheck'),
          i18next.t('ok'),
          () => {
            NavigationService.navigate(`/${destination}/resend-confirmation-token`);
          },
        );
        yield put(requestLoginError({}));
      } else if (!payload.unconfirmedModalAlert && e?.status === 412) {
        // registration login failure
        Alerts.actionAlert(
          i18next.t('info'),
          i18next.t('alerts.registerCheck'),
          i18next.t('ok'),
          () => {
            NavigationService.navigate(`/${destination}/login`);
          },
        );
        yield put(requestLoginError({}));
      } else {
        // just in case
        yield put(requestLoginError({ message: e?.message }));
      }
    }
  }
}

function* logOut(actionType: ActionPattern<Action<any>>) {
  const logoutChannel = yield actionChannel(actionType);
  while (true) {
    yield take(logoutChannel);
    try {
      yield call(logoutUserAPI);
      yield put(requestLogoutSuccess({}));
      yield call(StorageService.clearAll);
      LocalEventsService.emit('logOut');
    } catch ({ message }) {
      yield put(requestLogoutError({ message }));
    }
  }
}

function* forceLogOut(actionType) {
  const forceLogoutChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(forceLogoutChannel);
    try {
      const activeRole = yield call(getActiveRole);
      yield call(forceLogoutAPI, {
        toRequest: {
          token: payload,
        },
        customHeaders: {
          'X-Client-Destination': activeRole,
        },
      });
      yield put(forceLogoutSuccess({}));
      yield call(StorageService.clearAll);
      const destination = getDestination();
      NavigationService.navigate(`/${destination}/login`);
    } catch ({ message }) {
      yield put(forceLogoutError({ message }));
    }
  }
}

function* forgotPassword(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const forgetPasswordChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(forgetPasswordChannel);
    try {
      yield call(forgotPasswordAPI, payload);
      yield put(forgotPasswordSuccess({}));
      Alerts.actionAlert(
        i18next.t('info'),
        i18next.t('alerts.passwordResetCheck'),
        i18next.t('ok'),
        () => {
          NavigationService.navigate('/patient/login');
        },
      );
    } catch ({ message }) {
      yield put(forgotPasswordError({ message }));
    }
  }
}

/**
 * in case if user change it from settings
 * @param actionType
 */
function* changePassword(actionType: ActionPattern<Action<any>>) {
  const changePasswordChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(changePasswordChannel);
    try {
      yield call(changePasswordAPI, payload);
      Alerts.simpleAlert(`${i18next.t('info')}`, `${i18next.t('alerts.passwordUpdated')}`);
      yield put(changePasswordSuccess({}));
    } catch ({ message }) {
      yield put(changePasswordError({ message }));
    }
  }
}

/**
 * in case if user forgot his password
 * @param actionType
 */
function* resetPassword(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const resetPasswordChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(resetPasswordChannel);
    try {
      // @ts-ignore
      const response = yield call(resetPasswordAPI, payload.data);
      yield call(handleLoginResponse, response.data);
      const { isProfileCompleted, isConfirmed, isLoggedIn, hasFullAccess, activeRole } = yield call(
        handleLoginSuccessResponse,
        response.data,
      );
      yield put(
        requestLoginSuccess({
          isProfileCompleted,
          isConfirmed,
          isLoggedIn,
          hasFullAccess,
          activeRole,
          isDoctor: activeRole === 'ROLE_DOCTOR',
          userData: response.data?.userData,
        }),
      );
      LocalEventsService.emit('loginDone', { isProfileCompleted, isConfirmed, isLoggedIn });
      yield put(resetPasswordSuccess({}));
      Alerts.simpleAlert(i18next.t('info'), i18next.t('alerts.passwordUpdated'));
    } catch (e: any) {
      const destination = getDestination();
      if (e?.response?.status === 408) {
        NavigationService.navigate(`/${destination}/forgot-password`);
      }
      yield put(resetPasswordError({ message: e?.message }));
    }
  }
}

function* deleteAccount(actionType: ActionPattern<Action<any>>) {
  const deleteAccountChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(deleteAccountChannel);
    try {
      const response = yield call(deleteAccountAPI, payload);
      yield put(deleteAccountSuccess(response.data));
      yield put(requestLogoutSuccess({}));
      yield call(StorageService.clearAll);
      const destination = getDestination();
      NavigationService.navigate(`/${destination}/home`);
    } catch ({ message }) {
      yield put(deleteAccountError({ message }));
    }
  }
}

function* confirmEmail(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const confirmEmailChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(confirmEmailChannel);
    const destination = getDestination();
    try {
      // @ts-ignore
      const response = yield call(confirmEmailAPI, payload);
      if (!response || !response.data || response?.data?.status === 'failure') {
        yield put(confirmEmailError({ message: i18next.t('wrongUser') }));
      } else if (Utils.isMobileDevice() || !Utils.isSupportedBrowser()) {
        yield put(confirmEmailSuccess({}));
        NavigationService.navigate('/');
        Alerts.simpleAlert(`${i18next.t('info')}`, `${i18next.t('alerts.accountActivated')}`);
      } else {
        const { isProfileCompleted, isConfirmed, isLoggedIn, hasFullAccess, activeRole } =
          yield call(handleLoginSuccessResponse, response.data);
        yield put(
          confirmEmailSuccess({
            isLoggedIn: true,
            isConfirmed: true,
            isProfileCompleted,
            hasFullAccess: isProfileCompleted,
          }),
        );
        yield put(fetchCountriesRequest({}));
        yield put(fetchLanguagesRequest({}));
        if (!response.data.userData.referrer && !response.data.userData.userReferralCampaign) {
          NavigationService.navigate(`/${destination}/mlm`);
        } else {
          NavigationService.navigate(`/${destination}/home`);
        }

        Alerts.simpleAlert(`${i18next.t('info')}`, `${i18next.t('alerts.accountActivated')}`);
      }
    } catch ({ message }) {
      NavigationService.navigate(`/${destination}/login`);
      yield put(confirmEmailError({ message }));
    }
  }
}

function* resendToken(actionType) {
  const resendTokenChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(resendTokenChannel);
    try {
      const response = yield call(resendActivationAPI, payload);
      yield put(resendTokenSuccess(response.data));
    } catch ({ message }) {
      yield put(resendTokenError({ message }));
    }
  }
}

function* authSaga() {
  yield fork(logIn, LOGIN_REQUEST);
  yield fork(logOut, LOGOUT_REQUEST);
  yield fork(forgotPassword, FORGOT_PASSWORD_REQUEST);
  yield fork(changePassword, CHANGE_PASSWORD_REQUEST);
  yield fork(resetPassword, RESET_PASSWORD_REQUEST);
  yield fork(deleteAccount, DELETE_ACCOUNT_REQUEST);
  yield fork(confirmEmail, CONFIRM_EMAIL_REQUEST);
  yield fork(forceLogOut, FORCE_LOGOUT_REQUEST);
  yield fork(resendToken, RESEND_TOKEN_REQUEST);
}

export default authSaga;
