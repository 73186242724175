import { AnyAction } from 'redux';
import { antiDuplication } from 'shared/modules/ArrayUtils';
import {
  APPOINTMENT_ADD_REVIEW_SUCCESS,
  APPOINTMENT_CALL_NOW_SUCCESS,
  APPOINTMENT_CALL_SPECIALIST_SUCCESS,
  APPOINTMENT_CANCEL_SUCCESS,
  APPOINTMENT_EMERGENCY_SUCCESS,
  APPOINTMENT_LOCAL_STEPS_REQUEST,
  APPOINTMENT_SCHEDULE_SUCCESS,
  APPOINTMENT_SELECT_PROFILE_SUCCESS,
  FETCH_PATIENT_PAST_APPOINTMENTS_SUCCESS,
  FETCH_PATIENT_UPCOMING_APPOINTMENTS_SUCCESS,
  GET_PATIENT_APPOINTMENT_DETAILS_SUCCESS,
  SET_PENDING_APPOINTMENT,
} from 'shared/redux/types/PatientAppointmentTypes';
import {
  CONFIRM_PAYMENT_ERROR,
  CONFIRM_PAYMENT_SUCCESS,
  CREATE_PAYMENT_ERROR,
} from 'shared/redux/types/PatientPaymentTypes';
import {
  APPLY_VOUCHER_ERROR,
  APPLY_VOUCHER_SUCCESS,
  REMOVE_VOUCHER_SUCCESS,
} from '../types/VouchersTypes';

const defaultState = {
  id: 0,
  newAppointment: true,
  inProgress: false,
  status: null,
  callStatus: '',
  stickyStatus: '',
  duration: 0,
  startTime: undefined,
  endTime: '',
  type: null,
  noOfSteps: 0,
  price: 0,
  priceGrossAsString: '',
  scheduledAt: null,
  specializationId: 0,
  isActive: true,
  voucher: {},
  discountAsString: '',
  payment: {
    isPaid: false,
    status: null,
  },
  doctor: {
    id: 0,
    name: '',
    fullNameWithTitle: '',
    firstName: '',
    lastName: '',
    imgUrl: undefined,
    averageRating: 0,
    countRating: 0,
  },
  patient: {
    id: 0,
    name: '',
    fullNameWithTitle: '',
    firstName: '',
    lastName: '',
    imgUrl: undefined,
  },
  sharedFiles: [],
  enableVideo: true,
  enableAudio: true,
  isInitialTime: true,
  showTimeBubble: false,
  isOtherUserConnected: false,
  otherVideoEnabled: false,
  otherAudioEnabled: false,
  isCameraEnabled: true,
};

const PatientAppointmentReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case APPOINTMENT_EMERGENCY_SUCCESS:
    case APPOINTMENT_SCHEDULE_SUCCESS:
    case APPOINTMENT_CALL_NOW_SUCCESS:
      return { ...defaultState, ...action.payload, payment: {} };
    case APPOINTMENT_CALL_SPECIALIST_SUCCESS:
    case APPOINTMENT_LOCAL_STEPS_REQUEST:
      if (action.payload?.newAppointment === true) {
        return { ...defaultState, ...action.payload };
      }
      return { ...state, ...action.payload };
    case CONFIRM_PAYMENT_SUCCESS:
      return { ...state, payment: action.payload };
    case CONFIRM_PAYMENT_ERROR:
      return { ...state, payment: { ...state.payment, status: 'failed' } };
    case CREATE_PAYMENT_ERROR:
      return { ...state, payment: {} };
    case APPLY_VOUCHER_SUCCESS:
      return {
        ...state,
        voucher: action.payload.data?.voucher,
        price: action.payload.data?.price_as_string,
        priceGrossAsString: action.payload.data?.price_gross_as_string,
        discountAsString: action.payload.data?.discount_as_string,
      };
    case APPLY_VOUCHER_ERROR:
      return { ...state, voucher: {} };
    case REMOVE_VOUCHER_SUCCESS:
      return {
        ...state,
        voucher: {},
        price: action.payload.data?.price_as_string,
        priceGrossAsString: action.payload.data?.price_as_string,
        discountAsString: action.payload.data?.discount_as_string,
      };
    case APPOINTMENT_SELECT_PROFILE_SUCCESS:
      return { ...state, payment: {}, patient: action.payload };
    case APPOINTMENT_ADD_REVIEW_SUCCESS:
      return {
        ...state,
        status: 'reviewAdded',
      };
    default:
      return state;
  }
};

const PatientPastAppointmentsReducer = (
  state = { data: [], currentPage: 1, noOfPages: undefined },
  action: AnyAction = (() => {}) as unknown as AnyAction,
) => {
  switch (action.type) {
    case FETCH_PATIENT_PAST_APPOINTMENTS_SUCCESS:
      return {
        data: action.payload.nextPage
          ? antiDuplication([...state.data, ...action.payload.data], 'id')
          : action.payload.data,
        noOfPages: action.payload.noOfPages,
        currentPage: action.payload.nextPage ? state.currentPage + 1 : 2,
      };
    default:
      return state;
  }
};

const PatientUpcomingAppointmentsReducer = (
  state = { data: [], currentPage: 1, noOfPages: undefined },
  action: AnyAction = (() => {}) as unknown as AnyAction,
) => {
  switch (action.type) {
    case FETCH_PATIENT_UPCOMING_APPOINTMENTS_SUCCESS:
      return {
        data: action.payload.nextPage
          ? antiDuplication([...state.data, ...action.payload.data], 'id')
          : action.payload.data,
        noOfPages: action.payload.noOfPages,
        currentPage: action.payload.nextPage ? state.currentPage + 1 : 2,
      };
    case APPOINTMENT_CANCEL_SUCCESS:
      return {
        ...state,
        data: state.data.filter((row: Record<string, string>) => row?.id !== action.payload?.id),
      };
    default:
      return state;
  }
};

const PatientAppointmentDetailsReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case GET_PATIENT_APPOINTMENT_DETAILS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const PendingAppointmentReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case SET_PENDING_APPOINTMENT:
      return action.payload;
    default:
      return state;
  }
};

export {
  PatientAppointmentReducer,
  PatientAppointmentDetailsReducer,
  PatientPastAppointmentsReducer,
  PatientUpcomingAppointmentsReducer,
  PendingAppointmentReducer,
};
