import 'patient/styles/free-call.css';
import NavigationService from 'shared/services/NavigationService';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';

const PatientFreeCallUserData = () => {
  const dispatch = useDispatch();
  const [iAgree, setIAgree] = useState(false);
  return (
    <section className="free-call-medic">
      <div className="title-section">
        <h1>Date de contact</h1>
      </div>
      <button
        type="button"
        onClick={() => NavigationService.navigate('/patient/login')}
        className="white-button position-relative"
      >
        Ai cont? Conectează-te{' '}
        <CustomIcon
          className="custom-icon"
          color={Colors.darkGrey}
          size="24"
          icon="Right_pointer"
        />
      </button>
      <div className="or">sau</div>
      <form>
        <div className="form-input">
          <input
            id="fullName"
            name="fullName"
            autoComplete="off"
            type="text"
            className="form-control"
            placeholder=" "
          />
          <label htmlFor="fullName">Nume și prenume</label>
        </div>
        <div className="form-input">
          <input
            autoComplete="off"
            id="email"
            name="email"
            type="email"
            className="form-control"
            placeholder=" "
          />
          <label htmlFor="email">Adresa de email</label>
        </div>
        <div className="phone-number-input">
          <div className="form-select-label">
            <select required id="prefix" name="prefix" className="custom-select">
              <option value="+40" selected>
                +40
              </option>
            </select>
            <label htmlFor="prefix">Prefix</label>
          </div>
          <div className="form-input">
            <input
              autoComplete="off"
              id="telephone"
              name="telephone"
              type="text"
              className="form-control"
              placeholder=" "
            />
            <label htmlFor="telephone">Telefon</label>
          </div>
        </div>
        <div className="custom-control custom-checkbox orange-checkbox">
          <input
            className="custom-control-input"
            id="future_appointment"
            type="checkbox"
            checked={iAgree}
            onChange={() => setIAgree(!iAgree)}
          />
          <label htmlFor="future_appointment" className="custom-control-label">
            Sunt de acord cu&nbsp;
            <a
              target="_blank"
              href="https://api.ringdoc.ro/ro/pages/termenii-si-conditiile"
              rel="noreferrer"
            >
              Termenii și condițiile
            </a>
            ,&nbsp;
            <a
              target="_blank"
              href="https://api.ringdoc.ro/ro/pages/politica-de-confidentilitate"
              rel="noreferrer"
            >
              Politica de confidențialitate
            </a>
            , declar că am peste 18 ani și că datele sunt reale.
          </label>
        </div>
        <button
          type="submit"
          onClick={() => NavigationService.navigate('/patient/freeCall/searching-medic')}
          className="orange-button"
        >
          Sună medicul acum
        </button>
      </form>
    </section>
  );
};

export default PatientFreeCallUserData;
