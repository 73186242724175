import 'patient/styles/auth.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import { getEnv } from 'shared/services/EnvService';
import Alerts from 'shared/components/Alerts';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';
import { useNavigate } from 'react-router-dom';
import { registerRequest } from 'shared/redux/actions/UserActions';
import Validators from 'shared/modules/Validators';
import SocialLogin from 'shared/components/SocialLogin';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { styles } from 'shared/settings/GooglePlacesAutocomplete';
import { getAuthState, getCountriesState } from 'shared/redux/src/StatesGetter';
import { fetchCountriesRequest } from 'shared/redux/actions/CountryActions';
import { useTranslation } from 'react-i18next';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import Utils from 'shared/modules/Utils';
import gtag from 'react-ga4/src/gtag';
import GAService from '../../../shared/services/GAService';

const PatientRegister = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const [email, setEmail] = useState('');
  const [invitationCode, setInvitationCode] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [address, setAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [agreeTermsAndConditions, setAgreeTermsAndConditions] = useState(false);
  const [agreePrivacyPolicy, setAgreePrivacyPolicy] = useState(false);
  const [agreeDataIsReal, setAgreeDataIsReal] = useState(false);
  const [marketingNotificationSetting, setMarketingNotificationSetting] = useState(false);
  const [requiredFieldsError, setRequiredFieldsError] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };
  const phoneNumberRef = useRef(null);
  useEffect(() => {
    dispatch(fetchCountriesRequest({}));
  }, []);

  const gtagReportConversion = (url) => {
    GAService.event('conversion', {
      category: 'Conversion',
      action: 'Report Conversion',
      label: url,
    });
  };
  const onRegisterPress = (e) => {
    e.preventDefault();
    const parsedPhone = phoneNumber && Utils.withoutMetadata(parsePhoneNumber(phoneNumber));
    const getNumberAfterPossiblyEliminatingZero = Utils.getNumberAfterPossiblyEliminatingZero(
      parsedPhone?.nationalNumber,
      parsedPhone?.countryCallingCode,
    );

    if (
      !agreeTermsAndConditions ||
      !agreePrivacyPolicy ||
      !agreeDataIsReal ||
      !email ||
      !address ||
      !password ||
      !firstName ||
      !lastName ||
      !phoneNumber ||
      !parseInt(getNumberAfterPossiblyEliminatingZero.number, 10)
    ) {
      Alerts.simpleAlert(t('error'), t('allFieldsMandatory'));
      setRequiredFieldsError(true);
      return;
    }
    const isValidEmail = Validators.validateEmail(email);
    if (!isValidEmail) {
      Alerts.simpleAlert(t('error'), t('alerts.invalidEmail'));
      setRequiredFieldsError(true);
      return;
    }

    // #Doamne-ajuta!
    gtag('set', 'user_data', {
      email,
      phone_number: getNumberAfterPossiblyEliminatingZero?.formattedNumber,
      first_name: firstName,
      last_name: lastName,
    });
    GAService.event('web_createAccount');
    gtagReportConversion(window.location.href);
    dispatch(
      registerRequest({
        email,
        plainPassword: password,
        firstName,
        lastName,
        phoneNumber: getNumberAfterPossiblyEliminatingZero?.formattedNumber,
        rawAddress: address,
        agreeTermsAndConditions,
        agreePrivacyPolicy,
        agreeDataIsReal,
        marketingNotificationSetting: marketingNotificationSetting ? 1 : 0,
        invitationCode,
      }),
    );
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn]);

  const goToPrivacy = () => {
    navigate(`/support/privacy-policy`);
  };
  const goToTermsAndConditions = () => {
    navigate(`/support/terms-and-conditions`);
  };

  return (
    <section className="register">
      <form>
        <div className="title-section">
          <h1>{t('patientApp.createAccount')}</h1>
        </div>
        <SocialLogin />
        <div className="or">{t('or')}</div>
        <div className="info-message">
          <CustomIcon
            className="custom-icon"
            color={Colors.mediumGrey}
            size="24"
            icon="Warning_2"
          />
          {t('allFieldsMandatory')}
        </div>
        <div className="form-input">
          <input
            autoComplete="off"
            id="email"
            name="email"
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            className={`form-control ${
              requiredFieldsError && (!email || !Validators.validateEmail(email)) && 'is-invalid'
            }`}
            placeholder=" "
          />
          <label htmlFor="email">{t('email')}*</label>
        </div>
        <div className="password-eye">
          <input
            value={password}
            name="password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            type={passwordShown ? 'text' : 'password'}
            className={`form-control ${requiredFieldsError && !password && 'is-invalid'}`}
            placeholder=" "
          />
          <label htmlFor="password">{t('password')}*</label>
          <div className="eye" onClick={togglePasswordVisibility}>
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="24"
              icon={`${passwordShown ? 'Eye_off' : 'Eye'}`}
            />
          </div>
        </div>
        <div className="form-input">
          <input
            autoComplete="off"
            id="firstName"
            name="firstName"
            type="text"
            className={`form-control ${requiredFieldsError && !firstName && 'is-invalid'}`}
            placeholder=" "
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <label htmlFor="firstName">{t('firstName')}*</label>
        </div>
        <div className="form-input">
          <input
            autoComplete="off"
            id="lastName"
            name="lastName"
            type="text"
            className={`form-control ${requiredFieldsError && !lastName && 'is-invalid'}`}
            placeholder=" "
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <label htmlFor="lastName">{t('lastName')}*</label>
        </div>
        <div
          className={`address-google-places-autocomplete ${
            requiredFieldsError && !address && 'is-invalid'
          }`}
        >
          <GooglePlacesAutocomplete
            apiKey={getEnv('GOOGLE_PLACES_API_KEY')}
            apiOptions={{ language: 'ro', region: 'ro' }}
            minLengthAutocomplete={3}
            selectProps={{
              placeholder: t(''),
              defaultInputValue: address,
              onChange: (o) => {
                geocodeByPlaceId(o.value.place_id)
                  .then((results) => setAddress(JSON.stringify(results[0])))
                  .catch((error) => console.error(error));
              },
              styles,
            }}
          />
          <label htmlFor="react-select-3-input">{t('address')}*</label>
        </div>
        <div className="phone-number-with-prefix">
          <PhoneInput
            className={`${phoneNumber && 'has-value'} ${
              requiredFieldsError && !phoneNumber && 'is-invalid'
            }`}
            placeholder=" "
            value={phoneNumber}
            defaultCountry="RO"
            id="phoneNumber"
            name="phoneNumber"
            /* @ts-ignore */
            onChange={(value) => setPhoneNumber(value)}
            ref={phoneNumberRef}
          />
          <label htmlFor="phoneNumber">{t('phoneNumber')}*</label>
        </div>
        <div className="info-message">
          <CustomIcon
            className="custom-icon"
            color={Colors.mediumGrey}
            size="24"
            icon="Warning_2"
          />
          {t('invitationCodeInfo')}
        </div>
        <div className="form-input">
          <input
            autoComplete="off"
            id="invitationCode"
            name="invitationCode"
            type="invitationCode"
            value={invitationCode}
            onChange={(event) => setInvitationCode(event.target.value)}
            className="form-control form-control-invitation-code"
            placeholder=" "
          />
          <label htmlFor="invitationCode">{t('invitationCode')}</label>
        </div>

        <div className="align-self-start custom-control custom-checkbox orange-checkbox align-center-checkbox left-justify-content">
          <input
            className={`custom-control-input ${
              requiredFieldsError && !agreeTermsAndConditions && 'is-invalid'
            }`}
            id="terms_agree"
            type="checkbox"
            checked={agreeTermsAndConditions}
            onChange={() => setAgreeTermsAndConditions(!agreeTermsAndConditions)}
          />
          <label htmlFor="terms_agree" className="custom-control-label">
            {t('iAgreeWith')}
            <strong onClick={goToTermsAndConditions}>&nbsp;{t('termsAndConditions')}</strong>
            &nbsp;{t('iAgreeWithPersonalData')}*
          </label>
        </div>
        <div className="align-self-start custom-control custom-checkbox orange-checkbox align-center-checkbox left-justify-content">
          <input
            className={`custom-control-input ${
              requiredFieldsError && !agreePrivacyPolicy && 'is-invalid '
            }`}
            id="privacy_agree"
            type="checkbox"
            checked={agreePrivacyPolicy}
            onChange={() => setAgreePrivacyPolicy(!agreePrivacyPolicy)}
          />
          <label htmlFor="privacy_agree" className="custom-control-label">
            {t('iUnderstandAnd')}
            <strong onClick={goToPrivacy}>&nbsp;{t('privacyTitle')}</strong>*
          </label>
        </div>
        <div className="align-self-start custom-control custom-checkbox orange-checkbox align-center-checkbox left-justify-content">
          <input
            className={`custom-control-input ${
              requiredFieldsError && !agreeDataIsReal && 'is-invalid'
            }`}
            id="data_is_real"
            type="checkbox"
            checked={agreeDataIsReal}
            onChange={() => setAgreeDataIsReal(!agreeDataIsReal)}
          />
          <label htmlFor="data_is_real" className="custom-control-label">
            {t('olderAndRealData')}*
          </label>
        </div>
        <div className="align-self-start custom-control custom-checkbox orange-checkbox align-center-checkbox left-justify-content">
          <input
            className="custom-control-input"
            id="marketing_agree"
            type="checkbox"
            checked={marketingNotificationSetting}
            onChange={() => setMarketingNotificationSetting(!marketingNotificationSetting)}
          />
          <label htmlFor="marketing_agree" className="custom-control-label">
            {t('terms&commMessage')}
          </label>
        </div>
        <button type="submit" onClick={onRegisterPress} className="orange-button">
          {t('patientApp.createAccount')}
        </button>
        <hr />
        <button
          type="button"
          onClick={() => navigate('/patient/login')}
          className="white-button position-relative"
        >
          {t('patientApp.alreadyHaveAccount')}
          <CustomIcon
            className="custom-icon"
            color={Colors.darkGrey}
            size="24"
            icon="Right_pointer"
          />
        </button>
      </form>
    </section>
  );
};

export default PatientRegister;
