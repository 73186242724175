import { AnyAction } from 'redux';
import { FETCH_CURRENCY_SUCCESS } from 'shared/redux/types/CurrencyTypes';

const CurrencyReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case FETCH_CURRENCY_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default CurrencyReducer;
