import 'patient/styles/medic-list.css';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Modal, Form, Nav } from 'react-bootstrap';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';
import PatientMedicCard from 'patient/screens/medicList/PatientMedicCard';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { generatePath, useLocation, useParams } from 'react-router';
import {
  getAuthState,
  getFavouriteDoctorsState,
  getPatientAppointmentState,
  getPatientLocalChooseSpecializationState,
  getSpecializationDoctorsState,
  getSpecializationsState,
  getVouchersState,
} from 'shared/redux/src/StatesGetter';
import { useNavigate } from 'react-router-dom';
import {
  getSpecializationDoctorsRequest,
  patientLocallyChooseSpecialization,
} from 'shared/redux/actions/DoctorsActions';
import { getSpecializationsRequest } from 'shared/redux/actions/SpecializationActions';
import { appointmentLocalStepsRequest } from 'shared/redux/actions/PatientAppointmentActions';
import { fetchDoctorProfileRequest } from 'shared/redux/actions/DoctorProfileActions';
import { requestAppointment, allowNewAppointment } from 'shared/modules/PatientAppointmentUtils';
import openGraphImage from 'shared/assets/images/ringdoc_image_open_graph.png';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Tab from 'react-bootstrap/Tab';
import Carousel from 'react-bootstrap/Carousel';
import Utils from 'shared/modules/Utils';
import i18next from 'i18next';
import FindADoctorCard from './FindADoctorCard';
import {
  addFavouriteDoctorRequest,
  getFavouriteDoctorsRequest,
  removeFavouriteDoctorRequest,
} from '../../../shared/redux/actions/FavouriteDoctorsActions';

type medicFiltersAvailable = {
  specializationId: number | null;
  specializationName: string;
  specializationSearch?: string;
  doctorNameSearch?: string;
};

type LocationState = {
  medicFilterId: number | null;
  medicFilterName: string;
};

const PatientMedicListStaticSpecialization = () => {
  const currentLocation = useLocation();
  const specializationParamSelected = currentLocation.pathname.split('/').pop();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const specializationDescriptionRef = useRef<HTMLDivElement>(null);
  const { specialization: urlSpecialization } = useParams();
  const [urlSpecializationRecord, setUrlSpecializationRecord] = useState({
    id: 0,
    name: '',
    description: '',
    metaTitle: '',
    metaDescription: '',
  });
  const vouchers = useSelector(getVouchersState, shallowEqual);
  const [isActive, setActive] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showModalControlAlert, setModalControlAlertShow] = useState(false);
  const modalControlAlertClose = () => setModalControlAlertShow(false);
  const modalControlAlertShow = () => setModalControlAlertShow(true);
  const [medicFilters, setMedicFilters] = useState<medicFiltersAvailable>({
    doctorNameSearch: '',
    specializationId: null,
    specializationName: '',
  });
  const [specializationModal, setSpecializationModal] = useState<medicFiltersAvailable>({
    specializationId: null,
    specializationName: '',
    specializationSearch: '',
  });
  const [doctorNameSearch, setDoctorNameSearch] = useState('');
  const [specializationSearch, setSpecializationSearch] = useState('');
  const specializations = useSelector(getSpecializationsState, shallowEqual);
  const [selectedSpecializationDescription, setSelectedSpecializationDescription] = useState('');
  const [selectedSpecializationMetaTitle, setSelectedSpecializationMetaTitle] = useState('');
  const [selectedSpecializationMetaDescription, setSelectedSpecializationMetaDescription] =
    useState('');
  const {
    data: doctors,
    currentPage,
    noOfPages,
  } = useSelector(getSpecializationDoctorsState, shallowEqual);
  const preSelectedSpecialization = useSelector(
    getPatientLocalChooseSpecializationState,
    shallowEqual,
  );
  const [mobileSearchSpecialization, setMobileSearchSpecialization] = useState(false);
  let haveActiveDoctors = true;

  const specializationObj = specializations.data.find(
    (o) => o.slug === specializationParamSelected,
  );

  useEffect(() => {
    if (preSelectedSpecialization && !urlSpecialization) {
      setMedicFilters({
        doctorNameSearch: '',
        specializationId: preSelectedSpecialization.id,
        specializationName: preSelectedSpecialization.name,
      });
      setSpecializationModal({
        specializationId: preSelectedSpecialization.id,
        specializationName: preSelectedSpecialization.name,
        specializationSearch: '',
      });
      setSelectedSpecializationDescription(preSelectedSpecialization.description);
      setSelectedSpecializationMetaTitle(preSelectedSpecialization.metaTitle);
      setSelectedSpecializationMetaDescription(preSelectedSpecialization.metaDescription);
    } else {
      setUrlSpecializationRecord(
        specializations.data.find((record) => {
          return record.slug === urlSpecialization;
        }),
      );
    }
  }, [i18next.language]);

  useEffect(() => {
    if (urlSpecializationRecord?.id > 0) {
      setMedicFilters({
        doctorNameSearch: '',
        specializationId: urlSpecializationRecord.id,
        specializationName: urlSpecializationRecord.name,
      });
      setSpecializationModal({
        specializationId: urlSpecializationRecord.id,
        specializationName: urlSpecializationRecord.name,
        specializationSearch: '',
      });
      setSelectedSpecializationDescription(urlSpecializationRecord.description);
      setSelectedSpecializationMetaTitle(urlSpecializationRecord.metaTitle);
      setSelectedSpecializationMetaDescription(urlSpecializationRecord.metaDescription);
      dispatch(patientLocallyChooseSpecialization(urlSpecializationRecord));
    }
  }, [urlSpecializationRecord, i18next.language]);

  const [listItems, setListItems] = useState([]);
  const { status: appointmentStatus, stickyStatus } = useSelector(
    getPatientAppointmentState,
    shallowEqual,
  );
  const toggleSearchBar = () => setActive(!isActive);
  const handleSearchNameChange = (e) => {
    setDoctorNameSearch(e.target.value);
    setMedicFilters({
      ...medicFilters,
      doctorNameSearch: e.target.value,
    });
  };
  const handleSearchNameSubmit = (e) => {
    e.preventDefault();
    if (!doctorNameSearch) {
      setActive(false);
    }
    setMedicFilters({
      ...medicFilters,
      doctorNameSearch,
    });
  };
  const handleSpecializationSearchChange = (e) => setSpecializationSearch(e.target.value);
  const handleDoctorSpecializationChange = (e) => {
    const data = JSON.parse(decodeURIComponent(e.target.getAttribute('data-specialization')));
    setSpecializationModal({
      ...specializationModal,
      specializationId: +e.target.value,
      specializationName: e.target.getAttribute('data-name'),
    });
    setSelectedSpecializationDescription(data.description);
    setSelectedSpecializationMetaTitle(data.metaTitle);
    setSelectedSpecializationMetaDescription(data.metaDescription);
    dispatch(patientLocallyChooseSpecialization(data));
  };
  const handleDoctorSpecializationClear = () => {
    setSpecializationModal({
      ...specializationModal,
      specializationId: null,
      specializationName: '',
    });
    setSelectedSpecializationDescription('');
    setSelectedSpecializationMetaTitle('');
    setSelectedSpecializationMetaDescription('');
    setMedicFilters({
      ...medicFilters,
      specializationId: null,
      specializationName: '',
    });

    navigate(generatePath('/patient/medicList'));
    dispatch(patientLocallyChooseSpecialization({}));
  };
  const handleDoctorSpecializationSubmit = (specialization) => {
    setMedicFilters({
      ...medicFilters,
      specializationId: specialization.id,
      specializationName: specialization.name,
    });
    setSelectedSpecializationDescription(specialization.description);
    setSelectedSpecializationMetaTitle(specialization.metaTitle);
    setSelectedSpecializationMetaDescription(specialization.metaDescription);
    dispatch(patientLocallyChooseSpecialization(specialization));
    navigate(
      generatePath('/patient/medicList/:specialization', {
        specialization: specialization.slug,
      }),
    );
    handleClose();
  };

  useEffect(() => {
    setMedicFilters({
      ...medicFilters,
      specializationId: specializationObj?.id ?? null,
      specializationName: specializationObj?.name ?? '',
    });

    const newArray = _.unionBy(listItems, doctors.data, 'rowId');
    setListItems(newArray);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    dispatch(
      getSpecializationsRequest({
        searchName: specializationSearch,
      }),
    );
  }, [specializationSearch, i18next.language]);

  useEffect(() => {
    const { specializationId: id, doctorNameSearch: searchName } = medicFilters;
    dispatch(
      getSpecializationDoctorsRequest({
        id,
        searchName,
      }),
    );
  }, [medicFilters, i18next.language]);

  useEffect(() => {
    const newArray = _.unionBy(listItems, doctors.data, 'rowId');
    setListItems(newArray);
  }, [doctors]);

  const onScheduleClicked = (doctor, specializationId) => {
    if (!allowNewAppointment(appointmentStatus, stickyStatus)) {
      return;
    }
    dispatch(
      appointmentLocalStepsRequest({
        newAppointment: true,
        noOfSteps: 5,
        specializationId,
        type: 'programmed',
        doctor,
      }),
    );
    dispatch(fetchDoctorProfileRequest({ id: doctor?.id }));
    navigate('/patient/appointment/set-date');
  };

  const onCallNowClicked = (doctor, specializationId) => {
    if (!allowNewAppointment(appointmentStatus, stickyStatus)) {
      return;
    }
    requestAppointment('callNow', {
      forRequest: {
        doctor: `${doctor?.id}`,
        specialization: `${specializationId}`,
      },
      forUpdate: {
        specializationId,
        doctor,
      },
    });
  };

  const loadMore = () => {
    const { specializationId: id, doctorNameSearch: searchName } = medicFilters;

    dispatch(
      getSpecializationDoctorsRequest({
        id,
        searchName,
        page: currentPage,
        nextPage: true,
      }),
    );
  };

  useEffect(() => {
    if (specializationDescriptionRef.current) {
      if (selectedSpecializationDescription) {
        specializationDescriptionRef.current.innerHTML = selectedSpecializationDescription;
      } else {
        specializationDescriptionRef.current.innerHTML = '';
      }
    }
  }, [selectedSpecializationDescription]);
  const currentUrl = window.location.href;
  const baseUrl = window.location.origin;

  const redirectToVouchers = () => {
    navigate({
      pathname: '/patient/account',
      search: '?section=vouchers',
    });
  };

  const { ids: favouriteDoctorsIds } = useSelector(getFavouriteDoctorsState, shallowEqual);
  const toggleFavorite = (id) => {
    if (favouriteDoctorsIds.includes(id)) {
      dispatch(removeFavouriteDoctorRequest(parseInt(id, 10)));
    } else {
      dispatch(addFavouriteDoctorRequest(parseInt(id, 10)));
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getFavouriteDoctorsRequest({ limit: 500 }));
    }
  }, [i18next.language]);

  return (
    <section className="row medic-list">
      <Helmet>
        {selectedSpecializationMetaTitle && <title>{selectedSpecializationMetaTitle}</title>}
        {selectedSpecializationMetaDescription && (
          <meta
            name="description"
            content={
              selectedSpecializationMetaDescription ||
              'Pe aceasta pagina veti gasi lista tuturor medicilor disponibili in Ringdoc, pe care ii puteti filtra dupa specialitatea medicala'
            }
          />
        )}
        <meta property="og:url" content={currentUrl} />
        <meta property="og:locale" content="ro_RO" />
        <meta property="og:locale:alternate" content="en_GB" />
        <meta
          property="og:type:section"
          content={
            medicFilters.specializationName
              ? `${t('appointments.appointments')} ${medicFilters.specializationName} ${t(
                  'appointments.online',
                )}`
              : t('appointments.onlineAppointments')
          }
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={
            medicFilters.specializationName
              ? medicFilters.specializationName
              : t('medicalApp.homeScreenPatient.listOfDoctors')
          }
        />
        <meta
          property="og:description"
          content={
            selectedSpecializationMetaDescription ||
            'Pe aceasta pagina veti gasi lista tuturor medicilor disponibili in Ringdoc, pe care ii puteti filtra dupa specialitatea medicala'
          }
        />
        <meta property="og:image" content={`${baseUrl}${openGraphImage}`} />
      </Helmet>

      <div className="medic-list-info">
        <div className="title">
          {medicFilters.specializationName
            ? medicFilters.specializationName
            : t('medicalApp.homeScreenPatient.listOfDoctors')}
        </div>
        <div className="details" ref={specializationDescriptionRef} />
      </div>

      <div className="medic-list-filters col-12 col-md-4">
        <div className="filters-section">
          <div className="searchbar-speciality">
            <form className="searchbar active w-auto" onSubmit={handleSearchNameSubmit}>
              <CustomIcon className="custom-icon" color={Colors.darkGrey} size="20" icon="Search" />
              <input
                type="text"
                placeholder={t('doctorName')}
                value={doctorNameSearch}
                onChange={handleSearchNameChange}
              />
              <span onClick={toggleSearchBar}>{t('search')}</span>
            </form>
            <div className="search-speciality d-block d-md-none">
              <div
                className="custom-control custom-radio orange-radio right-radio specialization-item"
                onClick={() => setMobileSearchSpecialization(!mobileSearchSpecialization)}
              >
                <CustomIcon
                  className="custom-icon"
                  color={Colors.darkGrey}
                  size="20"
                  icon="Filter"
                />
                <div className="name">Specialitate</div>
                <CustomIcon
                  className="custom-icon down-pointer"
                  color={Colors.darkGrey}
                  size="24"
                  icon="Down_pointer"
                />
              </div>
            </div>
            {mobileSearchSpecialization && (
              <div className="scroll-list d-block d-md-none">
                <Form>
                  {medicFilters.specializationName && (
                    <div
                      className="specialization-selected"
                      onClick={handleDoctorSpecializationClear}
                    >
                      <span className="specialization-selected-name">
                        {medicFilters.specializationName}
                      </span>
                      <CustomIcon className="custom-icon" color="#FF6A39" size="20" icon="Cancel" />
                    </div>
                  )}
                  {!medicFilters.specializationName &&
                    specializations.data.map((specialization) => {
                      return (
                        <div
                          key={specialization.id}
                          className="custom-control custom-radio orange-radio right-radio specialization-item"
                          onClick={() => handleDoctorSpecializationSubmit(specialization)}
                          data-specialization={encodeURIComponent(JSON.stringify(specialization))}
                        >
                          <div className="name">{specialization.name}</div>
                          <CustomIcon
                            className="custom-icon"
                            color={Colors.darkGrey}
                            size="24"
                            icon="Right_pointer"
                          />
                        </div>
                      );
                    })}
                </Form>
              </div>
            )}
          </div>

          <div className="scroll-list d-none d-md-block">
            <Form>
              {medicFilters.specializationName && (
                <div className="specialization-selected" onClick={handleDoctorSpecializationClear}>
                  <span className="specialization-selected-name">
                    {medicFilters.specializationName}
                  </span>
                  <CustomIcon className="custom-icon" color="#FF6A39" size="20" icon="Cancel" />
                </div>
              )}
              {specializations.data.map((specialization) => {
                return (
                  <div
                    key={specialization.id}
                    className="custom-control custom-radio orange-radio right-radio specialization-item"
                    onClick={() => handleDoctorSpecializationSubmit(specialization)}
                    data-specialization={encodeURIComponent(JSON.stringify(specialization))}
                  >
                    <div className="name">{specialization.name}</div>
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.darkGrey}
                      size="24"
                      icon="Right_pointer"
                    />
                  </div>
                );
              })}
            </Form>
          </div>
        </div>
      </div>
      <div className="medic-list-doctors col-12 col-md-8">
        <div className="row rowp10">
          <InfiniteScroll
            dataLength={doctors.length}
            next={loadMore}
            hasMore={currentPage <= noOfPages}
            loader={<LoadingSpinner isLoading />}
            className="row"
          >
            {doctors.map((doctor) => {
              const doctorIsFavorite = favouriteDoctorsIds.includes(doctor.id);
              if (doctor.availability !== 'online' && haveActiveDoctors) {
                haveActiveDoctors = false;
                return (
                  <>
                    <FindADoctorCard
                      specialization={medicFilters.specializationName}
                      specializations={specializations}
                    />
                    <PatientMedicCard
                      key={_.uniqueId()}
                      listItem={doctor}
                      onScheduleClicked={onScheduleClicked}
                      onCallNowClicked={onCallNowClicked}
                      toggleFavorite={toggleFavorite}
                      doctorIsFavorite={doctorIsFavorite}
                      classes="col-12 col-xl-6 colp10"
                    />
                  </>
                );
              }
              return (
                <>
                  <PatientMedicCard
                    key={_.uniqueId()}
                    listItem={doctor}
                    onScheduleClicked={onScheduleClicked}
                    onCallNowClicked={onCallNowClicked}
                    toggleFavorite={toggleFavorite}
                    doctorIsFavorite={doctorIsFavorite}
                    classes="col-12 col-xl-6 colp10"
                  />
                </>
              );
            })}
          </InfiniteScroll>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} animation centered className="modal-specialization">
        <Modal.Header closeButton closeLabel={t('close')}>
          <Modal.Title>{t('chooseSpecialization')}</Modal.Title>
        </Modal.Header>

        <Modal.Header className="subheader">
          <div className="searchbar">
            <CustomIcon className="custom-icon" color={Colors.mediumGrey} size="20" icon="Search" />
            <input
              type="text"
              placeholder={t('searchSpecialization')}
              value={specializationSearch}
              onChange={handleSpecializationSearchChange}
            />
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="scroll-list">
            <Form>
              {specializations.data.map((specialization) => {
                return (
                  <div
                    key={specialization.id}
                    className="custom-control custom-radio orange-radio right-radio specialization-item"
                    onClick={() => handleDoctorSpecializationSubmit(specialization)}
                    data-specialization={encodeURIComponent(JSON.stringify(specialization))}
                  >
                    <div className="name">{specialization.name}</div>
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.darkGrey}
                      size="24"
                      icon="Right_pointer"
                    />
                  </div>
                );
              })}
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      {/* <Modal
        show={showModalControlAlert}
        onHide={modalControlAlertClose}
        animation
        centered
        className="modal-control-alert"
      >
        <Modal.Body>
          <div className="icon">
            <CustomIcon
              className="custom-icon"
              color={Colors.warningColor}
              size="159"
              icon="Warning"
            />
          </div>
          <div className="title">Atenție!</div>
          <p>
            Controlul medical poate fi efectuat dacă ai primit o recomandare pentru control din
            partea medicului care te-a consultat anterior.
          </p>
          <p>
            În cazul în care nu ai o recomandare de control te rugăm să efectuezi o consultație.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <button type="button" className="orange-button">
            {t('continue')}
          </button>
        </Modal.Footer>
      </Modal> */}
    </section>
  );
};

export default PatientMedicListStaticSpecialization;
