export const resolveDoctorSpecializationName = (specialization) => {
  const name = specialization?.translations?.filter((translation) => {
    return translation.locale === specialization.current_locale ?? specialization.fallback_locale;
  })[0]?.name;
  return name;
};

export const resolveAvailability = (available, busy, fakeBusy) => {
  if (busy || fakeBusy) {
    return 'busy';
  }
  if (available) {
    return 'online';
  }
  return 'offline';
};
