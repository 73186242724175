import 'shared/styles/how-it-works.css';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import FooterSection from 'shared/components/FooterSection';
import HeaderMenu from 'shared/components/HeaderMenu';
import HeaderMenuMobile from 'shared/components/HeaderMenuMobile';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import openGraphImage from 'shared/assets/images/ringdoc_image_open_graph.png';
import appMedicImage from '../assets/images/aplicatie-medic-image.png';
import Utils from '../modules/Utils';
import { scrollToTop } from '../modules/WindowUtils';

const HomepageDoctor = () => {
  const { t } = useTranslation();
  const [showModalVideo, setModalVideoShow] = useState(false);
  const modalVideoClose = () => setModalVideoShow(false);
  const modalVideoShow = () => setModalVideoShow(true);
  const currentUrl = window.location.href;
  const baseUrl = window.location.origin;
  const navigate = useNavigate();

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="how-it-works homepage-doctor gradient-homepage-doctor">
      <Helmet>
        <title>{t('homepageDoctor.titlePage')}</title>
        <meta
          name="description"
          content="Platforma medicala virtuala Ringdoc te conecteaza cu pacienti din toata Romania si din diaspora care cauta medici online disponibili"
        />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:locale" content="ro_RO" />
        <meta property="og:locale:alternate" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={t('homepageDoctor.titlePage')} />
        <meta
          property="og:description"
          content="Platforma medicala virtuala Ringdoc te conecteaza cu pacienti din toata Romania si din diaspora care cauta medici online disponibili"
        />
        <meta property="og:image" content={`${baseUrl}${openGraphImage}`} />
      </Helmet>

      <HeaderMenuMobile />

      <div className="container-fluid">
        <div className="row">
          <HeaderMenu />

          <main className="container">
            <section className="title">
              <h1>{t('homepageDoctor.title')}</h1>
            </section>

            <div className="landing-registration-btn">
              <button
                type="button"
                onClick={() => navigate('/doctor/register')}
                className="green-button"
              >
                {t('doctorApp.createAccount')}
              </button>
            </div>

            <section className="video">
              <div className="frame-video" onClick={modalVideoShow}>
                <div className="circle-play">
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.darkGrey}
                    size="70"
                    icon="Play"
                  />
                </div>
              </div>
              <p>{t('homepageDoctor.description')}</p>

              <div className="box-number doctor">
                <div className="circle one">
                  <span>1</span>
                </div>
                <div className="description">{t('homepageDoctor.steps.step1')}</div>
              </div>

              <div className="box-number doctor">
                <div className="circle two">
                  <span>2</span>
                </div>
                <div className="description">{t('homepageDoctor.steps.step2')}</div>
              </div>

              <div className="box-number doctor">
                <div className="circle three">
                  <span>3</span>
                </div>
                <div className="description">{t('homepageDoctor.steps.step3')}</div>
              </div>

              <div className="box-number doctor">
                <div className="circle four">
                  <span>4</span>
                </div>
                <div className="description">{t('homepageDoctor.steps.step4')}</div>
              </div>
            </section>

            <div className="landing-registration-btn">
              <button
                type="button"
                onClick={() => navigate('/doctor/register')}
                className="green-button"
              >
                {t('doctorApp.createAccount')}
              </button>
            </div>

            <section className="benefits">
              <h2>{t('homepageDoctor.advantages.title')}</h2>
              <div className="row rowp10">
                <div className="colp10 col-lg-6 col-xl-6 col-md-6 col-12">
                  <div className="box">
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.medicColor}
                      size="80"
                      icon="ph_globe-light"
                    />
                    <p>{t('homepageDoctor.advantages.text1')}</p>
                  </div>
                </div>
                <div className="colp10 col-lg-6 col-xl-6 col-md-6 col-12">
                  <div className="box">
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.medicColor}
                      size="80"
                      icon="ph_chart-line-up-light"
                    />
                    <p>{t('homepageDoctor.advantages.text2')}</p>
                  </div>
                </div>
                <div className="colp10 col-lg-6 col-xl-6 col-md-6 col-12">
                  <div className="box">
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.medicColor}
                      size="80"
                      icon="ph_coins-light"
                    />
                    <p>{t('homepageDoctor.advantages.text3')}</p>
                  </div>
                </div>
                <div className="colp10 col-lg-6 col-xl-6 col-md-6 col-12">
                  <div className="box">
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.medicColor}
                      size="80"
                      icon="ph_heart-light"
                    />
                    <p>{t('homepageDoctor.advantages.text4')}</p>
                  </div>
                </div>
              </div>
            </section>

            <section className="download-app medic">
              <div className="column">
                <h3>{t('homepageDoctor.downloadAppDoctor')}</h3>
                <div className="apps-button">
                  <a
                    target="_blank"
                    href={
                      Utils.isiPhone()
                        ? 'itms-apps://apps.apple.com/us/app/ringdoc-medic/id1574488917'
                        : 'https://apps.apple.com/us/app/ringdoc-medic/id1574488917'
                    }
                    rel="noreferrer"
                  >
                    <i className="app-store-icon cursor-pointer" />
                  </a>
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=ro.ascendnet.alodoc.doctor.ro"
                    rel="noreferrer"
                  >
                    <i className="google-play-icon cursor-pointer" />
                  </a>
                </div>
              </div>
              <div className="column">
                <img src={appMedicImage} alt="Descarca Aplicatia" />
              </div>
            </section>

            <Modal
              show={showModalVideo}
              onHide={modalVideoClose}
              animation
              centered
              className="modal-video"
            >
              <Modal.Header closeButton closeLabel={t('close')} />

              <Modal.Body>
                <div className="iframe-responsive">
                  <iframe
                    src="https://www.youtube.com/embed/GBX_3nBEAKo"
                    title="Cum functioneaza - Medici"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </Modal.Body>
            </Modal>
          </main>

          <FooterSection />
        </div>
      </div>
    </div>
  );
};

export default HomepageDoctor;
