import { actionChannel, call, fork, put, take } from 'redux-saga/effects';
import { FETCH_RECOMMENDATIONS_REQUEST } from 'shared/redux/types/RecommendationsTypes';
import { getRecommendationsAPI } from 'shared/redux/api/RecommendationsApi';
import {
  fetchRecommendationsError,
  fetchRecommendationsSuccess,
} from 'shared/redux/actions/RecommendationsActions';
import recommendationsSelector from 'shared/redux/selector/RecommendationsSelector';

function* getRecommendations(actionType) {
  const recommendationsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(recommendationsChannel);
    try {
      const safeAction = payload || { limit: 5, page: 1, id: 0 };
      const page = safeAction.page || 1;
      const limit = safeAction.limit || 5;
      const id = safeAction?.id || 0;
      const response = yield call(getRecommendationsAPI, { id, page, limit });
      yield put(
        fetchRecommendationsSuccess({
          ...recommendationsSelector(response.data),
          nextPage: payload.nextPage,
        }),
      );
    } catch ({ message }) {
      yield put(fetchRecommendationsError({ message }));
    }
  }
}

function* recommendationsSaga() {
  yield fork(getRecommendations, FETCH_RECOMMENDATIONS_REQUEST);
}

export default recommendationsSaga;
