import 'doctor/styles/consultation-details.css';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  doctorAppointmentUpdatesRequest,
  getAppointmentDetailsRequest,
} from 'shared/redux/actions/DoctorAppointmentsActions';
import {
  getAuthState,
  getDoctorAppointmentDetailsState,
  getDoctorAppointmentState,
  getUserProfileState,
} from 'shared/redux/src/StatesGetter';
import Utils from 'shared/modules/Utils';
import {
  getPrivateMediaFileRequest,
  updateLocaleRequest,
} from 'shared/redux/actions/UngroupedActions';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { Modal } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import _ from 'lodash';
import dayjs from 'dayjs';
import AppointmentConclusion from '../../screens/appointment/AppointmentConclusion';

type SelectedMedicalFileState = {
  createdAt: string;
  category: string;
  medias: [Record<string, null>];
  investigationDate: string;
};

const DoctorConsultationDetailsTabFolder = ({ template }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showMedicalFileDetailsModal, setShowMedicalFileDetailsModal] = useState(false);
  const [selectedMedicalFile, setSelectedMedicalFile] = useState<SelectedMedicalFileState>({
    category: '',
    createdAt: '',
    investigationDate: '',
    medias: [{}],
  });
  const { createdAt, category, medias: files, investigationDate } = selectedMedicalFile;
  const closeMedicalFileDetailsModalAction = () => {
    setShowMedicalFileDetailsModal(false);
    setSelectedMedicalFile({
      category: '',
      createdAt: '',
      investigationDate: '',
      medias: [{}],
    });
  };
  const showMedicalFileDetailsModalAction = (data) => {
    setShowMedicalFileDetailsModal(true);
    setSelectedMedicalFile(data);
  };
  const doctor = useSelector(getUserProfileState, shallowEqual);
  const {
    appointmentType = { duration: 0, type: '' },
    medicalRecords,
    conclusion,
    serverStatus,
    id: appointmentId,
  } = useSelector(getDoctorAppointmentDetailsState, shallowEqual);
  const { status: appointmentStatus, callStatus } = useSelector(
    getDoctorAppointmentState,
    shallowEqual,
  );
  const [hasConclusion, setHasConclusion] = useState(false);
  useEffect(() => {
    setHasConclusion(
      appointmentType.type === 'free_talk' ||
        ['in_progress', 'call_ended'].indexOf(serverStatus) === -1,
    );
  }, [serverStatus]);
  const [formattedMedicalRecords, setFormattedMedicalRecords] = useState(
    Utils.makeSectionListFormat(
      // @ts-ignore
      medicalRecords,
      'category',
    ),
  );
  useEffect(() => {
    setFormattedMedicalRecords(
      Utils.makeSectionListFormat(
        // @ts-ignore
        medicalRecords,
        'category',
      ),
    );
  }, [medicalRecords]);
  const doViewInvestigation = (fileDetails) => {
    if (callStatus === 'active') {
      dispatch(doctorAppointmentUpdatesRequest({ callStatus: 'minimized' }));
    }
    showMedicalFileDetailsModalAction(fileDetails);
  };
  const goodFileDate = (item) => item.investigationDate ?? item.createdAt;
  const viewFile = (mediaId) => {
    dispatch(getPrivateMediaFileRequest(mediaId));
  };
  const renderConclusions = () => {
    if (hasConclusion) {
      return (
        <div className="list">
          <div className="details">
            <div className="item">
              <div className="title">{t('symptoms')}</div>
              <div className="description">{conclusion.symptoms}</div>
            </div>
            <div className="item">
              <div className="title">{t('diagnosis')}</div>
              <div className="description">{conclusion.diagnosis}</div>
            </div>
            {conclusion.knownAllergies && (
              <div className="item">
                <div className="title">{t('knownAllergies')}</div>
                <div className="description">{conclusion.knownAllergies}</div>
              </div>
            )}
            {conclusion.ongoingTreatments && (
              <div className="item">
                <div className="title">{t('ongoingTreatments')}</div>
                <div className="description">{conclusion.ongoingTreatments}</div>
              </div>
            )}
            <div className="item">
              <div className="title">{t('treatment')}</div>
              <div className="description">{conclusion.treatment}</div>
            </div>
            <div className="item">
              <div className="title">{t('recommendation')}</div>
              <div className="description">{conclusion.recommendations}</div>
            </div>
            <div className="item">
              <div className="title">{t('doctorApp.documents')}</div>
              {conclusion?.files && conclusion?.files[0]?.id ? (
                <>
                  {conclusion?.files.map((file) => {
                    return (
                      <div className="document" key={file?.id} onClick={() => viewFile(file?.id)}>
                        <CustomIcon
                          className="custom-icon document"
                          color={Colors.darkGrey}
                          size="24"
                          icon="Document"
                        />
                        <div className="info">
                          <div className="name">{file?.name?.substring(0, 6)}</div>
                          <div className="data">
                            {conclusion?.updatedAt
                              ? dayjs(conclusion?.updatedAt).format('DD MMM YYYY')
                              : dayjs().format('DD MMM YYYY')}
                          </div>
                        </div>
                        <CustomIcon
                          className="custom-icon arrow"
                          color={Colors.darkGrey}
                          size="24"
                          icon="Right_pointer"
                        />
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="empty-container">{t('noFiles')}</div>
              )}
            </div>
          </div>
        </div>
      );
    }
    return (
      <AppointmentConclusion
        appointmentId={appointmentId}
        conclusion={conclusion}
        serverStatus={serverStatus}
        appointmentStep="DetailsTabFolder"
        doctor={doctor}
      />
    );
  };
  return (
    <>
      {medicalRecords && medicalRecords?.length ? (
        <>
          {!!formattedMedicalRecords?.length &&
            formattedMedicalRecords?.map((section) => (
              <div key={section.key} className="folder-documents-box">
                <div className="title">{Utils.capitalizeFirstLetter(section.key)}</div>
                {section?.data?.map((item) => (
                  <div className="document" key={item?.id}>
                    <button type="button" onClick={() => doViewInvestigation(item)}>
                      <CustomIcon
                        className="custom-icon document"
                        color={Colors.darkGrey}
                        size="24"
                        icon="Document"
                      />
                      <div className="info">
                        <div className="name">
                          {item?.medias?.length &&
                            Utils.shortenText(
                              Utils.capitalizeFirstLetter(item?.medias[0].mediaFileName),
                              25,
                            )}
                        </div>
                        <div className="data">
                          {goodFileDate(item)
                            ? dayjs(goodFileDate(item)).format('DD MMM YYYY')
                            : dayjs().format('DD MMM YYYY')}
                        </div>
                      </div>
                      <CustomIcon
                        className="custom-icon arrow"
                        color={Colors.darkGrey}
                        size="24"
                        icon="Right_pointer"
                      />
                    </button>
                  </div>
                ))}
              </div>
            ))}
        </>
      ) : (
        <div>
          <div className="empty-container">{t('noFiles')}</div>
        </div>
      )}
      {/* {['completed', 'call_ended'].indexOf(serverStatus) > -1 && */}
      {/*  template === 'withConclusions' && */}
      {/*  appointmentType.type !== 'freeTalk' && ( */}
      {/*    <> */}
      {/*      <div className="subtitle">{t('recommendations')}</div> */}
      {/*      <div className="folder-documents-box">{renderConclusions()}</div> */}
      {/*    </> */}
      {/*  )} */}
      <Modal
        show={showMedicalFileDetailsModal}
        onHide={closeMedicalFileDetailsModalAction}
        animation
        centered
        className="modal-options-appointments medic-modal"
      >
        <Modal.Header closeButton closeLabel={t('close2')}>
          <Modal.Title>
            {Utils.capitalizeFirstLetter(Utils.shortenText(files[0].mediaFileName, 20))}
            <div className="data">
              {createdAt
                ? dayjs(investigationDate ?? createdAt).format('DD MMM YYYY')
                : dayjs().format('DD MMM YYYY')}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="details">
            <div className="subtitle">{t('details')}</div>
            <div className="form-input">
              <input
                disabled
                type="text"
                id={category}
                name={category}
                className="form-control"
                value={Utils.capitalizeFirstLetter(files[0]?.mediaFileName)}
                placeholder=" "
              />
              <label htmlFor={category}>{category}</label>
            </div>
          </div>
          <div className="files">
            <div className="subtitle">{t('files')}</div>
            <ListGroup>
              {files?.length &&
                files.map((file, index) => {
                  return (
                    <div className="uploaded-box" key={_.uniqueId()}>
                      <div className="uploaded-file">
                        <div
                          className="file-name cursor-pointer"
                          onClick={() => viewFile(file.mediaId)}
                        >
                          <CustomIcon
                            className="custom-icon"
                            color={Colors.darkGrey}
                            size="24"
                            icon="Document"
                          />
                          <span>
                            {Utils.shortenText(`${file?.mediaFileName}_${index + 1}`, 25)}
                          </span>
                        </div>
                        <button type="button">
                          <CustomIcon
                            className="custom-icon arrow"
                            color={Colors.darkGrey}
                            size="24"
                            icon="Right_pointer"
                          />
                        </button>
                      </div>
                    </div>
                  );
                })}
            </ListGroup>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

DoctorConsultationDetailsTabFolder.defaultProps = {
  match: {},
  template: 'withConclusions',
};

DoctorConsultationDetailsTabFolder.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }),
  template: PropTypes.string,
};

export default DoctorConsultationDetailsTabFolder;
