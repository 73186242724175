import { actionChannel, ActionPattern, call, fork, put, take } from 'redux-saga/effects';
import { Action } from 'redux';
import {
  GET_SPECIALIZATIONS_REQUEST,
  GET_SPECIALIZATIONS_HOME_REQUEST,
} from 'shared/redux/types/SpecializationTypes';
import {
  getSpecializationsError,
  getSpecializationsSuccess,
  getSpecializationsHomeError,
  getSpecializationsHomeSuccess,
} from 'shared/redux/actions/SpecializationActions';
import {
  getSpecializationsAPI,
  getSpecializationsHomeAPI,
} from 'shared/redux/api/SpecializationApi';
import {
  specializationSelector,
  specializationHomeSelector,
} from 'shared/redux/selector/SpecializationSelector';
import StorageService from '../../services/StorageService';

function* getSpecializations(actionType: ActionPattern<Action<any>>) {
  const getSpecializationChannel = yield actionChannel(actionType);
  let isPharmacist = 'false';
  StorageService.getData('IS_PHARMACIST', 'false').then((response) => {
    isPharmacist = response;
  });
  while (true) {
    const { payload } = yield take(getSpecializationChannel);
    try {
      const safeAction = {
        limit: 300,
        page: 1,
        searchName: '',
        ...payload,
      };
      let response = { data: { pages: [] } };
      if (isPharmacist === 'false') {
        response = yield call(getSpecializationsAPI, safeAction);
      }
      yield put(
        getSpecializationsSuccess({
          data: specializationSelector(response.data),
          nextPage: payload.nextPage,
          noOfPages: response.data.pages,
        }),
      );
    } catch ({ message }) {
      yield put(getSpecializationsError({ message }));
    }
  }
}

function* getSpecializationsHome(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const getSpecializationHomeChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(getSpecializationHomeChannel);
    try {
      const safeAction = {
        ...payload,
      };
      // @ts-ignore
      const response = yield call(getSpecializationsHomeAPI, safeAction);
      yield put(
        getSpecializationsHomeSuccess({
          data: specializationHomeSelector(response.data),
        }),
      );
    } catch ({ message }) {
      yield put(getSpecializationsHomeError({ message }));
    }
  }
}

function* specializationSaga() {
  yield fork(getSpecializations, GET_SPECIALIZATIONS_REQUEST);
  yield fork(getSpecializationsHome, GET_SPECIALIZATIONS_HOME_REQUEST);
}

export default specializationSaga;
