import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { translatedFullDate } from 'shared/modules/DateTimeUtils';
import {
  fetchPushDetailsRequest,
  updatePushesRequest,
} from 'shared/redux/actions/NotificationActions';

const PatientNotificationBlock = ({
  id,
  title,
  date,
  description,
  isAnswered,
  notificationType,
}) => {
  const dispatch = useDispatch();
  const readNotification = () => {
    const multiActionsPushes = ['doctor_added_conclusion'];
    if (!isAnswered || multiActionsPushes.indexOf(notificationType) > -1) {
      dispatch(fetchPushDetailsRequest({ id }));
    }

    dispatch(
      updatePushesRequest({
        forRequest: { notificationId: id, status: 'answered' },
        forUpdate: null,
      }),
    );
  };

  return (
    <div
      className={`${!isAnswered ? 'box cursor-pointer' : 'box pointer-events-none'}`}
      onClick={readNotification}
    >
      <div className="title">
        {!isAnswered && (
          <div className="unread-notification">
            <div className="green-circle" />
          </div>
        )}
        {title}
      </div>
      <div className="date">{translatedFullDate(date)}</div>
      <div className="description">{description}</div>
    </div>
  );
};

PatientNotificationBlock.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isAnswered: PropTypes.bool.isRequired,
  notificationType: PropTypes.string.isRequired,
};

export default PatientNotificationBlock;
