import { AnyAction } from 'redux';
import {
  VIDEO_CALL_GET_TOKEN_ERROR,
  VIDEO_CALL_GET_TOKEN_REQUEST,
  VIDEO_CALL_GET_TOKEN_SUCCESS,
} from 'shared/redux/types/VideoCallTypes';

export const videoCallGetTokenRequest = (payload: any): AnyAction => ({
  type: VIDEO_CALL_GET_TOKEN_REQUEST,
  payload,
});

export const videoCallGetTokenSuccess = (payload: any): AnyAction => ({
  type: VIDEO_CALL_GET_TOKEN_SUCCESS,
  payload,
});

export const videoCallGetTokenError = (payload: any): AnyAction => ({
  type: VIDEO_CALL_GET_TOKEN_ERROR,
  payload,
});
