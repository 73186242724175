import { AnyAction } from 'redux';
import {
  GET_SPECIALIZATIONS_SUCCESS,
  GET_SPECIALIZATIONS_HOME_SUCCESS,
} from 'shared/redux/types/SpecializationTypes';

const SpecializationsReducer = (
  state = { data: [], currentPage: 1, noOfPages: undefined },
  action: AnyAction,
) => {
  switch (action.type) {
    case GET_SPECIALIZATIONS_SUCCESS:
      return {
        data: action.payload.data,
        noOfPages: action.payload.noOfPages,
        currentPage: action.payload.page,
      };
    default:
      return state;
  }
};

const SpecializationsHomeReducer = (state = { data: [] }, action: AnyAction) => {
  switch (action.type) {
    case GET_SPECIALIZATIONS_HOME_SUCCESS:
      return {
        data: action.payload.data,
      };
    default:
      return state;
  }
};

export { SpecializationsReducer, SpecializationsHomeReducer };
