import 'doctor/styles/mlm-recommend.css';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import { shallowEqual, useSelector } from 'react-redux';
import DoctorRecommendTabBlock from '../../components/mlm/DoctorRecommendTab';
import { getUserProfileState } from '../../../shared/redux/src/StatesGetter';

const DoctorRecommend = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('patient');
  const profileData = useSelector(getUserProfileState, shallowEqual);
  const changeTab = (k) => {
    setActiveTab(k);
  };
  return (
    <section className="mlm recommend">
      <div className="title-section">
        <h1>{t('mlm.recommendRingdoc')}</h1>
      </div>

      <Tab.Container id="mlm-recommend-tabs-menu" defaultActiveKey="patient" onSelect={changeTab}>
        <div className="small-container">
          <Nav variant="tabs">
            {!profileData.isPharmacist && (
              <>
                <Nav.Item>
                  <Nav.Link eventKey="patient">{t('mlm.invitePatientTab')}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="doctor">{t('mlm.inviteMedicTab')}</Nav.Link>
                </Nav.Item>
              </>
            )}
          </Nav>
        </div>
        <Tab.Content>
          <Tab.Pane eventKey={activeTab}>
            <DoctorRecommendTabBlock activeTab={activeTab} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </section>
  );
};
DoctorRecommend.defaultProps = {
  match: {},
  location: {},
};
DoctorRecommend.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({}),
  }),
};
export default DoctorRecommend;
