import { AnyAction } from 'redux';
import {
  FETCH_REFERRALS_REQUEST,
  FETCH_REFERRALS_SUCCESS,
  FETCH_REFERRALS_ERROR,
  SET_REFERRAL_REQUEST,
  SET_REFERRAL_SUCCESS,
  SET_REFERRAL_ERROR,
  SEND_REFERRAL_INVITATION_REQUEST,
  SEND_REFERRAL_INVITATION_SUCCESS,
  SEND_REFERRAL_INVITATION_ERROR,
  FETCH_REFERRAL_BONUS_REQUEST,
  FETCH_REFERRAL_BONUS_SUCCESS,
  FETCH_REFERRAL_BONUS_ERROR,
  FETCH_DOCTORS_ALL_INDEX_REQUEST,
  FETCH_DOCTORS_ALL_INDEX_SUCCESS,
  FETCH_DOCTORS_ALL_INDEX_ERROR,
  FETCH_SENT_REFERRALS_REQUEST,
  FETCH_SENT_REFERRALS_SUCCESS,
  FETCH_SENT_REFERRALS_ERROR,
} from '../types/Referrals';

export const fetchReferralsRequest = (payload): AnyAction => ({
  type: FETCH_REFERRALS_REQUEST,
  payload,
});

export const fetchReferralsSuccess = (payload): AnyAction => ({
  type: FETCH_REFERRALS_SUCCESS,
  payload,
});

export const fetchReferralsError = (payload): AnyAction => ({
  type: FETCH_REFERRALS_ERROR,
  payload,
});

export const fetchSentReferralsRequest = (payload): AnyAction => ({
  type: FETCH_SENT_REFERRALS_REQUEST,
  payload,
});

export const fetchSentReferralsSuccess = (payload): AnyAction => ({
  type: FETCH_SENT_REFERRALS_SUCCESS,
  payload,
});

export const fetchSentReferralsError = (payload): AnyAction => ({
  type: FETCH_SENT_REFERRALS_ERROR,
  payload,
});

export const setReferralRequest = (payload): AnyAction => ({
  type: SET_REFERRAL_REQUEST,
  payload,
});

export const setReferralSuccess = (payload): AnyAction => ({
  type: SET_REFERRAL_SUCCESS,
  payload,
});

export const setReferralError = (payload): AnyAction => ({
  type: SET_REFERRAL_ERROR,
  payload,
});

export const sendReferralInvitationRequest = (payload): AnyAction => ({
  type: SEND_REFERRAL_INVITATION_REQUEST,
  payload,
});

export const sendReferralInvitationSuccess = (payload): AnyAction => ({
  type: SEND_REFERRAL_INVITATION_SUCCESS,
  payload,
});

export const sendReferralInvitationError = (payload): AnyAction => ({
  type: SEND_REFERRAL_INVITATION_ERROR,
  payload,
});

export const fetchReferralBonusRequest = (payload): AnyAction => ({
  type: FETCH_REFERRAL_BONUS_REQUEST,
  payload,
});

export const fetchReferralBonusSuccess = (payload): AnyAction => ({
  type: FETCH_REFERRAL_BONUS_SUCCESS,
  payload,
});

export const fetchReferralBonusError = (payload): AnyAction => ({
  type: FETCH_REFERRAL_BONUS_ERROR,
  payload,
});

export const fetchDoctorsAllIndexRequest = (payload): AnyAction => ({
  type: FETCH_DOCTORS_ALL_INDEX_REQUEST,
  payload,
});

export const fetchDoctorsAllIndexSuccess = (payload): AnyAction => ({
  type: FETCH_DOCTORS_ALL_INDEX_SUCCESS,
  payload,
});

export const fetchDoctorsAllIndexError = (payload): AnyAction => ({
  type: FETCH_DOCTORS_ALL_INDEX_ERROR,
  payload,
});
