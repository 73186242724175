import 'patient/styles/medical-folder.css';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  getAuthState,
  getLocalCurrentPatientProfileState,
  getMedicalRecordCategoryState,
  getPatientMedicalRecordsState,
} from 'shared/redux/src/StatesGetter';
import { getPatientMedicalRecordsRequest } from 'shared/redux/actions/PatientProfileActions';
import { fetchMedicalRecordCategoryRequest } from 'shared/redux/actions/MedicalRecordCategoryActions';
import { getPossibleKinshipRequest } from 'shared/redux/actions/KinshipActions';
import PatientMedicalFolderTabProfile from './PatientMedicalFolderTabProfile';
import PatientMedicalFolderTabRecommendation from './PatientMedicalFolderTabRecommendation';
import PatientMedicalFolderTabFolder from './PatientMedicalFolderTabFolder';

const PatientMedicalFolder = () => {
  const { t, i18n } = useTranslation();
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('profile');
  const profileData = useSelector(getLocalCurrentPatientProfileState, shallowEqual);
  const medicalRecords = useSelector(getPatientMedicalRecordsState, shallowEqual);
  const fileTypes = useSelector(getMedicalRecordCategoryState, shallowEqual);
  const handleActiveTabChange = (newTab) => {
    if (newTab) {
      setActiveTab(newTab);
    }
  };

  useEffect(() => {
    const getActiveTabFromNavigation = location.state as string;
    const getSavedActiveTab = localStorage.getItem('patientMedicalFolderActiveTab');
    if (getActiveTabFromNavigation) {
      setActiveTab(getActiveTabFromNavigation);
      navigate(location.pathname, { replace: true });
    } else if (getSavedActiveTab) {
      setActiveTab(getSavedActiveTab);
    }
    if (profileData.id) {
      dispatch(getPatientMedicalRecordsRequest(profileData.id));
    }
    dispatch(fetchMedicalRecordCategoryRequest({}));
    dispatch(getPossibleKinshipRequest({}));
  }, [profileData]);

  useEffect(() => {
    localStorage.setItem('patientMedicalFolderActiveTab', activeTab);
  }, [activeTab]);

  return (
    <section className="medical-folder">
      <div className="title-section">
        <h1>{t('medicalRecord')}</h1>
      </div>

      <div className="small-container">
        <div className="patient-box">
          <div className="details">
            <div
              className="avatar"
              style={
                profileData?.imageUrl
                  ? { backgroundImage: `url(${profileData?.imageUrl})` }
                  : { backgroundColor: '#E5E7E8' }
              }
            >
              {!profileData?.imageUrl && (
                <div className="image-placeholder">{`${profileData?.firstName?.charAt(
                  0,
                )}${profileData?.lastName?.charAt(0)}`}</div>
              )}
            </div>
            <div className="info">
              <div className="name">{profileData.fullNameWithTitle}</div>
              <div className="age">
                {profileData.age === 0
                  ? t('medicalProfile.under_a_year')
                  : profileData.age && `${profileData.age} ${t('years')} - ${profileData.type}`}
              </div>
            </div>
          </div>
          <Link to="select-profile">{t('anotherProfile')}</Link>
        </div>
      </div>

      <Tab.Container
        id="medical-folder-tabs-menu"
        activeKey={activeTab}
        onSelect={handleActiveTabChange}
      >
        <div className="small-container">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="profile">{t('profile')}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="folder">{t('records')}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="recommendation">{t('settings.recommendations')}</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <Tab.Content>
          <Tab.Pane eventKey="profile">
            <PatientMedicalFolderTabProfile profileData={profileData} />
          </Tab.Pane>
          <Tab.Pane eventKey="folder">
            <PatientMedicalFolderTabFolder
              medicalRecords={medicalRecords}
              fileTypes={fileTypes}
              userId={profileData.id}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="recommendation">
            <PatientMedicalFolderTabRecommendation userId={profileData.id} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </section>
  );
};

export default PatientMedicalFolder;
