import { AnyAction } from 'redux';
import { antiDuplication, replaceArrayRowProps } from 'shared/modules/ArrayUtils';
import {
  FETCH_NOTIFICATIONS_SETTINGS_SUCCESS,
  FETCH_PUSHES_SUCCESS,
  UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS,
  UPDATE_PUSHES_SUCCESS,
} from 'shared/redux/types/NotificationTypes';

const PushesReducer = (
  state = { data: [], currentPage: 1, noOfPages: undefined, unseen: 0 },
  action: AnyAction,
) => {
  switch (action.type) {
    case FETCH_PUSHES_SUCCESS:
      return {
        data: action.payload.nextPage
          ? antiDuplication([...state.data, ...action.payload.data], 'id')
          : action.payload.data,
        noOfPages: action.payload.noOfPages,
        unseen: action.payload.unseen,
        currentPage: action.payload.nextPage ? state.currentPage + 1 : 2,
        total: action.payload.total,
      };
    case UPDATE_PUSHES_SUCCESS:
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
        data: replaceArrayRowProps(state.data, 'id', action.payload?.id, action.payload.props),
      };
    default:
      return state;
  }
};

const NotificationsSettingsReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_SETTINGS_SUCCESS:
      return action.payload;
    case UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS:
      return replaceArrayRowProps(state, 'id', action.payload?.id, action.payload.data);
    default:
      return state;
  }
};

export { PushesReducer, NotificationsSettingsReducer };
