import { default as ApiConstants } from 'shared/constants/ApiConstants.json';
import ApiUtils from 'shared/modules/ApiUtils';

const getPatientProfileAPI = () => ApiUtils.getRequest(`${ApiConstants.PATIENT_PROFILE_ENDPOINT}/`);

const updatePatientProfileAPI = (payload) =>
  ApiUtils.patchRequest(
    `${ApiConstants.UPDATE_PATIENT_PROFILE_ENDPOINT}/${payload.id}`,
    payload.data,
  );

const addPatientMedicalRecordAPI = (userId, payload) =>
  ApiUtils.postRequest(`${ApiConstants.PATIENT_ADD_MEDICAL_RECORD_ENDPOINT}/${userId}`, payload);

const updatePatientMedicalRecordAPI = (userId, mediaId, payload) =>
  ApiUtils.putRequest(
    `${ApiConstants.PATIENT_ADD_MEDICAL_RECORD_ENDPOINT}/${mediaId}/${userId}`,
    payload,
  );

const deletePatientMedicalRecordAPI = (userId, mediaId) =>
  ApiUtils.deleteRequest(
    `${ApiConstants.PATIENT_ADD_MEDICAL_RECORD_ENDPOINT}/${mediaId}/${userId}`,
  );

const getPatientMedicalRecordsAPI = (payload) =>
  ApiUtils.getRequest(
    `${ApiConstants.GET_PATIENT_MEDICAL_RECORDS_ENDPOINT}/${payload}/medical-records`,
  );

export {
  getPatientProfileAPI,
  updatePatientProfileAPI,
  addPatientMedicalRecordAPI,
  updatePatientMedicalRecordAPI,
  getPatientMedicalRecordsAPI,
  deletePatientMedicalRecordAPI,
};
