import 'patient/styles/account-change-password.css';
import { useState } from 'react';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { changePasswordRequest } from 'shared/redux/actions/AuthActions';
import Alerts from 'shared/components/Alerts';
import { useTranslation } from 'react-i18next';

const PatientAccountChangePasswordBlock = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [currentPassword, setCurrentPassword] = useState('');
  const handleCurrentPassword = (e) => setCurrentPassword(e.target.value);
  const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
  const toggleCurrentPasswordVisibility = () => {
    setCurrentPasswordShown(!currentPasswordShown);
  };
  const [newPassword, setNewPassword] = useState('');
  const handleNewPassword = (e) => setNewPassword(e.target.value);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const toggleNewPasswordVisibility = () => {
    setNewPasswordShown(!newPasswordShown);
  };
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const handleConfirmNewPassword = (e) => setConfirmNewPassword(e.target.value);
  const [repeatNewPasswordShown, setRepeatNewPasswordShown] = useState(false);
  const toggleRepeatNewPasswordVisibility = () => {
    setRepeatNewPasswordShown(!repeatNewPasswordShown);
  };
  const onPress = () => {
    if (!currentPassword || !newPassword || !confirmNewPassword) {
      Alerts.simpleAlert(t('error'), t('account.changePasswordValidation3'));
      return;
    }

    if (!currentPassword) {
      Alerts.simpleAlert(t('error'), t('account.changePasswordValidation1'));
      return;
    }

    if (newPassword !== confirmNewPassword) {
      Alerts.simpleAlert(t('error'), t('account.changePasswordValidation2'));
      return;
    }

    dispatch(
      changePasswordRequest({
        currentPassword,
        newPassword: { first: newPassword, second: confirmNewPassword },
      }),
    );
  };

  return (
    <div className="account-change-password">
      <div className="title-section">
        <h2>{t('settings.changePassword')}</h2>
      </div>

      <div className="password-eye">
        <input
          name="current-password"
          id="current-password"
          type={currentPasswordShown ? 'text' : 'password'}
          className="form-control"
          placeholder=" "
          onChange={handleCurrentPassword}
        />
        <label htmlFor="current-password">{t('account.oldPassword')}</label>
        <div className="eye" onClick={toggleCurrentPasswordVisibility}>
          <CustomIcon
            className="custom-icon"
            color={Colors.mediumGrey}
            size="24"
            icon={`${currentPasswordShown ? 'Eye_off' : 'Eye'}`}
          />
        </div>
      </div>

      <div className="password-eye">
        <input
          name="new-password"
          id="new-password"
          type={newPasswordShown ? 'text' : 'password'}
          className="form-control"
          placeholder=" "
          onChange={handleNewPassword}
        />
        <label htmlFor="new-password">{t('account.newPassword')}</label>
        <div className="eye" onClick={toggleNewPasswordVisibility}>
          <CustomIcon
            className="custom-icon"
            color={Colors.mediumGrey}
            size="24"
            icon={`${newPasswordShown ? 'Eye_off' : 'Eye'}`}
          />
        </div>
      </div>

      <div className="password-eye">
        <input
          name="repeat-new-password"
          id="repeat-new-password"
          type={repeatNewPasswordShown ? 'text' : 'password'}
          className="form-control"
          placeholder=" "
          onChange={handleConfirmNewPassword}
        />
        <label htmlFor="repeat-new-password">{t('account.confirmNewPassword')}</label>
        <div className="eye" onClick={toggleRepeatNewPasswordVisibility}>
          <CustomIcon
            className="custom-icon"
            color={Colors.mediumGrey}
            size="24"
            icon={`${repeatNewPasswordShown ? 'Eye_off' : 'Eye'}`}
          />
        </div>
      </div>

      <button type="button" className="orange-button" onClick={onPress}>
        {t('account.changePasswordBtn')}
      </button>
    </div>
  );
};

export default PatientAccountChangePasswordBlock;
