import { AnyAction } from 'redux';
import { ADD_APPOINTMENT_PRESCRIPTION_SUCCESS } from '../types/DoctorAppointmentsTypes';

const PrescriptionReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case ADD_APPOINTMENT_PRESCRIPTION_SUCCESS:
      return { ...state, prescription: action.payload };
    default:
      return state;
  }
};

export default PrescriptionReducer;
