import { default as ApiConstants } from 'shared/constants/ApiConstants.json';
import ApiUtils from 'shared/modules/ApiUtils';

const getFamilyMembersAPI = () => ApiUtils.getRequest(ApiConstants.GET_FAMILY_MEMBERS_ENDPOINT);

const getProfileDetailsAPI = (payload) =>
  ApiUtils.getRequest(`${ApiConstants.GET_FAMILY_MEMBERS_ENDPOINT}/${payload?.id}`);

const updateProfileDetailsAPI = (payload) =>
  ApiUtils.postRequest(
    `${ApiConstants.UPDATE_PATIENT_PROFILE_ENDPOINT}/${payload.id}`,
    payload.data,
  );

const addFamilyMemberAPI = (payload) =>
  ApiUtils.postRequest(ApiConstants.ADD_FAMILY_MEMBERS_ENDPOINT, payload);

const addFamilyMemberDiseaseAPI = (payload) =>
  ApiUtils.postRequest(
    `${ApiConstants.GET_FAMILY_MEMBERS_ENDPOINT}/${payload.patientId}/kinship-disease`,
    payload.data,
  );

const removeFamilyMemberAPI = (payload) =>
  ApiUtils.deleteRequest(`${ApiConstants.REMOVE_FAMILY_MEMBERS_ENDPOINT}/${payload}`);

const removeFamilyMemberDiseaseAPI = (payload) =>
  ApiUtils.deleteRequest(
    `/patient/managed/${payload.patientId}/kinship-disease/${payload.recordId}`,
  );

const updateFamilyMemberDiseaseAPI = (payload) =>
  ApiUtils.postRequest(
    `${ApiConstants.GET_FAMILY_MEMBERS_ENDPOINT}/${payload.patientId}/kinship-disease/${payload.recordId}`,
    payload.data,
  );

const updateFamilyMemberProfileAPI = (payload) =>
  ApiUtils.patchRequest(
    `${ApiConstants.UPDATE_PATIENT_PROFILE_ENDPOINT}/${payload?.id}`,
    payload.data,
  );

export {
  getFamilyMembersAPI,
  getProfileDetailsAPI,
  updateProfileDetailsAPI,
  addFamilyMemberAPI,
  removeFamilyMemberAPI,
  addFamilyMemberDiseaseAPI,
  removeFamilyMemberDiseaseAPI,
  updateFamilyMemberDiseaseAPI,
  updateFamilyMemberProfileAPI,
};
