import 'patient/styles/medical-folder.css';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import Utils from 'shared/modules/Utils';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { addFamilyMemberRequest } from 'shared/redux/actions/FamilyMembersActions';
import { getPossibleKinshipRequest } from 'shared/redux/actions/KinshipActions';
import { getPossibleKinshipState } from 'shared/redux/src/StatesGetter';
import { bloodSystemOptions, genderOptions } from 'shared/settings/FormSettings';
import usePatientProfile from 'patient/hooks/usePatientProfile';
import useProfilePictureUpload from 'patient/hooks/useProfilePictureUpload';
import Alerts from 'shared/components/Alerts';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type LocationState = {
  goBack: boolean;
};

const PatientMedicalFolderAddProfile = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const relations = useSelector(getPossibleKinshipState, shallowEqual);
  const picture = useProfilePictureUpload();
  const profile = usePatientProfile();
  const [realData, setRealData] = useState(false);
  let goBack = false;
  if (location.state) {
    const state = location.state as LocationState;
    goBack = state.goBack;
  }
  const handleRealDataChange = (e) => setRealData(!realData);
  const addPatientProfile = () => {
    if (!profile.firstName || !profile.lastName || !profile.birthDate || !profile.relation) {
      Alerts.simpleAlert(t('error'), t('markedFieldsMandatory'));
      return;
    }

    if (!realData) {
      Alerts.simpleAlert(t('error'), t('validations.confirmRealData'));
      return;
    }

    const pictureDataToPost = {
      originalName: picture.name,
      mimeType: picture.type,
      size: picture.size,
    };

    const patientData = {
      firstName: profile.firstName,
      lastName: profile.lastName,
      patientKinship: profile.relation,
      birthDate: profile.birthDate,
      height: profile.height,
      weight: profile.weight,
      gender: profile.gender,
      bloodSystem: profile.bloodSystem,
      knownAllergies: profile.knownAllergies,
      isSmoker: profile.isSmoker,
      isAlcoholConsumer: profile.isAlcoholConsumer,
      currentMedicalConditions: profile.currentMedicalConditions,
      pastMedicalConditions: profile.pastMedicalConditions,
    };

    let payloadToAdd;
    if (picture.type) {
      payloadToAdd = { ...patientData, picture: pictureDataToPost };
    } else {
      payloadToAdd = patientData;
    }

    Alerts.okCancelAlert(t('warning'), t('validations.saveMemberProfile'), () => {
      dispatch(
        addFamilyMemberRequest({
          toAdd: payloadToAdd,
          toUpload: picture.dataToUpload,
          goBack,
        }),
      );
      navigate(location.pathname, { replace: true });
    });
  };

  useEffect(() => {
    dispatch(getPossibleKinshipRequest({}));
  }, []);

  return (
    <section className="medical-folder add-profile small-container">
      <div className="title-section">
        {goBack && (
          <div className="icon-wrapper" onClick={() => navigate(-1)}>
            <CustomIcon
              className="custom-icon"
              color={Colors.darkGrey}
              size="24"
              icon="Left_arrow"
            />
          </div>
        )}
        <h1>{t('newProfile')}</h1>
      </div>

      <div className="info-message">
        <CustomIcon className="custom-icon" color={Colors.mediumGrey} size="24" icon="Warning_2" />
        {t('markedFieldsMandatory')}
      </div>

      <div className="personal-data">
        <div className="subtitle">{t('settings.personalData')}</div>
        <div className="form-input">
          <input
            autoComplete="off"
            id="firstName"
            name="firstName"
            type="text"
            className="form-control"
            placeholder=" "
            value={profile.firstName}
            onChange={profile.handleFirstNameChange}
          />
          <label htmlFor="firstName">{t('firstName')}*</label>
        </div>

        <div className="form-input">
          <input
            autoComplete="off"
            id="lastName"
            name="lastName"
            type="text"
            className="form-control"
            placeholder=" "
            value={profile.lastName}
            onChange={profile.handleLastNameChange}
          />
          <label htmlFor="lastName">{t('lastName')}*</label>
        </div>

        <div className="form-input">
          <input
            autoComplete="off"
            id="birthDate"
            name="birthDate"
            type="date"
            className="form-control"
            placeholder=" "
            value={profile.birthDate || ' '}
            onChange={profile.handleBirthDateChange}
          />
          <label htmlFor="birthDate">{t('birthDate')}*</label>
        </div>

        <div className="form-select-label">
          <select
            required
            id="possible-kinship"
            name="possible-kinship"
            className="custom-select"
            value={profile.relation || ''}
            onChange={profile.handleRelationChange}
          >
            <option value="" disabled hidden>
              {t('userRelation')}*
            </option>
            {relations.length &&
              Utils.makeSelectOptions(relations, 'name', 'id').map((rel) => {
                return (
                  <option key={rel.value} value={rel.value}>
                    {Utils.capitalizeFirstLetter(rel.label)}
                  </option>
                );
              })}
          </select>
          <label htmlFor="possible-kinship">{t('userRelation')}*</label>
        </div>
      </div>

      <div className="profile-picture">
        <div className="subtitle">{t('profilePhoto')}</div>
        <div className="change-picture">
          <Form.Group controlId="formFile" className="files-box">
            <Form.Label className="add">{t('addProfilePhoto')}</Form.Label>
            <Form.Control type="file" onChange={picture.handleProfilePictureChange} />
          </Form.Group>
          {!picture.uri && (
            <div className="no-avatar">
              <CustomIcon
                className="custom-icon"
                color={Colors.mediumGrey}
                size="40"
                icon="Profil"
              />
            </div>
          )}
          {picture.uri && (
            <div className="avatar" style={{ backgroundImage: `url(${picture.uri})` }} />
          )}
        </div>
      </div>

      <div className="biometric-data">
        <div className="subtitle">{t('bioData')}</div>
        <div className="row rowp5">
          <div className="col-lg-6 col-xl-6 col-md-6 col-12 colp5">
            <div className="form-input">
              <input
                autoComplete="off"
                id="height"
                name="height"
                type="text"
                className="form-control"
                placeholder=" "
                value={profile.height || ''}
                onChange={profile.handleHeightChange}
              />
              <label htmlFor="height">{t('medicalProfile.height')}</label>
            </div>
          </div>

          <div className="col-lg-6 col-xl-6 col-md-6 col-12 colp5">
            <div className="form-input">
              <input
                autoComplete="off"
                id="weight"
                name="weight"
                type="text"
                className="form-control"
                placeholder=" "
                value={profile.weight || ''}
                onChange={profile.handleWeightChange}
              />
              <label htmlFor="weight">{t('medicalProfile.weight')}</label>
            </div>
          </div>

          <div className="col-lg-6 col-xl-6 col-md-6 col-12 colp5">
            <div className="form-select-label">
              <select
                required
                id="gender"
                name="gender"
                className="custom-select"
                value={profile.gender || ''}
                onChange={profile.handleGenderChange}
              >
                <option value="" disabled hidden>
                  {t('medicalProfile.gender')}
                </option>
                {Utils.makeSelectOptions(genderOptions, 'name', 'id').map((genderOption) => {
                  return (
                    <option key={genderOption.value} value={genderOption.value}>
                      {Utils.capitalizeFirstLetter(i18next.t(genderOption.label))}
                    </option>
                  );
                })}
              </select>
              <label htmlFor="gender">{t('medicalProfile.gender')}</label>
            </div>
          </div>

          <div className="col-lg-6 col-xl-6 col-md-6 col-12 colp5">
            <div className="form-select-label">
              <select
                required
                id="blood-system"
                name="blood-system"
                className="custom-select"
                value={profile.bloodSystem || ''}
                onChange={profile.handleBloodSystemChange}
              >
                <option value="" disabled hidden>
                  {t('medicalProfile.bloodType')}
                </option>
                {Utils.makeSelectOptions2(bloodSystemOptions, 'name', 'id', 'name2').map(
                  (bloodSystemOption) => {
                    return (
                      <option key={bloodSystemOption.value} value={bloodSystemOption.value}>
                        {Utils.capitalizeFirstLetter(bloodSystemOption.label)}{' '}
                        {`${i18next.t(bloodSystemOption.name2)}`}
                      </option>
                    );
                  },
                )}
              </select>
              <label htmlFor="blood-system">{t('medicalProfile.bloodType')}</label>
            </div>
          </div>
        </div>

        <div className="form-input">
          <input
            autoComplete="off"
            id="known-allergies"
            name="known-allergies"
            type="text"
            className="form-control"
            placeholder=" "
            value={profile.knownAllergies || ''}
            onChange={profile.handleKnownAllergiesChange}
          />
          <label htmlFor="known-allergies">{t('medicalProfile.allergies')}</label>

          <div className="switch-box">
            <Form.Switch
              className="custom-switch"
              id="smoker"
              label={t('medicalProfile.smoking')}
              checked={profile.isSmoker}
              onChange={profile.handleIsSmokerChange}
            />
          </div>

          <div className="switch-box">
            <Form.Switch
              className="custom-switch"
              id="alcohol-consumer"
              label={t('medicalProfile.alcohol')}
              checked={profile.isAlcoholConsumer}
              onChange={profile.handleIsAlcoholConsumerChange}
            />
          </div>
        </div>
      </div>

      <div className="disease">
        <div className="subtitle">{t('medicalProfile.issues')}</div>

        <div className="form-input">
          <input
            autoComplete="off"
            id="current-medical-conditions"
            name="current-medical-conditions"
            type="text"
            className="form-control"
            placeholder=" "
            value={profile.currentMedicalConditions || ''}
            onChange={profile.handleCurrentMedicalConditionsChange}
          />
          <label htmlFor="current-medical-conditions">{t('medicalProfile.knownIssues')}</label>
        </div>

        <div className="form-input">
          <input
            autoComplete="off"
            id="past-medical-conditions"
            name="past-medical-conditions"
            type="text"
            className="form-control"
            placeholder=" "
            value={profile.pastMedicalConditions || ''}
            onChange={profile.handlePastMedicalConditionsChange}
          />
          <label htmlFor="past-medical-conditions">{t('medicalProfile.pastIssues')}</label>
        </div>
      </div>

      <div className="custom-control custom-checkbox orange-checkbox align-center-checkbox left-justify-content">
        <input
          className="custom-control-input"
          id="future_appointment"
          type="checkbox"
          checked={realData}
          onChange={handleRealDataChange}
        />
        <label htmlFor="future_appointment" className="custom-control-label">
          {t('realData')}
        </label>
      </div>

      <button type="button" className="orange-button" onClick={addPatientProfile}>
        {t('addProfile')}
      </button>
    </section>
  );
};

export default PatientMedicalFolderAddProfile;
