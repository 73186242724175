import { actionChannel, ActionPattern, call, fork, put, take } from 'redux-saga/effects';
import { Action } from 'redux';
import { FETCH_LANGUAGES_REQUEST, SET_LANGUAGES_REQUEST } from 'shared/redux/types/LanguageTypes';
import { getLanguagesAPI, setLanguagesAPI } from 'shared/redux/api/LanguageApi';
import {
  fetchLanguagesError,
  fetchLanguagesSuccess,
  setLanguagesError,
  setLanguagesSuccess,
} from 'shared/redux/actions/LanguageActions';
import languageSelector from 'shared/redux/selector/LanguageSelector';

function* getLanguages(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const languagesChannel = yield actionChannel(actionType);
  while (true) {
    yield take(languagesChannel);
    try {
      // @ts-ignore
      const response = yield call(getLanguagesAPI);
      yield put(fetchLanguagesSuccess(languageSelector(response.data)));
    } catch ({ message }) {
      yield put(fetchLanguagesError({ message }));
    }
  }
}

function* setLanguages(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const setLanguagesChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(setLanguagesChannel);
    try {
      // @ts-ignore
      const response = yield call(setLanguagesAPI, payload);
      yield put(setLanguagesSuccess(response.data));
    } catch ({ message }) {
      yield put(setLanguagesError({ message }));
    }
  }
}

function* languagesSaga() {
  yield fork(getLanguages, FETCH_LANGUAGES_REQUEST);
  yield fork(setLanguages, SET_LANGUAGES_REQUEST);
}

export default languagesSaga;
