const currencySelector = (currency: any[]) => {
  if (!currency || !Array.isArray(currency)) {
    return [];
  }
  return currency.map((row) => {
    return {
      id: row?.id,
      code: row.code,
    };
  });
};

export default currencySelector;
