import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { isDoctor } from 'shared/services/EnvService';
import { slide as Menu } from 'react-burger-menu';
import { useTranslation } from 'react-i18next';
import Colors from 'shared/themes/Colors';
import {
  getAuthState,
  getPatientProfileState,
  getUserProfileState,
} from 'shared/redux/src/StatesGetter';
import CustomIcon from './CustomIcon';
import Avatar from './Avatar';

const HeaderMenuMobile = () => {
  const { t } = useTranslation();
  const { isLoggedIn, isDoctor: isDoctorUser } = useSelector(getAuthState, shallowEqual);
  const goToBlog = () => window.open(`https://blog.ringdoc.ro`, '_self');
  const profileData = useSelector(
    isDoctorUser ? getUserProfileState : getPatientProfileState,
    shallowEqual,
  );

  const homeClass = (() => {
    if (window.location.pathname === '/') return 'active';
    if (window.location.pathname.match(/\/landing/)) return 'active';
    return '';
  })();
  const aboutClass = window.location.pathname.match(/\/about-us/) ? 'active' : '';
  const howItWorksClass = window.location.pathname.match(/\/how-it-works/) ? 'active' : '';
  const contactClass = window.location.pathname.match(/\/contact/) ? 'active' : '';

  return (
    <Menu right burgerButtonClassName="mobile-menu">
      <ul>
        <li className={homeClass}>
          <Link className="cursor-pointer" to={`${isDoctor(true) ? '/doctor/landing' : '/'}`}>
            {t('mainScreen')}
            <CustomIcon
              className="custom-icon"
              color={Colors.darkGrey}
              size="20"
              icon="Right_pointer"
            />
          </Link>
        </li>
        <li className={aboutClass}>
          <Link
            className="cursor-pointer"
            to={`${isDoctor(true) ? '/doctor/about-us' : '/patient/about-us'}`}
          >
            {t('aboutUs')}
            <CustomIcon
              className="custom-icon"
              color={Colors.darkGrey}
              size="20"
              icon="Right_pointer"
            />
          </Link>
        </li>
        <li className={howItWorksClass}>
          <Link
            className="cursor-pointer"
            to={`${isDoctor(true) ? '/doctor/how-it-works' : '/patient/how-it-works'}`}
          >
            {t('howItWork')}
            <CustomIcon
              className="custom-icon"
              color={Colors.darkGrey}
              size="20"
              icon="Right_pointer"
            />
          </Link>
        </li>
        <li>
          <div className="cursor-pointer" onClick={goToBlog}>
            {t('blog')}
            <CustomIcon
              className="custom-icon"
              color={Colors.darkGrey}
              size="20"
              icon="Right_pointer"
            />
          </div>
        </li>
        <li className={contactClass}>
          <Link
            className="cursor-pointer"
            to={`${isDoctor(true) ? '/doctor/contact' : '/patient/contact'}`}
          >
            {t('contact')}
            <CustomIcon
              className="custom-icon"
              color={Colors.darkGrey}
              size="20"
              icon="Right_pointer"
            />
          </Link>
        </li>
        {!isLoggedIn && isDoctor(true) && (
          <>
            <li className="auth">
              <Link
                className="cursor-pointer"
                to={`${isDoctor(true) ? '/doctor/login' : '/patient/login'}`}
              >
                {t('patientApp.login')}
                <CustomIcon
                  className="custom-icon"
                  color={Colors.darkGrey}
                  size="20"
                  icon="Right_pointer"
                />
              </Link>
            </li>
            <li>
              <Link className="cursor-pointer" to="/">
                {t('imAPatient')}
                <CustomIcon
                  className="custom-icon"
                  color={Colors.darkGrey}
                  size="20"
                  icon="Right_pointer"
                />
              </Link>
            </li>
          </>
        )}
        {!isLoggedIn && !isDoctor(true) && (
          <li className="auth">
            <Link
              className="cursor-pointer"
              to={`${isDoctor(true) ? '/doctor/login' : '/patient/login'}`}
            >
              {t('patientApp.login')}
              <CustomIcon
                className="custom-icon"
                color={Colors.darkGrey}
                size="20"
                icon="Right_pointer"
              />
            </Link>
          </li>
        )}

        {isLoggedIn && isDoctor(true) && !isDoctorUser && (
          <li className="auth">
            <Link
              className="cursor-pointer"
              to={`${isDoctor(true) ? '/doctor/login' : '/patient/login'}`}
            >
              {t('patientApp.login')}
              <CustomIcon
                className="custom-icon"
                color={Colors.darkGrey}
                size="20"
                icon="Right_pointer"
              />
            </Link>
          </li>
        )}
        {isLoggedIn && isDoctor(true) && isDoctorUser && (
          <li className="my-account">
            <Link className="cursor-pointer" to={`${isDoctorUser ? '/doctor/home' : '/'}`}>
              <div className="avatar-section">
                <Avatar
                  size={25}
                  imageURL={isDoctorUser ? profileData?.pictureMedia : profileData?.imageUrl}
                  firstName={profileData.firstName}
                  lastName={profileData.lastName}
                />
                {isDoctorUser ? 'Dr.' : ''} {profileData?.lastName}
              </div>
              <CustomIcon
                className="custom-icon"
                color={Colors.darkGrey}
                size="20"
                icon="Right_pointer"
              />
            </Link>
          </li>
        )}

        {isLoggedIn && !isDoctor(true) && isDoctorUser && (
          <li className="auth">
            <Link
              className="cursor-pointer"
              to={`${isDoctor(true) ? '/doctor/login' : '/patient/login'}`}
            >
              {t('patientApp.login')}
              <CustomIcon
                className="custom-icon"
                color={Colors.darkGrey}
                size="20"
                icon="Right_pointer"
              />
            </Link>
          </li>
        )}
        {isLoggedIn && !isDoctor(true) && !isDoctorUser && (
          <li className="my-account">
            <Link className="cursor-pointer" to={`${isDoctorUser ? '/doctor/home' : '/'}`}>
              <div className="avatar-section">
                <Avatar
                  size={25}
                  imageURL={isDoctorUser ? profileData?.pictureMedia : profileData?.imageUrl}
                  firstName={profileData.firstName}
                  lastName={profileData.lastName}
                />
                {isDoctorUser ? 'Dr.' : ''} {profileData?.lastName}
              </div>
              <CustomIcon
                className="custom-icon"
                color={Colors.darkGrey}
                size="20"
                icon="Right_pointer"
              />
            </Link>
          </li>
        )}
      </ul>
    </Menu>
  );
};

export default HeaderMenuMobile;
