import 'doctor/styles/auth.css';
import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getAuthState } from 'shared/redux/src/StatesGetter';
import SocialLogin from 'shared/components/SocialLogin';
import { useTranslation } from 'react-i18next';

const DoctorAuth = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoggedIn, isProfileCompleted, lastUpdate } = useSelector(getAuthState, shallowEqual);

  useEffect(() => {
    if (isProfileCompleted === false) {
      navigate('/doctor/register-step-personal-data');
    } else if (isLoggedIn && lastUpdate === 'justNow') {
      navigate('/doctor/home');
    }
  }, [isLoggedIn, isProfileCompleted]);

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/doctor/home');
    }
  }, [isLoggedIn]);

  return (
    <section className="auth">
      <Link className="logo cursor-pointer" to="/doctor/home">
        <i className="ringdoc-medic-color-logo" />
      </Link>
      <div className="title-section">
        <div className="small-title-text">{t('doctorApp.welcome')}</div>
        <h1>{t('doctorApp.auth')}</h1>
      </div>
      <div className="social-login">
        <SocialLogin />
      </div>
      <div className="or">{t('or')}</div>
      <div className="buttons">
        <button type="button" onClick={() => navigate('/doctor/register')} className="green-button">
          {t('doctorApp.createAccount')}
        </button>
        <button type="button" onClick={() => navigate('/doctor/login')} className="white-button">
          {t('doctorApp.alreadyHaveAccount')}
        </button>
      </div>
    </section>
  );
};

export default DoctorAuth;
