import i18n from 'i18next';
import { actionChannel, call, fork, put, take } from 'redux-saga/effects';
import { updateSpecializationPricesAPI } from '../api/SpecializationsApi';
import {
  updateSpecializationPricesError,
  updateSpecializationPricesSuccess,
} from '../actions/SpecializationsActions';
import Alerts from '../../components/Alerts';
import { fetchMySpecializationsRequest } from '../actions/UserProfileActions';
import { UPDATE_SPECIALIZATIONS_PRICES_REQUEST } from '../types/SpecializationsTypes';

function* specializationsPrices(actionType) {
  const specializationsPricesChannel = yield actionChannel(actionType);
  while (true) {
    try {
      const { payload } = yield take(specializationsPricesChannel);
      yield call(updateSpecializationPricesAPI, payload);
      yield put(updateSpecializationPricesSuccess({}));
      Alerts.simpleAlert(`${i18n.t('success')}`, `${i18n.t('alerts.changesUpdated')}`);
      yield put(fetchMySpecializationsRequest({}));
    } catch ({ message }) {
      yield put(updateSpecializationPricesError({ message }));
    }
  }
}

function* specializationsSaga() {
  yield fork(specializationsPrices, UPDATE_SPECIALIZATIONS_PRICES_REQUEST);
}

export default specializationsSaga;
