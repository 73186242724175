import { actionChannel, call, fork, put, take } from 'redux-saga/effects';
import { GET_PRIVATE_MEDIA_FILE_REQUEST } from 'shared/redux/types/UngroupedTypes';
import { getPrivateMediaFileAPI } from 'shared/redux/api/UngroupedApi';
import {
  getPrivateMediaFileError,
  getPrivateMediaFileSuccess,
} from 'shared/redux/actions/UngroupedActions';

function* getPrivetMediaFile(actionType) {
  const privateMediaChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(privateMediaChannel);
    try {
      const response = yield call(getPrivateMediaFileAPI, payload);
      window.open(response.data, '_blank');
      yield put(getPrivateMediaFileSuccess({}));
    } catch ({ message }) {
      yield put(getPrivateMediaFileError({ message }));
    }
  }
}
function* mediaFilesSaga() {
  yield fork(getPrivetMediaFile, GET_PRIVATE_MEDIA_FILE_REQUEST);
}

export default mediaFilesSaga;
