import { default as ApiConstants } from 'shared/constants/ApiConstants.json';
import ApiUtils from 'shared/modules/ApiUtils';

const loginUserAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.postRequest(ApiConstants.LOGIN_ENDPOINT, payload);

const facebookLoginAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.postRequest(ApiConstants.FACEBOOK_LOGIN_ENDPOINT, payload);

const googleLoginAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.postRequest(ApiConstants.GOOGLE_LOGIN_ENDPOINT, payload);

const appleLoginAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.postRequest(ApiConstants.APPLE_LOGIN_ENDPOINT, payload);

const logoutUserAPI = () => ApiUtils.postRequest(ApiConstants.LOGOUT_ENDPOINT);

const forgotPasswordAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.postRequest(ApiConstants.FORGOT_PASSWORD_ENDPOINT, payload);

const resetPasswordAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.postRequest(ApiConstants.RESET_PASSWORD_ENDPOINT, payload);

const changePasswordAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.postRequest(ApiConstants.CHANGE_PASSWORD_ENDPOINT, payload);

const deleteAccountAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.postRequest(ApiConstants.DELETE_ACCOUNT_ENDPOINT, payload);

const resendActivationAPI = (payload) =>
  ApiUtils.postRequest(ApiConstants.RESEND_TOKEN_ENDPOINT, payload);

const confirmEmailAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.postRequest(ApiConstants.CONFIRM_EMAIL_ENDPOINT, payload);

const forceLogoutAPI = (payload) =>
  ApiUtils.postRequest(
    ApiConstants.FORCE_LOGOUT_ENDPOINT,
    payload.toRequest,
    payload.customHeaders,
  );

export {
  loginUserAPI,
  logoutUserAPI,
  googleLoginAPI,
  facebookLoginAPI,
  appleLoginAPI,
  forgotPasswordAPI,
  changePasswordAPI,
  resetPasswordAPI,
  deleteAccountAPI,
  confirmEmailAPI,
  forceLogoutAPI,
  resendActivationAPI,
};
