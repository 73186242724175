import PropTypes from 'prop-types';
import iconSet from 'shared/assets/fonts/RingdocCustomFont.json';
import IcomoonReact from 'icomoon-react';

const CustomIcon = ({ color, size, icon, className }) => {
  return (
    <IcomoonReact className={className} iconSet={iconSet} color={color} size={size} icon={icon} />
  );
};

CustomIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomIcon.defaultProps = {
  className: '',
  color: '',
  size: '100%',
};

export default CustomIcon;
