import i18n from 'i18next';
import { actionChannel, call, fork, put, take } from 'redux-saga/effects';
import Alerts from '../../components/Alerts';
import { fetchMySpecializationsRequest } from '../actions/UserProfileActions';
import { UPDATE_SERVICES_PRICES_REQUEST } from '../types/ServicesTypes';
import { updateDoctorServicesAPI } from '../api/UserProfileApi';
import { updateServicesPricesError, updateServicesPricesSuccess } from '../actions/ServicesActions';
import { getServiceAPI } from '../api/ServiceApi';
import { getServicesSuccess } from '../actions/ServiceActions';
import { GET_SERVICES_REQUEST } from '../types/ServiceTypes';
import { serviceSelector } from '../selector/ServiceSelector';

function* getServices(actionType) {
  const servicesChannel = yield actionChannel(actionType);
  while (true) {
    try {
      const { payload } = yield take(servicesChannel);
      // @ts-ignore
      const response = yield call(getServiceAPI, payload);
      yield put(getServicesSuccess(serviceSelector({ data: response.data })));
    } catch ({ message }) {
      yield put(getServicesSuccess({ message }));
    }
  }
}

function* servicesPrices(actionType) {
  const servicesPricesChannel = yield actionChannel(actionType);
  while (true) {
    try {
      const { payload } = yield take(servicesPricesChannel);
      yield call(updateDoctorServicesAPI, payload);
      yield put(updateServicesPricesSuccess({}));
      Alerts.simpleAlert(`${i18n.t('success')}`, `${i18n.t('alerts.changesUpdated')}`);
      yield put(fetchMySpecializationsRequest({}));
    } catch ({ message }) {
      yield put(updateServicesPricesError({ message }));
    }
  }
}

function* servicesSaga() {
  yield fork(servicesPrices, UPDATE_SERVICES_PRICES_REQUEST);
  yield fork(getServices, GET_SERVICES_REQUEST);
}

export default servicesSaga;
