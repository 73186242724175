const size = {
  s48: 48,
  s40: 40,
  s38: 38,
  s36: 36,
  s34: 34,
  s32: 32,
  s30: 30,
  s28: 28,
  s26: 26,
  s24: 24,
  s22: 22,
  s20: 20,
  s18: 18,
  s16: 16,
  s14: 14,
  s12: 12,
  s10: 10,
};

export default size;
