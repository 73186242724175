import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

/**
 * @param callback
 * @param delay in minutes
 * @param isActive
 */
const useInterval = (callback: undefined, delay: number, isActive: boolean) => {
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (!isActive) {
      try {
        return () => clearInterval(intervalId);
        // eslint-disable-next-line no-empty,no-unreachable
      } catch {}
    } else if (delay) {
      // @ts-ignore
      intervalId = setInterval(savedCallback?.current, 60000 * delay);
      return () => clearInterval(intervalId);
    }
  }, [delay, isActive]);
};

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export { useInterval, useQuery };
