import { default as ApiConstants } from 'shared/constants/ApiConstants.json';
import ApiUtils from 'shared/modules/ApiUtils';

const getUserProfileAPI = () =>
  ApiUtils.getRequest(`${ApiConstants.USER_DOCTOR_PROFILE_ENDPOINT}/`);

const getMySpecializationsAPI = () =>
  ApiUtils.getRequest(`${ApiConstants.GET_MY_SPECIALIZATIONS_ENDPOINT}/`);

const updateDoctorServicesAPI = (payload) =>
  ApiUtils.patchRequest(`${ApiConstants.DOCTOR_SERVICES}`, payload);

const updateUserProfileAPI = (payload) =>
  ApiUtils.patchRequest(ApiConstants.UPDATE_DOCTOR_USER_PROFILE_ENDPOINT, payload);

const updateDoctorOrganizationAPI = (payload) =>
  ApiUtils.patchRequest(ApiConstants.UPDATE_DOCTOR_ORGANIZATION_ENDPOINT, payload);

const updateDoctorCvAPI = (payload) =>
  ApiUtils.patchRequest(ApiConstants.UPDATE_DOCTOR_CV_ENDPOINT, payload);

const associateSpecializationAPI = (payload) =>
  ApiUtils.postRequest(ApiConstants.ASSOCIATE_SPECIALIZATION_ENDPOINT, payload);

const setAvailabilityAPI = (payload) =>
  ApiUtils.postRequest(ApiConstants.SET_AVAILABILITY_ENDPOINT, payload);

const fetchTitlesAPI = () => ApiUtils.getRequest(`${ApiConstants.FETCH_TITLES_ENDPOINT}`);

export {
  getUserProfileAPI,
  updateUserProfileAPI,
  updateDoctorServicesAPI,
  updateDoctorOrganizationAPI,
  updateDoctorCvAPI,
  associateSpecializationAPI,
  setAvailabilityAPI,
  getMySpecializationsAPI,
  fetchTitlesAPI,
};
