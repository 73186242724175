import { AnyAction } from 'redux';
import {
  GET_PRIVATE_MEDIA_FILE_ERROR,
  GET_PRIVATE_MEDIA_FILE_REQUEST,
  GET_PRIVATE_MEDIA_FILE_SUCCESS,
  UPDATE_LOCALE_ERROR,
  UPDATE_LOCALE_REQUEST,
  UPDATE_LOCALE_SUCCESS,
  UPDATE_SHARED_STATE_REQUEST,
} from 'shared/redux/types/UngroupedTypes';

export const updateSharedStateAction = (payload: any): AnyAction => ({
  type: UPDATE_SHARED_STATE_REQUEST,
  payload,
});

export const updateLocaleRequest = (payload: any): AnyAction => ({
  type: UPDATE_LOCALE_REQUEST,
  payload,
});

export const updateLocaleSuccess = (payload: any): AnyAction => ({
  type: UPDATE_LOCALE_SUCCESS,
  payload,
});

export const updateLocaleError = (payload: any): AnyAction => ({
  type: UPDATE_LOCALE_ERROR,
  payload,
});

export const getPrivateMediaFileRequest = (payload: any): AnyAction => ({
  type: GET_PRIVATE_MEDIA_FILE_REQUEST,
  payload,
});

export const getPrivateMediaFileSuccess = (payload: any): AnyAction => ({
  type: GET_PRIVATE_MEDIA_FILE_SUCCESS,
  payload,
});

export const getPrivateMediaFileError = (payload: any): AnyAction => ({
  type: GET_PRIVATE_MEDIA_FILE_ERROR,
  payload,
});
