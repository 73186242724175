import { AnyAction } from 'redux';
import {
  GET_SERVICES_REQUEST,
  GET_SERVICES_SUCCESS,
  GET_SERVICES_ERROR,
} from 'shared/redux/types/ServiceTypes';

export const getServicesRequest = (payload): AnyAction => ({
  type: GET_SERVICES_REQUEST,
  payload,
});

export const getServicesSuccess = (payload): AnyAction => ({
  type: GET_SERVICES_SUCCESS,
  payload,
});

export const getServicesError = (payload): AnyAction => ({
  type: GET_SERVICES_ERROR,
  payload,
});
