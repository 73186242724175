import i18n from 'i18next';
import Colors from '../../themes/Colors';

const mainScreenSelector = (isResident) => {
  try {
    const recommendRingdoc = {
      title: i18n.t('mlm.recommendRingdoc'),
      subtitle: '',
      withArrow: true,
      icon: 'Recenzii',
      iconColor: Colors.patientColor,
      onclick: '/doctor/recommend',
    };
    const availabilityRoute = {
      title: i18n.t('mainScreenRoutes.availability'),
      subtitle: i18n.t('mainScreenRoutes.availabilitySubtitle'),
      withArrow: true,
      icon: 'Calendar',
      iconColor: Colors.medicColor,
      onclick: '/doctor/availability-schedule',
    };
    const pricesRoute = {
      title: i18n.t('mainScreenRoutes.prices'),
      subtitle: i18n.t('mainScreenRoutes.pricesSubtitle'),
      withArrow: true,
      icon: 'Price',
      iconColor: Colors.yellow,
      onclick: '/doctor/rates',
    };

    const section1 = isResident ? [] : [recommendRingdoc, availabilityRoute, pricesRoute];
    const section2 = [];
    const section3 = [];

    const routes = [
      {
        title: 'section1',
        data: section1,
      },
      {
        title: 'section2',
        data: section2,
      },
      {
        title: 'section3',
        data: section3,
      },
    ];
    return {
      routes,
    };
  } catch (e) {
    return {
      routes: [],
    };
  }
};

export default mainScreenSelector;
