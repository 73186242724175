import { default as ApiConstants } from 'shared/constants/ApiConstants.json';
import ApiUtils from 'shared/modules/ApiUtils';

const getSpecializationDoctorsAPI = async ({
  id,
  page,
  limit,
  searchName,
  minPrice,
  maxPrice,
  rating,
  sortingOptions,
}) => {
  let criteria = '';

  if (id) {
    criteria += `&criteria[specialization]=${id}`;
  }

  if (searchName) {
    criteria += `&criteria[name][type]=contains&criteria[name][value]=${searchName}`;
  }

  if (minPrice) {
    criteria += `&criteria[price][greaterThan]=${minPrice}`;
  }

  if (maxPrice) {
    criteria += `&criteria[price][lessThan]=${maxPrice}`;
  }

  if (rating) {
    criteria += `&criteria[rating][min]=${rating}&criteria[rating][max]=5`;
  }

  if (sortingOptions) {
    criteria += `&${sortingOptions}=true`;
  }

  return ApiUtils.getRequest(
    `${ApiConstants.GET_SPECIALIZATION_DOCTORS_ENDPOINT}/doctors?page=${page}&limit=${limit}${criteria}`,
  );
};

const getSortingOptionsAPI = () => ApiUtils.getRequest(ApiConstants.GET_SORTING_OPTIONS_ENDPOINT);

const getDoctorTimeSlotsAPI = (payload) =>
  ApiUtils.getRequest(
    `${ApiConstants.DOCTOR_TIME_SLOTS_ENDPOINT}/${payload.id}/${payload.specializationId}/timetable/${payload.date}`,
  );

const getAvailableDoctorsAPI = () => ApiUtils.getRequest(ApiConstants.AVAILABLE_DOCTORS_ENDPOINT);

export {
  getSpecializationDoctorsAPI,
  getDoctorTimeSlotsAPI,
  getAvailableDoctorsAPI,
  getSortingOptionsAPI,
};
