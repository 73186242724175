import React from 'react';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import Utils from 'shared/modules/Utils';
import { Accordion } from 'react-bootstrap';
import dayjs from 'dayjs';

const DisplayDocumentComponent = ({ medicalRecords, setMedicalRecordData, showEditModal }) => {
  const goodDate = (item) => item.investigationDate ?? item.createdAt;
  const openMedicalRecord = (medicalRecord) => {
    setMedicalRecordData(medicalRecord);
    showEditModal();
  };

  return (
    <>
      <Accordion className="medical-folder-accordion" alwaysOpen>
        {medicalRecords.length > 0 &&
          medicalRecords.map((section, index) => (
            <Accordion.Item className="box" eventKey={index}>
              <Accordion.Header>
                <div className="title">{Utils.capitalizeFirstLetter(section.key)}</div>
                {section.data.length && <div className="counter-badge">{section.data.length}</div>}
              </Accordion.Header>
              <Accordion.Body>
                {section.data.map((item, eq) => (
                  <div key={item?.id} className="document">
                    <button type="button" onClick={() => openMedicalRecord(item)}>
                      <CustomIcon
                        className="custom-icon document"
                        color={Colors.darkGrey}
                        size="24"
                        icon="Document"
                      />
                      <div className="info">
                        <div className="name">
                          {Utils.shortenText(Utils.capitalizeFirstLetter(item.title), 30)}
                        </div>
                        <div className="data">{dayjs(goodDate(item)).format('DD MMM YYYY')}</div>
                      </div>
                      <CustomIcon
                        className="custom-icon arrow"
                        color={Colors.darkGrey}
                        size="24"
                        icon="Right_pointer"
                      />
                    </button>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          ))}
      </Accordion>
    </>
  );
};

export default DisplayDocumentComponent;
