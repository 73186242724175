import 'patient/styles/call-specialist.css';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getPatientAppointmentState,
  getPatientLocalChooseSpecializationState,
  getSpecializationsState,
} from 'shared/redux/src/StatesGetter';
import { getSpecializationsRequest } from 'shared/redux/actions/SpecializationActions';
import { useNavigate } from 'react-router-dom';
import Alerts from 'shared/components/Alerts';
import {
  isAnyAppointmentInProgress,
  requestAppointment,
} from 'shared/modules/PatientAppointmentUtils';
import {
  appointmentCancelRequest,
  appointmentLocalStepsRequest,
} from 'shared/redux/actions/PatientAppointmentActions';
import { patientLocallyChooseSpecialization } from 'shared/redux/actions/DoctorsActions';

const SelectSpecialization = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const specializations = useSelector(getSpecializationsState, shallowEqual);
  const [specializationSearch, setSpecializationSearch] = useState('');
  const handleSpecializationSearchChange = (e) => setSpecializationSearch(e.target.value);
  const {
    status: appointmentStatus,
    stickyStatus,
    noOfSteps,
  } = useSelector(getPatientAppointmentState, shallowEqual);
  const stepString = `${t('step')} ${noOfSteps - 3}/${noOfSteps}`;
  const onSpecializationClick = (e, specialization) => {
    e.preventDefault();
    if (isAnyAppointmentInProgress(appointmentStatus, stickyStatus)) {
      Alerts.simpleAlert(t('info'), t('validations.alreadyHasAppointment'));
      return;
    }
    dispatch(patientLocallyChooseSpecialization(specialization));
    dispatch(
      appointmentLocalStepsRequest({
        newAppointment: true,
        noOfSteps: 4,
        type: 'callSpecialist',
        specializationId: +specialization.id,
        specializationName: specialization.name,
      }),
    );
    navigate('/patient/call-specialist/profile');
  };
  const cancelAppointment = () => {
    Alerts.okCancelAlert(t('warning'), t('validations.cancelCallSpecialis'), () => {
      dispatch(
        appointmentLocalStepsRequest({
          newAppointment: true,
        }),
      );
      navigate('/patient/medicList');
    });
  };

  useEffect(() => {
    dispatch(
      getSpecializationsRequest({
        searchName: specializationSearch,
      }),
    );
  }, [specializationSearch]);

  return (
    <section className="call-specialist">
      <div className="title-section">
        <div className="steps">
          <div className="icon-wrapper" onClick={() => navigate(-1)}>
            <CustomIcon
              className="custom-icon"
              color={Colors.darkGrey}
              size="24"
              icon="Left_arrow"
            />
          </div>
          {stepString}
          <div className="cancel-button" onClick={cancelAppointment}>
            <button type="button">{t('abort')}</button>
          </div>
        </div>
        <h1>{t('selectSpecialization')}</h1>
      </div>
      <div className="specializations">
        <div className="searchbar">
          <CustomIcon className="custom-icon" color={Colors.mediumGrey} size="20" icon="Search" />
          <input
            type="text"
            placeholder={t('searchSpecialization')}
            value={specializationSearch}
            onChange={handleSpecializationSearchChange}
          />
        </div>
        <Form>
          {specializations.data.map((specialization) => {
            return (
              <div
                key={specialization.id}
                className="specialization-item"
                onClick={(e) => onSpecializationClick(e, specialization)}
              >
                <div>
                  <div className="specialization-name">{specialization.name}</div>
                  <div className="specialization-details">
                    {specialization.callSpecialistPriceAsString} /{' '}
                    {specialization.callSpecialistDuration} {t('minutes')}
                  </div>
                </div>

                <CustomIcon
                  className="custom-icon"
                  color={Colors.darkGrey}
                  size="24"
                  icon="Right_pointer"
                />
              </div>
            );
          })}
        </Form>
      </div>
    </section>
  );
};

export default SelectSpecialization;
