import 'shared/styles/about-us.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { isDoctor } from 'shared/services/EnvService';
import FooterSection from 'shared/components/FooterSection';
import HeaderMenu from 'shared/components/HeaderMenu';
import HeaderMenuMobile from 'shared/components/HeaderMenuMobile';
import CustomIcon from 'shared/components/CustomIcon';
import aboutUsImage from 'shared/assets/images/about-us/despre-noi-image.png';
import appPatientImage from 'shared/assets/images/about-us/aplicatie-pacient-image.png';
import openGraphImage from 'shared/assets/images/ringdoc_image_open_graph.png';
import Colors from 'shared/themes/Colors';
import Utils from '../modules/Utils';

const AboutUsPage = () => {
  const { t } = useTranslation();

  let doctorAppStoreLink;
  if (isDoctor(true)) {
    if (Utils.isiPhone()) {
      doctorAppStoreLink = 'itms-apps://apps.apple.com/us/app/ringdoc-medic/id1574488917';
    } else {
      doctorAppStoreLink = 'https://apps.apple.com/us/app/ringdoc-medic/id1574488917';
    }
  }
  let patientAppStoreLink;
  if (!isDoctor(true)) {
    if (Utils.isiPhone()) {
      patientAppStoreLink = 'itms-apps://apps.apple.com/us/app/ringdoc/id1574488837';
    } else {
      patientAppStoreLink = 'https://apps.apple.com/us/app/ringdoc/id1574488837';
    }
  }

  const currentUrl = window.location.href;
  const baseUrl = window.location.origin;

  return (
    <div className="about-us-page gradient-about-us">
      <Helmet>
        <title>{t('aboutUsPage.titlePage')}</title>
        <meta
          name="description"
          content="Ringdoc este o clinica medicala care opereaza o platforma medicala virtuala oferind astfel medici online disponibili rapid"
        />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:locale" content="ro_RO" />
        <meta property="og:locale:alternate" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={t('aboutUsPage.titlePage')} />
        <meta
          property="og:description"
          content="Ringdoc este o clinica medicala care opereaza o platforma medicala virtuala oferind astfel medici online disponibili rapid"
        />
        <meta property="og:image" content={`${baseUrl}${openGraphImage}`} />
      </Helmet>

      {isDoctor(true) && <HeaderMenuMobile />}

      <div className="container-fluid">
        <div className="row">
          <HeaderMenu />

          <main className="container">
            <section className="title">
              <h1>{t('aboutUsPage.title')}</h1>
            </section>
            <section className="about-us">
              <p>{t('aboutUsPage.subtitle1')}</p>
              <div className="about-us-image">
                <img src={aboutUsImage} className="img-fluid" alt="Despre noi" />
              </div>
              <div className="row rowp10">
                <div className="colp10 col-12">
                  <div className="box">
                    <div className="row rowp10">
                      <div className="colp10 col-lg-8 col-xl-8 col-md-8 col-12 text-box">
                        <h3>{t('aboutUsPage.box.title1')}</h3>
                        <p>{t('aboutUsPage.box.description1')}</p>
                      </div>
                      <div className="colp10 col-lg-4 col-xl-4 col-md-4 col-12">
                        <div className="box viziune">
                          <CustomIcon
                            className="custom-icon"
                            color={Colors.white}
                            size="92"
                            icon="Eye"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="colp10 col-12">
                  <div className="box">
                    <div className="row rowp10">
                      <div className="colp10 col-lg-8 col-xl-8 col-md-8 col-12 text-box">
                        <h3>{t('aboutUsPage.box.title2')}</h3>
                        <p>{t('aboutUsPage.box.description2')}</p>
                      </div>
                      <div className="colp10 col-lg-4 col-xl-4 col-md-4 col-12">
                        <div className="box misiuni">
                          <CustomIcon
                            className="custom-icon"
                            color={Colors.white}
                            size="92"
                            icon="Flag"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="colp10 col-12">
                  <div className="box">
                    <div className="row rowp10">
                      <div className="colp10 col-lg-8 col-xl-8 col-md-8 col-12 text-box">
                        <h3>{t('aboutUsPage.box.title3')}</h3>
                        <p>{t('aboutUsPage.box.description3')}</p>
                      </div>
                      <div className="colp10 col-lg-4 col-xl-4 col-md-4 col-12">
                        <div className="box valori">
                          <CustomIcon
                            className="custom-icon"
                            color={Colors.white}
                            size="92"
                            icon="Star"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="download-app patient">
              <div className="column">
                <h3>{t('aboutUsPage.downloadAppPatient')}</h3>
                <div className="apps-button">
                  <a
                    target="_blank"
                    href={isDoctor(true) ? doctorAppStoreLink : patientAppStoreLink}
                    rel="noreferrer"
                  >
                    <i className="app-store-icon cursor-pointer" />
                  </a>
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=ro.ascendnet.alodoc.patient.ro"
                    rel="noreferrer"
                  >
                    <i className="google-play-icon cursor-pointer" />
                  </a>
                </div>
              </div>
              <div className="column">
                <img src={appPatientImage} alt="Descarca Aplicatia" />
              </div>
            </section>
          </main>

          <FooterSection />
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
