import { default as ApiConstants } from 'shared/constants/ApiConstants.json';
import ApiUtils from 'shared/modules/ApiUtils';
import i18next from 'i18next';

const getAboutUsAPI = () =>
  ApiUtils.getRequest(
    i18next.language === 'ro' ? ApiConstants.ABOUT_US_ENDPOINT : ApiConstants.ABOUT_US_ENDPOINT_EN,
  );
const getPrivacyPolicyAPI = () =>
  i18next.language === 'ro'
    ? ApiUtils.getRequest(ApiConstants.PRIVACY_POLICY_ENDPOINT)
    : ApiUtils.getRequest(ApiConstants.PRIVACY_POLICY_ENDPOINT_EN);
const getTermsAPI = () =>
  i18next.language === 'ro'
    ? ApiUtils.getRequest(ApiConstants.TERMS_ENDPOINT)
    : ApiUtils.getRequest(ApiConstants.TERMS_ENDPOINT_EN);
const getGDPRAPI = () =>
  i18next.language === 'ro'
    ? ApiUtils.getRequest(ApiConstants.GDPR_ENDPOINT)
    : ApiUtils.getRequest(ApiConstants.GDPR_ENDPOINT_EN);
const getStripeTermsAPI = () =>
  i18next.language === 'ro'
    ? ApiUtils.getRequest(ApiConstants.STRIPE_TERMS_ENDPOINT)
    : ApiUtils.getRequest(ApiConstants.TERMS_ENDPOINT_EN);
const getTwilioTermsAPI = () =>
  i18next.language === 'ro'
    ? ApiUtils.getRequest(ApiConstants.TWILIO_TERMS_ENDPOINT)
    : ApiUtils.getRequest(ApiConstants.TERMS_ENDPOINT_EN);

export {
  getAboutUsAPI,
  getPrivacyPolicyAPI,
  getTermsAPI,
  getGDPRAPI,
  getStripeTermsAPI,
  getTwilioTermsAPI,
};
