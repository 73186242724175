import { AnyAction } from 'redux';
import {
  ADD_FAMILY_MEMBER_DISEASE_ERROR,
  ADD_FAMILY_MEMBER_DISEASE_REQUEST,
  ADD_FAMILY_MEMBER_DISEASE_SUCCESS,
  ADD_FAMILY_MEMBER_ERROR,
  ADD_FAMILY_MEMBER_REQUEST,
  ADD_FAMILY_MEMBER_SUCCESS,
  DELETE_FAMILY_MEMBER_DISEASE_ERROR,
  DELETE_FAMILY_MEMBER_DISEASE_REQUEST,
  DELETE_FAMILY_MEMBER_DISEASE_SUCCESS,
  GET_FAMILY_MEMBERS_ERROR,
  GET_FAMILY_MEMBERS_REQUEST,
  GET_FAMILY_MEMBERS_SUCCESS,
  GET_PROFILE_ERROR,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  REMOVE_FAMILY_MEMBER_ERROR,
  REMOVE_FAMILY_MEMBER_REQUEST,
  REMOVE_FAMILY_MEMBER_SUCCESS,
  UPDATE_FAMILY_MEMBER_DISEASE_ERROR,
  UPDATE_FAMILY_MEMBER_DISEASE_REQUEST,
  UPDATE_FAMILY_MEMBER_DISEASE_SUCCESS,
  UPDATE_FAMILY_MEMBER_ERROR,
  UPDATE_FAMILY_MEMBER_REQUEST,
  UPDATE_FAMILY_MEMBER_SUCCESS,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
} from 'shared/redux/types/FamilyMembersTypes';

export const getFamilyMembersRequest = (payload): AnyAction => ({
  type: GET_FAMILY_MEMBERS_REQUEST,
  payload,
});

export const getFamilyMembersSuccess = (payload): AnyAction => ({
  type: GET_FAMILY_MEMBERS_SUCCESS,
  payload,
});

export const getFamilyMembersError = (payload): AnyAction => ({
  type: GET_FAMILY_MEMBERS_ERROR,
  payload,
});

export const getProfileRequest = (payload): AnyAction => ({
  type: GET_PROFILE_REQUEST,
  payload,
});

export const getProfileSuccess = (payload): AnyAction => ({
  type: GET_PROFILE_SUCCESS,
  payload,
});

export const getProfileError = (payload): AnyAction => ({
  type: GET_PROFILE_ERROR,
  payload,
});

export const updateProfileRequest = (payload): AnyAction => ({
  type: UPDATE_PROFILE_REQUEST,
  payload,
});

export const updateProfileSuccess = (payload): AnyAction => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload,
});

export const updateProfileError = (payload): AnyAction => ({
  type: UPDATE_PROFILE_ERROR,
  payload,
});

export const addFamilyMemberRequest = (payload): AnyAction => ({
  type: ADD_FAMILY_MEMBER_REQUEST,
  payload,
});

export const addFamilyMemberSuccess = (payload): AnyAction => ({
  type: ADD_FAMILY_MEMBER_SUCCESS,
  payload,
});

export const addFamilyMemberError = (payload): AnyAction => ({
  type: ADD_FAMILY_MEMBER_ERROR,
  payload,
});

export const updateFamilyMemberRequest = (payload): AnyAction => ({
  type: UPDATE_FAMILY_MEMBER_REQUEST,
  payload,
});

export const updateFamilyMemberSuccess = (payload): AnyAction => ({
  type: UPDATE_FAMILY_MEMBER_SUCCESS,
  payload,
});

export const updateFamilyMemberError = (payload): AnyAction => ({
  type: UPDATE_FAMILY_MEMBER_ERROR,
  payload,
});

export const removeFamilyMemberRequest = (payload): AnyAction => ({
  type: REMOVE_FAMILY_MEMBER_REQUEST,
  payload,
});

export const removeFamilyMemberSuccess = (payload): AnyAction => ({
  type: REMOVE_FAMILY_MEMBER_SUCCESS,
  payload,
});

export const removeFamilyMemberError = (payload): AnyAction => ({
  type: REMOVE_FAMILY_MEMBER_ERROR,
  payload,
});

export const addFamilyMemberDiseaseRequest = (payload): AnyAction => ({
  type: ADD_FAMILY_MEMBER_DISEASE_REQUEST,
  payload,
});

export const addFamilyMemberDiseaseSuccess = (payload): AnyAction => ({
  type: ADD_FAMILY_MEMBER_DISEASE_SUCCESS,
  payload,
});

export const addFamilyMemberDiseaseError = (payload): AnyAction => ({
  type: ADD_FAMILY_MEMBER_DISEASE_ERROR,
  payload,
});

export const deleteFamilyMemberDiseaseRequest = (payload): AnyAction => ({
  type: DELETE_FAMILY_MEMBER_DISEASE_REQUEST,
  payload,
});

export const deleteFamilyMemberDiseaseSuccess = (payload): AnyAction => ({
  type: DELETE_FAMILY_MEMBER_DISEASE_SUCCESS,
  payload,
});

export const deleteFamilyMemberDiseaseError = (payload): AnyAction => ({
  type: DELETE_FAMILY_MEMBER_DISEASE_ERROR,
  payload,
});

export const updateFamilyMemberDiseaseRequest = (payload): AnyAction => ({
  type: UPDATE_FAMILY_MEMBER_DISEASE_REQUEST,
  payload,
});

export const updateFamilyMemberDiseaseSuccess = (payload): AnyAction => ({
  type: UPDATE_FAMILY_MEMBER_DISEASE_SUCCESS,
  payload,
});

export const updateFamilyMemberDiseaseError = (payload): AnyAction => ({
  type: UPDATE_FAMILY_MEMBER_DISEASE_ERROR,
  payload,
});
