import { AnyAction } from 'redux';
import {
  CONFIRM_EMAIL_SUCCESS,
  FORCE_LOGOUT_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
} from 'shared/redux/types/AuthTypes';
import { REGISTER_SUCCESS } from 'shared/redux/types/UserTypes';
import { UPDATE_SHARED_STATE_REQUEST } from 'shared/redux/types/UngroupedTypes';

const AuthReducer = (
  state = {
    isLoggedIn: undefined,
    isConfirmed: undefined,
    isProfileCompleted: undefined,
    hasFullAccess: undefined,
    lastUpdate: undefined,
    isDoctor: false,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case UPDATE_SHARED_STATE_REQUEST:
      if (action.payload.isLoggedIn) {
        return { ...state, ...action.payload };
      }
      return state;
    case CONFIRM_EMAIL_SUCCESS:
    case LOGIN_SUCCESS:
      return { ...state, ...action.payload };
    case FORCE_LOGOUT_SUCCESS:
    case LOGOUT_SUCCESS:
      return {
        ...action.payload,
        isLoggedIn: false,
        isConfirmed: undefined,
        isProfileCompleted: undefined,
        hasFullAccess: undefined,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isConfirmed: false,
        isProfileCompleted: undefined,
        hasFullAccess: false,
      };
    default:
      return state;
  }
};

export default AuthReducer;
