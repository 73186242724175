import {
  translatedDayOfWeek,
  translatedDayOfWeekWithNumber,
  translatedMonthAndYear,
} from 'shared/modules/DateTimeUtils';

const transactionsSelector = (rawTransactions) => {
  const { data = {} } = rawTransactions;
  // eslint-disable-next-line no-underscore-dangle
  const { items: transactions = [] } = data._embedded;
  return transactions
    .filter((row) => row.connected_account)
    .map((transaction) => {
      return {
        id: transaction?.id,
        type: 'appointment',
        card: {
          last4: transaction.card_last_digits,
          brand: transaction.card_brand,
        },
        doctor: {
          id: transaction.appointment.doctor_or_anon_user?.id,
          name: transaction.appointment.doctor_or_anon_user.full_name_with_title,
          firstName: transaction.appointment.doctor_or_anon_user.first_name,
          lastName: transaction.appointment.doctor_or_anon_user.last_name,
          rating: transaction.appointment.doctor_or_anon_user.average_rating,
          reviewsNumber: transaction.appointment.doctor_or_anon_user.count_rating,
          imgUrl: transaction.appointment.doctor_or_anon_user.picture_media?.public_url ?? null,
        },
        createdAt: transaction.appointment.time_start,
        createdAtFormatted: translatedMonthAndYear(transaction.appointment.time_start),
        refund: transaction.status === 'full_refunded',
        amount: transaction.appointment.price_as_string,
      };
    });
};

export default transactionsSelector;
