import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Alerts from 'shared/components/Alerts';
import { deleteAccountRequest } from 'shared/redux/actions/AuthActions';
import { getAuthState, getUserProfileState } from 'shared/redux/src/StatesGetter';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { getEnv, isLoginOrRegister } from 'shared/services/EnvService';
import { useTranslation } from 'react-i18next';
import StorageService from '../../../shared/services/StorageService';
import { updateLocaleRequest } from '../../../shared/redux/actions/UngroupedActions';

const DoctorAccountDeleteAccountBlock = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profileData = useSelector(getUserProfileState, shallowEqual);
  const { isCreatedWithSocialLogin } = profileData;
  const [requestedInput, setRequestedInput] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };
  const onPress = (e) => {
    e.preventDefault();
    if (!requestedInput) {
      Alerts.simpleAlert(`${t('error')}`, `${t('account.deleteAccountValidation')}`);
      return;
    }
    let payloadToSend;
    if (isCreatedWithSocialLogin) {
      payloadToSend = { email: requestedInput };
    } else {
      payloadToSend = { password: requestedInput };
    }
    Alerts.okCancelAlert(`${t('warning')}`, `${t('validations.deleteAccount')}`, () =>
      dispatch(deleteAccountRequest(payloadToSend)),
    );
  };
  return (
    <div className="account-delete-account">
      <div className="title-section">
        <h2>{t('settings.deleteAccount')}</h2>
      </div>
      <div className="info-message">
        <CustomIcon className="custom-icon" color={Colors.mediumGrey} size="24" icon="Warning_2" />
        <div>
          {t('account.deleteAccountSubtitle2')}
          <br />
          <br />
          {isCreatedWithSocialLogin
            ? t('account.deleteAccountConfirm2')
            : t('account.deleteAccountConfirm')}
        </div>
      </div>
      <form onSubmit={onPress}>
        {isCreatedWithSocialLogin ? (
          <div className="form-input">
            <input
              autoComplete="off"
              id="email"
              name="email"
              type="email"
              className="form-control"
              placeholder=" "
            />
            <label htmlFor="email">{t('email')}</label>
          </div>
        ) : (
          <div className="password-eye">
            <input
              name="password"
              id="password"
              value={requestedInput}
              onChange={(event) => setRequestedInput(event.target.value)}
              className="form-control"
              placeholder=" "
              type={passwordShown ? 'text' : 'password'}
            />
            <label htmlFor="password">{t('password')}</label>
            <div className="eye" onClick={togglePasswordVisibility}>
              <CustomIcon
                className="custom-icon"
                color={Colors.mediumGrey}
                size="24"
                icon={`${passwordShown ? 'Eye_off' : 'Eye'}`}
              />
            </div>
          </div>
        )}
        <button type="submit" className="orange-button">
          {t('account.deleteAccountBtn2')}
        </button>
      </form>
    </div>
  );
};

export default DoctorAccountDeleteAccountBlock;
