import React, { useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import CustomIcon from '../../../shared/components/CustomIcon';
import Colors from '../../../shared/themes/Colors';
import {
  addAppointmentPrescriptionMedicationRequest,
  getAppointmentDetailsRequest,
  updateAppointmentPrescriptionMedicationRequest,
} from '../../../shared/redux/actions/DoctorAppointmentsActions';
import Alerts from '../../../shared/components/Alerts';

const Medication = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    setAddNewMedication,
    prescription,
    appointmentId,
    conclusionAddMedication,
    currentMedication,
    setCurrentMedication,
  } = props;

  const [medicationId, setMedicationId] = useState(currentMedication?.id);
  const [medicationName, setMedicationName] = useState(currentMedication?.name);
  const [medicationConcentration, setMedicationConcentration] = useState(
    currentMedication?.concentration,
  );
  const [medicationAdministration, setMedicationAdministration] = useState(
    currentMedication?.administration,
  );
  const [medicationQuantity, setMedicationQuantity] = useState(currentMedication?.quantity);
  const [medicationDuration, setMedicationDuration] = useState(currentMedication?.duration);
  const [medicationType, setMedicationType] = useState(currentMedication?.type);

  useEffect(() => {
    setMedicationId(currentMedication?.id ? currentMedication?.id : '');
    setMedicationName(currentMedication?.name ? currentMedication?.name : '');
    setMedicationConcentration(
      currentMedication?.concentration ? currentMedication?.concentration : '',
    );
    setMedicationAdministration(
      currentMedication?.administration ? currentMedication?.administration : '',
    );
    setMedicationQuantity(currentMedication?.quantity ? currentMedication?.quantity : '');
    setMedicationDuration(currentMedication?.duration ? currentMedication?.duration : '');
    setMedicationType(currentMedication?.type ? currentMedication?.type : '');
  }, [currentMedication]);

  const addMedicationId = (val) => {
    setMedicationId(val);
  };

  const addMedicationName = (val) => {
    setMedicationName(val);
  };

  const addMedicationConcentration = (val) => {
    setMedicationConcentration(val);
  };

  const addMedicationType = (val) => {
    setMedicationType(val);
  };

  const addMedicationAdministration = (val) => {
    setMedicationAdministration(val);
  };

  const addMedicationQuantity = (val) => {
    setMedicationQuantity(val);
  };

  const addMedicationDuration = (val) => {
    setMedicationDuration(val);
  };

  const addMedicineToPrescription = (id) => {
    if (
      // medicationName === '' ||
      medicationConcentration === '' ||
      medicationAdministration === '' ||
      medicationQuantity === '' ||
      medicationDuration === '' ||
      medicationType === ''
    ) {
      Alerts.simpleAlert(t('error'), t('prescription.mandatoryFields'));
    } else {
      const localMedication = {
        name: medicationName,
        concentration: medicationConcentration,
        administration: medicationAdministration,
        quantity: medicationQuantity,
        duration: medicationDuration,
        type: medicationType,
      };
      if (id === '') {
        dispatch(
          addAppointmentPrescriptionMedicationRequest({
            toAdd: localMedication,
            id: prescription.id,
          }),
        );
      } else {
        dispatch(
          updateAppointmentPrescriptionMedicationRequest({
            toAdd: localMedication,
            id: medicationId,
          }),
        );
      }

      setCurrentMedication({
        id: '',
        name: '',
        concentration: '',
        administration: '',
        quantity: '',
        duration: '',
        type: '',
      });
      setTimeout(() => {
        dispatch(getAppointmentDetailsRequest({ id: appointmentId }));
      }, 1000);

      conclusionAddMedication();
    }
  };

  const redirectBackToPrescription = () => {
    if (location.pathname.indexOf('/doctor/consultation-details') === 0) {
      setCurrentMedication({
        id: '',
        name: '',
        concentration: '',
        administration: '',
        quantity: '',
        duration: '',
        type: '',
      });
      setTimeout(() => {
        dispatch(getAppointmentDetailsRequest({ id: appointmentId }));
      }, 1000);
      conclusionAddMedication();
    } else if (location.pathname.indexOf('/doctor/final-consultation') === 0) {
      setCurrentMedication({
        id: '',
        name: '',
        concentration: '',
        administration: '',
        quantity: '',
        duration: '',
        type: '',
      });
      setTimeout(() => {
        dispatch(getAppointmentDetailsRequest({ id: appointmentId }));
      }, 1000);
      conclusionAddMedication();
    } else {
      setAddNewMedication(false);
    }
  };

  return (
    <div>
      <div className="mb-5">
        <div>
          <div
            className="custom-icon-back-medicine"
            onClick={() => {
              redirectBackToPrescription();
            }}
          >
            <CustomIcon
              className="custom-icon"
              color={Colors.darkGrey}
              size="24"
              icon="Left_arrow"
            />{' '}
            {t('prescription.title')}
          </div>
        </div>
        <div className="form-input">
          <input name="id" defaultValue={medicationId} type="hidden" />
        </div>
        <div className="form-input">
          <TextareaAutosize
            name="medicationName"
            id="medicationName"
            defaultValue={medicationName}
            className="form-control"
            placeholder=" "
            onChange={(event) => {
              addMedicationName(event.target.value);
            }}
          />
          <label htmlFor="medicationName">{t('prescription.medicationName')}*</label>
        </div>
        <div className="form-input">
          <TextareaAutosize
            name="medicationConcentration"
            id="medicationConcentration"
            className="form-control textarea-autoheight"
            defaultValue={medicationConcentration}
            placeholder=" "
            onChange={(event) => {
              addMedicationConcentration(event.target.value);
            }}
            minRows={1}
          />
          <label htmlFor="medicationConcentration">
            {t('prescription.medicationConcentration')}*
          </label>
        </div>
        <div className="form-input">
          <TextareaAutosize
            name="medicationType"
            id="medicationType"
            className="form-control textarea-autoheight"
            defaultValue={medicationType}
            placeholder=" "
            onChange={(event) => {
              addMedicationType(event.target.value);
            }}
            minRows={1}
          />
          <label htmlFor="medicationType">{t('prescription.medicationType')}*</label>
        </div>
        <div className="form-input">
          <TextareaAutosize
            name="medicationAdministration"
            id="medicationAdministration"
            defaultValue={medicationAdministration}
            className="form-control textarea-autoheight"
            placeholder=" "
            onChange={(event) => {
              addMedicationAdministration(event.target.value);
            }}
            minRows={1}
          />
          <label htmlFor="medicationAdministration">
            {t('prescription.medicationAdministration')}*
          </label>
        </div>
        <div className="form-input">
          <TextareaAutosize
            name="medicationQuantity"
            id="medicationQuantity"
            defaultValue={medicationQuantity}
            className="form-control textarea-autoheight"
            placeholder=" "
            onChange={(event) => {
              addMedicationQuantity(event.target.value);
            }}
            minRows={1}
          />
          <label htmlFor="medicationQuantity">{t('prescription.medicationQuantity')}*</label>
        </div>
        <div className="form-input">
          <TextareaAutosize
            name="medicationDuration"
            id="medicationDuration"
            defaultValue={medicationDuration}
            className="form-control textarea-autoheight"
            placeholder=" "
            onChange={(event) => {
              addMedicationDuration(event.target.value);
            }}
            minRows={1}
          />
          <label htmlFor="medicationDuration">{t('prescription.medicationDuration')}*</label>
        </div>

        <button
          type="button"
          className="green-button btn-send-conclusions mt-4"
          onClick={() => {
            addMedicineToPrescription(medicationId);
          }}
        >
          {t('prescription.addMedication')}
        </button>
      </div>
    </div>
  );
};

export default Medication;
