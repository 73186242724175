import React, { SetStateAction, useState } from 'react';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import Utils from 'shared/modules/Utils';
import { useTranslation } from 'react-i18next';
import { Accordion } from 'react-bootstrap';
import _ from 'lodash';
import dayjs from 'dayjs';

const SelectDocumentComponent = (props) => {
  const { t } = useTranslation();
  const { medicalRecords, sharedFiles, setSelectedFiles, selectedFiles } = props;
  const [selectedSections, setSelectedSections] = useState([]);
  const isSelected = (id) => selectedFiles?.indexOf(id as never) > -1;
  const isShared = (id) => sharedFiles?.indexOf(id) > -1;
  const setSelection = (id) => {
    if (isShared(id)) return;
    const newFiles: string[] = [...selectedFiles];
    const index = newFiles.indexOf(id);
    if (index > -1) {
      newFiles.splice(index, 1);
    } else {
      newFiles.push(id);
    }
    setSelectedFiles(newFiles as SetStateAction<never[]>);
  };
  const isAllSectionSelected = (section) => {
    const selectedGroup = medicalRecords.find((row) => row.key === section).data;
    return !selectedGroup.find(
      (row) => selectedFiles.indexOf(row.id as never) === -1 && !isShared(row.id),
    );
  };
  const toggleSelectAll = (key) => {
    const selectedGroup = medicalRecords.find((row) => row.key === key).data;
    // if (isAllSectionSelected(key)) return;
    const selectedIds: string | number[] = [];
    selectedGroup.map((row) => {
      selectedIds.push(row.id);
      return true;
    });
    if (selectedSections.indexOf(key as never) === -1) {
      setSelectedSections([...selectedSections, key as never]);
      setSelectedFiles([...selectedFiles, ...(selectedIds as never[])]);
    } else {
      const newSelected = [];
      selectedFiles.map((row) => {
        if (selectedIds.indexOf(row) === -1) {
          // @ts-ignore
          newSelected.push(row);
        }
        return true;
      });
      setSelectedFiles(newSelected);
      setSelectedSections((prevState) => prevState.filter((row) => row !== key));
    }
  };
  const goodDate = (item) => item.investigationDate ?? item.createdAt;

  return (
    <div className="choose-documents">
      <div className="subtitle">{t('chooseFiles')}</div>
      {!medicalRecords.length && <div className="empty-container">{t('noMedicalRecords')}</div>}

      <Accordion className="medical-records-accordion" alwaysOpen>
        {medicalRecords.map((section, index) => (
          <Accordion.Item eventKey={index} key={_.uniqueId()}>
            <Accordion.Header>
              <div>{Utils.capitalizeFirstLetter(section.key)}</div>
              {section.data.length && <div className="counter-badge">{section.data.length}</div>}
            </Accordion.Header>
            <Accordion.Body>
              <div className={section.key} key={section.key}>
                <div className="documents-box">
                  <div className="header">
                    <div className="custom-control custom-checkbox orange-checkbox align-center-checkbox select-all right-checkbox active-label-orange">
                      <input
                        checked={
                          selectedSections.indexOf(section.key as never) > -1 ||
                          isAllSectionSelected(section.key)
                        }
                        className="custom-control-input"
                        id={`${section.key}`}
                        type="checkbox"
                        onChange={() => toggleSelectAll(section.key)}
                      />
                      <label htmlFor={`${section.key}`} className="custom-control-label">
                        <div>{t('all')}</div>
                      </label>
                    </div>
                  </div>
                  {section.data.map((item, eq) => (
                    <div
                      key={item?.id}
                      className="custom-control custom-checkbox orange-checkbox align-center-checkbox documents-checkbox right-checkbox active-label-orange"
                    >
                      <input
                        className="custom-control-input"
                        id={`${section.key}${eq}`}
                        type="checkbox"
                        onChange={() => setSelection(item?.id)}
                        checked={isSelected(item?.id) || isShared(item?.id)}
                      />
                      <label htmlFor={`${section.key}${eq}`} className="custom-control-label">
                        <CustomIcon
                          className="custom-icon"
                          color={Colors.darkGrey}
                          size="24"
                          icon="Document"
                        />
                        <div className="info">
                          <div className="title">
                            {Utils.shortenText(Utils.capitalizeFirstLetter(item.title), 25)}
                          </div>
                          <div className="details">
                            {dayjs(goodDate(item)).format('DD MMM YYYY')}
                          </div>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export default SelectDocumentComponent;
