import { AnyAction } from 'redux';
import { FETCH_SETTINGS_SUCCESS } from '../types/SettingsTypes';

const SettingsReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case FETCH_SETTINGS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default SettingsReducer;
