import { actionChannel, call, fork, put, take } from 'redux-saga/effects';
import NavigationService from 'shared/services/NavigationService';
import { FETCH_DOCTOR_REVIEWS_REQUEST } from 'shared/redux/types/DoctorReviewsTypes';
import { getDoctorReviewsAPI } from 'shared/redux/api/DoctorReviewsApi';
import {
  fetchDoctorReviewsError,
  fetchDoctorReviewsSuccess,
} from 'shared/redux/actions/DoctorReviewsActions';
import doctorReviewsSelector from 'shared/redux/selector/DoctorReviewsSelector';

function* getDoctorReviews(actionType) {
  const doctorReviewsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(doctorReviewsChannel);
    try {
      const safeAction = payload || { limit: 5, page: 1, id: 0, name: '' };
      const page = safeAction.page || 1;
      const limit = safeAction.limit || 5;
      const id = safeAction?.id || 0;
      const name = safeAction.name || '';
      const response = yield call(getDoctorReviewsAPI, { id, page, limit });
      yield put(
        fetchDoctorReviewsSuccess({
          ...doctorReviewsSelector(response.data),
          nextPage: payload.nextPage,
        }),
      );
    } catch ({ message }) {
      yield put(fetchDoctorReviewsError({ message }));
    }
  }
}

function* doctorReviewsSaga() {
  yield fork(getDoctorReviews, FETCH_DOCTOR_REVIEWS_REQUEST);
}

export default doctorReviewsSaga;
