import 'shared/styles/static-pages.css';
import { shallowEqual, useSelector } from 'react-redux';
import { getTwilioTermsState } from 'shared/redux/src/StatesGetter';
import React, { useEffect, useRef } from 'react';

const SettingsTwilioTermsConditions = () => {
  const devRef = useRef<HTMLDivElement>(null);
  const { name, content } = useSelector(getTwilioTermsState, shallowEqual);
  useEffect(() => {
    if (devRef.current) devRef.current.innerHTML = content;
  }, [content]);
  return (
    <section className="static-pages">
      <div className="title-section">
        {/* <Link to="/doctor/account" className="back-to-account"> */}
        {/*  <CustomIcon className="custom-icon" color={Colors.darkGrey} size="24" icon="Left_arrow" /> */}
        {/*  Înapoi la Cont */}
        {/* </Link> */}
        <h1>{name}</h1>
      </div>

      <div ref={devRef} />
    </section>
  );
};

export default SettingsTwilioTermsConditions;
