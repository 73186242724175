import { AnyAction } from 'redux';
import { FETCH_ALL_REVIEWS_SUCCESS } from 'shared/redux/types/AllReviewsTypes';

const AllReviewsReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case FETCH_ALL_REVIEWS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default AllReviewsReducer;
