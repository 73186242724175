import { AnyAction } from 'redux';
import {
  TWILIO_TOKEN_REQUEST,
  TWILIO_TOKEN_SUCCESS,
  TWILIO_TOKEN_ERROR,
  GET_ROOM_PARTICIPANT_ERROR,
  GET_ROOM_PARTICIPANT_SUCCESS,
  GET_ROOM_PARTICIPANT_REQUEST,
} from 'shared/redux/types/TwilioTypes';

export const getTwilioTokenRequest = (payload: any): AnyAction => ({
  type: TWILIO_TOKEN_REQUEST,
  payload,
});

export const getTwilioTokenSuccess = (payload: any): AnyAction => ({
  type: TWILIO_TOKEN_SUCCESS,
  payload,
});

export const getTwilioTokenError = (payload: any): AnyAction => ({
  type: TWILIO_TOKEN_ERROR,
  payload,
});
