const makeCardUniqueId = (last4, expMonth, expYear) => {
  const shortYear =
    expYear.length === 2
      ? expYear
      : `${expYear?.charAt(expYear.length - 2)}${expYear?.charAt(expYear.length - 1)}`;

  return `${last4}A${expMonth}A${shortYear}`;
};

const paymentMethodsSelector = (rawPaymentMethod) => {
  const { data: paymentMethods } = rawPaymentMethod;
  const cards: Record<string, unknown>[] = [];
  const all: Record<string, unknown>[] = [];
  const uniqueIds: string[] = [];
  if (Array.isArray(paymentMethods.data)) {
    paymentMethods.data.map((row) => {
      if (!row.card) {
        return true;
      }
      const uniqueId = makeCardUniqueId(row.card.last4, row.card.exp_month, `${row.card.exp_year}`);
      const check = uniqueIds.indexOf(uniqueId);
      const cardPayload = {
        id: row?.id,
        brand: row.card.brand,
        last4: row.card.last4,
        expiryYear: row.card.exp_year,
        expiryMonth: row.card.exp_month,
        uniqueId,
      };
      all.push(cardPayload);
      if (check === -1) {
        cards.push(cardPayload);
        uniqueIds.push(uniqueId);
      }
      return true;
    });
  }
  return { cards, uniqueIds, all };
};

const mergePaymentMethodSelector = (paymentMethods, newPaymentMethod) => {
  const uniqueId = makeCardUniqueId(
    newPaymentMethod.card.last4,
    newPaymentMethod.card.exp_month,
    `${newPaymentMethod.card.exp_year}`,
  );
  if (paymentMethods.uniqueIds.indexOf(uniqueId) > -1) {
    return paymentMethods;
  }
  return {
    cards: [
      {
        id: newPaymentMethod?.id,
        brand: newPaymentMethod.card.brand,
        last4: newPaymentMethod.card.last4,
        expiryYear: newPaymentMethod.card.exp_year,
        expiryMonth: newPaymentMethod.card.exp_month,
        uniqueId,
      },
      ...paymentMethods.cards,
    ],
    all: [
      {
        id: newPaymentMethod?.id,
        brand: newPaymentMethod.card.brand,
        last4: newPaymentMethod.card.last4,
        expiryYear: newPaymentMethod.card.exp_year,
        expiryMonth: newPaymentMethod.card.exp_month,
        uniqueId,
      },
      ...paymentMethods.all,
    ],
    uniqueIds: [...paymentMethods.uniqueIds, uniqueId],
  };
};
export { paymentMethodsSelector, mergePaymentMethodSelector, makeCardUniqueId };
