export const GET_FAVOURITE_DOCTORS_REQUEST = 'GET_FAVOURITE_DOCTORS_REQUEST';
export const GET_FAVOURITE_DOCTORS_SUCCESS = 'GET_FAVOURITE_DOCTORS_SUCCESS';
export const GET_FAVOURITE_DOCTORS_ERROR = 'GET_FAVOURITE_DOCTORS_ERROR';

export const ADD_FAVOURITE_DOCTOR_REQUEST = 'ADD_FAVOURITE_DOCTOR_REQUEST';
export const ADD_FAVOURITE_DOCTOR_SUCCESS = 'ADD_FAVOURITE_DOCTOR_SUCCESS';
export const ADD_FAVOURITE_DOCTOR_ERROR = 'ADD_FAVOURITE_DOCTOR_ERROR';

export const REMOVE_FAVOURITE_DOCTOR_REQUEST = 'REMOVE_FAVOURITE_DOCTOR_REQUEST';
export const REMOVE_FAVOURITE_DOCTOR_SUCCESS = 'REMOVE_FAVOURITE_DOCTOR_SUCCESS';
export const REMOVE_FAVOURITE_DOCTOR_ERROR = 'REMOVE_FAVOURITE_DOCTOR_ERROR';
