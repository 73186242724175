import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import Medication from '../appointments/Medication';
import Avatar from '../../../shared/components/Avatar';
import {
  getDoctorAppointmentDetailsState,
  getUserProfileState,
} from '../../../shared/redux/src/StatesGetter';
import Utils from '../../../shared/modules/Utils';
import CustomIcon from '../../../shared/components/CustomIcon';
import Colors from '../../../shared/themes/Colors';
import { getAppointmentDetailsRequest } from '../../../shared/redux/actions/DoctorAppointmentsActions';

const DoctorConsultationDetailsTabRecommendationMedication = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const doctor = useSelector(getUserProfileState, shallowEqual);
  const { prescription } = useSelector(getDoctorAppointmentDetailsState, shallowEqual);
  const {
    patient,
    user,
    appointmentType = { duration: 0, type: '' },
    medicalRecords,
    conclusion,
    serverStatus,
    id: appointmentId,
    specializationName,
  } = useSelector(getDoctorAppointmentDetailsState, shallowEqual);
  const { isResident = false } = useSelector(getUserProfileState, shallowEqual);

  const { firstName = '', lastName = '', picture, birthDate } = patient ?? user ?? {};

  const [addNewMedication, setAddNewMedication] = useState(false);
  const [currentMedication, setCurrentMedication] = useState({
    id: '',
    name: '',
    concentration: '',
    administration: '',
    quantity: '',
    duration: '',
    type: '',
  });

  const renderSubTitle = () => {
    if (Utils.calculateAge(birthDate) > 0) {
      if (
        medicalRecords?.length &&
        ['call_ended', 'completed'].indexOf(serverStatus) > -1 &&
        !isResident
      ) {
        return (
          <div className="age">
            {Utils.calculateAge(birthDate) > 0 && `${Utils.calculateAge(birthDate)} ani • `}
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="15"
              icon="Document"
            />
            {t('attachedFiles')}
          </div>
        );
      }
      return <div className="age">{`${Utils.calculateAge(birthDate)} ani`}</div>;
    }
    return null;
  };
  const renderTabNav = () => {
    return (
      <Nav variant="tabs">
        {/* <Nav.Item> */}
        {/*  <Nav.Link eventKey="info">{t('info')}</Nav.Link> */}
        {/* </Nav.Item> */}
        <Nav.Item>
          <Nav.Link eventKey="profile">{t('profile')}</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="records">{t('records')}</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="recommendation">{t('recommendation')}</Nav.Link>
        </Nav.Item>
      </Nav>
    );
  };

  const conclusionAddMedication = () => {
    dispatch(getAppointmentDetailsRequest({ id: appointmentId }));
    setTimeout(() => {
      navigate(`/doctor/consultation-details/${appointmentId}/prescription?t=r`, {
        state: {
          templateType: 'past',
        },
      });
    }, 1000);
  };

  useEffect(() => {
    const localMedication = prescription.medications.filter((medication) => {
      // @ts-ignore
      return medication.id === parseInt(params.medicationId, 10);
    });
    setCurrentMedication(localMedication[0]);
  }, []);

  return (
    <section className="consultation-details">
      <div className="title-section">
        <h1>{t('history.details')}</h1>
      </div>
      <div className="small-container">
        <div className="patient-box">
          <Avatar size={40} imageURL={picture} firstName={firstName} lastName={lastName} />
          <div className="info">
            <div className="fullName">{`${firstName} ${lastName}`}</div>
            <div className="requested-specialization">{specializationName}</div>
            {renderSubTitle()}
          </div>
        </div>
        {renderTabNav()}
        <Medication
          setAddNewMedication={setAddNewMedication}
          prescription={prescription}
          appointmentId={appointmentId}
          conclusionAddMedication={conclusionAddMedication}
          currentMedication={currentMedication}
          setCurrentMedication={setCurrentMedication}
        />
      </div>
    </section>
  );
};

export default DoctorConsultationDetailsTabRecommendationMedication;
