import { actionChannel, call, fork, put, take, select } from 'redux-saga/effects';
import i18next from 'i18next';
import {
  associateSpecializationAPI,
  fetchTitlesAPI,
  getMySpecializationsAPI,
  getUserProfileAPI,
  setAvailabilityAPI,
  updateDoctorCvAPI,
  updateDoctorOrganizationAPI,
  updateUserProfileAPI,
} from 'shared/redux/api/UserProfileApi';
import {
  fetchUserProfileSuccess,
  fetchUserProfileError,
  patchUserProfileSuccess,
  patchUserProfileError,
  patchDoctorCvSuccess,
  patchDoctorCvError,
  patchDoctorOrganizationError,
  patchDoctorOrganizationSuccess,
  associateSpecializationError,
  associateSpecializationSuccess,
  setAvailabilitySuccess,
  setAvailabilityError,
  fetchUserProfileRequest,
  fetchMySpecializationsSuccess,
  fetchMySpecializationsError,
  fetchMySpecializationsRequest,
  fetchTitlesSuccess,
  fetchTitlesError,
  locallyRemoveSpecialization,
} from 'shared/redux/actions/UserProfileActions';
import {
  ASSOCIATE_SPECIALIZATION_REQUEST,
  FETCH_TITLES_REQUEST,
  FETCH_USER_PROFILE_REQUEST,
  GET_MY_SPECIALIZATIONS_REQUEST,
  PATCH_DOCTOR_CV_REQUEST,
  PATCH_DOCTOR_ORGANIZATION_REQUEST,
  PATCH_USER_PROFILE_REQUEST,
  SET_AVAILABILITY_REQUEST,
} from 'shared/redux/types/UserProfileTypes';
import {
  mySpecializationsSelector,
  titlesSelector,
  userProfileSelector,
} from 'shared/redux/selector/UserProfileSelector';
import GCStorageService from 'shared/services/GCStorageService';
import { getAuthState } from 'shared/redux/src/StatesGetter';
import ApiUtils from 'shared/modules/ApiUtils';
import { updateSharedStateAction } from 'shared/redux/actions/UngroupedActions';
import Alerts from 'shared/components/Alerts';
import NavigationService from 'shared/services/NavigationService';
import { handleExtraLoginResponse } from 'shared/modules/AuthUtils';
import { fetchBalanceRequest } from '../actions/TransactionActions';
import StorageService from '../../services/StorageService';

function* getUserProfile(actionType) {
  const userProfileChannel = yield actionChannel(actionType);
  while (true) {
    yield take(userProfileChannel);
    try {
      const response = yield call(getUserProfileAPI);
      yield put(fetchUserProfileSuccess(userProfileSelector(response.data)));
      if (response.data.is_doctor) {
        yield put(fetchBalanceRequest({}));
      }
    } catch ({ message }) {
      yield put(fetchUserProfileError({ message }));
    }
  }
}

function* updateDoctorUserProfile(actionType) {
  const updateDoctorUserProfileChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(updateDoctorUserProfileChannel);
    try {
      // Update Doctor CV
      const response = yield call(updateUserProfileAPI, payload.toUpdate);
      if (
        payload.toUpdate.picture &&
        response?.data?.picture_media &&
        response.data.picture_media?.gcloud_upload_policy_v4?.url &&
        payload.avatarFile
      ) {
        yield call(
          GCStorageService.uploadToGCWithSignedUrl,
          response.data.picture_media.gcloud_upload_policy_v4.url,
          response.data.picture_media.gcloud_upload_policy_v4.fields,
          payload.avatarFile,
        );
      }

      // AditionalDocument
      if (
        payload?.additionalDocument?.uri &&
        response?.data?.additional_document_media &&
        response.data.additional_document_media?.gcloud_upload_policy_v4?.url
      ) {
        yield call(
          GCStorageService.uploadToGCWithSignedUrl,
          response.data.additional_document_media.gcloud_upload_policy_v4.url,
          response.data.additional_document_media.gcloud_upload_policy_v4.fields,
          payload.additionalDocument,
        );
      }

      // IdCard
      if (
        payload?.idCard?.uri &&
        response?.data?.identity_card_media &&
        response.data?.identity_card_media?.gcloud_upload_policy_v4?.url
      ) {
        yield call(
          GCStorageService.uploadToGCWithSignedUrl,
          response.data?.identity_card_media.gcloud_upload_policy_v4.url,
          response.data?.identity_card_media.gcloud_upload_policy_v4.fields,
          payload?.idCard,
        );
      }

      // DoctorSeal
      if (
        payload?.doctorSeal?.uri &&
        response.data?.doctor_seal_media &&
        response.data?.doctor_seal_media.gcloud_upload_policy_v4.url
      ) {
        yield call(
          GCStorageService.uploadToGCWithSignedUrl,
          response.data?.doctor_seal_media.gcloud_upload_policy_v4.url,
          response.data?.doctor_seal_media.gcloud_upload_policy_v4.fields,
          payload?.doctorSeal,
        );
      }

      yield put(patchUserProfileSuccess(userProfileSelector(response.data)));
      const { isProfileCompleted } = yield select(getAuthState);
      if (isProfileCompleted === false) {
        yield call(ApiUtils.doRefreshToken);
      }
      const isProfiled = response.data?.is_doctor;
      yield call(handleExtraLoginResponse, isProfileCompleted ? 'true' : 'Not Completed', 'true');
      yield put(
        updateSharedStateAction({
          isLoggedIn: isProfiled,
          isConfirmed: true,
          isProfileCompleted: payload.lastStep || isProfileCompleted,
          lastUpdate: isProfiled ? 'justNow' : '',
        }),
      );
      if (payload.withNavigation && !isProfileCompleted) {
        Alerts.actionAlert(i18next.t('success'), i18next.t('profileSaved'), i18next.t('ok'), () => {
          NavigationService.navigate('/doctor/register-step-invoice');
        });
      } else if (payload.lastStep && !isProfileCompleted) {
        Alerts.actionAlert(
          i18next.t('success'),
          i18next.t('alerts.profileChanged'),
          i18next.t('ok'),
          () => {
            NavigationService.navigate('/doctor/home');
          },
        );
      } else {
        Alerts.actionAlert(
          i18next.t('success'),
          i18next.t('alerts.profileChanged'),
          i18next.t('ok'),
          () => {},
        );
      }
    } catch ({ message }) {
      yield put(patchUserProfileError({ message }));
    }
  }
}

function* updateDoctorOrganization(actionType) {
  const updateDoctorOrganizationChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(updateDoctorOrganizationChannel);
    try {
      const response = yield call(updateDoctorOrganizationAPI, payload.data);
      yield put(patchDoctorOrganizationSuccess(response.data));
      Alerts.actionAlert(`${i18next.t('success')}`, `${i18next.t('profileSaved')}`, 'Ok', () => {
        if (payload.withNavigation && payload.type === 'unprofiled') {
          NavigationService.navigate('/doctor/register-step-specialization');
        } else {
          NavigationService.navigate('/doctor/account-profile/personal');
        }
      });
    } catch ({ message }) {
      yield put(patchDoctorOrganizationError({ message }));
    }
  }
}

function* updateDoctorCV(actionType) {
  const updateDoctorCvChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(updateDoctorCvChannel);
    try {
      const response = yield call(updateDoctorCvAPI, payload.toUpdate);
      if (response?.data?.cv_media) {
        yield call(
          GCStorageService.uploadToGCWithSignedUrl,
          response.data.cv_media.gcloud_upload_policy_v4.url,
          response.data.cv_media.gcloud_upload_policy_v4.fields,
          payload.cvFile,
        );
      }
      yield put(patchDoctorCvSuccess(response.data));
      const { isProfileCompleted } = yield select(getAuthState);
      if (isProfileCompleted === false) {
        yield call(ApiUtils.doRefreshToken);
      }
      const isProfiled = response.data?.is_doctor;
      yield call(handleExtraLoginResponse, isProfileCompleted ? 'true' : 'Not Completed', 'true');
      yield put(
        updateSharedStateAction({
          isLoggedIn: isProfiled,
          isConfirmed: true,
          isProfileCompleted,
        }),
      );
      Alerts.simpleAlert(`${i18next.t('success')}`, `${i18next.t('profileSaved')}`);
      yield put(fetchUserProfileRequest({}));
    } catch ({ message }) {
      yield put(patchDoctorCvError({ message }));
    }
  }
}

function* associateSpecialization(actionType) {
  const associateSpecializationChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(associateSpecializationChannel);
    try {
      const response = yield call(associateSpecializationAPI, payload.toAdd);
      if (response?.data[0]?.gcloud_upload_policy_v4) {
        yield call(
          GCStorageService.uploadToGCWithSignedUrl,
          response.data[0].gcloud_upload_policy_v4.url,
          response.data[0].gcloud_upload_policy_v4.fields,
          payload.proofFile,
        );
      }
      yield put(associateSpecializationSuccess({}));
      yield put(locallyRemoveSpecialization({}));
      yield put(fetchMySpecializationsRequest({}));
      Alerts.actionAlert(
        `${i18next.t('success')}`,
        payload.destination === 'userProfile'
          ? `${i18next.t('specializationSaved')}`
          : `${i18next.t('modifySaved')}`,
        'Ok',
        () => {
          window.location.href = '/doctor/account-profile/specialization';
          // NavigationService.navigate('/doctor/account-profile/specialization');
        },
      );
      if (payload.destination === 'unprofiled') {
        NavigationService.navigate('/doctor/register-step-specialization');
      } else if (payload.destination === 'userProfile') {
        NavigationService.navigate('/doctor/account-profile/personal');
      } else {
        NavigationService.navigate('/doctor/rates');
      }
    } catch ({ message }) {
      yield put(associateSpecializationError({ message }));
    }
  }
}

function* setAvailability(actionType) {
  const setAvailabilityChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(setAvailabilityChannel);
    try {
      const response = yield call(setAvailabilityAPI, payload);
      yield put(setAvailabilitySuccess(response.data));
      yield put(fetchUserProfileRequest({}));
    } catch ({ message }) {
      yield put(setAvailabilityError({ message }));
    }
  }
}

function* getMySpecializations(actionType) {
  const mySpecializationsChannel = yield actionChannel(actionType);
  let isPharmacist = 'false';
  StorageService.getData('IS_PHARMACIST', 'false').then((response) => {
    isPharmacist = response;
  });
  while (true) {
    yield take(mySpecializationsChannel);
    try {
      let response = { data: [] };
      if (isPharmacist === 'false') {
        response = yield call(getMySpecializationsAPI);
      }
      yield put(fetchMySpecializationsSuccess(mySpecializationsSelector(response.data)));
    } catch ({ message }) {
      yield put(fetchMySpecializationsError({ message }));
    }
  }
}

function* getTitles(actionType) {
  const titlesChannel = yield actionChannel(actionType);
  while (true) {
    yield take(titlesChannel);
    try {
      const response = yield call(fetchTitlesAPI);
      yield put(fetchTitlesSuccess(titlesSelector(response.data)));
    } catch ({ message }) {
      yield put(fetchTitlesError({ message }));
    }
  }
}

function* userProfileSaga() {
  yield fork(getUserProfile, FETCH_USER_PROFILE_REQUEST);
  yield fork(updateDoctorUserProfile, PATCH_USER_PROFILE_REQUEST);
  yield fork(updateDoctorOrganization, PATCH_DOCTOR_ORGANIZATION_REQUEST);
  yield fork(updateDoctorCV, PATCH_DOCTOR_CV_REQUEST);
  yield fork(associateSpecialization, ASSOCIATE_SPECIALIZATION_REQUEST);
  yield fork(setAvailability, SET_AVAILABILITY_REQUEST);
  yield fork(getMySpecializations, GET_MY_SPECIALIZATIONS_REQUEST);
  yield fork(getTitles, FETCH_TITLES_REQUEST);
}

export default userProfileSaga;
