import { actionChannel, ActionPattern, call, fork, put, take } from 'redux-saga/effects';
import { Action } from 'redux';
import {
  FETCH_NOTIFICATIONS_SETTINGS_REQUEST,
  FETCH_PUSH_DETAILS_REQUEST,
  FETCH_PUSHES_REQUEST,
  SILENTLY_FETCH_PUSHES_REQUEST,
  UPDATE_NOTIFICATIONS_SETTINGS_REQUEST,
  UPDATE_PUSHES_REQUEST,
} from 'shared/redux/types/NotificationTypes';
import {
  getNotificationsSettingsAPI,
  getPushDetailsAPI,
  getPushesAPI,
  updateAccountSettingsAPI,
  updatePushesAPI,
} from 'shared/redux/api/NotificationApi';
import {
  fetchNotificationsSettingsError,
  fetchNotificationsSettingsSuccess,
  fetchPushDetailsError,
  fetchPushDetailsSuccess,
  fetchPushesError,
  fetchPushesSuccess,
  silentlyFetchPushesRequest,
  updateNotificationsSettingsError,
  updateNotificationsSettingsSuccess,
  updatePushesError,
  updatePushesSuccess,
} from 'shared/redux/actions/NotificationActions';
import {
  notificationsSettingsSelector,
  pushesSelector,
} from 'shared/redux/selector/NotificationSelector';
import { fetchUserProfileRequest } from 'shared/redux/actions/UserProfileActions';
import { fetchPatientProfileRequest } from 'shared/redux/actions/PatientProfileActions';
import LocalEventsService from 'shared/services/LocalEventsService';
import { getEnv } from '../../services/EnvService';
import StorageService from '../../services/StorageService';

function* getPushes(actionType: ActionPattern<Action<any>>) {
  const pushesChannel = yield actionChannel(actionType);
  let isPharmacist = 'false';
  StorageService.getData('IS_PHARMACIST', 'false').then((response) => {
    isPharmacist = response;
  });
  while (true) {
    const { payload } = yield take(pushesChannel);
    try {
      const safeAction = {
        limit: 100,
        page: 1,
        ...payload,
      };
      let response = { data: [] };
      if (isPharmacist === 'false') {
        response = yield call(getPushesAPI, safeAction);
      }
      yield put(
        fetchPushesSuccess({
          ...pushesSelector(response.data),
          nextPage: payload.nextPage,
        }),
      );
    } catch ({ message }) {
      yield put(fetchPushesError({ message }));
    }
  }
}

function* getPushDetails(actionType) {
  const pushDetailsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(pushDetailsChannel);
    try {
      const response = yield call(getPushDetailsAPI, payload.id);
      yield put(fetchPushDetailsSuccess({}));
      LocalEventsService.emit('pushNotificationClicked', response.data);
    } catch ({ message }) {
      yield put(fetchPushDetailsError({ message }));
    }
  }
}

function* updatePushes(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const updatePushesChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(updatePushesChannel);
    try {
      yield call(updatePushesAPI, payload.forRequest);
      yield put(updatePushesSuccess(payload.forUpdate));
      if (!payload.forUpdate) {
        yield put(silentlyFetchPushesRequest({}));
      }
    } catch ({ message }) {
      yield put(updatePushesError({ message }));
    }
  }
}

function* getNotificationsSettings(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const notificationsSettingsChannel = yield actionChannel(actionType);
  while (true) {
    yield take(notificationsSettingsChannel);
    try {
      // @ts-ignore
      const response = yield call(getNotificationsSettingsAPI);
      yield put(fetchNotificationsSettingsSuccess(notificationsSettingsSelector(response.data)));
    } catch ({ message }) {
      yield put(fetchNotificationsSettingsError({ message }));
    }
  }
}

function* setNotificationsSettings(actionType) {
  const setNotificationsSettingsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(setNotificationsSettingsChannel);
    try {
      const response = yield call(updateAccountSettingsAPI, payload.forUpdate);
      yield put(updateNotificationsSettingsSuccess(response.data));

      if (payload.refreshPatient) {
        yield put(fetchPatientProfileRequest({}));
      }

      if (payload.refreshDoctor) {
        yield put(fetchUserProfileRequest({}));
      }
    } catch ({ message }) {
      yield put(updateNotificationsSettingsError({ message }));
    }
  }
}

function* pushesSaga() {
  yield fork(getPushes, FETCH_PUSHES_REQUEST);
  yield fork(getPushes, SILENTLY_FETCH_PUSHES_REQUEST);
  yield fork(getPushDetails, FETCH_PUSH_DETAILS_REQUEST);
  yield fork(updatePushes, UPDATE_PUSHES_REQUEST);
  yield fork(getNotificationsSettings, FETCH_NOTIFICATIONS_SETTINGS_REQUEST);
  yield fork(setNotificationsSettings, UPDATE_NOTIFICATIONS_SETTINGS_REQUEST);
}

export default pushesSaga;
