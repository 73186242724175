import { getEnv } from 'shared/services/EnvService';
import i18next from 'i18next';

const specializationSelector = (specializations) => {
  const resolveMedia = (media) => {
    return {
      id: media?.id,
      isAvailable: media?.status === 'available',
      mediaFileName: media?.original_file_name,
      url: media?.public_url,
      mediaType: media?.server_mime_type,
    };
  };

  // eslint-disable-next-line no-underscore-dangle
  return specializations._embedded?.items
    .filter((row) => row.is_active)
    .map((specialization) => {
      return {
        id: specialization?.id,
        name:
          specialization.translations.find((row) => row.locale === i18next.language)?.name ?? '',
        title:
          specialization.translations
            .find((row) => row.locale === i18next.language)
            ?.title?.toLowerCase() ?? '',
        description:
          specialization.translations.find((row) => row.locale === i18next.language)?.description ??
          '',
        slug:
          specialization.translations.find((row) => row.locale === i18next.language)?.slug ?? '',
        metaTitle:
          specialization.translations.find((row) => row.locale === i18next.language)?.meta_title ??
          '',
        metaDescription:
          specialization.translations.find((row) => row.locale === i18next.language)
            ?.meta_description ?? '',
        seoTitle:
          specialization.translations.find((row) => row.locale === i18next.language)?.seo_title ??
          '',
        callSpecialistDuration: specialization.call_specialist_duration,
        callSpecialistPrice: specialization.call_specialist_price,
        callSpecialistPriceAsString: specialization?.call_specialist_price_as_string,
        media: resolveMedia(specialization?.icon_media),
      };
    });
};

const specializationHomeSelector = (specializations) => {
  // eslint-disable-next-line no-underscore-dangle
  return specializations
    .filter((row) => row.is_active)
    .map((specialization) => {
      return {
        id: specialization?.id,
        name:
          specialization.translations.find((row) => row.locale === i18next.language)?.name ?? '',
        slug:
          specialization.translations.find((row) => row.locale === i18next.language)?.slug ?? '',
        imgUrl: specialization?.icon_media?.public_url ?? '',
      };
    });
};

export { specializationSelector, specializationHomeSelector };
