import 'doctor/styles/account-availability-schedule.css';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';
import { getDoctorRecurrenceTimetableState } from 'shared/redux/src/StatesGetter';
import {
  fetchMergedDoctorTimetableRequest,
  locallyAddDate,
  removeDoctorExceptionTimetableRequest,
  removeDoctorRecurrentTimetableRequest,
} from 'shared/redux/actions/DoctorTimetableActions';
import Alerts from 'shared/components/Alerts';
import { translatedDate, translatedDayOfWeek } from 'shared/modules/DateTimeUtils';
import { useTranslation } from 'react-i18next';
import AddExceptionModal from 'doctor/components/modals/AddException';
import AddAvailabilityModal from 'doctor/components/modals/AddAvailability';
import dayjs from 'dayjs';

const DoctorAccountAvailabilityScheduleBlock = () => {
  const { t } = useTranslation();
  const [currentAddAvailabilityModal, setCurrentAddAvailabilityModal] = useState({
    type: '',
    data: {},
  });
  const [currentAddExceptionModal, setCurrentAddExceptionModal] = useState({ type: '', data: {} });
  const [showModalAddException, setModalAddExceptionShow] = useState(false);
  const [showModalAddInterval, setModalAddIntervalShow] = useState(false);
  const mergedTimetableData = useSelector(getDoctorRecurrenceTimetableState, shallowEqual);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMergedDoctorTimetableRequest({}));
  }, [mergedTimetableData.length]);
  const deleteInterval = (interval) => {
    Alerts.okCancelAlert(
      `${t('warning')}`,
      `${t('validations.deleteRecord')}`,
      () => {
        if (interval?.entryType === 'timetable_recurrence') {
          dispatch(removeDoctorRecurrentTimetableRequest({ id: interval?.id }));
        } else {
          dispatch(removeDoctorExceptionTimetableRequest({ id: interval?.id }));
        }
      },
      () => {},
    );
  };
  const goToAddAvailability = ({ type, data }) => {
    setCurrentAddAvailabilityModal({ type, data });
    setModalAddIntervalShow(true);
  };
  const goToAddException = ({ type, data }) => {
    setCurrentAddExceptionModal({ type, data });
    setModalAddExceptionShow(true);
  };
  const editInterval = (interval) => {
    if (interval?.entryType === 'timetable_recurrence') {
      setCurrentAddAvailabilityModal({
        type: 'edit',
        data: {
          id: interval?.id,
          startTime: interval?.timeStart,
          endTime: interval?.timeStop,
          dayOfWeek: interval?.dayOfWeek,
        },
      });
      setModalAddIntervalShow(true);
    } else {
      dispatch(locallyAddDate({ date: interval?.date }));
      setCurrentAddExceptionModal({
        type: 'edit',
        data: {
          id: interval?.id,
          startTime: interval?.timeStart,
          endTime: interval?.timeStop,
          dayOfWeek: interval?.dayOfWeek,
          date: interval?.date,
          type: interval?.type,
        },
      });
      setModalAddExceptionShow(true);
    }
  };
  return (
    <div className="account-availability-schedule">
      <div className="title-section">
        <h2>{t('mainScreenRoutes.availability')}</h2>
      </div>
      <button
        type="button"
        className="white-button add-exceptions-btn"
        onClick={() => {
          goToAddException({
            type: 'add',
            data: { id: 0, date: dayjs().format('YYYY-MM-DD HH:mm:ss') },
          });
        }}
      >
        <CustomIcon className="custom-icon" color={Colors.darkGrey} size="24" icon="Plus" />
        {t('addException')}
      </button>
      {!!mergedTimetableData.length &&
        mergedTimetableData
          // ?.filter((row) => row?.length)
          ?.map((row, eq) => {
            // @ts-ignore
            return (
              <div className="day-box" key={`${eq + 1}_${row.toString()}`}>
                <div className="day-title">
                  <div className="custom-control custom-radio green-radio">
                    <input
                      className="custom-control-input"
                      onChange={() => {}}
                      id={`checkbox_${eq}`}
                      type="checkbox"
                      checked={!!(mergedTimetableData && row.length)}
                    />
                    <label htmlFor={`checkbox_${eq}`} className="custom-control-label">
                      {translatedDayOfWeek(eq)}
                    </label>
                  </div>
                </div>
                {row.length
                  ? row.map((interval) => {
                      return (
                        <div
                          className="interval-box"
                          key={`interval_${interval?.date}_${interval?.entryType}_${interval?.id}`}
                        >
                          <div className="info">
                            {interval?.entryType !== 'timetable_recurrence' && (
                              <div className="exception">
                                {t('exceptionOn')} {translatedDate(interval?.date)}
                              </div>
                            )}
                            {interval.type === 'day_off' ? (
                              <div className="interval">{t('dayOff')}</div>
                            ) : (
                              <div className="interval">{`${interval.timeStart} - ${interval.timeStop}`}</div>
                            )}
                          </div>
                          <div className="actions">
                            <button type="button" onClick={() => deleteInterval(interval)}>
                              <CustomIcon
                                className="custom-icon"
                                color={Colors.mediumGrey}
                                size="24"
                                icon="Delete"
                              />
                            </button>
                            <button type="button" onClick={() => editInterval(interval)}>
                              <CustomIcon
                                className="custom-icon"
                                color={Colors.mediumGrey}
                                size="24"
                                icon="Edit"
                              />
                            </button>
                          </div>
                        </div>
                      );
                    })
                  : null}
                <button
                  type="button"
                  className="white-button add-interval-btn"
                  onClick={() => {
                    goToAddAvailability({ type: 'add', data: { id: 0, dayOfWeek: eq } });
                  }}
                >
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.medicColor}
                    size="24"
                    icon="Plus"
                  />
                  {t('chooseInterval')}
                </button>
              </div>
            );
          })}
      <AddExceptionModal
        setModalAddExceptionShow={setModalAddExceptionShow}
        setCurrentAddExceptionModal={setCurrentAddExceptionModal}
        showModalAddException={showModalAddException}
        currentAddExceptionModal={currentAddExceptionModal}
      />
      <AddAvailabilityModal
        setModalAddIntervalShow={setModalAddIntervalShow}
        setCurrentAddAvailabilityModal={setCurrentAddAvailabilityModal}
        showModalAddInterval={showModalAddInterval}
        currentAddAvailabilityModal={currentAddAvailabilityModal}
      />
    </div>
  );
};

export default DoctorAccountAvailabilityScheduleBlock;
