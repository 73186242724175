import 'doctor/styles/video-call.css';
import Tab from 'react-bootstrap/Tab';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getDoctorAppointmentDetailsState,
  getDoctorAppointmentState,
  getUserProfileState,
  getVideoCallGetTokenState,
} from 'shared/redux/src/StatesGetter';
import Alerts from 'shared/components/Alerts';
import { appointmentEndConsultationRequest } from 'shared/redux/actions/AppointmentActions';
import TimerComponent from 'shared/components/TimerComponent';
import {
  doctorAppointmentUpdatesRequest,
  getAppointmentDetailsRequest,
} from 'shared/redux/actions/DoctorAppointmentsActions';
import { Nav } from 'react-bootstrap';
import DoctorVideoCallTabVideo from 'doctor/components/videoCall/DoctorVideoCallTabVideo';
import Avatar from 'shared/components/Avatar';
import DoctorConsultationDetailsTabProfile from 'doctor/components/consultationDetails/DoctorConsultationDetailsTabProfile';
import DoctorConsultationDetailsTabFolder from 'doctor/components/consultationDetails/DoctorConsultationDetailsTabFolder';
import AppointmentConclusion from 'doctor/screens/appointment/AppointmentConclusion';
import { useTranslation } from 'react-i18next';
import { usePageVisibility } from 'react-page-visibility';
import {
  useJoin,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  usePublish,
  useRemoteAudioTracks,
  useRemoteUsers,
  useRemoteVideoTracks,
  useRTCClient,
} from 'agora-rtc-react';
import { useAgoraContext } from '../../../shared/agora/AgoraManager';
import { videoCallGetTokenRequest } from '../../../shared/redux/actions/VideoCallActions';
import { appointmentLocalStepsRequest } from '../../../shared/redux/actions/PatientAppointmentActions';
import { getEnv } from '../../../shared/services/EnvService';
import NavigationService from '../../../shared/services/NavigationService';

const DoctorVideoCall = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isVisible = usePageVisibility();
  const {
    callStatus: appointmentCallStatus,
    endTime,
    status: appointmentStatus,
    timeEndWithPause,
    enableVideo = true,
    enableAudio = true,
    isExtendable,
    isInitialTime = true,
    showTimeBubble = false,
    isOtherUserConnected = false,
    otherVideoEnabled = true,
    otherAudioEnabled = true,
  } = useSelector(getDoctorAppointmentState);

  const doctor = useSelector(getUserProfileState, shallowEqual);

  const {
    patient = { firstName: '', lastName: '' },
    type: appointmentType,
    id: appointmentId,
    conclusion,
  } = useSelector(getDoctorAppointmentDetailsState, shallowEqual);

  const { firstName = '', lastName = '', picture = '' } = patient;

  const onTimeAboutToEnd = (val) => {
    if (val !== showTimeBubble) {
      dispatch(doctorAppointmentUpdatesRequest({ showTimeBubble: val }));
    }
  };

  const { serverStatus, id: serverAppointmentId } = useSelector(
    getDoctorAppointmentDetailsState,
    shallowEqual,
  );

  const [tabName, setTabName] = useState('video-tab-container');
  const activeTab = (e) => {
    if (e === 'video') {
      setTabName('video-tab-container');
    } else if (e === 'profile') {
      setTabName('profile-tab-container');
    } else if (e === 'records') {
      setTabName('records-tab-container');
    } else if (e === 'conclusions') {
      setTabName('conclusions-tab-container');
    }
  };

  const leaveRoom = () => {
    dispatch(
      appointmentLocalStepsRequest({
        status: 'needReview',
        callStatus: 'terminated',
        stickyStatus: 'ended',
        enableVideo: true,
        enableAudio: true,
        isInitialTime: true,
      }),
    );

    NavigationService.navigate(`/doctor/final-consultation/${appointmentId}/review-conclusions`);
    // window.location.href = `/doctor/final-consultation/${appointmentId}/review-conclusions`;
  };

  const timeUp = () => {
    if (!isExtendable) return;
    Alerts.twoActionsAlert(
      `${t('info')}`,
      `${t('validations.extendCall2')}`,
      `${t('continue')}`,
      () => {
        dispatch(
          doctorAppointmentUpdatesRequest({
            endTime: timeEndWithPause,
            isInitialTime: false,
            isExtendable: false,
          }),
        );
      },
      `${t('states.call_ended')}`,
      () => {
        dispatch(appointmentEndConsultationRequest({ appointmentId }));
        dispatch(getAppointmentDetailsRequest({ id: appointmentId }));
        dispatch(
          doctorAppointmentUpdatesRequest({
            status: 'needConclusion',
            callStatus: 'terminated',
            isExtendable: true,
            showNextAppointment: false,
          }),
        );
        leaveRoom();
      },
    );
  };

  //* ******* AGORA **************************************************************
  const videoCallInfo = useSelector(getVideoCallGetTokenState, shallowEqual);
  const { room: chanel, token, user, userId } = videoCallInfo;
  const { localMicrophoneTrack } = useLocalMicrophoneTrack();
  const { localCameraTrack } = useLocalCameraTrack();
  const remoteUsers = useRemoteUsers();
  const remoteUser = remoteUsers[0];
  useRemoteAudioTracks(remoteUsers);
  useRemoteVideoTracks(remoteUsers);

  usePublish([localMicrophoneTrack, localCameraTrack]);

  const remoteUserHasVideo = remoteUser?.hasVideo;
  const remoteUserHasAudio = remoteUser?.hasAudio;
  useEffect(() => {
    console.log(
      `%c remoteUser audio: ${remoteUser?.audioTrack}`,
      'color:#FF6A39; font-family:monospace; font-size: 15px;',
    );
    console.log(
      `%c remoteUser video: ${remoteUser?.videoTrack}`,
      'color:#FF6A39; font-family:monospace; font-size: 15px;',
    );
    console.log(
      `%c remoteUser audio: ${remoteUserHasAudio}`,
      'color:#FF6A39; font-family:monospace; font-size: 15px;',
    );
    console.log(
      `%c remoteUser video: ${remoteUserHasVideo}`,
      'color:#FF6A39; font-family:monospace; font-size: 15px;',
    );
    dispatch(doctorAppointmentUpdatesRequest({ otherAudioEnabled: remoteUserHasAudio }));
    dispatch(doctorAppointmentUpdatesRequest({ otherVideoEnabled: remoteUserHasVideo }));
    if (remoteUser === undefined) {
      dispatch(doctorAppointmentUpdatesRequest({ isOtherUserConnected: false }));
    } else {
      dispatch(doctorAppointmentUpdatesRequest({ isOtherUserConnected: true }));
    }
  }, [remoteUser, remoteUserHasVideo, remoteUserHasAudio]);

  // @ts-ignore
  const agoraAppId: string = getEnv('AGORA_APP_ID');
  useJoin({
    appid: agoraAppId,
    channel: chanel,
    token,
    uid: userId,
  });

  useEffect(() => {
    dispatch(videoCallGetTokenRequest({ appointmentId }));
  }, [appointmentId]);

  const rtcClient = useRTCClient();

  const handleMic = () => {
    dispatch(doctorAppointmentUpdatesRequest({ enableAudio: !enableAudio }));
  };

  const toggleVideo = () => {
    dispatch(doctorAppointmentUpdatesRequest({ enableVideo: !enableVideo }));
  };
  useEffect(() => {
    if (isVisible) {
      localCameraTrack?.setEnabled(true);
    } else {
      localCameraTrack?.setEnabled(false);
    }
  }, [isVisible, localCameraTrack]);

  useEffect(() => {
    if (localMicrophoneTrack !== null) {
      if (enableAudio) {
        localMicrophoneTrack?.setEnabled(true).then(() => {
          // @ts-ignore
          rtcClient.publish(localMicrophoneTrack).then(() => {
            console.log(
              '%c mic - publish success',
              'color:#FF6A39; font-family:monospace; font-size: 15px;',
            );
          });
        });
      } else {
        localMicrophoneTrack?.setEnabled(false);
      }
    }

    if (localCameraTrack !== null) {
      if (enableVideo) {
        localCameraTrack?.setEnabled(true).then(() => {
          // @ts-ignore
          rtcClient.publish(localCameraTrack).then(() => {
            console.log('publish success');
          });
        });
      } else {
        localCameraTrack?.setEnabled(false);
      }
    }
  }, [localMicrophoneTrack, localCameraTrack, enableAudio, enableVideo]);

  useEffect(() => {
    return () => {
      localCameraTrack?.close();
      localMicrophoneTrack?.close();
      rtcClient.leave().then(() => {
        console.log('client leaves channel');
      });
    };
  }, []);

  //* END ******* AGORA **************************************************************

  return (
    <section className="video-call">
      <Tab.Container id="video-call-tabs-menu" onSelect={activeTab} defaultActiveKey="video">
        <div className="header-top">
          <div className="patient">
            <div className="avatar-section">
              <Avatar size={36} imageURL={picture} firstName={firstName} lastName={lastName} />
            </div>
            <div className="info">
              <div className="fullName">{`${firstName} ${lastName}`}</div>
              <div className="time-left">
                {isInitialTime ? (
                  <>
                    {t('remainingTime')}
                    <TimerComponent
                      action={timeUp}
                      endTime={endTime}
                      onTimeAboutToEnd={onTimeAboutToEnd}
                    />
                  </>
                ) : (
                  t('timeExpire')
                )}
              </div>
            </div>
          </div>
          <div className="tab-menu">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="video">{t('video')}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="profile">{t('profile')}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="records">{t('records')}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="conclusions">{t('conclusions')}</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className={tabName}>
          <div className="video-screens">
            <DoctorVideoCallTabVideo
              appointmentType={appointmentType}
              appointmentId={appointmentId}
              enableAudio={enableAudio}
              enableVideo={enableVideo}
              patient={patient}
              doctor={doctor}
              showTimeBubble={showTimeBubble}
              isInitialTime={isInitialTime}
              isOtherUserConnected={isOtherUserConnected}
              otherVideoEnabled={otherVideoEnabled}
              otherAudioEnabled={otherAudioEnabled}
              localMicrophoneTrack={localMicrophoneTrack}
              handleMic={handleMic}
              localCameraTrack={localCameraTrack}
              toggleVideo={toggleVideo}
              remoteAudioTrack
              remoteVideoTrack
              remoteUser
              remoteUserHasAudio
              remoteUserHasVideo
            />
          </div>
          <Tab.Content>
            <Tab.Pane eventKey="video" />
            <Tab.Pane eventKey="profile">
              <div className="profile-tab">
                <DoctorConsultationDetailsTabProfile />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="records">
              <div className="folder-tab">
                <DoctorConsultationDetailsTabFolder />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="conclusions">
              <div className="conclusions-tab">
                <AppointmentConclusion
                  appointmentId={serverAppointmentId}
                  conclusion={conclusion}
                  serverStatus={serverStatus}
                  appointmentStep="videoCall"
                  doctor={doctor}
                />
              </div>
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </section>
  );
};

export default DoctorVideoCall;
