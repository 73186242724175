import { AnyAction } from 'redux';
import { GET_POSSIBLE_KINSHIP_SUCCESS } from 'shared/redux/types/KinshipTypes';

const PossibleKinshipReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case GET_POSSIBLE_KINSHIP_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default PossibleKinshipReducer;
