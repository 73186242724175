import 'doctor/styles/consultation-details.css';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import { Nav } from 'react-bootstrap';
import {
  getDoctorAppointmentDetailsState,
  getUserProfileState,
} from 'shared/redux/src/StatesGetter';
import Avatar from 'shared/components/Avatar';
import Utils from 'shared/modules/Utils';
import DoctorConsultationDetailsTabInfo from 'doctor/components/consultationDetails/DoctorConsultationDetailsTabInfo';
import DoctorConsultationDetailsTabProfile from 'doctor/components/consultationDetails/DoctorConsultationDetailsTabProfile';
import DoctorConsultationDetailsTabFolder from 'doctor/components/consultationDetails/DoctorConsultationDetailsTabFolder';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import DoctorConsultationDetailsTabRecommendation from '../../components/consultationDetails/DoctorConsultationDetailsTabRecommendation';

type LocationState = {
  templateType: string;
};

const DoctorConsultationDetails = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { templateType = 'future' } = location?.state as LocationState;
  const [searchParams, setSearchParams] = useSearchParams();
  const redirectedTab = searchParams.get('t');
  const {
    patient,
    user,
    medicalRecords,
    specializationName,
    appointmentType = { duration: 0, type: '' },
    serverStatus,
  } = useSelector(getDoctorAppointmentDetailsState, shallowEqual);

  const { isResident = false } = useSelector(getUserProfileState, shallowEqual);
  const { firstName = '', lastName = '', picture, birthDate } = patient ?? user ?? {};

  const renderSubTitle = () => {
    if (Utils.calculateAge(birthDate) > 0) {
      if (
        medicalRecords?.length &&
        ['call_ended', 'completed'].indexOf(serverStatus) > -1 &&
        !isResident
      ) {
        return (
          <div className="age">
            {Utils.calculateAge(birthDate) > 0 && `${Utils.calculateAge(birthDate)} ani • `}
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="15"
              icon="Document"
            />
            {t('attachedFiles')}
          </div>
        );
      }
      return <div className="age">{`${Utils.calculateAge(birthDate)} ani`}</div>;
    }
    return null;
  };
  const renderTabNav = () => {
    if (templateType === 'past') {
      if (appointmentType?.type !== 'free_talk' && !isResident) {
        if (['call_ended'].indexOf(serverStatus) > -1) {
          return (
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="info">{t('info')}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="records">{t('records')}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="recommendation">{t('recommendation')}</Nav.Link>
              </Nav.Item>
            </Nav>
          );
        }
        if (['completed'].indexOf(serverStatus) > -1) {
          return <Nav variant="tabs" />;
        }
      }
    }
    return (
      <Nav variant="tabs">
        <Nav.Item>
          <Nav.Link eventKey="info">{t('info')}</Nav.Link>
        </Nav.Item>
        {appointmentType.type !== 'free_talk' && !isResident && (
          <>
            <Nav.Item>
              <Nav.Link eventKey="profile">{t('profile')}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="records">{t('records')}</Nav.Link>
            </Nav.Item>
          </>
        )}
      </Nav>
    );
  };

  let activeKey = templateType === 'future' ? 'info' : 'info';
  if (redirectedTab === 'r') {
    activeKey = 'recommendation';
  }
  return (
    <section className="consultation-details">
      <div className="title-section">
        <h1>{t('history.details')}</h1>
      </div>
      <div className="small-container">
        <div className="patient-box">
          <Avatar size={40} imageURL={picture} firstName={firstName} lastName={lastName} />
          <div className="info">
            <div className="fullName">{`${firstName} ${lastName}`}</div>
            <div className="requested-specialization">{specializationName}</div>
            {renderSubTitle()}
          </div>
        </div>
        <Tab.Container id="consultation-details-tabs-menu" defaultActiveKey={activeKey}>
          {renderTabNav()}
          <Tab.Content>
            {templateType === 'future' && (
              <>
                <Tab.Pane eventKey="profile">
                  <DoctorConsultationDetailsTabProfile />
                </Tab.Pane>
              </>
            )}
            <Tab.Pane eventKey="info">
              <DoctorConsultationDetailsTabInfo templateType={templateType} />
            </Tab.Pane>
            <Tab.Pane eventKey="records">
              <DoctorConsultationDetailsTabFolder />
            </Tab.Pane>
            {templateType === 'past' && (
              <>
                <Tab.Pane eventKey="recommendation">
                  <DoctorConsultationDetailsTabRecommendation />
                </Tab.Pane>
              </>
            )}
          </Tab.Content>
        </Tab.Container>
      </div>
    </section>
  );
};

DoctorConsultationDetails.defaultProps = {
  match: {},
  location: {
    state: {
      templateType: 'future',
    },
  },
};
DoctorConsultationDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      templateType: PropTypes.string,
    }),
  }),
};
export default DoctorConsultationDetails;
