import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { translatedFullDate } from 'shared/modules/DateTimeUtils';
import {
  fetchPushDetailsRequest,
  updatePushesRequest,
} from 'shared/redux/actions/NotificationActions';

const DoctorNotificationBlock = ({ item }) => {
  const { id, isAnswered, title, createdAt, body } = item;
  const dispatch = useDispatch();
  const readNotification = () => {
    // if (!isAnswered) {
    dispatch(fetchPushDetailsRequest({ id }));
    // }
    // dispatch(
    //   updatePushesRequest({
    //     forRequest: { notificationId: id, status: 'answered' },
    //     forUpdate: { id, props: { isAnswered: true } },
    //   }),
    // );
  };

  return (
    <div
      className={`${!isAnswered ? 'box cursor-pointer' : 'box pointer-events-none'}`}
      onClick={readNotification}
    >
      <div className="title">
        {!isAnswered && (
          <div className="unread-notification">
            <div className="green-circle" />
          </div>
        )}
        {title}
      </div>
      <div className="date">{translatedFullDate(createdAt)}</div>
      <div className="description">{body}</div>
      {/* <button type="button" onClick={readNotification}> */}
      {/*    Mergi la recomandare */}
      {/*    <CustomIcon */}
      {/*        className="custom-icon" */}
      {/*        color={Colors.patientColor} */}
      {/*        size="24" */}
      {/*        icon="Right_pointer" */}
      {/*    /> */}
      {/* </button> */}
    </div>
  );
};

DoctorNotificationBlock.propTypes = {
  item: PropTypes.shape({}).isRequired,
};

export default DoctorNotificationBlock;
