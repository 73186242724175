import 'doctor/styles/availability-schedule.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DoctorAccountAvailabilityScheduleBlock from 'doctor/components/account/DoctorAccountAvailabilityScheduleBlock';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';

const DoctorAvailabilitySchedule = () => {
  const { t } = useTranslation();
  return (
    <section className="availability-schedule">
      <Link to="/doctor/home" className="back-to-home">
        <CustomIcon className="custom-icon" color={Colors.darkGrey} size="24" icon="Left_arrow" />
        {t('back')}
      </Link>
      <div className="small-container">
        <DoctorAccountAvailabilityScheduleBlock />
      </div>
    </section>
  );
};

export default DoctorAvailabilitySchedule;
