import { Form, Modal } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import {
  getLocalSpecializationState,
  getSpecializationsState,
  getUserProfileState,
} from 'shared/redux/src/StatesGetter';
import { getSpecializationsRequest } from 'shared/redux/actions/SpecializationActions';
import {
  locallyAddSpecialization,
  locallyRemoveSpecialization,
} from 'shared/redux/actions/UserProfileActions';
import Alerts from 'shared/components/Alerts';
import { useTranslation } from 'react-i18next';

const SpecializationPicker = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    showModalSpecializationPicker,
    setModalSpecializationPickerShow,
    setModalNewSpecializationShow,
  } = props;
  const modalSpecializationPickerClose = () => {
    setModalSpecializationPickerShow(false);
    setModalNewSpecializationShow(true);
  };
  const localSpecialization = useSelector(getLocalSpecializationState, shallowEqual);
  const [search, setSearch] = useState('');
  const {
    data: specializations,
    currentPage,
    noOfPages,
  } = useSelector(getSpecializationsState, shallowEqual);
  const { doctorSpecializations } = useSelector(getUserProfileState, shallowEqual);
  const ids = doctorSpecializations
    .filter((o) => o.isValidated && o.isActive)
    .map((sp) => {
      return sp?.id;
    });
  useEffect(() => {
    dispatch(getSpecializationsRequest({ searchName: search }));
  }, []);
  const onListEndReached = () => {
    // if (currentPage <= noOfPages) {
    //   dispatch(
    //     getSpecializationsRequest({
    //       page: currentPage,
    //       searchName: search,
    //       nextPage: true,
    //     }),
    //   );
    // }
  };
  // const onRefresh = () => {
  //   dispatch(getSpecializationsRequest({ searchName: search }));
  // };
  const searchSpecialization = () => {
    dispatch(getSpecializationsRequest({ searchName: search }));
  };
  const toggleSp = (item) => {
    if (item?.id === localSpecialization?.id) {
      dispatch(locallyRemoveSpecialization({}));
      return;
    }
    dispatch(locallyAddSpecialization({ id: item?.id, name: item.name }));
  };
  const renderItem = (item) => {
    if (ids.indexOf(item?.id) > -1) {
      return (
        <div
          key={item.id.toString()}
          style={{ opacity: ids.length && ids?.indexOf(item?.id) > -1 ? 0.5 : 1 }}
          className="custom-control active-label-green"
        >
          <label htmlFor={`specialization_${item.id}`} className="custom-control-label">
            {`${item.name}`}
          </label>
        </div>
      );
    }
    return (
      <div
        key={item.id.toString()}
        style={{ opacity: ids.length && ids?.indexOf(item?.id) > -1 ? 0.5 : 1 }}
        className="custom-control custom-radio green-radio right-radio active-label-green"
      >
        <input
          className="custom-control-input"
          id={`specialization_${item.id}`}
          name="specialization"
          type="radio"
          checked={item?.id === localSpecialization?.id}
          onChange={() => toggleSp(item)}
          disabled={ids.length && ids?.indexOf(item?.id) > -1}
        />
        <label htmlFor={`specialization_${item.id}`} className="custom-control-label">
          {`${item.name}`}
        </label>
      </div>
    );
  };
  const apply = () => {
    if (!localSpecialization) {
      Alerts.simpleAlert(t('warning'), t('validations.missingSpecialization'));
    }
    setModalSpecializationPickerShow(false);
    setModalNewSpecializationShow(true);
  };
  const reset = () => {
    setSearch('');
    specializations.map((item) => {
      if (item?.id === localSpecialization?.id) {
        dispatch(locallyRemoveSpecialization({}));
      }
      return null;
    });
    dispatch(getSpecializationsRequest({ searchName: '' }));
  };
  return (
    <Modal
      show={showModalSpecializationPicker}
      onHide={modalSpecializationPickerClose}
      animation
      centered
      className="modal-add-specialization medic-modal"
    >
      <Modal.Header closeButton closeLabel={t('close2')}>
        <Modal.Title>{t('addSpecialization')}</Modal.Title>
      </Modal.Header>
      <Modal.Header className="subheader">
        <div className="searchbar">
          <div onClick={searchSpecialization}>
            <CustomIcon className="custom-icon" color={Colors.mediumGrey} size="20" icon="Search" />
          </div>
          <input
            value={search}
            onChange={(e) => setSearch(e?.target?.value)}
            type="text"
            placeholder={t('searchSpecialization')}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="scroll-list">
          <Form>
            {specializations?.length ? (
              specializations.map((sp) => {
                return renderItem(sp);
              })
            ) : (
              <p>{t('noSpecialization')}</p>
            )}
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="confirm-selection-btn green-button" onClick={apply}>
          {t('confirm')}
        </button>
        <button type="button" className="green-button" onClick={reset}>
          {t('reset')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SpecializationPicker;
