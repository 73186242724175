import { AnyAction } from 'redux';
import {
  FETCH_LANGUAGES_ERROR,
  FETCH_LANGUAGES_REQUEST,
  FETCH_LANGUAGES_SUCCESS,
  SET_LANGUAGES_ERROR,
  SET_LANGUAGES_REQUEST,
  SET_LANGUAGES_SUCCESS,
} from 'shared/redux/types/LanguageTypes';

export const fetchLanguagesRequest = (payload: any): AnyAction => ({
  type: FETCH_LANGUAGES_REQUEST,
  payload,
});

export const fetchLanguagesSuccess = (payload: any): AnyAction => ({
  type: FETCH_LANGUAGES_SUCCESS,
  payload,
});

export const fetchLanguagesError = (payload: any): AnyAction => ({
  type: FETCH_LANGUAGES_ERROR,
  payload,
});

export const setLanguagesRequest = (payload: any): AnyAction => ({
  type: SET_LANGUAGES_REQUEST,
  payload,
});

export const setLanguagesSuccess = (payload: any): AnyAction => ({
  type: SET_LANGUAGES_SUCCESS,
  payload,
});

export const setLanguagesError = (payload: any): AnyAction => ({
  type: SET_LANGUAGES_ERROR,
  payload,
});
