import dayjs from 'dayjs';

const myReviewsSelector = (reviews) => {
  // eslint-disable-next-line no-underscore-dangle
  const reviewsArray = reviews._embedded?.items ?? [];

  return reviewsArray.map((row) => {
    return {
      id: row?.id,
      rating: row.rating,
      remarks: row.remarks,
      createdAt: dayjs(row.created_at).format('YYYY-MM-DD'),
      originator: row.anonymized_name,
    };
  });
};

export default myReviewsSelector;
