import { AnyAction } from 'redux';
import {
  FETCH_RECOMMENDATIONS_ERROR,
  FETCH_RECOMMENDATIONS_REQUEST,
  FETCH_RECOMMENDATIONS_SUCCESS,
} from 'shared/redux/types/RecommendationsTypes';

export const fetchRecommendationsRequest = (payload): AnyAction => ({
  type: FETCH_RECOMMENDATIONS_REQUEST,
  payload,
});

export const fetchRecommendationsSuccess = (payload): AnyAction => ({
  type: FETCH_RECOMMENDATIONS_SUCCESS,
  payload,
});

export const fetchRecommendationsError = (payload): AnyAction => ({
  type: FETCH_RECOMMENDATIONS_ERROR,
  payload,
});
