import StorageService from 'shared/services/StorageService';
import { default as StringConstants } from 'shared/constants/StringConstants.json';
import firebase from 'firebase';
import LocalEventsService from 'shared/services/LocalEventsService';

export const getDestination = () => {
  if (document.getElementById('isDoctorApp')) return 'doctor';
  return 'patient';
};

export const getActiveRole = async (): Promise<string | null> => {
  return StorageService.getData(StringConstants.ACTIVE_ROLE, null);
};

export const isLoggedIn = async () => {
  const userToken = await StorageService.getData(StringConstants.USER_TOKEN, '');
  return userToken?.length;
};

export const loginCheckPoint = async () => {
  const userToken = await StorageService.getData(StringConstants.USER_TOKEN, null);
  const isProfileCompleted = await StorageService.getData(
    StringConstants.IS_PROFILE_COMPLETED,
    'true',
  );
  const isConfirmed = await StorageService.getData(StringConstants.IS_CONFIRMED, 'true');
  if (userToken) {
    const deviceRegId = await firebase.getWebToken();
    return {
      action: 'login',
      deviceRegId,
      isProfileCompleted: isProfileCompleted.indexOf('Not') === -1,
      isConfirmed: isConfirmed.indexOf('Not') === -1,
    };
  }
  return { action: 'logOut', deviceRegId: null, isProfileCompleted: true, isConfirmed: true };
};

export const handleLoginResponse = async (data, isReal = true) => {
  await StorageService.setData(StringConstants.USER_TOKEN, data.token);
  await StorageService.setData(StringConstants.REFRESH_TOKEN, data.refresh_token);
  await StorageService.setData(StringConstants.IS_REALLY_LOGGED_IN, isReal ? 'true' : 'false');
  const activeRole = getDestination();
  await StorageService.setData(StringConstants.ACTIVE_ROLE, activeRole);
};

export const handleExtraLoginResponse = async (isProfileCompleted, isConfirmed) => {
  await StorageService.setData(StringConstants.IS_PROFILE_COMPLETED, isProfileCompleted);
  await StorageService.setData(StringConstants.IS_CONFIRMED, isConfirmed);
};

export const allowRequestCheckpoint = async () => {
  return StorageService.getData(StringConstants.USER_TOKEN, null).then(async (token) => {
    if (!token) return true;
    const destination = getDestination();
    const activeRole = await getActiveRole();
    if (destination !== activeRole) {
      LocalEventsService.emit('forceLogout');
      return false;
    }
    return true;
  });
};
