import 'shared/styles/contact.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { contactUsRequest } from 'shared/redux/actions/SupportActions';
import FooterSection from 'shared/components/FooterSection';
import HeaderMenu from 'shared/components/HeaderMenu';
import HeaderMenuMobile from 'shared/components/HeaderMenuMobile';
import { Helmet } from 'react-helmet';
import openGraphImage from 'shared/assets/images/ringdoc_image_open_graph.png';
import Alerts from 'shared/components/Alerts';
import { isDoctor } from 'shared/services/EnvService';
import {
  getAuthState,
  getPatientProfileState,
  getUserProfileState,
} from 'shared/redux/src/StatesGetter';
import { scrollToTop } from '../modules/WindowUtils';
import Validators from '../modules/Validators';

const ContactPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoggedIn, lastUpdate } = useSelector(getAuthState, shallowEqual);
  const profileData = useSelector(
    isDoctor(true) ? getUserProfileState : getPatientProfileState,
    shallowEqual,
  );
  const [firstName, setFirstName] = useState(profileData?.firstName);
  const [lastName, setLastName] = useState(profileData?.lastName);
  const [email, setEmail] = useState(profileData?.email);
  const [phoneNumber, setPhoneNumber] = useState(profileData?.phoneNumber);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [privacyPolicyConsent, setPrivacyPolicyConsent] = useState(false);
  const [requiredFieldsError, setRequiredFieldsError] = useState(false);

  const sendAction = (e) => {
    e.preventDefault();

    if (!firstName) {
      Alerts.simpleAlert(
        `${t('error')}`,
        `${t('contactPage.missingFieldValue', {
          field: t('firstName'),
        })}`,
      );
      setRequiredFieldsError(true);
      return;
    }

    if (!lastName) {
      Alerts.simpleAlert(
        `${t('error')}`,
        `${t('contactPage.missingFieldValue', {
          field: t('lastName'),
        })}`,
      );
      setRequiredFieldsError(true);
      return;
    }

    if (!email) {
      Alerts.simpleAlert(
        `${t('error')}`,
        `${t('contactPage.missingFieldValue', {
          field: t('email'),
        })}`,
      );
      setRequiredFieldsError(true);
      return;
    }

    if (!phoneNumber) {
      Alerts.simpleAlert(
        `${t('error')}`,
        `${t('contactPage.missingFieldValue', {
          field: t('phoneNumber'),
        })}`,
      );
      setRequiredFieldsError(true);
      return;
    }

    if (!subject) {
      Alerts.simpleAlert(
        `${t('error')}`,
        `${t('contactPage.missingFieldValue', {
          field: t('support.subject'),
        })}`,
      );
      setRequiredFieldsError(true);
      return;
    }

    if (!message) {
      Alerts.simpleAlert(
        `${t('error')}`,
        `${t('contactPage.missingFieldValue', {
          field: t('support.message'),
        })}`,
      );
      setRequiredFieldsError(true);
      return;
    }

    if (!privacyPolicyConsent) {
      Alerts.simpleAlert(`${t('error')}`, `${t('contactPage.iAgreeWith')}`);
      setRequiredFieldsError(true);
      return;
    }

    dispatch(
      contactUsRequest({
        subject,
        content: message,
        firstName,
        lastName,
        email,
        phoneNumber,
      }),
    );

    setRequiredFieldsError(false);
    setPrivacyPolicyConsent(false);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const currentUrl = window.location.href;
  const baseUrl = window.location.origin;

  return (
    <div className="contact-page">
      <Helmet>
        <title>{t('contactPage.titlePage')}</title>
        <meta
          name="description"
          content="Detalii contact Ringdoc, clinica ta medicala virtuala pe care poti efectua consultatii cu doctori online de oriunde ai fi"
        />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:locale" content="ro_RO" />
        <meta property="og:locale:alternate" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={t('contactPage.titlePage')} />
        <meta
          property="og:description"
          content="Detalii contact Ringdoc, clinica ta medicala virtuala pe care poti efectua consultatii cu doctori online de oriunde ai fi"
        />
        <meta property="og:image" content={`${baseUrl}${openGraphImage}`} />
      </Helmet>

      {/* <HeaderMenuMobile /> */}

      <div className="container-fluid">
        <div className="row">
          <HeaderMenu />

          <main className="container mt-auto mb-auto">
            <section className="title">
              <h1>{t('contactPage.title')}</h1>
            </section>
            <div className="contact-form">
              <div className="row">
                <div className="col-lg-8 col-xl-8 col-md-6 col-12">
                  <form onSubmit={sendAction}>
                    {(!isLoggedIn || !firstName) && (
                      <div className="form-input">
                        <input
                          type="text"
                          name="firstName"
                          value={firstName}
                          onChange={(event) => setFirstName(event.target.value)}
                          className={`form-control ${
                            requiredFieldsError && !firstName && 'is-invalid'
                          }`}
                          placeholder=" "
                        />
                        <label htmlFor="firstName">{t('firstName')}</label>
                      </div>
                    )}
                    {(!isLoggedIn || !lastName) && (
                      <div className="form-input">
                        <input
                          type="text"
                          name="lastName"
                          value={lastName}
                          onChange={(event) => setLastName(event.target.value)}
                          className={`form-control ${
                            requiredFieldsError && !lastName && 'is-invalid'
                          }`}
                          placeholder=" "
                        />
                        <label htmlFor="lastName">{t('lastName')}</label>
                      </div>
                    )}
                    {(!isLoggedIn || !email) && (
                      <div className="form-input">
                        <input
                          type="text"
                          name="email"
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                          className={`form-control ${
                            requiredFieldsError &&
                            (email === '' || !Validators.validateEmail(email)) &&
                            'is-invalid'
                          }`}
                          placeholder=" "
                        />
                        <label htmlFor="email">{t('email')}</label>
                      </div>
                    )}
                    {(!isLoggedIn || !phoneNumber) && (
                      <div className="form-input">
                        <input
                          type="text"
                          name="phoneNumber"
                          value={phoneNumber}
                          onChange={(event) => setPhoneNumber(event.target.value)}
                          className={`form-control ${
                            requiredFieldsError && !phoneNumber && 'is-invalid'
                          }`}
                          placeholder=" "
                        />
                        <label htmlFor="phoneNumber">{t('phoneNumber')}</label>
                      </div>
                    )}
                    <div className="form-input">
                      <input
                        type="text"
                        name="subject"
                        value={subject}
                        onChange={(event) => setSubject(event.target.value)}
                        className={`form-control ${
                          requiredFieldsError && !subject && 'is-invalid'
                        }`}
                        placeholder=" "
                      />
                      <label htmlFor="subject">{t('support.subject')}</label>
                    </div>
                    <div className="form-textarea">
                      <textarea
                        className={`form-control ${
                          requiredFieldsError && !message && 'is-invalid'
                        }`}
                        name="message"
                        placeholder=" "
                        value={message}
                        onChange={(event) => setMessage(event.target.value)}
                        rows={5}
                      />
                      <label htmlFor="message">{t('support.message')}</label>
                    </div>
                    <div className="custom-control custom-checkbox orange-checkbox">
                      <input
                        className={`custom-control-input ${
                          requiredFieldsError && !privacyPolicyConsent && 'is-invalid'
                        }`}
                        id="future_appointment"
                        type="checkbox"
                        checked={privacyPolicyConsent}
                        onChange={() => setPrivacyPolicyConsent(!privacyPolicyConsent)}
                      />
                      <label htmlFor="future_appointment" className="custom-control-label">
                        {t('iUnderstandAnd')}{' '}
                        <a
                          target="_blank"
                          href="https://api.ringdoc.ro/ro/pages/politica-de-confidentilitate"
                          rel="noreferrer"
                        >
                          {t('support.policy')}
                        </a>
                      </label>
                    </div>
                    <button
                      type="submit"
                      className={isDoctor(true) ? 'green-button mt-4' : 'orange-button mt-4'}
                    >
                      {t('send')}
                    </button>
                  </form>
                </div>
                <div className="col-lg-4 col-xl-4 col-md-6 col-12 pt-4 pt-md-0">
                  <div className="subtitle mt-0">{t('contactPage.applicationProgram')}</div>
                  <div className="contact-info-text">{`${t('day_1')} - ${t(
                    'day_7',
                  )}: 8:00 - 24:00`}</div>
                  <div className="contact-info-text">contact@ringdoc.ro</div>

                  <div className="subtitle">{t('address')}</div>
                  <div className="contact-info-text">
                    Alo Doc App SRL
                    <br />
                    Calea Floreasca 169A, Cladirea A, Unitatea A.3.3, Et. 3, Sector. 1, Bucuresti
                    020285, Romania
                  </div>
                </div>
              </div>
            </div>
          </main>
          <FooterSection />
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
