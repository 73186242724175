import { Routes, Route, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import PrivateRoute from 'shared/components/PrivateRoute';
import PatientRating from 'patient/screens/rating/PatientRating';
import PatientLogin from 'patient/screens/auth/PatientLogin';
import PatientRegister from 'patient/screens/auth/PatientRegister';
import PatientForgotPassword from 'patient/screens/auth/PatientForgotPassword';
import PatientResetPassword from 'patient/screens/auth/PatientResetPassword';
import PatientAccount from 'patient/screens/account/PatientAccount';
import PatientMedicalFolder from 'patient/screens/medicalFolder/PatientMedicalFolder';
import PatientMedicalFolderSelectProfile from 'patient/screens/medicalFolder/PatientMedicalFolderSelectProfile';
import PatientMedicalFolderAddProfile from 'patient/screens/medicalFolder/PatientMedicalFolderAddProfile';
import PatientAppointmentSpecialization from 'patient/screens/appointment/PatientAppointmentSpecialization';
import PatientAppointmentChooseService from 'patient/screens/appointment/PatientAppointmentChooseService';
import PatientAppointmentSetDate from 'patient/screens/appointment/PatientAppointmentSetDate';
import PatientAppointmentProfile from 'patient/screens/appointment/PatientAppointmentProfile';
import PatientAppointmentMedicalDocuments from 'patient/screens/appointment/PatientAppointmentMedicalDocuments';
import PatientAppointmentSummaryPayment from 'patient/screens/appointment/PatientAppointmentSummaryPayment';
import PatientAppointmentConfirmed from 'patient/screens/appointment/PatientAppointmentConfirmed';
import PatientAppointmentWaitingScreen from 'patient/screens/appointment/PatientAppointmentWaitingScreen';
import PatientVideoCall from 'patient/screens/videoCall/PatientVideoCall';
import PatientNotifications from 'patient/screens/notifications/PatientNotifications';
import PatientFreeCallUserData from 'patient/screens/freeCall/PatientFreeCallUserData';
import PatientFreeCallSearchingMedic from 'patient/screens/freeCall/PatientFreeCallSearchingMedic';
import PatientFreeCallMedicFound from 'patient/screens/freeCall/PatientFreeCallMedicFound';
import PatientMedicList from 'patient/screens/medicList/PatientMedicList';
import PatientMedicProfile from 'patient/screens/medicProfile/PatientMedicProfile';
import PatientMedicProfileCV from 'patient/screens/medicProfile/PatientMedicProfileCV';
import PatientHome from 'patient/screens/main/PatientHome';
import AccountActivation from 'patient/screens/auth/AccountActivation';
import WorkInProgress from 'shared/screens/WorkInProgress';
import PermissionsScreen from 'shared/screens/auth/PermissionsScreen';
import ConsultationList from 'patient/screens/consultations/ConsultationList';
import ConsultationDetails from 'patient/screens/consultations/ConsultationDetails';
import SearchingMedic from 'patient/screens/callSpecialist/SearchingMedic';
import MedicFound from 'patient/screens/callSpecialist/MedicFound';
import SummaryPayment from 'patient/screens/callSpecialist/SummaryPayment';
import WaitingScreen from 'patient/screens/callSpecialist/WaitingScreen';
import SelectSpecialization from 'patient/screens/callSpecialist/SelectSpecialization';
import PageNotFound from 'shared/screens/PageNotFound';
import Profile from './screens/callSpecialist/Profile';
import ResendConfirmationToken from '../shared/screens/auth/ResendConfirmationToken';
import PatientMainScreen from './screens/main/PatientMainScreen';
import PatientMedicListStaticSpecialization from './screens/medicList/PatientMedicListStaticSpecialization';
import PatientLanding from './screens/main/PatientLanding';

const PatientMenuRoutesWithFullAccess = [
  {
    path: '/',
    key: 'PATIENT_HOME_MENU',
    className: 'home',
    name: 'mainScreen',
    iconName: 'Home',
    activeClassWhenPathStartsWith: '/',
  },
  {
    path: '/patient/medicList',
    key: 'PATIENT_MEDIC_LIST_MENU',
    className: 'medicList',
    name: 'medicList',
    iconName: 'List_doctors',
    activeClassWhenPathStartsWith: '/patient/medicList',
  },
  {
    path: '/patient/medicalFolder/select-profile',
    key: 'PATIENT_ROOT_MEDICAL_FOLDER_MENU',
    className: 'medicalFolder',
    name: 'medicalRecord',
    iconName: 'Dosar',
    activeClassWhenPathStartsWith: '/patient/medicalFolder',
  },
  {
    path: '/patient/consultations',
    key: 'PATIENT_CONSULTATIONS_MENU',
    className: 'consultations',
    name: 'appointments.appointments',
    iconName: 'Appointments',
    activeClassWhenPathStartsWith: '/patient/consultations',
  },
  {
    path: '/patient/account',
    key: 'PATIENT_ACCOUNT_MENU',
    className: 'account',
    name: 'account.account',
    iconName: 'Profil',
    activeClassWhenPathStartsWith: '/patient/account',
  },
  {
    path: '/patient/notifications',
    key: 'PATIENT_NOTIFICATIONS_MENU',
    className: 'notifications',
    name: 'notifications',
    iconName: 'Notifications',
    activeClassWhenPathStartsWith: '/patient/notifications',
  },
];

const PatientMenuRoutesWithoutFullAccess = [
  {
    path: '/',
    key: 'PATIENT_HOME_MENU',
    className: 'home',
    name: 'mainScreen',
    iconName: 'Home',
    activeClassWhenPathStartsWith: '',
  },
  {
    path: '/patient/account',
    key: 'PATIENT_ACCOUNT_MENU',
    className: 'account',
    name: 'account.account',
    iconName: 'Profil',
    activeClassWhenPathStartsWith: '/patient/account',
  },
  {
    path: '/patient/notifications',
    key: 'PATIENT_NOTIFICATIONS_MENU',
    className: 'notifications',
    translationKey: '',
    iconName: 'Notifications',
    activeClassWhenPathStartsWith: '/patient/notifications',
  },
];

const AuthRoutes = [
  {
    path: 'login',
    key: 'PATIENT_ROOT_LOGIN',
    exact: true,
    component: <PatientLogin />,
  },
  {
    path: 'activate-account',
    key: 'PATIENT_ROOT_ACTIVATE_ACCOUNT',
    exact: true,
    component: <AccountActivation />,
  },
  {
    path: 'register',
    key: 'PATIENT_ROOT_REGISTER',
    exact: true,
    component: <PatientRegister />,
  },
  {
    path: 'forgot-password',
    key: 'PATIENT_ROOT_FORGOT_PASSWORD',
    exact: true,
    component: <PatientForgotPassword />,
  },
  {
    path: 'reset-password',
    key: 'PATIENT_ROOT_RESET_PASSWORD',
    exact: true,
    component: <PatientResetPassword />,
  },
  {
    path: 'resend-confirmation-token',
    key: 'PATIENT_ROOT_RESEND_CONFIRMATION_TOKEN',
    exact: true,
    component: <ResendConfirmationToken />,
  },
];

const StaticRoutes = [
  {
    path: '/',
    key: 'PATIENT_HOME',
    exact: true,
    component: <PatientMainScreen />,
  },
  {
    path: 'home',
    key: 'homepage_patient',
    component: <PatientLanding />,
  },
  {
    path: '/rating',
    key: 'PATIENT_ROOT_RATING',
    exact: true,
    component: <PatientRating />,
  },
];

const MedicPublicRoutes = [
  {
    path: 'medicList',
    key: 'PATIENT_ROOT_MEDIC_LIST',
    exact: true,
    component: <PatientMedicList />,
  },
  {
    path: 'medicList/:specialization',
    key: 'PATIENT_ROOT_MEDIC_LIST',
    exact: true,
    component: <PatientMedicList />,
  },
  {
    path: 'medicProfile/:id',
    key: 'PATIENT_ROOT_MEDIC_PROFILE',
    exact: true,
    component: <PatientMedicProfile />,
  },
  {
    path: 'medicProfile/:id/curriculum-vitae',
    key: 'PATIENT_ROOT_MEDIC_PROFILE_CV',
    exact: true,
    component: <PatientMedicProfileCV />,
  },
  {
    path: 'alergologie',
    key: 'PATIENT_ROOT_MEDIC_LIST_ALERGOLOGIE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'anestezie-si-terapie-intensiva',
    key: 'PATIENT_ROOT_MEDIC_LIST_ANESTEZIE_SI_TERAPIE_INTENSIVA',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'apifitoterapie',
    key: 'PATIENT_ROOT_MEDIC_LIST_APIFITOTERAPIE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'boli-infectioase',
    key: 'PATIENT_ROOT_MEDIC_LIST_BOLI_INFECTIOASE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'cardiologie',
    key: 'PATIENT_ROOT_MEDIC_LIST_CARDIOLOGIE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'chirurgie-plastica',
    key: 'PATIENT_ROOT_MEDIC_LIST_CHIRURGIE_PLASTICA',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'chirurgie-generala',
    key: 'PATIENT_ROOT_MEDIC_LIST_CHIRURGIE_GENERALA',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'chirurgie-pediatrica',
    key: 'PATIENT_ROOT_MEDIC_LIST_CHIRURGIE_PEDIATRICA',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'dermatologie',
    key: 'PATIENT_ROOT_MEDIC_LIST_DERMATOLOGIE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'diabet-zaharat',
    key: 'PATIENT_ROOT_MEDIC_LIST_DIABET_ZAHARAT',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'endocrinologie',
    key: 'PATIENT_ROOT_MEDIC_LIST_ENDOCRINOLOGIE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'gastroenterologie',
    key: 'PATIENT_ROOT_MEDIC_LIST_GASTROENTEROLOGIE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'hematologie',
    key: 'PATIENT_ROOT_MEDIC_LIST_HEMATOLOGIE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'homeopatie',
    key: 'PATIENT_ROOT_MEDIC_LIST_HOMEOPATIE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'kinetoterapie',
    key: 'PATIENT_ROOT_MEDIC_LIST_KINETOTERAPIE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'logopedie',
    key: 'PATIENT_ROOT_MEDIC_LIST_LOGOPEDIE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'medicina-de-familie',
    key: 'PATIENT_ROOT_MEDIC_LIST_MEDICINA_DE_FAMILIE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'managementul-durerii',
    key: 'PATIENT_ROOT_MEDIC_LIST_MANAGEMENTUL_DURERII',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'medicina-interna',
    key: 'PATIENT_ROOT_MEDIC_LIST_MEDICINA_INTERNA',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'medicina-generala-urgenta',
    key: 'PATIENT_ROOT_MEDIC_LIST_MEDICINA_GENERALA_URGENTA',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'medicina-generala',
    key: 'PATIENT_ROOT_MEDIC_LIST_MEDICINA_GENERALA',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'nefrologie',
    key: 'PATIENT_ROOT_MEDIC_LIST_NEFROLOGIE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'neonatologie',
    key: 'PATIENT_ROOT_MEDIC_LIST_NEONATOLOGIE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'neurochirurgie',
    key: 'PATIENT_ROOT_MEDIC_LIST_NEUROCHIRURGIE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'neurologie',
    key: 'PATIENT_ROOT_MEDIC_LIST_NEUROLOGIE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'neurologie-pediatrica',
    key: 'PATIENT_ROOT_MEDIC_LIST_NEUROLOGIE_PEDIATRICA',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'obstetrica-ginecologie',
    key: 'PATIENT_ROOT_MEDIC_LIST_OBSTETRICA_GINECOLOGIE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'oftalmologie',
    key: 'PATIENT_ROOT_MEDIC_LIST_OFTALMOLOGIE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'orientare-medicala',
    key: 'PATIENT_ROOT_MEDIC_LIST_ORIENTARE_MEDICALA',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'orl',
    key: 'PATIENT_ROOT_MEDIC_LIST_ORL',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'ortopedie-pediatrica',
    key: 'PATIENT_ROOT_MEDIC_LIST_ORTOPEDIE_PEDIATRICA',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'ortopedie-si-traumatologie',
    key: 'PATIENT_ROOT_MEDIC_LIST_ORTOPEDIE_SI_TRAUMATOLOGIE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'pediatrie',
    key: 'PATIENT_ROOT_MEDIC_LIST_PEDIATRIE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'pneumologie',
    key: 'PATIENT_ROOT_MEDIC_LIST_PNEUMOLOGIE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'psihiatrie',
    key: 'PATIENT_ROOT_MEDIC_LIST_PSIHIATRIE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'psihiatrie-pediatrica',
    key: 'PATIENT_ROOT_MEDIC_LIST_PSIHIATRIE_PEDIATRICA',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'psihologie',
    key: 'PATIENT_ROOT_MEDIC_LIST_PSIHOLOGIE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'psihoterapie',
    key: 'PATIENT_ROOT_MEDIC_LIST_PSIHOTERAPIE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'radiologie-interventionala',
    key: 'PATIENT_ROOT_MEDIC_LIST_RADIOLOGIE_INTERVENTIONALA',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'recuperare-medicala',
    key: 'PATIENT_ROOT_MEDIC_LIST_RECUPERARE_MEDICALA',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'reumatologie',
    key: 'PATIENT_ROOT_MEDIC_LIST_REUMATOLOGIE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'stomatologie-generala',
    key: 'PATIENT_ROOT_MEDIC_LIST_STOMATOLOGIE_GENERALA',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
  {
    path: 'urologie',
    key: 'PATIENT_ROOT_MEDIC_LIST_UROLOGIE',
    exact: true,
    component: <PatientMedicListStaticSpecialization />,
  },
];

const AppointmentRoutes = [
  {
    path: 'appointment/specialization',
    key: 'PATIENT_ROOT_APPOINTMENT_SPECIALIZATION',
    exact: true,
    component: <PatientAppointmentSpecialization />,
  },
  {
    path: 'appointment/choose-service',
    key: 'PATIENT_ROOT_APPOINTMENT_CHOOSE_SERVICE',
    exact: true,
    component: <PatientAppointmentChooseService />,
  },
  {
    path: 'appointment/set-date',
    key: 'PATIENT_ROOT_APPOINTMENT_SET_DATE',
    exact: true,
    component: <PatientAppointmentSetDate />,
  },
  {
    path: 'appointment/profile',
    key: 'PATIENT_ROOT_APPOINTMENT_PROFILE',
    exact: true,
    component: <PatientAppointmentProfile />,
  },
  {
    path: 'appointment/medical-documents',
    key: 'PATIENT_ROOT_APPOINTMENT_MEDICAL_DOCUMENTS',
    exact: true,
    component: <PatientAppointmentMedicalDocuments />,
  },
  {
    path: 'appointment/summary-payment',
    key: 'PATIENT_ROOT_APPOINTMENT_SUMMARY_PAYMENT',
    exact: true,
    component: <PatientAppointmentSummaryPayment />,
  },
  {
    path: 'appointment/confirmed-appointment',
    key: 'PATIENT_ROOT_APPOINTMENT_CONFIRMED',
    exact: true,
    component: <PatientAppointmentConfirmed />,
  },
  {
    path: 'appointment/waiting-screen',
    key: 'PATIENT_ROOT_APPOINTMENT_WAITING_SCREEN',
    exact: true,
    component: <PatientAppointmentWaitingScreen />,
  },
  {
    path: 'video-call',
    key: 'PATIENT_VIDEO_CALL_ROOT',
    exact: true,
    component: <PatientVideoCall />,
  },
  {
    path: 'freeCall/user',
    key: 'PATIENT_ROOT_FREE_CALL_APP_USER_DATA',
    exact: true,
    component: <PatientFreeCallUserData />,
  },
  {
    path: 'freeCall/searching-medic',
    key: 'PATIENT_ROOT_FREE_CALL_APP_SEARCHING_MEDIC',
    exact: true,
    component: <PatientFreeCallSearchingMedic />,
  },
  {
    path: 'freeCall/medic-found',
    key: 'PATIENT_ROOT_FREE_CALL_APP_MEDIC_FOUND',
    exact: true,
    component: <PatientFreeCallMedicFound />,
  },
  {
    path: 'call-specialist/select-specialization',
    key: 'PATIENT_ROOT_CALL_SPECIALIST_SELECT_SPECIALIZATION',
    exact: true,
    component: <SelectSpecialization />,
  },
  {
    path: 'call-specialist/profile',
    key: 'PATIENT_ROOT_CALL_SPECIALIST_PROFILE',
    exact: true,
    component: <Profile />,
  },
  {
    path: 'call-specialist/searching-medic',
    key: 'PATIENT_ROOT_CALL_SPECIALIST_SEARCHING_MEDIC',
    exact: true,
    component: <SearchingMedic />,
  },
  {
    path: 'call-specialist/medic-found',
    key: 'PATIENT_ROOT_CALL_SPECIALIST_MEDIC_FOUND',
    exact: true,
    component: <MedicFound />,
  },
  {
    path: 'call-specialist/summary-payment',
    key: 'PATIENT_ROOT_CALL_SPECIALIST_SUMMARY_PAYMENT',
    exact: true,
    component: <SummaryPayment />,
  },
  {
    path: 'call-specialist/waiting-screen',
    key: 'PATIENT_ROOT_CALL_SPECIALIST_WAITING_SCREEN',
    exact: true,
    component: <WaitingScreen />,
  },
];

const MedicalFolderRoutes = [
  {
    path: 'medicalFolder',
    key: 'PATIENT_ROOT_MEDICAL_FOLDER',
    exact: true,
    component: <PatientMedicalFolder />,
  },
  {
    path: 'medicalFolder/select-profile',
    key: 'PATIENT_ROOT_MEDICAL_FOLDER_SELECT_PROFILE',
    exact: true,
    component: <PatientMedicalFolderSelectProfile />,
  },
  {
    path: 'medicalFolder/add-profile',
    key: 'PATIENT_ROOT_MEDICAL_FOLDER_ADD_PROFILE',
    exact: true,
    component: <PatientMedicalFolderAddProfile />,
  },
];

const ConsultationRoutes = [
  {
    path: 'consultations',
    key: 'PATIENT_ROOT_CONSULTATIONS',
    exact: true,
    component: <ConsultationList />,
  },
  {
    path: 'consultations/consultation-details/:appointmentId',
    key: 'PATIENT_ROOT_CONSULTATION_DETAILS',
    exact: true,
    component: <ConsultationDetails />,
  },
];

const PatientPublicRoutes = [...AuthRoutes, ...MedicPublicRoutes];

const PatientPrivateRoutes = [
  ...StaticRoutes,
  ...AppointmentRoutes,
  ...MedicalFolderRoutes,
  ...ConsultationRoutes,
];

const AccountRoutes = [
  {
    path: 'account',
    key: 'PATIENT_ROOT_ACCOUNT',
    exact: true,
    component: <PatientAccount />,
  },
  {
    path: 'notifications',
    key: 'PATIENT_ROOT_NOTIFICATIONS',
    exact: true,
    component: <PatientNotifications />,
  },
];

const PatientRoutes = ({ isLoggedIn, hasFullAccess, maintenanceMode }) => {
  if (maintenanceMode) {
    return (
      <Routes>
        <Route path="*" element={<WorkInProgress />} />
      </Routes>
    );
  }

  return (
    <Routes>
      {PatientPublicRoutes.map((route) => {
        return <Route key={route.key} path={route.path} element={route.component} />;
      })}

      <Route path="/" element={<PrivateRoute isLoggedIn={isLoggedIn} redirectTo="login" />}>
        {AccountRoutes.map((route) => {
          return <Route key={route.key} path={route.path} element={route.component} />;
        })}
      </Route>

      <Route
        path="/"
        element={
          <PrivateRoute
            isLoggedIn={isLoggedIn && hasFullAccess}
            redirectTo={isLoggedIn && !hasFullAccess ? 'account' : 'login'}
          />
        }
      >
        {PatientPrivateRoutes.map((route) => {
          return <Route key={route.key} path={route.path} element={route.component} />;
        })}
      </Route>

      {/* <Route path="*" element={<Navigate replace to="/404" />} /> */}
      {/* <Route path="/404" element={<PageNotFound />} /> */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

PatientRoutes.defaultProps = {
  isLoggedIn: false,
  hasFullAccess: false,
  maintenanceMode: false,
};

PatientRoutes.propTypes = {
  isLoggedIn: PropTypes.bool,
  hasFullAccess: PropTypes.bool,
  maintenanceMode: PropTypes.bool,
};

export { PatientRoutes, PatientMenuRoutesWithFullAccess, PatientMenuRoutesWithoutFullAccess };
