import 'doctor/styles/rates.css';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DoctorAccountRatesBlock from 'doctor/components/account/DoctorAccountRatesBlock';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';

const DoctorRates = () => {
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState('/doctor/home');
  return (
    <section className="rates">
      <a href={redirect} className="back-to-home">
        <CustomIcon className="custom-icon" color={Colors.darkGrey} size="24" icon="Left_arrow" />
        {t('back')}
      </a>
      <div className="small-container">
        <DoctorAccountRatesBlock setRedirect={setRedirect} />
      </div>
    </section>
  );
};

export default DoctorRates;
