import { AnyAction } from 'redux';
import { UPDATE_REMOTE_CONFIG_REQUEST } from 'shared/redux/types/RemoteConfigTypes';
import DataFormatter from 'shared/modules/DataFormatter';

const RemoteConfigReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case UPDATE_REMOTE_CONFIG_REQUEST:
      return { ...state, ...DataFormatter.camelcaseObjectKeys(action.payload) };
    default:
      return state;
  }
};

export default RemoteConfigReducer;
