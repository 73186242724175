import { actionChannel, call, fork, put, take } from 'redux-saga/effects';
import {
  ADD_FAVOURITE_DOCTOR_REQUEST,
  GET_FAVOURITE_DOCTORS_REQUEST,
  REMOVE_FAVOURITE_DOCTOR_REQUEST,
} from 'shared/redux/types/FavouriteDoctorsTypes';
import {
  addFavouriteDoctorError,
  addFavouriteDoctorSuccess,
  getFavouriteDoctorsError,
  getFavouriteDoctorsSuccess,
  removeFavouriteDoctorError,
  removeFavouriteDoctorSuccess,
} from 'shared/redux/actions/FavouriteDoctorsActions';
import {
  addFavouriteDoctorAPI,
  getFavouriteDoctorsAPI,
  removeFavouriteDoctorAPI,
} from 'shared/redux/api/FavouriteDoctorsApi';
import favouriteDoctorSelector from 'shared/redux/selector/FavouriteDoctorsSelector';

function* getFavouriteDoctors(actionType) {
  const getFavouriteDoctorsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(getFavouriteDoctorsChannel);
    try {
      const safeAction = payload || { limit: 5, page: 1 };
      const page = safeAction.page || 1;
      const limit = safeAction.limit || 5;
      const response = yield call(getFavouriteDoctorsAPI, { page, limit });
      yield put(
        getFavouriteDoctorsSuccess({
          ...favouriteDoctorSelector(response.data),
          nextPage: payload.nextPage,
        }),
      );
    } catch ({ message }) {
      yield put(getFavouriteDoctorsError({ message }));
    }
  }
}

function* addFavouriteDoctor(actionType) {
  const addFavouriteDoctorChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(addFavouriteDoctorChannel);
    try {
      yield call(addFavouriteDoctorAPI, payload);
      yield put(addFavouriteDoctorSuccess(payload));
    } catch ({ message }) {
      yield put(addFavouriteDoctorError({ message }));
    }
  }
}

function* removeFavouriteDoctor(actionType) {
  const removeFavouriteDoctorChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(removeFavouriteDoctorChannel);
    try {
      yield call(removeFavouriteDoctorAPI, payload);
      yield put(removeFavouriteDoctorSuccess(payload));
    } catch ({ message }) {
      yield put(removeFavouriteDoctorError({ message }));
    }
  }
}

function* favouriteDoctorsSaga() {
  yield fork(getFavouriteDoctors, GET_FAVOURITE_DOCTORS_REQUEST);
  yield fork(addFavouriteDoctor, ADD_FAVOURITE_DOCTOR_REQUEST);
  yield fork(removeFavouriteDoctor, REMOVE_FAVOURITE_DOCTOR_REQUEST);
}

export default favouriteDoctorsSaga;
