import 'patient/styles/account-assistance.css';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { supportFormRequest } from 'shared/redux/actions/SupportActions';
import { useTranslation } from 'react-i18next';

const PatientAccountAssistanceBlock = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [showModalContact, setModalContactShow] = useState(false);
  const [contactSubject, setContactSubject] = useState('');
  const [contactContent, setContactContent] = useState('');
  const modalContactClose = () => setModalContactShow(false);
  const modalContactShow = () => setModalContactShow(true);
  const handleContactSubjectChange = (e) => setContactSubject(e.target.value);
  const handleContactContentChange = (e) => setContactContent(e.target.value);
  const sendContactRequest = () => {
    dispatch(
      supportFormRequest({
        subject: contactSubject,
        content: contactContent,
      }),
    );
    setContactSubject('');
    setContactContent('');

    modalContactClose();
  };

  return (
    <div className="account-assistance">
      <div className="title-section">
        {/* <h2>{t('settings.support')}</h2> */}
        <h2>{t('usefulInfo')}</h2>
      </div>
      {/* <div className="contact"> */}
      {/*  <div className="subtitle">{t('contact')}</div> */}

      {/*  <button type="button" className="white-button" onClick={modalContactShow}> */}
      {/*    {t('support.supportForm')} */}
      {/*    <CustomIcon */}
      {/*      className="custom-icon" */}
      {/*      color={Colors.darkGrey} */}
      {/*      size="24" */}
      {/*      icon="Right_pointer" */}
      {/*    /> */}
      {/*  </button> */}
      {/* </div> */}
      <div className="information">
        {/* <div className="subtitle">{t('usefulInfo')}</div> */}
        <Link to="/patient/about-us" className="white-button">
          {t('support.aboutUs')}
          <CustomIcon
            className="custom-icon"
            color={Colors.darkGrey}
            size="24"
            icon="Right_pointer"
          />
        </Link>
        <ListGroup>
          <ListGroup.Item>
            <Link to="/support/terms-and-conditions">
              {t('support.terms')}
              <CustomIcon
                className="custom-icon"
                color={Colors.darkGrey}
                size="24"
                icon="Right_pointer"
              />
            </Link>
          </ListGroup.Item>
          <ListGroup.Item>
            <Link to="/support/privacy-policy">
              {t('support.policy')}
              <CustomIcon
                className="custom-icon"
                color={Colors.darkGrey}
                size="24"
                icon="Right_pointer"
              />
            </Link>
          </ListGroup.Item>
          <ListGroup.Item>
            <Link to="/support/gdpr">
              {t('gdprTitle')}
              <CustomIcon
                className="custom-icon"
                color={Colors.darkGrey}
                size="24"
                icon="Right_pointer"
              />
            </Link>
          </ListGroup.Item>
        </ListGroup>
      </div>

      <Modal
        show={showModalContact}
        onHide={modalContactClose}
        animation
        centered
        className="modal-contact"
      >
        <Modal.Header closeButton closeLabel={t('close')}>
          <Modal.Title>{t('contact')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="info-message">
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="24"
              icon="Warning_2"
            />
            {t('contactText')}
          </div>
          <form>
            <div className="form-input">
              <input
                autoComplete="off"
                id="subject"
                name="subject"
                type="text"
                className="form-control"
                placeholder=" "
                value={contactSubject}
                onChange={handleContactSubjectChange}
              />
              <label htmlFor="subject">{t('support.subject')}</label>
            </div>
            <div className="form-textarea">
              <textarea
                id="message"
                name="message"
                className="form-control"
                placeholder=" "
                rows={5}
                value={contactContent}
                onChange={handleContactContentChange}
              />
              <label htmlFor="message">{t('support.message')}</label>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="orange-button" onClick={sendContactRequest}>
            {t('send')}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PatientAccountAssistanceBlock;
