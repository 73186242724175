import { default as ApiConstants } from 'shared/constants/ApiConstants.json';
import ApiUtils from 'shared/modules/ApiUtils';
import StripeService from 'shared/services/StripeService';

const getPaymentMethodsAPI = () => ApiUtils.getRequest(ApiConstants.GET_PAYMENT_METHOD_ENDPOINT);

const initializeStripePaymentApi = async (payload) =>
  StripeService.initializeStripePayment(payload);

const confirmStripePaymentWithTokenApi = async (payload) =>
  StripeService.confirmPaymentIntentWithToken(payload);

const confirmStripePaymentWithPaymentIdApi = async (payload) =>
  StripeService.confirmPaymentIntentWithPaymentId(payload);

const createPaymentMethodPI = (payload) => StripeService.createPaymentMethod(payload);

const removePaymentMethodPI = (payload) =>
  ApiUtils.deleteRequest(ApiConstants.PAYMENT_CUSTOMER_REMOVE_METHOD_ENDPOINT, payload);

const attachPaymentMethodToCustomerPI = (payload) =>
  ApiUtils.postRequest(ApiConstants.PAYMENT_CUSTOMER_ADD_METHOD_ENDPOINT, payload);

const appointmentPaymentAPI = (payload) =>
  ApiUtils.postRequest(ApiConstants.PAYMENT_INTENT_ENDPOINT, payload);

const getPatientTransactionsAPI = ({ page, limit }) =>
  ApiUtils.getRequest(`${ApiConstants.PAYMENT_TRANSACTIONS_ENDPOINT}?page=${page}&limit=${limit}`);

export {
  getPaymentMethodsAPI,
  initializeStripePaymentApi,
  createPaymentMethodPI,
  confirmStripePaymentWithTokenApi,
  confirmStripePaymentWithPaymentIdApi,
  appointmentPaymentAPI,
  attachPaymentMethodToCustomerPI,
  removePaymentMethodPI,
  getPatientTransactionsAPI,
};
