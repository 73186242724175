import 'patient/styles/share-social.css';
import React, { useState } from 'react';
import CustomIcon from 'shared/components/CustomIcon';
import { Modal } from 'react-bootstrap';
import Colors from 'shared/themes/Colors';
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  ViberIcon,
  WhatsappIcon,
} from 'react-share';
import { useTranslation } from 'react-i18next';

const Referral = ({ invitationCode }) => {
  const { t } = useTranslation();
  const [socialModalDisplayed, setSocialModalDisplayed] = useState(false);
  const showSocialModal = () => setSocialModalDisplayed(true);
  const hideModal = () => setSocialModalDisplayed(false);
  const url = `http://bit.ly/ringdocpacienti`;
  const iconsSize = 48;

  const invitationText = `${t('sharePersonalCodeTextWeb', {
    personalCode: invitationCode,
  })}`;
  const invitationTitle = `${t('recommendApp')}`;
  const emailUrl = `${t('sharePersonalCodeTextWeb', {
    personalCode: invitationCode,
  })} ${url}`;

  return (
    <>
      <button type="button" className="orange-button mt-4" onClick={showSocialModal}>
        {t('referralInviteAFriendTitle')}
      </button>

      <Modal
        show={socialModalDisplayed}
        onHide={hideModal}
        animation
        centered
        className="modal-share-social"
      >
        <Modal.Header closeButton closeLabel={t('close')}>
          <Modal.Title>{t('share')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="medic-box">
            <div className="info">
              <div className="name">{invitationCode}</div>
            </div>
          </div>

          <div className="link-text">{url}</div>

          <button
            type="button"
            className="white-button"
            onClick={() => navigator.clipboard.writeText(invitationText)}
          >
            {t('copyLink')}
          </button>
        </Modal.Body>

        <Modal.Footer>
          <div className="social-list">
            <FacebookShareButton quote={invitationText} url={url} onShareWindowClose={hideModal}>
              <FacebookIcon size={iconsSize} />
            </FacebookShareButton>
            <TwitterShareButton title={invitationText} url={url} onShareWindowClose={hideModal}>
              <TwitterIcon size={iconsSize} />
            </TwitterShareButton>
            <FacebookMessengerShareButton
              title={invitationText}
              redirectUri={url}
              url={url}
              onShareWindowClose={hideModal}
              appId="380344953549489"
            >
              <FacebookMessengerIcon size={iconsSize} />
            </FacebookMessengerShareButton>
            <LinkedinShareButton title={invitationText} url={url} onShareWindowClose={hideModal}>
              <LinkedinIcon size={iconsSize} />
            </LinkedinShareButton>
            <ViberShareButton title={invitationText} url={url} onShareWindowClose={hideModal}>
              <ViberIcon size={iconsSize} />
            </ViberShareButton>
            <TelegramShareButton title={invitationText} url={url} onShareWindowClose={hideModal}>
              <TelegramIcon size={iconsSize} />
            </TelegramShareButton>
            <WhatsappShareButton title={invitationText} url={url} onShareWindowClose={hideModal}>
              <WhatsappIcon size={iconsSize} />
            </WhatsappShareButton>
            <EmailShareButton
              subject={invitationTitle}
              url={emailUrl}
              onShareWindowClose={hideModal}
            >
              <EmailIcon size={iconsSize} />
            </EmailShareButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Referral;
