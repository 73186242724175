import 'doctor/styles/mlm-recommend.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  getMlmAcceptedInvitationsState,
  getMlmSentInvitationsState,
  getUserProfileState,
} from 'shared/redux/src/StatesGetter';
import {
  fetchReferralsRequest,
  fetchSentReferralsRequest,
  sendReferralInvitationRequest,
} from 'shared/redux/actions/ReferralsActions';
import 'react-phone-number-input/style.css';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import Utils from 'shared/modules/Utils';
import { Accordion } from 'react-bootstrap';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import dayjs from 'dayjs';
import * as DOMPurify from 'dompurify';
import Alerts from '../../../shared/components/Alerts';

const DoctorRecommendTabBlock = ({ activeTab }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    data: acceptedInvitationsData,
    currentPage,
    noOfPages,
    total = 0,
  } = useSelector(getMlmAcceptedInvitationsState, shallowEqual);
  const {
    data: sentInvitationsData,
    currentPage: currentPageSent,
    noOfPages: noOfPagesSent,
    total: totalSent = 0,
  } = useSelector(getMlmSentInvitationsState, shallowEqual);
  const profileData = useSelector(getUserProfileState, shallowEqual);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [contactConsent, setContactConsent] = useState(false);
  const phoneNumberRef = useRef(null);
  const [disabledButton, setDisabledButton] = useState(false);
  const [requiredFieldsError, setRequiredFieldsError] = useState(false);
  const sanitizer = DOMPurify.sanitize;

  const loadNextPage = () => {
    if (currentPage <= noOfPages) {
      dispatch(
        fetchReferralsRequest({
          page: currentPage,
          nextPage: true,
          type: activeTab,
          connected: 'yes',
        }),
      );
    }
  };

  const loadNextPageSent = () => {
    if (currentPageSent <= noOfPagesSent) {
      dispatch(
        fetchSentReferralsRequest({
          page: currentPageSent,
          nextPage: true,
          type: activeTab,
          connected: 'no',
        }),
      );
    }
  };

  useEffect(() => {
    setPhoneNumber('');
    dispatch(fetchReferralsRequest({ type: activeTab, connected: 'yes' }));
  }, [activeTab]);

  useEffect(() => {
    setPhoneNumber('');
    dispatch(fetchSentReferralsRequest({ type: activeTab, connected: 'no' }));
  }, [activeTab]);

  const handleSendPhoneNumber = (e) => {
    e.preventDefault();
    if (phoneNumber === '') {
      Alerts.simpleAlert(t('error'), t('allFieldsMandatory'));
      setRequiredFieldsError(true);
      return;
    }
    if (profileData.isPharmacist && !contactConsent) {
      Alerts.simpleAlert(t('error'), t('mlm.contactConsent'));
      return;
    }

    if (phoneNumber !== '') {
      setDisabledButton(true);
      const parsedPhone = phoneNumber && Utils.withoutMetadata(parsePhoneNumber(phoneNumber));
      const getNumberAfterPossiblyEliminatingZero = Utils.getNumberAfterPossiblyEliminatingZero(
        parsedPhone?.nationalNumber,
        parsedPhone?.countryCallingCode,
      );

      if (profileData.isPharmacist) {
        dispatch(
          sendReferralInvitationRequest({
            phoneNumber: getNumberAfterPossiblyEliminatingZero?.formattedNumber,
            contactConsent,
            type: activeTab,
          }),
        );
      } else {
        dispatch(
          sendReferralInvitationRequest({
            phoneNumber: getNumberAfterPossiblyEliminatingZero?.formattedNumber,
            type: activeTab,
          }),
        );
      }

      setTimeout(() => {
        setDisabledButton(false);
        setPhoneNumber('');
      }, 1000);
    }
  };
  let user = '';
  if (activeTab === 'patient') {
    user = t('mlm.patient');
  } else {
    user = t('mlm.doctor');
  }

  const goodDate = (item) => item.invitationDate;

  return (
    <div className="row">
      <div className="col-lg-6 col-xl-6 col-md-6 col-12">
        <div className="box-invite">
          <form onSubmit={handleSendPhoneNumber}>
            <h2>{t(activeTab === 'patient' ? 'mlm.invitePatient' : 'mlm.inviteMedic')}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: sanitizer(
                  t('mlm.inviteTextWeb', {
                    user: `<span class="trans-placeholder">${user}</span>`,
                  }),
                ),
              }}
            />
            <div className="phone-number-with-prefix">
              <PhoneInput
                className={phoneNumber && 'has-value'}
                placeholder=" "
                value={phoneNumber}
                defaultCountry="RO"
                id="phoneNumber"
                name="phoneNumber"
                /* @ts-ignore */
                onChange={(value) => setPhoneNumber(value)}
                ref={phoneNumberRef}
              />
              <label htmlFor="phoneNumber">{t('phoneNumber')}</label>
            </div>
            {profileData.isPharmacist && (
              <div className="align-self-start custom-control custom-checkbox orange-checkbox align-center-checkbox left-justify-content mb-20">
                <input
                  className={`custom-control-input ${requiredFieldsError && 'is-invalid'}`}
                  id="terms_agree"
                  type="checkbox"
                  onChange={() => setContactConsent(!contactConsent)}
                />
                <label
                  htmlFor="terms_agree"
                  className={`custom-control-label is-pharmacist-agreement-label ${
                    requiredFieldsError && 'is-invalid'
                  }`}
                >
                  {t('contactConsent')}
                </label>
              </div>
            )}
            <button
              type="submit"
              className={activeTab === 'patient' ? 'orange-button' : 'green-button'}
              disabled={disabledButton}
            >
              {t('mlm.sendInvitation')}
            </button>
          </form>
        </div>
      </div>
      <div className="col-lg-6 col-xl-6 col-md-6 col-12">
        <Accordion className="medical-records-accordion" alwaysOpen>
          <Accordion.Item
            eventKey="acceptate"
            className={`${acceptedInvitationsData?.length > 0 ? '' : 'no-arrow'}`}
          >
            <Accordion.Header>
              {t('mlm.invitationsAccepted')}
              <div className="counter-badge">{total}</div>
            </Accordion.Header>
            <Accordion.Body>
              {acceptedInvitationsData?.length > 0 &&
                acceptedInvitationsData?.map((referral) => {
                  return (
                    <div key={_.uniqueId()} className="referral-invitation">
                      <CustomIcon
                        className={`custom-icon ${
                          activeTab === 'patient' ? 'font-orange' : 'font-green'
                        }`}
                        color={activeTab === 'patient' ? Colors.patientColor : Colors.medicColor}
                        size="24"
                        icon="Check_round"
                      />
                      <div className="info">
                        <div className="name">{referral.name}</div>
                        <div className="data">
                          {dayjs(goodDate(referral)).format('DD MMM YYYY')}
                        </div>
                      </div>
                    </div>
                  );
                })}
              {currentPage <= noOfPages && (
                <div className="box-invitation">
                  <button
                    type="button"
                    onClick={loadNextPage}
                    className={`white-button ${
                      activeTab === 'patient' ? 'white-button-orange' : 'white-button-green'
                    }`}
                  >
                    {t('mlm.showMore')}
                  </button>
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="trimise"
            className={`${sentInvitationsData?.length > 0 ? '' : 'no-arrow'}`}
          >
            <Accordion.Header>
              {t('mlm.invitationsSent')}
              <div className="counter-badge">{totalSent}</div>
            </Accordion.Header>
            <Accordion.Body>
              {sentInvitationsData?.length > 0 &&
                sentInvitationsData?.map((referral) => {
                  return (
                    <div key={_.uniqueId()} className="referral-invitation">
                      <div className="info">
                        <div className="name">{referral.name}</div>
                        <div className="data">
                          {dayjs(goodDate(referral)).format('DD MMM YYYY')}
                        </div>
                      </div>
                    </div>
                  );
                })}
              {currentPageSent <= noOfPagesSent && (
                <div className="box-invitation">
                  <button
                    type="button"
                    onClick={loadNextPageSent}
                    className={`white-button ${
                      activeTab === 'patient' ? 'white-button-orange' : 'white-button-green'
                    }`}
                  >
                    {t('mlm.showMore')}
                  </button>
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};
DoctorRecommendTabBlock.defaultProps = {
  match: {},
  location: {},
};
DoctorRecommendTabBlock.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({}),
  }),
};
export default DoctorRecommendTabBlock;
