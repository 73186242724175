import 'doctor/styles/notifications.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getPushesState } from 'shared/redux/src/StatesGetter';
import Utils from 'shared/modules/Utils';
import { fetchPushesRequest } from 'shared/redux/actions/NotificationActions';
import DoctorNotificationBlock from 'doctor/components/notifications/DoctorNotificationBlock';
import InfiniteScroll from 'react-infinite-scroll-component';
import dayjs from 'dayjs';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';

const DoctorNotifications = ({ location }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: pushes, currentPage, noOfPages, total } = useSelector(getPushesState, shallowEqual);
  const [formattedPushes, setFormattedPushed] = useState(
    Utils.makeSectionListFormat(pushes, 'createdAtFormatted'),
  );

  const loadNextPagePushes = () => {
    if (currentPage <= noOfPages) {
      dispatch(
        fetchPushesRequest({
          page: currentPage,
          nextPage: true,
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(fetchPushesRequest({}));
  }, []);

  useEffect(() => {
    setFormattedPushed(Utils.makeSectionListFormat(pushes, 'createdAtFormatted'));
  }, [pushes]);

  return (
    <section className="notifications position-relative">
      <div className="title-section">
        <h1>{total > 0 ? `${t('notifications')} • ${total}` : t('notifications')}</h1>
      </div>

      {!formattedPushes.length && <div className="empty-container">{t('noNotifications')}</div>}

      <InfiniteScroll
        dataLength={pushes.length}
        next={loadNextPagePushes}
        hasMore={currentPage <= noOfPages}
        loader={<LoadingSpinner isLoading />}
      >
        {formattedPushes.map((dayList) => {
          return dayList.key === dayjs().format('YYYY-MM-DD') ? (
            <div className="recent-notifications" key={dayList.key}>
              <div className="subtitle">{t('recent')}</div>
              {dayList.data.map((pushNotification) => {
                return (
                  <DoctorNotificationBlock key={pushNotification.id} item={pushNotification} />
                );
              })}
            </div>
          ) : (
            <div className="old-notifications" key={dayList.key}>
              <div className="subtitle">
                {t('old')} • {dayList.key}
              </div>
              {dayList.data.map((pushNotification) => {
                return (
                  <DoctorNotificationBlock key={pushNotification.id} item={pushNotification} />
                );
              })}
            </div>
          );
        })}
      </InfiniteScroll>
    </section>
  );
};
DoctorNotifications.defaultProps = {
  match: {},
  location: {},
};
DoctorNotifications.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({}),
  }),
};
export default DoctorNotifications;
