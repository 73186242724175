import { actionChannel, ActionPattern, call, fork, put, take } from 'redux-saga/effects';
import { Action } from 'redux';
import { getCurrencyAPI } from 'shared/redux/api/CurrencyApi';
import { fetchCurrencyError, fetchCurrencySuccess } from 'shared/redux/actions/CurrencyActions';
import { FETCH_CURRENCY_REQUEST } from 'shared/redux/types/CurrencyTypes';
import currencySelector from 'shared/redux/selector/CurrencySelector';

function* getCurrency(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const currencyChannel = yield actionChannel(actionType);
  while (true) {
    yield take(currencyChannel);
    try {
      // @ts-ignore
      const response = yield call(getCurrencyAPI);
      yield put(fetchCurrencySuccess(currencySelector(response.data)));
    } catch ({ message }) {
      yield put(fetchCurrencyError({ message }));
    }
  }
}

function* currencySaga() {
  yield fork(getCurrency, FETCH_CURRENCY_REQUEST);
}

export default currencySaga;
