import i18next from 'i18next';

const countriesSelector = (countries: any[]) => {
  return countries?.map((row) => {
    return {
      id: row?.id,
      codeAlpha2: row.code_alpha2,
      codeAlpha3: row.code_alpha3,
      codeNumeric: row.code_numeric,
      callingCode: row.calling_code.replace('00', '+'),
      name: row.translations.find((r) => r.locale === i18next.language)?.name ?? '',
    };
  });
};

export default countriesSelector;
