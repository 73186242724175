import { actionChannel, ActionPattern, call, fork, put, take } from 'redux-saga/effects';
import { Action } from 'redux';
import { FETCH_COUNTRIES_REQUEST } from 'shared/redux/types/CountryTypes';
import { getCountriesAPI } from 'shared/redux/api/CountryApi';
import { fetchCountriesError, fetchCountriesSuccess } from 'shared/redux/actions/CountryActions';
import countriesSelector from 'shared/redux/selector/CountrySelector';

function* getCountries(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const countriesChannel = yield actionChannel(actionType);
  while (true) {
    yield take(countriesChannel);
    try {
      // @ts-ignore
      const response = yield call(getCountriesAPI);
      yield put(fetchCountriesSuccess(countriesSelector(response.data)));
    } catch ({ message }) {
      yield put(fetchCountriesError({ message }));
    }
  }
}

function* countriesSaga() {
  yield fork(getCountries, FETCH_COUNTRIES_REQUEST);
}

export default countriesSaga;
