import i18next from 'i18next';

export const resolveDoctorSpecializations = (specializations) => {
  return specializations
    ?.filter((row) => row.is_validated && row.specialization?.is_visible)
    ?.map((specialization) => {
      return {
        id: specialization?.id,
        price: specialization.price_as_string,
        priceAsString: specialization.price_as_string,
        parentId: specialization.specialization?.id,
        name:
          specialization.specialization.translations.find((row) => row.locale === i18next.language)
            ?.name ?? '',
        description:
          specialization.specialization.translations.find((row) => row.locale === i18next.language)
            ?.description ?? '',
        certificationsPortfolio: specialization.certifications_portfolio,
        isValidated: specialization.is_validated,
        isAvailable: specialization.is_available,
        duration: specialization.duration,
        // activeDoctorServices: specialization.active_doctor_services,
        doctorServices: specialization.doctor_services,
      };
    });
};

export const resolveUserLanguages = (userLanguages) => {
  return userLanguages.map((language) => {
    return {
      locale: language.current_locale,
    };
  });
};
