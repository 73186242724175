import { addAppointmentPrescriptionMedicationRequest } from '../actions/DoctorAppointmentsActions';

export const FETCH_DOCTOR_PAST_APPOINTMENTS_REQUEST = 'FETCH_DOCTOR_PAST_APPOINTMENTS_REQUEST';
export const FETCH_DOCTOR_PAST_APPOINTMENTS_SUCCESS = 'FETCH_DOCTOR_PAST_APPOINTMENTS_SUCCESS';
export const FETCH_DOCTOR_PAST_APPOINTMENTS_ERROR = 'FETCH_DOCTOR_PAST_APPOINTMENTS_ERROR';

export const FETCH_DOCTOR_UPCOMING_APPOINTMENTS_REQUEST =
  'FETCH_DOCTOR_UPCOMING_APPOINTMENTS_REQUEST';
export const FETCH_DOCTOR_UPCOMING_APPOINTMENTS_SUCCESS =
  'FETCH_DOCTOR_UPCOMING_APPOINTMENTS_SUCCESS';
export const FETCH_DOCTOR_UPCOMING_APPOINTMENTS_ERROR = 'FETCH_DOCTOR_UPCOMING_APPOINTMENTS_ERROR';

export const FETCH_DOCTOR_TODAY_APPOINTMENTS_REQUEST = 'FETCH_DOCTOR_TODAY_APPOINTMENTS_REQUEST';
export const FETCH_DOCTOR_TODAY_APPOINTMENTS_SUCCESS = 'FETCH_DOCTOR_TODAY_APPOINTMENTS_SUCCESS';
export const FETCH_DOCTOR_TODAY_APPOINTMENTS_ERROR = 'FETCH_DOCTOR_TODAY_APPOINTMENTS_ERROR';

export const GET_APPOINTMENT_DETAILS_REQUEST = 'GET_APPOINTMENT_DETAILS_REQUEST';
export const GET_APPOINTMENT_DETAILS_SUCCESS = 'GET_APPOINTMENT_DETAILS_SUCCESS';
export const GET_APPOINTMENT_DETAILS_ERROR = 'GET_APPOINTMENT_DETAILS_ERROR';

export const DOCTOR_ACCEPTS_CALL_REQUEST = 'DOCTOR_ACCEPTS_CALL_REQUEST';
export const DOCTOR_ACCEPTS_CALL_SUCCESS = 'DOCTOR_ACCEPTS_CALL_SUCCESS';
export const DOCTOR_ACCEPTS_CALL_ERROR = 'DOCTOR_ACCEPTS_CALL_ERROR';

export const DOCTOR_REJECTS_CALL_REQUEST = 'DOCTOR_REJECTS_CALL_REQUEST';
export const DOCTOR_REJECTS_CALL_SUCCESS = 'DOCTOR_REJECTS_CALL_SUCCESS';
export const DOCTOR_REJECTS_CALL_ERROR = 'DOCTOR_REJECTS_CALL_ERROR';

export const ADD_APPOINTMENT_CONCLUSION_REQUEST = 'ADD_APPOINTMENT_CONCLUSION_REQUEST';
export const ADD_APPOINTMENT_CONCLUSION_SUCCESS = 'ADD_APPOINTMENT_CONCLUSION_SUCCESS';
export const ADD_APPOINTMENT_CONCLUSION_ERROR = 'ADD_APPOINTMENT_CONCLUSION_ERROR';

export const ADD_APPOINTMENT_PRESCRIPTION_REQUEST = 'ADD_APPOINTMENT_PRESCRIPTION_REQUEST';
export const ADD_APPOINTMENT_PRESCRIPTION_SUCCESS = 'ADD_APPOINTMENT_PRESCRIPTION_SUCCESS';
export const ADD_APPOINTMENT_PRESCRIPTION_ERROR = 'ADD_APPOINTMENT_PRESCRIPTION_ERROR';

export const ADD_APPOINTMENT_PRESCRIPTION_MEDICATION_REQUEST =
  'ADD_APPOINTMENT_PRESCRIPTION_MEDICATION_REQUEST';
export const ADD_APPOINTMENT_PRESCRIPTION_MEDICATION_SUCCESS =
  'ADD_APPOINTMENT_PRESCRIPTION_MEDICATION_SUCCESS';
export const ADD_APPOINTMENT_PRESCRIPTION_MEDICATION_ERROR =
  'ADD_APPOINTMENT_PRESCRIPTION_MEDICATION_ERROR';

export const UPDATE_APPOINTMENT_PRESCRIPTION_MEDICATION_REQUEST =
  'UPDATE_APPOINTMENT_PRESCRIPTION_MEDICATION_REQUEST';
export const UPDATE_APPOINTMENT_PRESCRIPTION_MEDICATION_SUCCESS =
  'UPDATE_APPOINTMENT_PRESCRIPTION_MEDICATION_SUCCESS';
export const UPDATE_APPOINTMENT_PRESCRIPTION_MEDICATION_ERROR =
  'UPDATE_APPOINTMENT_PRESCRIPTION_MEDICATION_ERROR';

export const REMOVE_APPOINTMENT_PRESCRIPTION_MEDICATION_REQUEST =
  'REMOVE_APPOINTMENT_PRESCRIPTION_MEDICATION_REQUEST';
export const REMOVE_APPOINTMENT_PRESCRIPTION_MEDICATION_SUCCESS =
  'REMOVE_APPOINTMENT_PRESCRIPTION_MEDICATION_SUCCESS';
export const REMOVE_APPOINTMENT_PRESCRIPTION_MEDICATION_ERROR =
  'REMOVE_APPOINTMENT_PRESCRIPTION_MEDICATION_ERROR';

export const REMOVE_APPOINTMENT_PRESCRIPTION_REQUEST = 'REMOVE_APPOINTMENT_PRESCRIPTION_REQUEST';
export const REMOVE_APPOINTMENT_PRESCRIPTION_SUCCESS = 'REMOVE_APPOINTMENT_PRESCRIPTION_SUCCESS';
export const REMOVE_APPOINTMENT_PRESCRIPTION_ERROR = 'REMOVE_APPOINTMENT_PRESCRIPTION_ERROR';

export const CANCEL_APPOINTMENT_BY_DOCTOR_REQUEST = 'CANCEL_APPOINTMENT_BY_DOCTOR_REQUEST';
export const CANCEL_APPOINTMENT_BY_DOCTOR_SUCCESS = 'CANCEL_APPOINTMENT_BY_DOCTOR_SUCCESS';
export const CANCEL_APPOINTMENT_BY_DOCTOR_ERROR = 'CANCEL_APPOINTMENT_BY_DOCTOR_ERROR';

export const CANCEL_ALL_APPOINTMENTS_BY_DOCTOR_REQUEST =
  'CANCEL_ALL_APPOINTMENTS_BY_DOCTOR_REQUEST';
export const CANCEL_ALL_APPOINTMENTS_BY_DOCTOR_SUCCESS =
  'CANCEL_ALL_APPOINTMENTS_BY_DOCTOR_SUCCESS';
export const CANCEL_ALL_APPOINTMENTS_BY_DOCTOR_ERROR = 'CANCEL_ALL_APPOINTMENTS_BY_DOCTOR_ERROR';

export const DOCTOR_APPOINTMENT_UPDATES_REQUEST = 'DOCTOR_APPOINTMENT_UPDATES_REQUEST';

export const ACCEPTED_DOCTOR_CALLS_STACK_REQUEST = 'ACCEPTED_DOCTOR_CALLS_STACK_REQUEST';
export const UPDATE_DOCTOR_CALLS_STACK_REQUEST = 'UPDATE_DOCTOR_CALLS_STACK_REQUEST';
export const REMOVE_DOCTOR_CALLS_STACK_REQUEST = 'REMOVE_DOCTOR_CALLS_STACK_REQUEST';

export const GET_CALL_SPECIALIST_INFO_REQUEST = 'GET_CALL_SPECIALIST_INFO_REQUEST';
export const GET_CALL_SPECIALIST_INFO_SUCCESS = 'GET_CALL_SPECIALIST_INFO_SUCCESS';
export const GET_CALL_SPECIALIST_INFO_ERROR = 'GET_CALL_SPECIALIST_INFO_ERROR';
