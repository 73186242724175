import 'doctor/styles/auth.css';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Alerts from 'shared/components/Alerts';
import {
  confirmEmailRequest,
  requestLogout,
  resendTokenRequest,
} from 'shared/redux/actions/AuthActions';
import { useNavigate } from 'react-router-dom';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { useQuery } from 'shared/modules/Hook';

const AccountActivation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const email = query.get('email')?.trim()?.replace(' ', '+') ?? '';
  const token = query.get('token')?.trim() ?? '';
  const [newToken, setNewToken] = useState('');
  const [resendCode, setResendCode] = useState(true);
  const resendToken = () => {
    setResendCode(!resendCode);
    dispatch(resendTokenRequest({}));
  };
  const activateAccount = (e) => {
    e.preventDefault();
    if (!token) {
      Alerts.simpleAlert(`${t('error')}`, `${t('allFieldsMandatory')}`);
      return;
    }
    dispatch(confirmEmailRequest({ token: newToken, email }));
  };
  const onLogOut = (e) => {
    e.preventDefault();
    dispatch(requestLogout({}));
    navigate('/doctor/login');
  };

  useEffect(() => {
    if (email.length && token.length) {
      dispatch(confirmEmailRequest({ token, email }));
    }
  }, [email, token]);

  return (
    <section className="activation-account">
      <form onSubmit={activateAccount}>
        <div className="title-section">
          <h1>{t('activationAccount')}</h1>
        </div>
        <div className="info-message">
          <CustomIcon
            className="custom-icon"
            color={Colors.mediumGrey}
            size="24"
            icon="Warning_2"
          />
          {t('tokenText')}
        </div>
        <div className="form-input">
          <input
            type="text"
            id="token"
            name="token"
            className="form-control"
            defaultValue={token}
            onChange={(event) => setNewToken(event.target.value)}
            placeholder=" "
          />
          <label htmlFor="token">{t('token')}</label>
        </div>

        {!resendCode && <div className="resend-code-text">{t('resendCode')}</div>}

        <button type="button" className="white-button" onClick={resendToken}>
          {t('didNotGetToken')}
        </button>

        <button type="submit" className="green-button mb-3">
          {t('activationAccount')}
        </button>

        <div className="cancel-button" onClick={onLogOut}>
          <button type="button">{t('abort')}</button>
        </div>
      </form>
    </section>
  );
};

export default AccountActivation;
