import { AnyAction } from 'redux';
import { FETCH_MY_REVIEWS_SUCCESS } from 'shared/redux/types/MyReviewsTypes';

const MyReviewsReducer = (
  state = { data: [], currentPage: 1, noOfPages: undefined },
  action: AnyAction,
) => {
  switch (action.type) {
    case FETCH_MY_REVIEWS_SUCCESS:
      return {
        data: action.payload.data,
        noOfPages: action.payload.noOfPages,
        currentPage: action.payload.page,
      };
    default:
      return state;
  }
};

export default MyReviewsReducer;
