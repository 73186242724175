import 'patient/styles/medical-folder.css';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { getPrivateMediaFileRequest } from 'shared/redux/actions/UngroupedActions';
import InfiniteScroll from 'react-infinite-scroll-component';
import { fetchRecommendationsRequest } from 'shared/redux/actions/RecommendationsActions';
import { getAuthState, getRecommendationsState } from 'shared/redux/src/StatesGetter';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import { useTranslation } from 'react-i18next';

const PatientMedicalFolderTabRecommendation = ({ userId }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const {
    data: recommendationsData,
    currentPage,
    noOfPages,
  } = useSelector(getRecommendationsState, shallowEqual);
  const openMedicalRecordFile = (externalId) => dispatch(getPrivateMediaFileRequest(externalId));
  const loadNextPageRecommendations = () => {
    if (currentPage <= noOfPages) {
      dispatch(
        fetchRecommendationsRequest({
          id: userId,
          page: currentPage,
          nextPage: true,
        }),
      );
    }
  };

  useEffect(() => {
    if (userId) {
      dispatch(
        fetchRecommendationsRequest({
          id: userId,
        }),
      );
    }
  }, []);

  return (
    <div className="small-container recommendation-tab">
      {recommendationsData && (
        <InfiniteScroll
          dataLength={recommendationsData.length}
          next={loadNextPageRecommendations}
          hasMore={currentPage <= noOfPages}
          loader={<LoadingSpinner isLoading />}
        >
          {!recommendationsData.length && (
            <div className="empty-container">{t('noConclusions')}</div>
          )}

          {recommendationsData.map((recommendation) => {
            return (
              <div className="box" key={recommendation.id}>
                <div
                  className="avatar"
                  style={{ backgroundImage: `url(${recommendation.doctorImageUrl})` }}
                />
                <div className="details">
                  <div className="fullName">{recommendation.doctorFullName}</div>
                  <div className="data">{recommendation.createdAt}</div>
                  <div className="specialization">
                    {recommendation.specializationTranslations[i18n.language]}
                  </div>
                  <div className="item">
                    <div className="title">{t('symptoms')}</div>
                    <div className="description">{recommendation.symptoms}</div>
                  </div>
                  <div className="item">
                    <div className="title">{t('diagnosis')}</div>
                    <div className="description">{recommendation.diagnosis}</div>
                  </div>
                  <div className="item">
                    <div className="title">{t('knownAllergies')}</div>
                    <div className="description">{recommendation.knownAllergies}</div>
                  </div>
                  <div className="item">
                    <div className="title">{t('ongoingTreatments')}</div>
                    <div className="description">{recommendation.ongoingTreatments}</div>
                  </div>
                  <div className="item">
                    <div className="title">{t('treatment')}</div>
                    <div className="description">{recommendation.treatment}</div>
                  </div>
                  <div className="item">
                    <div className="title">{t('recommendation')}</div>
                    <div className="description">{recommendation.recommendations}</div>
                  </div>
                  {recommendation.prescriptionsPortfolio && (
                    <div className="item">
                      <div className="title">{t('doctorApp.documents')}</div>

                      {recommendation.prescriptionsPortfolio.media_portfolios.map(
                        (mediaPortfolio) => {
                          return (
                            <div
                              className="document cursor-pointer"
                              key={mediaPortfolio.id}
                              onClick={() => openMedicalRecordFile(mediaPortfolio.media.id)}
                            >
                              <CustomIcon
                                className="custom-icon document"
                                color={Colors.darkGrey}
                                size="24"
                                icon="Document"
                              />
                              <div className="info">
                                <div className="name">
                                  {mediaPortfolio.media.original_file_name}
                                </div>
                                <div className="data">{recommendation.createdAt}</div>
                              </div>
                              <CustomIcon
                                className="custom-icon arrow"
                                color={Colors.darkGrey}
                                size="24"
                                icon="Right_pointer"
                              />
                            </div>
                          );
                        },
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </InfiniteScroll>
      )}
    </div>
  );
};

export default PatientMedicalFolderTabRecommendation;
