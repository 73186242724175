import dayjs from 'dayjs';

const doctorReviewsSelector = (reviews) => {
  // eslint-disable-next-line no-underscore-dangle
  const reviewsArray = reviews._embedded?.items ?? [];
  const data: Array<Record<string, unknown>> = [];

  // eslint-disable-next-line array-callback-return
  reviewsArray.map((row) => {
    data.push({
      id: row?.id,
      rating: row.rating,
      remarks: row.remarks,
      createdAt: dayjs(row.created_at).format('YYYY-MM-DD'),
      originator: row.anonymized_name,
    });
  });
  const { pages } = reviews;
  return { noOfPages: pages, data };
};

export default doctorReviewsSelector;
