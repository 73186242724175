import { AnyAction } from 'redux';
import {
  FETCH_SETTINGS_REQUEST,
  FETCH_SETTINGS_SUCCESS,
  FETCH_SETTINGS_ERROR,
} from '../types/SettingsTypes';

export const fetchSettingsRequest = (payload): AnyAction => ({
  type: FETCH_SETTINGS_REQUEST,
  payload,
});

export const fetchSettingsSuccess = (payload): AnyAction => ({
  type: FETCH_SETTINGS_SUCCESS,
  payload,
});

export const fetchSettingsError = (payload): AnyAction => ({
  type: FETCH_SETTINGS_ERROR,
  payload,
});
