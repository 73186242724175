import 'shared/styles/static-pages.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getGDPRState } from 'shared/redux/src/StatesGetter';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { getGDPRRequest } from 'shared/redux/actions/StaticPagesActions';
import { useTranslation } from 'react-i18next';
import HeaderMenuMobile from 'shared/components/HeaderMenuMobile';
import HeaderMenu from 'shared/components/HeaderMenu';
import FooterSection from 'shared/components/FooterSection';

const SettingsGDPR = () => {
  const { t } = useTranslation();
  const devRef = useRef<HTMLDivElement>(null);
  const { name, content } = useSelector(getGDPRState, shallowEqual);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getGDPRRequest({}));
    if (devRef.current) devRef.current.innerHTML = content;
  }, [content]);
  return (
    <div className="terms-page">
      <Helmet>
        <title>GDPR</title>
        <meta
          name="description"
          content="Completeaza formularul si cere detalii despre Ringdoc si modul de functionare al aplicatiei chiar acum"
        />
      </Helmet>

      <div className="container-fluid">
        <div className="row">
          <HeaderMenu />

          <main className="container">
            <section className="static-pages">
              <div className="title-section">
                <h1>{name}</h1>
              </div>

              <div ref={devRef} />
            </section>
          </main>

          <FooterSection />
        </div>
      </div>
    </div>
  );
};

export default SettingsGDPR;
