import { AnyAction } from 'redux';
import {
  APPLY_VOUCHER_SUCCESS,
  FETCH_VALID_VOUCHERS_SUCCESS,
  FETCH_VOUCHERS_SUCCESS,
  LOCALLY_CHOOSE_VOUCHER,
  REMOVE_VOUCHER_SUCCESS,
} from 'shared/redux/types/VouchersTypes';

const VouchersReducer = (state = [], action: AnyAction = (() => {}) as unknown as AnyAction) => {
  switch (action.type) {
    case FETCH_VOUCHERS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const ValidVouchersReducer = (
  state = [],
  action: AnyAction = (() => {}) as unknown as AnyAction,
) => {
  switch (action.type) {
    case FETCH_VALID_VOUCHERS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const ApplyVoucherReducer = (
  state = [],
  action: AnyAction = (() => {}) as unknown as AnyAction,
) => {
  switch (action.type) {
    case APPLY_VOUCHER_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const RemoveVoucherReducer = (
  state = [],
  action: AnyAction = (() => {}) as unknown as AnyAction,
) => {
  switch (action.type) {
    case REMOVE_VOUCHER_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const LocalChooseVoucherReducer = (
  state = {},
  action: AnyAction = (() => {}) as unknown as AnyAction,
) => {
  switch (action.type) {
    case LOCALLY_CHOOSE_VOUCHER:
      return action.payload;
    default:
      return state;
  }
};

export {
  VouchersReducer,
  ValidVouchersReducer,
  ApplyVoucherReducer,
  RemoveVoucherReducer,
  LocalChooseVoucherReducer,
};
