import 'doctor/styles/account-reviews.css';
import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getMyReviewsState, getUserProfileState } from 'shared/redux/src/StatesGetter';
import { fetchMyReviewsRequest } from 'shared/redux/actions/MyReviewsActions';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import Avatar from 'shared/components/Avatar';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';

type ModalContent = {
  rating: string;
  originator: string;
  remarks: string;
  createdAt: string;
};

const DoctorAccountReviewsBlock = () => {
  const { t, i18n } = useTranslation();
  const [showModalViewReview, setModalViewReviewShow] = useState(false);
  const [currentModalContent, setCurrentModalContent] = useState<ModalContent>({
    rating: '',
    originator: '',
    remarks: '',
    createdAt: '',
  });
  const modalViewReviewClose = () => {
    setModalViewReviewShow(false);
    setCurrentModalContent({
      rating: '',
      originator: '',
      remarks: '',
      createdAt: '',
    });
  };
  const dispatch = useDispatch();
  const profileData = useSelector(getUserProfileState, shallowEqual);
  const {
    firstName = '',
    lastName = '',
    pictureMedia,
    averageRating,
    countRating,
    name = '',
  } = profileData;
  const ratingString = `${t('note')} ${averageRating ?? 0} (${countRating ?? 0} ${t(
    'noOfReviews',
  )})`;
  const reviewsState = useSelector(getMyReviewsState, shallowEqual);
  const { data: reviewsData, currentPage, noOfPages } = reviewsState;
  const [listItems, setListItems] = useState([]);
  const notInitialRender = useRef(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);

  const fetchResults = () => {
    dispatch(
      fetchMyReviewsRequest({
        limit,
        page,
      }),
    );
  };
  useEffect(() => {
    dispatch(fetchMyReviewsRequest({}));
    const newArray = _.unionBy(listItems, reviewsData, 'id');
    setListItems(newArray);
  }, []);
  useEffect(() => {
    if (notInitialRender.current) {
      setListItems([]);
      if (page === 1) {
        fetchResults();
      } else {
        setPage(1);
      }
    }
    notInitialRender.current = true;
  }, []);
  useEffect(() => {
    const newArray = _.unionBy(listItems, reviewsData, 'id');
    setListItems(newArray);
  }, [reviewsState]);
  useEffect(() => {
    fetchResults();
  }, [page]);
  const loadMore = () => {
    if (currentPage === noOfPages) return;
    const newPage = page + 1;
    setPage(newPage);
  };
  const readMoreAction = (item) => {
    setCurrentModalContent(item);
    setModalViewReviewShow(true);
  };
  const renderItem = (item) => (
    <div className="review-box" key={item.id.toString()}>
      <div className="header">
        <div className="name">{item.originator}</div>
        <div className="rating">
          <CustomIcon className="custom-icon" color={Colors.mediumGrey} size="14" icon="Rating_2" />
          <i className="rating-icon" />
          {`${t('note')} ${item.rating} / 5`}
        </div>
      </div>
      <div className="date">
        {item?.createdAt
          ? dayjs(item?.createdAt).format('DD MMM YYYY')
          : dayjs().format('DD MMM YYYY')}
      </div>
      <div className="description">
        {item.remarks.length < 170 ? item.remarks : `${item.remarks.slice(0, 170)} ... `}
      </div>
      {item.remarks.length > 170 && (
        <button type="button" className="read-all" onClick={() => readMoreAction(item)}>
          Citește tot
        </button>
      )}
    </div>
  );
  return (
    <div className="account-reviews">
      <div className="title-section">
        <h2>{t('allReviews')}</h2>
      </div>
      <div className="medic-small-box">
        <div className="avatar-section">
          <Avatar size={36} imageURL={pictureMedia} firstName={firstName} lastName={lastName} />
        </div>
        <div className="info">
          <div className="name">{name}</div>
          <div className="rating">
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="14"
              icon="Rating_2"
            />
            {ratingString}
          </div>
        </div>
      </div>
      <div className="reviews-data-wrapper">
        {reviewsData.length > 0 ? (
          <InfiniteScroll
            dataLength={listItems.length}
            next={loadMore}
            hasMore={currentPage !== noOfPages}
            loader={<LoadingSpinner isLoading />}
            className="reviews-data-wrapper"
          >
            {listItems.map((rw) => renderItem(rw))}
          </InfiniteScroll>
        ) : (
          <div className="empty-container">{t('medicalApp.noReviews')}</div>
        )}
      </div>
      <Modal
        show={showModalViewReview}
        onHide={modalViewReviewClose}
        animation
        centered
        className="modal-view-review medic-modal"
      >
        <Modal.Header closeButton closeLabel={t('close2')}>
          <Modal.Title>{t('review')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="scroll-list">
            <div className="review">
              <div className="header">
                <div className="name">{currentModalContent?.originator}</div>
                <div className="rating">
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.mediumGrey}
                    size="14"
                    icon="Rating_2"
                  />
                  {`${t('note')} ${currentModalContent?.rating} / 5`}
                </div>
              </div>
              <div className="date">
                {currentModalContent?.createdAt
                  ? dayjs(currentModalContent?.createdAt).format('DD MMM YYYY')
                  : dayjs().format('DD MMM YYYY')}
              </div>
              <div className="text">
                <p>{currentModalContent?.remarks}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

DoctorAccountReviewsBlock.defaultProps = {
  match: {},
  location: {},
};
DoctorAccountReviewsBlock.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({}),
  }),
};
export default DoctorAccountReviewsBlock;
