import { actionChannel, ActionPattern, call, fork, put, take } from 'redux-saga/effects';
import { Action } from 'redux';
import { getPossibleKinshipAPI } from 'shared/redux/api/KinshipApi';
import {
  getPossibleKinshipError,
  getPossibleKinshipSuccess,
} from 'shared/redux/actions/KinshipActions';
import { GET_POSSIBLE_KINSHIP_REQUEST } from 'shared/redux/types/KinshipTypes';
import { possibleKinshipSelector } from 'shared/redux/selector/KinshipSelector';

function* getPossibleKinship(actionType: ActionPattern<Action<any>>) {
  const possibleKinshipChannel = yield actionChannel(actionType);
  while (true) {
    yield take(possibleKinshipChannel);
    try {
      const response = yield call(getPossibleKinshipAPI);
      yield put(getPossibleKinshipSuccess(possibleKinshipSelector(response.data)));
    } catch ({ message }) {
      yield put(getPossibleKinshipError({ message }));
    }
  }
}
function* kinshipSaga() {
  yield fork(getPossibleKinship, GET_POSSIBLE_KINSHIP_REQUEST);
}

export default kinshipSaga;
