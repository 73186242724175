import { actionChannel, ActionPattern, call, fork, put, take } from 'redux-saga/effects';
import i18next from 'i18next';
import { Action } from 'redux';
import { CONTACT_US_REQUEST, SUPPORT_FORM_REQUEST } from 'shared/redux/types/SupportTypes';
import { contactUsAPI, supportFormAPI } from 'shared/redux/api/SupportApi';
import {
  contactUsError,
  contactUsSuccess,
  supportFormError,
  supportFormSuccess,
} from 'shared/redux/actions/SupportActions';
import Alerts from 'shared/components/Alerts';
import NavigationService from 'shared/services/NavigationService';
import { isDoctor } from 'shared/services/EnvService';

function* sendSupportForm(actionType: ActionPattern<Action<any>>) {
  const sendSupportFormChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(sendSupportFormChannel);
    try {
      const response = yield call(supportFormAPI, payload);
      yield put(supportFormSuccess(response.data));
      if (response?.data?.registration_number) {
        Alerts.actionAlert(
          `${i18next.t('info')}`,
          `${i18next.t('alerts.supportSent')} ${response?.data?.registration_number}`,
          `${i18next.t('ok')}`,
          () =>
            isDoctor()
              ? NavigationService.navigate('/doctor/account')
              : NavigationService.navigate('/patient/account'),
        );
      }
    } catch ({ message }) {
      yield put(supportFormError({ message }));
    }
  }
}

function* contactUsSaga(actionType: ActionPattern<Action<any>>) {
  const contactUsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(contactUsChannel);
    try {
      const response = yield call(contactUsAPI, payload);
      yield put(contactUsSuccess(response.data));
      if (response?.data?.registration_number) {
        Alerts.simpleAlert(
          `${i18next.t('info')}`,
          `${i18next.t('alerts.supportSent')} ${response?.data?.registration_number}`,
        );
      }
    } catch ({ message }) {
      yield put(contactUsError({ message }));
    }
  }
}

function* supportSaga() {
  yield fork(sendSupportForm, SUPPORT_FORM_REQUEST);
  yield fork(contactUsSaga, CONTACT_US_REQUEST);
}

export default supportSaga;
