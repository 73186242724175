export const FETCH_VOUCHERS_REQUEST = 'FETCH_VOUCHERS_REQUEST';
export const FETCH_VOUCHERS_SUCCESS = 'FETCH_VOUCHERS_SUCCESS';
export const FETCH_VOUCHERS_ERROR = 'FETCH_VOUCHERS_ERROR';

export const FETCH_VALID_VOUCHERS_REQUEST = 'FETCH_VALID_VOUCHERS_REQUEST';
export const FETCH_VALID_VOUCHERS_SUCCESS = 'FETCH_VALID_VOUCHERS_SUCCESS';
export const FETCH_VALID_VOUCHERS_ERROR = 'FETCH_VALID_VOUCHERS_ERROR';

export const APPLY_VOUCHER_REQUEST = 'APPLY_VOUCHER_REQUEST';
export const APPLY_VOUCHER_SUCCESS = 'APPLY_VOUCHER_SUCCESS';
export const APPLY_VOUCHER_ERROR = 'APPLY_VOUCHER_ERROR';

export const REMOVE_VOUCHER_REQUEST = 'REMOVE_VOUCHER_REQUEST';
export const REMOVE_VOUCHER_SUCCESS = 'REMOVE_VOUCHER_SUCCESS';
export const REMOVE_VOUCHER_ERROR = 'REMOVE_VOUCHER_ERROR';

export const LOCALLY_CHOOSE_VOUCHER = 'LOCALLY_CHOOSE_VOUCHER';
