import { snakeToCamel } from 'shared/modules/StringUtils';

const appointmentSettingsSelector = (appointmentSettings) => {
  if (!Array.isArray(appointmentSettings) || !appointmentSettings.length) {
    return {};
  }
  const formattedSettings = {};
  appointmentSettings?.map((appointmentSetting) => {
    const type = snakeToCamel(appointmentSetting.type);
    formattedSettings[type] = {
      duration: appointmentSetting.duration,
      pause: appointmentSetting.pause,
      isAvailable: appointmentSetting.is_available,
      minPrice: appointmentSetting.min_price,
      maxPrice: appointmentSetting.max_price,
      minPriceAsStr: appointmentSetting.min_price_as_string,
      maxPriceAsStr: appointmentSetting.max_price_as_string,
      startTime: appointmentSetting.opening_hour,
      endTime: appointmentSetting.closing_hour,
    };
    return true;
  });
  return formattedSettings;
};

export { appointmentSettingsSelector };
