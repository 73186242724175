import 'doctor/styles/auth.css';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'shared/modules/Hook';
import useResetPassword from 'shared/hooks/useResetPassword';

const DoctorResetPassword = () => {
  const { t } = useTranslation();
  const query = useQuery();
  const emailParam = query.get('email') ?? '';
  const tokenParam = query.get('token') ?? '';
  const resetPassword = useResetPassword(emailParam, tokenParam);

  return (
    <section className="forgot-password">
      <form onSubmit={resetPassword.onSubmit} autoComplete="off">
        <div className="title-section">
          <h1>{t('newPassword')}</h1>
        </div>
        <div className="password-eye">
          <input
            name="password"
            id="password"
            type={resetPassword.passwordShown ? 'text' : 'password'}
            value={resetPassword.password}
            onChange={(event) => resetPassword.setPassword(event.target.value)}
            className="form-control"
            placeholder=" "
            autoComplete="off"
          />
          <label htmlFor="actual-password">{t('password')}</label>
          <div className="eye" onClick={resetPassword.togglePasswordVisibility}>
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="24"
              icon={`${resetPassword.passwordShown ? 'Eye_off' : 'Eye'}`}
            />
          </div>
        </div>
        <div className="password-eye">
          <input
            name="new-password"
            id="new-password"
            type={resetPassword.confirmPasswordShown ? 'text' : 'password'}
            value={resetPassword.confirmPassword}
            onChange={(event) => resetPassword.setConfirmPassword(event.target.value)}
            className="form-control"
            placeholder=" "
            autoComplete="off"
          />
          <label htmlFor="new-password">{t('passwordConfirm')}</label>
          <div className="eye" onClick={resetPassword.toggleConfirmPasswordVisibility}>
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="24"
              icon={`${resetPassword.confirmPasswordShown ? 'Eye_off' : 'Eye'}`}
            />
          </div>
        </div>
        <button type="submit" className="green-button">
          {t('doctorApp.resetPassword')}
        </button>
      </form>
    </section>
  );
};

export default DoctorResetPassword;
