import { default as ApiConstants } from 'shared/constants/ApiConstants.json';
import ApiUtils from 'shared/modules/ApiUtils';

// @ts-ignore
const getPushesAPI = ({ page, limit }) =>
  ApiUtils.getRequest(`${ApiConstants.PUSHES_ENDPOINT}?page=${page}&limit=${limit}`, {});

const getPushDetailsAPI = (pushId) =>
  ApiUtils.getRequest(`${ApiConstants.PUSHES_ENDPOINT}/${pushId}`);

const updatePushesAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.putRequest(ApiConstants.UPDATE_PUSHES_ENDPOINT, payload);

const updateAccountSettingsAPI = (payload) =>
  ApiUtils.patchRequest(`${ApiConstants.ACCOUNT_SETTINGS_ENDPOINT}`, payload);

const getNotificationsSettingsAPI = () =>
  ApiUtils.getRequest(`${ApiConstants.NOTIFICATIONS_SETTINGS_ENDPOINT}`);

// @ts-ignore
const updateNotificationsSettingsAPI = ({ id, data }) =>
  ApiUtils.putRequest(`${ApiConstants.NOTIFICATIONS_SETTINGS_ENDPOINT}/${id}`, data);

export {
  getPushesAPI,
  getPushDetailsAPI,
  updatePushesAPI,
  getNotificationsSettingsAPI,
  updateNotificationsSettingsAPI,
  updateAccountSettingsAPI,
};
