import 'doctor/styles/account-profile.css';
import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getMySpecializationsState, getUserProfileState } from 'shared/redux/src/StatesGetter';
import {
  patchDoctorCvRequest,
  patchUserProfileRequest,
} from 'shared/redux/actions/UserProfileActions';
import Alerts from 'shared/components/Alerts';
import { getPrivateMediaFileRequest } from 'shared/redux/actions/UngroupedActions';
import { useTranslation } from 'react-i18next';
import AddSpecializationModal from 'doctor/components/modals/AddSpecialization';

const DoctorAccountProfileTabSpecialization = ({ type }) => {
  const { t } = useTranslation();
  const [showModalNewSpecialization, setModalNewSpecializationShow] = useState(false);
  const modalNewSpecializationShow = () => setModalNewSpecializationShow(true);
  const dispatch = useDispatch();
  const profileData = useSelector(getUserProfileState, shallowEqual);
  const { cvMedia, identityCardMedia, additionalDocumentMedia, doctorSealMedia } = profileData;
  const doctorSpecializations = useSelector(getMySpecializationsState, shallowEqual);
  const [media, setMedia] = useState(cvMedia);
  const [idMedia, setIdMedia] = useState(identityCardMedia);
  const [adMedia, setAdMedia] = useState(additionalDocumentMedia);
  const [sealMedia, setSealMedia] = useState(doctorSealMedia);

  const [cvName, setCvName] = useState('');
  const [cvSize, setCvSize] = useState(0);
  const [cvType, setCvType] = useState('');
  const [cvData, setCvData] = useState<any>(null);

  const [idCardName, setIdCardName] = useState('');
  const [idCardSize, setIdCardSize] = useState(0);
  const [idCardType, setIdCardType] = useState('');
  const [idCardData, setIdCardData] = useState(null);

  const [additionalDocName, setAdditionalDocName] = useState('');
  const [additionalDocSize, setAdditionalDocSize] = useState(0);
  const [additionalDocType, setAdditionalDocType] = useState('');
  const [additionalDocData, setAdditionalDocData] = useState(null);

  const [doctorSealName, setDoctorSealName] = useState('');
  const [doctorSealSize, setDoctorSealSize] = useState(0);
  const [doctorSealType, setDoctorSealType] = useState('');
  const [doctorSealData, setDoctorSealData] = useState(null);

  const hiddenFileInputCv = useRef(null);
  const hiddenFileInputId = useRef(null);
  const hiddenFileInputAd = useRef(null);
  const hiddenFileInputDs = useRef(null);

  useEffect(() => {
    if (media?.name !== cvName) {
      setMedia(cvMedia);
    }
  }, [cvMedia]);
  useEffect(() => {
    if (idMedia?.name !== idCardName) {
      setIdMedia(identityCardMedia);
    }
  }, [identityCardMedia]);
  useEffect(() => {
    if (adMedia?.name !== additionalDocName) {
      setAdMedia(additionalDocumentMedia);
    }
  }, [additionalDocumentMedia]);
  useEffect(() => {
    if (sealMedia?.name !== doctorSealName) {
      setSealMedia(doctorSealMedia);
    }
  }, [doctorSealMedia]);

  const handleChangeCv = (e) => {
    const [file] = e.target.files;
    setCvData(file);
    setCvName(file.name);
    setCvSize(file.size);
    setCvType(file.type);

    dispatch(
      patchDoctorCvRequest({
        toUpdate: {
          cv: {
            originalName: file.name,
            mimeType: file.type,
            size: file.size,
          },
        },
        cvFile: file,
      }),
    );
  };
  const handleChangeId = (e) => {
    const [file] = e.target.files;
    setIdCardData(file);
    setIdCardName(file.name);
    setIdCardSize(file.size);
    setIdCardType(file.type);
  };
  const handleChangeAd = (e) => {
    const [file] = e.target.files;
    setAdditionalDocData(file);
    setAdditionalDocName(file.name);
    setAdditionalDocSize(file.size);
    setAdditionalDocType(file.type);
  };
  const handleChangeDs = (e) => {
    const [file] = e.target.files;
    setDoctorSealData(file);
    setDoctorSealName(file.name);
    setDoctorSealSize(file.size);
    setDoctorSealType(file.type);
  };

  const saveCVAndDocuments = () => {
    if (!cvData?.length && !media) {
      Alerts.simpleAlert(t('information'), t('alerts.addCurriculumViate'));
      return;
    }

    if (!idCardName?.length && !idMedia) {
      Alerts.simpleAlert(t('information'), t('alerts.addIdCard'));
      return;
    }
    if (!additionalDocName?.length && !adMedia) {
      Alerts.simpleAlert(t('information'), t('alerts.addAdditionalDoc'));
      return;
    }

    if (!doctorSealName?.length && !sealMedia) {
      Alerts.simpleAlert(t('information'), t('alerts.addDoctorSeal'));
      return;
    }

    let toUpdatePayload = {};
    if (!idMedia) {
      toUpdatePayload = {
        identityCard: {
          originalName: idCardName,
          mimeType: idCardType,
          size: idCardSize,
        },
      };
    }
    if (!adMedia) {
      toUpdatePayload = {
        ...toUpdatePayload,
        additionalDocument: {
          originalName: additionalDocName,
          mimeType: additionalDocType,
          size: additionalDocSize,
        },
      };
    }
    if (!sealMedia) {
      toUpdatePayload = {
        ...toUpdatePayload,
        doctorSeal: {
          originalName: doctorSealName,
          mimeType: doctorSealType,
          size: doctorSealSize,
        },
      };
    }
    dispatch(
      patchUserProfileRequest({
        lastStep: true,
        toUpdate: toUpdatePayload,
        idCard: idCardName?.length ? idCardData : {},
        additionalDocument: additionalDocName?.length ? additionalDocData : {},
        doctorSeal: doctorSealName?.length ? doctorSealData : {},
      }),
    );
  };

  const viewFile = async (id) => {
    if (id) {
      dispatch(getPrivateMediaFileRequest(id));
    }
  };
  const deleteCv = () => {
    Alerts.okCancelAlert(
      `${t('warning')}`,
      `${t('validations.deleteFile')}`,
      () => {
        setMedia({});
        setCvName('');
        setCvData(null);
      },
      () => {},
    );
  };
  const deleteId = () => {
    Alerts.okCancelAlert(
      `${t('warning')}`,
      `${t('validations.deleteFile')}`,
      () => {
        setIdMedia({});
        setIdCardData(null);
        setIdCardName('');
      },
      () => {},
    );
  };
  const deleteAdditional = () => {
    Alerts.okCancelAlert(
      `${t('warning')}`,
      `${t('validations.deleteFile')}`,
      () => {
        setAdMedia({});
        setAdditionalDocData(null);
        setAdditionalDocName('');
      },
      () => {},
    );
  };
  const deleteDoctorSeal = () => {
    Alerts.okCancelAlert(
      `${t('warning')}`,
      `${t('validations.deleteFile')}`,
      () => {
        setSealMedia('');
        setDoctorSealName('');
        setDoctorSealSize(0);
        setDoctorSealType('');
        setDoctorSealData(null);
      },
      () => {},
    );
  };
  return (
    <>
      <div className="subtitle">{t('medicalApp.specialization')}</div>
      <button
        type="button"
        className="white-button add-specialization-btn"
        onClick={modalNewSpecializationShow}
      >
        <CustomIcon className="custom-icon" color={Colors.medicColor} size="24" icon="Plus" />
        {t('addSpecialization')}
      </button>
      {doctorSpecializations?.map((sp) => (
        <div className="specialization-box" key={sp?.id}>
          <div className="type">
            <div className="primary">{sp?.name}</div>
            {sp?.acceptChildren && <div className="secondary">{t('acceptChildren')}</div>}
          </div>
          <div className="status">
            <div className={sp?.isValidated ? 'approved' : 'in-progress'}>
              {sp?.isValidated ? t('approved') : t('processing')}
            </div>
          </div>
        </div>
      ))}
      <div className="subtitle">{t('cv')}</div>
      {!media?.id ? (
        <>
          {cvName?.length === 0 ? (
            <div className="upload-files">
              <Form.Group controlId="uploadCv" className="files-box">
                <Form.Label>
                  <div className="info-file cursor-pointer">
                    <div className="title">{t('account.uploadCV')}</div>
                    <div className="details">{t('account.limitUploadReg10')}</div>
                  </div>
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.medicColor}
                    size="24"
                    icon="Upload"
                  />
                </Form.Label>
                <Form.Control type="file" ref={hiddenFileInputCv} onChange={handleChangeCv} />
              </Form.Group>
            </div>
          ) : (
            <div className="uploaded-box">
              <div className="uploaded-file">
                <div className="file-name">
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.darkGrey}
                    size="24"
                    icon="Document"
                  />
                  <span>{t('cv')}</span>
                </div>
                <button type="button" onClick={() => setCvName('')}>
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.mediumGrey}
                    size="24"
                    icon="Delete"
                  />
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="uploaded-box">
          <div className="uploaded-file">
            <div className="file-name cursor-pointer" onClick={() => viewFile(cvMedia?.id)}>
              <CustomIcon
                className="custom-icon"
                color={Colors.darkGrey}
                size="24"
                icon="Document"
              />
              <span>{t('cv')}</span>
            </div>
            <button type="button" onClick={deleteCv}>
              <CustomIcon
                className="custom-icon"
                color={Colors.mediumGrey}
                size="24"
                icon="Delete"
              />
            </button>
          </div>
        </div>
      )}
      <div className="subtitle">{t('doctorApp.documents')}</div>
      {!idMedia?.id ? (
        <>
          {idCardName?.length === 0 ? (
            <div className="upload-files">
              <Form.Group controlId="uploadId" className="files-box">
                <Form.Label>
                  <div className="info-file cursor-pointer">
                    <div className="title">{t('account.idCard')}</div>
                    <div className="details">{t('account.limitUploadReg10')}</div>
                  </div>
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.medicColor}
                    size="24"
                    icon="Upload"
                  />
                </Form.Label>
                <Form.Control type="file" ref={hiddenFileInputId} onChange={handleChangeId} />
              </Form.Group>
            </div>
          ) : (
            <div className="uploaded-box">
              <div className="uploaded-file">
                <div className="file-name">
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.darkGrey}
                    size="24"
                    icon="Document"
                  />
                  <span>{t('account.idCard')}</span>
                </div>
                <button type="button" onClick={() => setIdCardName('')}>
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.mediumGrey}
                    size="24"
                    icon="Delete"
                  />
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="uploaded-box">
          <div className="uploaded-file">
            <div className="file-name cursor-pointer" onClick={() => viewFile(idMedia?.id)}>
              <CustomIcon
                className="custom-icon"
                color={Colors.darkGrey}
                size="24"
                icon="Document"
              />
              <span>{t('account.idCard')}</span>
            </div>
            <button type="button" onClick={deleteId}>
              <CustomIcon
                className="custom-icon"
                color={Colors.mediumGrey}
                size="24"
                icon="Delete"
              />
            </button>
          </div>
        </div>
      )}
      {!adMedia?.id ? (
        <>
          {additionalDocName?.length === 0 ? (
            <div className="upload-files">
              <Form.Group controlId="uploadAd" className="files-box">
                <Form.Label>
                  <div className="info-file cursor-pointer">
                    <div className="title">{t('account.additionalIdDoc')}</div>
                    <div className="details">{t('account.limitUploadReg10')}</div>
                  </div>
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.medicColor}
                    size="24"
                    icon="Upload"
                  />
                </Form.Label>
                <Form.Control type="file" ref={hiddenFileInputAd} onChange={handleChangeAd} />
              </Form.Group>
            </div>
          ) : (
            <div className="uploaded-box">
              <div className="uploaded-file">
                <div className="file-name">
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.darkGrey}
                    size="24"
                    icon="Document"
                  />
                  <span>{t('account.additionalIdDoc')}</span>
                </div>
                <button type="button" onClick={() => setAdditionalDocName('')}>
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.mediumGrey}
                    size="24"
                    icon="Delete"
                  />
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="uploaded-box">
          <div className="uploaded-file">
            <div className="file-name cursor-pointer" onClick={() => viewFile(adMedia?.id)}>
              <CustomIcon
                className="custom-icon"
                color={Colors.darkGrey}
                size="24"
                icon="Document"
              />
              <span>{t('account.additionalIdDoc')}</span>
            </div>
            <button type="button" onClick={deleteAdditional}>
              <CustomIcon
                className="custom-icon"
                color={Colors.mediumGrey}
                size="24"
                icon="Delete"
              />
            </button>
          </div>
        </div>
      )}
      {!sealMedia?.id ? (
        <>
          {doctorSealName?.length === 0 ? (
            <div className="upload-files">
              <Form.Group controlId="uploadS" className="files-box">
                <Form.Label>
                  <div className="info-file cursor-pointer">
                    <div className="title">{t('account.seal')}</div>
                    <div className="details">{t('account.limitUploadReg10')}</div>
                  </div>
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.medicColor}
                    size="24"
                    icon="Upload"
                  />
                </Form.Label>
                <Form.Control type="file" ref={hiddenFileInputDs} onChange={handleChangeDs} />
              </Form.Group>
            </div>
          ) : (
            <div className="uploaded-box">
              <div className="uploaded-file">
                <div className="file-name">
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.darkGrey}
                    size="24"
                    icon="Document"
                  />
                  <span>{t('account.seal')}</span>
                </div>
                <button type="button" onClick={() => setDoctorSealName('')}>
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.mediumGrey}
                    size="24"
                    icon="Delete"
                  />
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="uploaded-box">
          <div className="uploaded-file">
            <div className="file-name cursor-pointer" onClick={() => viewFile(sealMedia?.id)}>
              <CustomIcon
                className="custom-icon"
                color={Colors.darkGrey}
                size="24"
                icon="Document"
              />
              <span>{t('account.seal')}</span>
            </div>
            <button type="button" onClick={deleteDoctorSeal}>
              <CustomIcon
                className="custom-icon"
                color={Colors.mediumGrey}
                size="24"
                icon="Delete"
              />
            </button>
          </div>
        </div>
      )}
      <button type="button" onClick={saveCVAndDocuments} className="green-button">
        {type === 'profiled' ? t('save') : t('finishRegistration')}
      </button>
      <AddSpecializationModal
        showModalNewSpecialization={showModalNewSpecialization}
        setModalNewSpecializationShow={setModalNewSpecializationShow}
        contextType={type}
      />
    </>
  );
};

DoctorAccountProfileTabSpecialization.defaultProps = {
  type: 'profiled',
};

DoctorAccountProfileTabSpecialization.propTypes = {
  type: PropTypes.string,
};

export default DoctorAccountProfileTabSpecialization;
