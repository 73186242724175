import { default as ApiConstants } from 'shared/constants/ApiConstants.json';
import ApiUtils from 'shared/modules/ApiUtils';

const changeLocaleAPI = (payload: Record<string, unknown> | null | undefined) =>
  ApiUtils.patchRequest(ApiConstants.CHANGE_LOCALE_ENDPOINT, payload);

const getPrivateMediaFileAPI = (mediaId: any) =>
  ApiUtils.postRequest(`${ApiConstants.GET_MEDICAL_RECORD_DETAILS_ENDPOINT}/${mediaId}`);

const serverTimeAPI = (plusMinutes) =>
  ApiUtils.getRequest(`${ApiConstants.SERVER_TIME_ENDPOINT}/${plusMinutes}`);

export { changeLocaleAPI, getPrivateMediaFileAPI, serverTimeAPI };
