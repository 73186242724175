import { storeDispatcher, storeStateGetter } from 'shared/redux/ReduxStore';
import LocalEventsService from 'shared/services/LocalEventsService';
import NavigationService from 'shared/services/NavigationService';
import { forceLogoutRequest, requestLogout } from 'shared/redux/actions/AuthActions';
import { appointmentLocalStepsRequest } from 'shared/redux/actions/PatientAppointmentActions';
import { locallyChooseVoucher } from 'shared/redux/actions/VouchersActions';
import {
  fetchPatientProfileRequest,
  getPatientMedicalRecordsRequest,
  locallyCurrentPatientProfile,
} from 'shared/redux/actions/PatientProfileActions';
import { localAppointmentSelector } from 'shared/redux/selector/LocalAppointmentSelector';
import {
  videoCallGetTokenRequest,
  videoCallGetTokenSuccess,
} from 'shared/redux/actions/VideoCallActions';
import StorageService from 'shared/services/StorageService';
import { default as StringConstants } from 'shared/constants/StringConstants.json';
import { getAppointmentSettingsRequest } from 'shared/redux/actions/AppointmentActions';
import PatientPushNotifier from './PatientPushNotifier';
import { isTwilioScreen } from '../services/EnvService';
import Alerts from '../components/Alerts';
import { fetchMedicalRecordCategoryRequest } from '../redux/actions/MedicalRecordCategoryActions';
import { updateSharedStateAction } from '../redux/actions/UngroupedActions';
import { parseToJson } from './JsonUtils';

export const registerPatientLocalEvents = () => {
  LocalEventsService.offAll();
  LocalEventsService.on('checkEvents', (data) => {
    // console.warn(`still comes checkEvents ${JSON.stringify(data)}`);
  });
  LocalEventsService.on('twilioError', () => {
    storeDispatcher(
      appointmentLocalStepsRequest({
        newAppointment: true,
      }),
    );
  });
  LocalEventsService.on('pushNotificationClicked', (pushPayload) => {
    localStorage.setItem('Answer_Call', `false`);
    PatientPushNotifier.onForegroundPushReceived({
      data: { ...pushPayload, type: pushPayload.notification_type.type_name },
    });
  });
  LocalEventsService.on('callEnded', () => {
    storeDispatcher(
      appointmentLocalStepsRequest({
        payment: {},
        patient: {},
        voucher: {},
        stickyStatus: 'ended',
        status: 'needReview',
        callStatus: 'terminated',
        inProgress: false,
        enableVideo: true,
        enableAudio: true,
        isInitialTime: true,
        showTimeBubble: false,
        isOtherUserConnected: false,
        otherVideoEnabled: false,
        otherAudioEnabled: false,
      }),
    );
    storeDispatcher(locallyChooseVoucher({}));
    NavigationService.navigate(`/patient/rating`);
  });
  LocalEventsService.on('joinVideoCall', () => {
    if (localStorage.getItem('Answer_Call') === 'true') {
      storeDispatcher(
        appointmentLocalStepsRequest({
          status: 'inProgress',
          callStatus: 'active',
          stickyStatus: 'started',
          inProgress: true,
        }),
      );
      NavigationService.navigate('/patient/video-call');
    }
  });
  LocalEventsService.on('logOut', () => {
    storeDispatcher(locallyCurrentPatientProfile({}));
    storeDispatcher(getAppointmentSettingsRequest({}));
    // window.location.assign('/patient/login');
  });
  LocalEventsService.on('forceLogout', () => {
    StorageService.getData(StringConstants.REFRESH_TOKEN, null).then((token) => {
      if (!token) return true;
      storeDispatcher(forceLogoutRequest(token));
      storeDispatcher(locallyCurrentPatientProfile({}));
      return true;
    });
  });
  LocalEventsService.on('loginDone', ({ isProfileCompleted }) => {
    storeDispatcher(fetchPatientProfileRequest({}));
    if (isProfileCompleted === false) {
      NavigationService.navigate('/patient/account');
    } else {
      NavigationService.navigate('/patient/home');
    }
  });
  LocalEventsService.on('currentAppointmentData', (rawData) => {
    const [data] = rawData;
    const appointmentData = localAppointmentSelector(data);
    storeDispatcher(appointmentLocalStepsRequest(appointmentData));
    if (appointmentData.inProgress) {
      storeDispatcher(videoCallGetTokenRequest({ appointmentId: data.id }));
    } else {
      storeDispatcher(videoCallGetTokenSuccess({}));
      storeDispatcher(
        appointmentLocalStepsRequest({ status: '', callStatus: '', inProgress: false }),
      );
    }
  });
  LocalEventsService.on('noCurrentAppointment', () => {
    storeDispatcher(
      appointmentLocalStepsRequest({
        stickyStatus: '',
        status: '',
        callStatus: '',
        inProgress: false,
        enableVideo: true,
        enableAudio: true,
        isInitialTime: true,
        showTimeBubble: false,
      }),
    );

    if (isTwilioScreen()) {
      NavigationService.navigate(`/patient/rating`);
    }
  });
};

export const removePatientLocalEvents = () => {
  LocalEventsService.offAll();
};
