import { AnyAction } from 'redux';
import {
  ADD_DOCTOR_EXCEPTION_TIMETABLE_SUCCESS,
  ADD_DOCTOR_RECURRENT_TIMETABLE_SUCCESS,
  ADD_DOCTOR_TIMETABLE_SUCCESS,
  FETCH_DOCTOR_TIMETABLE_SUCCESS,
  FETCH_MERGED_DOCTOR_TIMETABLE_SUCCESS,
  GET_DOCTOR_PAST_TIMETABLE_SUCCESS,
  LOCALLY_ADD_DATE,
  PATCH_DOCTOR_EXCEPTION_TIMETABLE_SUCCESS,
  PATCH_DOCTOR_RECURRENT_TIMETABLE_SUCCESS,
  PATCH_DOCTOR_TIMETABLE_SUCCESS,
  REMOVE_DOCTOR_EXCEPTION_TIMETABLE_SUCCESS,
  REMOVE_DOCTOR_RECURRENT_TIMETABLE_SUCCESS,
  REMOVE_DOCTOR_TIMETABLE_SUCCESS,
} from 'shared/redux/types/DoctorTimetableTypes';
import { replaceArrayRowProps } from 'shared/modules/ArrayUtils';

const DoctorTimetableReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case FETCH_DOCTOR_TIMETABLE_SUCCESS:
      return action.payload;
    case PATCH_DOCTOR_TIMETABLE_SUCCESS:
      return {
        ...state,
        // @ts-ignore
        [action.payload.data?.date]: replaceArrayRowProps(
          state[action.payload.data?.date],
          'id',
          action.payload.data?.id,
          action.payload.data?.data,
        ),
      };
    case ADD_DOCTOR_TIMETABLE_SUCCESS:
      return {
        ...state,
        // @ts-ignore
        [action.payload.date]: state[action.payload.date]?.concat(action.payload) ?? [
          action.payload,
        ],
      };
    case REMOVE_DOCTOR_TIMETABLE_SUCCESS:
      return {
        ...state,
        [action.payload.date]:
          // @ts-ignore
          state[action.payload.date]?.filter((row) => row?.id !== action.payload?.id) ?? [],
      };
    default:
      return state;
  }
};
const DoctorRecurrenceTimetableReducer = (
  state = [],
  action: AnyAction = (() => {}) as unknown as AnyAction,
) => {
  switch (action.type) {
    case FETCH_MERGED_DOCTOR_TIMETABLE_SUCCESS:
      return action.payload;
    case PATCH_DOCTOR_RECURRENT_TIMETABLE_SUCCESS:
      return {
        ...state,
        // @ts-ignore
        [action.payload.data.date]: replaceArrayRowProps(
          state[action.payload.data.date],
          'id',
          action.payload.data?.id,
          action.payload.data.data,
        ),
      };
    case ADD_DOCTOR_RECURRENT_TIMETABLE_SUCCESS:
      return {
        ...state,
        // @ts-ignore
        [action.payload.date]: state[action.payload.date]?.concat(action.payload) ?? [
          action.payload.data,
        ],
      };
    case REMOVE_DOCTOR_RECURRENT_TIMETABLE_SUCCESS:
      return {
        ...state,
        [action.payload.date]:
          // @ts-ignore
          state[action.payload.date]?.filter((row) => row?.id !== action.payload?.id) ?? [],
      };
    default:
      return state;
  }
};

const DoctorExceptionTimetableReducer = (
  state = [],
  action: AnyAction = (() => {}) as unknown as AnyAction,
) => {
  switch (action.type) {
    case FETCH_MERGED_DOCTOR_TIMETABLE_SUCCESS:
      return action.payload;
    case PATCH_DOCTOR_EXCEPTION_TIMETABLE_SUCCESS:
      return {
        ...state,
        // @ts-ignore
        [action.payload.data.date]: replaceArrayRowProps(
          state[action.payload.data.date],
          'id',
          action.payload.data?.id,
          action.payload.data.data,
        ),
      };
    case ADD_DOCTOR_EXCEPTION_TIMETABLE_SUCCESS:
      return {
        ...state,
        // @ts-ignore
        [action.payload.date]: state[action.payload.date]?.concat(action.payload) ?? [
          action.payload.data,
        ],
      };
    case REMOVE_DOCTOR_EXCEPTION_TIMETABLE_SUCCESS:
      return {
        ...state,
        [action.payload.date]:
          // @ts-ignore
          state[action.payload.date]?.filter((row) => row?.id !== action.payload?.id) ?? [],
      };
    default:
      return state;
  }
};

const DoctorPastTimetableReducer = (
  state = [],
  action: AnyAction = (() => {}) as unknown as AnyAction,
) => {
  switch (action.type) {
    case GET_DOCTOR_PAST_TIMETABLE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const LocalDateReducer = (state = {}, action: AnyAction = (() => {}) as unknown as AnyAction) => {
  switch (action.type) {
    case LOCALLY_ADD_DATE:
      return action.payload;
    default:
      return state;
  }
};

export {
  DoctorTimetableReducer,
  DoctorPastTimetableReducer,
  LocalDateReducer,
  DoctorRecurrenceTimetableReducer,
  DoctorExceptionTimetableReducer,
};
