import 'doctor/styles/auth.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import Alerts from 'shared/components/Alerts';
import Colors from 'shared/themes/Colors';
import Utils from 'shared/modules/Utils';
import CustomIcon from 'shared/components/CustomIcon';
import { useNavigate } from 'react-router-dom';
import SocialLogin from 'shared/components/SocialLogin';
import { getPossibleKinshipRequest } from 'shared/redux/actions/KinshipActions';
import { fetchCountriesRequest } from 'shared/redux/actions/CountryActions';
import { getCountriesState } from 'shared/redux/src/StatesGetter';
import Validators from 'shared/modules/Validators';
import { registerRequest } from 'shared/redux/actions/UserActions';
import { useTranslation } from 'react-i18next';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';

const DoctorRegister = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [cnp, setCnp] = useState('');
  const [password, setPassword] = useState('');
  const [agreeTermsAndConditions, setAgreeTermsAndConditions] = useState(false);
  const [agreePrivacyPolicy, setAgreePrivacyPolicy] = useState(false);
  const [agreeDataIsReal, setAgreeDataIsReal] = useState(false);
  const [agreeStripeTermsAndConditions, setAgreeStripeTermsAndConditions] = useState(false);
  const [marketingNotificationSetting, setMarketingNotificationSetting] = useState(false);
  const [requiredFieldsError, setRequiredFieldsError] = useState(false);
  const phoneNumberRef = useRef(null);
  useEffect(() => {
    dispatch(getPossibleKinshipRequest({}));
    dispatch(fetchCountriesRequest({}));
  }, []);
  const onRegisterPress = (e) => {
    e.preventDefault();
    const parsedPhone = phoneNumber && Utils.withoutMetadata(parsePhoneNumber(phoneNumber));
    const getNumberAfterPossiblyEliminatingZero = Utils.getNumberAfterPossiblyEliminatingZero(
      parsedPhone?.nationalNumber,
      parsedPhone?.countryCallingCode,
    );

    if (
      !agreeTermsAndConditions ||
      !agreePrivacyPolicy ||
      !agreeDataIsReal ||
      !agreeStripeTermsAndConditions ||
      !email ||
      !cnp ||
      !password ||
      !lastName ||
      !firstName ||
      !phoneNumber ||
      !parseInt(getNumberAfterPossiblyEliminatingZero.number, 10)
    ) {
      Alerts.simpleAlert(t('error'), t('allFieldsMandatory'));
      setRequiredFieldsError(true);
      return;
    }
    const isValidEmail = Validators.validateEmail(email);
    if (!isValidEmail) {
      Alerts.simpleAlert(t('error'), t('invalidEmail'));
      setRequiredFieldsError(true);
      return;
    }

    dispatch(
      registerRequest({
        email,
        plainPassword: password,
        firstName,
        lastName,
        phoneNumber: getNumberAfterPossiblyEliminatingZero?.formattedNumber,
        numericPersonalCode: cnp,
        agreeTermsAndConditions,
        agreePrivacyPolicy,
        agreeDataIsReal,
        agreeStripeTermsAndConditions,
        marketingNotificationSetting: marketingNotificationSetting ? 1 : 0,
      }),
    );
  };
  const handleLogin = () => {
    navigate('/doctor/login');
  };
  return (
    <section className="register">
      <form onSubmit={onRegisterPress}>
        <div className="title-section">
          <h1>{t('doctorApp.createAccount')}</h1>
        </div>
        <div className="social-login">
          <SocialLogin />
        </div>
        <div className="or">{t('or')}</div>
        <div className="info-message">
          <CustomIcon
            className="custom-icon"
            color={Colors.mediumGrey}
            size="24"
            icon="Warning_2"
          />
          {t('allFieldsMandatory')}
        </div>
        <div className="form-input">
          <input
            autoComplete="off"
            id="email"
            name="email"
            type="email"
            className={`form-control ${
              requiredFieldsError && (!email || !Validators.validateEmail(email)) && 'is-invalid'
            }`}
            placeholder=" "
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <label htmlFor="email">{t('email')}</label>
        </div>
        <div className="password-eye">
          <input
            value={password}
            name="password"
            id="password"
            onChange={(event) => setPassword(event.target.value)}
            type={passwordShown ? 'text' : 'password'}
            className={`form-control ${requiredFieldsError && !password && 'is-invalid'}`}
            placeholder=" "
          />
          <label htmlFor="password">{t('password')}</label>
          <div className="eye" onClick={() => setPasswordShown(!passwordShown)}>
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="24"
              icon={`${passwordShown ? 'Eye_off' : 'Eye'}`}
            />
          </div>
        </div>
        <div className="form-input">
          <input
            autoComplete="off"
            id="lastName"
            name="lastName"
            type="text"
            className={`form-control ${requiredFieldsError && !lastName && 'is-invalid'}`}
            placeholder=" "
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
          />
          <label htmlFor="lastName">{t('lastName')}</label>
        </div>
        <div className="form-input">
          <input
            autoComplete="off"
            id="firstName"
            name="firstName"
            type="text"
            className={`form-control ${requiredFieldsError && !firstName && 'is-invalid'}`}
            placeholder=" "
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
          />
          <label htmlFor="firstName">{t('firstName')}</label>
        </div>
        <div className="form-input">
          <input
            autoComplete="off"
            id="cnp"
            name="cnp"
            type="text"
            className={`form-control ${requiredFieldsError && !cnp && 'is-invalid'}`}
            placeholder=" "
            value={cnp}
            onChange={(event) => setCnp(event.target.value)}
          />
          <label htmlFor="cnp">{t('cnp')}</label>
        </div>
        <div className="phone-number-with-prefix">
          <PhoneInput
            className={`${phoneNumber && 'has-value'} ${
              requiredFieldsError && !phoneNumber && 'is-invalid'
            }`}
            placeholder=" "
            value={phoneNumber}
            defaultCountry="RO"
            id="phoneNumber"
            name="phoneNumber"
            /* @ts-ignore */
            onChange={(value) => setPhoneNumber(value)}
            ref={phoneNumberRef}
          />
          <label htmlFor="phoneNumber">{t('phoneNumber')}</label>
        </div>

        {/* todo logi arrange checkboxes */}
        <div className="custom-control custom-checkbox green-checkbox align-center-checkbox left-justify-content">
          <input
            className={`custom-control-input ${
              requiredFieldsError && !agreeTermsAndConditions && 'is-invalid'
            }`}
            id="agree_terms"
            type="checkbox"
            checked={agreeTermsAndConditions}
            onChange={() => setAgreeTermsAndConditions(!agreeTermsAndConditions)}
          />
          <label htmlFor="agree_terms" className="custom-control-label">
            {t('iAgreeWith')}{' '}
            <a
              target="_blank"
              href="https://api.ringdoc.ro/ro/pages/termeni-si-conditii"
              rel="noreferrer"
            >
              {t('termsAndConditions')}
            </a>
          </label>
        </div>

        <div className="custom-control custom-checkbox green-checkbox align-center-checkbox left-justify-content">
          <input
            className={`custom-control-input ${
              requiredFieldsError && !agreeStripeTermsAndConditions && 'is-invalid'
            }`}
            id="agree_stripe"
            type="checkbox"
            checked={agreeStripeTermsAndConditions}
            onChange={() => setAgreeStripeTermsAndConditions(!agreeStripeTermsAndConditions)}
          />
          <label htmlFor="agree_stripe" className="custom-control-label">
            {t('iAgreeWith')}{' '}
            <a
              target="_blank"
              href="https://api.ringdoc.ro/ro/pages/termeni-stripe"
              rel="noreferrer"
            >
              {t('stripeTermsAndConditions')}
            </a>
          </label>
        </div>

        <div className="custom-control custom-checkbox green-checkbox align-center-checkbox left-justify-content">
          <input
            className={`custom-control-input ${
              requiredFieldsError && !agreePrivacyPolicy && 'is-invalid'
            }`}
            id="agree_policy"
            type="checkbox"
            checked={agreePrivacyPolicy}
            onChange={() => setAgreePrivacyPolicy(!agreePrivacyPolicy)}
          />
          <label htmlFor="agree_policy" className="custom-control-label">
            {t('iUnderstandAnd')}{' '}
            <a
              target="_blank"
              href="https://api.ringdoc.ro/ro/pages/politica-de-confidentilitate"
              rel="noreferrer"
            >
              {t('privacyTitle')}
            </a>
          </label>
        </div>
        <div className="custom-control custom-checkbox green-checkbox align-center-checkbox left-justify-content">
          <input
            className={`custom-control-input ${
              requiredFieldsError && !agreeDataIsReal && 'is-invalid'
            }`}
            id="real_data"
            type="checkbox"
            checked={agreeDataIsReal}
            onChange={() => setAgreeDataIsReal(!agreeDataIsReal)}
          />
          <label htmlFor="real_data" className="custom-control-label">
            {t('olderAndRealData')}
          </label>
        </div>
        <div className="custom-control custom-checkbox green-checkbox align-center-checkbox left-justify-content">
          <input
            className="custom-control-input"
            id="agree_marketing"
            type="checkbox"
            checked={marketingNotificationSetting}
            onChange={() => setMarketingNotificationSetting(!marketingNotificationSetting)}
          />
          <label htmlFor="agree_marketing" className="custom-control-label">
            {t('terms&commMessage')}
          </label>
        </div>
        <button type="submit" className="green-button">
          {t('doctorApp.createAccount')}
        </button>
        <hr />
        <button type="button" onClick={handleLogin} className="white-button position-relative">
          {t('doctorApp.alreadyHaveAccount')}
          <CustomIcon
            className="custom-icon"
            color={Colors.darkGrey}
            size="24"
            icon="Right_pointer"
          />
        </button>
      </form>
    </section>
  );
};

export default DoctorRegister;
