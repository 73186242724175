import { actionChannel, call, fork, put, take } from 'redux-saga/effects';
import { fetchSettingsError, fetchSettingsSuccess } from '../actions/SettingsActions';
import { getSettingsAPI } from '../api/SettingsApi';
import { FETCH_SETTINGS_REQUEST } from '../types/SettingsTypes';
import { settingsSelector } from '../selector/SettingsSelector';

function* getSettings(actionType) {
  const translationsChanel = yield actionChannel(actionType);
  while (true) {
    yield take(translationsChanel);
    try {
      const response = yield call(getSettingsAPI);
      yield put(fetchSettingsSuccess(settingsSelector(response.data)));
    } catch ({ message }) {
      yield put(fetchSettingsError({ message }));
    }
  }
}

function* settingsSaga() {
  yield fork(getSettings, FETCH_SETTINGS_REQUEST);
}

export default settingsSaga;
