export const GET_FAMILY_MEMBERS_REQUEST = 'GET_FAMILY_MEMBERS_REQUEST';
export const GET_FAMILY_MEMBERS_SUCCESS = 'GET_FAMILY_MEMBERS_SUCCESS';
export const GET_FAMILY_MEMBERS_ERROR = 'GET_FAMILY_MEMBERS_ERROR';

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';

export const ADD_FAMILY_MEMBER_REQUEST = 'ADD_FAMILY_MEMBER_REQUEST';
export const ADD_FAMILY_MEMBER_SUCCESS = 'ADD_FAMILY_MEMBER_SUCCESS';
export const ADD_FAMILY_MEMBER_ERROR = 'ADD_FAMILY_MEMBER_ERROR';

export const UPDATE_FAMILY_MEMBER_REQUEST = 'UPDATE_FAMILY_MEMBER_REQUEST';
export const UPDATE_FAMILY_MEMBER_SUCCESS = 'UPDATE_FAMILY_MEMBER_SUCCESS';
export const UPDATE_FAMILY_MEMBER_ERROR = 'UPDATE_FAMILY_MEMBER_ERROR';

export const REMOVE_FAMILY_MEMBER_REQUEST = 'REMOVE_FAMILY_MEMBER_REQUEST';
export const REMOVE_FAMILY_MEMBER_SUCCESS = 'REMOVE_FAMILY_MEMBER_SUCCESS';
export const REMOVE_FAMILY_MEMBER_ERROR = 'REMOVE_FAMILY_MEMBER_ERROR';

export const ADD_FAMILY_MEMBER_DISEASE_REQUEST = 'ADD_FAMILY_MEMBER_DISEASE_REQUEST';
export const ADD_FAMILY_MEMBER_DISEASE_SUCCESS = 'ADD_FAMILY_MEMBER_DISEASE_SUCCESS';
export const ADD_FAMILY_MEMBER_DISEASE_ERROR = 'ADD_FAMILY_MEMBER_DISEASE_ERROR';

export const DELETE_FAMILY_MEMBER_DISEASE_REQUEST = 'DELETE_FAMILY_MEMBER_DISEASE_REQUEST';
export const DELETE_FAMILY_MEMBER_DISEASE_SUCCESS = 'DELETE_FAMILY_MEMBER_DISEASE_SUCCESS';
export const DELETE_FAMILY_MEMBER_DISEASE_ERROR = 'DELETE_FAMILY_MEMBER_DISEASE_ERROR';

export const UPDATE_FAMILY_MEMBER_DISEASE_REQUEST = 'UPDATE_FAMILY_MEMBER_DISEASE_REQUEST';
export const UPDATE_FAMILY_MEMBER_DISEASE_SUCCESS = 'UPDATE_FAMILY_MEMBER_DISEASE_SUCCESS';
export const UPDATE_FAMILY_MEMBER_DISEASE_ERROR = 'UPDATE_FAMILY_MEMBER_DISEASE_ERROR';
