import { default as ApiConstants } from 'shared/constants/ApiConstants.json';
import ApiUtils from 'shared/modules/ApiUtils';

const getSpecializationsAPI = ({ page, limit, searchName }) => {
  const criteria = `criteria[search][type]=contains&criteria[search][value]=${searchName}`;
  return ApiUtils.getRequest(
    `${ApiConstants.GET_SPECIALIZATIONS_ENDPOINT}?page=${page}&limit=${limit}&${criteria}`,
  );
};

const getSpecializationsHomeAPI = () => {
  return ApiUtils.getRequest(`${ApiConstants.GET_SPECIALIZATIONS_HOME_ENDPOINT}`);
};

const OtherAPI = () => ApiUtils.patchRequest(ApiConstants.LOGOUT_ENDPOINT);

export { getSpecializationsAPI, getSpecializationsHomeAPI, OtherAPI };
