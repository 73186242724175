import { actionChannel, ActionPattern, call, fork, put, take } from 'redux-saga/effects';
import { Action } from 'redux';
import { goBack } from 'react-router-redux';
import {
  appointmentEndConsultationAPI,
  appointmentReportIssueAPI,
  getAppointmentSettingsAPI,
  getCurrentAppointmentAPI,
} from 'shared/redux/api/AppointmentApi';
import {
  appointmentEndConsultationError,
  appointmentEndConsultationSuccess,
  appointmentReportIssueError,
  appointmentReportIssueSuccess,
  getAppointmentSettingsError,
  getAppointmentSettingsSuccess,
  getCurrentAppointmentError,
  getCurrentAppointmentSuccess,
} from 'shared/redux/actions/AppointmentActions';
import {
  APPOINTMENT_END_CONSULTATION_REQUEST,
  APPOINTMENT_REPORT_ISSUE_REQUEST,
  GET_APPOINTMENT_SETTINGS_REQUEST,
  GET_CURRENT_APPOINTMENT_REQUEST,
} from 'shared/redux/types/AppointmentTypes';
import { appointmentSettingsSelector } from 'shared/redux/selector/AppointmentSelector';
import LocalEventsService from 'shared/services/LocalEventsService';
import Alerts from 'shared/components/Alerts';
import i18next from 'i18next';
import { videoCallGetTokenSuccess } from '../actions/VideoCallActions';

function* appointmentEndConsultation(actionType: ActionPattern<Action<any>>) {
  const appointmentEndConsultationChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(appointmentEndConsultationChannel);
    try {
      const response = yield call(appointmentEndConsultationAPI, payload);
      yield put(appointmentEndConsultationSuccess(payload));
      yield put(videoCallGetTokenSuccess({}));
      if (response?.data?.status === 'call_ended') {
        LocalEventsService.emit('callEnded', {
          appointmentId: payload.appointmentId,
          appointmentType: response.data.appointment_type.type,
          withNavigate: payload?.withNavigate ?? false, // patient redirect to doctor review
        });
      }
    } catch ({ message }) {
      yield put(appointmentEndConsultationError({ message }));
    }
  }
}

function* appointmentReportIssue(actionType: ActionPattern<Action<any>>) {
  const appointmentReportIssueChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(appointmentReportIssueChannel);
    try {
      yield call(appointmentReportIssueAPI, payload);
      yield put(appointmentReportIssueSuccess(payload));
      Alerts.simpleAlert(`${i18next.t('success')}`, `${i18next.t('alerts.appointmentReported')}`);
      yield put(goBack());
    } catch ({ message }) {
      yield put(appointmentReportIssueError({ message }));
    }
  }
}

function* getAppointmentSettings(actionType: ActionPattern<Action<any>>) {
  const appointmentSettingsChannel = yield actionChannel(actionType);
  while (true) {
    yield take(appointmentSettingsChannel);
    try {
      const response = yield call(getAppointmentSettingsAPI);
      yield put(getAppointmentSettingsSuccess(appointmentSettingsSelector(response.data)));
    } catch ({ message }) {
      yield put(getAppointmentSettingsError({ message }));
    }
  }
}

function* currentAppointmentSaga(actionType) {
  const currentAppointmentChannel = yield actionChannel(actionType);
  while (true) {
    yield take(currentAppointmentChannel);
    try {
      const response = yield call(getCurrentAppointmentAPI);
      yield put(getCurrentAppointmentSuccess({}));
      if (response?.data?.length) {
        LocalEventsService.emit('currentAppointmentData', response.data);
      } else {
        LocalEventsService.emit('noCurrentAppointment');
      }
    } catch ({ message }) {
      LocalEventsService.emit('noCurrentAppointment');
      yield put(getCurrentAppointmentError({ message }));
    }
  }
}

function* sharedAppointmentSaga() {
  yield fork(appointmentEndConsultation, APPOINTMENT_END_CONSULTATION_REQUEST);
  yield fork(appointmentReportIssue, APPOINTMENT_REPORT_ISSUE_REQUEST);
  yield fork(getAppointmentSettings, GET_APPOINTMENT_SETTINGS_REQUEST);
  yield fork(currentAppointmentSaga, GET_CURRENT_APPOINTMENT_REQUEST);
}

export default sharedAppointmentSaga;
