import { AnyAction } from 'redux';
import {
  UPDATE_SPECIALIZATIONS_PRICES_ERROR,
  UPDATE_SPECIALIZATIONS_PRICES_REQUEST,
  UPDATE_SPECIALIZATIONS_PRICES_SUCCESS,
} from 'shared/redux/types/SpecializationsTypes';

export const updateSpecializationPricesRequest = (payload): AnyAction => ({
  type: UPDATE_SPECIALIZATIONS_PRICES_REQUEST,
  payload,
});

export const updateSpecializationPricesSuccess = (payload): AnyAction => ({
  type: UPDATE_SPECIALIZATIONS_PRICES_SUCCESS,
  payload,
});

export const updateSpecializationPricesError = (payload): AnyAction => ({
  type: UPDATE_SPECIALIZATIONS_PRICES_ERROR,
  payload,
});
