import { AnyAction } from 'redux';
import {
  GET_POSSIBLE_KINSHIP_ERROR,
  GET_POSSIBLE_KINSHIP_REQUEST,
  GET_POSSIBLE_KINSHIP_SUCCESS,
} from 'shared/redux/types/KinshipTypes';

export const getPossibleKinshipRequest = (payload: any): AnyAction => ({
  type: GET_POSSIBLE_KINSHIP_REQUEST,
  payload,
});

export const getPossibleKinshipSuccess = (payload: any): AnyAction => ({
  type: GET_POSSIBLE_KINSHIP_SUCCESS,
  payload,
});

export const getPossibleKinshipError = (payload: any): AnyAction => ({
  type: GET_POSSIBLE_KINSHIP_ERROR,
  payload,
});
