const vouchersSelector = (vouchers) => {
  const resolveMedia = (media) => {
    return {
      id: media?.id,
      isAvailable: media?.status === 'available',
      mediaFileName: media?.original_file_name,
      url:
        media?.public_url ??
        'https://storage.googleapis.com/ringdoc-uploads/assets/campaigns/pictureMedia_1659.png',
      mediaType: media?.server_mime_type,
    };
  };
  const resolveCampaign = (data) => {
    return {
      id: data.id,
      isActive: data.is_active,
      dateStart: data.date_start,
      dateEnd: data.date_end,
      discount: data.discount,
      discountType: data.discount_type,
      name: data.name,
      description: data.description,
      priority: data.priority,
      forAppointmentNo: data.for_appointment_no,
      pictureMedia: resolveMedia(data.picture_media),
    };
  };
  // eslint-disable-next-line array-callback-return
  return vouchers.map((row) => {
    return {
      id: row.id,
      code: row.code,
      name: row.name,
      isActive: row.is_active,
      dateStart: row.date_start,
      dateEnd: row.date_end,
      shortDesc: row?.description,
      discount: row.discount,
      discountType: row.discount_type,
      discountAsString: row.discount_as_string,
      maxUses: row.max_uses,
      eligible: row.eligible,
      campaign: resolveCampaign(row.campaign),
    };
  });
};

export { vouchersSelector };
