const PatientTheme = () => {
  return {
    mode: 'light',
    colors: {
      primary: '#FF6A39',
      accent: '#00CFB4',
      primaryOpacity: '#FFBE9D',
      primaryLight: 'rgba(255,106,57,0.1)',
      checkBoxDisabled: 'rgba(255,106,57,0.4)',
      green: '#4EDCCA',
      yellow: '#F4AF23',
      lightYellow: '#FEF5D9',
      red: '#EB3300',
      white: '#FFFFFF',
      backdrop: 'rgba(0, 0, 0, 0.5)',
      inputBg: 'rgba(0, 0, 0, 0.05)',
      inputBgFocused: '#FFFFFF',
      placeholder: 'rgba(0, 0, 0, 0.6)',
      placeholderFocused: 'rgba(0, 0, 0, 0.3)',
      disabled: 'rgba(0, 0, 0, 0.1)',
      border: '#E5E7E8',
      darkGrey: '#80898A',
      grey: '#BFC4C5',
      text: '#001315',
      background: '#F2F3F3',
      redOpacity: 'rgba(235, 51, 0, 0.1)',
    },
    roundness: 12,
  };
};

const DoctorTheme = () => {
  return {
    mode: 'light',
    colors: {
      primary: '#00CFB4',
      accent: '#FF6A39',
      primaryLight: 'rgba(0, 207, 180, 0.4)',
      primaryOpacity: 'rgba(0, 207, 180, 0.1)',
      checkBoxDisabled: 'rgba(0, 207, 180, 0.4)',
      green: '#4EDCCA',
      yellow: '#F4AF23',
      lightYellow: '#FEF5D9',
      red: '#EB3300',
      white: '#FFFFFF',
      backdrop: 'rgba(0, 0, 0, 0.5)',
      inputBg: 'rgba(0, 0, 0, 0.05)',
      inputBgFocused: '#FFFFFF',
      placeholder: 'rgba(0, 0, 0, 0.6)',
      placeholderFocused: 'rgba(0, 0, 0, 0.3)',
      disabled: 'rgba(0, 0, 0, 0.1)',
      border: '#E5E7E8',
      darkGrey: '#80898A',
      grey: '#BFC4C5',
      text: '#001315',
      background: '#F2F3F3',
      redOpacity: 'rgba(235, 51, 0, 0.1)',
    },
    roundness: 12,
  };
};

const ThemeExporter = {
  PatientTheme,
  DoctorTheme,
};

export default ThemeExporter;
