import 'shared/styles/permissions.css';
import patientPermImage from 'shared/assets/images/acces-permissions-patient-image.png';
import doctorPermImage from 'shared/assets/images/acces-permissions-doctor-image.png';
import { getEnv } from 'shared/services/EnvService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Utils from '../../modules/Utils';
import { requestMediaCamera } from '../../modules/PermissionsUtils';

const PermissionsScreen = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [missingCameraNotificationText, setMissingCameraNotificationText] = useState('');
  const [missingMicrophoneNotificationText, setMissingMicrophoneNotificationText] = useState('');
  const [cameraNotificationText, setCameraNotificationText] = useState('');
  const [microphoneNotificationText, setMicrophoneNotificationText] = useState('');
  const [notificationText, setNotificationText] = useState('');
  const [activatedPermissionsText, setActivatedPermissionsText] = useState('');

  useEffect(() => {
    (async () => {
      const localResponseToken = {};

      if (searchParams.get('notifications') !== 'required') {
        const camMicPermissions = await requestMediaCamera();
        Object.entries(camMicPermissions).forEach((row) => {
          if (row[1] === true || row[1] === null) {
            _.set(localResponseToken, row[0], true);
          }
        });
      }

      const responseWebToken = await firebase.getWebToken();
      Object.entries(responseWebToken).forEach((row) => {
        if (row[1] === true || row[1] === null) {
          _.set(localResponseToken, row[0], true);
        }
      });
      return localResponseToken;
    })().then((localResponseToken) => {
      let havePermissions = true;

      Object.entries(localResponseToken).forEach((row) => {
        if (row[0] === 'noAudioInput') {
          setMissingMicrophoneNotificationText(t('permissions.missingMicrophoneNotificationText'));
          havePermissions = false;
        }

        if (row[0] === 'noVideoInput') {
          setMissingCameraNotificationText(t('permissions.missingCameraNotificationText'));
          havePermissions = false;
        }

        if (row[0] === 'noAudioStreamLength') {
          setMicrophoneNotificationText(t('permissions.microphoneNotificationText'));
          havePermissions = false;
        }

        if (row[0] === 'noVideoStreamLength') {
          setCameraNotificationText(t('permissions.cameraNotificationText'));
          havePermissions = false;
        }

        if (row[0] === 'noToken') {
          setNotificationText(t('permissions.notificationText'));
          havePermissions = false;
        }
      });

      if (havePermissions) {
        setActivatedPermissionsText(t('permissions.activatedPermissionsText'));
      }
    });

    return () => {};
  }, []);

  const acceptPermissions = () => {
    if (searchParams.get('d') === 'd') {
      window.location.href = '/doctor/login';
    } else {
      window.location.href = '/patient/login';
    }
  };

  const svgSize = Utils.isMobileDevice() ? '20' : '35';

  return (
    <div
      className="permissions-bg"
      style={{
        backgroundColor:
          getEnv('APP_TYPE', true) === 'patient' ? 'rgba(0, 19, 21, 0.5)' : 'rgba(0, 19, 21, 0.5)',
      }}
    >
      {/* {!Utils.isMobileDevice() && ( */}
      {/*  <div className="circle-arrow-permission"> */}
      {/*    <CustomIcon className="custom-icon" color={Colors.darkGrey} size="50" icon="Left_arrow" /> */}
      {/*  </div> */}
      {/* )} */}
      <i className="big-arrow-permissions-icon" />
      <section className="h-100">
        <header className="container h-100">
          <div className="d-flex align-items-center justify-content-center h-100">
            <div className="permissions-page">
              <div className="left-column">
                <h2>Acces permisiuni</h2>
                <ul>
                  {missingMicrophoneNotificationText !== '' ||
                  missingCameraNotificationText !== '' ||
                  microphoneNotificationText !== '' ||
                  cameraNotificationText !== '' ||
                  notificationText !== '' ||
                  activatedPermissionsText !== '' ? (
                    <>
                      {missingMicrophoneNotificationText !== '' && (
                        <li>
                          <div className="circle-icon">
                            <CustomIcon
                              className="custom-icon"
                              color={Colors.patientColor}
                              size={svgSize}
                              icon="Microphone"
                            />
                          </div>
                          <span>{missingMicrophoneNotificationText}</span>
                        </li>
                      )}
                      {missingCameraNotificationText !== '' && (
                        <li>
                          <div className="circle-icon">
                            <CustomIcon
                              className="custom-icon"
                              color={Colors.patientColor}
                              size={svgSize}
                              icon="Video"
                            />
                          </div>
                          <span>{missingCameraNotificationText}</span>
                        </li>
                      )}
                      {microphoneNotificationText !== '' && (
                        <li>
                          <div className="circle-icon">
                            <CustomIcon
                              className="custom-icon"
                              color={Colors.patientColor}
                              size={svgSize}
                              icon="Microphone"
                            />
                          </div>
                          <span>{microphoneNotificationText}</span>
                        </li>
                      )}
                      {cameraNotificationText !== '' && (
                        <li>
                          <div className="circle-icon">
                            <CustomIcon
                              className="custom-icon"
                              color={Colors.patientColor}
                              size={svgSize}
                              icon="Video"
                            />
                          </div>
                          <span>{cameraNotificationText}</span>
                        </li>
                      )}
                      {notificationText !== '' && (
                        <li>
                          <div className="circle-icon">
                            <CustomIcon
                              className="custom-icon"
                              color={Colors.patientColor}
                              size={svgSize}
                              icon="Notifications"
                            />
                          </div>
                          <span>{notificationText}</span>
                        </li>
                      )}
                      {activatedPermissionsText !== '' && (
                        <li className="mb-5">
                          <div className="circle-icon">
                            <CustomIcon
                              className="custom-icon"
                              color={Colors.patientColor}
                              size={svgSize}
                              icon="Check"
                            />
                          </div>
                          <span>{t('permissions.activatedPermissions')}</span>
                        </li>
                      )}
                    </>
                  ) : (
                    <li className="mb-5">
                      <div className="circle-icon">
                        <CustomIcon
                          className="custom-icon"
                          color={Colors.patientColor}
                          size={svgSize}
                          icon="Transactions_Arrows"
                        />
                      </div>
                      <span>{t('permissions.checkingPermissionsText')}</span>
                    </li>
                  )}
                </ul>
                {/* eslint-disable-next-line react/button-has-type */}
                <button className="btn-activation" onClick={acceptPermissions}>
                  {t('permissions.reloadPage')}
                </button>
              </div>
              <div className="right-column">
                <img
                  className="button-image"
                  src={getEnv('APP_TYPE', true) === 'patient' ? patientPermImage : doctorPermImage}
                  alt="access permissions"
                />
              </div>
            </div>
          </div>
        </header>
      </section>
    </div>
  );
};

export default PermissionsScreen;
