export const FETCH_USER_PROFILE_REQUEST = 'FETCH_USER_PROFILE_REQUEST';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_ERROR = 'FETCH_USER_PROFILE_ERROR';
export const PATCH_USER_PROFILE_REQUEST = 'PATCH_USER_PROFILE_REQUEST';
export const PATCH_USER_PROFILE_SUCCESS = 'PATCH_USER_PROFILE_SUCCESS';
export const PATCH_USER_PROFILE_ERROR = 'PATCH_USER_PROFILE_ERROR';
export const POST_USER_PROFILE_REQUEST = 'POST_USER_PROFILE_REQUEST';
export const POST_USER_PROFILE_SUCCESS = 'POST_USER_PROFILE_SUCCESS';
export const POST_USER_PROFILE_ERROR = 'POST_USER_PROFILE_ERROR';
export const LOCALLY_UPDATE_USER_PROFILE = 'LOCALLY_UPDATE_USER_PROFILE';

export const PATCH_DOCTOR_ORGANIZATION_REQUEST = 'PATCH_DOCTOR_ORGANIZATION_REQUEST';
export const PATCH_DOCTOR_ORGANIZATION_SUCCESS = 'PATCH_DOCTOR_ORGANIZATION_SUCCESS';
export const PATCH_DOCTOR_ORGANIZATION_ERROR = 'PATCH_DOCTOR_ORGANIZATION_ERROR';

export const PATCH_DOCTOR_CV_REQUEST = 'PATCH_DOCTOR_CV_REQUEST';
export const PATCH_DOCTOR_CV_SUCCESS = 'PATCH_DOCTOR_CV_SUCCESS';
export const PATCH_DOCTOR_CV_ERROR = 'PATCH_DOCTOR_CV_ERROR';

export const ASSOCIATE_SPECIALIZATION_REQUEST = 'ASSOCIATE_SPECIALIZATION_REQUEST';
export const ASSOCIATE_SPECIALIZATION_SUCCESS = 'ASSOCIATE_SPECIALIZATION_SUCCESS';
export const ASSOCIATE_SPECIALIZATION_ERROR = 'ASSOCIATE_SPECIALIZATION_ERROR';

export const GET_MY_SPECIALIZATIONS_REQUEST = 'GET_MY_SPECIALIZATIONS_REQUEST';
export const GET_MY_SPECIALIZATIONS_SUCCESS = 'GET_MY_SPECIALIZATIONS_SUCCESS';
export const GET_MY_SPECIALIZATIONS_ERROR = 'GET_MY_SPECIALIZATIONS_ERROR';

export const SET_AVAILABILITY_REQUEST = 'SET_AVAILABILITY_REQUEST';
export const SET_AVAILABILITY_SUCCESS = 'SET_AVAILABILITY_SUCCESS';
export const SET_AVAILABILITY_ERROR = 'SET_AVAILABILITY_ERROR';

export const LOCALLY_ADD_SPECIALIZATION = 'LOCALLY_ADD_SPECIALIZATION';
export const LOCALLY_REMOVE_SPECIALIZATION = 'LOCALLY_REMOVE_SPECIALIZATION';

export const LOCALLY_ADD_CITY = 'LOCALLY_ADD_CITY';

export const FETCH_TITLES_REQUEST = 'FETCH_TITLES_REQUEST';
export const FETCH_TITLES_SUCCESS = 'FETCH_TITLES_SUCCESS';
export const FETCH_TITLES_ERROR = 'FETCH_TITLES_ERROR';
