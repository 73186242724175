import { Form, ListGroup, Modal } from 'react-bootstrap';
import Calendar from 'react-calendar';
import { useEffect, useState } from 'react';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  addDoctorExceptionTimetableRequest,
  locallyAddDate,
  patchDoctorExceptionTimetableRequest,
} from 'shared/redux/actions/DoctorTimetableActions';
import { getLocalDateState } from 'shared/redux/src/StatesGetter';
import Alerts from 'shared/components/Alerts';
import { generateTimeSlots } from 'shared/modules/DateTimeUtils';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Utils from '../../../shared/modules/Utils';

const AddExceptionModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    setModalAddExceptionShow,
    setCurrentAddExceptionModal,
    showModalAddException,
    currentAddExceptionModal,
  } = props;
  const { type = 'add', action = 'server', data = { id: 0 } } = currentAddExceptionModal;
  const modalAddExceptionClose = () => {
    setModalAddExceptionShow(false);
    setCurrentAddExceptionModal({ type: '', data: {} });
    // dispatch(locallyAddDate({}));
  };
  const localDate = useSelector(getLocalDateState, shallowEqual);
  const [selectedDate, setSelectedDate] = useState(localDate?.date ?? new Date());

  useEffect(() => {
    if (type === 'edit') {
      setSelectedDate(new Date(localDate?.date));
    }
  }, [type]);
  const onChange = (d) => {
    if (type === 'edit') return;
    setSelectedDate(d);
    dispatch(locallyAddDate({ date: d }));
  };
  const [dayOff, setIsDayOff] = useState(data?.dayOff ?? false);
  const [startHour, setStartHour] = useState(
    Utils.getTypeAddExceptionOrAvailability(data, 15, 'HH', type),
  );
  const [startMin, setStartMin] = useState(
    Utils.getTypeAddExceptionOrAvailability(data, 15, 'MM', type),
  );
  const [endHour, setEndHour] = useState(
    Utils.getTypeAddExceptionOrAvailability(data, 45, 'HH', type),
  );
  const [endMin, setEndMin] = useState(
    Utils.getTypeAddExceptionOrAvailability(data, 45, 'MM', type),
  );
  useEffect(() => {
    setStartHour(Utils.getTypeAddExceptionOrAvailability(data, 15, 'HH', type));
    setStartMin(Utils.getTypeAddExceptionOrAvailability(data, 15, 'MM', type));
    setEndHour(Utils.getTypeAddExceptionOrAvailability(data, 45, 'HH', type));
    setEndMin(Utils.getTypeAddExceptionOrAvailability(data, 45, 'MM', type));
  }, [type]);
  const onTimeSelected = (dateType, timePart, slot) => {
    if (dateType === 'start') {
      if (timePart === 'hour') {
        setStartHour(slot);
      } else {
        setStartMin(slot);
      }
      return;
    }
    if (timePart === 'hour') {
      setEndHour(slot);
    } else {
      setEndMin(slot);
    }
  };
  const toggleExceptionType = () => {
    setIsDayOff(!dayOff);
  };
  useEffect(() => {
    if (type === 'edit') {
      // dispatch(locallyAddDate({ date: data.date }));
      setIsDayOff(data.type === 'day_off');
    }
  }, [type]);
  const addException = (e) => {
    e.preventDefault();
    if (!selectedDate) {
      Alerts.simpleAlert(t('error'), t('allFieldsMandatory'));
      return;
    }
    let payloadToSend;
    if (dayOff) {
      payloadToSend = { type: 'day_off', date: dayjs(selectedDate).format() };
    } else {
      const datePayload = selectedDate
        ? dayjs(selectedDate).format('YYYY-MM-DD')
        : dayjs().format('YYYY-MM-DD');
      payloadToSend = {
        type: 'interval',
        date: datePayload,
        timeStart: `${startHour}:${startMin}`,
        timeStop: `${endHour}:${endMin}`,
      };
    }
    dispatch(
      addDoctorExceptionTimetableRequest({
        toRequest: payloadToSend,
        action,
      }),
    );
    modalAddExceptionClose();
  };
  const editException = (e, dataPayload) => {
    e.preventDefault();
    let payloadToSend;
    if (dayOff) {
      payloadToSend = { type: 'day_off', date: dataPayload?.date };
    } else {
      payloadToSend = {
        type: 'interval',
        timeStart: `${startHour}:${startMin}`,
        timeStop: `${endHour}:${endMin}`,
        date: dataPayload?.date,
      };
    }
    dispatch(
      patchDoctorExceptionTimetableRequest({
        toRequest: {
          id: dataPayload?.id,
          data: payloadToSend,
        },
      }),
    );
    modalAddExceptionClose();
  };
  return (
    <Modal
      show={showModalAddException}
      onHide={modalAddExceptionClose}
      animation
      centered
      className="modal-add-exception medic-modal"
    >
      <Modal.Header closeButton closeLabel={t('close2')}>
        <Modal.Title>
          {type === 'add' ? t('addException') : t('modifyException')}
          <div className="data">{t('exceptionSubtitle')}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="calendar" style={{ pointerEvents: type === 'edit' ? 'none' : 'auto' }}>
          <div className="subtitle">{t('chooseDate')}</div>
          <Calendar
            onChange={onChange}
            value={new Date(selectedDate)}
            minDate={new Date()}
            locale={t('calendar.locale')}
            defaultView="month"
            maxDetail="month"
            minDetail="month"
            nextLabel={
              <CustomIcon
                className="custom-icon"
                color={Colors.medicColor}
                size="24"
                icon="Right_pointer"
              />
            }
            prevLabel={
              <CustomIcon
                className="custom-icon rotate-180"
                color={Colors.medicColor}
                size="24"
                icon="Right_pointer"
              />
            }
            showNavigation
          />
        </div>
        <div className="free-day">
          <ListGroup className="switch-box">
            <ListGroup.Item>
              <Form.Check
                type="switch"
                checked={dayOff}
                onChange={toggleExceptionType}
                className="custom-switch green-switch"
                id="free-day"
                label={t('dayOff')}
              />
            </ListGroup.Item>
          </ListGroup>
        </div>
        {!dayOff && (
          <>
            <div className="subtitle">{t('dayInterval')}</div>
            <div className="interval-hour">
              <div className="form-select-label">
                <select
                  onChange={(event) => onTimeSelected('start', 'hour', event.target.value)}
                  value={startHour}
                  required
                  id="interval1"
                  name="interval1"
                  className="custom-select"
                >
                  {generateTimeSlots(24).map((h) => {
                    return <option key={`interval1_${h}`}>{h}</option>;
                  })}
                </select>
              </div>
              <span>:</span>
              <div className="form-select-label">
                <select
                  onChange={(event) => onTimeSelected('start', 'min', event.target.value)}
                  value={startMin}
                  required
                  id="interval2"
                  name="interval2"
                  className="custom-select"
                >
                  {generateTimeSlots(60).map((h) => {
                    return <option key={`interval2_${h}`}>{h}</option>;
                  })}
                </select>
              </div>
              <span>-</span>
              <div className="form-select-label">
                <select
                  onChange={(event) => onTimeSelected('end', 'hour', event.target.value)}
                  value={endHour}
                  required
                  id="interval3"
                  name="interval3"
                  className="custom-select"
                >
                  {generateTimeSlots(24).map((h) => {
                    return <option key={`interval3_${h}`}>{h}</option>;
                  })}
                </select>
              </div>
              <span>:</span>
              <div className="form-select-label">
                <select
                  onChange={(event) => onTimeSelected('end', 'min', event.target.value)}
                  value={endMin}
                  required
                  id="interval4"
                  name="interval4"
                  className="custom-select"
                >
                  {generateTimeSlots(60).map((h) => {
                    return <option key={`interval4_${h}`}>{h}</option>;
                  })}
                </select>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="green-button"
          onClick={(e) => (type === 'add' ? addException(e) : editException(e, data))}
        >
          {type === 'add' ? t('addException') : t('modifyException')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddExceptionModal;
