import '../styles/page-not-found-404.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import FooterSection from '../components/FooterSection';
import Colors from '../themes/Colors';
import CustomIcon from '../components/CustomIcon';

const WorkInProgress = ({ fullPage = false }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const randomScreen = Math.floor(Math.random() * 9);
  const screens = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <div className={`page-not-found-404 d-flex flex-column ${fullPage ? 'min-vh-100' : ''}`}>
      <div className={`container-fluid d-flex flex-column ${fullPage ? 'min-vh-100' : ''}`}>
        <div className={`row d-flex flex-column ${fullPage ? 'min-vh-100' : ''}`}>
          {fullPage && (
            <header>
              <Link className="logo cursor-pointer" to="/">
                <i className="ringdoc-color-logo" />
              </Link>
            </header>
          )}

          <main className="container mt-auto mb-auto">
            <section>
              <div className="animation">
                <CustomIcon
                  className="custom-icon"
                  color={Colors.darkGrey}
                  size="288"
                  icon={`${screens[randomScreen]}`}
                />
              </div>
              <h1>{t('pageNotFound')}</h1>
              <button type="button" onClick={() => navigate('/')} className="orange-button">
                Înapoi la pagina principală
              </button>
              <button
                type="button"
                onClick={() => navigate('/patient/contact')}
                className="white-button"
              >
                Scrie-ne aici
              </button>
            </section>
          </main>

          {fullPage && <FooterSection />}
        </div>
      </div>
    </div>
  );
};

export default WorkInProgress;
