import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getDoctorAppointmentDetailsState } from '../../../shared/redux/src/StatesGetter';
import Medication from '../../components/appointments/Medication';
import { getAppointmentDetailsRequest } from '../../../shared/redux/actions/DoctorAppointmentsActions';

const DoctorFinalConsultationPrescriptionMedication = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();

  const { prescription } = useSelector(getDoctorAppointmentDetailsState, shallowEqual);
  const { id: appointmentId, patient } = useSelector(
    getDoctorAppointmentDetailsState,
    shallowEqual,
  );

  const [addNewMedication, setAddNewMedication] = useState(false);
  const [currentMedication, setCurrentMedication] = useState({
    id: '',
    name: '',
    concentration: '',
    administration: '',
    quantity: '',
    duration: '',
    type: '',
  });

  const conclusionAddMedication = () => {
    dispatch(getAppointmentDetailsRequest({ id: appointmentId }));
    setTimeout(() => {
      window.location.href = `/doctor/final-consultation/${appointmentId}/prescription`;
    }, 1000);
  };

  useEffect(() => {
    const localMedication = prescription.medications.filter((medication) => {
      // @ts-ignore
      return medication.id === parseInt(params.medicationId, 10);
    });
    setCurrentMedication(localMedication[0]);
  }, []);

  return (
    <section className="final-consultation">
      <div className="title-section">
        <div className="small-title-text">{t('reviewFindingsFor')}</div>
        <h1>{`${patient?.firstName} ${patient?.lastName}`}</h1>
      </div>
      <Medication
        setAddNewMedication={setAddNewMedication}
        prescription={prescription}
        appointmentId={appointmentId}
        conclusionAddMedication={conclusionAddMedication}
        currentMedication={currentMedication}
        setCurrentMedication={setCurrentMedication}
      />
    </section>
  );
};

export default DoctorFinalConsultationPrescriptionMedication;
