import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { getEnv, authFooter, isDoctor, consultationFlowFooter } from 'shared/services/EnvService';
import AuthBackgroundImage from 'doctor/assets/images/auth-background-image.png';
import { useTranslation } from 'react-i18next';
import Colors from 'shared/themes/Colors';
import dayjs from 'dayjs';
import { isMobile } from 'react-device-detect';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import CustomIcon from './CustomIcon';
import { forceLogoutRequest, requestLogout } from '../redux/actions/AuthActions';
import StorageService from '../services/StorageService';
import { getAuthState } from '../redux/src/StatesGetter';

const FooterSection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);

  const onChangePlatform = (platform: string) => {
    dispatch(requestLogout({}));
    localStorage.setItem('CHANGE_PLATFORM', platform);
    StorageService.setData('IS_CHANGE_PLATFORM', true);

    setTimeout(() => {
      if (platform === 'doctor') {
        navigate('/doctor/landing');
      } else {
        navigate('/');
      }
    }, 500);
  };

  return (
    <footer
      className={`${authFooter() ? 'auth-footer' : ''} ${
        consultationFlowFooter() ? 'consultation-flow-footer' : ''
      }`}
    >
      {authFooter() && (
        <img src={AuthBackgroundImage} className="auth-background-image" alt="Ringdoc Medic" />
      )}
      {!consultationFlowFooter() ? (
        <div className="container">
          {!isMobile && (
            <div className="column-login">
              {!isDoctor(true) ? (
                <div
                  // to="/doctor/landing"
                  className="green-button cursor-pointer change-platform-button"
                  onClick={(e) => {
                    e.preventDefault();
                    onChangePlatform('doctor');
                  }}
                >
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.white}
                    size="20"
                    icon="Doctors"
                  />
                  {t('footer.authDoctors')}
                </div>
              ) : (
                <div
                  // to="/"
                  className="orange-button cursor-pointer change-platform-button"
                  onClick={(e) => {
                    e.preventDefault();
                    onChangePlatform('patient');
                  }}
                >
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.white}
                    size="20"
                    icon="Profil"
                  />
                  {t('footer.authPatients')}
                </div>
              )}
            </div>
          )}
          <div className="navigation">
            <div className="column">
              <div className="title">{t('footer.patients')}</div>
              <ul>
                <li>
                  <Link to="/patient/how-it-works">{t('footer.howItWorks')}</Link>
                </li>
                <li>
                  <HashLink smooth to="/patient/how-it-works#faq">
                    {t('support.faq')}
                  </HashLink>
                </li>
                <li>
                  <Link to="/patient/login">{t('patientApp.login')}</Link>
                </li>
                <li>
                  <Link to="/patient/register">{t('patientApp.createAccount')}</Link>
                </li>
              </ul>
            </div>

            <div className="column">
              <div className="title">{t('footer.doctors')}</div>
              <ul>
                <li>
                  <Link to="/doctor/how-it-works">{t('footer.howItWorks')}</Link>
                </li>
                <li>
                  <HashLink smooth to="/doctor/how-it-works#faq">
                    {t('support.faq')}
                  </HashLink>
                </li>
                <li>
                  <Link to="/doctor/login">{t('patientApp.login')}</Link>
                </li>
                <li>
                  <Link to="/doctor/register">{t('patientApp.createAccount')}</Link>
                </li>
              </ul>
            </div>
            <div className="column">
              <div className="title">Ringdoc</div>
              <ul>
                <li>
                  <Link to={`${isDoctor(true) ? '/doctor/about-us' : '/patient/about-us'}`}>
                    {t('support.aboutUsRingDoc')}
                  </Link>
                </li>
                <li>
                  <Link to="/support/terms-and-conditions">{t('support.terms2')}</Link>
                </li>
                <li>
                  <Link to="/support/privacy-policy">{t('support.policy')}</Link>
                </li>
                <li>
                  <Link to="/support/cookie-policy">{t('support.cookie-policy')}</Link>
                </li>
                <li>
                  <Link to={`${isDoctor(true) ? '/doctor/contact' : '/patient/contact'}`}>
                    {t('contact')}
                  </Link>
                </li>
                <li>
                  <Link to={`${isDoctor(true) ? '/doctor/partners' : '/patient/partners'}`}>
                    {t('partners.partners')}
                  </Link>
                </li>
                <li>
                  <a href="https://blog.ringdoc.ro">{t('blog')}</a>
                </li>
              </ul>
            </div>
          </div>

          {isMobile && (
            <div className="platform-login-button-container">
              <div className="column-login">
                {!isDoctor(true) ? (
                  <Link to="/doctor/landing" className="green-button">
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.white}
                      size="20"
                      icon="Doctors"
                    />
                    {t('footer.authDoctors')}
                  </Link>
                ) : (
                  <Link to="/" className="orange-button">
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.white}
                      size="20"
                      icon="Profil"
                    />
                    {t('footer.authPatients')}
                  </Link>
                )}
              </div>
            </div>
          )}

          <div className="footer-bottom">
            <div className="copyright">
              <Link className="logo cursor-pointer" to="/">
                <i className="ringdoc-color-logo" />
              </Link>
              {`Copyright © ${dayjs().format('YYYY')} ${getEnv('APP_NAME')} - ${t(
                'support.allRightsReserved',
              )}`}
              . {`${t('support.version')} ${getEnv('VERSION')}`}
            </div>
            <div className="social-media">
              <a target="_blank" href="https://www.facebook.com/ringdocapp" rel="noreferrer">
                <CustomIcon
                  className="custom-icon"
                  color={authFooter() ? Colors.white : Colors.mediumGrey}
                  size="30"
                  icon="Fb"
                />
              </a>
              <a target="_blank" href="https://www.instagram.com/ringdocapp" rel="noreferrer">
                <i className="instagram-icon" />
              </a>
              <a target="_blank" href="https://www.youtube.com/@ringdocapp" rel="noreferrer">
                <CustomIcon
                  className="custom-icon"
                  color={authFooter() ? Colors.white : Colors.mediumGrey}
                  size="30"
                  icon="Play"
                />
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="footer-bottom">
            <div className="copyright">
              {`Copyright © ${dayjs().format('YYYY')} ${getEnv('APP_NAME')} - ${t(
                'support.allRightsReserved',
              )}`}
              . {`${t('support.version')} ${getEnv('VERSION')}`}
            </div>
          </div>
        </div>
      )}
    </footer>
  );
};

export default FooterSection;
