import { Routes, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import DoctorAuth from 'doctor/screens/auth/DoctorAuth';
import DoctorLogin from 'doctor/screens/auth/DoctorLogin';
import DoctorForgotPassword from 'doctor/screens/auth/DoctorForgotPassword';
import DoctorRegister from 'doctor/screens/auth/DoctorRegister';
import DoctorRegisterPersonalData from 'doctor/screens/auth/DoctorRegisterPersonalData';
import DoctorRegisterInvoice from 'doctor/screens/auth/DoctorRegisterInvoice';
import DoctorRegisterSpecialization from 'doctor/screens/auth/DoctorRegisterSpecialization';
import DoctorAccount from 'doctor/screens/account/DoctorAccount';
import DoctorAccountProfile from 'doctor/screens/account/DoctorAccountProfile';
import DoctorVideoCall from 'doctor/screens/videoCall/DoctorVideoCall';
import DoctorFinalConsultationReviewConclusions from 'doctor/screens/finalConsultation/DoctorFinalConsultationReviewConclusions';
import DoctorHome from 'doctor/screens/home/DoctorHome';
import DoctorNotifications from 'doctor/screens/notifications/DoctorNotifications';
import DoctorAppointments from 'doctor/screens/appointment/DoctorAppointments';
import DoctorAvailabilitySchedule from 'doctor/screens/availabilitySchedule/DoctorAvailabilitySchedule';
import DoctorConsultationDetails from 'doctor/screens/consultationDetails/DoctorConsultationDetails';
import DoctorRates from 'doctor/screens/rates/DoctorRates';
import DoctorWallet from 'doctor/screens/wallet/DoctorWallet';
import DoctorWalletUnfolded from 'doctor/screens/wallet/DoctorWalletUnfolded';
import SettingsTwilioTermsConditions from 'shared/screens/settings/SettingsTwilioTermsConditions';
import SettingsStripeTermsConditions from 'shared/screens/settings/SettingsStripeTermsConditions';
import SettingsAboutUs from 'shared/screens/settings/SettingsAboutUs';
import PrivateRoute from 'shared/components/PrivateRoute';
import AccountActivation from 'doctor/screens/auth/AccountActivation';
import DoctorRecommend from 'doctor/screens/mlm/DoctorRecommend';
import WorkInProgress from 'shared/screens/WorkInProgress';
import PageNotFound from 'shared/screens/PageNotFound';
import DoctorResetPassword from 'doctor/screens/auth/DoctorResetPassword';
import ResendConfirmationToken from '../shared/screens/auth/ResendConfirmationToken';
import DoctorFinalConsultationPrescription from './screens/finalConsultation/DoctorFinalConsultationPrescription';
import DoctorFinalConsultationPrescriptionMedication from './screens/finalConsultation/DoctorFinalConsultationPrescriptionMedication';
import DoctorConsultationDetailsTabRecommendationPrescription from './components/consultationDetails/DoctorConsultationDetailsTabRecommendationPrescription';
import DoctorConsultationDetailsTabRecommendationMedication from './components/consultationDetails/DoctorConsultationDetailsTabRecommendationMedication';

const AuthRoutes = [
  {
    path: 'auth',
    key: 'DOCTOR_ROOT_AUTH',
    exact: true,
    component: <DoctorAuth />,
  },
  {
    path: 'login',
    key: 'DOCTOR_ROOT_LOGIN',
    exact: true,
    component: <DoctorLogin />,
  },
  {
    path: 'activate-account',
    key: 'DOCTOR_ROOT_ACTIVATE_ACCOUNT',
    exact: true,
    component: <AccountActivation />,
  },
  {
    path: 'register',
    key: 'DOCTOR_ROOT_REGISTER',
    exact: true,
    component: <DoctorRegister />,
  },
  {
    path: 'register-step-personal-data',
    key: 'DOCTOR_ROOT_REGISTER_PERSONAL_DATA',
    exact: true,
    component: <DoctorRegisterPersonalData />,
  },
  {
    path: 'register-step-invoice',
    key: 'DOCTOR_ROOT_REGISTER_INVOICE',
    exact: true,
    component: <DoctorRegisterInvoice />,
  },
  {
    path: 'register-step-specialization',
    key: 'DOCTOR_ROOT_REGISTER_SPECIALIZATION',
    exact: true,
    component: <DoctorRegisterSpecialization />,
  },
  {
    path: 'forgot-password',
    key: 'DOCTOR_ROOT_FORGOT_PASSWORD',
    exact: true,
    component: <DoctorForgotPassword />,
  },
  {
    path: 'reset-password',
    key: 'DOCTOR_ROOT_RESET_PASSWORD',
    exact: true,
    component: <DoctorResetPassword />,
  },
  {
    path: 'resend-confirmation-token',
    key: 'DOCTOR_ROOT_RESEND_CONFIRMATION_TOKEN',
    exact: true,
    component: <ResendConfirmationToken />,
  },
];

const StaticRoutes = [
  {
    path: 'support/twilio-terms-and-conditions',
    key: 'DOCTOR_TWILIO',
    exact: true,
    component: <SettingsTwilioTermsConditions />,
  },
  {
    path: 'support/stripe-terms-and-conditions',
    key: 'DOCTOR_STRIPE',
    exact: true,
    component: <SettingsStripeTermsConditions />,
  },
  {
    path: 'support/about',
    key: 'DOCTOR_ABOUT',
    exact: true,
    component: <SettingsAboutUs />,
  },
  {
    path: 'final-consultation/:appointmentId/review-conclusions',
    key: 'DOCTOR_ROOT_FINAL_CONSULTATION_REVIEW_CONCLUSIONS',
    exact: true,
    component: <DoctorFinalConsultationReviewConclusions />,
  },
  {
    path: 'final-consultation/:appointmentId/prescription',
    key: 'DOCTOR_ROOT_FINAL_CONSULTATION_PRESCRIPTION',
    exact: true,
    component: <DoctorFinalConsultationPrescription />,
  },
  {
    path: 'final-consultation/:prescriptionId/medication',
    key: 'DOCTOR_ROOT_FINAL_CONSULTATION_PRESCRIPTION_MEDICATION',
    exact: true,
    component: <DoctorFinalConsultationPrescriptionMedication />,
  },
  {
    path: 'final-consultation/:prescriptionId/medication/:medicationId',
    key: 'DOCTOR_ROOT_FINAL_CONSULTATION_PRESCRIPTION_MEDICATION_EDIT',
    exact: true,
    component: <DoctorFinalConsultationPrescriptionMedication />,
  },
];

const SettingsRoutes = [
  {
    path: '/account-profile/:tab',
    key: 'DOCTOR_ROOT_ACCOUNT_PROFILE',
    exact: true,
    component: <DoctorAccountProfile />,
  },
  {
    path: 'account',
    key: 'DOCTOR_ROOT_ACCOUNT',
    exact: true,
    component: <DoctorAccount />,
  },
];

const AccountRoutes = [
  {
    path: 'home',
    key: 'DOCTOR_ROOT_HOME',
    exact: true,
    component: <DoctorHome />,
  },
  {
    path: 'recommend',
    key: 'DOCTOR_ROOT_RECOMMEND',
    exact: true,
    component: <DoctorRecommend />,
  },
  {
    path: 'rates',
    key: 'DOCTOR_ROOT_RATES',
    exact: true,
    component: <DoctorRates />,
  },
  {
    path: 'wallet/:tab',
    key: 'DOCTOR_ROOT_WALLET',
    exact: true,
    component: <DoctorWallet />,
  },
  {
    path: 'wallet/unfolded',
    key: 'DOCTOR_ROOT_WALLET_UNFOLDED',
    exact: true,
    component: <DoctorWalletUnfolded />,
  },
  {
    path: 'appointments/:tab',
    key: 'DOCTOR_ROOT_APPOINTMENTS',
    exact: true,
    component: <DoctorAppointments />,
  },
  {
    path: 'consultation-details/:appointmentId',
    key: 'DOCTOR_ROOT_CONSULTATIONS_DETAILS',
    exact: true,
    component: <DoctorConsultationDetails />,
  },
  {
    path: 'consultation-details/:appointmentId/prescription',
    key: 'DOCTOR_ROOT_CONSULTATIONS_DETAILS_RECOMMENDATION',
    exact: true,
    component: <DoctorConsultationDetailsTabRecommendationPrescription />,
  },
  {
    path: 'consultation-details/:prescriptionId/medication',
    key: 'DOCTOR_ROOT_CONSULTATIONS_DETAILS_RECOMMENDATION_MEDICATION',
    exact: true,
    component: <DoctorConsultationDetailsTabRecommendationMedication />,
  },
  {
    path: 'consultation-details/:prescriptionId/medication/:medicationId',
    key: 'DOCTOR_ROOT_CONSULTATIONS_DETAILS_RECOMMENDATION_MEDICATION_EDIT',
    exact: true,
    component: <DoctorConsultationDetailsTabRecommendationMedication />,
  },
  {
    path: 'availability-schedule',
    key: 'DOCTOR_ROOT_AVAILABILITY_SCHEDULE',
    exact: true,
    component: <DoctorAvailabilitySchedule />,
  },
];

const AppointmentRoutes = [
  {
    path: 'video-call',
    key: 'DOCTOR_ROOT_VIDEO_CALL',
    exact: true,
    component: <DoctorVideoCall />,
  },
];

const HomeRoutes = [
  {
    path: 'notifications',
    key: 'DOCTOR_ROOT_NOTIFICATIONS',
    exact: true,
    component: <DoctorNotifications />,
  },
];

const DoctorMenuRoutes = [
  {
    path: 'home',
    key: 'DOCTOR_HOME',
    exact: true,
    component: <DoctorHome />,
    className: 'home',
    name: 'mainScreen',
    classIcon: 'home-icon',
    iconName: 'Home',
  },
  {
    path: 'appointments/:tab',
    key: 'DOCTOR_APPOINTMENTS',
    exact: true,
    component: <DoctorAppointments />,
    className: 'appointments',
    name: 'appointments.appointments',
    classIcon: 'appointments-icon',
    iconName: 'Appointments',
  },
  {
    path: 'wallet/:tab',
    key: 'DOCTOR_WALLET',
    exact: true,
    component: <DoctorWallet />,
    className: 'wallet',
    name: 'wallet.header',
    classIcon: 'wallet-icon',
    iconName: 'Portofel',
  },
  {
    path: 'recommend',
    key: 'DOCTOR_RECOMMEND',
    exact: true,
    component: <DoctorRecommend />,
    className: 'recommend',
    name: 'mlm.recommend',
    classIcon: 'recommend-icon',
    iconName: 'Recenzii',
  },
  {
    path: 'account',
    key: 'DOCTOR_ACCOUNT',
    exact: true,
    component: <DoctorAccount />,
    className: 'account',
    name: 'myAccount',
    classIcon: 'account-icon',
    iconName: 'Profil',
  },
  {
    path: 'notifications',
    key: 'DOCTOR_NOTIFICATIONS',
    exact: true,
    component: <DoctorNotifications />,
    className: 'notifications',
    name: 'notifications',
    classIcon: 'notifications-icon',
    iconName: 'Notifications',
  },
];

const DoctorPublicRoutes = [...AuthRoutes, ...StaticRoutes];

const DoctorPrivateRoutes = [
  ...HomeRoutes,
  ...AppointmentRoutes,
  ...AccountRoutes,
  ...SettingsRoutes,
  ...StaticRoutes,
];

const DoctorRoutes = ({ isLoggedIn, maintenanceMode }) => {
  if (maintenanceMode) {
    return (
      <Routes>
        <Route path="*" element={<WorkInProgress />} />
      </Routes>
    );
  }

  return (
    <Routes>
      {DoctorPublicRoutes.map((route) => {
        return <Route key={route.key} path={route.path} element={route.component} />;
      })}
      <Route path="/" element={<PrivateRoute isLoggedIn={isLoggedIn} redirectTo="login" />}>
        {DoctorPrivateRoutes.map((route) => {
          return <Route key={route.key} path={route.path} element={route.component} />;
        })}
      </Route>
      {/* <Route path="*" element={<Navigate replace to="/404" />} /> */}
      {/* <Route path="/404" element={<PageNotFound />} /> */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

DoctorRoutes.defaultProps = {
  isLoggedIn: false,
  maintenanceMode: false,
};

DoctorRoutes.propTypes = {
  isLoggedIn: PropTypes.bool,
  maintenanceMode: PropTypes.bool,
};

export { DoctorRoutes, DoctorMenuRoutes };
