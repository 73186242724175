import i18next from 'i18next';
import dayjs from 'dayjs';

const allReviewsSelector = (reviews) => {
  return reviews.map((row) => {
    return {
      id: row?.id,
      originator: row.anonymized_name,
      rating: row.rating,
      remarks: row.remarks,
      city: row?.patient?.address?.city ?? '-',
      doctorFullName: row?.appointment?.doctor_specialization?.doctor?.full_name_with_title,
      doctorFirstName: row?.appointment?.doctor_specialization?.doctor?.first_name,
      doctorLastName: row?.appointment?.doctor_specialization?.doctor?.last_name,
      doctorImage: row?.appointment?.doctor_specialization?.doctor?.picture_media?.public_url,
      specialization:
        row?.appointment?.doctor_specialization?.specialization?.translations?.find(
          (r) => r.locale === i18next.language,
        )?.name ?? '',
      createdAt: dayjs(row.created_at).format('YYYY-MM-DD'),
    };
  });
};

export default allReviewsSelector;
