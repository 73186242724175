/**
 * @upgrade https://firebase.google.com/docs/web/modular-upgrade
 */
import firebase from '@firebase/app-compat';
import '@firebase/messaging-compat';
import PatientPushNotifier from 'shared/modules/PatientPushNotifier';
import DoctorPushNotifier from 'shared/modules/DoctorPushNotifier';
import { getEnv } from 'shared/services/EnvService';
import { requestMediaCamera } from './shared/modules/PermissionsUtils';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

let messaging = null;

if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
} else {
  console.warn('This browser does not support firebase messaging!');
}

const isPushNotificationSupported = () => {
  return 'serviceWorker' in navigator && 'PushManager' in window;
};

const handlePushNotification = (payload) => {
  console.log(`%cPush Payload:`, 'color:#FF6A39; font-family:monospace; font-size: 15px;');
  console.log('pushNotification', payload);
  if (getEnv('APP_TYPE', true) === 'patient') {
    PatientPushNotifier.onForegroundPushReceived(payload);
  } else {
    DoctorPushNotifier.onForegroundPushReceived(payload);
  }
};

// either custom event listener or onMessage
// messaging?.onMessage(handlePushNotification);

// Background & Foreground Messages
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.addEventListener('message', (message) => {
    const { data: payload } = message;
    handlePushNotification({
      data: payload.data,
      messageId: payload.fcmMessageId,
      from: payload.from,
      notification: payload.notification,
    });
  });
}

const getWebToken = () => {
  const response = [];
  return messaging
    ?.getToken({
      vapidKey: process.env.REACT_APP_FIREBASE_VAP_ID_KEY,
    })
    .then((currentToken) => {
      response.deviceRegId = currentToken;
      return response;
    })
    .catch((e) => {
      response.noToken = null;
      if (e.name === 'AbortError') {
        window.location.reload();
      }
      return response;
    });
};

export default { getWebToken };
