import { actionChannel, call, fork, put, take } from 'redux-saga/effects';
import { getAllReviewsAPI } from '../api/AllReviewsApi';
import { fetchAllReviewsError, fetchAllReviewsSuccess } from '../actions/AllReviewsActions';
import { getCurrencyAPI } from '../api/CurrencyApi';
import { fetchCurrencyError, fetchCurrencySuccess } from '../actions/CurrencyActions';
import currencySelector from '../selector/CurrencySelector';
import { FETCH_ALL_REVIEWS_REQUEST } from '../types/AllReviewsTypes';
import allReviewsSelector from '../selector/AllReviewsSelector';

function* getAllReviews(actionType) {
  const allReviewsChannel = yield actionChannel(actionType);
  while (true) {
    yield take(allReviewsChannel);
    try {
      // @ts-ignore
      const response = yield call(getAllReviewsAPI);
      yield put(fetchAllReviewsSuccess(allReviewsSelector(response.data)));
    } catch ({ message }) {
      yield put(fetchAllReviewsError({ message }));
    }
  }
}

function* allReviewsSaga() {
  yield fork(getAllReviews, FETCH_ALL_REVIEWS_REQUEST);
}

export default allReviewsSaga;
