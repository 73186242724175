import { default as ApiConstants } from 'shared/constants/ApiConstants.json';
import ApiUtils from 'shared/modules/ApiUtils';

const scheduleAppointmentAPI = (payload) =>
  ApiUtils.postRequest(ApiConstants.PATIENT_PROGRAM_APPOINTMENT_ENDPOINT, payload);

const callNowAppointmentAPI = (payload) =>
  ApiUtils.postRequest(ApiConstants.PATIENT_APPOINTMENT_CALL_NOW_ENDPOINT, payload);

const emergencyAppointmentAPI = (payload) =>
  ApiUtils.postRequest(ApiConstants.PATIENT_EMERGENCY_APPOINTMENT_ENDPOINT, payload);

const callSpecialistAppointmentAPI = (payload) =>
  ApiUtils.postRequest(ApiConstants.PATIENT_CALL_SPECIALIST_APPOINTMENT_ENDPOINT, payload);

const appointmentSelectProfileAPI = ({ appointmentId, data }) =>
  ApiUtils.putRequest(
    `${ApiConstants.PATIENT_APPOINTMENT_ENDPOINT}/${appointmentId}/profile`,
    data,
  );

const appointmentDeleteAPI = ({ appointmentId, data }) =>
  ApiUtils.postRequest(
    `${ApiConstants.PATIENT_APPOINTMENT_ENDPOINT}/${appointmentId}/delete`,
    data,
  );

const appointmentBookAPI = ({ appointmentId, data }) =>
  ApiUtils.putRequest(`${ApiConstants.PATIENT_APPOINTMENT_ENDPOINT}/${appointmentId}/booked`, data);

const appointmentCancelSearchAPI = ({ appointmentId, data }) =>
  ApiUtils.putRequest(`${ApiConstants.USER_APPOINTMENT_ENDPOINT}/${appointmentId}/cancel`, data);

const appointmentCancelAPI = ({ appointmentId, data }) =>
  ApiUtils.putRequest(`${ApiConstants.USER_APPOINTMENT_ENDPOINT}/${appointmentId}/cancel`, data);

const getPatientPastAppointmentsAPI = ({ page, limit }) =>
  ApiUtils.getRequest(
    `${ApiConstants.PATIENT_PAST_APPOINTMENTS_ENDPOINT}?page=${page}&limit=${limit}`,
  );

const getPatientUpcomingAppointmentsAPI = ({ page, limit }) =>
  ApiUtils.getRequest(
    `${ApiConstants.PATIENT_UPCOMING_APPOINTMENTS_ENDPOINT}?page=${page}&limit=${limit}`,
  );

const getPatientAppointmentDetailsAPI = (payload) =>
  ApiUtils.getRequest(`${ApiConstants.PATIENT_APPOINTMENT_DETAILS_ENDPOINT}/${payload?.id}`);

const appointmentAddReviewAPI = (payload) =>
  ApiUtils.postRequest(
    `${ApiConstants.PATIENT_APPOINTMENT_ADD_REVIEW_ENDPOINT}/${payload.appointmentId}/review`,
    payload?.body,
  );

const appointmentShareMedicalRecordAPI = (payload) =>
  ApiUtils.putRequest(
    `${ApiConstants.PATIENT_APPOINTMENT_ENDPOINT}/${payload.appointmentId}/medical-records`,
    payload?.body,
  );

export {
  scheduleAppointmentAPI,
  callNowAppointmentAPI,
  emergencyAppointmentAPI,
  callSpecialistAppointmentAPI,
  appointmentSelectProfileAPI,
  appointmentDeleteAPI,
  appointmentBookAPI,
  appointmentCancelSearchAPI,
  appointmentCancelAPI,
  getPatientAppointmentDetailsAPI,
  getPatientPastAppointmentsAPI,
  getPatientUpcomingAppointmentsAPI,
  appointmentAddReviewAPI,
  appointmentShareMedicalRecordAPI,
};
