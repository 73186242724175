import {
  resolveDoctorSpecializations,
  resolveUserLanguages,
} from 'shared/modules/PatientDataFormatter';
import { resolveAvailability } from 'shared/modules/DoctorDataFormatter';
import i18next from 'i18next';
import Utils from 'shared/modules/Utils';
import { translatedFullDate } from 'shared/modules/DateTimeUtils';

const doctorSelector = (doctors, specializationId) => {
  // eslint-disable-next-line no-underscore-dangle
  const doctorsArray = doctors._embedded?.items ?? [];
  const data: Array<Record<string, unknown>> = [];

  // eslint-disable-next-line array-callback-return
  doctorsArray.map((row) => {
    const mainSpecialization = {
      id: row.specialization.id,
      price: row.price,
      price_as_string: row.price_as_string,
      specialization: row.specialization,
      certifications_portfolio: null,
      is_validated: true,
      is_available: true,
      duration: row.duration,
    };

    row.doctor.doctor_specializations.unshift(mainSpecialization);

    data.push({
      doctorServices: row.doctor_services?.map((service) => {
        return {
          name: service.service.name,
          priceAsString: service.price_as_string,
          duration: service.duration,
          slug: service.service.slug,
          isActive: service.is_active,
        };
      }),
      id: row.doctor?.id,
      specializationId: row.specialization.id,
      price: row.price_as_string,
      priceAsString: row.price_as_string,
      duration: row.duration,
      rowId: row.id,
      email: row.doctor.email,
      locale: row.doctor.locale,
      accountStatus: row.doctor.account_status,
      firstName: row.doctor.first_name,
      lastName: row.doctor.last_name,
      fullNameWithTitle: row.doctor.full_name_with_title,
      numericPersonalCode: row.doctor.numeric_personal_code,
      gender: Utils.solveGender(row?.doctor?.gender),
      birthDate: row.doctor.birth_date,
      phoneNumberCountry: row.doctor.phone_number_country?.calling_code,
      phoneNumber: row.doctor.phone_number,
      address: row.doctor.address,
      pictureMedia: row.doctor.picture_media?.public_url ?? null,
      imgUrl: row.doctor.picture_media?.public_url ?? null,
      availability: resolveAvailability(
        row.doctor.is_available_for_call_now,
        row.doctor.is_busy,
        row.doctor.is_fake_busy,
      ),
      userLanguages: resolveUserLanguages(row?.doctor?.user_languages),
      stripeConnectedAccount: row.doctor.stripe_connected_account,
      createdAt: row.doctor.created_at,
      updatedAt: row.doctor.updated_at,
      deletedAt: row.doctor.deleted_at,
      managedBy: row.doctor.managed_by,
      patientMedicalRecords: row.doctor.patient_medical_records,
      organization: row.doctor.organization,
      doctorDescription: row.doctor.doctor_description,
      identityCardMedia: row.doctor?.identity_card_media,
      additionalDocumentMedia: row.doctor.additional_document_media,
      cvMedia: row.doctor.cv_media,
      averageRating: row.doctor.average_rating,
      countRating: row.doctor.count_rating,
      isAvailableForCallNow: row.doctor.is_available_for_call_now,
      isAvailableForEmergency: row.doctor.is_available_for_emergency,
      isAvailableForFreeCall: row.doctor?.is_available_for_free_talk,
      hasSchedule: row.doctor?.has_schedule,
      organizationClinicName: row.doctor?.organization?.clinic_name,
      organizationLogo: row.doctor?.organization?.logo_media?.public_url,
      specializationName:
        row?.specialization?.translations?.find((r) => r.locale === i18next.language)?.name ?? '',
      doctorSpecializations: resolveDoctorSpecializations(row.doctor?.doctor_specializations),
      rawFirstAvailableTime: row.doctor.first_available_time,
      firstAvailableTime: row.doctor.first_available_time
        ? translatedFullDate(row.doctor.first_available_time, true, true)
        : null,
    });
  });
  const { pages, page } = doctors;
  return { noOfPages: pages, currentPage: page, data };
};

const timeSlotsSelector = (slots) => {
  const formattedSlots = {};
  if (Array.isArray(slots)) {
    slots.map((slot) => {
      formattedSlots[slot.date] = slot.time_slots;
      return true;
    });
  }
  return formattedSlots;
};

const sortingSelector = (sortingOptions) => {
  const sortingArray = sortingOptions?.data ?? [];
  return sortingArray.map((option) => {
    return {
      slug: option.slug,
      translations: option.translations,
    };
  });
};

export { doctorSelector, timeSlotsSelector, sortingSelector };
