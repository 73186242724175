import { AnyAction } from 'redux';
import { replaceArrayRowProps } from 'shared/modules/ArrayUtils';
import {
  GET_FAMILY_MEMBERS_SUCCESS,
  GET_PROFILE_SUCCESS,
  UPDATE_FAMILY_MEMBER_SUCCESS,
  UPDATE_PROFILE_SUCCESS,
} from 'shared/redux/types/FamilyMembersTypes';

const FamilyMembersReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case GET_FAMILY_MEMBERS_SUCCESS:
      return action.payload;
    case UPDATE_FAMILY_MEMBER_SUCCESS:
      return replaceArrayRowProps(state, 'id', action.payload?.id, action.payload);
    default:
      return state;
  }
};

const ProfileDetailsReducer = (
  state = {},
  action: AnyAction = (() => {}) as unknown as AnyAction,
) => {
  switch (action.type) {
    case GET_PROFILE_SUCCESS:
      return action.payload;
    case UPDATE_PROFILE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export { FamilyMembersReducer, ProfileDetailsReducer };
