import 'shared/styles/how-it-works.css';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import FooterSection from 'shared/components/FooterSection';
import HeaderMenu from 'shared/components/HeaderMenu';
import HeaderMenuMobile from 'shared/components/HeaderMenuMobile';
import CustomIcon from 'shared/components/CustomIcon';
import appMedicImage from 'shared/assets/images/aplicatie-medic-image.png';
import Colors from 'shared/themes/Colors';
import openGraphImage from 'shared/assets/images/ringdoc_image_open_graph.png';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Utils from '../modules/Utils';
import { scrollToTop } from '../modules/WindowUtils';

const HowItWorksDoctor = () => {
  const { t } = useTranslation();
  const currentUrl = window.location.href;
  const baseUrl = window.location.origin;

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="how-it-works gradient-how-it-works-doctor">
      <Helmet>
        <title>{t('howItWorks.doctor.titlePage')}</title>
        <meta
          name="description"
          content="Afla cum functioneaza aplicatia de telemedicina Ringdoc in care poti acorda consultatii medicale online pacientilor din toata lumea"
        />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:locale" content="ro_RO" />
        <meta property="og:locale:alternate" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={t('howItWorks.doctor.titlePage')} />
        <meta
          property="og:description"
          content="Afla cum functioneaza aplicatia de telemedicina Ringdoc in care poti acorda consultatii medicale online pacientilor din toata lumea"
        />
        <meta property="og:image" content={`${baseUrl}${openGraphImage}`} />
      </Helmet>

      <HeaderMenuMobile />

      <div className="container-fluid">
        <div className="row">
          <HeaderMenu />

          <main className="container">
            <section className="title">
              <h1>{t('howItWorks.doctor.title')}</h1>
            </section>

            <section className="video">
              <h2>{t('howItWorks.doctor.description')}</h2>

              <div className="box-number doctor">
                <div className="circle one">
                  <span>1</span>
                </div>
                <div className="description">{t('howItWorks.doctor.steps.step1')}</div>
              </div>

              <div className="box-number doctor">
                <div className="circle two">
                  <span>2</span>
                </div>
                <div className="description">{t('howItWorks.doctor.steps.step2')}</div>
              </div>

              <div className="box-number doctor">
                <div className="circle three">
                  <span>3</span>
                </div>
                <div className="description">{t('howItWorks.doctor.steps.step3')}</div>
              </div>

              <div className="box-number doctor">
                <div className="circle four">
                  <span>4</span>
                </div>
                <div className="description">{t('howItWorks.doctor.steps.step4')}</div>
              </div>
            </section>

            <section className="faq" id="faq">
              <h3>{t('howItWorks.doctor.faq')}</h3>
              <Accordion defaultActiveKey="">
                <Accordion.Item eventKey="0">
                  <Accordion.Header as={Card.Header}>
                    {t('howItWorks.doctor.faqs.title1')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>{t('howItWorks.doctor.faqs.description1')}</p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header as={Card.Header}>
                    {t('howItWorks.doctor.faqs.title2')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>{t('howItWorks.doctor.faqs.description2')}</p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header as={Card.Header}>
                    {t('howItWorks.doctor.faqs.title3')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>{t('howItWorks.doctor.faqs.description3')}</p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header as={Card.Header}>
                    {t('howItWorks.doctor.faqs.title4')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>{t('howItWorks.doctor.faqs.description4')}</p>
                    <br />
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                  <Accordion.Header as={Card.Header}>
                    {t('howItWorks.doctor.faqs.title5')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>{t('howItWorks.doctor.faqs.description5')}</p>
                    <br />
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                  <Accordion.Header as={Card.Header}>
                    {t('howItWorks.doctor.faqs.title6')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>{t('howItWorks.doctor.faqs.description6')}</p>
                    <br />
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="6">
                  <Accordion.Header as={Card.Header}>
                    {t('howItWorks.doctor.faqs.title7')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>{t('howItWorks.doctor.faqs.description7')}</p>
                    <br />
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="7">
                  <Accordion.Header as={Card.Header}>
                    {t('howItWorks.doctor.faqs.title8')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>{t('howItWorks.doctor.faqs.description8')}</p>
                    <br />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </section>

            <section className="download-app medic">
              <div className="column">
                <h3>{t('howItWorks.doctor.downloadAppDoctor')}</h3>
                <div className="apps-button">
                  <a
                    target="_blank"
                    href={
                      Utils.isiPhone()
                        ? 'itms-apps://apps.apple.com/us/app/ringdoc-medic/id1574488917'
                        : 'https://apps.apple.com/us/app/ringdoc-medic/id1574488917'
                    }
                    rel="noreferrer"
                  >
                    <i className="app-store-icon cursor-pointer" />
                  </a>
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=ro.ascendnet.alodoc.doctor.ro"
                    rel="noreferrer"
                  >
                    <i className="google-play-icon cursor-pointer" />
                  </a>
                </div>
              </div>
              <div className="column">
                <img src={appMedicImage} alt="Descarca Aplicatia" />
              </div>
            </section>
          </main>

          <FooterSection />
        </div>
      </div>
    </div>
  );
};

export default HowItWorksDoctor;
